// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// REACT ICONS
import { FaMoneyBills } from "react-icons/fa6";
import { RiBillFill } from "react-icons/ri";

// PARAMS
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import GroupingBill from "../../Reusable/Functions/GroupingArticle/GroupingBill";
import GroupingArticle from "../../Reusable/Functions/GroupingArticle/GroupingArticle";

// GLOBAL STYLES
import { mainBody, textForm } from "../../../Style/Global/Global";

export default function BillOutstandingModal(props) {
  // PROPS
  const { openModal, closeModal, guestData } = props;

  // Currency
  const currency = CurrencySymbols().code;
  const accounting = AccountingParams();
  const bill_type = FrontOfficeParams().bill_type;

  // STATE
  // DATA
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({
    grand: 0,
    total: 0,
    tax: 0,
    service: 0,
  });
  // BOOLEAN
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  // NAVIGATE
  const navigate = useNavigate();

  // USE FORM
  const [form] = Form.useForm();

  // USEEFFECT GUEST DATA
  useEffect(() => {
    let cleanUp = false;

    if (guestData && !cleanUp) {
      setFormFields(guestData);
      const manipulatedResponse = handleManipulateResponse(guestData.details);
      setData(manipulatedResponse);
    }

    return () => {
      cleanUp = true;
    };
  }, [guestData]);

  // HANDLE SET FORM FIELDS
  const setFormFields = (data) => {
    form.setFieldsValue({
      reservation_id: data?.reservation_id || "-",
      bill_master: data?.bill_master || "-",
      bill_receiver_name: data?.bill_receiver_name || "-",
      guest_name: data?.guest_name || "-",
    });

    setIsLoading(false);
  };

  // HANDLE MANIPULATE API RESPONSE
  // MAKE RESPONSE TO 1 ROW (Article, Tax, Service)
  const handleManipulateResponse = (array) => {
    const _arr = array;
    const result = [];

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    const _grouping = GroupingArticle(_billing);
    // Showing Bill Type
    const totals = BillArticleShow({
      article: _grouping,
      bill_type: bill_type,
    });

    // array.forEach((item) => {
    //   const key = `${item.article_id}-${item.date}`;
    //   const existing = result.find((entry) => entry.key === key);

    //   if (existing) {
    //     existing.price += item.price;
    //   } else {
    //     result.push({
    //       key: key,
    //       // id: generateUniqueKey(),
    //       article_id: item.article_id,
    //       article_name: item.article_name,
    //       reservation_id: item.reservation_id,
    //       price: item.price,
    //       quantity: item.quantity,
    //       date: item.date,
    //       created_date: item.created_date,
    //       is_show: item.is_show,
    //     });
    //   }
    // });

    // console.log(
    //   "Detail Outstanding->> ",
    //   result.map(({ key, ...rest }) => rest)
    // );

    // return result.map(({ key, ...rest }) => rest);

    console.log("Billing: ", _billing);
    console.log("Grouping Res: ", _grouping);
    console.log("Grouping Ttoals: ", totals);

    setData(totals.article);
    setTotal({
      grand: totals.total.total_price,
      service: totals.total.service,
      tax: totals.total.tax,
      total: totals.total.unit_price,
    });

    return totals.article;
  };

  // HANDLE CLOSE
  const handleClose = () => {
    closeModal((state) => !state);
  };

  // REDIRECT TO CASHIER FO
  const handleNavigate = () => {
    setLoading(true);

    const _path = "/front-office/bill-outstanding-list/master-folio";
    const _state = guestData;

    navigate(_path, {
      state: {
        data_res: _state,
      },
    });
  };

  // TBALE COLUMNS
  const columns = [
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    // },
    {
      title: "Trx Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (date, record) => {
        return moment(LocalizationDate(date)).format("HH:mm:ss, DD-MM-YYYY");
      },
      sort: ["ascend", "descend"],
      sorter: (a, b) => a.created_date.localeCompare(b.created_date),
    },
    {
      title: `Qty`,
      dataIndex: "quantity",
      key: "quantity",
      width: 50,
      render: (qty, record) => {
        return `${qty > 0 ? qty : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "price",
      align: "right",
      render: (unit, record) => {
        return `${unit > 0 ? unit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "price",
      align: "right",
      render: (unit, record) => {
        return `${unit > 0 ? unit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  return (
    <Modal
      className="bill_outstanding-modal"
      key="bill_outstanding-modal"
      title={
        <>
          <Row className="modal-title-row" justify="start" align="middle">
            <RiBillFill
              className="modal-icon"
              style={{
                color: "#3699FF",
                fontSize: 24,
              }}
            />
            <Typography
              className="modal-txt"
              style={{ marginLeft: 15 }}
            >{`Bill Outstanding List `}</Typography>
          </Row>
        </>
      }
      centered
      // confirmLoading={isLoading}
      footer={null}
      open={openModal}
      closable
      onCancel={handleClose}
      width={1000}
    >
      <Form
        className="bill-outstanding-form"
        key="bill-outstanding-form"
        name="bill-outstanding-form"
        labelAlign="left"
        form={form}
        labelCol={{
          span: 12,
        }}
        labelWrap={{
          span: 12,
        }}
        layout="horizontal"
      >
        <Row className="identity-row" style={{ marginBottom: 20 }}>
          <Col span={24} className="identity-col">
            <Row className="ident-row" gutter={30}>
              <Col span={12} className="name-col">
                <Form.Item
                  label="Guest Name"
                  className="name-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="guest-name-text" style={textForm}>
                    {guestData?.guest_name
                      ? guestData?.guest_name?.replace(/\d+$/, "").trim()
                      : "-"}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Arrival"
                  className="name-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="arrival-text" style={textForm}>
                    {guestData?.arrival
                      ? moment(guestData.arrival).format("DD-MM-YYYY")
                      : moment(guestData?.start_date).format("DD-MM-YYYY") !=
                        "Invalid Date"
                      ? moment(guestData?.start_date).format("DD-MM-YYYY")
                      : "-"}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Departure"
                  className="name-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="departure-text" style={textForm}>
                    {guestData?.departure
                      ? moment(guestData.departure).format("DD-MM-YYYY")
                      : moment(guestData?.end_date).format("DD-MM-YYYY") !=
                        "Invalid Date"
                      ? moment(guestData?.end_date).format("DD-MM-YYYY")
                      : "-"}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Night(s)"
                  className="night"
                  style={{ margin: 0 }}
                >
                  <Typography className="departure-text" style={textForm}>
                    {guestData?.night ? guestData.night : 0}
                  </Typography>
                </Form.Item>
              </Col>

              <Col span={12} className="count-col">
                <Form.Item
                  label="Reservation No."
                  className="name-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="reservation-text" style={textForm}>
                    {guestData?.reservation_id
                      ? guestData?.reservation_id
                      : "-"}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Payment Detail"
                  className="payment-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="payment-text" style={textForm}>
                    {`${guestData?.room_category} - ${guestData?.room_number}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Payment Status"
                  className="payment-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="payment-text" style={textForm}>
                    {guestData?.payment_status == 3
                      ? "Cash Basis"
                      : "Charge To Room"}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Divider
        className="form-divider"
        style={{ margin: "0px 0px 20px", background: "#EBEDF3" }}
      />

      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24}>
          <Row style={{ width: "100%" }}>
            <Typography
              style={{
                fontSize: 17,
                fontWeight: 750,
                marginBottom: 10,
              }}
            >
              {`Bill List`}
            </Typography>

            <Table
              className="bill-outstanding-detail-table"
              name="bill-outstanding-detail-table"
              key="bill-outstanding-detail-table"
              columns={columns}
              dataSource={data}
              // loading={isLoading}
              bordered={true}
              size="small"
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record?.article_id > 0 ? record.article_id : record?.key;
              }}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      {bill_type ? (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3} />

                            <Table.Summary.Cell index={4}>
                              <b>Subtotal</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={5} align="right">
                              {`${currency} ${
                                total.total > 0 ? total.total : 0
                              }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3} />

                            <Table.Summary.Cell index={4}>
                              <b>Service</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={5} align="right">
                              {`${currency} ${total?.service || 0}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3} />

                            <Table.Summary.Cell index={4}>
                              <b>Tax</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={5} align="right">
                              {`${currency} ${total?.tax || 0}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      ) : null}

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={3} />

                        <Table.Summary.Cell index={4}>
                          <b style={{ fontSize: 15 }}>Grand Total</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={5} align="right">
                          <b style={{ fontSize: 15 }}>
                            {`${currency} ${
                              total.grand > 0 ? total.grand : 0
                            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>

      <Row justify="end" align="middle" style={{ marginTop: 30 }}>
        <Button
          type="primary"
          onClick={handleNavigate}
          key={"submit"}
          // loading={isLoading}
          icon={
            <FaMoneyBills
              style={{
                margin: "0px 5px 0px",
                fontSize: 20,
              }}
            />
          }
          className="submit-btn"
          style={{
            marginRight: 15,
            display: "flex",
            alignContent: "center",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#FFF",
            }}
          >{`Redirect To Create Folio`}</Typography>
        </Button>

        <Button className="cancel-btn" type="default" onClick={handleClose}>
          {`Close`}
        </Button>
      </Row>
    </Modal>
  );
}
