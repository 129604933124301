// Import React Components
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useParams } from "react-router-dom";

// Page Components
import ReservationPage from "../../Pages/FrontOffice/Reservation/Reservation";
import AddReservation from "../../Pages/FrontOffice/AddReservation/AddReservation";
import GuestCardListPage from "../../Pages/FrontOffice/GuestList/GuestList";
import AddReservationTabs from "../../Components/Tabs/Reservation/AddReservationTabs";
import EditReservationTabs from "../../Components/Tabs/Reservation/EditReservationTabs";

import ReservationDepositPage from "../../Pages/FrontOffice/ReservationDeposit/ReservationDeposit";
import ExpectedArrivalPage from "../../Pages/FrontOffice/ExpectedArrival/ExpextedArrival";
import ExpectedDeparturePage from "../../Pages/FrontOffice/ExpectedDeparture/ExpectedDeparture";
import GuestInHousePage from "../../Pages/FrontOffice/GuestInHouse/GuestInHouse";
import ForeignGuestPage from "../../Pages/FrontOffice/ForeignGuestList/ForeignGuestList";
import HistoryGuestPage from "../../Pages/FrontOffice/HistoryGuestList/HistoryGuestList";
import AmericanBreakfastPage from "../../Pages/FrontOffice/AmericanBreakfast/AmericanBreakfast";
import BillOutstandingPage from "../../Pages/FrontOffice/BillOutsanding/BillOutstandingList";
import BirthdayPage from "../../Pages/FrontOffice/Birthday/Birthday";
import WalkInGuestPage from "../../Pages/FrontOffice/WalkInGuest/WalkInGuest";
import RoomChartPage from "../../Pages/FrontOffice/RoomChart/RoomChart";
import RoomAvailabilityPage from "../../Pages/FrontOffice/RoomAvailability/RoomAvailability";
import NonStayPage from "../../Pages/FrontOffice/NonStay/NonStay";
import HistoryNonStayPage from "../../Pages/FrontOffice/HistoryNonStay/HistoryNonStay";
import AddMemberGroupTabs from "../../Components/Tabs/Reservation/AddMemberTabs";
import CheckOutPage from "../../Pages/FrontOffice/CheckOut/CheckOut";
import NightAuditPage from "../../Pages/FrontOffice/NightAudit/NightAudit";
import AuditSuccessPage from "../../Pages/FrontOffice/NightAudit/Success";
import MasterFolioPage from "../../Pages/FrontOffice/MasterFolio/MasterFolio";
import ReNightAuditPage from "../../Pages/FrontOffice/ReNightAudit/ReNightAudit";
import ReAuditSuccessPage from "../../Pages/FrontOffice/ReNightAudit/Success";
import FOCashierPage from "../../Pages/FrontOffice/Cashier/FOCashier";
import CashierSuccessPage from "../../Pages/FrontOffice/Cashier/Success";
import GuestFolioPage from "../../Pages/FrontOffice/MasterFolio/GuestFolio";
import MasterFolioSuccessPage from "../../Pages/FrontOffice/MasterFolio/Success";
import EarlyCheckOutPage from "../../Pages/FrontOffice/EarlyCheckOut/EarlyCO";
import CheckOutSuccessPage from "../../Pages/FrontOffice/CheckOut/Success";
//
import NotFound from "../../Pages/NotFound/NotFound";
import UnAuthorized from "../../Pages/Unauthorized/Unauthorized";

// AUTH
import { sub_module } from "../../API/Global/Payload";
import EarlyCheckOutSuccessPage from "../../Pages/FrontOffice/EarlyCheckOut/Success";

const subRoute = [
  {
    path: "front-office-cashier",
    name: "fo-cashier",
    id_modules: 9,
    element: <FOCashierPage />,
  },
  {
    path: "front-office-cashier/success",
    name: "fo-cashier",
    id_modules: 9,
    element: <CashierSuccessPage />,
  },
  {
    path: "reservation",
    name: "reservation",
    id_modules: 9,
    element: <ReservationPage />,
  },
  {
    path: "reservation/add-reservation",
    name: "add-res",
    id_modules: 9,
    element: <AddReservationTabs />,
  },
  {
    path: "reservation/edit-reservation",
    name: "edit-res",
    id_modules: 9,
    element: <EditReservationTabs />,
  },
  {
    path: "reservation/add-member-group",
    name: "edit-res",
    id_modules: 9,
    element: <AddMemberGroupTabs />,
  },
  {
    path: "reservation/guest-card-list",
    name: "guest-list",
    id_modules: 73,
    element: <GuestCardListPage />,
  },
  {
    path: "reservation-deposit",
    name: "reservation-deposit",
    id_modules: 10,
    element: <ReservationDepositPage />,
  },
  {
    path: "expected-arrival",
    name: "expected-arrival",
    id_modules: 11,
    element: <ExpectedArrivalPage />,
  },
  {
    path: "guest-inhouse",
    name: "guest-inhouse",
    id_modules: 12,
    element: <GuestInHousePage />,
  },
  {
    path: "expected-departure",
    name: "expected-departure",
    id_modules: 13,
    element: <ExpectedDeparturePage />,
  },
  {
    path: "expected-departure/guest-folio",
    name: "guest-folio",
    id_modules: 16,
    element: <GuestFolioPage />,
  },
  {
    path: "/expected-departure/check-out",
    name: "check-out",
    id_modules: 13,
    element: <CheckOutPage />,
  },
  {
    path: "expected-departure/check-out/success",
    name: "result-check-out",
    id_modules: 13,
    element: <CheckOutSuccessPage />,
  },
  {
    path: "/expected-departure/early-check-out",
    name: "check-out",
    id_modules: 13,
    element: <EarlyCheckOutPage />,
  },
  {
    path: "expected-departure/early-check-out/success",
    name: "result-check-out",
    id_modules: 13,
    element: <EarlyCheckOutSuccessPage />,
  },
  {
    path: "foreign-guest-list",
    name: "foreign-guest",
    id_modules: 14,
    element: <ForeignGuestPage />,
  },
  {
    path: "history-guest-list",
    name: "history-geust",
    id_modules: 15,
    element: <HistoryGuestPage />,
  },
  {
    path: "bill-outstanding-list",
    name: "bil-outstanding",
    id_modules: 16,
    element: <BillOutstandingPage />,
  },
  {
    path: "bill-outstanding-list/master-folio",
    name: "master-folio",
    id_modules: 16,
    element: <MasterFolioPage />,
  },
  {
    path: "bill-outstanding-list/master-folio/success",
    name: "result-master-folio",
    id_modules: 16,
    element: <MasterFolioSuccessPage />,
  },
  {
    path: "abf-guest-list",
    name: "abf-guest",
    id_modules: 17,
    element: <AmericanBreakfastPage />,
  },
  {
    path: "birthday",
    name: "birthday",
    id_modules: 18,
    element: <BirthdayPage />,
  },
  {
    path: "walkin-guest-list",
    name: "walking-guest",
    id_modules: 19,
    element: <WalkInGuestPage />,
  },
  {
    path: "room-chart",
    name: "room-chart",
    id_modules: 20,
    element: <RoomChartPage />,
  },
  {
    path: "room-availability",
    name: "room-avail",
    id_modules: 21,
    element: <RoomAvailabilityPage />,
  },
  {
    path: "non-stay",
    name: "non-stay",
    id_modules: 22,
    element: <NonStayPage />,
  },
  {
    path: "history-non-stay",
    name: "history-non-stay",
    id_modules: 23,
    element: <HistoryNonStayPage />,
  },
  {
    path: "night-audit",
    name: "night-audit",
    id_modules: 24,
    element: <NightAuditPage />,
  },
  {
    path: "night-audit/success",
    name: "success-audit",
    id_modules: 24,
    element: <AuditSuccessPage />,
  },
  {
    path: "re-audit",
    name: "check-out",
    id_modules: 112,
    element: <ReNightAuditPage />,
  },
  {
    path: "re-audit/success",
    name: "check-out",
    id_modules: 112,
    element: <ReAuditSuccessPage />,
  },
];

// CODE
export default function FrontOfficeRoutes() {
  // STATE MANAGEMENT
  // Data
  const [protectedRoutes, setProtectedRoutes] = useState(null);

  // Filtering Routes
  useEffect(() => {
    protectedRoute();
  }, []);

  // Handle Route
  const protectedRoute = () => {
    // console.log("Props Front Office: ", sub_module);
    const _mods = sub_module.length > 0 ? sub_module : []; // values.module;
    let _arr = [];

    const proRoute = subRoute.map((value) => {
      // console.log("ID Mods: ", value);
      // console.log("ID Mods: ", _mods);

      if (_mods.includes(value.id_modules)) {
        return (
          <Route key={value.id_modules}>
            <Route path={value.path} element={value.element} key={value.name} />
          </Route>
        );
      } else {
        return (
          <Route
            path={value.path}
            element={<UnAuthorized />}
            key={value.name}
          />
        );
      }
    });

    // console.log("Protected Routes FO: ", proRoute);
    // console.log("Protected Arr: ", _arr);

    setProtectedRoutes(proRoute);
  };

  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />

        {protectedRoutes}

        {/* 
        <Route path="/reservation" element={<ReservationPage />} />
        <Route
          path="/reservation/add-reservation"
          element={<AddReservationTabs />}
          // element={<AddReservation />}
        />
        <Route
          path="/reservation/edit-reservation"
          element={<EditReservationTabs />}
        />
        <Route
          path="/reservation/add-member-group"
          element={<AddMemberGroupTabs />}
        />
        <Route
          path="/reservation/guest-card-list"
          element={<GuestCardListPage />}
        />
        <Route
          path="/reservation-deposit"
          element={<ReservationDepositPage />}
        />
        <Route path="/expected-arrival" element={<ExpectedArrivalPage />} />
        <Route path="/expected-departure" element={<ExpectedDeparturePage />} />
        <Route
          path="/expected-departure/check-out"
          element={<CheckOutPage />}
        />
        <Route
          path="/expected-departure/check-out/success"
          element={<CheckOutSuccessPage />}
        />
        <Route path="/early-check-out" element={<EarlyCheckOutPage />} />
        <Route path="/guest-inhouse" element={<GuestInHousePage />} />
        <Route path="/foreign-guest-list" element={<ForeignGuestPage />} />
        <Route path="/history-guest-list" element={<HistoryGuestPage />} />
        <Route
          path="/bill-outstanding-list"
          element={<BillOutstandingPage />}
        />
        <Route path="/abf-guest-list" element={<AmericanBreakfastPage  />} />
        <Route path="/birthday" element={<BirthdayPage />} />
        <Route path="/walkin-guest-list" element={<WalkInGuestPage />} />
        <Route path="/room-chart" element={<RoomChartPage />} />
        <Route path="/room-availability" element={<RoomAvailabilityPage />} />
        <Route path="/non-stay" element={<NonStayPage />} />
        <Route path="/history-non-stay" element={<HistoryNonStayPage />} />

        <Route path="/night-audit" element={<NightAuditPage />} />
        <Route path="/night-audit/success" element={<AuditSuccessPage />} />
      */}
      </Routes>
      <Outlet />
    </>
  );
}
