// Import React Components
import React, { useState, useEffect } from "react";

// Import React Components
import {
  Form,
  Modal,
  Row,
  Typography,
} from "antd";

// Import React Icons
import { AiOutlineSave } from "react-icons/ai";
import CaptainOrderTable from "../../../Table/Outlet/Order/Main/CaptainTable";

// Modal
const { confirm } = Modal;

export default function CaptainModalChangeTable(props) {
  // PROPS
  const { openModal, closeModal, locationCategories, selectedTable } = props;

  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // SHOW MODAL
  const showConfirmChangeTableModal = (value) => {
    const _data = value;
    console.log(`CaptainChangeTableModal>selectedTable:`, _data);
    confirm({
      className: "confirm",
      title: `POS Order CHANGE TABLE Confirmation`,
      content: `Are you sure you want to change table to ${(_data?.table_code).toUpperCase()}`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        
        handleCancel(_data);
        // Modal.destroyAll();
      },

      onCancel() {
        // setIsLoading(false);
        // Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE CANCEL
  const handleCancel = (value) => {
    selectedTable(value);
    setOpen(false);
    closeModal(false);

    form.resetFields();
  };

  return (
    <>
      <Modal
        className="change-table-modal"
        title={
          <>
            <Row className="change-table-modal-row">
              <AiOutlineSave
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Select Table`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <CaptainOrderTable
          table_number={ handleCancel }
          locationCategories={locationCategories}
        />
      </Modal>
    </>
  );
}
