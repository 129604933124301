const getURL = async () => {
  return await fetch("assets/configurl.json", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then(function (response) {
      // console.log("Response Panggil: ", response);

      return response.json();
    })
    .then(function (myJson) {
      // console.log("My JSON: ", myJson);

      return myJson;
    })
    .catch((error) => {
      return error;
    });
};

const caller = await getURL();

export { caller };
