// Import React Components
import { io } from "socket.io-client";
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";

import {
  masterSuccessNotification,
  masterFailedNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

export default function KitchenProcessModal(props) {
  // PROPS
  const { openModal, closeModal, data, allDetails, is_created } = props;

  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [bodyToPrint, setBodyToPrint] = useState([]);

  // USE FORM
  const [form] = Form.useForm();
  const { Text } = Typography;

  // USE EFFECT
  useEffect(() => {
    console.log("bodyToPrint", bodyToPrint);
    const socket = io("https://printer-api.hilook.support/", {
      transports: ["websocket"],
      auth: {
        hotel_id: "abcdefg",
      },
      withCredentials: true,
    });

    socket.connect();

    if(bodyToPrint){
      socket.emit("message", bodyToPrint);
    }

    // socket.on("recieve_message", setMessage);
    
    return () => {
      socket.disconnect();
    }
  },[bodyToPrint]);
  
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    console.log("DATA>", data);
  },[data])


  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    let printer = e.kitchen_bar;
    handleUpdateDetail(data, printer)
    // handleCancel();
  };
      
  const onFinishFailed = (e) => {
    console.log("error", e);
  };

  const handleUpdateDetail = async (dataItem, printer) => {
    let updateDetail = [];
    let deleteDetail = [];

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Between Ordered And Cooked
    // Untuk filter status Cooked
    const dataFilterCooked = allDetails.filter((x) => {
      if(x.order_step == 1) {
        return true
      } else {
        return false
      }
    });

    // Untuk filter status Ordered
    const dataFilterOrdered = dataItem.selectedItems.filter((x) => {
      if(x.order_step == 0) {
        return true
      } else {
        return false
      }
    });

    console.log("dataFilterCooked", dataFilterCooked);
    console.log("dataFilterOrdered", dataFilterOrdered);


    // Untuk filter data bertatus Cooked yang memiliki data lain berstatus Ordered dimana article_id sama, serving_que sama 
    const dataSpecifiedCooked = dataFilterCooked.filter((x) => {
      if(dataFilterOrdered.map(e => parseInt(e.article_id)).indexOf(parseInt(x.article_id)) > -1 && dataFilterOrdered.map(e => parseInt(e.serving_que)).indexOf(parseInt(x.serving_que)) > -1) {
        return true
      } else {
        return false
      }
    });

    console.log("dataSpecifiedCooked", dataSpecifiedCooked);

    // Untuk menambah item_quantity pada data Cooked jika memiliki data lain berstatus Ordered
    const dataSpecifiedCookedMap = dataSpecifiedCooked.map((x) => {
      // Untuk mendapatkan item_quantity pada data berstatus Ordered dimana article_id sama, serving_que sama 
      const dataItems = dataFilterOrdered.filter((y) => {
        if(parseInt(x.serving_que) == parseInt(y.serving_que) && parseInt(x.article_id) == parseInt(y.article_id)){
          return true
        } else {
          return false
        }
      })[0];

      return {
        ...x,
        deleted_reason: "ADD QUANTITY",
        item_quantity: dataItems?.item_quantity ? x.item_quantity + dataItems.item_quantity : x.item_quantity,
        notes: x?.notes && dataItems?.notes ? x.notes + "," + dataItems.notes :
               (x?.notes == null || x?.notes == "undefined") && dataItems?.notes  ? dataItems?.notes :
               x?.notes && (dataItems?.notes == null || dataItems?.notes == "undefined") ? x.notes :
               "",
      }
    });

    console.log("dataSpecifiedCookedMap", dataSpecifiedCookedMap);

    // Untuk filter data bertatus Ordered yang memiliki data lain berstatus Cooked dimana article_id sama, serving_que sama. Tujuannya untuk menghapus item.
    const itemDeleteChangeCooked = dataFilterOrdered.filter((x) => {
      if(dataFilterCooked.map(e => parseInt(e.article_id)).indexOf(parseInt(x.article_id)) > -1 && dataFilterCooked.map(e => parseInt(e.serving_que)).indexOf(parseInt(x.serving_que)) > -1) {
        return true
      } else {
        return false
      }
    });

    console.log("itemDeleteChangeCooked", itemDeleteChangeCooked);

    if(dataSpecifiedCookedMap.length > 0) {
      dataSpecifiedCookedMap.map((x) => {
        updateDetail.push(x)
      });
    };

    if(itemDeleteChangeCooked.length > 0) {
      itemDeleteChangeCooked.map((x) => {
        deleteDetail.push(x)
      });
    };

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Other Conditions: Tidak ada data lain yg serupa
    const detailDifferentData = dataItem.selectedItems.filter((x) => {
      const checkSimilarData = dataSpecifiedCookedMap.some(y => y.article_id === x.article_id && y.serving_que == x.serving_que && x.order_step == 0);
      
      if(x.header_id && !checkSimilarData) {
        return true
      } else {
        return false
      }
    });

    const detailDifferentDataMap = detailDifferentData.map((x) => {
      return {
        ...x,
        deleted_reason: "CHANGE TO COOKED",
        notes: x.notes,
      }
    });

    console.log("detailDifferentDataMap", detailDifferentDataMap);

    if(detailDifferentDataMap.length > 0) {
      detailDifferentDataMap.map((x) => {
        updateDetail.push(x)
      });
    };

    console.log("updateDetail", updateDetail);
    for (let i = 0; i < updateDetail.length; i++) {
      await axios
        .put(
          `${baseurl}/pos/order-detail`,
          {
            deleted_reason: updateDetail[i].deleted_reason, //Available
            notes: updateDetail[i].notes ?? null, //Available
            id: updateDetail[i].id,
            created_by: user_name,
            item_quantity: updateDetail[i].item_quantity,
            serving_order: updateDetail[i].serving_que,
            total_price: updateDetail[i].gross * updateDetail[i].item_quantity,
            tax: updateDetail[i].tax,
            service: updateDetail[i].service,
            is_tax: updateDetail[i].is_tax,
            is_service: updateDetail[i].is_service,
            gross: updateDetail[i].gross,
            add_ons: updateDetail[i].notes ?? null,
            order_step: 1,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("Update Detail: ", response);
            let successCount = i + 1;
            if(successCount == updateDetail.length) {
              handleDeleteDetail(deleteDetail, dataItem, printer);
            }
          } else {
            masterFailedNotification(response, {
              method: 1,
              source: `Kitchen Order Item`,
            });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 1,
            source: `Kitchen Order Item`,
          });
        })
    }
  };

  const handleDeleteDetail = async (e, dataItem, printer) => {
    let sales_type= dataItem.sales_type == 1 ? "Dine-In" : dataItem.sales_type == 2 ? "Take Away" : dataItem.sales_type == 3 ? "Online Order" : "No Data";

    let bodyOrder = {
      order_id_alias : dataItem.order_id_alias,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
      sales_type: sales_type,
      table_code : dataItem.table_code,
      printer_id: printer,
      item: dataItem.selectedItems,
    };

    if(e.length > 0){
      for (let i = 0; i < e.length; i++) {
        let obj =
          {
            id: e[i].id,
            order_id_alias: e[i].order_id_alias,
            deleted_by: user_name,
            deleted_reason: "OTHERS",
          };
        
        console.log("obj", obj)
        await axios
          .delete(`${baseurl}/pos/order-detail`, {
            data: obj,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("DEL POS Bill Detail >> ", response);
              let successCount = i + 1;
              if(successCount == e.length){
                masterSuccessNotification(response, {
                  method: 2,
                  source: `Kitchen Order Item`,
                });
              }
              // fetchTable();
            }
          })
          .catch((error) => {
            console.log("ERROR DEL POS Bill Detail >> ", error);
            masterFailedNotification(error, {
              method: 2,
              source: `Kitchen Order Item`,
            });
          });
      }
      console.log("bodyOrder1", bodyOrder);
      // socket.emit("message", bodyOrder);
      setBodyToPrint(bodyOrder);
      handleCancel();
      is_created();
    } else {
      masterSuccessNotification("", {
        method: 1,
        source: `Kitchen Order Item`,
      });
      console.log("bodyOrder2", bodyOrder);
      // socket.emit("message", bodyOrder);
      setBodyToPrint(bodyOrder);
      handleCancel();
      is_created();
    }
  };

  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={
          <>
            <Row className="cancel-order-modal-row">
              <AiOutlineInfoCircle
                style={{
                  color: "#f5222d",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Kitchen/Bar Process`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={500}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "0px 36px" }}
        >
          <Row
            className="cancel-order-modal-form-serve-row"
            //   justify="center"
            //   gutter={[30, 30]}
            style={{ margin: "0px 40px 10px 0px" }}
          >
            <Text>{`Are you sure want to process this article ?`}</Text>
          </Row>

          <Row
            className="serve-row"
            justify="center"
            style={{ margin: "0px 40px" }}
          >
            <Col span={24} className="serve-col">
              <Form.Item
                label="Kitchen/Bar"
                name={"kitchen_bar"}
                rules={[
                  {
                    required: true,
                    message: "Please, Select an Option!",
                  },
                ]}
              >
                <Select
                  //   defaultValue="CANCEL ORDER"
                  placeholder={"Select Option"}
                  //   style={{
                  //     width: 120,
                  //   }}
                  onChange={() => {}}
                  options={[
                    {
                      value: 1,
                      label: "Kitchen",
                    },
                    {
                      value: 3,
                      label: "Bar",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="cancel-order-modal-form-divider-form"
            style={{ margin: "0px 0px 10px 0px" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              onClick={(e) => {
                // selectedTable(tableKeys);
                // handleCancel();
              }}
              loading={loading}
              className="submit-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Yes`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`No`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
