// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import React Components
import {
  Button,
  Divider,
  Form,
  Modal,
  Row,
  Typography,
} from "antd";

// Import Notification
import {
  failedFetch,
} from "../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import React Icons
import { AiOutlineSave } from "react-icons/ai";
import TransferOrderTable from "../../../Table/Outlet/Order/TransferOrder/TableTransferOrder";

export default function ModalTransferDestination(props) {
  // PROPS
  const { openModal, closeModal, getClear, data, orderId } = props;

  // CONTEXT
  const { getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [destinationOrder, setDestinationOrder] = useState([]);
  const [guestData, setGuestData] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Modal
const { confirm } = Modal;

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
      fetchDataGuestInHouse();
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    console.log("destinationOrderId", destinationOrder)
  }, [destinationOrder])

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    closeModal(false);
    setLoading(false);

  };

  // FETCH DATA GUEST IN HOUSE
  const fetchDataGuestInHouse = async () => {
    await getGuestInHouse({
      guestInHouse: "guest-in-house",
      type: "stay",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setGuestData(filtered);
          // setBulks(filtered);
        } else {
          setGuestData([]);
          // setBulks([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
        failedFetch(error);
      },
    });
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    setOpen(false);
    form.resetFields();
    closeModal(false);
    setLoading(false);
    getClear();
  };

  const showConfirmModal = (value) => {

    console.log("tableKey showConfirmModal", value);

    confirm({
      className: "confirm",
      title: `Transfer Order Confirmation`,
      content: `Are you sure you want to Transfer Order to ${value.order_id_alias}?`,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleTransfer(value);
        Modal.destroyAll();
      },
      
      onCancel() {
        Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  const handleTransfer = (value) => {
    let transfer = data.filter((x) => {
      if(x.transfer_quantity > 0) {
        return true;
      } else {
        return false;
      }
    });

    console.log("transferData", transfer);
    getDataDestination(destinationOrder.order_id_alias, transfer);
    // handleCancel();
  };

  const getDataDestination = async (value, transfer) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        order_id_alias: value,
      })
      .then((response) => {
        console.log("Order Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.g_pos_article_detail.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            
            return {
              ...x,
              serving_que: x.serving_order,
            }
          });
          console.log("_resMap", _resMap);
          handleAddDestination(_resMap, transfer);
        } else {
          failedFetch(response);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
  };

  const handleAddDestination = async (value, transfer) => {
    let add = [];

    let checkAdd = transfer.filter((x) => {
      if(value.some((y) => x.article_id == y.article_id && x.serving_que == y.serving_que && x.order_step == y.order_step )) {
        return false
      } else {
        return true
      }
    });

    console.log("checkAdd", checkAdd);

    console.log("guestData", guestData);

    let filterGuestData = guestData.filter((x) => {
      if(x.reservation_id == destinationOrder?.reservation_id && x.guest_name == destinationOrder?.name){
        return true
      } else {
        return false
      }
    });

    console.log("filterGuestData: ", filterGuestData);

    let room_number = filterGuestData.length > 0 ? filterGuestData[0]?.room_number : null;

    console.log("room_number", room_number);

    for (let i = 0; i < checkAdd.length; i++) {
      add.push({
        id: checkAdd[i]?.article_id,
        article_id: checkAdd[i]?.article_id,
        article_sub_name: checkAdd[i]?.article_sub_name || null,
        article_sub_id: checkAdd[i]?.article_sub_id,
        article_name: checkAdd[i]?.article_name,
        unit_price: checkAdd[i]?.unit_price,
        item_quantity: checkAdd[i]?.item_quantity,
        total_price: checkAdd[i]?.gross * checkAdd[i]?.item_quantity,
        table_code: destinationOrder?.table_code,
        table_id: destinationOrder?.table_id,
        serving_order: checkAdd[i]?.serving_que,
        guest_name: destinationOrder?.name,
        chart_of_account: checkAdd[i]?.chart_of_account,
        tax: checkAdd[i]?.tax,
        service: checkAdd[i]?.service,
        is_tax: checkAdd[i].is_tax,
        is_service: checkAdd[i].is_service,
        gross: checkAdd[i]?.gross,
        add_ons: checkAdd[i].notes,
        notes: checkAdd[i]?.notes,
        payment_status: destinationOrder?.payment_status,
        order_id_alias: destinationOrder?.order_id_alias,
        header_id: destinationOrder?.id,
        created_by: user_name,
        order_step: checkAdd[i].order_step,
        source: 2,
        room_number: room_number,
      });
    }

    console.log("add", add);

    if(add.length > 0) {
      await axios
        .post(
          `${baseurl}/pos/order-detail`,
          {
            data: add.length > 0 ? add : null,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then((response) => {
          console.log("Add Detail: ", response);
          if (response.status === 200) {
              handleUpdateDestination(value, transfer);
          } else {
            masterFailedNotification(response, {
              method: 0,
              source: `Transfer Captain Order Item - Create Process`,
            });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 0,
            source: `Transfer Captain Order Item - Create Process`,
          });
        })
      } else {
      handleUpdateDestination(value, transfer);
    }
  };

  const handleUpdateDestination = async (value, transfer) => {
    let checkUpdate = value.filter((x) => {
      if(transfer.some((y) => x.article_id == y.article_id && x.serving_que == y.serving_que && x.order_step == y.order_step)) {
        return true
      } else {
        return false
      }
    });

    console.log("checkUpdate", checkUpdate);
    
    let quantityUpdate = checkUpdate.map((x) => {
      return {
        ...x,
        item_quantity: x.item_quantity + transfer.filter((y) => x.article_id == y.article_id && x.serving_que == y.serving_que && x.order_step == y.order_step)[0].transfer_quantity,
      }
    })
    
    console.log("quantityUpdate", quantityUpdate);

    if(quantityUpdate.length > 0) {
      for (let i = 0; i < quantityUpdate.length; i++) {
        await axios
            .put(
              `${baseurl}/pos/order-detail`,
              {
                deleted_reason: "TRANSFER ITEM",
                notes: quantityUpdate[i].notes,
                id: quantityUpdate[i].id,
                created_by: user_name,
                item_quantity: quantityUpdate[i].item_quantity,
                serving_order: quantityUpdate[i].serving_que,
                total_price: quantityUpdate[i].gross * quantityUpdate[i].item_quantity,
                tax: quantityUpdate[i].tax,
                service: quantityUpdate[i].service,
                is_tax: quantityUpdate[i].is_tax,
                is_service: quantityUpdate[i].is_service,
                gross: quantityUpdate[i].gross,
                add_ons: quantityUpdate[i].notes ?? null,
                order_step: quantityUpdate[i].order_step,
              },
              {
                headers: { Authorization: `Bearer ${token ? token : ""}` },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                console.log("Update Detail: ", response);
                let successCount = i + 1;
                if(successCount == quantityUpdate.length) {
                  handleSubstract(transfer);
                }
              } else {
                masterFailedNotification(response, {
                  method: 1,
                  source: `Transfer Captain Order Item  - Add Process`,
                });
              }
            })
            .catch((error) => {
              console.log("Error: ", error);
              masterFailedNotification(error, {
                method: 1,
                source: `Transfer Captain Order Item - Add Process`,
              });
            })
      }
    } else {
      handleSubstract(transfer);
    }
  };

  const handleSubstract = async (transfer) => {
    let checkSubstract = transfer.filter((x) => {
      if(x.transfer_quantity < x.item_quantity) {
        return true;
      } else {
        return false;
      }
    });
    
    let substract = checkSubstract.map((x) => {
      return {
        ...x,
        item_quantity: x.item_quantity - x.transfer_quantity,
      }
    });

    console.log("substract", substract);

    if(substract.length > 0) {
      for (let i = 0; i < substract.length; i++) {
        await axios
          .put(
            `${baseurl}/pos/order-detail`,
            {
              deleted_reason: "SUBSTRACT ITEM QUANTITY",
              notes: substract[i].notes,
              id: substract[i].id,
              created_by: user_name,
              item_quantity: substract[i].item_quantity,
              serving_order: substract[i].serving_que,
              total_price: substract[i].gross * substract[i].item_quantity,
              tax: substract[i].tax,
              service: substract[i].service,
              is_tax: substract[i].is_tax,
              is_service: substract[i].is_service,
              gross: substract[i].gross,
              add_ons: substract[i].notes ?? null,
              order_step: substract[i].order_step,
            },
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("Update Detail: ", response);
              let successCount = i + 1;
              if(successCount == substract.length) {
                handleDelete(transfer);
              }
            } else {
              masterFailedNotification(response, {
                method: 1,
                source: `Transfer Captain Order Item  - Substract Process`,
              });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            masterFailedNotification(error, {
              method: 1,
              source: `Transfer Captain Order Item - Substract Process`,
            });
          })
      }
    } else {
      handleDelete(transfer);
    }

    
  };

  const handleDelete = async (transfer) => {
    let checkDelete = transfer.filter((x) => {
      if(x.transfer_quantity == x.item_quantity) {
        return true;
      } else {
        return false;
      }
    });

    console.log("checkDelete", checkDelete);
    if(checkDelete.length > 0) {
      for (let i = 0; i < checkDelete.length; i++) {
        let obj =
          {
            id: checkDelete[i].id,
            order_id_alias: checkDelete[i].order_id_alias,
            deleted_by: user_name,
            deleted_reason: "DELETE FROM TRANSFER",
          };
        
        console.log("obj", obj);
        await axios
          .delete(`${baseurl}/pos/order-detail`, {
            data: obj,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("DEL POS Bill Detail >> ", response);
              let successCount = i + 1;
              if(successCount == checkDelete.length){
                masterSuccessNotification(response, {
                  method: 0,
                  source: `Transfer Captain Order Item`,
                });
              }
              // fetchTable();
            }
          })
          .catch((error) => {
            console.log("ERROR DEL POS Bill Detail >> ", error);
            masterFailedNotification(error, {
              method: 2,
              source: `Transfer Captain Order Item - Delete Item`,
            });
          });
      }
      handleRefresh();
    } else {
      masterSuccessNotification("", {
        method: 0,
        source: `Transfer Captain Order Item`,
      });
      handleRefresh();
    }

  }


  return (
    <>
      <Modal
        className="saved-bill-modal"
        title={
          <>
            <Row className="saved-bill-modal-row">
              <AiOutlineSave
                style={{
                  color: "#1BC5BD",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Select Transfer Destination`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <Row
          className="saved-bill-modal-form-serve-row"
          justify="start"
          gutter={[30, 30]}
          style={{ padding: 20 }}
        >
          <TransferOrderTable
            getSelectedHeader={(e) => {
              setDestinationOrder(e);
            }}
            orderId={orderId}
          />
        </Row>

        <Divider
          className="saved-bill-modal-form-divider-form"
          style={{ margin: "15px 0px" }}
        />

        <Row justify="end" align="middle">
          <Button
            type="primary"
            onClick={(e) => {
              showConfirmModal(destinationOrder);
            }}
            loading={loading}
            className="submit-btn"
            style={{
              marginRight: 30,
            }}
          >
            {`Submit`}
          </Button>

          <Button className="cancel-btn" type="default" onClick={handleCancel}>
            {`Cancel`}
          </Button>
        </Row>
      </Modal>
    </>
  );
}
