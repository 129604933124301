// Import React's Component
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Table,
  Button,
  Col,
  Row,
  Form,
  Typography,
  Card,
  Tag,
} from "antd";

// Import Notification
import {
  failedFetch,
} from "../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification,
} from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import KitchenProcessModal from "../../../Modals/Outlet/KitchenOrder/Modal";
import { FaUtensils } from "react-icons/fa";

// CODE
export default function KitchenDetailsTable({selectedData, is_created}) {

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataOrder, setDataOrder] = useState(null);

  // Selected Data & Keys
  const selectionType = 'checkbox';

  // Modal
  const [modalConfirm, setModalConfirm] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedItemsData, setSelectedItemsData] = useState([]);

  // USE FORM
  const [form] = Form.useForm();

  // USEEFFECT
  useEffect(() => {
    console.log("selectedRowKeys array", selectedRowKeys)
  }, [selectedRowKeys])
  
  useEffect(() => {
    console.log("selectedData on useEffect PerTable", selectedData);
    uncheckAll(selectedData);

    return () => {};
  }, [selectedData]);

  useEffect(() => {
    console.log("dataRefresh", data)
  },[data])

  const fetchOrder = async (record) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        // order_id_alias: tableKey?.order_id_alias,
        order_id_alias: record?.order_id_alias,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.g_pos_article_detail.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          });
          console.log("_resMap", _resMap)

          setData(_resMap);
          setDataOrder(response.data.msg[0]);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
  };

  // COLUMNS
  const columns = [
    {
      title: "Items",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Que No.",
      dataIndex: "serving_que",
      key: "serving_que",
      render: (text) => {
        let color = "#686868";
            
        return (
          <Tag color={color}>
            {text ? text : "-"}
          </Tag>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
      render: (text) => {
        return text;
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: "Status",
      dataIndex: "order_step",
      key: "order_step",
      // hidden: true,
      render: (text) => {
        let color = "";
        let txt = "";
        
        switch (text) {
          case 0:
            color = "#2db7f5";
            txt = "Ordered";
            break;
            case 1:
              color = "#108ee9";
            txt = "Cooked";
            break;
          case 2:
            color = "#87d068";
            txt = "Served";
            break;
            
            default:
              color = "#d9d9d9";
              txt = "-";
              break;
            }
            
            return (
              <Tag color={color}>
            {txt ? txt.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => {
        return (
          <Row justify="center">
            <FaUtensils
              disabled = {record.order_step == 1 ? false : true}
              className="served-btn"
              onClick={() => {
                if(record.order_step == 1) {
                  handleServed(record, selectedData)
                } else {
                  null
                }
              }}
              style={{
                fontSize: 20,
                color: record.order_step == 1 ? "#1BC5BD" : "#d9d9d9" ,
                cursor: record.order_step == 1 ? "pointer" : "not-allowed",
                justify: "center",
              }}
            />
          </Row>
        )
      },
    },
  ].filter(item => !item.hidden);

  // ON FINISH
  const onFinish = (value) => {
    console.log("onFinish", value);
  };
  
  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    console.log("onFinish", value);
  };

  const handleClear = () => {
    setData([]);
    setDataOrder(null);
    form.resetFields();
    is_created();
  };

  const handleRefresh = (value, isCreated) => {
    if(isCreated){
      is_created();
    }
    uncheckAll(value);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (key, record) => {
      setSelectedRowKeys(key);
      setSelectedItemsData(record);
    },
    getCheckboxProps: (record) => ({
      disabled: record.order_step !== 0,
      // Column configuration not to be checked
      article_name: record.article_name,
    }),
  };

  const uncheckAll = (value) => {
    setSelectedRowKeys([]);
    setSelectedItemsData([]);
    fetchOrder(value);
  };

  const getOtherItemStatus = (record) => {
    let filter = data.filter((x) => {
      if(x.id !== record.id) {
        return true
      } else {
        return false
      }
    });

    console.log("filter", filter)

    if(filter.every((x) => x.order_step == 2)){
      return true
    } else {
      return false
    }
  };

  const handleServed = async (record, header) => {
    console.log("data", data);
    console.log("record", record);
    let checkOtherItemStatus = getOtherItemStatus(record);
    console.log("checkOtherItemStatus", checkOtherItemStatus);
    let updateDetail = [];
    let deleteDetail = [];
    // //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Between Cooked and Served
    const dataFilterServed = data.filter((x) => {
      if(record.article_id == x.article_id && parseInt(record.serving_que) == parseInt(x.serving_que) && x.order_step == 2) {
        return true
      } else {
        return false
      }
    });

    const dataFilterServedMap = dataFilterServed.map((x) => {
      return {
        ...x,
        deleted_reason: "ADD QUANTITY",
        item_quantity: record?.item_quantity ? x.item_quantity + record.item_quantity : x.item_quantity,
        notes: x?.notes && record?.notes ? x.notes + "," + record.notes :
               (x?.notes == null || x?.notes == "undefined") && record?.notes  ? record?.notes :
               x?.notes && (record?.notes == null || record?.notes == "undefined") ? x.notes :
               null,
      }
    });

    const itemDeleteCooked = dataFilterServed.length > 0 ? [record] : [];

    if(dataFilterServedMap.length > 0) {
      dataFilterServedMap.map((x) => {
        updateDetail.push(x)
      });
    };

    if(itemDeleteCooked.length > 0) {
      itemDeleteCooked.map((x) => {
        deleteDetail.push(x)
      });
    };

    //----------------------------------------------------------------------------------------------------------------------------------------------------------------------- Other Conditions: Tidak ada data lain yg serupa
    const checkSimilarData = dataFilterServedMap.some(y => y.article_id === record.article_id && y.serving_que == record.serving_que && record.order_step == 1);

    const detailDifferentDataMap = [{
      ...record,
      deleted_reason: "CHANGE TO SERVED",
    }]

    console.log("detailDifferentDataMap", detailDifferentDataMap);

    if(detailDifferentDataMap.length > 0 && !checkSimilarData) {
      detailDifferentDataMap.map((x) => {
        updateDetail.push(x)
      });
    }
    
    console.log("updateDetail", updateDetail);
    console.log("deleteDetail", deleteDetail);

    let shouldRunFinally = true;

    await axios
        .put(
          `${baseurl}/pos/order-detail`,
          {
            deleted_reason: "SERVED", //Available
            notes: updateDetail[0].notes ?? null, //Available
            id: updateDetail[0].id,
            created_by: user_name,
            item_quantity: updateDetail[0].item_quantity,
            serving_order: updateDetail[0].serving_que,
            total_price: updateDetail[0].gross * updateDetail[0].item_quantity,
            tax: updateDetail[0].tax,
            service: updateDetail[0].service,
            is_tax: updateDetail[0].is_tax,
            is_service: updateDetail[0].is_service,
            gross: updateDetail[0].gross,
            add_ons: updateDetail[0].notes ?? null,
            order_step: 2,
          },
          {
            headers: { Authorization: `Bearer ${token ? token : ""}` },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            console.log("Update Detail: ", response);
            if(deleteDetail.length > 0){
              shouldRunFinally = false;
              handleDeleteDetail(deleteDetail[0], header, checkOtherItemStatus);
            } else {
              if(checkOtherItemStatus){
                shouldRunFinally = false;
                // setTimeout(() => {
                  handleKitchenStatus(header);
                // }, 1000);
              } else {
                masterSuccessNotification(response, {
                  method: 1,
                  source: `Kitchen Order Item`,
                })
              }
            }
          } else {
            masterFailedNotification(response, {
              method: 1,
              source: `Kitchen Order Item`,
            });
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, {
            method: 2,
            source: `Kitchen Order Item`,
          });
        })
        .finally(() => {
          if(shouldRunFinally){
            console.log("shoudRunFinally yes")
            console.log("selectedData")
            handleRefresh(selectedData, false)
          }
        })
  }

  const handleKitchenStatus = async (header) => {
    const obj = {
      id: header?.id,
      name: header?.name,
      table_code: header?.table_code,
      table_id: header?.table_id,
      payment_type: header?.payment_type,
      payment_details: header?.payment_details,
      payment_status: header?.payment_status,
      total_nett: header?.total_nett,
      total_gross: header?.total_gross,
      total_tax: header?.total_tax,
      total_service: header?.total_service,
      is_open_bill: header?.is_open_bill,
      sales_type: header?.sales_type,
      updated_by: user_name,
      old_table_code: header?.table_code,
      old_table_id: header?.table_id,
      reservation_id: header?.reservation_id,
      pax: header?.pax,
      order_status: false,
      kitchen_status: 2, // Variable yg diupdate
      segment: header?.segment,
      source: header?.source,
      room_number: header?.room_number,
    };
    console.log("handleUpdate > obj:", obj);

    await axios
      .put(
        `${baseurl}/pos/order-header`, obj,
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )

      .then(async (response) => {
        if (response.status === 200) {
          console.log("Edit Header: ", response);
          masterSuccessNotification(response, {
            method: 1,
            source: `Kitchen Order Item`,
          });
        } else {
          console.log("handleKitchenStatus failed 1", response);
          masterFailedNotification(response, {
            method: 1,
            source: `Kitchen Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Errorz: ", error);
        masterFailedNotification(error, {
          method: 1,
          source: `Kitchen Order`,
        });
      })
      .finally(() => handleRefresh(selectedData, true))
      // .finally(() => handleRefresh(selectedData))
  }

  const handleDeleteDetail = async (deleteDetail, header, checkOtherItemStatus) => {

    let obj =
      {
        id: deleteDetail.id,
        order_id_alias: deleteDetail.order_id_alias,
        deleted_by: user_name,
        deleted_reason: "OTHERS",
      };
    
    console.log("obj", obj)
    await axios
      .delete(`${baseurl}/pos/order-detail`, {
        data: obj,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if(checkOtherItemStatus){
          // setTimeout(() => {
            handleKitchenStatus(header);
          // }, 1000);
        } else {
          masterSuccessNotification(response, {
            method: 1,
            source: `Kitchen Order Item`,
          })
          handleRefresh(selectedData, false);
        }
      })
      .catch((error) => {
        console.log("ERROR DEL POS Bill Detail >> ", error);
        masterFailedNotification(error, {
          method: 2,
          source: `Kitchen Order Item`,
        });
      })
  };

  return (
    <>
      <Row className="row-pertable" style={mainBody}>
        <Col
          className="table-col"
          span={24}
          style={{ width: "100%", padding: "0px 30px 30px" }}
        >
          <Card
            className="table-card"
            bordered
            // loading={cardLoad}
            style={{
              width: "100%",
              // marginTop: 16,
            }}
            title={
              <Row justify="center" align="middle" className="title-row">
                <Col span={24} className="title-col">
                  <Typography
                    className="bill-txt"
                    key={"txt-bill"}
                    style={{ fontSize: 20 }}
                  >
                    {selectedData?.order_id_alias || dataOrder?.order_id_alias || "---"}
                  </Typography>
                </Col>
              </Row>
            }
            headStyle={{
              textAlign: "center",
              background: "#e4e6ef",
            }}
            bodyStyle={{
              textAlign: "center",
              display: selectedData?.table_id ?  "block" : "none",
            }}
          >
            <Typography
              className="bill-txt"
              key={"txt-bill"}
              style={{ fontSize: 20, fontWeight: 500 }}
            >
              {selectedData?.table_id || "No Table"}
            </Typography>
          </Card>

          <Form
            form={form}
            className="order-form"
            key="order-form"
            name="order-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            style={{
              padding: "15px 0px 0px",
              margin: 0,
            }}
          >
            <Form.Item
              name="article_list"
              label=""
              rules={[
                {
                  validator: async (keys) => {
                    form.setFieldsValue({
                      article_list: data,
                    });

                    if (data.length === 0) {
                      return Promise.reject("Please, Select a Menu!");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Table
                className="ordertable-tbl"
                name="ordertable-tbl"
                key="ordertable=tbl"
                columns={columns}
                dataSource={data}
                // dataSource={dataDetails}
                bordered
                size="medium"
                pagination={false}
                scroll={{
                  x: true,
                }}
                // rowKey={(record) => record?.article_id}
                rowKey={(record) => record?.key}
                rowClassName={(_, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                expandable={{
                  columnTitle: "Notes",
                  expandedRowRender: (record) => (
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      {record.notes ? record.notes : "-"}
                    </p>
                  ),
                }}
              />
            </Form.Item>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row className="row-modal-btn" justify="end" align="middle">
              <Button
                onClick={(e) => {
                  setModalConfirm(true);
                }}
                disabled={selectedRowKeys.length > 0 ? false : true}
                type="primary"
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Submit to Process`}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>

      <KitchenProcessModal
        openModal={modalConfirm}
        closeModal={setModalConfirm}
        data={{
          ...selectedData,
          selectedItems: selectedItemsData.map((x) => {
            return {
              ...x,
              notes: x.notes ? x.notes : "",
            }
          }),
        }}
        allDetails={data}
        is_created={handleClear}
      />
    </>
  );
}
