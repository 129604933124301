// Import React Components
import React, { useState, useEffect, useContext } from "react";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Divider, Select, Space, Button, Row } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// Import React Icons

// CODE
export default function MasterRoomNumberCkIn(props) {
  // PROPS
  const {
    // Get Room Number
    getRoomNumber,
    leaderRoom,
    memberRoom,
    room_number,
    // Room Category
    roomCode,
    // Set Chart of Account
    existed_coa,
    // ROOM INFO
    roomInfo,
    // Is Group
    isGroup,
    name,
    // Disabled
    disable,
    isClose,
    isClosed,
  } = props;

  // CONTEXT
  const { getMasterRoom } = useContext(MasterContext);
  const { getRoomStatus } = useContext(MainContext);

  // STATE MANAGEMENT
  const [room, setRoom] = useState(isGroup ? [] : null);
  const [optionValue, setOptionValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [isSetLead, setIsSetLead] = useState(0);
  const [isSetMember, setIsSetMember] = useState([]);
  // Loadings
  const [isLoading, setIsLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await getMasterRoom({
      main: "main-arrival",
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Room Status => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt?.room_status_id == 2) {
              return !filt.is_deleted;
            }
          });

          let filt = filtered.filter((dev) => {
            if (roomCode) {
              return dev.room_category_id == roomCode;
            } else {
              return dev;
            }
          });

          // console.log("Filter by Room Status: ", filtered);
          // console.log("Filter by Room Code: ", filt);
          // console.log("Room Cat ID: ", roomCode);

          let option = filt.map((dev) => ({
            value: dev?.room_id,
            label: dev?.room_id,
            code: dev?.room_category_code,
            room: dev?.room_number,
            room_category_name: dev?.room_category_name,
            category_id: dev?.room_category_id,
            id: dev?.id,
            coa: dev?.chart_of_account,
            info: {
              label: dev?.room_id,
              number: dev?.room_number,
              status: dev?.room_status_id,
              occupants: dev?.guest_name,
              updated_date: dev?.updated_date,
              occ_status: dev?.occupancy_status || false,
              room_type: dev?.room_bedsetup,
              room_overlook: dev?.room_overlook,
              is_connecting: dev?.connecting_room == 1 ? true : false,
              is_smoking: dev?.smoking == 1 ? true : false,
              floor: dev?.room_floor > 0 ? dev.room_floor : 0,
            },
          }));

          console.log("Option Value; ", option);

          setOptionValue(option);
          setOptions(option);

          if (isSetLead > 0) {
            handleRoom(isSetLead);
          }
        } else {
          setOptionValue([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setIsLoading(false);
      },
    });
  };

  // Room Number Occupancy
  const fetchOccupy = async () => {
    await getRoomStatus({
      status: "status",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("OCCUPANCY => ", response);

        let _response = response?.data?.msg;
        let filtered = _response.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            if (filt.room_status_id == 2) {
              return filt;
            }
          }
        });

        let filt = filtered.filter((dev) => {
          if (roomCode) {
            if (dev.room_category_id == roomCode) {
              return dev;
            }
          } else return dev;
        });

        // console.log("OCC by Cat --> ", filt);

        let option = filt.map((dev) => ({
          value: dev.room_id,
          label: dev.room_id,
          number: dev.room_number,
        }));

        // console.log("OCC --> ", option);
        setOptions(option);
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (roomCode > 0 && !cleanUp) {
      // console.log("THIS IS HELL: ", roomCode);
      fetchData();
      setRoom(isGroup ? [] : null);
      // fetchOccupy();
      // console.log("Room Cat => ", roomCode);

      //   getChartOfAccount(room_number);
    } else {
      setOptionValue([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [roomCode]);

  // Get COA
  useEffect(() => {
    let cleanUp = false;

    if (room_number && optionValue?.length > 0 && !cleanUp) {
      // console.log("THIS IS HELL ALSO: ", room_number);

      let chart = optionValue.filter((coa) => {
        // console.log(coa);
        if (isGroup == false) {
          // console.log("Selected ", coa);
          return coa?.coa;
        } else {
          return coa?.coa;
        }
      });

      console.log("Charts: ", chart);

      existed_coa(chart[0]?.coa);
    }

    return () => {
      cleanUp = true;
    };
  }, [room_number, optionValue]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    console.log("IS CLOSE: ", isClose, optionValue);
    console.log("Option Value: ", optionValue);
    console.log("Options: ", options);

    if (isClose == true && !cleanUp) {
      console.log("IS CLOSE: ", isClose, optionValue);

      setOptionValue([]);
      setOptions([]);
      fetchData();

      setIsSetLead(0);
      setIsSetMember([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [isClose]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (leaderRoom > 0 && options.length > 0 && isGroup && !cleanUp) {
      console.log("Room Leaders: ", leaderRoom, isSetLead);

      if (leaderRoom != isSetLead) {
        setIsSetLead(leaderRoom);
        handleRoom(leaderRoom);
      }
    } else {
      setIsSetLead(0);

      console.log("RESET LEADERS! ", leaderRoom);

      if (typeof room == "object") {
        handleClear();
      }
    }

    if (memberRoom?.length > 0 && options.length > 0 && !isGroup && !cleanUp) {
      console.log("Room Members: ", memberRoom, isSetMember);
      setIsSetMember(memberRoom);
      handleRoomMember(memberRoom);
    } else {
      setIsSetMember([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [leaderRoom, options, memberRoom]);

  // Handle Room Leaders
  const handleRoom = (value) => {
    const _newOptions = options.filter((items) => {
      return items.room != value;
    });

    console.log("Room for Leaders: ", value);
    setOptionValue(_newOptions);
    handleClear();
  };

  // HAndle Room Member
  const handleRoomMember = (value) => {
    console.log("Value: ", value);

    const _newOptions = options.filter((items) => {
      return !value.includes(items.room);
    });

    console.log("Room for Members: ", value);
    setOptionValue(_newOptions);
  };

  // HANDLE NUMBER
  const handleNumber = async (opt, no) => {
    const option = opt;
    const num = no;
    // Arr
    const _arr = [];
    const _roomNum = [];
    const _val = [];

    console.log(option, num);

    let merged = option.filter((val) => {
      // console.log(option.find((x) => x.number == val));

      return _arr.push(
        optionValue.find((x) => x.number == val || x.value == val)
      );
    });

    // console.log("Find Room: ", _arr);

    // if (_arr.length > 0) {
    for (let i = 0; i < _arr?.length; i++) {
      _roomNum.push(_arr[i]?.number);
      _val.push(_arr[i]?.value);
    }
    // }

    console.log("Filter COA: ", _arr);

    // if (_val.length > 0 && _roomNum.length > 0)
    getRoomNumber(name, _val, _roomNum);
    existed_coa(_arr[0]?.coa);
    // existedRoomNumber(name, _arr);
  };

  // Handle Changes
  const handleChanges = (value) => {
    const _name = value?.names;
    const _allKeys = value?.allKeys;
    const _info = isGroup
      ? _allKeys[_allKeys?.length - 1]?.info
      : _allKeys?.info;

    const _allNumbers =
      _allKeys?.length > 0
        ? _allKeys.map(({ room }) => {
            return room;
          })
        : _allKeys?.room;

    const _allID =
      _allKeys?.length > 0
        ? _allKeys.map(({ id }) => {
            return id;
          })
        : _allKeys?.id;

    const _allCoa =
      _allKeys?.length > 0
        ? _allKeys.map(({ coa }) => {
            return coa;
          })
        : _allKeys?.coa;

    // console.log(_name);
    console.log("Option All: ", _allKeys);

    // getRoomNumber(e, opt.number, index);
    setRoom(_name);

    let roomNum = [];

    getRoomNumber({
      form_index: name,
      number: _allNumbers,
      room_id: _name,
      id: _allID,
      info: _info,
      category_id: isGroup ? _allKeys[0]?.category_id : _allKeys?.category_id,
      coa: _allCoa,
    });
    console.log("Room Number = ", _name);

    console.log("Selected Room --> ", _allKeys);

    roomInfo(_info);
  };

  // Handle Clear
  const handleClear = () => {
    setRoom(isGroup ? [] : null);
  };

  // Handle Refresh
  const handleRefresh = () => {
    fetchData();
    setRoom(isGroup ? [] : null);
  };

  return (
    <>
      <Select
        className="room-select"
        key={"checkin-room-number"}
        placeholder="Choose Room Number!"
        showSearch
        allowClear
        disabled={disable}
        loading={isLoading}
        mode={isGroup ? "multiple" : ""}
        value={room}
        onChange={(e, opt, index) => {
          handleChanges({
            names: e,
            allKeys: opt,
          });
        }}
        onClear={handleClear}
        options={optionValue}
        style={{ width: "100%" }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "5px 0",
                background: "#EBEDF3",
              }}
            />

            <Row
              justify="end"
              align="middle"
              key={"rows-key"}
              style={{
                padding: "0px 5px",
              }}
            >
              <Button
                type={"default"}
                icon={<SyncOutlined />}
                onClick={handleRefresh}
              >
                {`Refresh`}
              </Button>
            </Row>
          </>
        )}
      />
    </>
  );
}
