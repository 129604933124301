// Import React Components
import React, { useState } from "react";

// Import Ant Design Components
import {
  Col,
  Row,
  Layout,
  Divider,
  Input,
  DatePicker,
  Typography,
  Button,
  Space,
  Form,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { SearchOutlined } from "@ant-design/icons";
import { FaPlaneDeparture } from "react-icons/fa";

// Import Page Components
import ExpectedDepartureTable from "../../../Components/Table/FrontOffice/ExpectedDeparture/Table";
import MasterSegment from "../../../Components/Reusable/Master/Segment/Segment";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";

export default function ExpectedDeparturePage() {
  // STATE MANAGEMENT
  const [value, setValue] = useState(null);
  const [resId, setResId] = useState(null);
  // Search
  const [search, setSearch] = useState();

  // GET SEGMENT
  const getSegment = async (value, key) => {
    console.log(value, key);
  };

  // HANDLE SEARCH
  const handleSearch = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleSearchId = (e) => {
    e.preventDefault();
    setResId(e.target.value);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="expected-arrival-main-layout">
          <Row
            className="row-title"
            justify="start"
            align="top"
            style={cardHeader}
          >
            <FaPlaneDeparture
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />

            <Typography.Text
              ellipsis={true}
              style={{
                margin: "22px 0px",
                fontSize: 17,
                fontWeight: 500,
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {`Expected Departure List`}
            </Typography.Text>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: 30,
              }}
            >
              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Reservation Name`}
                </Typography>

                <Input
                  allowClear
                  key={"input-name"}
                  placeholder="Guest's Name"
                  onChange={handleSearch}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>
                  {`Reservation ID`}
                </Typography>

                <Input
                  allowClear
                  key={"input-id"}
                  placeholder="Reservation Number"
                  onChange={handleSearchId}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Col>

              <Col
                className="col-search-btn"
                style={{
                  // margin: "54px 0px 0px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Button
                  className="search-btn"
                  type="primary"
                  onClick={search}
                  icon={<SearchOutlined />}
                >
                  {`Search`}
                </Button>
              </Col>
            </Space>
          </Row>

          <ExpectedDepartureTable
            is_search={setSearch}
            searchKey={value}
            searchId={resId}
          />
        </Col>
      </Content>
    </Layout>
  );
}
