// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";
import { token } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Steps,
  Progress,
  Alert,
  Badge,
  Tag,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Master Components
// Functions
import { CurrencySymbols } from "../../../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../../../Reusable/Functions/Parameters/ParamsFO";
import ParametersFrontOffice from "../../../../Reusable/Parameters/ParamsFO";

// Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CONST
const { Text, Link, Title } = Typography;

// CODE
export default function OutstandingAuditTable(props) {
  // PROPS
  const { dataBillOut, dateAudit, is_refresh, is_reset } = props;

  // Params
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // COLUMN
  const columns = [
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
      // fixed: "left",
      width: 150,
      render: (res, record, index) => {
        return res ? res.toUpperCase() : res;
      },
      sorter: (a, b) => a?.reservation_id.localeCompare(b?.reservation_id),
    },
    // {
    //   title: "Reservation No.",
    //   dataIndex: "reservation_id",
    //   key: "reservation_id",
    //   fixed: "left",
    //   width: 150,
    //   render: (res, record, index) => {
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = res;
    //         break;

    //       case 2:
    //         txtName = null;
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName ? txtName.toUpperCase() : txtName;
    //   },
    // },
    // {
    //   title: "Name",
    //   dataIndex: "guest_name",
    //   key: "guest_name",
    //   width: 175,
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.guest_name.length - b.guest_name.length,
    //   render: (name, record, index) => {
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = name;
    //         break;

    //       case 2:
    //         txtName = null;
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName ? txtName.toUpperCase() : txtName;
    //   },
    // },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
      width: 150,
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
      align: "center",
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Floor",
      dataIndex: "room_floor",
      key: "room_floor",
      align: "center",
      width: 125,
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      align: "center",
      // width: 175,
    },
    {
      title: "Child",
      dataIndex: "child",
      key: "child",
      align: "center",
      // width: 175,
    },
    {
      title: "Infant",
      dataIndex: "infant",
      key: "infant",
      align: "center",
      // width: 175,
    },

    {
      title: "Check Status",
      dataIndex: "room_status_id",
      key: "room_status_id",
      // width: 250,
      render: (room_status, record) => {
        let status = "";
        let clr = "";

        switch (room_status) {
          case 1:
            status = "Vacant Clean Unchecked";
            clr = "#1890ff";
            break;
          case 2:
            status = "Vacant Clean Checked";
            clr = "#87d068";
            break;
          case 3:
            status = "Occupied Dirty";
            clr = "#f5222d";
            break;
          case 4:
            status = "Occupied Clean";
            clr = "#13c2c2";
            break;
          case 5:
            status = "Vacant Dirty";
            clr = "#fa8c16";
            break;
          case 6:
            status = "Out of Order";
            clr = "black";
            break;
          case 7:
            status = "Out of Service";
            clr = "brown";
            break;
          case 8:
            status = "Occupied (No Luggage)";
            clr = "#fa541c";
            break;
          case 9:
            status = "NO SHOW";
            clr = "#135200";
            break;
          case 10:
            status = "OFF MARKET";
            clr = "#722ed1";
            break;

          default:
            status = "UNKNOWN";
            clr = "grey";
            break;
        }

        return (
          <Tag color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Occupancy Status",
      dataIndex: "occupancy_status",
      key: "occupancy_status",
      width: 150,
      render: (status) => {
        let stat = "";

        if (status) return "OCCUPIED";

        return "NON OCCUPIED";
      },
    },
    // {
    //   title: "Article / Room",
    //   dataIndex: "article_name",
    //   key: "article_name",
    //   width: 175,
    //   render: (name, record, index) => {
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = null;
    //         break;

    //       case 2:
    //         txtName = name;
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName ? txtName.toUpperCase() : txtName;
    //   },
    // },
    // {
    //   title: "Arrival",
    //   dataIndex: "arrival",
    //   key: "arrival",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.arrival - b.arrival,
    //   render: (date, record) => {
    //     // return moment(date).format("DD-MM-YYYY");
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = date;
    //         break;

    //       case 2:
    //         txtName = null;
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName ? moment(txtName).format("DD-MM-YYYY") : txtName;
    //   },
    // },
    // {
    //   title: "Departure",
    //   dataIndex: "departure",
    //   key: "departure",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.departure - b.departure,
    //   render: (date, record) => {
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = date;
    //         break;

    //       case 2:
    //         txtName = null;
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName ? moment(txtName).format("DD-MM-YYYY") : txtName;
    //   },
    // },
    // {
    //   title: "Rate Code",
    //   dataIndex: "rate_code",
    //   key: "rate_code",
    //   render: (res, record, index) => {
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = res;
    //         break;

    //       case 2:
    //         txtName = null;
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName ? txtName.toUpperCase() : txtName;
    //   },
    // },
    // {
    //   title: "Deposit",
    //   dataIndex: "initial_balance",
    //   key: "initial_balance",
    //   // sortDirections: ["descend", "ascend"],
    //   // sorter: (a, b) => a.deposit - b.deposit,
    //   render: (balance, record) => {
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = null;
    //         break;

    //       case 2:
    //         txtName = `${balance || 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName;
    //   },
    // },
    // {
    //   title: "Credit",
    //   dataIndex: "credit",
    //   key: "credit",
    //   render: (balance, record) => {
    //     let txtName = "";

    //     switch (record?.type_data) {
    //       case 1:
    //         txtName = null;
    //         break;

    //       case 2:
    //         txtName = `${balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //         break;

    //       default:
    //         break;
    //     }

    //     return txtName;
    //   },
    // },
    // {
    //   title: "Debts",
    //   dataIndex: "debit",
    //   key: "debit",
    //   render: (balance) => {
    //     let _bal = balance > 0 ? balance : 0;

    //     return `${_bal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   },
    // },
    // {
    //   title: "Account Balance",
    //   dataIndex: "account_balance",
    //   key: "account_balance",
    //   render: (balance) => {
    //     let _bal = balance ? balance : 0;

    //     return `${_bal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   },
    // },
  ];

  // Nested Tables
  const nestedTable = (value) => {
    // Props from Parents
    const _parents = value;

    // Nested Columns
    const columns = [
      {
        title: "No",
        dataIndex: "",
        key: "rowIndex",
        render: (_, record, index) => {
          return index + 1;
        },
      },
      {
        title: "Article / Room",
        dataIndex: "article_name",
        key: "article_name",
        render: (name, record, index) => {
          // if (record?.reservation_id === _parents?.reservation_id)
          return name.toUpperCase();
        },
      },
      {
        title: "Initial Balance",
        dataIndex: "initial_balance",
        key: "initial_balance",
        render: (balance) => {
          let _bal = balance > 0 ? balance : 0;

          return `${_bal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        title: "Credit",
        dataIndex: "credit",
        key: "credit",
        render: (balance) => {
          let _bal = balance > 0 ? balance : 0;

          return `${_bal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        title: "Debts",
        dataIndex: "debit",
        key: "debit",
        render: (balance) => {
          let _bal = balance > 0 ? balance : 0;

          return `${_bal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
      {
        title: "Balance",
        dataIndex: "account_balance",
        key: "account_balance",
        render: (balance) => {
          let _bal = balance > 0 ? balance : 0;

          return `${_bal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
      },
    ];

    console.log("Record Nested: ", _parents);
    // console.log("Index Nested: ", index);
    // console.log("Indent Nested: ", indent);
    // console.log("Expanded Nested: ", expanded);

    return (
      <Table
        className="details-count-sheet"
        bordered
        // components={}
        columns={columns}
        dataSource={nestData}
        pagination={false}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? "odd" : "even";
        }}
        rowKey={(record) => (record.id ? record.id : record.key)}
        key={_parents?.reservation_id}
      />
    );
  };

  // CONTEXT
  const { getNightAudit } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [nestData, setNestData] = useState([]);
  const [date_audit, setDate_Audit] = useState(null);
  // Loads
  const [loading, setLoading] = useState(false);

  // Fetch Data Night Audit Room Rate
  const fetchData = async () => {
    setLoading(true);

    await getNightAudit({
      type: "room-count-sheet",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          // handleFilter(_response);
          setData(_response);
          dataBillOut(_response);
        } else {
          setData([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // Fetch Data Re-Night Audit
  const fetchReAudit = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseurl}/re-night-audit/room-count-sheet`,
        {
          date: moment(dateAudit).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          setData(_response);
          dataBillOut(_response);
        } else {
          dataBillOut([]);
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dateAudit != date_audit && !cleanUp) {
      setDate_Audit(dateAudit);
      fetchReAudit();
    } else {
      if (date_audit) {
        setData([]);
        dataBillOut([]);
      } else {
        fetchData();
      }
    }

    if (is_refresh && !cleanUp) {
      fetchData();
      is_reset(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [dateAudit, is_refresh]);

  // HANDLE ID
  const handleFilter = (val) => {
    const _arr = val;
    let _id = 1;

    // Bucket Arrays
    let _head = [];
    let _details = [];

    let _filtered = _arr.filter((val) => {
      switch (val?.type_data) {
        case 1:
          _head.push(val);
          break;

        case 2:
          _details.push(val);
          break;

        default:
          break;
      }
    });

    let _hd = _head.map((value) => ({
      ...value,
      id: _id++,
    }));

    let _det = _details.map((value) => ({
      ...value,
      id: _id++,
    }));

    console.log("_Filtered: ", _filtered);
    console.log("Heads: ", _hd);
    console.log("Details: ", _det);

    const _result = [];

    // Create Childrens
    const result = _head.forEach((valueHead) => {
      console.log("Head: ", valueHead);
      let _nestTable = [];

      const _childrenOfHead = _det.filter((value) => {
        if (valueHead?.reservation_id === value?.reservation_id) {
          return _nestTable.push(value);
        }
      });

      console.log("Arr Children: ", _childrenOfHead);
      return _result.push({ ...valueHead, children: _childrenOfHead });
    });

    console.log("RESULTS Child: ", result);
    console.log("RESULTS FINALE: ", _result);

    setData(_result);
    setNestData(_det);
    dataBillOut(_result);
  };

  return (
    <>
      <Row style={{ margin: "30px 0px 0px", width: "100%" }}>
        <Table
          className="na-billoutstanding-table"
          key="na-billoutstanding-table"
          name="na-billoutstanding-table"
          loading={loading}
          columns={columns}
          dataSource={data}
          size="small"
          bordered
          pagination={false}
          scroll={{
            x: true,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => (record.id ? record.id : record.reservation_id)}
        />
      </Row>

      <Row justify="end" style={{ padding: "5px 30px" }}>
        <Typography style={{ color: "#000000", fontWeight: 750 }}>
          {`Showing total of ${data?.length || 0} Data (Entries)`}
        </Typography>
      </Row>
    </>
  );
}
