// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../../API/Global/Payload";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Typography,
} from "antd";

// Import React Icons
import { PlusCircleTwoTone, PlusOutlined } from "@ant-design/icons";

// Import Page Components
import AddArticleTable from "../../../Table/FrontOffice/Article/Table";
import MasterPosting from "../../../Reusable/Master/Posting/PostingType";
import MasterArticleSub from "../../../Reusable/Master/ArticleSub/ArticleSub";
import MasterArticleList from "../../../Reusable/Master/ArticleList/ArticleList";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notification
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";
import {
  failedArticleStay,
  failedFetch,
  successArticleStay,
} from "../../../Reusable/Notification/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ModalArticle(props) {
  // PROPS
  const { openModal, closeModal, dataGuest } = props;

  const [lastKey, setLastKey] = useState(0);

  // CONTEXT
  const { getArticleStay, getMasterBillTrx } = useContext(MainContext);

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataEdit, setDataEdit] = useState(null);
  // Data Article Table
  const [articleTable, setArticleTable] = useState([]);
  // Master Article Name
  const [subGroup, setSubGroup] = useState({
    article_sub_name: "",
    article_sub_id: 0,
  });
  const [article, setArticle] = useState({
    article_name: "",
    article_id: 0,
    code: 0,
    dept_id: 0,
    sub_id: 0,
    main_id: 0,
    coa: "",
  });
  const [articlePrice, setArticlePrice] = useState({
    nett: 0,
    service: 0,
    tax: 0,
    gross: 0,
  });
  const [key, setKey] = useState(0);
  // Modal
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Reset Row Selection & Refresh
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState();

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setData(dataGuest);
      setOpen(!open);

      fetchData();

      // Set Res ID and Guest Name
      setFormHeaders(dataGuest);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // USE EFFECT EDIT
  useEffect(() => {
    // console.log("Data Edit == ", dataEdit);
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // FETCH DATA
  const fetchData = async () => {
    setLoading(true);

    await getArticleStay({
      article_stay: "article-res-stay",
      res_id: dataGuest?.reservation_id
        ? dataGuest.reservation_id
        : data?.reservation_id,
      prc_id: dataGuest?.price_id >= 0 ? dataGuest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response >> ", response);

        if (response?.data?.length > 0) {
          const filtered = response.data.filter((val) => {
            if (val.is_deleted == null || val.is_deleted == false) {
              return val;
            }
          });

          console.log("Data Article: ", filtered);

          setArticleTable(filtered);
        } else {
          setArticleTable([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error >> ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // SET FIELDS HEADER
  const setFormHeaders = (val) => {
    const data = val;

    console.log("Header Forms --> ", data);

    form.setFieldsValue({
      reservation_id: data?.reservation_id ? data.reservation_id : null,
      guest_name:
        data?.guest_category == "INCOGNITO" ? "PRIVATE" : data.guest_name,
      room_number: data?.room_number > 0 ? data.room_number : null,
    });
  };

  // SET FIELD FORMS
  const setFormFields = (val) => {
    const data = val;

    console.log("Field Forms --> ", data);

    form.setFieldsValue({
      article_sub_name: data?.article_sub_name ? data?.article_sub_name : null,
      article_name: data?.article_name ? data?.article_name : null,
      article_id: data?.article_id > 0 ? data.article_id : null,
      qty: data?.item_quantity ? data?.item_quantity : data?.qty,
      posting_type: data?.posting_type ? data?.posting_type : null,
      total_posting: data?.total_posting ? data?.total_posting : null,
      chart_of_account: data?.chart_of_account ? data?.chart_of_account : null,
      date_periodic:
        data?.start_date != null && data?.end_date != null
          ? [moment(data?.start_date), moment(data?.end_date)]
          : [],
      unit_price: data?.gross > 0 ? data?.gross : null,
      gross: data?.gross,
      nett: data?.unit_price,
      tax: data?.tax,
      service: data?.service,
      order_date: data?.order_date ? moment(data?.order_date) : null,
    });
  };

  // Article Sub
  const getArticleSub = (value) => {
    console.log(value);

    if (edit == true) {
      dataEdit.article_sub_name = value?.name;
      dataEdit.article_name = null;
      dataEdit.article_id = null;

      form.setFieldsValue({
        article_name: null,
      });
    }

    setSubGroup({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });

    form.setFieldsValue({
      article_sub_name: value?.name,
      article_sub_id: value?.sub_id,
    });
  };

  // Article List
  const getArticle = (value) => {
    console.log("Get Article: ", value);

    setArticle({
      article_id: value?.article_id,
      article_name: value?.article_name,
      code: value?.code,
      dept_id: value?.dept_id,
      main_id: value?.main_id,
      sub_id: value?.sub_id,
      coa: value?.coa,
    });

    setArticlePrice({
      gross: value?.gross,
      service: value?.service,
      tax: value?.tax,
      nett: value?.price,
    });

    if (edit == true) {
      dataEdit.article_name = value?.article_name;
      dataEdit.chart_of_account = value?.coa;
      dataEdit.article_sub_group_id = value?.sub_id;
      dataEdit.unit_price = value?.price;
    }

    form.setFieldsValue({
      article_name: value?.article_name,
      article_id: value?.article_id,
      chart_of_account: value?.coa,
      unit_price: value?.gross,
      service: value?.service,
      tax: value?.tax,
      nett: value?.price,
      qty: 1,
      total_posting: 1,
    });
  };

  // Handle Price
  const handlePrice = (val) => {
    const _qty = val;
    const _price = edit ? dataEdit?.gross : articlePrice?.gross;

    let total = _qty * _price;

    console.log("Totals: ", total, _price);
    form.setFieldValue("unit_price", total);
    // form.setFieldsValue({
    //   unit_price: total,
    // });
  };

  // Posting Type
  const getPostType = (value) => {
    // setPostingType(value);

    form.setFieldsValue({
      posting_type: value,
    });
  };

  // Handle Edit
  const handleEdit = (val) => {
    const _article = val;

    console.log("Edit: ", _article);

    setDataEdit(_article);
    setFormFields(_article);

    setSubGroup({
      article_sub_id: _article?.article_sub_id,
      article_sub_name: _article?.article_sub_name,
    });
  };

  // HANDLE ADD ARTICLE on FINISH
  const onFinish = async (e) => {
    const newArticle = form.getFieldsValue({});
    setLoading(true);

    console.log("New Article >> ", newArticle);

    handleAddArticle(newArticle);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    // console.log("FAILED >> ", value);

    masterIncomplete(value);
  };

  // ON FINISH SUBMIT
  const onSubmit = () => {
    const error = {
      errorFields: [
        {
          errors: ["Please, Add an Articles!"],
        },
      ],
    };

    console.log(articleTable);

    if (articleTable.length > 0) {
      showModalConfirm();
    } else {
      masterIncomplete(error);
    }
  };

  // Handle Add Articles
  const handleAddArticle = (val) => {
    const newArticle = val;

    // Check Items in Article Tables
    const findArticle = articleTable.findIndex((item) => {
      return item.article_id === newArticle.article_id;
    });

    console.log("Article Find: ", findArticle);

    if (findArticle !== -1) {
      const _art = articleTable[findArticle];

      const _new = {
        ..._art,
        item_quantity: _art.item_quantity + newArticle.qty,
      };

      articleTable.splice(findArticle, 1, {
        ..._new,
      });

      console.log("Article: ", _new);
      console.log("Article Tables: ", articleTable);

      setArticleTable([]);

      setTimeout(() => {
        setArticleTable(articleTable);
      }, 50);
    } else {
      setArticleTable([
        ...(articleTable?.length > 0 ? articleTable : []),
        {
          key: lastKey + key,
          reservation_id: data.reservation_id,
          room_number: data.room_number,
          article_sub_id:
            subGroup.article_sub_id > 0 ? subGroup.article_sub_id : 0,
          article_sub_name: subGroup.article_sub_name
            ? subGroup.article_sub_name
            : null,
          article_id: article.article_id > 0 ? article.article_id : 0,
          article_name: article.article_name ? article.article_name : null,
          unit_price: parseInt(newArticle?.nett) || articlePrice.nett,
          item_quantity: parseInt(newArticle?.qty | 0),
          total_price: parseInt(
            parseInt(newArticle.unit_price) * parseInt(newArticle.qty)
          ),
          posting_type: newArticle?.posting_type
            ? newArticle.posting_type
            : null,
          total_posting: newArticle.total_posting
            ? newArticle.total_posting
            : 0,
          start_date: newArticle?.date_periodic
            ? moment(newArticle.date_periodic[0]).format("YYYY-MM-DD")
            : null,
          end_date: newArticle?.date_periodic
            ? moment(newArticle.date_periodic[1]).format("YYYY-MM-DD")
            : null,
          chart_of_account: newArticle.chart_of_account
            ? newArticle.chart_of_account
            : null,

          tax:
            newArticle?.tax > 0 ? parseInt(newArticle.tax) : articlePrice.tax,
          service:
            newArticle?.service > 0
              ? parseInt(newArticle.service)
              : articlePrice.service,
          gross:
            newArticle?.unit_price > 0
              ? parseInt(newArticle.unit_price)
              : articlePrice.gross,
          order_date: newArticle?.order_date || moment(),

          created_by: user_name,
          created_date: moment().format(),

          //         - is_tax diubah => is_tax_stay (boolean)
          // - tax diubah => tax_stay (int8)
          // - is_service diubah => is_service_stay (boolean)
          // - service diubah => service_stay (int8)
          // - gross_stay (int8)
        },
      ]);
    }

    // Data
    setSubGroup({
      article_sub_name: "",
      article_sub_id: 0,
    });

    form.resetFields([
      "article_sub_name",
      "article_name",
      "unit_price",
      "qty",
      "posting_type",
      "total_posting",
      "date_periodic",
      "chart_of_account",
      "tax",
      "service",
      "nett",
      "order_date",
    ]);

    setLastKey(lastKey + 1);
  };

  // SHOW MODAL CONFIRMATION
  const showModalConfirm = () => {
    setIsLoading(true);
    let submitContent = data;

    let submitEdit = form.getFieldsValue();

    console.log("Data About to Confirm =>>> ", submitContent);
    console.log("Data About to Edit =>>> ", submitEdit);

    confirm({
      className: "article-stay-confirm",
      title: `Are you sure want to ${
        edit == true ? "Update" : "Add new "
      } Article with guest named ${submitContent?.guest_name || "Unknown"}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleFinish(edit == false ? dataGuest : submitEdit);
        // console.log("Guest deleted");
      },
      onCancel() {
        setIsLoading(false);
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE FINISH CONFIRMATION
  const handleFinish = (value) => {
    const content = value;

    console.log("FINISH Content >> ", content);

    if (edit == true) {
      let editData = {
        article_id: article.article_id
          ? article.article_id
          : dataEdit?.article_id,
        article_sub_name:
          content?.article_sub_name || subGroup.article_sub_name,
        article_sub_id:
          subGroup.article_sub_id > 0
            ? subGroup.article_sub_id
            : dataEdit?.article_sub_id,
        article_name: content?.article_name,
        unit_price: parseInt(content.nett),
        item_quantity: parseInt(content.qty),
        total_price: parseInt(
          parseInt(content.unit_price) * parseInt(content.qty)
        ),
        room_number:
          content?.room_number > 0
            ? content.room_number
            : dataEdit?.room_number,
        posting_type: content?.posting_type,
        total_posting: content?.total_posting
          ? content.total_posting
          : dataEdit?.total_posting,
        chart_of_account: content?.chart_of_account
          ? content.chart_of_account
          : dataEdit?.chart_of_account,
        start_date:
          content?.date_periodic?.length > 0
            ? moment(content.date_periodic[0]).format("YYYY-MM-DD h:mm:ss")
            : null,
        end_date:
          content?.date_periodic?.length > 0
            ? moment(content.date_periodic[1]).format("YYYY-MM-DD h:mm:ss")
            : null,
        reservation_id: content?.reservation_id,
        id: dataEdit?.id || 0,
        price_id: data?.price_id ? data.price_id : dataEdit?.price_id,
        is_service_stay: dataEdit?.is_service,
        is_tax_stay: dataEdit?.is_tax,
        tax_stay: content?.tax || dataEdit?.tax,
        service_stay: content?.service || dataEdit?.service,
        gross_stay: content?.gross || dataEdit?.gross,

        order_date: content?.order_date || content?.order_date,
        // is_service: edit.is_service,
        //   is_tax: edit.is_tax,
        //   tax: edit.tax,
        //   service: edit.service,

        updated_by: user_name,
      };

      handleSubmit(editData);
    } else {
      let filtered = articleTable.filter((value) => {
        if (value.id == null) return value;
      });

      let allBody = [];

      for (let i = 0; i < filtered?.length; i++) {
        allBody.push({
          reservation_id: filtered[i].reservation_id,
          room_number: filtered[i].room_number,
          article_sub_id: filtered[i].article_sub_id,
          article_sub_name: filtered[i].article_sub_name,
          article_id: filtered[i].article_id,
          article_name: filtered[i].article_name,
          unit_price: filtered[i].unit_price,
          item_quantity: filtered[i].item_quantity,
          total_price: filtered[i].total_price,
          posting_type: filtered[i].posting_type,
          total_posting: filtered[i].total_posting,
          start_date: filtered[i].start_date,
          end_date: filtered[i].end_date,
          chart_of_account: filtered[i].chart_of_account,
          price_id: data?.price_id ? data.price_id : dataGuest.price_id,
          // NEW KEY
          is_service_stay: filtered[i].service > 0 ? true : false,
          is_tax_stay: filtered[i].tax > 0 ? true : false,
          tax_stay: filtered[i].tax,
          service_stay: filtered[i].service,
          gross_stay: filtered[i].gross,
          order_date: filtered[i].order_date,
          //         - is_tax diubah => is_tax_stay (boolean)
          // - tax diubah => tax_stay (int8)
          // - is_service diubah => is_service_stay (boolean)
          // - service diubah => service_stay (int8)
          // - gross_stay (int8)

          created_by: user_name,
        });
      }
      handleSubmit(allBody);
    }
  };

  // Handle Cancel Update
  const handleCancelUpdate = () => {
    setEdit(false);

    setArticleTable([]);
    setDataEdit([]);
    setSelected([]);

    form.resetFields();
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("Data About to PUT/POST: ", contentSubmit);

    if (edit == false) {
      await axios
        .post(
          `${baseurl}/reservation/article-stay`,
          { data: contentSubmit },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("Response Add Article Stay: ", response);
          successArticleStay(response, { method: 0 });
          handleClose();
        })
        .catch((error) => {
          console.log("Error: ", error);
          failedArticleStay(error, { method: 0 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .put(`${baseurl}/reservation/article-stay`, contentSubmit, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response Update Article Stay: ", response);
          successArticleStay(response, { method: 1 });
          handleClose();
          fetchData();
        })
        .catch((error) => {
          console.log("Error: ", error);
          failedArticleStay(error, { method: 1 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // CLOSE MODAL
  const handleClose = () => {
    // console.log(e);
    setOpen(false);
    closeModal(false);
    setEdit(false);

    // Data
    setSubGroup({
      article_sub_name: "",
      article_sub_id: 0,
    });

    setArticle({
      article_name: "",
      article_id: 0,
      code: 0,
      dept_id: 0,
      sub_id: 0,
      main_id: 0,
      coa: "",
      item_quantity: 0,
    });

    setArticlePrice({
      gross: 0,
      nett: 0,
      service: 0,
      tax: 0,
    });

    setDataEdit(null);
    setSelected([]);

    setArticleTable([]);

    setIsLoading(false);
    setLoading(false);
    setData(null);

    form.resetFields();
  };

  // console.log("Test Submit >>>", addArticle);

  // console.log(lastKey);

  return (
    <>
      <Modal
        className="add-article-modal"
        key={"add-article-modal"}
        title={
          <>
            <Row>
              <PlusCircleTwoTone style={{ fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Reservation Article`}
              </Typography>
            </Row>
          </>
        }
        width={800}
        centered={true}
        open={open}
        onCancel={handleClose}
        footer={null}
        style={{ padding: 0 }}
      >
        <Form
          className="form-article-stay"
          key="form-article-stay"
          name="form-article-stay"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          style={{ padding: "0px 36px 0px" }}
        >
          <Row gutter={30} className="row-res-id">
            <Col span={12}>
              <Form.Item label="Reservation No." name="reservation_id">
                <Input placeholder="Reservation No." disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Guest Name" name="guest_name">
                <Input placeholder="Name" disabled />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item label="Room Number" name="room_number" hidden>
                <Input placeholder="Room Number" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", background: "#EBEDF3" }}
          />

          <Row gutter={30} className="row-article">
            <Col span={8}>
              <Form.Item
                label="Article Sub-Group"
                name="article_sub_name"
                rules={[
                  {
                    required: true,
                    message: "Please, select an Article Sub-Group!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <MasterArticleSub
                  getArticleSub={getArticleSub}
                  getArticleMainId={null}
                  articleSubName={
                    dataEdit != null ? dataEdit?.article_sub_name : null
                  }
                  addArticleState={false}
                  is_forms={true}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Article Name"
                name="article_name"
                rules={[
                  {
                    required: true,
                    message: "Please, select an Article Name!",
                  },
                ]}
                style={{ marginRight: 30, width: "100%" }}
              >
                <MasterArticleList
                  getArticle={getArticle}
                  articleName={edit === true ? dataEdit?.article_name : null}
                  getArticleSub={subGroup.article_sub_id}
                  addArticleState={true}
                  is_FOparams={false}
                />
              </Form.Item>

              <Form.Item label="Article ID" name="article_id" hidden>
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Quantity"
                name="qty"
                rules={[
                  {
                    required: true,
                    message: "Please, input the item quantity!",
                  },
                ]}
              >
                <InputNumber
                  // type={"number"}
                  min={0}
                  placeholder="Quantity"
                  style={{ width: "100%" }}
                  onChange={handlePrice}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-art-price">
            <Col span={8}>
              <Form.Item
                label="Unit Price"
                name="unit_price"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  placeholder="Price"
                  addonBefore={currency}
                  disabled
                  formatter={(unit_price) => {
                    return `${unit_price > 0 ? unit_price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item label="Nett" name="nett" hidden>
                <InputNumber />
              </Form.Item>

              <Form.Item label="Service" name="service" hidden>
                <InputNumber />
              </Form.Item>

              <Form.Item label="Tax" name="tax" hidden>
                <InputNumber />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Posting Type"
                name="posting_type"
                style={{ width: "100%" }}
              >
                <MasterPosting
                  getPostType={getPostType}
                  posting_type={dataEdit != null ? dataEdit?.posting_type : ""}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Total Posting" name="total_posting">
                <InputNumber
                  type={"number"}
                  placeholder="Total Posting"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-coa">
            <Col span={12}>
              <Form.Item label="Order for Date" name="order_date">
                <DatePicker placeholder="Order for Date" />
              </Form.Item>

              <Form.Item label="Date Periodic" name={"date_periodic"}>
                <DatePicker.RangePicker format={"YYYY-MM-DD"} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Chart of Account" name="chart_of_account">
                <Input placeholder="Chart of Account" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider style={{ margin: "0px 0px 15px", background: "#EBEDF3" }} />

          <Row justify="end">
            <Button
              className="print-btn"
              key={"add-article-btn"}
              type="primary"
              hidden={edit}
              htmlType="submit"
              icon={<PlusOutlined />}
              // onClick={handleAddArticle}
              // style={{ width: 100 }}
              disabled={edit}
            >
              {`Add Article`}
            </Button>

            <Button
              className="next-btn"
              type="primary"
              hidden={edit == true ? false : true}
              style={{ marginRight: "10px" }}
              onClick={showModalConfirm}
            >{`Update`}</Button>

            <Button
              className="cancel-btn"
              type="default"
              hidden={edit == true ? false : true}
              onClick={handleCancelUpdate}
            >{`Cancel`}</Button>
          </Row>

          <Divider style={{ margin: "15px 0px 15px", background: "#EBEDF3" }} />

          <Row gutter={30} className="row-article-stay-table">
            <p>*Choose for edit data</p>
            <AddArticleTable
              dataAddArticle={open === true ? articleTable : []}
              // newArticle={article}
              editArticle={handleEdit}
              is_edited={setEdit}
              is_loading={loading}
              is_deleted={setArticleTable}
              is_refresh={setRefresh}
              is_selected={setSelected}
              is_reset={selected}
            />
          </Row>

          <Divider style={{ margin: "15px 0px 15px", background: "#EBEDF3" }} />

          <Row className="row-modal-btn" justify="end" align="middle">
            <Button
              className="submit-btn"
              // htmlType="submit"
              disabled={edit}
              onClick={onSubmit}
              loading={isLoading}
              type="primary"
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="close-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
