// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../../API/Context/FinanceContext/FinanceContext";
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// AUTH
import AuditDate from "../../../../../Reusable/DateAudit/Date";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Collapse,
  Table,
  Typography,
  Card,
  Divider,
  Spin,
} from "antd";

// Import Icons
import { MdPrint } from "react-icons/md";
import { PrinterOutlined } from "@ant-design/icons";

// Import Page Components
import SpinnerComponents from "../../../../../Reusable/Spinner/Spinner";
import EmptyTableComponents from "../../../../../Reusable/Empty/EmptyTable";

// Import Notifications
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody, printPreview } from "../../../../../../Style/Global/Global";
import SegmentStatisticTable from "../Segment/Table";

// Const
const { Panel } = Collapse;
const { Text, Link, Title } = Typography;

// CODE
export default function MergedDSRTable({ is_printed, dataDate }) {
  // CONTEXT
  const { getDSR, getDailyHistory } = useContext(FinanceContext);
  const { getParameters } = useContext(MasterContext);
  const tablesContainerRef = useRef(null);

  // Date Now
  const date = new Date(Date.now());
  const _date = AuditDate().audit;
  const today = moment(date).format("DD MMM YYYY");

  // STATE MANAGEMENT
  // Data
  const [bulks, setBulks] = useState([]);
  const [dataMaster, setDataMaster] = useState([]);
  const [dateNow, setDateNow] = useState(_date);
  // Data Tables
  const [tablesData, setTablesData] = useState([]);
  const [statistics, setStatistics] = useState([]);
  // Boolean
  const [isPrinted, setIsPrinted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);

  const [accountingParameter, setAccountingParameter] = useState([]);

  // Columns Table
  const column = [
    {
      title: () => {
        return handleTitleHeader("Descripton");
      },
      dataIndex: "description",
      key: "description",
      width: 150,
      //   ellipsis: true,
      render: (description, record) => {
        return {
          props: {
            style: { background: record["key"] ? "#8c8c8c" : "white" },
          },
          children: (
            <Text
              className="drr-desc-text"
              style={
                is_printed === true
                  ? printPreview
                  : {
                      fontSize: 10,
                      fontWeight: 500,
                      // textTransform: "capitalize",
                    }
              }
            >
              {`${description}`
                .replace(/-+/g, " ")
                .replace(/(^\w|\s\w)/g, (val) => val)}
            </Text>
          ),
        };
      },
    },
    {
      title: () => {
        return handleTitleHeader("Today");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("Today");
          },
          dataIndex: "actual_today",
          key: "actual_today",
          width: 100,
          render: (act, record) => {
            const values = parseInt(act ?? 0).toFixed(0);

            if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_today",
          key: "budget_today",
          width: 125,
          render: (act, record) => {
            const values = parseInt(act ?? 0).toFixed(0);

            if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Variance");
          },
          dataIndex: "variance_today",
          key: "variance_today",
          width: 125,
          render: (act, record) => {
            const values = parseInt(act ?? 0).toFixed(0);

            // return handleTitleHeader(handleNegativeBracket(values));
            if (values == 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(
                  handleNegativeBracket(record.actual_ytd - record.budget_ytd)
                ),
              };
            }
          },
        },
      ],
    },
    {
      title: () => {
        return handleTitleHeader("Month to Date (MTD)");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("MTD");
          },
          dataIndex: "actual_mtd",
          key: "actual_mtd",
          width: 100,
          render: (mtd, record) => {
            const values = parseInt(mtd ?? 0).toFixed(0);

            if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_mtd",
          key: "budget_mtd",
          width: 125,
          render: (mtd, record) => {
            const values = parseInt(mtd ?? 0).toFixed(0);

            if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Variance");
          },
          dataIndex: "variance_mtd",
          key: "variance_mtd",
          width: 125,
          render: (mtd, record) => {
            const values = parseInt(mtd ?? 0).toFixed(0);

            if (values == 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(
                  handleNegativeBracket(record.actual_ytd - record.budget_ytd)
                ),
              };
            }
          },
        },
      ],
    },
    {
      title: () => {
        return handleTitleHeader("Year to Date (YTD)");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("YTD");
          },
          dataIndex: "actual_ytd",
          key: "actual_ytd",
          width: 125,
          render: (ytd, record) => {
            const values = parseInt(ytd ?? 0).toFixed(0);

            if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_ytd",
          key: "budget_ytd",
          width: 130,
          render: (ytd, record) => {
            const values = parseInt(ytd ?? 0).toFixed(0);

            if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Variance");
          },
          dataIndex: "variance_ytd",
          key: "ytd_variance",
          render: (ytd, record) => {
            const values = parseInt(ytd ?? 0).toFixed(0);

            if (values == 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else if (values < 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(handleNegativeBracket(values)),
              };
            } else if (values > 0) {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(values),
              };
            } else {
              return {
                props: {
                  style: { background: record["key"] ? "#8c8c8c" : "white" },
                },
                children: handleTitleHeader(
                  handleNegativeBracket(record.actual_ytd - record.budget_ytd)
                ),
              };
            }
          },
        },
      ],
    },
  ];

  // Fetch Data
  const fetchData = async () => {
    setIsLoading(true);

    await getParameters({
      params: "parameters",
      type: "accounting",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);
        if (response?.data?.msg?.length) {
          let _res = response.data.msg[0];
          setAccountingParameter(_res);
        }
        console.log(accountingParameter);
      },
      onReject: (error) => {
        console.log(error);
        setIsLoading(false);
        failedFetch(error);
      },
    });

    await getDSR({
      type: "dsr-detail-group-id",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Room Revenue Details => ", response);

        if (response?.data?.msg?.length) {
          let _res = response.data.msg;

          handleData(_res);
        }
        // setIsLoading(false);
      },
      onReject: (error) => {
        console.log(error);

        setIsLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });

    await getParameters({
      params: "parameters",
      type: "source-detail-dsr",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setDataMaster(filtered);
        }
      },
      onReject: (error) => {
        console.log(error);

        setIsLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });

    // setTimeout(() => {
    setIsLoading(false);
    // }, 2500);
  };

  // FETCH HISTORY
  const fetchHistory = async () => {
    await getDailyHistory({
      type: "dsr-detail-group-id",
      date: moment(dataDate).format("YYYY-MM-DD"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response History: ", response);

        if (response?.data?.length > 0) {
          let _res = response.data;

          // setRevenue(_res);
          handleData(_res);
        } else {
          console.log("ITS SUPPOSED TOBE NULL!");
          setBulks([]);
          setTablesData([]);
        }
      },
      onReject: (error) => {
        console.log("Failed Revenue DRR: ", error);
        // setLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });

    await getDailyHistory({
      type: "dsr-statistic",
      date: moment(dataDate).format("YYYY-MM-DD"),
      onAwait: () => {
        // setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response DSR Statistics History => ", response);

        if (response?.data?.length > 0) {
          let _res = response.data;

          setStatistics(_res);
        } else {
          setStatistics([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("Failed Statistics DRR: ", error);
        setIsLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
    // destructureResult();
  }, []);

  useEffect(() => {
    let cleanUp = false;

    if (is_printed === true && !cleanUp) {
      setIsPrinted(true);
    } else {
      setIsPrinted(false);
    }

    if (dataDate && !cleanUp) {
      console.log("Tanggal: ", dataDate);

      setDateNow(dataDate);

      fetchHistory();
    }

    return () => {
      cleanUp = true;
    };
  }, [is_printed, dataDate]);

  // Create Tables
  useEffect(() => {
    let cleanUp = false;

    if (dataMaster.length > 0 && bulks.length > 0 && !cleanUp) {
      handleSorter();
    }

    return () => {
      cleanUp = true;
    };
  }, [dataMaster, bulks]);

  // USEEFFECT RESIZE VIEWPORT FOR TABLE BREAK
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [tablesData]);

  // HANDLE RESIZE VIEWPORT FOR TABLE BREAK
  const handleResize = () => {
    if (tablesContainerRef.current) {
      const tables =
        tablesContainerRef.current.querySelectorAll(".table-wrapper");

      let currentPageHeight = window.innerHeight;
      let currentSpaceLeft = currentPageHeight;

      tables.forEach((table, index) => {
        const observer = new ResizeObserver(() => {
          // reset viewport empty space for new page
          if (index === 0) {
            currentPageHeight = window.innerHeight;
            currentSpaceLeft = currentPageHeight;
          }

          // cek only second table and more
          if (index > 0) {
            const previousTable = tables[index - 1];
            const previousTableBottom =
              previousTable.offsetTop + previousTable.offsetHeight;

            if (table.offsetTop < previousTableBottom) {
              const estimatedTableHeight = table.offsetHeight;

              if (estimatedTableHeight <= currentSpaceLeft) {
                currentSpaceLeft -= estimatedTableHeight;
                table.classList.remove("table-break");
              } else {
                currentSpaceLeft = currentPageHeight - estimatedTableHeight;
                table.classList.add("table-break");
              }
            }
          }
        });

        observer.observe(table);
      });
    }
  };

  // Handle Sorting based on Parameters
  const handleSorter = () => {
    const result = [];

    console.log("DATA MASTER ==>", dataMaster);
    console.log("BULKS ==>", dataMaster);

    dataMaster.forEach(function (key) {
      var found = false;

      // console.log("KEY: ", key)

      let items = bulks.filter(function (item) {
        // console.log("Items: ", item)

        if (!found && item[0].group_id === key.id && key.is_show === true) {
          // console.log("Item is true: ", item[0].group_id)
          // console.log("Item is matched with: ", key)
          result.push(item);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });

    console.log("Results of sorting: ", result);
    handleCreateTable(result);
  };

  // HANDLE DATA
  const handleData = (value) => {
    const _valRes = value;

    let result = _valRes.reduce(function (r, a) {
      r[a.group_id] = r[a.group_id] || [];
      r[a.group_id].push(a);

      return r;
    }, []);

    console.log("Data Mapped: ", result);
    setBulks(result);
    // handleCreateTable(result);
  };

  const handleFormat = (e) => {
    // let dataSort = e.sort((a, b) =>
    //   a.account_code.localeCompare(b.account_code)
    // );
    // let dataGrouping = Object.groupBy(dataSort, ({ account_code }) =>
    //   account_code.substr(0, 6)
    // );

    let arr_sub_group = [
      {
        "400-01": "Room Revenue",
        "450-01": "Other Revenue",
      },
    ];
    let dataSort = e
      .sort((a, b) => a.account_code.localeCompare(b.account_code))
      .map((v) => ({
        ...v,
        sub_group_name: arr_sub_group[0][v.account_code.substr(0, 6)] ?? "Sub",
      }));
    let dataGrouping = Object.groupBy(dataSort, ({ account_code }) =>
      account_code.substr(0, 6)
    );

    const dataMerge = () => {
      let val = Object.values(dataGrouping);
      for (let i = 0; i < val.length; i++) {
        val[i] = val[i].concat(
          (() => {
            let actualToday = 0;
            let actualMtd = 0;
            let actualYtd = 0;
            let budgetToday = 0;
            let budgetMtd = 0;
            let budgetYtd = 0;
            for (let row of val[i]) {
              actualToday += row["actual_today"];
              actualMtd += row["actual_mtd"];
              actualYtd += row["actual_ytd"];
              budgetToday += row["budget_today"];
              budgetMtd += row["budget_mtd"];
              budgetYtd += row["budget_ytd"];
            }
            return {
              key: "total",
              description: `Total ${val[i][0].sub_group_name}`,
              actual_today: actualToday,
              actual_mtd: actualMtd,
              actual_ytd: actualYtd,
              budget_today: budgetToday,
              budget_mtd: budgetMtd,
              budget_ytd: budgetYtd,
              variance_today: actualToday - budgetToday,
              variance_mtd: actualMtd - budgetMtd,
              variance_ytd: actualYtd - budgetYtd,
            };
          })()
        );
      }
      return val.flat();
    };
    dataMerge();
    return dataMerge();
  };

  // Handle Table
  const handleCreateTable = (data) => {
    const _bulks = data;
    let _tables = [];

    for (let i = 0; i < _bulks.length; i++) {
      if (_bulks[i] !== null && _bulks[i] !== undefined) {
        // console.log(`Loop-${i}: `, _bulks[i]);

        _tables.push(
          <div
            // ref={(el) => (tablesContainerRef.current = el)}
            key={`table-${i}`}
            style={{ width: "100%" }}
            className="table-wrapper"
          >
            <Table
              className={"dsr-table"}
              name={"dsr-table-" + i}
              key={"dsr-table-" + i}
              title={() => (
                <Typography
                  className="table-title"
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {_bulks[i][0]?.label_header || `Data-${i}`}
                </Typography>
              )}
              //   dataSource={_bulks[i]}
              dataSource={handleFormat(_bulks[i])}
              columns={column}
              pagination={false}
              size="small"
              bordered={true}
              loading={isLoading}
              rowKey={(record) =>
                record?.id ? record.id : record?.account_code
              }
              // scroll={{
              //   x: 1000,
              // }}
              //   summary={(pageData) => {
              //     let totalToday = 0;
              //     let totalBudgetToday = 0;

              //     let totalMtd = 0;
              //     let totalBudgetMtd = 0;

              //     let totalYtd = 0;
              //     let totalBudgetYtd = 0;

              //     pageData.forEach(
              //       ({
              //         budget_today,
              //         budget_mtd,
              //         budget_ytd,
              //         actual_today,
              //         actual_mtd,
              //         actual_ytd,
              //       }) => {
              //         totalToday += actual_today;
              //         totalBudgetToday += Math.floor(budget_today ?? 0);

              //         totalMtd += actual_mtd;
              //         totalBudgetMtd += Math.floor(budget_mtd ?? 0);

              //         totalYtd += actual_ytd;
              //         totalBudgetYtd += budget_ytd;
              //       }
              //     );
              //     return (
              //       <>
              //         <Table.Summary
              //           fixed
              //           key="summary-tables"
              //           className="sum-table"
              //         >
              //           <Table.Summary.Row>
              //             <Table.Summary.Cell
              //               className={"summary-head"}
              //               index={0}
              //               // colSpan={3}
              //               align="right"
              //             >
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {_bulks[i][0]?.total_label_name || "Total"}
              //               </Text>
              //             </Table.Summary.Cell>

              //             <Table.Summary.Cell index={1}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalToday)}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={2}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalBudgetToday)}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={3}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(
              //                   totalToday - totalBudgetToday
              //                 )}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={4}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalMtd)}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={5}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalBudgetMtd)}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={6}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalMtd - totalBudgetMtd)}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={7}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalYtd)}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={8}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalBudgetYtd)}
              //               </Text>
              //             </Table.Summary.Cell>
              //             <Table.Summary.Cell index={9}>
              //               <Text
              //                 strong
              //                 style={{ fontSize: isPrinted ? "6px" : "12px" }}
              //               >
              //                 {handleNegativeBracket(totalYtd - totalBudgetYtd)}
              //               </Text>
              //             </Table.Summary.Cell>
              //           </Table.Summary.Row>

              //           <Table.Summary.Row
              //             className="row-space-2"
              //             key={"row-space-2"}
              //           >
              //             <Table.Summary.Cell
              //               key="row-cell-space"
              //               className="row-cell-space"
              //               index={0}
              //               colSpan={9}
              //             />
              //           </Table.Summary.Row>
              //         </Table.Summary>
              //       </>
              //     );
              //   }}
              summary={(e) => {
                let totalNetActualToday = 0;
                let totalTaxActualToday = 0;
                let totalServiceActualToday = 0;
                let totalNetBudgetToday = 0;
                let totalTaxBudgetToday = 0;
                let totalServiceBudgetToday = 0;
                let totalNetActualMtd = 0;
                let totalTaxActualMtd = 0;
                let totalServiceActualMtd = 0;
                let totalNetBudgetMtd = 0;
                let totalTaxBudgetMtd = 0;
                let totalServiceBudgetMtd = 0;
                let totalNetActualYtd = 0;
                let totalTaxActualYtd = 0;
                let totalServiceActualYtd = 0;
                let totalNetBudgetYtd = 0;
                let totalTaxBudgetYtd = 0;
                let totalServiceBudgetYtd = 0;
                e.forEach(
                  ({
                    key,
                    actual_today,
                    budget_today,
                    actual_mtd,
                    budget_mtd,
                    actual_ytd,
                    budget_ytd,
                  }) => {
                    if (!key) {
                      totalNetActualToday += actual_today;
                      totalTaxActualToday +=
                        (actual_today * accountingParameter?.tax_percentage) /
                        100;
                      totalServiceActualToday +=
                        (actual_today *
                          accountingParameter?.service_percentage) /
                        100;
                      totalNetBudgetToday += budget_today;
                      totalTaxBudgetToday +=
                        (budget_today * accountingParameter?.tax_percentage) /
                        100;
                      totalServiceBudgetToday +=
                        (budget_today *
                          accountingParameter?.service_percentage) /
                        100;
                      totalNetActualMtd += actual_mtd;
                      totalTaxActualMtd +=
                        (actual_mtd * accountingParameter?.tax_percentage) /
                        100;
                      totalServiceActualMtd +=
                        (actual_mtd * accountingParameter?.service_percentage) /
                        100;
                      totalNetBudgetMtd += budget_mtd;
                      totalTaxBudgetMtd +=
                        (budget_mtd * accountingParameter?.tax_percentage) /
                        100;
                      totalServiceBudgetMtd +=
                        (budget_mtd * accountingParameter?.service_percentage) /
                        100;
                      totalNetActualYtd += actual_ytd;
                      totalTaxActualYtd +=
                        (actual_ytd * accountingParameter?.tax_percentage) /
                        100;
                      totalServiceActualYtd +=
                        (actual_ytd * accountingParameter?.service_percentage) /
                        100;
                      totalNetBudgetYtd += budget_ytd;
                      totalTaxBudgetYtd +=
                        (budget_ytd * accountingParameter?.tax_percentage) /
                        100;
                      totalServiceBudgetYtd +=
                        (budget_ytd * accountingParameter?.service_percentage) /
                        100;
                    }
                  }
                );

                return (
                  <>
                    <Table.Summary.Row style={{ background: "#bfbfbf" }}>
                      <Table.Summary.Cell index={0}>
                        <Text strong style={{ fontSize: 10 }}>
                          Total Net
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalNetActualToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalNetBudgetToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalNetActualToday - totalNetBudgetToday
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalNetActualMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalNetBudgetMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalNetActualMtd - totalNetBudgetMtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalNetActualYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalNetBudgetYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalNetActualYtd - totalNetBudgetYtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row style={{ background: "#bfbfbf" }}>
                      <Table.Summary.Cell index={0}>
                        <Text strong style={{ fontSize: 10 }}>
                          Total Tax ({accountingParameter?.tax_percentage}%)
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalTaxActualToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalTaxBudgetToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalTaxActualToday - totalTaxBudgetToday
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalTaxActualMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalTaxBudgetMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalTaxActualMtd - totalTaxBudgetMtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalTaxActualYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalTaxBudgetYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalTaxActualYtd - totalTaxBudgetYtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row style={{ background: "#bfbfbf" }}>
                      <Table.Summary.Cell index={0}>
                        <Text strong style={{ fontSize: 10 }}>
                          Total Service (
                          {accountingParameter?.service_percentage}%)
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalServiceActualToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalServiceBudgetToday)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalServiceActualToday - totalServiceBudgetToday
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalServiceActualMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalServiceBudgetMtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalServiceActualMtd - totalServiceBudgetMtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalServiceActualYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(totalServiceBudgetYtd)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalServiceActualYtd - totalServiceBudgetYtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row style={{ background: "#bfbfbf" }}>
                      <Table.Summary.Cell index={0}>
                        <Text strong style={{ fontSize: 10 }}>
                          Total Gross
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(
                            totalNetActualToday +
                              totalTaxActualToday +
                              totalServiceActualToday
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(
                            totalNetBudgetToday +
                              totalTaxBudgetToday +
                              totalServiceBudgetToday
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalNetActualToday -
                              totalNetBudgetToday +
                              (totalTaxActualToday - totalTaxBudgetToday) +
                              (totalServiceActualToday -
                                totalServiceBudgetToday)
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(
                            totalNetActualMtd +
                              totalTaxActualMtd +
                              totalServiceActualMtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(
                            totalNetBudgetMtd +
                              totalTaxBudgetMtd +
                              totalServiceBudgetMtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalNetActualMtd -
                              totalNetBudgetMtd +
                              (totalTaxActualMtd - totalTaxBudgetMtd) +
                              (totalServiceActualMtd - totalServiceBudgetMtd)
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(
                            totalNetActualYtd +
                              totalTaxActualYtd +
                              totalServiceActualYtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleFormatter(
                            totalNetBudgetYtd +
                              totalTaxBudgetYtd +
                              totalServiceBudgetYtd
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9}>
                        <Text strong style={{ fontSize: 10 }}>
                          {handleNegativeBracket(
                            totalNetActualYtd -
                              totalNetBudgetYtd +
                              (totalTaxActualYtd - totalTaxBudgetYtd) +
                              (totalServiceActualYtd - totalServiceBudgetYtd)
                          )}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
              style={{ padding: "0px 0px 10px 0px" }}
            />
          </div>
        );
      }
    }

    if (_tables.length > 0) {
      console.log("Table Results: ", _tables);
      setTablesData(_tables);
    } else {
      setTablesData([]);
    }

    setIsLoading(false);
    setIsProcessing(false);
  };

  // Handle Negative Bracket
  const handleNegativeBracket = (values) => {
    const _formatted = Math.round(values * 100) / 100;

    return `${_formatted}`
      .replace(/-(.*)/, "($1)")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleFormatter = (values) => {
    const _formatted = Math.round(values * 100) / 100;

    return `${_formatted}`
      .replace(/-(.*)/, "($1)")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle Title on Print Preview
  const handleTitleHeader = (values) => {
    const _formatted = values.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <Text
        className="header-text"
        style={
          isPrinted === true
            ? printPreview
            : {
                fontSize: 10,
                fontWeight: 500,
              }
        }
      >
        {values != null ? (values != 0 ? _formatted : "-") : "-"}
      </Text>
    );
  };

  // Handle Refresh
  const handleRefresh = (value) => {
    // console.log(value);

    fetchData();
  };

  if (isProcessing === true) {
    return <SpinnerComponents />;
  }

  return (
    <>
      <Row
        // gutter={[30, 30]}
        className="row-body"
        justify="start"
        style={mainBody}
        ref={tablesContainerRef}
      >
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {`Merge of Daily Revenue Report (DRR)`}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 0px 0px", color: "#8c8c8c" }}
          >
            {`Idea's Hotel Bandung`}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 20px 0px", color: "#8c8c8c" }}
          >
            {moment(dateNow).format("DD MMM YYYY")}
          </Typography.Title>

          <Row>
            {tablesData.length > 0 ? (
              tablesData
            ) : (
              <EmptyTableComponents is_refresh={handleRefresh} />
            )}
          </Row>
          <Row>
            <Table
              className="dsr-table"
              name="statistics-table"
              key="statistics-table"
              title={() =>
                statistics?.length > 0 ? (
                  <Typography
                    className="table-title"
                    style={{
                      fontSize: 12,
                      fontWeight: 500,
                    }}
                  >
                    {`Statistics`}
                  </Typography>
                ) : null
              }
              dataSource={statistics}
              columns={statistics.length > 0 ? column : []}
              // columns={column}
              pagination={false}
              size="small"
              bordered={true}
              loading={isLoading}
              rowKey={(record) =>
                record?.id ? record.id : record?.account_code
              }
            />
          </Row>
          <Row style={{ marginTop: 10, justifyContent: "center" }}>
            <SegmentStatisticTable
              is_for_merge={true}
              is_printed={isPrinted}
              dataDate={dateNow}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
