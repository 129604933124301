// Import React's Component
import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";

// Import ANTD Component
import {
  Table,
  Tag,
  Form,
  Row,
  Col,
  Tooltip,
  InputNumber,
  Input,
  DatePicker,
  Space,
  Button,
  Typography,
} from "antd";

// Import Icons
import { PrinterOutlined, SyncOutlined } from "@ant-design/icons/lib/icons";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Import React Icons Components
import ModalAddTableAvailability from "../../../Modals/Outlet/TableAvailability/ModalAdd";
import ModalDetailTableAvailability from "../../../Modals/Outlet/TableAvailability/ModalDetail";
import ModalTableAvailability from "../../../Modals/Outlet/TableAvailability/ModalAdd";

const { Text } = Typography;

// CODE
export default function TableAvailabilityTable(props) {
  // PROPS
  const { is_search, searchKeyName, getSelectedHeader } =
    props;

  // Data
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [select, setSelect] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [openAddResv, setOpenAddResv] = useState(false);
  const [openDetailResv, setOpenDetailResv] = useState(false);
  const [filterDate, setFilterDate] = useState(null);

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  // Loadings
  const [loading, setLoading] = useState(false);

  // FETCH Data Header
  const fetchData = async (e, v) => {
    const startDate = e;
    const searchKeyName = v;
    setLoading(true);
    await axios
      .post(`${baseurl}/pos/reservation-by-date`, {
        start_date: startDate ?? moment(),
        outlet_location_id: sessionStorage.getItem("id_outlet_location"),
      })
      .then((response) => {
        console.log("TABLE AVAILABILITY >>> ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            return e;
          });
          setData(_res);
        }
      })
      .catch((error) => {
        console.log("Error Table Availability >>> ", error);

        // failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(filterDate, searchKeyName);
  }, []);

  useEffect(() => {
    fetchData(filterDate, null);
  }, [filterDate]);

  // Search
  useEffect(() => {
    // kalo pengen search langsung tampa klik button search, func handleSearch ubah jadi {handleSearch();}
    is_search(() => handleSearch);
  }, [searchKeyName]);

  // Columns
  const columns = [
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "action",
    //   //   fixed: "left",
    //   width: 70,
    //   render: (_, record, index) => {
    //     const editable = isEditing(record, index);

    //     return editable ? (
    //       <>
    //         <Row className="action-btn" gutter={20} style={{ width: "100%" }}>
    //           <Col span={12}>
    //             <FaCheckCircle
    //               className="edit-btn"
    //               onClick={() => {
    //                 save(index, record.article_name);
    //               }}
    //               style={{
    //                 fontSize: 20,
    //                 color: "#1BC5BD",
    //                 cursor: "pointer",
    //               }}
    //             />
    //           </Col>

    //           <Col span={12}>
    //             <FaTimesCircle
    //               className="delete-btn"
    //               onClick={cancel}
    //               style={{ fontSize: 20, color: "#F64E60", cursor: "pointer" }}
    //             />
    //           </Col>
    //         </Row>
    //       </>
    //     ) : (
    //       <>
    //         <Row className="action-btn" style={{ width: "100%" }}>
    //           <Col span={12}>
    //             <Tooltip title="Edit Quantity" className="delete-tooltip">
    //               <FiEdit
    //                 className="edit-btn"
    //                 onClick={() => {
    //                   console.log("EDIT ON CLICK >>>");
    //                   if (edit != true) {
    //                     console.log("IS EDITING QTY: ", editingKey);
    //                     console.log("Edit: ", record);
    //                     console.log("Edit: ", edit);
    //                     editData(record, index);
    //                     setEdit(true);
    //                   }
    //                 }}
    //                 style={{
    //                   fontSize: 20,
    //                   color: "#1BC5BD",
    //                   cursor: "pointer",
    //                 }}
    //               />
    //             </Tooltip>
    //           </Col>
    //         </Row>
    //       </>
    //     );
    //   },
    // },
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 55,
      fixed: "left",
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 120,
      fixed: "left",
      render: (amount) => {
        return moment(LocalizationDate(amount)).format("YYYY-MM-DD");
      },
    },
    {
      title: "Table",
      dataIndex: "table_id",
      key: "table_id",
      width: 70,
      fixed: "left",
      render: (amount) => amount,
    },
    {
      title: "Seat",
      dataIndex: "seat",
      key: "seat",
      width: 70,
      fixed: "left",
      render: (amount) => amount,
    },

    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  let children = [];
  for (let i = 0; i < 24; i++) {
    let obj = {
      title: `${i < 10 ? "0" : ""}${i}.00`,
      dataIndex: `${i}`,
      key: `${i}`,
      width: 150,
      render: (amount, record) => {
        amount[0]?.order_id_alias;
        if (amount[0]?.order_id_alias != null) {
          return (
            <Button
              className="delete-btn"
              onClick={() => {
                handleDetailModal({
                  name: amount[0]?.name,
                  order_id_alias: amount[0]?.order_id_alias,
                  pax: amount[0]?.pax,
                  phone_number: amount[0]?.phone_number,
                  table_id: record?.table_id,
                  table_code: record?.table_code,
                  status_table: record?.status_table,
                  start_date: moment(amount[0]?.start_date).set("hour", i),
                  end_date: moment(amount[0]?.end_date).set(
                    "hour",
                    amount[0]?.end_date
                  ),
                });
              }}
              type="danger"
              style={{
                width: 125,
                // color: "#cd201f",
              }}
            >
              <Row>
                {/* {`${amount[0]?.order_id_alias} || ${amount[0]?.name}`} */}
                <Text
                  style={{ width: 100, color: "white" }}
                  ellipsis={{
                    tooltip: `${amount[0]?.order_id_alias} || ${amount[0]?.name}`,
                  }}
                >
                  {`${amount[0]?.order_id_alias} || ${amount[0]?.name}`}
                </Text>
              </Row>
            </Button>
          );
        }
        return (
          <Button
            className="submit-btn"
            onClick={() => {
              handleAddModal({
                table_id: record?.table_id,
                start_date: moment(record?.date).set("hour", i),
              });
            }}
            type="primary"
            style={{
              width: 125,
              //   color: "#87d068",
            }}
          >
            Available
          </Button>
        );
      },
    };
    children.push(obj);
  }
  let objFinal = {
    title: "Start Time (in 24 Hour)",
    children: children,
  };
  columns.push(objFinal);

  const handleAddModal = (e) => {
    setOpenAddResv(true);
    setSelectedData(e);
  };
  const handleDetailModal = (e) => {
    setOpenDetailResv(true);
    setSelectedData(e);
  };

  const handleFilterDate = (e) => {
    setFilterDate(e);
  };

  const handleSearch = () => {
    let res = data.filter((e) => {
      for (let i = 0; i < 24; i++) {
        if (
          (e[`${i}`][0]?.name || "")
            .toLowerCase()
            .indexOf(searchKeyName.toLowerCase()) !== -1
        ) {
          return e;
        }
      }
    });

    // console.log(res);
    setDataSearch(res);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    columnTitle: "Select",
    selectedRowKeys: select,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelect(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      getSelectedHeader(selectedRows[0]);
    },
    getCheckboxProps: (record) => {
      return {
        // disabled: record.item_quantity == (0 || null || undefined), //disable the first 4 rows only
      };
    },
  };

  return (
    <>
      <Row
        className="guest-inhouse-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row
            justify="start"
            className="row-search-btn"
            align="top"
            gutter={[0, 30]}
          >
            {/* <Button
              className="submit-btn"
              type="primary"
              onClick={openModal}
              icon={<PlusOutlined />}
              style={{
                backgroundColor: "#1BC5BD",
                borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              Add Article
            </Button> */}
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="print-col"
              align="start"
              // direction="horizontal"
            >
              <Button
                type="primary"
                className="print-btn"
                icon={<PrinterOutlined />}
                // onClick={handlePrint}
              >{`Print & Download List`}</Button>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className="refresh-col"
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Button
                className="refresh-btn"
                type="default"
                icon={<SyncOutlined />}
                onClick={() => {
                  fetchData(null);
                }}
              >
                {`Refresh`}
              </Button>
            </Col>
          </Row>

          <Row style={{ marginTop: 30, width: "100%" }}>
            <Col
              // span={8}
              style={{
                textAlign: "start",
                // margin: "22px 30px",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              <Typography style={{ marginBottom: 10 }}>
                {`Filter By Date`}
              </Typography>

              <Input.Group>
                <DatePicker
                  className="pos-resv-date-picker"
                  onChange={(e) => {
                    handleFilterDate(e);
                  }}
                  style={{
                    minWidth: 150,
                  }}
                />
              </Input.Group>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 10, width: "100%" }}>
            <Table
              className="saved-bill-modal-table"
              name="saved-bill-modal-table"
              key="saved-bill-modal-table"
              columns={columns}
              dataSource={dataSearch.length != 0 ? dataSearch : data}
              bordered
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
                onChange(current, pageSize) {
                  setPage(current);
                  setPaginationSize(pageSize);
                },
              }}
              scroll={{
                x: 1800,
              }}
              loading={loading}
              //   rowSelection={{
              //     type: "radio",
              //     ...rowSelection,
              //   }}
              //   components={{
              //     body: {
              //       cell: EditableCell,
              //     },
              //   }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => record.table_id}
            />
          </Row>

          {/* <ModalAddArticle openModal={open} closeModal={setOpen} /> */}
        </Col>
      </Row>

      <ModalAddTableAvailability
        openModal={openAddResv}
        closeModal={setOpenAddResv}
        selectedData={selectedData}
        handleRefresh={() => {
          fetchData(null);
        }}
      />
      <ModalDetailTableAvailability
        openModal={openDetailResv}
        closeModal={setOpenDetailResv}
        selectedData={selectedData}
        handleRefresh={() => {
          fetchData(null);
        }}
      />
    </>
  );
}
