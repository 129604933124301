// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// Import Notification
import {
  failedCheckOut,
  failedFetch,
  successCheckOut,
} from "../../Reusable/Notification/Notification";

// Import Functions
import { PreTotalPriceFunction } from "../../Reusable/Functions/TotalPrice/PreTotalPrice";
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { PreBillArticleShow } from "../../Reusable/Functions/BillType/BillType";
import GroupingArticle from "../../Reusable/Functions/GroupingArticle/GroupingArticle";
import { FilterEarlyCO } from "../../Reusable/Functions/EarlyCO/EarlyCO";
import ParametersBreakfast from "../../Reusable/Parameters/ParamsBF";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import MasterIdCardType from "../../Reusable/Master/IdCardType/IdCardType";
import MasterTitle from "../../Reusable/Master/Title/Title";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function JoinBillTrx(props) {
  // Props
  const {
    // Data
    // Data Leader
    dataLeader,
    dataArticle,
    is_early,
    // Set Total Payment Amounts
    totalPayment,
    // Is Group
    is_group,
    load_state,
  } = props;

  // USELOCATION STATE
  const { pathname, state } = useLocation();

  // CONTEXT
  const { getMasterBillTrx, getReservationLeader } = useContext(MainContext);
  const { getMasterPrint } = useContext(MasterContext);

  // Use Ref
  const componentRef = useRef();

  // Currency
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;
  const accounting = AccountingParams();
  // Breakfast
  const breakfast = ParametersBreakfast().breakfast;

  // Data State
  const leader = state?.length > 0 ? state[0] : null;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataBill, setDataBill] = useState([]);
  // Total
  // const [totalAmount, setTotalAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState({
    nett: 0,
    tax: 0,
    service: 0,
    gross: 0,
  });
  // Article Room and Stay
  const [articleRoom, setArticleRoom] = useState([]);
  const [articleStay, setArticleStay] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data
  const fetchMasterBill = async () => {
    load_state(false);
    // await getReservationLeader({
    //   leader: "get-leader",
    //   reservation_id: leader?.reservation_id,
    //   onAwait: () => {},
    //   onSuccess: (response) => {
    //     console.log("Leader Data --> ", response);

    //     if (response?.data?.msg?.length > 0) {
    //       setData(response.data.msg[0]);
    //       dataLeader(response.data.msg[0]);
    //     }
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //   },
    // });

    await getMasterBillTrx({
      bill: "bill",
      type: "article-master-bill-guest",
      res_id: leader?.reservation_id
        ? leader.reservation_id
        : state[0]?.reservation_id,
      onAwait: () => {
        setLoading(true);
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Master => ", response);

        // let arr = [];
        if (response?.data?.length > 0) {
          const _res = response.data;

          const _filter = FilterEarlyCO({
            article_room: _res[0]?.get_article_room_master_bill_guest,
            article_stay:
              _res[0]?.get_article_stay_master_bill_guest?.length > 0
                ? _res[0].get_article_stay_master_bill_guest
                : [],
            isAbf: accounting?.abf_plus_one_day,
            bf_id: breakfast?.id,
          });

          console.log("Filtered Early: ", _filter);

          if (is_early) {
            handleFilter(_filter);
          } else {
            handleFilter(_res);
          }
        } else {
          setDataBill([]);
        }
        // arr.push(newRoom);
        //   console.log(newRoom);
        //   setArticleRoom(newRoom);
        //   console.log("TESTTTT >>>", response);
        load_state(false);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Failed Master Bill: ", error);
        load_state(true);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // FETCH PRINT FORM
  const fetchPrint = async () => {
    // third;
    await getMasterPrint({
      reservation_id: leader?.reservation_id || data?.reservation_id,
      price_id: leader?.price_id > 0 ? leader.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: leader || data,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataLeader && breakfast?.id > 0 && !cleanUp) {
      console.log("Data Leaders --> ", dataLeader);
      console.log("Data STATE --> ", state);

      setData(dataLeader);

      // Fetch Data
      fetchMasterBill();
      fetchPrint();
    }

    return () => {
      cleanUp = true;
    };
  }, [dataLeader, is_early, breakfast]);

  useEffect(() => {
    let cleanUp = false;

    if ((articleRoom?.length > 0 || articleStay?.length > 0) && !cleanUp) {
      handleTotal(articleRoom, articleStay);
    } else {
      setDataBill([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleRoom, articleStay]);

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>{`${record.unit_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        );
      },
    },
    {
      title: `Total ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>{`${record.total_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        );
      },
    },
  ];

  // ON FINISH
  const onFinish = () => {
    handlePrint();
  };

  // SEQUENTIAL BUTTON SUBMIT
  const [counter, setCounter] = useState(0);
  const myclick = () => {
    setCounter((counter) => counter + 1);
    console.log(counter);
    if (counter == 0);
    else if (counter == 1) {
      //   move();
      setCounter(0);
    }
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    // closeModal(false);
    setCounter(0);
    setArticleRoom([]);
    setArticleStay([]);
    setData([]);
    // setEdit(null);

    form.resetFields();
  };

  // HANDLE FILTER
  const handleFilter = (values) => {
    const _response = values;

    let id = 1;
    let _newRoom = [];
    let _articleRoom = [];
    let _articles = [];

    let _filtered_article = [];

    // for (let i = 0; i < state?.length; i++) {
    // // console.log("Loop ", i);

    // const _filteredRoom =
    //   _response[0].get_article_room_master_bill_guest.filter((val) => {
    //     return val.price_id == state[i].price_id;
    //   });

    // const newRoom = _filteredRoom.map((e) => ({
    //   article_name: `Room ${e.room_category} ${e.room_number}`,
    //   item_quantity: 1,
    //   unit_price: e?.room_price,
    //   total_price: e?.room_price,
    //   id: id++,
    //   price_id: e?.price_id,
    //   gross: e?.gross,
    //   tax: e?.tax,
    //   service: e?.service,
    // }));

    // if (_response[0]?.get_article_stay_master_bill_guest?.length > 0) {
    //   const _filteredArt =
    //     _response[0]?.get_article_stay_master_bill_guest.filter((val) => {
    //       return val.price_id == state[i].price_id;
    //     });

    //   let newArticles = _filteredArt.map((e) => ({
    //     ...e,
    //     id: id++,
    //     unit_price: e?.unit_price,
    //     total_price: e?.unit_price * e?.item_quantity,
    //     tax: e?.tax * e?.item_quantity,
    //     service: e?.service * e?.item_quantity,
    //     gross: e?.gross * e?.item_quantity,
    //     article_name: `${e?.article_name} - ${e?.room_number}`,
    //   }));

    //   if (newArticles?.length > 0) {
    //     console.log("New Articles: ", newArticles);

    //     for (let j = 0; j < newArticles?.length; j++) {
    //       _articles.push(newArticles[j]);
    //     }
    //   }
    //   }

    //   _articleRoom.push(_filteredRoom[0]);

    //   if (newRoom?.length > 1) {
    //     // console.log("NEW ROOM: ", newRoom);

    //     for (let j = 0; j < newRoom.length; j++) {
    //       _newRoom.push(newRoom[j]);
    //     }
    //   } else {
    //     _newRoom.push(newRoom[0]);
    //   }
    // }
    const _filteredRoom = is_early
      ? _response?.room
      : _response[0]?.get_article_room_master_bill_guest;

    if (is_early) {
      _filtered_article =
        _response?.article?.length > 0 ? _response.article : [];
    } else {
      _filtered_article =
        _response[0]?.get_article_stay_master_bill_guest?.length > 0
          ? _response[0].get_article_stay_master_bill_guest
          : [];
    }

    // console.log("Room Filtered ", _filteredRoom);

    const _newArticle = PreBillArticleShow({
      article_room: _filteredRoom,
      article_stay: _filtered_article,
      bill_type: bill_type,
    });

    // const newRoom = _filteredRoom.map((e) => ({
    //   article_name: `Room ${e.room_category} ${e.room_number}`,
    //   quantity: 1,
    //   unit_price: e?.room_price,
    //   total_price: e?.room_price,
    //   id: e?.id,
    //   price_id: e?.price_id,
    //   gross: e?.gross,
    //   tax: e?.tax,
    //   service: e?.service,
    // }));

    // if (_response[0]?.get_article_stay_master_bill_guest?.length > 0) {
    //   const _filteredArt = _response[0]?.get_article_stay_master_bill_guest;

    //   const newArticles = _filteredArt.map((e) => {
    //     return {
    //       id: e?.id,
    //       unit_price: e?.unit_price,
    //       total_price: e?.unit_price * e?.item_quantity,
    //       article_name: `${e.article_name} ${e.room_number}`,
    //       gross: e?.total_price,
    //       quantity: e?.item_quantity || 0,
    //       tax: e?.tax * e?.item_quantity,
    //       service: e?.service * e?.item_quantity,
    //     };
    //   });

    //   if (newArticles?.length > 0) {
    //     _articles.push(newArticles);
    //   }
    // }

    // _articleRoom.push(_filteredRoom);
    // _newRoom.push(newRoom);
    // }

    console.log("New Articles = ", _newArticle);
    // console.log("Articles = ", _articleRoom);
    // console.log("New Room = ", _newRoom);

    // console.log("New Articles = ", _articles);
    // console.log("Articles = ", _articleRoom);
    // console.log("New Room = ", _newRoom);

    // setArticleRoom(_newRoom);
    // setArticleStay(_articles);
    setArticleRoom(_newArticle.room);
    setArticleStay(_newArticle.article);
  };

  // HANDLE TOTAL BILL
  const handleTotal = (room, stay) => {
    // let totalNett = 0;
    // let totalTax = 0;
    // let totalService = 0;
    // let totalGross = 0;

    const _room = room;
    const _stay = stay;

    const _join = [..._room, ..._stay];

    console.log("Article Room => ", _room);
    console.log("Article Stay => ", _stay);

    if (_join.length > 0) {
      let _total = PreTotalPriceFunction(_join);

      console.log("TOTAL: ", _total);

      setDataBill([..._room, ..._stay]);
      setTotalPrice({
        nett: _total.nett,
        service: _total.service,
        tax: _total.tax,
        gross: _total.gross,
      });
      totalPayment({
        nett: _total.nett,
        service: _total.service,
        tax: _total.tax,
        gross: _total.gross,
      });

      if (data?.night > 1) {
        const _grouping = GroupingArticle(_join);

        console.log("Group results: ", _grouping);
        setDataBill(_grouping);
      } else {
        setDataBill(_join);
      }
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1250);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    PrintCount({
      data: data,
      is_update: true,
      counter_list: {
        pre_master_bill: countPrint?.pre_master_bill + 1,
        pre_bill: countPrint?.pre_bill,
        checkout_letter_history: countPrint?.checkout_letter_history,
        registration_form: countPrint?.registration_form,
        guarantee_letter: countPrint?.guarantee_letter,
        thankyou_letter: countPrint?.thankyou_letter,
        bill: countPrint?.bill,
        master_bill: countPrint?.master_bill,
        reservation_letter: countPrint?.reservation_letter,
      },
    });

    handlePrintOut();
  };

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Bill ${
      data?.reservation_id ? data.reservation_id : " ---- "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  // CONSOLE LOG
  // console.log("DATA >>>", data);
  // console.log("Data Master Bill >>>", dataBill);

  return (
    <>
      <Form
        name="resv-form-modal"
        className="resv-form-modal"
        form={form}
        labelCol={{ span: 12 }}
        labelWrap={{ span: 12 }}
        labelAlign="left"
        style={{
          width: "100%",
        }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        //   initialValues={handleShow}
        //   onChange={handleChange}
        autoComplete="off"
        layout="horizontal"
      >
        <Row
          justify="end"
          align="middle"
          className="row-form-btn"
          style={{
            padding: "30px 30px 15px",
            // margin: 30,
          }}
        >
          <Button
            className="refresh-btn"
            icon={<SyncOutlined />}
            type="default"
            onClick={fetchMasterBill}
            style={{
              margin: "0px 30px 0px 0px",
            }}
          >
            {`Refresh`}
          </Button>

          <Button
            className="print-btn"
            type="primary"
            htmlType="submit"
            icon={<PrinterOutlined />}
            // onClick={() => {
            //   handlePrint();
            //   if (counter == 0) {
            //     myclick();
            //   }
            // }}
            // style={{
            // backgroundColor: "#1BC5BD",
            // borderColor: "#1BC5BD",
            //   marginRight: 30,
            // }}
          >
            {`Print & Download`}
          </Button>
        </Row>

        <Divider
          className="divider-form"
          style={{ margin: 0, backgroundColor: "#000000" }}
        />

        {!isLoading ? (
          <Content
            ref={componentRef}
            className="content-receipt-form"
            style={{ padding: "30px 80px 30px 80px" }}
          >
            <Row gutter={30} className="row-header">
              <Col span={12} className="col-title-form">
                <Typography.Title
                  level={2}
                  className="text-form-title"
                  style={{ margin: 0 }}
                >
                  {`Bill Transaction List`}
                </Typography.Title>

                <Typography.Title
                  level={4}
                  className="text-form-title"
                  style={{ margin: 0 }}
                >
                  {is_group == true ? "(Join Bill)" : ""}
                </Typography.Title>

                <Typography.Title
                  level={4}
                  className="text-form-title"
                  style={{ margin: 0 }}
                >
                  {is_early == true ? "Early Check-Out" : ""}
                </Typography.Title>
              </Col>

              <Col span={12} align="middle" className="col-img-logo">
                <Image
                  // src="/looklogo(color).png"
                  src="/ideas.png"
                  className="img-form-logo"
                  preview={false}
                  style={{
                    width: "20%",
                  }}
                />

                <Typography.Title level={4} className="text-form-id">
                  {`Idea's Hotel`}
                </Typography.Title>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-receipt-no"
              style={{ height: 30, width: "100%", margin: 0 }}
            >
              <Form.Item
                label="No"
                className="receipt_number"
                style={{ width: "100%" }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                {}
              </Form.Item>
            </Row>

            <Row
              className="row-date"
              style={{ height: 30, width: "100%", margin: 0 }}
            >
              <Form.Item
                label="Date"
                className="date"
                style={{ width: "100%" }}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Typography className="date-text" style={textForm}>
                  {dataBill.length > 0
                    ? moment().format("HH:mm, DD-MM-YYYY")
                    : ""}
                </Typography>
              </Form.Item>
            </Row>

            <Row className="row-titles" justify="start">
              <Col span={12}>
                <Typography
                  className="text-payment"
                  style={{
                    margin: "15px 0px 0px",
                    fontSize: 12,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Payment Data`}
                </Typography>
              </Col>

              <Col span={12}>
                <Typography
                  className="text-guest"
                  style={{
                    margin: "15px 0px 0px",
                    fontSize: 12,
                    fontWeight: 750,
                    // color: "grey",
                  }}
                >
                  {`Data Guest`}
                </Typography>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: 0, backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-guest-details"
              style={{
                width: "100%",
                // height: 40,
              }}
            >
              <Col span={12}>
                <Form.Item
                  label="Payment Type"
                  className="payment_type"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-type" style={textForm}>
                    {`${data?.payment_type ? data.payment_type : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Payment Details"
                  className="payment_details"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${data?.payment_details ? data.payment_details : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Payment Status"
                  className="payment_status"
                  // style={{ width: 450 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-payment-details" style={textForm}>
                    {`${
                      data?.payment_status == 3
                        ? "Cash Basis"
                        : data?.payment_status == 2
                        ? "Charge To Room"
                        : ""
                    }`}
                  </Typography>
                </Form.Item>
              </Col>

              <Col span={12} className="col-res-id">
                <Form.Item
                  label="Res. No"
                  className="reservation_id"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${data?.reservation_id ? data.reservation_id : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Guest Name"
                  className="guest_name"
                  style={{ margin: 0 }}
                  // labelCol={{ span:  }}
                  // wrapperCol={{ span: 20 }}
                >
                  <Typography className="text-guest-name" style={textForm}>
                    {`${data?.guest_name ? data.guest_name : ""}`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Arrival"
                  className="arrival"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${
                      data?.arrival
                        ? moment(data.arrival).format("DD-MM-YYYY")
                        : ""
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Departure"
                  className="departure"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${
                      is_early
                        ? moment().format("DD-MM-YYYY")
                        : moment(data?.departure).format("DD-MM-YYYY")
                    }`}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Night(s)"
                  className="night"
                  style={{ margin: 0 }}
                  // labelCol={{ span: 5 }}
                  // wrapperCol={{ span: 19 }}
                >
                  <Typography className="text-res-id" style={textForm}>
                    {`${
                      is_early
                        ? moment().diff(moment(data?.arrival), "days")
                        : data?.night
                    }`}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>

            <Row justify="start">
              <Typography style={textForm}>{`Transaction Details`}</Typography>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: 0, backgroundColor: "#d9d9d9" }}
            />

            <Row
              className="row-table"
              justify="start"
              style={{ padding: "10px 0px" }}
            >
              <Table
                className="transaction-table"
                key="transaction-table"
                name="transaction-table"
                loading={loading}
                columns={columns}
                dataSource={dataBill}
                bordered={true}
                size="small"
                style={{
                  padding: 5,
                  border: "1px solid #000000",
                }}
                pagination={false}
                rowKey={(record) => (record.id > 0 ? record.id : record.key)}
                summary={() => {
                  return (
                    <>
                      <Table.Summary fixed>
                        {bill_type ? (
                          <>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={3} />

                              <Table.Summary.Cell index={3}>
                                <b>Subtotal</b>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell index={4} align="right">
                                {`${currency} ${totalPrice.nett}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={3} />

                              <Table.Summary.Cell index={3}>
                                <b>Service</b>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell index={4} align="right">
                                {`${currency} ${totalPrice.service}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>

                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0} colSpan={3} />

                              <Table.Summary.Cell index={3}>
                                <b>Tax</b>
                              </Table.Summary.Cell>

                              <Table.Summary.Cell index={4} align="right">
                                {`${currency} ${totalPrice.tax}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </Table.Summary.Cell>
                            </Table.Summary.Row>
                          </>
                        ) : null}

                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={3} />

                          <Table.Summary.Cell index={3}>
                            <b>Grand Total</b>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell index={4} align="right">
                            <b>
                              {`${currency} ${totalPrice.gross}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </b>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    </>
                  );
                }}
              />
            </Row>

            <Typography className="text-notes" style={{ fontSize: 10 }}>
              *notes: please, recheck your transactions again before paying!
            </Typography>

            {bill_type ? null : (
              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: all prices shown are already include tax & service
              </Typography>
            )}

            <Row
              className="signature"
              justify="end"
              style={{ padding: "30px 0px 0px" }}
            >
              <Col span={12} />

              <Col
                span={12}
                style={{
                  textAlign: "center",
                }}
              >
                {`Guest Signature`}

                <div
                  className="box-sign"
                  style={{
                    border: "1px solid #000000",
                    borderRadius: 5,
                    height: 60,
                  }}
                />
              </Col>
            </Row>
          </Content>
        ) : (
          <LoadingComponents />
        )}
      </Form>
    </>
  );
}
