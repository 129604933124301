// Import React Components
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { roles, token, user_name } from "../../../API/Global/Payload";
import {
  authorized_by,
  authorized_token,
  handleAuthorization,
} from "../../../API/Global/Permission";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// BASE API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Col,
  Row,
  Tabs,
  Space,
  Divider,
  Input,
  DatePicker,
  TimePicker,
  Select,
  InputNumber,
  Button,
  Checkbox,
  Modal,
  Form,
  Typography,
  Alert,
  Grid,
  Radio,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  PlusOutlined,
  DeleteOutlined,
  DeleteFilled,
  SwitcherFilled,
  SwitcherTwoTone,
  UpCircleOutlined,
  DownCircleOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { MdOutlineDashboardCustomize } from "react-icons/md";

// Import Notification
import {
  failedAddReserv,
  failedArticleStay,
  failedFetch,
  incomplete,
  successAddReserv,
  successArticleStay,
} from "../../Reusable/Notification/Notification";
import {
  failedDeposit,
  masterFailedNotification,
  successDeposit,
} from "../../Reusable/Notification/MasterNotif/Notification";

// Import Page Components
import ReservationRoomRateTable from "../../Table/FrontOffice/AddReservation/Table";
import ModalRoomPick from "../../Modals/RoomPick/RoomPick";
import ReservationForm from "../../Forms/Reservation/Form";
import RoomavailabilityTable from "../../Table/FrontOffice/RoomAvailability/Table";
import ReservationTable from "../../Table/FrontOffice/Reservation/Table";

// Master Components
import MasterGuestCat from "../../Reusable/Master/GuestCat/GuestCat";
import MasterPaymentType from "../../Reusable/Master/PaymentType/PaymentType";
import MasterCurrency from "../../Reusable/Master/Currency/Currency";
import MasterReservationStatus from "../../Reusable/Master/ResStatus/ResStatus";
import MasterRateCode from "../../Reusable/Master/RateCode/RateCode";
import MasterComplimentType from "../../Reusable/Master/Compliment/Compliment";
import MasterPaymentCard from "../../Reusable/Master/PaymentCard/PaymentCard";
import MasterSegment from "../../Reusable/Master/Segment/Segment";
import MasterSource from "../../Reusable/Master/Source/Source";
import MasterGuest from "../../Reusable/Master/Guest/Guest";
import MasterSalesman from "../../Reusable/Master/Salesman/Salesman";

import MasterBoolean from "../../Reusable/Master/Boolean/Boolean";
import MasterSubSegment from "../../Reusable/Master/Segment/SubSegment";
import MasterArrange from "../../Reusable/Master/Arrange/Arrange";
import MasterRoomCat from "../../Reusable/Master/RoomCat/RoomCat";
import MasterGroup from "../../Reusable/Master/Group/Group";
import MasterInputAdult from "../../Reusable/Master/InputNumber/InputAdult";
import MasterInputChild from "../../Reusable/Master/InputNumber/InputChild";
import MasterInputInfant from "../../Reusable/Master/InputNumber/InputInfant";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// STYLING
import {
  styleActive,
  styleInactive,
  styleError,
} from "../../../Style/Global/Global";

// Global Breakpoint
import { Breakpoint } from "../../../Style/Breakpoint/Breakpoint";

// NIGHT AUDIT DATE
import AuditDate from "../../Reusable/DateAudit/Date";
import ParametersFrontOffice from "../../Reusable/Parameters/ParamsFO";
import ParametersBreakfast from "../../Reusable/Parameters/ParamsBF";

// TAB PANE of TABS
const { TabPane } = Tabs;
const { TextArea } = Input;
// const { useBreakpoint } = Grid;

// ON CHANGE
const onChangeTime = (time, timeString) => {
  console.log("ETA/ETD: ", time, timeString);
};

// CODE
export default function AddReservation() {
  // CONTEXT
  const { getRatePrice } = useContext(MainContext);
  const { getMasterSegment } = useContext(MasterContext);
  const {
    getMasterRatePeriod,
    getMasterPricePeriod,
    getMasterRatePrice,
    getMasterRateRoomNo,
    getMasterSegmentRoom,
  } = useContext(MasterContext);

  // PARAMS
  const currency = CurrencySymbols().code;

  // GET DATE
  const date = new Date(Date.now());
  const _date = AuditDate().audit;

  const disabledDate = (current) => {
    // Can not select days before today and today
    // console.log(current);

    return current <= moment(_date);
  };

  const disabledEndDate = (current) => {
    // Can not select days before today and today
    // console.log(current);

    return current <= moment(startDate ? startDate : _date);
  };

  // STATE MANAGEMENT
  // ADD NEW RESERVATION
  // Data Reservation
  const [reservationData, setReservationData] = useState([]);
  // Night
  // const [night, setNight] = useState(0);
  // Date
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Payment
  const [paymentType, setPaymentType] = useState({
    pay_id: 0,
    pay_name: "",
  });
  const [paymentCard, setPaymentCard] = useState({
    card_id: 0,
    card_name: "",
  });
  const [payment_status, setPayment_status] = useState(null);
  // Room
  const [roomCode, setRoomCode] = useState([]);
  // Active Key Tab Pane
  const [activeKey, setActiveKey] = React.useState("1");
  // ROOM RATE
  const [segmentID, setSegmentID] = useState(null);
  const [subSegment, setSubSegment] = useState({
    source_id: 0,
    sales_id: 0,
  });
  const [roomList, setRoomList] = useState([]);
  const [finalRoomList, setFinalRoomList] = useState([]);
  const [finalDetailRoomList, setFinalDetailRoomList] = useState([]);
  const [roomQty, setRoomQty] = useState(0);
  const [roomPrice, setRoomPrice] = useState();
  // Adult Value
  const [people, setPeople] = useState(0);
  // PRICE ROOM
  const [rCode, setRcode] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({
    value: "",
    guest_id: 0,
    rate_code: [],
    segment: [],
    rate_id: [],
    segment_id: [],
    company_name: "",
    company_id: 0,
    is_allow: false,
    credit_limit: 0,
  });
  const [segmentation, setSegmentation] = useState(null);
  const [informations, setInformations] = useState(null);
  // Guest ID
  const [guestId, setGuestId] = useState(null);
  // RESERVATION ID
  const [created, setCreated] = useState(false);
  // MODAL STATE ROOM AVAILABILITY
  const [open, setOpen] = useState(false);
  const [fieldValue, setFieldValue] = useState(null);
  const [isComply, setIsComply] = useState({
    compliment_id: 2,
    compliment_value: false,
    compliment_label: "No Compliment",
  });
  const [compliment, setCompliment] = useState(null);

  // BOOLEAN STATE
  const [permitted, setPermitted] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [isBreakfast, setIsBreakfast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // ERROR STATE
  const [error, setError] = useState(false);
  const [renderCount, setRenderCount] = useState(0);
  const [isFailed, setIsFailed] = useState(false);

  // SEGMENT CONDITION
  const [dynamic, setDynamic] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [disableDeposit, setDisableDeposit] = useState(true);

  // TOTAL RESV FORM
  const [resetModal, setResetModal] = useState(false);

  // DEFAULT RATE CODE
  const [defaultRate, setDefaultRate] = useState(null);

  // Form Modal
  const [openResv, setOpenResv] = useState(false);

  let totalNight = moment(endDate).diff(startDate, "days");

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE & LOCATION
  const navigate = useNavigate();
  const { state } = useLocation();

  // PARAMS
  const params_coa = ParametersFrontOffice().params || FrontOfficeParams();
  const breakfast = ParametersBreakfast().breakfast;

  // optionValue
  const [optionValue, setOptionValue] = useState([]);

  // console.log("Params FO: ", params_coa);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (state && renderCount === 0 && !cleanUp) {
      console.log("State MASTER: ", state);

      getGuestFromState(state);
      setRenderCount(renderCount + 1);
    }
    fetchDataSegment();

    return () => {
      cleanUp = true;
    };
  }, []);

  // CREATED DATA
  useEffect(() => {
    let cleanUp = false;

    if (created == true && !cleanUp) {
      console.log("Supposed to be create new Reservations: ", created);
      const _error = {
        errorFields: [
          {
            errors: [
              "Failed to Create Reservation Because of Parameter's not be Found or not yet been setted!",
            ],
          },
        ],
      };

      if (breakfast) {
        console.log("BF Params: ", breakfast);
        // handleSubmit(reservationData);
        handleSubmit2(reservationData);
        setCreated(false);
      } else {
        incomplete(_error);
        // error.errorFields[0].errors[0]
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [created]);

  // Fetch Data
  const fetchDataSegment = async () => {
    await getMasterSegment({
      segment: "main-segment",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        // if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
        //   let _response = response.data.msg;

        //   let filtered = _response.filter((filt) => {
        //     if (filt.is_deleted == false || filt.is_deleted == null) {
        //       return filt;
        //     }
        //   });

        //   let option = filtered.map((dev) => ({
        //     value: dev?.main_segment_name,
        //     label: dev?.main_segment_name,
        //     code: dev?.main_segment_code,
        //     segment_id: dev?.id,
        //     key: dev?.id,
        //     className: dev?.id,
        //     is_dynamic: dev?.is_dynamic,
        //     is_authorized: dev?.is_authorized,
        //   }));

        //   console.log("SEGMENT = ", option);

        //   setOptionValue(option);
        // } else {
        //   setOptionValue([]);
        // }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
      },
    });
  };

  const onKeyChange = (key) => {
    // console.log(key);
    setActiveKey(key);
  };

  // HANDLE START DATE AND END DATE
  // Get Start Date
  const handleFirstDate = (first) => {
    const _room = form.getFieldsValue();
    const start = first ? moment(first.format()).toArray() : null;

    setStartDate(start);

    // console.log("start date", start);
    // console.log("First Date --> ", _room);
    console.log("End Date --> ", endDate);

    if (endDate) {
      let ngt = moment(endDate).diff(start, "days");

      console.log("Night = ", ngt);

      setResetModal(true);
      form.setFieldsValue({
        night: ngt,
        room_qty: 1,
      });
    } else {
      form.setFieldsValue({
        night: 1,
        room_qty: 1,
        adult: 1,
        child: 0,
        infant: 0,
        departure: moment(start).add(1, "days"),
      });
    }

    if (_room?.room_category?.length > 0) {
      form.resetFields([
        "room_category",
        "room_category_id",
        "room_number",
        "room_price",
        "room_modal",
        "room_qty",
      ]);
    }

    setRoomList([]);
    setRoomPrice([]);
  };

  // Get End Date
  const handleLastDate = (last) => {
    const end = last ? moment(last.format()).toArray() : null;

    setEndDate(end);
    // console.log("last date", end);

    let ngt = moment(end).diff(startDate, "days");

    if (roomCode?.length > 0) {
      console.log(roomCode);

      form.setFieldsValue({
        room_category: null,
        is_abf: false,
      });
      setRoomCode([]);
      setRoomList([]);

      setIsBreakfast(false);
      setResetModal(true);
    }
    // console.log(roomList);
    // console.log(roomPrice);
    // console.log(ngt);

    form.setFieldsValue({
      night: ngt,
      room_qty: 1,
      adult: 1,
      room_category: [],
      room_category_id: [],
      room_modal: [],
      room_number: [],
      room_price: [],
    });
  };

  const onChangeNight = async (e) => {
    const night = e;
    let _newEnd = endDate ? endDate : moment();
    // console.log("Night: ", night);

    if (startDate) {
      _newEnd = moment(startDate).add(night, "days");

      setEndDate(_newEnd);
      setRoomCode([]);
      setRoomList([]);

      setIsRender(true);
      setInformations(null);
      setResetModal(true);
      // setIsBreakfast(false);

      form.setFieldsValue({
        night: night,
        departure: _newEnd,
        room_category: [],
        room_category_id: [],
        room_modal: [],
        room_number: [],
        room_price: [],
      });
    }

    setRoomList([]);
    setRoomPrice([]);
  };

  // HANDLE MASTER
  // Arrangement
  const getArrange = (value) => {
    form.setFieldsValue({
      arrangement: value,
    });
  };

  // Guest Status
  const getCategory = (value) => {
    form.setFieldsValue({
      guest_category: value,
    });
  };

  // Payment Type
  const getPaymentType = (value, code, name) => {
    console.log(value, code, name);
    setPaymentType({
      pay_id: value?.id || 0,
      pay_name: value?.name || "",
    });

    console.log("Pay Code: ", paymentCard);

    form.setFieldsValue({
      payment_type: value?.name,
    });
  };

  // Payment Type ID
  const getPayTypeId = (value) => {
    console.log(value);
  };

  // Payment Details/Card
  const getPayCard = (value, code, name) => {
    console.log("CARDS: ", value, code, name);
    const fieldvalue = form.getFieldsValue();

    // console.log(fieldvalue);

    setPaymentCard({
      card_id: value?.id,
      card_name: value?.name,
      chart_of_account: value?.coa,
    });

    form.setFieldsValue({
      payment_details: value?.name,
    });
  };
  // Currency
  const getCurrency = (value) => {
    form.setFieldsValue({
      currency: value,
    });
  };
  // Reservation Status
  const getResStatus = (value) => {
    form.setFieldsValue({
      reservation_status: value,
    });
  };
  // Segment
  const getSegment = (value, id, code, dynamic, authorized) => {
    console.log(
      "Segment: ",
      value,
      " ID: ",
      id,
      " is_dynamic: ",
      dynamic,
      " is_authorized: ",
      authorized
    );

    setSegmentID(id);
    setSegmentation(value);
    setDynamic(dynamic);
    setAuthorized(authorized);

    if (roomList?.length > 0) {
      setRoomList([]);
    }

    setInformations(null);

    form.setFieldsValue({
      segment: value,
      // compliment_type: 2,
      abf_total: 0,
    });
  };
  const getSubSegment = (value, id) => {
    console.log(value, id);

    setSubSegment(id);

    if (roomList?.length > 0) {
      setRoomList([]);
    }

    form.setFieldsValue({
      sub_segment: value,
    });
  };
  // Compliment Type
  const getCompliment = (value, key, allKey) => {
    console.log(value, key);
    console.log("Compliment AllKey", allKey);

    if (value) {
      setCompliment(value);
    } else {
      null;
    }

    if (key == 1 || key == 3) {
      setIsComply({
        compliment_id: key,
        compliment_value: true,
        compliment_label: allKey?.value,
      });
    } else {
      setIsComply({
        compliment_id: key,
        compliment_value: false,
        compliment_label: allKey?.value,
      });
    }

    form.setFieldsValue({
      compliment_type: key,
    });
  };
  // Rate Code
  const getRateCode = (value, code) => {
    console.log("RATE CODE = ", value);
    console.log("RATE CODE ID = ", code);

    // setRateCode(code);
    // setPeriodEmpty(true);
    if (code?.description) {
      setInformations(code?.description);
    }

    handleRate(value, code);
    setDefaultRate(code);

    form.setFieldsValue({
      rate_code: value,
    });
  };

  // Options
  const getOptions = (value) => {
    console.log("optionValue on Tabs", value);
    setOptionValue(value);
  };

  // Source
  const getSource = (value) => {
    console.log("Sources: ", value);

    setSubSegment({
      sales_id: subSegment.sales_id,
      source_id: value?.source_id,
    });

    form.setFieldsValue({
      source: value?.name,
    });
  };
  // Sales
  const getSalesman = (value, opt) => {
    console.log("Value: ", value);
    // console.log("OPT: ", opt);

    setSubSegment({
      sales_id: opt?.sales_id,
      source_id: subSegment.source_id,
    });

    form.setFieldsValue({
      sales: value,
    });
  };

  // Handle Deposit
  const handleDeposit = (val, code) => {
    console.log("Val: ", val, " - Code: ", code);

    form.setFieldsValue({
      deposit_status_id: val,
    });
  };

  // Handle Deposit Disable
  const handleDisable = (val) => {
    if (val > 0) {
      setDisableDeposit(false);
    } else {
      setDisableDeposit(true);
    }
  };

  // Room Category
  const setRoom = async (value) => {
    console.log("SET ROOM: ", value);

    const room_set = value;

    let cat = [];
    let r_code = [];
    let r_num = [];
    let r_qty = [];
    let r_id = [];
    // let r_no = []
    // cat.length > 1 ? "this" : cat.length > 2 ? "that" : "those";

    for (let i = 0; i < value.length; i++) {
      // const element = value[i];

      cat.push(room_set[i].room_category);

      r_code.push(room_set[i].room_code);

      r_num.push(room_set[i].room_no);

      r_qty.push(room_set[i].room_qty);

      r_id.push(room_set[i].room_category_id);

      // console.log("ISI ROOM CAT >> ", cat);
      // console.log("ISI ROOM QUANTITY >> ", r_qty);
    }

    setRcode(r_code);
    console.log(r_num.map((e) => e).flat());

    let toy = r_qty.reduce(function (s, v) {
      return s + (v || 0);
    }, 0);

    setRoomQty(toy);

    // console.log(toy);

    // Set Fields
    form.setFieldsValue({
      room_category: cat,
      room_code: r_code,
      room_category_id: r_id,
      room_qty: toy,
      room_number: r_num.map((e) => e).flat(),
      room_modal: room_set,
      // rate_code: null,
      // rate_code: value.length > 0 ? value[0].rate_code_name : "",
      // compliment_type: "Room Only",
    });

    setIsRender(true);
    setRoomList([]);
    setInformations(null);
    setSegmentation(null);

    // setPeople(cat.length);
    // handleRate(r_code);
  };

  // SET GUEST NAME
  const getGuestFromState = (value) => {
    const _state = value;

    console.log("STATE RERENDER GUEST! ", _state);

    setCompanyInfo({
      value: _state?.full_name,
      guest_id: _state?.id > 0 ? _state.id : 0,
      rate_code: _state?.rate_code?.length > 0 ? _state.rate_code : [],
      rate_id: _state?.rate_code_id?.length > 0 ? _state.rate_code_id : [],
      segment_id:
        _state?.main_segment_id?.length > 0 ? _state.main_segment_id : [],
      segment: _state?.segment?.length > 0 ? _state?.segment : [],
      company_name: _state?.company_name || null,
      company_id: _state?.master_comp_id > 0 ? _state.master_comp_id : 0,
      is_allow: _state?.allow_ledger,
      credit_limit: _state?.credit_limit > 0 ? _state.credit_limit : 0,
    });

    form.setFieldsValue({
      guest_name: _state?.full_name,
      bill_instruction: _state?.full_name,
      bill_receiver: _state?.full_name,
    });

    setGuestId(_state?.id);
  };

  // GUEST NAME, BILL RECEICER & BILL INSTRUCTION
  const getGuestName = (value) => {
    const _guestData = value;

    console.log("Guest Master Data: ", value);

    setIsRender(true);

    setGuestId(_guestData.guest_id);
    // setSegmentation(_guestData?.segment ? _guestData?.segment[0] : null);

    setIsRender(true);
    setSegmentation(
      _guestData?.segment?.length > 0 ? _guestData.segment[0] : null
    );

    if (roomList?.length > 0) {
      setRoomList([]);
    }

    if (value) {
      setCompanyInfo({
        value: _guestData?.value,
        guest_id: _guestData?.guest_id > 0 ? _guestData.guest_id : 0,
        rate_code:
          _guestData?.rate_code?.length > 0 ? _guestData.rate_code : [],
        rate_id: _guestData?.rate_id?.length > 0 ? _guestData.rate_id : [],
        segment_id:
          _guestData?.segment_id?.length > 0 ? _guestData.segment_id : [],
        segment: _guestData?.segment?.length > 0 ? _guestData?.segment : [],
        company_name: _guestData?.company_name || null,
        company_id: _guestData?.company_id > 0 ? _guestData.company_id : 0,
        is_allow: _guestData?.is_allow,
        credit_limit:
          _guestData?.credit_limit > 0 ? _guestData.credit_limit : 0,
      });
    } else {
      setCompanyInfo({
        value: "",
        guest_id: 0,
        rate_code: [],
        segment: [],
        rate_id: [],
        segment_id: [],
        company_name: "",
        company_id: 0,
        is_allow: false,
        credit_limit: 0,
      });
    }

    form.setFieldsValue({
      guest_name: _guestData.value,
      bill_receiver: _guestData.value,
      bill_instruction: _guestData.value,
      bill_receiver_id: _guestData.guest_id,
      segment: _guestData?.segment ? _guestData?.segment[0] : null,
    });
  };

  // BILL RECEIVER
  const getBillReceiver = (value) => {
    const _guestData = value;

    console.log("Bill Receiver: ", value);

    form.setFieldsValue({
      bill_receiver: _guestData.value,
      bill_receiver_id: _guestData.guest_id,
    });
  };

  // GROUP CODE
  const getGroup = (value) => {
    form.setFieldsValue({
      group: value,
    });
  };
  // GET PRICE
  const getPrice = (value) => {
    console.log("ROOM PRICE === ", value);

    form.setFieldsValue({
      room_price: value,
    });
  };
  // GET ADULT
  const getAdult = (value) => {
    // console.log(value);
    setPeople(value);

    form.setFieldsValue({
      // child: value,
      adult: value,
      // infant: value,
    });
  };
  // RADIO STATUS PAYMENTS
  const onRadioChange = async (e) => {
    console.log("radio checked", e.target.value);

    setPayment_status(e.target.value);

    form.setFieldsValue({
      payment_status: e.target.value,
    });
  };

  // Navigate to Page
  const move = () => {
    // console.log(location);
    let auth = localStorage.getItem("permit");

    if (authorized_token || auth) {
      localStorage.clear();
      handleAuthorization(null);
    }

    navigate(`/front-office/reservation`);
  };

  // HANDLE PRICE
  const handleRate = async (value, code) => {
    const content = value;
    const rate_id = code?.id > 0 ? code.id : 0;
    const ngt = form.getFieldsValue();

    console.log("Handle Rate --> ", content);
    console.log("Handle Rate Code --> ", code);

    // await getMasterRatePrice({
    //   rate: "rate-code",
    //   category: "category-res",
    //   rate_code: content,
    //   room_cat_code: rCode.join("|"),
    //   segment: segmentID,
    //   sub_segment: subSegment,

    if (rate_id > 0) {
      await getRatePrice({
        rate: "rate-code",
        price: "master-room-price",
        rate_code: rate_id,
        onAwait: () => {
          "on Await";
        },
        onSuccess: (response) => {
          console.log("Rate Room Category >> ", response);

          // if (response?.data !== null) {
          if (response?.data?.msg?.length > 0) {
            const _res = response.data.msg;

            console.log("Res Fixed => ", _res);
            console.log("PARAMS => ", ngt);

            handleRoom(_res, ngt);
          }
        },
        onReject: (error) => {
          console.log("ERROR >>>> ", error);
          handleRoom([], ngt);

          masterFailedNotification(error, {
            method: 8,
            source: "Rate for Reservations",
          });
        },
      });
    } else {
      handleRoom([], ngt);
    }
  };

  // GENERATE ROOM PRICE
  const handleRoom = (res, params) => {
    // Array Container
    let rate = [];
    let group = [];
    // Assign Variables
    const _fixed = res;
    const _params = params;

    if (
      (_params?.room_category?.length == 1 && _params?.room_qty == 1) ||
      (_params?.room_category?.length > 1 && _params?.room_qty == 1)
    ) {
      console.log("First Condition Rate: 1 Category 1 Room Qty");
      // for (let i = 0; i < ngt.night; i++) {
      for (let j = 0; j < _fixed.length; j++) {
        let newRate = {
          ..._fixed[j],
          date: moment(startDate).add(j, "day"),
          room_category_name: _fixed[j]?.room_category_name
            ? _fixed[j]?.room_category_name
            : _params?.room_category,
          room_category_id:
            _params?.room_category_id?.length > 0
              ? _params.room_category_id[0]
              : 0,
          room_no:
            _params?.room_modal[j]?.room_no?.length > 0
              ? _params?.room_modal[j]?.room_no[j]
              : 0,
          id: _fixed[j]?.id + j,
          rate_code_id: _fixed[0]?.id,
          room_number_id:
            _params?.room_modal[j]?.room_number_id?.length > 0
              ? _params.room_modal[j].room_number_id[j]
              : 0,
          night: _params?.night > 1 ? _params.night : 1,
        };
        rate.push(newRate);
      }
      // }
    } else if (_params?.room_qty > 1 && _params?.room_category.length == 1) {
      console.log("Second Condition Rate: 1 Category more than 1 Room");

      for (let i = 0; i < _params?.room_qty; i++) {
        for (let j = 0; j < _fixed?.length; j++) {
          let newRate = {
            ..._fixed[j],
            date: moment(startDate),
            room_category_name: _fixed[j]?.room_category_name
              ? _fixed[j]?.room_category_name
              : _params?.room_category[0],
            room_number_id:
              _params?.room_modal[j]?.room_number_id?.length > 0
                ? _params.room_modal[j].room_number_id[i]
                : 0,
            room_no:
              _params?.room_modal[j]?.room_no?.length > 0
                ? _params.room_modal[j].room_no[i]
                : 0,
            room_number:
              _params?.room_modal[j]?.room_no?.length > 0
                ? _params.room_modal[j].room_no[i]
                : 0,
            rate_code_id: _fixed[0]?.id,
            room_category_id:
              _params?.room_category_id?.length > 0
                ? _params.room_category_id[0]
                : 0,
            id: _fixed[j]?.id + i,
          };

          // console.log(newRate);
          rate.push(newRate);
        }
      }
    } else if (
      // ngt.night == 1 &&
      _params?.room_qty > 1 &&
      _params?.room_category.length > 1
    ) {
      for (let h = 0; h < _params?.room_modal?.length; h++) {
        group.push({ ...params.room_modal[h], ..._fixed[0] });
      }

      console.log("Group: ", group);

      // _fixed.sort(
      //   (a, b) =>
      //     _params?.room_category.indexOf(a.room_category_name) -
      //     _params?.room_category.indexOf(b.room_category_name)
      // );

      console.log(
        "Third Condition Rate: More than 1 Category and Room: ",
        _fixed
      );

      for (let i = 0; i < group?.length; i++) {
        for (let j = 0; j < group[i].room_qty; j++) {
          let toy = {
            ...group[i],
            id: parseInt(group[0].id++),
            room_category_name: group[i]?.room_category
              ? group[i].room_category
              : null,
            room_number_id:
              group[i]?.room_number_id?.length > 0
                ? group[i].room_number_id[j]
                : 0,
            room_no: group[i]?.room_no?.length > 0 ? group[i].room_no[j] : 0,
            room_number:
              group[i]?.room_number?.length > 0 ? group[i]?.room_number[j] : 0,
            room_category_id:
              _params?.room_category_id?.length > 0
                ? _params.room_category_id[i]
                : 0,
            rate_code_id: _fixed[0]?.id,
            date: moment(startDate),
          };

          // rate = [...rate, toy];
          rate.push(toy);
          // console.log(`Loop i: ${i} & j: ${j} = `, group[i].room_no[j]);
          // console.log("Toy (New Object in Loops): ", toy);
        }
      }
    }

    console.log("Final Rate --> ", rate);
    setRoomList(rate);

    if (!_fixed[0]?.description) {
      setInformations(
        `${_fixed[0]?.room_category_name || "GROUP"} for ${
          _fixed[0]?.room_occupancy || 0
        } Adult(s) and ${_fixed[0]?.room_breakfast || 0} Pax of Breakfast(s)`
      );
    }

    form.setFieldsValue({
      room_price: rate,
      abf_total: _fixed[0]?.room_breakfast > 0 ? _fixed[0].room_breakfast : 0,
      is_abf: _fixed[0]?.room_breakfast > 0 ? true : false,
      segment: _fixed[0]?.main_segment_name,
      // rate_code: rate.length > 0 ? rate[0].rate_code_name : "",
      // compliment_type: "Room Only",
    });
  };

  // HANDLE ADULT
  const handleAdult = (value) => {
    const _adult = value.adult;
    const _qty = value.room_qty;

    let ad = 0;
    let last_ad = 0;

    ad = (_adult / _qty).toFixed(0);
    // misal 8, qty 3, 8/3 = 2
    if (_adult % _qty != 0) {
      last_ad = _adult - ad * _qty;
    }

    console.log("Adulting: ", ad, last_ad);

    return { adult: ad, last_adult: last_ad };
  };

  // ON SUBMIT FINISH
  const onFinish = async (e) => {
    let content = e;
    console.log("DATA SUBMIT ONFINISH >>> ", content);
    console.log("Params BF: ", breakfast);
    console.log("Data Res: ", reservationData);
    console.log("Room List: ", roomList);

    setReservationData(content);
    setError(false);

    // HANDLE ADULTS TOTAL
    if (content?.room_qty > 1 && content?.room_category.length >= 1) {
      let _adult = handleAdult(content);
      console.log("Handle Adult", _adult);

      content.adult = parseInt(_adult.adult);
      content.last_adult = _adult.last_adult;
    }

    // handleSubmit(e);
    if (content?.room_price?.length > 0) {
      setOpenResv(!openResv);
    } else {
      onFinishFailed({
        errorFields: [
          {
            errors: [
              "Please, make sure you already choose Room Category, Segment, and Rate Code to Get Room Rate!",
            ],
          },
        ],
      });
    }
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = async (error) => {
    console.log("Data Incomplete >>> ", error?.values);
    console.log("Payment Type: ", paymentType);
    console.log("Payment Cards: ", paymentCard);
    console.log("Fields Value form: ", fieldValue);
    console.log("Informations: ", informations);
    console.log("Segmentation: ", segmentation, segmentID);
    console.log("Room Code rCode: ", rCode);
    console.log("Room Code: ", roomCode);
    console.log("Room List: ", roomList);
    console.log("Room Price: ", roomPrice);
    console.log("Source & Sales: ", subSegment);
    // setReservationData(e);
    setError(true);

    incomplete(error);
  };

  // HANDLE DETAIL ROOM LIST
  const handleDetailRoomList = (arrRoomList) => {
    console.log("Arr Room List ->> ", arrRoomList);
    const flatResult = arrRoomList.flatMap((item) => {
      let arrDetails = [];
      let startDate = moment(item.date, "DD-MM-YYYY");

      for (let i = 0; i < totalNight; i++) {
        let currentDate = startDate.clone().add(i, "days");

        arrDetails.push({
          ...item,
          key: i,
          date: currentDate,
        });
      }

      return arrDetails;
    });

    return flatResult;
  };

  const handleFinalRoomDetails = (datas) => {
    console.log("Data Final Mut Source ->> ", datas);
    const flatFinalDetail = datas.flatMap((data, index) => {
      return data.details;
    });

    setFinalDetailRoomList(flatFinalDetail);

    // console.log("Final Detail Room ->> ", finalDetailRoom);
    // getFinalRoomList(finalDetailRoom);
  };

  // HANDLE SUBMIT 2
  const handleSubmit2 = async (value) => {
    let submitData = value;

    console.log("SUBMIT DATA >>> ", submitData);
    console.log("Room List >>> ", roomList);

    console.log("Final Detail Room List ->> ", finalDetailRoomList);

    // ASSIGN DETAIL ROOM LIST DATA
    const detailRoomList =
      finalDetailRoomList.length > 0
        ? finalDetailRoomList
        : totalNight > 1
        ? handleDetailRoomList(roomList)
        : roomList;

    console.log("Detail RoomList ", detailRoomList);
    console.log("Final Room List ", finalRoomList);

    let arr_key = [];
    let foreign_key = {
      payment_type_id: 0,
      payment_card_id: 0,
      room_category_id: 0,
      segment_id: 0,
      rate_code_id: 0,
      room_id: 0,
      sales_id: 0,
      source_id: 0,
      rate_code: "",
    };

    // Array Payments
    const _payTypes = [submitData?.payment_type];
    const _payCards = [submitData?.payment_details];

    if (submitData?.rate_code != null || submitData?.rate_code != undefined) {
      arr_key.push(submitData?.rate_code);
      arr_key.push(submitData?.room_price[0]?.main_segment_id);
      arr_key.push(submitData?.room_price[0]?.id);

      foreign_key = {
        segment_id:
          submitData?.room_price[0]?.main_segment_id > 0
            ? submitData.room_price[0].main_segment_id
            : 0,
        payment_card_id: paymentCard.card_id,
        payment_type_id: paymentType.pay_id,
        rate_code: submitData?.rate_code || "",
        rate_code_id: defaultRate?.id || 0,
        room_category_id:
          submitData?.room_modal?.length > 0
            ? submitData.room_modal[0].room_category_id
            : 0,
        room_id:
          submitData?.room_modal[0]?.room_number_id?.length > 0
            ? submitData.room_modal[0].room_number_id[0]
            : 0,
        sales_id: subSegment.sales_id,
        source_id: subSegment.source_id,
      };
    }

    let allBodyReq = {};

    console.log("ID Rate Room Array = ", arr_key);
    console.log("Foreign Array = ", foreign_key);
    console.log("Payments Array = ", _payTypes, _payCards);

    // GROUP OR INDIVIDUAL CHECKING
    if (roomList.length > 1) {
      // GROUP GUEST
      const _member = (finalRoomList.length > 0 ? finalRoomList : roomList)
        .map((item, index) => {
          // if (index !== 0) {
          return {
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? submitData.night.toString() : 0,
            room_category: item?.room_category_id ? item.room_category_id : 0,
            room_number: item?.room_no > 0 ? item.room_no : 0,
            room_qty: 1,
            adult:
              submitData?.adult > 0
                ? submitData.adult
                : parseInt(submitData.adult),
            child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
            infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            rate_code: item?.details[0]?.rate_code || "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            guest_name: submitData?.guest_name
              ? `${submitData.guest_name + " " + (index + 1)}`
              : "",
            bill_receiver:
              submitData?.bill_receiver_id > 0
                ? submitData.bill_receiver_id
                : 0,
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group: 2,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            deposit: submitData.deposit ? parseInt(submitData.deposit) : 0,
            payment_type: _payTypes.length > 0 ? _payTypes : [],
            payment_details: _payCards.length > 0 ? _payCards : [],
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            deposit_status_id:
              submitData?.deposit_status_id > 0
                ? submitData.deposit_status_id
                : 2,
            payment_status: submitData?.payment_status || null,
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? false
                : true,
            group_name: submitData?.group_name ? submitData.group_name : "",
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            // room_rate_id: arr_key ? arr_key : [0],
            room_rate_id:
              item?.details?.map((detail) =>
                detail?.rate_code !== null && detail?.rate_code !== undefined
                  ? detail.rate_code
                  : defaultRate?.value
              ) || submitData?.rate_code,
            price_id: item.price_id,
            guest_id: guestId,
            created_by: authorized_by ? authorized_by : user_name,
            // is_abf: submitData?.is_abf,
            is_abf: item?.details?.some(
              (detail) => detail.room_details === "Breakfast"
            ),
            deposit_code: "100",
            description_deposit: "",
            coa_deposit: "230-01-010",
            params_coa_deposit: "11",
            payment_type_id_deposit:
              paymentType?.pay_id > 0 ? paymentType.pay_id : 0,
            payment_card_id_deposit:
              paymentCard?.card_id > 0 ? paymentCard.card_id : 0,
            sales: submitData?.sales ? submitData.sales : "",
            // };
          };
        })
        .filter((item, index) => {
          return index !== 0 && item;
        });

      allBodyReq = {
        arrival: submitData?.arrival
          ? moment(submitData.arrival).format("YYYY-MM-DD")
          : "",
        departure: submitData?.departure
          ? moment(submitData.departure).format("YYYY-MM-DD")
          : "",
        night: submitData?.night ? submitData.night.toString() : 0,
        room_category:
          submitData?.room_category_id?.length > 0
            ? submitData.room_category_id[0]
            : 0,
        // room_number: roomList[0]?.room_no > 0 ? roomList[0].room_no : 0,
        room_number:
          finalRoomList.length > 0
            ? finalRoomList[0]?.room_no
            : roomList[0]?.room_no,
        room_qty: 1,
        adult:
          submitData?.last_adult > 0
            ? submitData.adult + submitData.last_adult
            : parseInt(submitData.adult),
        child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
        infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
        reservation_status: submitData?.reservation_status
          ? submitData.reservation_status
          : "",
        arrangement: foreign_key,
        // rate_code: submitData?.rate_code ? submitData.rate_code : "",
        rate_code: finalRoomList[0]?.details[0]?.rate_code || "",
        compliment_type: submitData?.compliment_type
          ? submitData.compliment_type
          : 2,
        currency: submitData?.currency ? submitData.currency : "IDR",
        guest_name: submitData?.guest_name ? submitData.guest_name : "",
        bill_receiver:
          submitData?.bill_receiver_id > 0 ? submitData.bill_receiver_id : 0,
        bill_instruction: submitData?.bill_instruction
          ? submitData.bill_instruction
          : "",
        arrival_flight: submitData?.arrival_flight
          ? submitData.arrival_flight
          : "",
        eta: submitData?.eta ? submitData.eta : null,
        departure_flight: submitData?.departure_flight
          ? submitData.departure_flight
          : "",
        etd: submitData?.etd ? submitData.etd : null,
        group:
          submitData?.room_category?.length == 1 && submitData?.room_qty == 1
            ? 0
            : 1,
        segment: submitData?.segment ? submitData.segment : "",
        source: submitData?.source ? submitData.source : "",
        letter: submitData?.letter ? submitData.letter : "",
        deposit: submitData.deposit ? parseInt(submitData.deposit) : 0,
        payment_type: _payTypes.length > 0 ? _payTypes : [],
        payment_details: _payCards.length > 0 ? _payCards : [],
        memo_room: submitData?.memo_room ? submitData.memo_room : "",
        payment_status: submitData?.payment_status || null,
        is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
        is_group:
          submitData?.room_category?.length == 1 && submitData?.room_qty == 1
            ? false
            : true,
        group_name: submitData?.group_name ? submitData.group_name : "",
        guest_category: submitData?.guest_category
          ? submitData.guest_category
          : "REGULAR",
        // room_rate_id: arr_key ? arr_key : [0],
        room_rate_id:
          finalRoomList[0]?.details?.map((detail) =>
            detail?.rate_code !== null && detail?.rate_code !== undefined
              ? detail.rate_code
              : defaultRate?.value
          ) || submitData?.rate_code,
        price_id: 1,
        guest_id: guestId,
        created_by: authorized_by ? authorized_by : user_name,
        is_abf:
          finalRoomList.length > 0
            ? finalRoomList[0].details.some(
                (detail) => detail.room_details === "Breakfast"
              )
            : submitData?.is_abf,
        deposit_code: "100",
        description_deposit: "",
        coa_deposit: "230-01-010",
        params_coa_deposit: "11",
        payment_type_id_deposit:
          paymentType?.pay_id > 0 ? paymentType.pay_id : 0,
        payment_card_id_deposit:
          paymentCard?.card_id > 0 ? paymentCard.card_id : 0,
        sales: submitData?.sales ? submitData.sales : "",
        member: _member,
      };
    } else {
      // INDIVIDUAL GUEST
      allBodyReq = {
        arrival: submitData?.arrival
          ? moment(submitData.arrival).format("YYYY-MM-DD")
          : "",
        departure: submitData?.departure
          ? moment(submitData.departure).format("YYYY-MM-DD")
          : "",
        night: submitData?.night ? submitData.night.toString() : 0,
        room_category:
          submitData?.room_category_id?.length > 0
            ? submitData.room_category_id[0]
            : 0,
        // room_number: roomList[0]?.room_no > 0 ? roomList[0].room_no : 0,
        room_number:
          finalRoomList.length > 0
            ? finalRoomList[0]?.room_no
            : roomList[0]?.room_no,
        room_qty: 1,
        adult:
          submitData?.last_adult > 0
            ? submitData.adult + submitData.last_adult
            : parseInt(submitData.adult),
        child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
        infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
        reservation_status: submitData?.reservation_status
          ? submitData.reservation_status
          : "",
        arrangement: foreign_key,
        // rate_code: submitData?.rate_code ? submitData.rate_code : "",
        rate_code: finalRoomList[0]?.details[0]?.rate_code || "",
        compliment_type: submitData?.compliment_type
          ? submitData.compliment_type
          : 2,
        currency: submitData?.currency ? submitData.currency : "IDR",
        guest_name: submitData?.guest_name ? submitData.guest_name : "",
        bill_receiver:
          submitData?.bill_receiver_id > 0 ? submitData.bill_receiver_id : 0,
        bill_instruction: submitData?.bill_instruction
          ? submitData.bill_instruction
          : "",
        arrival_flight: submitData?.arrival_flight
          ? submitData.arrival_flight
          : "",
        eta: submitData?.eta ? submitData.eta : null,
        departure_flight: submitData?.departure_flight
          ? submitData.departure_flight
          : "",
        etd: submitData?.etd ? submitData.etd : null,
        group:
          submitData?.room_category?.length == 1 && submitData?.room_qty == 1
            ? 0
            : 1,
        segment: submitData?.segment ? submitData.segment : "",
        source: submitData?.source ? submitData.source : "",
        letter: submitData?.letter ? submitData.letter : "",
        sales: submitData?.sales ? submitData.sales : "",
        deposit: submitData?.deposit ? parseInt(submitData.deposit) : 0,
        payment_type: _payTypes.length > 0 ? _payTypes : [],
        payment_details: _payCards.length > 0 ? _payCards : [],
        memo_room: submitData?.memo_room ? submitData.memo_room : "",
        payment_status: submitData?.payment_status || null,
        is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
        is_group:
          submitData?.room_category?.length == 1 && submitData?.room_qty == 1
            ? false
            : true,
        group_name: submitData?.group_name ? submitData.group_name : "",
        guest_category: submitData?.guest_category
          ? submitData.guest_category
          : "REGULAR",
        // room_rate_id: arr_key ? arr_key : [0],
        room_rate_id:
          finalRoomList[0]?.details?.map((detail) =>
            detail?.rate_code !== null || detail?.rate_code !== undefined
              ? detail.rate_code
              : defaultRate?.value
          ) || submitData?.rate_code,
        price_id: 1,
        guest_id: guestId,
        created_by: authorized_by ? authorized_by : user_name,
        // is_abf: submitData?.is_abf,
        is_abf:
          finalRoomList.length > 0
            ? finalRoomList[0]?.details?.some(
                (detail) => detail?.room_details === "Breakfast"
              )
            : submitData?.is_abf,
        deposit_code: "100",
        description_deposit: "",
        coa_deposit: "230-01-010",
        params_coa_deposit: "11",
        payment_type_id_deposit:
          paymentType?.pay_id > 0 ? paymentType.pay_id : 0,
        payment_card_id_deposit:
          paymentCard?.card_id > 0 ? paymentCard.card_id : 0,
      };
    }

    // MAPPING DETAIL ROOM RESERVATION FOR SUBMIT DATA
    const _roomReservation = detailRoomList?.map((item, index) => {
      // console.log(
      //   "Items: ",
      //   item?.room_rate_id?.some((item) => item !== null || item !== undefined)
      //     ? item.room_rate_id
      //     : [item?.rate_code, item?.main_segment_id, item?.id]
      // );
      // console.log("Items: ", item.room_rate_id);
      // console.log(
      //   "Items Nett: ",
      //   item.nett - breakfast?.unit_price * item?.room_breakfast
      // );
      // console.log(
      //   "Items Service: ",
      //   item.service - breakfast?.service * item?.room_breakfast
      // );
      // console.log(
      //   "Items Tax: ",
      //   item.tax - breakfast?.tax * item?.room_breakfast
      // );
      // console.log(
      //   "Items Gross: ",
      //   item.gross - breakfast?.gross * item?.room_breakfast
      // );

      return {
        room_price:
          item?.main_compliment_id == 2 && item?.nett > 0
            ? item.nett - breakfast?.unit_price * item?.room_breakfast
            : 0,
        gross:
          item?.main_compliment_id == 2 && item?.gross
            ? item.gross - breakfast?.gross * item?.room_breakfast
            : 0,
        service:
          item?.main_compliment_id == 2 && item?.service > 0
            ? item.service - breakfast?.service * item?.room_breakfast
            : 0,
        tax:
          item?.main_compliment_id == 2 && item?.tax > 0 > 0
            ? item.tax - breakfast?.tax * item?.room_breakfast
            : 0,
        room_number: item?.room_no,
        date: item?.date ? moment(item.date).format("YYYY-MM-DD") : null,
        room_rate_id: item?.room_rate_id?.some(
          (item) => item !== null || item !== undefined
        )
          ? item.room_rate_id
          : [item?.rate_code, item?.main_segment_id, item?.rate_code_id],
        price_id: item?.price_id,
        is_tax: true,
        is_service: true,
        created_by: authorized_by ? authorized_by : user_name,
        main_segment_id: item?.main_segment_id,
        main_compliment_id: item?.main_compliment_id,
        room_category: item?.room_category_id,
        chart_of_account: "",
        rate_code_id: item?.rate_code_id
          ? item.rate_code_id
          : defaultRate?.value,
      };
    });

    // CHECK DETAIL ROOM LIST DATA CONTAINS THE VALUE "Breakfast" or NOT
    let is_abf = detailRoomList.some(
      (item) => item.room_details === "Breakfast"
    );

    // ABF CHECKING
    if (is_abf) {
      // IF TRUE
      // RESERVATIONS WITH BREAKFAST
      const _abfReservation = detailRoomList
        .filter((item) => item.room_details === "Breakfast")
        .map((item, index) => {
          return {
            article_id: breakfast?.id,
            article_sub_name: breakfast?.article_sub_name,
            article_sub_id: breakfast?.article_sub_id,
            article_name: breakfast?.article_name,
            unit_price:
              submitData.compliment_type != 2 ? 0 : breakfast?.unit_price,
            created_by: authorized_by ? authorized_by : user_name,
            item_quantity: item?.room_breakfast > 0 ? item.room_breakfast : 0,
            total_price:
              submitData.compliment_type != 2
                ? 0
                : item?.room_breakfast * breakfast?.gross,
            // gross breakfast * qty breakfast
            room_number: item?.room_no,
            posting_type: "",
            total_posting: "",
            start_date: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            end_date: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            chart_of_account: breakfast?.chart_of_account,
            is_tax: breakfast?.is_tax,
            tax: submitData?.compliment_type == 2 ? breakfast?.tax : 0,
            is_service: breakfast?.is_service,
            service: submitData?.compliment_type == 2 ? breakfast?.service : 0,
            price_id: item?.price_id,
            gross: submitData?.compliment_type == 2 ? breakfast?.gross : 0,
            order_date: moment(item?.date).format("YYYY-MM-DD"),
          };
        });

      allBodyReq = {
        ...allBodyReq,
        room_reservasi: _roomReservation,
        abf_reservasi: _abfReservation,
      };
    } else {
      // IF FALSE
      // RESERVATIONS W/O BREAKFAST
      allBodyReq = {
        ...allBodyReq,
        room_reservasi: _roomReservation,
      };
    }

    console.log("Final Body: ", allBodyReq);

    await axios
      .post(`${baseurl}/reservation/stay-all`, allBodyReq, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (response) => {
        console.log("Create >> ", response);

        // NOTIFS
        setIsFailed(false);
        successAddReserv(response, { method: 0 });
        successArticleStay(response, { method: 0 });
        move();
      })
      .catch((error) => {
        console.log("Failed to Create Reservations: ", error);

        failedAddReserv(error, { method: 0 });
        failedArticleStay(error, { method: 3 });
        setIsFailed(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const submitData = e;

    console.log("SUBMIT DATA >>> ", submitData);
    console.log("Room List >>> ", roomList);

    let arr_key = [];
    let foreign_key = {
      payment_type_id: 0,
      payment_card_id: 0,
      room_category_id: 0,
      segment_id: 0,
      rate_code_id: 0,
      room_id: 0,
      sales_id: 0,
      source_id: 0,
      rate_code: "",
    };
    let arr_room_no = [];
    // let allBody = [];

    if (submitData?.rate_code != null || submitData?.rate_code != undefined) {
      arr_key.push(submitData?.rate_code);
      arr_key.push(submitData?.room_price[0]?.main_segment_id);
      arr_key.push(submitData?.room_price[0]?.id);

      foreign_key = {
        segment_id:
          submitData?.room_price[0]?.main_segment_id > 0
            ? submitData.room_price[0].main_segment_id
            : 0,
        payment_card_id: paymentCard.card_id,
        payment_type_id: paymentType.pay_id,
        rate_code: submitData?.rate_code || "",
        rate_code_id: submitData?.room_price[0]?.id || 0,
        room_category_id:
          submitData?.room_modal?.length > 0
            ? submitData.room_modal[0].room_category_id
            : 0,
        room_id:
          submitData?.room_modal[0]?.room_number_id?.length > 0
            ? submitData.room_modal[0].room_number_id[0]
            : 0,
        sales_id: subSegment.sales_id,
        source_id: subSegment.source_id,
      };
    }

    console.log("ID Rate Room Array = ", arr_key);
    console.log("Foreign Array = ", foreign_key);

    // if (submitData?.room_category?.length == 1 && submitData?.room_qty == 1) {
    console.log("INDIVIDUAL GUEST");

    if (
      submitData?.deposit > 0 &&
      (submitData?.is_abf === false ||
        submitData?.is_abf === undefined ||
        submitData?.is_abf === null)
    ) {
      console.log("INDIVIDUAL GUEST WITH DEPOSIT");

      await axios
        .post(
          `${baseurl}/reservation/stay-and-deposit`,
          {
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category:
              submitData?.room_category_id?.length > 0
                ? submitData.room_category_id[0]
                : 0,
            room_number: roomList[0]?.room_no > 0 ? roomList[0].room_no : 0,
            deposit: submitData?.deposit > 0 ? submitData.deposit : 0,
            room_qty: 1,
            adult:
              submitData?.last_adult > 0
                ? submitData.adult + submitData.last_adult
                : parseInt(submitData.adult),
            child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
            infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name ? submitData.guest_name : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? 0
                : 1,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            // deposit: submitData.deposit ? parseInt(submitData.deposit) : 100000,
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price:
              submitData.room_price[0]?.nett > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].nett
                : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: 1,
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? false
                : true,
            guest_id: guestId,

            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : null,

            coa: params_coa?.deposit_coa ? params_coa.deposit_coa : "",
            params_coa: "11",
            deposit_code: "100",

            payment_type_id: paymentType?.pay_id > 0 ? paymentType.pay_id : 0,
            payment_card_id: paymentCard.card_id > 0 ? paymentCard.card_id : 0,

            description: submitData?.description ? submitData.description : "",

            gross_room:
              submitData.room_price[0]?.gross > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].gross
                : 0,
            is_tax_room: true,
            tax_room:
              submitData.room_price[0]?.tax > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].tax
                : 0,
            is_service_room: true,
            service_room:
              submitData.room_price[0]?.service > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].service
                : 0,

            created_by: authorized_by ? authorized_by : user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (response) => {
          console.log("Create >> ", response);

          // IF GROUP RESERVATIONS
          if (
            submitData?.room_category?.length == 1 &&
            submitData?.room_qty == 1
          ) {
            // navigate("/front-office/reservation");
            // NOTIFICATIONS
            successAddReserv(response, { method: 0 });
            successDeposit(response, { method: 0 });

            move();
          } else {
            if (response?.status == 200) {
              await axios
                .get(`${baseurl}/reservation/last-res-id`, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                  console.log("Handle Group --> ", response);

                  handleGroup(response?.data[0]?.reservation_id, submitData, 0);
                })
                .catch((error) => {
                  console.log(error);
                  failedAddReserv(error, { method: 3 });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            } else {
              failedAddReserv(response, { method: 3 });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          failedAddReserv(error, { method: 0 });
          failedDeposit(error, { method: 0 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (
      submitData?.is_abf === true &&
      (submitData?.deposit == 0 ||
        submitData?.deposit == null ||
        submitData?.deposit == undefined)
    ) {
      console.log("INDIVIDUAL GUEST WITH BREAKFAST");

      await axios
        .post(
          `${baseurl}/reservation/stay-and-abf`,
          {
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category:
              submitData?.room_category_id?.length > 0
                ? submitData.room_category_id[0]
                : 0,
            room_number: roomList[0]?.room_no > 0 ? roomList[0]?.room_no : 0,
            deposit: submitData?.deposit > 0 ? submitData.deposit : 0,
            room_qty: 1,
            adult:
              submitData?.last_adult > 0
                ? submitData.adult + submitData.last_adult
                : parseInt(submitData.adult),
            child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
            infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name ? submitData.guest_name : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? 0
                : 1,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price:
              submitData.room_price[0]?.nett > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].nett -
                  breakfast?.unit_price *
                    submitData?.room_price[0].room_breakfast
                : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: 1,
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? false
                : true,
            guest_id: guestId,

            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : null,

            article_sub_id: breakfast?.article_sub_id,
            article_sub_name: breakfast?.article_sub_name,
            article_id: breakfast?.id,
            article_name: breakfast?.article_name,
            unit_price:
              submitData.compliment_type != 2 ? 0 : breakfast?.unit_price, // Nett
            item_quantity: submitData?.room_price
              ? parseInt(submitData?.room_price[0].room_breakfast)
              : 0,
            total_price:
              submitData.compliment_type != 2
                ? 0
                : parseInt(submitData?.room_price[0].room_breakfast) *
                  breakfast?.gross,
            posting_type: "",
            total_posting: "",
            start_date: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            end_date: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            coa_abf: breakfast?.chart_of_account,

            is_tax_abf: breakfast?.is_tax,
            tax_abf: submitData?.compliment_type == 2 ? breakfast?.tax : 0,
            is_service_abf: breakfast?.is_service,
            service_abf:
              submitData?.compliment_type == 2 ? breakfast?.service : 0,
            gross_abf: submitData?.compliment_type == 2 ? breakfast?.gross : 0, // Gross

            gross_room:
              submitData.room_price[0]?.gross > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].gross -
                  breakfast?.gross * submitData?.room_price[0].room_breakfast
                : 0,
            is_tax_room: true,
            tax_room:
              submitData.room_price[0]?.tax > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].tax -
                  breakfast?.tax * submitData?.room_price[0].room_breakfast
                : 0,
            is_service_room: true,
            service_room:
              submitData.room_price[0]?.service > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].service -
                  breakfast?.service * submitData?.room_price[0].room_breakfast
                : 0,

            created_by: authorized_by ? authorized_by : user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (response) => {
          console.log("Create >> ", response);

          // NOTIFS
          // successAddReserv(response, { method: 0 });
          // successArticleStay(response, { method: 0 });

          // IF GROUP RESERVARTIONS
          if (
            submitData?.room_category?.length == 1 &&
            submitData?.room_qty == 1
          ) {
            successAddReserv(response, { method: 0 });
            successArticleStay(response, { method: 0 });
            // navigate("/front-office/reservation");
            move();
          } else {
            if (response?.status == 200) {
              await axios
                .get(`${baseurl}/reservation/last-res-id`, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                  console.log("Handle Group --> ", response);

                  handleGroup(response?.data[0]?.reservation_id, submitData, 1);
                })
                .catch((error) => {
                  console.log(error);
                  failedAddReserv(error, { method: 3 });
                });
            } else {
              failedAddReserv(response, { method: 3 });
            }
          }
          // navigate("/front-office/reservation");
        })
        .catch((error) => {
          console.log(error);
          failedAddReserv(error, { method: 0 });
          failedArticleStay(error, { method: 3 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (submitData?.is_abf === true && submitData?.deposit > 0) {
      console.log("INDIVIDUAL GUEST WITH BREAKFAST & DEPOSIT");

      await axios
        .post(
          `${baseurl}/reservation/stay-deposit-abf`,
          {
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category:
              submitData?.room_category_id?.length > 0
                ? submitData.room_category_id[0]
                : 0,
            room_number: roomList[0]?.room_no > 0 ? roomList[0].room_no : 0,
            deposit: submitData?.deposit > 0 ? submitData.deposit : 0,
            room_qty: 1,
            adult:
              submitData?.last_adult > 0
                ? submitData.adult + submitData.last_adult
                : parseInt(submitData.adult),
            child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
            infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name ? submitData.guest_name : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? 0
                : 1,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price:
              submitData.room_price[0]?.nett > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].nett -
                  breakfast?.unit_price *
                    submitData.room_price[0].room_breakfast
                : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: 1,
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? false
                : true,
            guest_id: guestId,

            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : null,

            coa: params_coa?.deposit_coa ? params_coa.deposit_coa : "",
            params_coa: "11",
            deposit_code: "100",

            payment_type_id: paymentType.pay_id > 0 ? paymentType.pay_id : 0,
            payment_card_id: paymentCard.card_id > 0 ? paymentCard.card_id : 0,

            article_sub_id: breakfast?.article_sub_id,
            article_sub_name: breakfast?.article_sub_name,
            article_id: breakfast?.id,
            article_name: breakfast?.article_name,
            unit_price:
              submitData.compliment_type != 2 ? 0 : breakfast?.unit_price,
            item_quantity: submitData?.room_price
              ? parseInt(submitData?.room_price[0].room_breakfast)
              : 0,
            total_price:
              submitData.compliment_type != 2
                ? 0
                : parseInt(submitData?.room_price[0].room_breakfast) *
                  breakfast?.gross,
            posting_type: "",
            total_posting: "",
            start_date: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            end_date: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            coa_abf: breakfast?.chart_of_account,

            is_tax_abf: breakfast?.is_tax,
            tax_abf: submitData?.compliment_type == 2 ? breakfast?.tax : 0,
            is_service_abf: breakfast?.is_service,
            service_abf:
              submitData?.compliment_type == 2 ? breakfast?.service : 0,
            gross_abf: submitData?.compliment_type == 2 ? breakfast?.gross : 0,

            gross_room:
              submitData.room_price[0]?.gross > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].gross -
                  breakfast?.gross * submitData?.room_price[0].room_breakfast
                : 0,
            is_tax_room: true,
            tax_room:
              submitData.room_price[0]?.tax > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].tax -
                  breakfast?.tax * submitData?.room_price[0].room_breakfast
                : 0,
            is_service_room: true,
            service_room:
              submitData.room_price[0]?.service > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].service -
                  breakfast?.service * submitData?.room_price[0].room_breakfast
                : 0,

            description: submitData?.description ? submitData.description : "",

            created_by: authorized_by ? authorized_by : user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (response) => {
          console.log("Create >> ", response);

          // IF GROUP RESERVARTIONS
          if (
            submitData?.room_category?.length == 1 &&
            submitData?.room_qty == 1
          ) {
            // NOTIFICATIONS
            successAddReserv(response, { method: 0 });
            successDeposit(response, { method: 0 });
            successArticleStay(response, { method: 0 });

            move();
            // navigate("/front-office/reservation");
          } else {
            if (response?.status == 200) {
              await axios
                .get(`${baseurl}/reservation/last-res-id`, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                  console.log("Handle Group --> ", response);

                  handleGroup(response?.data[0]?.reservation_id, submitData, 1);
                })
                .catch((error) => {
                  console.log(error);
                  failedAddReserv(error, { method: 3 });
                });
            } else {
              failedAddReserv(response, { method: 3 });
            }
          }
          // navigate("/front-office/reservation");
        })
        .catch((error) => {
          console.log(error);
          failedAddReserv(error, { method: 0 });
          failedDeposit(error, { method: 0 });
          failedArticleStay(error, { method: 3 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      console.log("INDIVIDUAL GUEST ROOM ONLY");

      await axios
        .post(
          `${baseurl}/reservation/stay`,
          {
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category:
              submitData?.room_category_id?.length > 0
                ? submitData.room_category_id[0]
                : 0,
            room_number: roomList[0]?.room_no > 0 ? roomList[0].room_no : 0,
            deposit: submitData?.deposit ? submitData.deposit : 0,
            room_qty: 1,
            adult:
              submitData?.last_adult > 0
                ? submitData.adult + submitData.last_adult
                : parseInt(submitData.adult),
            child: submitData?.child > 0 ? parseInt(submitData.child) : 0,
            infant: submitData?.infant > 0 ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name ? submitData.guest_name : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? 0
                : 1,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            // deposit: submitData.deposit ? parseInt(submitData.deposit) : 100000,
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price:
              submitData.room_price[0]?.nett > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].nett
                : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: 1,
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group:
              submitData?.room_category?.length == 1 &&
              submitData?.room_qty == 1
                ? false
                : true,
            guest_id: guestId,
            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : null,

            gross_room:
              submitData.room_price[0]?.gross > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].gross
                : 0,
            is_tax_room: true,
            tax_room:
              submitData.room_price[0]?.tax > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].tax
                : 0,
            is_service_room: true,
            service_room:
              submitData.room_price[0]?.service > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].service
                : 0,
            created_by: authorized_by ? authorized_by : user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (response) => {
          console.log("Create >> ", response);

          // NOTIFICATIONS

          // IF GROUP RESERVARTIONS
          if (
            submitData?.room_category?.length == 1 &&
            submitData?.room_qty == 1
          ) {
            successAddReserv(response, { method: 0 });

            move();
            // navigate("/front-office/reservation");
          } else {
            if (response?.status == 200) {
              await axios
                .get(`${baseurl}/reservation/last-res-id`, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                  console.log("Handle Group --> ", response);

                  handleGroup(response?.data[0]?.reservation_id, submitData, 0);
                })
                .catch((error) => {
                  console.log(error);
                  failedAddReserv(error, { method: 3 });
                });
            } else {
              failedAddReserv(response, { method: 3 });
            }
          }
          // navigate("/front-office/reservation");
        })
        .catch((error) => {
          console.log(error);
          failedAddReserv(error, { method: 0 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    setIsLoading(false);
    /*
    } else {
      console.log("GROUP GUEST!!!");

      // let allBody = [];

      await axios
        .post(
          `${baseurl}/reservation/stay`,
          {
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category: submitData?.room_price[0]
              ? submitData.room_price[0]?.room_category_name
              : "",
            room_number:
              submitData?.room_number[0] != undefined
                ? submitData.room_number[0]
                : 0,
            deposit: submitData?.deposit ? submitData.deposit : 0,
            room_qty: 1,
            adult: submitData?.adult ? parseInt(submitData.adult) : 0,
            child: submitData?.child ? parseInt(submitData.child) : 0,
            infant: submitData?.infant ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: "",
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            guest_name: submitData?.guest_name ? submitData.guest_name : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group: 1,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            // deposit: submitData.deposit ? parseInt(submitData.deposit) : 100000,
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price: submitData.room_price[0]?.price
              ? submitData.room_price[0].price
              : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: submitData?.room_price[0]?.id
              ? submitData.room_price[0].id
              : 0,
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group: false,
            guest_id: guestId,
            created_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(async (response) => {
          // console.log(`GROUP RES LOOP = `, response);
          console.log(`GROUP RES LOOP 1 = `, response);
          // console.log(`GROUP RES LOOP ${i + 1} = `, response);
          successAddReserv(response, { method: 0 });

          // let allBody = [];
          // let lastResId = [];

          if (response?.status == 200) {
            await axios
              .get(`${baseurl}/reservation/last-res-id`, {
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((response) => {
                console.log("Handle Group --> ", response);

                handleGroup(response?.data[0]?.reservation_id, submitData);
              })
              .catch((error) => {
                console.log(error);
                failedAddReserv(error, { method: 0 });
              });
          } else {
            failedAddReserv(error, { method: 0 });
          }
        })
        .catch((error) => {
          console.log(`GROUP RES GROUP ERROR = `, error);
          // console.log(`GROUP RES LOOP ERROR ${i + 1} = `, error);
          failedAddReserv(error, { method: 0 });
        });
    }
    */
  };

  // HANDLE DEPOSIT
  /*
  const handleDeposit = async (value) => {
    const contentSubmit = value;

    console.log(contentSubmit);

    await axios
      .get(`${baseurl}/reservation/last-res-id`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("RES ID >>> ", response);

        axios
          .post(
            `${baseurl}/reservation/deposit-stay/`,
            {
              reservation_id:
                response.data.length > 0 ? response.data[0].reservation_id : "",
              deposit_amount:
                contentSubmit?.deposit != null ? contentSubmit.deposit : 0,
              payment_type: contentSubmit?.payment_type
                ? contentSubmit.payment_type
                : "",
              payment_type_id: paymentType ? paymentType : 0,
              payment_details: contentSubmit?.payment_details
                ? contentSubmit.payment_details
                : "",
              payment_card_id: paymentCard ? paymentCard : 0,
              description: "Deposit at Reservation",
              coa: params_coa?.deposit_coa ? params_coa.deposit_coa : "",
              deposit_code: "100",
              params_coa: "11",
              price_id: contentSubmit?.room_price[0].id
                ? contentSubmit.room_price[0].id
                : 0,
              created_by: user_name,
            },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            console.log("SUCCESS DEPO >>> ", response);
            successDeposit(response, { method: 0 });
          })
          .catch((error) => {
            console.log(error);
            failedDeposit(error, { method: 0 });
          });
      })
      .catch((error) => {
        console.log("Error Deposit >>> ", error);
      });
  }; */

  // HANDLE GROUP RESERVATION
  const handleGroup = async (value, data, type) => {
    const res_id = value;
    const submitData = data;
    const typeGroup = type;

    const allBody = [];

    console.log("RES ID => ", res_id);
    console.log("SUBMIT DATA => ", submitData);
    console.log("TYPE GROUP => ", typeGroup);

    let foreign_key = {
      payment_type_id: 0,
      payment_card_id: 0,
      room_category_id: 0,
      segment_id: 0,
      rate_code_id: 0,
      room_id: 0,
      sales_id: 0,
      source_id: 0,
      rate_code: "",
    };
    let arr_key = [];
    let arr_room_no = [];
    // let allBody = [];

    if (submitData?.rate_code != undefined) {
      arr_key.push(submitData?.rate_code);
      arr_key.push(submitData?.room_price[0]?.main_segment_id);
      arr_key.push(submitData?.room_price[0]?.id);
    }

    switch (typeGroup) {
      case 1:
        for (let i = 1; i < submitData?.room_price?.length; i++) {
          foreign_key = {
            segment_id:
              roomList[i]?.main_segment_id > 0
                ? roomList[i].main_segment_id
                : 0,
            payment_card_id: paymentCard.card_id,
            payment_type_id: paymentType.pay_id,
            rate_code: submitData?.rate_code || "",
            rate_code_id:
              roomList[i]?.rate_code_id > 0 ? roomList[i]?.rate_code_id : 0,
            room_category_id:
              roomList?.length > 0 ? roomList[i].room_category_id : 0,
            room_id:
              roomList[i]?.room_number_id > 0 ? roomList[i].room_number_id : 0,
            sales_id: subSegment.sales_id,
            source_id: subSegment.source_id,
          };

          allBody.push({
            reservation_id: res_id,
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category:
              roomList?.length > 0
                ? roomList[i]?.room_category_id
                : submitData?.room_price[i]?.room_category_id,
            room_number: roomList[i]?.room_no > 0 ? roomList[i].room_no : 0,
            deposit: 0,
            room_qty: 1,
            adult: 1,
            child: submitData?.child ? parseInt(submitData.child) : 0,
            infant: submitData?.infant ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name
              ? `${submitData.guest_name + " " + (i + 1)}`
              : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group: 2,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price:
              submitData.room_price[i]?.nett > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[i].nett -
                  breakfast?.unit_price *
                    submitData.room_price[i].room_breakfast
                : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: 1 + i,
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group: true,
            guest_id: guestId,
            created_by: authorized_by ? authorized_by : user_name,

            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : null,

            article_sub_id: breakfast?.article_sub_id,
            article_sub_name: breakfast?.article_sub_name,
            article_id: breakfast?.id,
            article_name: breakfast?.article_name,
            unit_price:
              submitData.compliment_type != 2 ? 0 : breakfast?.unit_price,
            item_quantity: submitData?.room_price
              ? parseInt(submitData?.room_price[0].room_breakfast)
              : 0,
            total_price:
              submitData.compliment_type != 2
                ? 0
                : parseInt(submitData?.room_price[0].room_breakfast) *
                  breakfast?.gross,
            posting_type: "",
            total_posting: "",
            start_date: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            end_date: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            coa_abf: breakfast?.chart_of_account,

            is_tax_abf: breakfast?.is_tax,
            tax_abf: submitData?.compliment_type == 2 ? breakfast?.tax : 0,
            is_service_abf: breakfast?.is_service,
            service_abf:
              submitData?.compliment_type == 2 ? breakfast?.service : 0,
            gross_abf: submitData?.compliment_type == 2 ? breakfast?.gross : 0,

            gross_room:
              submitData.room_price[0]?.gross > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].gross -
                  breakfast?.gross * submitData?.room_price[0].room_breakfast
                : 0,
            is_tax_room: true,
            tax_room:
              submitData.room_price[0]?.tax > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].tax -
                  breakfast?.tax * submitData?.room_price[0].room_breakfast
                : 0,
            is_service_room: true,
            service_room:
              submitData.room_price[0]?.service > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].service -
                  breakfast?.service * submitData?.room_price[0].room_breakfast
                : 0,
          });
        }

        console.log("DATA ARRAY GROUP WITH ABF ==== ", allBody);

        await axios
          .post(
            `${baseurl}/reservation/stay-and-abf`,
            { data: allBody },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            console.log("ALL BODY RES CASE 1 => ", response);

            successAddReserv(response, { method: 0 });
            successAddReserv(response, { method: 3 });
            successArticleStay(response, { method: 4 });

            // navigate("/front-office/reservation");
            move();
          })
          .catch((error) => {
            console.log("ALL BODY ERR => ", error);

            failedAddReserv(error, { method: 3 });
            failedArticleStay(error, { method: 4 });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;

      default:
        for (let i = 1; i < submitData?.room_price?.length; i++) {
          foreign_key = {
            segment_id:
              roomList[i]?.main_segment_id > 0
                ? roomList[i].main_segment_id
                : 0,
            payment_card_id: paymentCard.card_id,
            payment_type_id: paymentType.pay_id,
            rate_code: submitData?.rate_code || "",
            rate_code_id:
              roomList[i]?.rate_code_id > 0 ? roomList[i]?.rate_code_id : 0,
            room_category_id:
              roomList?.length > 0 ? roomList[i].room_category_id : 0,
            room_id:
              roomList[i]?.room_number_id > 0 ? roomList[i].room_number_id : 0,
            sales_id: subSegment.sales_id,
            source_id: subSegment.source_id,
          };

          allBody.push({
            reservation_id: res_id,
            arrival: submitData?.arrival
              ? moment(submitData.arrival).format("YYYY-MM-DD")
              : "",
            departure: submitData?.departure
              ? moment(submitData.departure).format("YYYY-MM-DD")
              : "",
            night: submitData?.night ? parseInt(submitData.night) : 0,
            room_category:
              roomList?.length > 0
                ? roomList[i]?.room_category_id
                : submitData?.room_price[i]?.room_category_id,
            room_number: roomList[i]?.room_no > 0 ? roomList[i].room_no : 0,
            deposit: 0,
            room_qty: 1,
            adult: 1,
            child: submitData?.child ? parseInt(submitData.child) : 0,
            infant: submitData?.infant ? parseInt(submitData.infant) : 0,
            reservation_status: submitData?.reservation_status
              ? submitData.reservation_status
              : "",
            arrangement: foreign_key,
            guest_category: submitData?.guest_category
              ? submitData.guest_category
              : "REGULAR",
            rate_code: submitData?.rate_code ? submitData.rate_code : "",
            compliment_type: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            currency: submitData?.currency ? submitData.currency : "IDR",
            // room_type: submitData?.room_type ? submitData.room_type : "",
            guest_name: submitData?.guest_name
              ? `${submitData.guest_name + " " + (i + 1)}`
              : "",
            bill_receiver: submitData?.bill_receiver
              ? submitData.bill_receiver
              : "",
            bill_instruction: submitData?.bill_instruction
              ? submitData.bill_instruction
              : "",
            arrival_flight: submitData?.arrival_flight
              ? submitData.arrival_flight
              : "",
            eta: submitData?.eta ? submitData.eta : null,
            departure_flight: submitData?.departure_flight
              ? submitData.departure_flight
              : "",
            etd: submitData?.etd ? submitData.etd : null,
            group: 2,
            segment: submitData?.segment ? submitData.segment : "",
            source: submitData?.source ? submitData.source : "",
            letter: submitData?.letter ? submitData.letter : "",
            // deposit: submitData.deposit ? parseInt(submitData.deposit) : 100000,
            payment_type: submitData?.payment_type
              ? submitData.payment_type
              : "",
            payment_details: submitData?.payment_details
              ? submitData.payment_details
              : "",
            memo_room: submitData?.memo_room ? submitData.memo_room : "",
            group_name: submitData?.group_name ? submitData.group_name : "",
            room_price:
              submitData.room_price[i]?.nett > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[i].nett
                : 0,
            room_rate_id: arr_key ? arr_key : [0],
            price_id: 1 + i,
            payment_status: submitData?.payment_status || null,
            sales: submitData?.sales ? submitData.sales : "",
            is_dummy: submitData?.is_dummy ? submitData.is_dummy : true,
            is_group: true,
            guest_id: guestId,
            created_by: authorized_by ? authorized_by : user_name,

            main_compliment_id: submitData?.compliment_type
              ? submitData.compliment_type
              : 2,
            main_segment_id: segmentID > 0 ? segmentID : null,
            // subsegment_id: subSegment > 0 ? subSegment : null,

            gross_room:
              submitData.room_price[0]?.gross > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].gross
                : 0,
            is_tax_room: true,
            tax_room:
              submitData.room_price[0]?.tax > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].tax
                : 0,
            is_service_room: true,
            service_room:
              submitData.room_price[0]?.service > 0 &&
              submitData?.compliment_type == 2
                ? submitData.room_price[0].service
                : 0,
          });
        }

        console.log("DATA ARRAY GROUP ROOM ONLY ==== ", allBody);

        await axios
          .post(
            `${baseurl}/reservation/stay`,
            { data: allBody },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            console.log("ALL BODY RES CASE 0 (DEFAULTS) => ", response);

            successAddReserv(response, { method: 0 });
            successAddReserv(response, { method: 3 });

            // navigate("/front-office/reservation");

            move();
          })
          .catch((error) => {
            console.log("ALL BODY ERR => ", error);
            failedAddReserv(error, { method: 3 });
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
    }

    setIsLoading(false);
  };

  // CHECK ON TAB CLICK
  const disabled = (e) => {
    console.log(e);
  };

  // GET REFRESH RATE CODE
  const getRefreshRate = async (e) => {
    console.log(`getRefreshRate ${e}`);
    setSegmentID(e);
  };

  // HANDLE FINAL ROOM LIST
  const handleGetFinalRoomList = (data) => {
    setFinalRoomList(data);
  };

  // HANDLE FINAL DETAIL ROOM LIST
  const handleGetFinalDetailRoomList = (data) => {
    setFinalDetailRoomList(data);
  };

  /*
  // CONSOLE DATA
  // console.log("DATA ADD RESERV ==>>> ", reservationData);
  // console.log("Room Modal => ", roomCode);
  // console.log("STATE => ", state); */

  // TAB CONTENTS
  const items = [
    {
      label: (
        <>
          <Row
            className="row-first-tab"
            justify="start"
            align="middle"
            style={
              // screenSize.lg == false
              { width: 297, height: 80 }
              // : { minWidth: 150, minHeight: 80 }
            }
          >
            <Col
              className="col-first-tab"
              style={{ margin: "10px 20px 10px 10px" }}
            >
              <Button
                className="1st-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={
                  activeKey == 1
                    ? styleActive
                    : error == true
                    ? styleError
                    : styleInactive
                }
              >
                1
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p style={error == true ? { color: "#f64e60" } : {}}>
                  Add Reservation
                </p>
              </Row>

              <Row style={{ height: 20 }}>
                <h4>Date and Guest Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "1",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              // padding: screen.lg == false ? "60px 249px" : 30,
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="row-reserve-date">
              <Col span={8} className="col-arrival">
                <Form.Item
                  label="Arrival"
                  name="arrival"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select Arrival Date!",
                    },
                  ]}
                  style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    onChange={handleFirstDate}
                    disabledDate={disabledDate}
                    placeholder={"Start Date"}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Departure"
                  name="departure"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select Departure Date!",
                    },
                    {
                      validator: async (_, departure) => {
                        let arrival = form.getFieldValue("arrival");
                        // console.log("ARRIVAL => ", arrival);
                        // console.log("DEPART => ", departure);
                        // console.log("DIFF", (departure = arrival));

                        if (arrival != null)
                          if (departure <= moment(arrival)) {
                            return Promise.reject(
                              "Departure Date Must be Greater than Arrival Date!"
                            );
                          }
                      },
                    },
                  ]}
                  style={{ margin: "0px 30px 0px 0px", minWidth: "100%" }}
                >
                  <DatePicker
                    format={"YYYY-MM-DD"}
                    placeholder={"End Date"}
                    disabledDate={disabledEndDate}
                    onChange={handleLastDate}
                    style={{
                      minWidth: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Night"
                  name="night"
                  rules={[
                    {
                      required: true,
                      message: "Please, Pick the Date!",
                      //   validator: (_, night) => {
                      //     console.log(night);

                      //     if (night != null)
                      //       if (night <= 0) {
                      //         return Promise.reject(
                      //           "Night cannot be Negative value!"
                      //         );
                      //       }
                      //   },
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    placeholder="Qty"
                    onChange={onChangeNight}
                    style={{ width: 100 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserv-guest">
              <Col xxl={8} xl={8} lg={16} md={16}>
                <Form.Item
                  label="Guest Name"
                  name="guest_name"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Guest Name!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterGuest
                    getGuestName={getGuestName}
                    name={state?.id > 0 ? state.id : null}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="Arrangement"
                  name="arrangement"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select Room Arrangement!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: '100%' }}
                >
                  <MasterArrange getArrange={getArrange} />
                </Form.Item>
              </Col> */}

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item
                  label="Payment Type"
                  name="payment_type"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select the Payment Type!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterPaymentType
                    getPaymentType={getPaymentType}
                    // getPayTypeId={setPaymentType}
                  />
                </Form.Item>
              </Col>

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item
                  label="Payment Details"
                  name="payment_details"
                  style={{ minWidth: "100%" }}
                >
                  <MasterPaymentCard
                    getPayCard={getPayCard}
                    getPayTypeId={paymentType.pay_id}
                    // getCode={}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserv-room-info">
              <Col xxl={8} xl={8} lg={16} md={16}>
                <Form.Item
                  label="Room Category"
                  name="room_category"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Room!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Room Category"
                    disabled
                    addonAfter={
                      <>
                        <Tooltip
                          className="modal-tooltip"
                          title="Click to Open Modals"
                        >
                          {/* <Button
                            className="suffix-btn"
                            icon={ */}
                          <MdOutlineDashboardCustomize
                            className="suffix-icons"
                            onClick={() => {
                              // Data Fields
                              const fieldValue = form.getFieldsValue();
                              setFieldValue(fieldValue);
                              // console.log("Field Values >>> ", fieldValue);

                              setOpen(!open);
                            }}
                            style={{
                              fontSize: 20,
                              color: "#3699FF",
                              marginTop: 3,
                            }}
                          />
                          {/*
                            }
                           /> */}
                        </Tooltip>
                      </>
                    }
                    onClick={() => {
                      setOpen(!open);
                    }}
                    style={{
                      minWidth: "100%",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Room Category ID"
                  name="room_category_id"
                  hidden
                >
                  <Input />
                </Form.Item>
              </Col>

              <Form.Item name="room_modal">
                <ModalRoomPick
                  countNight={totalNight}
                  modalOpen={open}
                  setRoomCode={setRoomCode}
                  modalClose={setOpen}
                  setRoom={setRoom}
                  field={fieldValue}
                  is_reset={resetModal}
                  is_rereset={setResetModal}
                />
              </Form.Item>

              <Col xxl={8} xl={8} lg={12} md={12}>
                <Form.Item label="Room Number" name="room_number">
                  <Input
                    placeholder="Choose an option"
                    disabled
                    style={{
                      minWidth: "100%",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Room Quantity" name="room_qty">
                  <InputNumber
                    disabled
                    size="large"
                    placeholder="Qty"
                    style={{ width: 100 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="row-reserv-guest">
              <Col span={4}>
                <Form.Item
                  label="Adult"
                  name="adult"
                  rules={[
                    {
                      required: true,
                      message: "Please, Input Total Guest!",
                    },
                    {
                      validator: async (_, adult) => {
                        let qty = form.getFieldValue("room_qty");
                        // console.log("ARRIVAL => ", arrival);
                        // console.log("DEPART => ", departure);
                        // console.log("DIFF", (departure = arrival));

                        if (qty != null)
                          if (adult <= qty - 1) {
                            return Promise.reject(
                              "Adult guest must be greater or equals to Room Quantity."
                            );
                          }
                      },
                    },
                  ]}
                  style={{ margin: "0px 30px 0px 0px" }}
                >
                  <InputNumber
                    size="large"
                    min={0}
                    controls={<PlusOutlined />}
                    onChange={getAdult}
                    placeholder="Total Adult"
                    style={{ width: 90 }}
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Form.Item
                  label="Child"
                  name="child"
                  style={{ margin: "0px 30px 0px 0px" }}
                >
                  <InputNumber
                    size="large"
                    min={0}
                    max={10}
                    placeholder="Total Child"
                    style={{ width: 90 }}
                  />
                  {/* <MasterInputChild getChild={getChild} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Infant" name="infant">
                  <InputNumber
                    size="large"
                    min={0}
                    max={10}
                    placeholder="Total Infant"
                    style={{ width: 90 }}
                  />
                  {/* <MasterInputInfant getInfant={getInfant} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Guest Status"
                  name="guest_category"
                  style={{ minWidth: "100%" }}
                >
                  <MasterGuestCat getCategory={getCategory} />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 30px", backgroundColor: "#EBEDF3" }}
            />

            <Row justify="end" className="row-button-next-tab-pane">
              <Button
                className="cancel-btn"
                type="ghost"
                onClick={move}
                style={{ marginRight: 30 }}
              >
                {`Cancel`}
              </Button>

              <Button
                className="next-btn"
                type="primary"
                onClick={() => onKeyChange("2")}
              >
                {`Next`}
              </Button>
            </Row>
          </Content>
        </>
        // </TabPane>
      ),
    },
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="2nd-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={
                  activeKey == 2
                    ? styleActive
                    : error == true
                    ? styleError
                    : styleInactive
                }
              >
                2
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p style={error == true ? { color: "#f64e60" } : {}}>
                  Add Reservation
                </p>
              </Row>
              <Row style={{ height: 20 }}>
                <h4>Room Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "2",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              // padding: screen.lg == false ? "60px 249px" : 30,
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="room-segmentations">
              <Col span={6}>
                <Form.Item
                  label="Compliment Type"
                  name="compliment_type"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Compliment Type!",
                    },
                  ]}
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <MasterComplimentType
                    getCompliment={getCompliment}
                    compl={"No Compliment"}
                  />
                </Form.Item>
              </Col>
              {/* </Row>

            <Row gutter={30} className="room-segmentations"> */}
              <Col span={6}>
                <Form.Item
                  label="Segment"
                  name="segment"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Segment!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterSegment
                    getSegment={getSegment}
                    sgmnt={segmentation}
                    is_render={isRender}
                    is_rerender={setIsRender}
                    is_company={companyInfo?.segment_id || []}
                  />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="Sub Segment"
                  name="sub_segment"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Sub Segment!",
                    },
                  ]}
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <MasterSubSegment
                    getSubSegment={getSubSegment}
                    is_render={isRender}
                    is_rerender={setIsRender}
                  />
                </Form.Item>
              </Col> */}

              <Col span={12} className="abf-room-row">
                <Row
                  className="rate-code-row"
                  justify="space-between"
                  // gutter={30}
                >
                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      label="Rate Code"
                      name="rate_code"
                      rules={[
                        {
                          required: true,
                          message: "Please, Select a Rate Code!",
                        },
                      ]}
                      style={{
                        marginRight: 15,
                        minWidth: "100%",
                      }}
                    >
                      <MasterRateCode
                        getRateCode={getRateCode}
                        segment_id={segmentID}
                        segment_name={segmentation}
                        roomCat={roomCode}
                        qty={roomQty}
                        allSegment={
                          companyInfo?.segment_id?.length > 0
                            ? companyInfo.segment_id
                            : []
                        }
                        // sub_segment_id={subSegment}
                        is_render={isRender}
                        is_rerender={setIsRender}
                        is_company={companyInfo?.rate_id || []}
                        is_permitted={permitted}
                        getOptions={getOptions}
                      />
                    </Form.Item>
                  </Col>

                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item label="Total Breakfast" name="abf_total">
                      <InputNumber
                        placeholder="Total Breakfast"
                        min={0}
                        style={{
                          width: "75%",
                        }}
                      />
                      {/* <Checkbox
                        key={"check-breakfast"}
                        className="ro-rbf-select"
                        onChange={(e) => {
                          console.log(e.target.checked);

                          setIsBreakfast(e.target.checked);
                        }}
                      >
                        Includes Breakfast
                      </Checkbox> */}
                    </Form.Item>

                    <Form.Item
                      label="IS ABF?"
                      // valuePropName="checked"
                      hidden
                      name="is_abf"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="rate-desc" style={{ width: "100%" }}>
              <Alert
                className="desc-alert"
                type="info"
                closable={false}
                showIcon
                message={"Segments & Rate Code Descriptions:"}
                description={
                  <Typography className="desc-text" style={{ fontWeight: 500 }}>
                    {`SEGMENTS: ${
                      segmentation ? segmentation.toUpperCase() : ""
                    } --- ${informations || ""}`}
                  </Typography>
                }
                style={{ width: "100%" }}
              />
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row className="row-room-price">
              <Col span={24}>
                <Form.Item
                  name="room_price"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please, make sure Room Category, Segment, Sub-Segment and Rate Code to Get Rate!",
                    },
                  ]}
                >
                  <ReservationRoomRateTable
                    roomList={roomList}
                    setRoomPrice={setRoomPrice}
                    getPrice={getPrice}
                    night={totalNight > 0 ? totalNight : 1}
                    qty={roomQty}
                    is_breakfast={isBreakfast}
                    is_compliment={isComply}
                    is_permit={setPermitted}
                    is_dynamic={dynamic ? true : false}
                    is_authorized={authorized ? true : false}
                    compliment={compliment}
                    optionValue={companyInfo}
                    getRefreshRate={getRefreshRate}
                    getFinalRoomList={handleGetFinalRoomList}
                    getFinalDetailRoomList={handleGetFinalDetailRoomList}
                    defaultRate={defaultRate}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="guest-name-reservation">
              <Col span={8}>
                <Form.Item
                  label="Reservation Status"
                  name="reservation_status"
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <MasterReservationStatus getResStatus={getResStatus} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Bill Receiver" name="bill_receiver">
                  <MasterGuest
                    getGuestName={getBillReceiver}
                    name={companyInfo.value ? companyInfo.value : null}
                    bill_id={
                      companyInfo.guest_id > 0 ? companyInfo.guest_id : 0
                    }
                    is_bill_receiver={true}
                  />
                </Form.Item>

                <Form.Item
                  label="Bill Receiver"
                  name="bill_receiver_id"
                  hidden={true}
                >
                  <InputNumber
                    placeholder="Bill Receiver ID"
                    style={{ minWidth: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Bill Instruction" name="bill_instruction">
                  <Input
                    placeholder="Insert Bill Instruction"
                    style={{ minWidth: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={30} className="flight-arrival-reservation">
              <Col span={8}>
                <Form.Item
                  label="Currency"
                  name="currency"
                  style={{
                    minWidth: "100%",
                  }}
                >
                  <MasterCurrency getCurrency={getCurrency} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Payment Status"
                  name="payment_status"
                  rules={[
                    {
                      required: true,
                      message: "Please, Select a Payment Status!",
                    },
                  ]}
                  style={{
                    marginRight: 30,
                    minWidth: "100%",
                  }}
                >
                  <Radio.Group onChange={onRadioChange} value={payment_status}>
                    <Space direction="vertical">
                      <Radio value={3}>{`Cash Basis`}</Radio>
                      <Radio value={2}>{`Charge To Room`}</Radio>
                      {/* <Radio value={3}>{`Deposit`}</Radio> */}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row justify="end" className="row-button-next-tab-pane">
              <Button
                type="ghost"
                className="cancel-btn"
                onClick={move}
                style={{ marginRight: 30 }}
              >
                {`Cancel`}
              </Button>

              <Button
                type="primary"
                className="next-btn"
                onClick={() => onKeyChange("1")}
                style={{ marginRight: 30 }}
              >
                {`Back`}
              </Button>

              <Button
                className="next-btn"
                type="primary"
                onClick={() => onKeyChange("3")}
              >
                {`Next`}
              </Button>
            </Row>
          </Content>
        </>
      ),
    },
    {
      label: (
        <>
          <Row
            justify="start"
            align="middle"
            style={{ width: 297, height: 80 }}
          >
            <Col style={{ margin: "10px 20px 10px 10px" }}>
              <Button
                className="3rd-icon-tab-reservation-btn"
                disabled
                // icon={<RiNumber1 />}
                type="primary"
                style={activeKey == 3 ? styleActive : styleInactive}
              >
                3
              </Button>
            </Col>

            <Col>
              <Row style={{ height: 20 }}>
                <p>Add Reservation</p>
              </Row>

              <Row style={{ height: 20 }}>
                <h4>Room Memo Details</h4>
              </Row>
            </Col>
          </Row>
        </>
      ),
      key: "3",
      disabled: true,
      forceRender: true,
      children: (
        <>
          <Content
            className="tab-pane-layout"
            key={"tab-pane-layout"}
            style={{
              // padding: "60px 249px",
              // padding: screen.lg == false ? "60px 249px" : 30,
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 5px 5px",
            }}
          >
            <Row gutter={30} className="group-segment-reservation">
              <Col span={8}>
                <Form.Item
                  label="Group Name"
                  name="group_name"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <Input placeholder="Group Name" />
                  {/* <MasterGroup getGroup={getGroup} /> */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Deposit"
                  name="deposit"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <InputNumber
                    placeholder="Input Deposit"
                    addonBefore={currency}
                    onChange={handleDisable}
                    formatter={(deposit) => {
                      return `${deposit > 0 ? deposit : 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Source"
                  name="source"
                  style={{ minWidth: "100%" }}
                >
                  <MasterSource getSource={getSource} />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="sales-info-reservation">
              <Col xxl={16} xl={16} lg={16} md={24} sm={24}>
                <Row className="flight-row" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Arrival Flight"
                      name="arrival_flight"
                      style={{
                        marginRight: 30,
                        minWidth: "100%",
                      }}
                    >
                      <Input placeholder="Arrival Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETA" name="eta">
                      <TimePicker
                        onChange={onChangeTime}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="flight-row" gutter={30}>
                  <Col span={12}>
                    <Form.Item
                      label="Departure Flight"
                      name="departure_flight"
                      style={{
                        marginRight: 30,
                        minWidth: "100%",
                      }}
                    >
                      <Input placeholder="Departure Flight" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="ETD" name="etd">
                      <TimePicker
                        onChange={onChangeTime}
                        // defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Letter"
                  name="letter"
                  style={{ marginRight: 30, minWidth: "100%" }}
                >
                  <Select
                    showSearch
                    placeholder="Select an Option"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={
                      [
                        // {
                        //   value: "1",
                        //   label: "1",
                        // },
                        // {
                        //   value: "2",
                        //   label: "2",
                        // },
                      ]
                    }
                  />
                </Form.Item>

                <Form.Item
                  label="Sales"
                  name="sales"
                  style={{ minWidth: "100%" }}
                >
                  {/* <Input placeholder="Sales Name" /> */}
                  <MasterSalesman
                    getSalesman={getSalesman}
                    segmentId={segmentID}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 30px", backgroundColor: "#EBEDF3" }}
            />

            <Row gutter={30} className="row-reserve-guest-memo">
              {/* <Col span={8}>
                <Form.Item
                  // label="Master Bill Active"
                  name="master_bill_active"
                  valuePropName="checked"
                >
                  <Checkbox>Master Bill Active</Checkbox>
                </Form.Item>

                <Form.Item
                  // label="Cash Basis"
                  name="cash_basis"
                  valuePropName="checked"
                >
                  <Checkbox>Cash Basis</Checkbox>
                </Form.Item>
              </Col> */}

              <Col span={16}>
                <Form.Item label="Memo" name="memo_room">
                  <Input.TextArea
                    showCount
                    maxLength={100}
                    style={{
                      height: 100,
                      width: "100%",
                    }}
                    // onChange={onChangeTextArea}
                    placeholder="Descriptions"
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Deposit Payment" name="deposit_status_id">
                  <Select
                    placeholder="Payment"
                    className="deposit_status_id"
                    allowClear
                    showSearch
                    disabled={disableDeposit}
                    // onClear={}
                    // value={}
                    onChange={handleDeposit}
                    options={[
                      {
                        label: "EARLY PAYMENT",
                        value: 1,
                      },
                      {
                        label: "PAYMENT AT CHECK-OUT",
                        value: 2,
                      },
                    ]}
                    style={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 30px", backgroundColor: "#EBEDF3" }}
            />

            <Row className="submit-reservation" justify="end">
              <Button
                className="cancel-btn"
                type="ghost"
                onClick={move}
                style={{ marginRight: 30 }}
              >
                {`Cancel`}
              </Button>

              <Button
                type="primary"
                className="next-btn"
                onClick={() => onKeyChange("2")}
                style={{ marginRight: 30 }}
              >
                {`Back`}
              </Button>

              <Button
                className="submit-btn"
                type="primary"
                key={"submit"}
                htmlType="submit"
                // style={{
                //   backgroundColor: "#1BC5BD",
                //   borderColor: "#1BC5BD",
                // }}
              >
                {`Submit`}
              </Button>
            </Row>
          </Content>
        </>
      ),
    },
  ];

  // RETURN
  return (
    <>
      {/* FORM */}
      <Form
        form={form}
        name="guest-modal-form"
        className="guest-modal-form"
        key="guest-modal-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        autoComplete="off"
        labelWrap={true}
        // forceRender={true}
        initialValues={{
          compliment_type: 2,
          adult: 1,
          infant: 0,
          child: 0,
          deposit_status_id: 2,
        }}
        style={{ padding: 0, fontSize: 8 }}
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          activeKey={activeKey}
          onChange={onKeyChange}
          // onTabClick={disabled}
          // forceRender={true}
          key="add-reserv-tabs"
          name="add-reserv-tabs"
          className="add-reserv-tabs"
          items={items}
        />
      </Form>

      <ReservationForm
        openModal={openResv}
        closeModal={setOpenResv}
        dataGuest={openResv == true ? reservationData : null}
        is_saved={openResv == true ? false : true}
        is_created={setCreated}
        is_member={false}
        is_loading={isLoading}
        detailResv={finalDetailRoomList}
        is_failed={isFailed}
        reset_failed={setIsFailed}
      />
    </>
  );
}
