// Import React's Component
import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Table,
  Space,
  Button,
  Col,
  Row,
} from "antd";

// Import React Icons
import {
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Notification
import { failedFetch } from "../../../../Components/Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { LocalizationDate } from "../../../../Components/Reusable/Functions/Localizations/Localization";

// CODE
export default function POSHeaderTable({
  selectedData,
  is_refresh,
}) {

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState(5);

  // Table Columns
  const columnsArticle = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (value, record, index) => (page - 1) * paginationSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (amount) => amount,
    },
    {
      title: "Table",
      dataIndex: "table_id",
      key: "table_id",
      render: (text, record) => {
        if(text){
          return text
        } else {
          return `(${record.sales_type == 2 ? "Take Away" : record.sales_type == 3 ? "Online Order" : "-"})`
        }
      },
    },
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
      render: (amount) => amount,
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (amount) => {
        return moment(LocalizationDate(amount)).format("YYYY-MM-DD, HH:mm");
      },
    },
    {
      title: "Article ID",
      dataIndex: "id_article",
      key: "id_article",
      hidden: true,
      render: (amount) => amount,
    },
  ].filter((item) => !item.hidden);

  // FETCH Data Header
  const fetchDataHeader = async () => {
    await axios
      .get(`${baseurl}/pos/order-header`, {})
      .then((response) => {
        console.log("HEADER FOR SAVED BILL: ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (
              e.is_deleted == false &&
              !e.is_paid &&
              e.bill_status != 2 &&
              e.bill_status != 3
            ) {
              return e;
            }
          });

          console.log("_res", _res);

          let filter = _res.filter((x) => {
            if(x.order_status==true){
              return true
            } else {
              return false
            }
          })

          setData(filter);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchDataHeader();
  }, []);

  useEffect(() => {
    fetchDataHeader();
  }, [is_refresh]);

  // Handle Row Selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      // setRateCode(selectedRows);
      // getRateCode(selectedRows);
      selectedData(
        // selectedRows?.length > 0 ? selectedRows[0].bill_alias : []
        selectedRows?.length > 0 ? selectedRows[0] : []
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={12}>
              <Space
                className="title-spacetxt"
                align="center"
                size={"large"}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {`Order List`}
              </Space>
            </Col>

            <Col className="col-btn" span={12}>
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchDataHeader}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="order-list-table"
              name="order-list-table"
              key="order-list-table"
              columns={columnsArticle}
              dataSource={data}
              bordered
              loading={loading}
              size="medium"
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
