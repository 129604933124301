// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import axios from "axios";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React's Icons
import { BiSolidFilePdf } from "react-icons/bi";
import { PrinterOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";

// Import Functions
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import ParametersBreakfast from "../../Reusable/Parameters/ParamsBF";
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";

// Import Notification Components
import {
  masterFailedNotification,
  masterIncomplete,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";
import {
  failedChangeRoom,
  successChangeRoom,
} from "../../Reusable/Notification/Notification";

// Modals
const { confirm } = Modal;

// CODE
export default function ChangeRoomForm(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    is_close,
    // Data
    dataGuest,
    is_new_category,
    // Refresh State
    is_refresh,
  } = props;

  // DATE
  const today = moment(Date.now());

  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // Params
  const currency = CurrencySymbols().code;
  const param_fo = FrontOfficeParams();
  const param_breakfast = ParametersBreakfast().breakfast;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [guest, setGuest] = useState(null);
  const [company, setCompany] = useState(null);
  // CLICK
  const [click, setClick] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  // USEFORM
  const [form] = Form.useForm();

  // FETCH GUEST
  const fetchGuest = async () => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          let filtered = _response.filter((val) => {
            if (val?.id === dataGuest?.data_guest?.guest_id) {
              return val;
            }
          });

          console.log("Guest: ", filtered);

          setGuest(filtered?.length > 0 ? filtered[0] : null);
        } else {
          setGuest(null);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
      },
    });

    setTimeout(() => {
      setLoading(false);
    }, 1250);
  };

  // FETCH COMPANY
  const fetchCompany = async () => {
    await getGuest({
      guest: "guest",
      type: "company-and-ota",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Company => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          let filtered = _response.filter((val) => {
            if (val?.id === guest?.master_comp_id) {
              return val;
            }
          });

          console.log("Companies: ", filtered);

          setCompany(filtered?.length > 0 ? filtered[0] : null);
        } else {
          setCompany(null);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
      },
    });

    setTimeout(() => {
      setLoading(false);
    }, 1250);
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (dataGuest && !cleanUp) {
      console.log("DATA GUEST = ", dataGuest);
      setData(dataGuest);

      fetchGuest();
    }

    if (openModal && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest]);

  useEffect(() => {
    let cleanUp = false;

    if (guest && !cleanUp) {
      console.log("DATA GUEST = ", guest);

      fetchCompany();
    }

    return () => {
      cleanUp = true;
    };
  }, [guest]);

  // ON FINISH
  const onFinish = (value) => {
    setIsLoading(true);
    const content = value;

    console.log("ON FINISH by BTN >>> ", content);
    console.log("ON FINISH REAL DATA ==> ", data);

    // handleSubmit(data);
    showModalConfirm(data);
  };

  // Show Modal Confirmations
  const showModalConfirm = (val) => {
    const _data = val;

    confirm({
      className: "modal-confirm",
      title: `Are you sure want to Change Room of the Guest Named ${
        _data?.data_submit?.guest_name || "----"
      }
       from ${_data?.data_submit.room_category} Room ${
        _data?.data_submit?.room_number || 0
      } to ${_data?.data_submit?.new_room_number || 0}?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 30,
        zIndex: 10,
      },
    });
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;

    masterIncomplete(error);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("About to Change Room: ", contentSubmit);

    // const data_change = {
    //   room_number:
    //     contentSubmit?.data_submit?.room_number > 0
    //       ? contentSubmit.data_submit.room_number
    //       : 0,
    //   rate_code: contentSubmit?.data_submit?.rate_code,
    //   reservation_id: contentSubmit?.data_guest?.reservation_id,
    //   room_category: contentSubmit?.data_submit?.room_category,
    //   room_category_id: contentSubmit?.data_submit?.room_category_id || 0,
    //   price_id:
    //     contentSubmit?.old_rate?.price_id > 0
    //       ? contentSubmit.old_rate.price_id
    //       : 0,
    //   // date: contentSubmit?.data_submit?.date,
    //   room_rate_id: [
    //     contentSubmit?.data_submit?.rate_code,
    //     contentSubmit?.new_rate?.main_segment_id || 0,
    //     contentSubmit?.new_rate?.id || 0,
    //   ],
    //   room_price:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.old_rate?.room_price
    //       : contentSubmit?.new_rate?.nett -
    //           param_breakfast?.unit_price *
    //             contentSubmit?.new_rate?.room_breakfast || 0,
    //   tax:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.old_rate?.tax
    //       : contentSubmit?.new_rate?.tax -
    //           param_breakfast?.tax * contentSubmit?.new_rate?.room_breakfast ||
    //         0,
    //   service:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.old_rate?.service
    //       : contentSubmit?.new_rate?.service -
    //           param_breakfast?.service *
    //             contentSubmit?.new_rate?.room_breakfast || 0,
    //   gross:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.old_rate?.gross
    //       : contentSubmit?.new_rate?.gross -
    //           param_breakfast?.gross *
    //             contentSubmit?.new_rate?.room_breakfast || 0,
    //   article_id: param_fo.article_id || 0,
    //   order_date: contentSubmit?.data_submit?.date,
    //   with_breakfast: contentSubmit?.data_guest?.is_abf,
    //   compliment_type: contentSubmit?.new_rate?.compliment_type || "2",
    //   segment: contentSubmit?.new_rate?.main_segment_name
    //     ? contentSubmit.new_rate.main_segment_name
    //     : null,
    //   main_segment_id:
    //     contentSubmit?.new_rate?.main_segment_id > 0
    //       ? contentSubmit.new_rate.main_segment_id
    //       : 0,
    //   main_compliment_id:
    //     contentSubmit?.new_rate?.compliment_type > 0
    //       ? parseInt(contentSubmit.new_rate.compliment_type)
    //       : 2,
    //   room_id:
    //     contentSubmit?.old_rate?.id > 0 ? contentSubmit?.old_rate?.id : 0,
    //   reason: contentSubmit?.data_submit?.reason,
    //   quantity: contentSubmit?.new_rate?.room_breakfast,
    //   start_date: contentSubmit?.data_submit?.date,
    //   end_date: contentSubmit?.data_guest?.departure,
    //   new_room_number:
    //     contentSubmit?.data_submit?.new_room_number > 0
    //       ? contentSubmit.data_submit.new_room_number
    //       : 0,

    //   updated_by: user_name,
    // };
    // {
    //   room_number: contentSubmit?.data_submit?.new_room_number || 0,
    //   rate_code: contentSubmit?.data_submit?.rate_code,
    //   reservation_id: contentSubmit?.data_guest?.reservation_id,
    //   room_category: contentSubmit?.data_submit?.room_category,
    //   room_category_id: contentSubmit?.data_submit?.room_category_id || 0,
    //   price_id:
    //     contentSubmit?.old_rate?.price_id > 0
    //       ? contentSubmit.old_rate.price_id
    //       : 0,
    //   date: contentSubmit?.data_submit?.date,
    //   room_rate_id: [
    //     contentSubmit?.data_submit?.rate_code,
    //     contentSubmit?.new_rate?.main_segment_id || 0,
    //     contentSubmit?.new_rate?.id || 0,
    //   ],
    //   room_price:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.new_rate?.nett
    //       : contentSubmit?.new_rate?.nett -
    //           param_breakfast?.unit_price *
    //             contentSubmit?.new_rate?.room_breakfast || 0,
    //   tax:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.new_rate?.tax
    //       : contentSubmit?.new_rate?.tax -
    //           param_breakfast?.tax * contentSubmit?.new_rate?.room_breakfast ||
    //         0,
    //   service:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.new_rate?.service
    //       : contentSubmit?.new_rate?.service -
    //           param_breakfast?.service *
    //             contentSubmit?.new_rate?.room_breakfast || 0,
    //   gross:
    //     contentSubmit?.data_submit?.is_same == true
    //       ? contentSubmit?.new_rate?.gross
    //       : contentSubmit?.new_rate?.gross -
    //           param_breakfast?.gross *
    //             contentSubmit?.new_rate?.room_breakfast || 0,
    //   article_id: param_fo.article_id || 0,
    //   order_date: contentSubmit?.data_submit?.date,
    //   with_breakfast: contentSubmit?.data_guest?.is_abf,
    //   compliment_type: contentSubmit?.new_rate?.compliment_type || "2",
    //   segment: contentSubmit?.new_rate?.main_segment_name
    //     ? contentSubmit.new_rate.main_segment_name
    //     : null,
    //   main_segment_id:
    //     contentSubmit?.new_rate?.main_segment_id > 0
    //       ? contentSubmit.new_rate.main_segment_id
    //       : 0,
    //   main_compliment_id:
    //     contentSubmit?.new_rate?.compliment_type > 0
    //       ? parseInt(contentSubmit.new_rate.compliment_type)
    //       : 2,
    //   room_id:
    //     contentSubmit?.data_submit?.new_room_number_code?.room_id || null,
    //   reason: contentSubmit?.data_submit?.reason,

    //   updated_by: user_name,
    // };

    // const data_same = {
    //   reservation_id: contentSubmit?.data_guest?.reservation_id,
    //   room_id:
    //     contentSubmit?.data_submit?.new_room_number_code?.room_id || null,
    //   room_category: contentSubmit?.data_submit?.room_category_id,
    //   room_price:
    //     contentSubmit?.old_rate?.room_price > 0
    //       ? contentSubmit?.old_rate?.room_price
    //       : 0,
    //   date: contentSubmit?.data_submit?.date,
    //   room_rate_id: contentSubmit?.old_rate?.room_rate_id || 0,
    //   room_number:
    //     contentSubmit?.data_submit?.new_room_number > 0
    //       ? contentSubmit.data_submit.new_room_number
    //       : 0,
    //   price_id: contentSubmit?.old_rate?.price_id || 0,
    //   tax_article:
    //     contentSubmit?.old_rate?.tax > 0 ? contentSubmit?.old_rate?.tax : 0,
    //   service_article:
    //     contentSubmit?.old_rate?.service > 0
    //       ? contentSubmit?.old_rate?.service
    //       : 0,
    //   gross_article:
    //     contentSubmit?.old_rate?.gross > 0 ? contentSubmit?.old_rate?.gross : 0,
    //   is_service_article: contentSubmit?.old_rate?.is_service,
    //   is_tax_article: contentSubmit?.old_rate?.is_tax,
    //   reason_article: contentSubmit?.data_submit?.reason,
    //   coa_article: contentSubmit?.data_submit?.chart_of_account,
    //   old_room_number:
    //     contentSubmit?.data_submit?.room_number > 0
    //       ? contentSubmit.data_submit.room_number
    //       : 0,
    //   guest_article: contentSubmit?.data_guest?.guest_name,

    //   created_by: contentSubmit?.data_guest?.created_by,
    // };

    // console.log("Change Room Category: ", data_change);
    // console.log("Breakfast: ", param_breakfast);

    // console.log("Change Room: ", data_same);

    if (is_new_category) {
      await axios
        .put(
          `${baseurl}/reservation/article-room-change-category`,
          {
            room_number:
              contentSubmit?.data_submit?.room_number > 0
                ? contentSubmit.data_submit.room_number
                : 0,
            rate_code: contentSubmit?.data_submit?.rate_code,
            reservation_id: contentSubmit?.data_guest?.reservation_id,
            room_category: contentSubmit?.data_submit?.room_category,
            room_category_id: contentSubmit?.data_submit?.room_category_id || 0,
            price_id:
              contentSubmit?.old_rate?.price_id > 0
                ? contentSubmit.old_rate.price_id
                : 0,
            // date: contentSubmit?.data_submit?.date,
            room_rate_id: [
              contentSubmit?.data_submit?.rate_code,
              contentSubmit?.new_rate?.main_segment_id || 0,
              contentSubmit?.new_rate?.id || 0,
            ],
            rate_code_id:
              contentSubmit?.new_rate?.id > 0 ? contentSubmit.new_rate.id : 0,
            room_price:
              contentSubmit?.new_rate?.nett -
                param_breakfast?.unit_price *
                  contentSubmit?.new_rate?.room_breakfast || 0,
            tax:
              contentSubmit?.new_rate?.tax -
                param_breakfast?.tax *
                  contentSubmit?.new_rate?.room_breakfast || 0,
            service:
              contentSubmit?.new_rate?.service -
                param_breakfast?.service *
                  contentSubmit?.new_rate?.room_breakfast || 0,
            gross:
              contentSubmit?.new_rate?.gross -
                param_breakfast?.gross *
                  contentSubmit?.new_rate?.room_breakfast || 0,
            article_id: param_fo.article_id || 0,
            order_date: contentSubmit?.data_submit?.date,
            with_breakfast:
              contentSubmit?.new_rate?.room_breakfast > 0 ? true : false,
            compliment_type: contentSubmit?.new_rate?.compliment_type || "2",
            segment: contentSubmit?.new_rate?.main_segment_name
              ? contentSubmit.new_rate.main_segment_name
              : null,
            main_segment_id:
              contentSubmit?.new_rate?.main_segment_id > 0
                ? contentSubmit.new_rate.main_segment_id
                : 0,
            main_compliment_id:
              contentSubmit?.new_rate?.compliment_type > 0
                ? parseInt(contentSubmit.new_rate.compliment_type)
                : 2,
            room_id:
              contentSubmit?.old_rate?.id > 0 ? contentSubmit?.old_rate?.id : 0,
            reason: contentSubmit?.data_submit?.reason,
            quantity: contentSubmit?.new_rate?.room_breakfast,
            start_date: contentSubmit?.data_submit?.date,
            end_date: contentSubmit?.data_guest?.departure,
            new_room_number:
              contentSubmit?.data_submit?.new_room_number > 0
                ? contentSubmit.data_submit.new_room_number
                : 0,

            updated_by: user_name,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("Different Category: ", response);

          is_refresh(true);
          masterSuccessNotification(response, {
            method: 1,
            source: "Room",
          });
          handleCancel();
          is_close(true);
        })
        .catch((error) => {
          console.log("Error: ", error);
          masterFailedNotification(error, { method: 1, source: "Room" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await axios
        .post(
          `${baseurl}/reservation/article-room`,
          {
            reservation_id: contentSubmit?.data_guest?.reservation_id,
            room_id:
              contentSubmit?.data_submit?.new_room_number_code?.room_id || null,
            room_category: contentSubmit?.data_submit?.room_category_id,
            room_price:
              contentSubmit?.old_rate?.room_price > 0
                ? contentSubmit?.old_rate?.room_price
                : 0,
            date: contentSubmit?.data_submit?.date,
            room_rate_id: contentSubmit?.old_rate?.room_rate_id || 0,
            room_number:
              contentSubmit?.data_submit?.new_room_number > 0
                ? contentSubmit.data_submit.new_room_number
                : 0,
            price_id: contentSubmit?.old_rate?.price_id || 0,
            tax_article:
              contentSubmit?.old_rate?.tax > 0
                ? contentSubmit?.old_rate?.tax
                : 0,
            service_article:
              contentSubmit?.old_rate?.service > 0
                ? contentSubmit?.old_rate?.service
                : 0,
            gross_article:
              contentSubmit?.old_rate?.gross > 0
                ? contentSubmit?.old_rate?.gross
                : 0,
            is_service_article: contentSubmit?.old_rate?.is_service,
            is_tax_article: contentSubmit?.old_rate?.is_tax,
            reason_article: contentSubmit?.data_submit?.reason,
            coa_article: contentSubmit?.data_submit?.chart_of_account,
            old_room_number:
              contentSubmit?.data_submit?.room_number > 0
                ? contentSubmit.data_submit.room_number
                : 0,
            guest_article: contentSubmit?.data_guest?.guest_name,

            created_by: contentSubmit?.data_guest?.created_by,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log("RESPONSE CHANGE ROOM >>> ", response);

          // successChangeRoom(response);
          // if (response.status === 200) {
          // changeRoomHistory(contentSubmit);
          is_refresh(true);
          masterSuccessNotification(response, {
            method: 1,
            source: "Room",
          });
          handleCancel();
          is_close(true);
          // }
        })
        .catch((error) => {
          console.log("ERROR >>> ", error);
          // setClick()
          masterFailedNotification(error, { method: 1, source: "Room" });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // changeRoomHistory(contentSubmit);
  };

  const changeRoomHistory = async (value) => {
    const contentSubmit = value;

    // let _data = {
    //   room_id: contentSubmit?.room_id,
    //   room_category: contentSubmit?.room_category,
    //   reservation_id: contentSubmit?.reservation_id,
    //   created_by: contentSubmit?.created_by,
    //   room_price: contentSubmit?.room_price > 0 ? contentSubmit?.room_price : 0,
    //   date: contentSubmit?.date,
    //   room_rate_id: contentSubmit?.room_rate_id,
    //   room_number: contentSubmit?.new_room_number || contentSubmit?.room_number,
    //   price_id: contentSubmit?.price_id || 0,
    //   tax: contentSubmit?.tax > 0 ? contentSubmit?.tax : 0,
    //   service: contentSubmit?.service > 0 ? contentSubmit?.service : 0,
    //   gross: contentSubmit?.gross > 0 ? contentSubmit?.gross : 0,
    //   is_service: contentSubmit?.is_service,
    //   is_tax: contentSubmit?.is_tax,
    //   reason: contentSubmit?.reason,
    //   chart_of_account: contentSubmit?.chart_of_account,

    //   old_room_number: contentSubmit?.room_number
    //     ? contentSubmit?.room_number
    //     : 0,
    //   guest: contentSubmit?.guest_name,
    // };

    // console.log("API SECOND 2: ", _data);

    await axios
      .post(
        `${baseurl}/reservation/article-change-room-history`,
        {
          room_id: contentSubmit?.room_id,
          room_category: contentSubmit?.room_category,
          reservation_id: contentSubmit?.reservation_id,
          created_by: contentSubmit?.created_by,
          room_price:
            contentSubmit?.room_price > 0 ? contentSubmit?.room_price : 0,
          date: contentSubmit?.date,
          room_rate_id: contentSubmit?.room_rate_id,
          room_number:
            contentSubmit?.new_room_number || contentSubmit?.room_number,
          price_id: contentSubmit?.price_id || 0,
          tax: contentSubmit?.tax > 0 ? contentSubmit?.tax : 0,
          service: contentSubmit?.service > 0 ? contentSubmit?.service : 0,
          gross: contentSubmit?.gross > 0 ? contentSubmit?.gross : 0,
          is_service: contentSubmit?.is_service,
          is_tax: contentSubmit?.is_tax,
          reason: contentSubmit?.reason,
          chart_of_account: contentSubmit?.chart_of_account,

          old_room_number: contentSubmit?.room_number
            ? contentSubmit?.room_number
            : 0,
          guest: contentSubmit?.guest_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Res Insert: ", response);
        successChangeRoom(response);
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedChangeRoom(error);
        setIsLoading(false);
      });
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    form.resetFields();

    setData([]);
    setIsLoading(false);
    setLoading(true);

    closeModal(false);
    setOpen(false);
    setClick(false);
  };

  // HANDLE REACT PRINT
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Change Room ${
      data?.data_submit?.reservation_id
        ? data.data_submit.reservation_id
        : " ---- "
    }`,
    onafterprint: () => alert("Print Success"),
  });

  return (
    <>
      <Modal
        className="change-room-modal"
        title={
          <>
            <Row>
              <BiSolidFilePdf
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography style={{ marginLeft: 15 }}>
                {`Change Room Form`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          className="change-room-form"
          name="change-room-form"
          key="change-room-form"
          autoComplete="false"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="horizontal"
          labelAlign="left"
          labelCol={{ span: 8 }}
          labelWrap={{ span: 16 }}
        >
          {!loading ? (
            <Content
              className="content-change-room-form"
              ref={componentRef}
              style={{
                //   border: "solid 1px",
                padding: "15px 80px 30px",
              }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Change Room`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "grey" }}
              />

              <Col span={24} className="col-body">
                <Card
                  title={
                    <>
                      <Typography.Title
                        level={1}
                        style={{ textAlign: "center" }}
                      >
                        {`CHANGE ROOM ${data?.data_guest?.reservation_id}`}
                      </Typography.Title>
                    </>
                  }
                  className="card-form-title"
                  bordered={true}
                  size="small"
                  hoverable={false}
                  style={{
                    borderColor: "black",
                  }}
                >
                  <Typography.Title level={4} className="text-date">
                    Date: {`${moment(today).format("dddd, DD-MMM-YYYY")}`}
                  </Typography.Title>

                  <Divider
                    className="divider-form"
                    orientationMargin={0}
                    style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
                  />

                  <Row className="row-name" style={{ width: "100%" }}>
                    <Form.Item
                      label="Guest Name"
                      className="guest_name"
                      style={{ width: "100%", margin: 0 }}
                    >
                      {data?.data_guest?.guest_name
                        ? data.data_guest.guest_name
                        : ""}
                    </Form.Item>
                  </Row>

                  <Row className="row-company">
                    <Form.Item
                      label="Company"
                      className="company"
                      style={{ width: "100%", margin: 0 }}
                    >
                      {company?.company_name ? company.company_name : ""}
                    </Form.Item>
                  </Row>

                  <Row className="row-checkin-date">
                    <Form.Item
                      label="Check-In Date"
                      className="checkin_at"
                      style={{ width: "100%", margin: 0 }}
                    >
                      {data?.data_guest?.checkin_date
                        ? `${moment(data.data_guest.checkin_date).format(
                            "HH:MM:ss, DD MMM YYYY"
                          )}`
                        : ""}
                    </Form.Item>
                  </Row>

                  <Row className="row-depart-date">
                    <Form.Item
                      label="Expected Departure"
                      className="departure"
                      style={{ width: "100%", margin: 0 }}
                    >
                      {data?.data_guest?.departure
                        ? `${moment(param_fo.checkout_time).format(
                            "HH:mm"
                          )}, ${moment(data.data_guest.departure).format(
                            "DD MMM YYYY"
                          )}`
                        : ""}
                    </Form.Item>
                  </Row>

                  <Row className="row-rate">
                    <Form.Item
                      label={is_new_category ? "Old Rate & Category" : "Rate"}
                      className="rate"
                      style={{ width: "100%", margin: 0 }}
                    >
                      {data?.old_rate?.room_rate_id?.length > 0
                        ? `${data.old_rate.room_rate_id[0] + " - "}` +
                          `${currency} ${
                            data?.old_rate?.gross > 0 ? data.old_rate.gross : 0
                          }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : null}
                    </Form.Item>
                  </Row>

                  {is_new_category ? (
                    <Row className="row-rate">
                      <Form.Item
                        label={"New Category and Rate"}
                        className="rate"
                        style={{ width: "100%", margin: 0 }}
                      >
                        {data?.data_submit?.rate_code
                          ? `${data?.data_submit?.room_category} - ${data?.data_submit?.rate_code} - ` +
                            `${currency} ${
                              data?.data_submit?.new_room_price > 0
                                ? data.data_submit.new_room_price
                                : 0
                            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : null}
                      </Form.Item>
                    </Row>
                  ) : null}

                  <Divider
                    className="divider-form"
                    orientationMargin={0}
                    style={{
                      margin: "0px 0px 10px",
                      backgroundColor: "#d9d9d9",
                    }}
                  />

                  <Row className="row-room">
                    <Card.Grid
                      hoverable={false}
                      className="card-grid-from"
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <Typography.Title className="text-from" level={5}>
                        {`FROM`}
                      </Typography.Title>

                      <Typography.Title className="text-from" level={5}>
                        {data?.old_rate?.room_category || ""}
                      </Typography.Title>

                      <Typography.Title className="text-old-room" level={3}>
                        {data?.data_submit?.room_number > 0
                          ? data.data_submit.room_number
                          : "ERROR"}
                      </Typography.Title>
                    </Card.Grid>

                    <Card.Grid
                      className="card-grid-to"
                      hoverable={false}
                      style={{
                        width: "50%",
                        textAlign: "center",
                      }}
                    >
                      <Typography.Title className="text-to" level={5}>
                        {`TO`}
                      </Typography.Title>

                      <Typography.Title className="text-from" level={5}>
                        {data?.data_submit.room_category || ""}
                      </Typography.Title>

                      <Typography.Title className="text-new-room" level={3}>
                        {data?.data_submit?.new_room_number > 0
                          ? data.data_submit.new_room_number
                          : "ERROR"}
                      </Typography.Title>
                    </Card.Grid>
                  </Row>

                  <Row className="row-reason">
                    <Form.Item
                      label="Reason"
                      className="reason"
                      style={{
                        width: "100%",
                        padding: "30px 0px 0px",
                      }}
                    >
                      <Typography.Title className="text-reason" level={4}>
                        {data?.data_submit?.reason
                          ? data.data_submit.reason
                          : " ERROR "}
                      </Typography.Title>
                    </Form.Item>
                  </Row>

                  <Row
                    className="row-sign-by"
                    style={{ margin: "60px 0px 10px" }}
                  >
                    <Col
                      span={12}
                      className="prepared-by-sign"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography className="text-prepared-by">
                        Prepared by
                      </Typography>
                    </Col>

                    <Col
                      span={12}
                      className="approved-by-sign"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography className="text-approved-by">
                        Approved by
                      </Typography>
                    </Col>
                  </Row>

                  <Row className="row-sign" style={{ margin: "60px 0px 10px" }}>
                    <Col
                      span={12}
                      className="prepared-sign"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: 750,
                      }}
                    >
                      <Typography className="text-dot">{`( ${user_name} )`}</Typography>
                    </Col>

                    <Col
                      span={12}
                      className="approved-sign"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontWeight: 750,
                      }}
                    >
                      <Typography className="text-dot">{`( ${param_fo.registration_sign_by} )`}</Typography>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 10px", color: "black" }}
          />

          <Row className="row-form-btn" justify="end" align="middle">
            <Button
              className="print-btn"
              type="primary"
              icon={<PrinterOutlined />}
              onClick={() => {
                handlePrint();
                if (click == false) {
                  setClick(true);
                }
              }}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="submit-btn"
              type="primary"
              //   icon={<CheckSquareOutlined />}
              disabled={!click}
              htmlType="submit"
              loading={isLoading}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit & Save`}
            </Button>

            <Button
              type="default"
              //   icon={<CloseSquareOutlined />}
              className="close-btn"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
