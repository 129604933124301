// CODES
export default function RoundNumber(props) {
  // PROPS
  const numbers = parseInt(props);

  let newNumber;
  let _minus = 0;

  const _checkLastDigit = numbers.toString();
  const _last = _checkLastDigit.slice(-1);

  _minus = 10 - parseInt(_last);

  if (_last == 0) {
    newNumber = numbers;
  } else {
    newNumber = ((numbers + _minus) / 10) * 10;
  }
  //   const round = Math.ceil((numbers + 1) / 10) * 10;

  return newNumber;
}
