// Import React Components
import React, { useEffect, useState } from "react";

// Import Ant Design Components
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Col,
  Row,
  Typography,
} from "antd";

// Import React Icons
import { AppstoreOutlined } from "@ant-design/icons";

// Import Master Components
import MasterOutletLocation from "../../../../Reusable/Outlet/Master/OutletLocation/CaptainOutletLocation";

// Import Notifications
import { masterIncomplete } from "../../../../Reusable/Notification/MasterNotif/Notification";

// CODE
export default function ModalOutletLocation(props) {
  const { openModal, closeModal, getLocs } = props;

  // CONTEXT

  // STATE MANAGEMENT
  // Modal State
  const [open, setOpen] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("Modal Outlet: ", openModal);
    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // Handle Outlet Location
  const handleOutlet = (val) => {
    const _value = val;

    console.log("Outlet Locations: ", _value);

    form.setFieldsValue({
      outlet_location: _value?.value,
      outlet_id: _value?.id,
      label_outlet_location: _value?.label,
    });
  };

  // CLOSE MODAL
  const handleClose = () => {
    // console.log(e);
    setOpen(false);
    closeModal(false);
    setIsLoading(false);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (value) => {
    const _finish = value;
    console.log("On Finish: ", _finish);

    setIsLoading(true);

    handleClose()
    getLocs(_finish);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _finish = value;
    console.log("FAILED: ", _finish.values);

    masterIncomplete(value);
  };

  return (
    <>
      <Modal
        className="add-article-modal"
        key={"add-article-modal"}
        title={
          <>
            <Row>
              <AppstoreOutlined style={{ fontSize: 24, color: "#3699FF" }} />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Outlet Locations`}</Typography>
            </Row>
          </>
        }
        width={800}
        centered={true}
        open={open}
        closable
        onCancel={handleClose}
        footer={null}
        style={{ padding: 0 }}
      >
        <Form
          className="form-article-stay"
          key="form-article-stay"
          name="form-article-stay"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="horizontal"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{ padding: "0px 36px 0px" }}
        >
          <Row justify="center" className="row-outlet-loc">
            <Col span={20}>
              <Form.Item
                label="Outlet Location"
                name="outlet_location"
                rules={[
                  {
                    required: true,
                    message: "Please, Choose an Options!",
                  },
                ]}
              >
                <MasterOutletLocation
                  getOutletLocation={handleOutlet}
                  outletLocation={null}
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item label="Outlet ID" name="outlet_id" hidden>
                <Input placeholder="Outlet ID" disabled />
              </Form.Item>

              <Form.Item label="Outlet Loc" name="label_outlet_location" hidden>
                <Input placeholder="Outlet Loc" disabled />
              </Form.Item>
            </Col>
          </Row>
          
          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 15px", backgroundColor: "#EBEDF3" }}
          />

          <Row className="row-modal-btn" justify="end" align="middle">
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              loading={isLoading}
              style={{
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
