// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Typography,
  Input,
  Tooltip,
  Checkbox,
  Tag,
  InputNumber,
} from "antd";

// Import React Icons
import { AiOutlineInfoCircle } from "react-icons/ai";
import {
  masterSuccessNotification,
  masterFailedNotification
} from "../../../Reusable/Notification/MasterNotif/Notification";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import ModalGuestInHouse from "../Guest/GuestInHouse";
import MasterSalesType from "../../../Reusable/Outlet/Master/SalesType/SalesType";
import CaptainModalChangeTable from "./CaptainChangeTableModal";

export default function ItemNewOrder(props) {
  // PROPS
  const { openModal, closeModal, getClear, data, locationCategories } = props;

  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openModalChangeTable, setOpenModalChangeTable] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  // Variable
  const [name, setName] = useState(null);
  const [guest, setGuest] = useState(null);
  const [salesType, setSalesType] = useState(null);
  const [table, setTable] = useState(null);
  const [segment, setSegment] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [roomNo, setRoomNo] = useState(null);
  const [priceId, setPriceId] = useState(null);

  // USE FORM
  const [form] = Form.useForm();
  const { Text } = Typography;

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    setLoading(false);
    closeModal(false);
    setRoomNo(null);
    setPriceId(null);

    form.resetFields();
  };

  const handleRefresh = () => {
    setOpen(false);
    setLoading(false);
    closeModal(false);
    getClear();
    setRefresh(!refresh);
    setRoomNo(null);
    setPriceId(null);

    form.resetFields();
  }

  // ON FINISH
  const onFinish = async (e) => {
    const _data = e;
    console.log("ABOUT TO NEW ORDER ", _data);
    handleSubmit(_data, data)
  };

  const onFinishFailed = (e) => {};

  // HANDLE SUBMIT
  const handleSubmit = async (_data, value) => {
    let checkAdd = value.filter((x) => {
      if(x.added_quantity > 0) {
        return true
      } else {
        return false
      }
    });

    let checkStatusItems = checkAdd?.every((x) => x.order_step == 2);

    console.log("checkStatusItems", checkStatusItems);

    // const _data = value;
    let _totalNett = 0;
    let _totalTax = 0;
    let _totalSer = 0;
    let _totalGross = 0;

    data.forEach(({ unit_price, tax, service, gross, item_quantity }) => {
      let _tGross = gross * item_quantity;
      let _tTax = tax * item_quantity;
      let _tSer = service * item_quantity;
      let _tNett = unit_price * item_quantity;

      _totalNett += _tNett;
      _totalSer += _tSer;
      _totalTax += _tTax;
      _totalGross += _tGross;
    });

    // console.log("About TO ORDER: ", _data);
    // console.log("TABLE SELECTED: ", tableKey);

    await axios
      .post(
        `${baseurl}/pos/order-header`,
        {
          name: _data?.name ?? "NON STAY GUEST",
          table_code: table?.table_code || "No Table",
          table_id: table?.table_id || null,
          payment_type: null,
          payment_details: null,
          payment_status: _data?.payment_status == true ? 2 : 3,
          total_nett: _totalNett,
          total_gross: _totalGross,
          total_tax: _totalTax,
          total_service: _totalSer,
          is_open_bill: 1,
          sales_type: _data?.sales_type,
          order_id_alias: null,
          reservation_id: _data?.reservation_id || null, //
          created_by: user_name,
          pax: _data?.pax, //need action
          order_status: false,
          kitchen_status: checkStatusItems ? 2 : 1,
          segment: segment, //need action
          source: 2,
          room_number: roomNo ? roomNo : null,
          price_id: priceId ? priceId : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then(async (response) => {
        console.log("Add Headers: ", response);
        if (response.status === 200) {
          await axios
            .get(`${baseurl}/pos/last-order-id-header`)
            .then((res) => {
              console.log("Res Last ID: ", res);
              
              if (res?.data?.length > 0) {
                handleDetail(res.data[0], checkAdd);
              }
            })
            .catch((error) => {
              console.log("Res Last Err: ", error);
            });
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: `Captain Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: `Captain Order`,
        });
      });
  };

  const handleDetail = async (value, record) => {
    console.log("value handleDetail New Order", value, "record", record);
    const _bill_id = value;
    let bulks = [];

    console.log("roomNo", roomNo);

    for (let i = 0; i < record.length; i++) {
      bulks.push({
        id: record[i]?.article_id,
        article_id: record[i]?.article_id,
        article_sub_name: record[i]?.article_sub_name || null, //Akan selalu null, karena ketika get ga ada datanya
        article_sub_id: record[i]?.article_sub_id,
        article_name: record[i]?.article_name,
        unit_price: record[i]?.unit_price,
        item_quantity: record[i]?.added_quantity,
        total_price: record[i]?.gross * record[i]?.added_quantity,
        table_code: table?.table_code || "No Table",
        table_id: table?.table_id || null,
        serving_order: record[i]?.serving_que,
        guest_name: record?.name ?? "NON STAY GUEST",
        chart_of_account: record[i]?.chart_of_account,
        tax: record[i]?.tax,
        service: record[i]?.service,
        is_tax: record[i]?.tax > 0 ? true : false,
        is_service: record[i]?.service > 0 ? true : false,
        gross: record[i]?.gross,
        add_ons: record[i]?.notes || null,
        notes: record[i]?.notes,
        payment_status: record?.payment_status == true ? 2 : 3,
        order_id_alias: _bill_id?.order_id_alias,
        header_id: _bill_id?.id,
        created_by: user_name,
        order_step: record[i]?.order_step,
        source: 2,
        room_number: roomNo ? roomNo : null,
      });
    };

    console.log("handleDetail > bulks:", bulks);

    await axios
      .post(
        `${baseurl}/pos/order-detail`,
        {
          data: bulks.length > 0 ? bulks : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Add Detail: ", response);

        if (response.status === 200) {
          handleSubstract(record)
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: `Captain Order Item`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: `Captain Order`,
        });
      })
  };

  const handleSubstract = async (record) => {
    let checkSubstract = record.filter((x) => {
      if(x.added_quantity < x.item_quantity) {
        return true;
      } else {
        return false;
      }
    });
    
    let substract = checkSubstract.map((x) => {
      return {
        ...x,
        item_quantity: x.item_quantity - x.added_quantity,
      }
    });

    let successCount = 0;

    console.log("substract", substract);

    if(substract.length > 0) {
      for (let i = 0; i < substract.length; i++) {
        
        await axios
          .put(
            `${baseurl}/pos/order-detail`,
            {
              deleted_reason: "SUBSTRACT ITEM QUANTITY",
              notes: substract[i].notes,
              id: substract[i].id,
              created_by: user_name,
              item_quantity: substract[i].item_quantity,
              serving_order: substract[i].serving_que,
              total_price: substract[i].gross * substract[i].item_quantity,
              tax: substract[i].tax,
              service: substract[i].service,
              is_tax: substract[i].is_tax,
              is_service: substract[i].is_service,
              gross: substract[i].gross,
              add_ons: substract[i].notes,
              order_step: substract[i].order_step,
            },
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("Update Detail: ", response);
              let successCount = i + 1;
              if(successCount == substract.length){
                console.log("substract.length", substract.length, "successCount", successCount);
                handleDelete(record);
              }
            } else {
              masterFailedNotification(response, {
                method: 1,
                source: `Add Captain Order Item  - Substract Process`,
              });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            masterFailedNotification(error, {
              method: 1,
              source: `Add Captain Order Item - Substract Process`,
            });
          })
      }
      
    } else {
      handleDelete(record);
    }
  };

  const handleDelete = async (record) => {
    let checkDelete = record.filter((x) => {
      if(x.added_quantity == x.item_quantity) {
        return true;
      } else {
        return false;
      }
    });

    console.log("checkDelete", checkDelete);
    if(checkDelete.length > 0) {
      for (let i = 0; i < checkDelete.length; i++) {
        let obj =
          {
            id: checkDelete[i].id,
            order_id_alias: checkDelete[i].order_id_alias,
            deleted_by: user_name,
            deleted_reason: "DELETE FROM TRANSFER",
          };
        
        console.log("obj", obj)
        await axios
          .delete(`${baseurl}/pos/order-detail`, {
            data: obj,
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("DEL POS Bill Detail >> ", response);
              let successCount = i + 1;
              console.log("substract.length", checkDelete.length, "successCount", successCount);
              if(successCount == checkDelete.length) {
                masterSuccessNotification(response, {
                  method: 0,
                  source: `Add Items to New Captain Order`,
                });
              }
              // fetchTable();
            }
          })
          .catch((error) => {
            console.log("ERROR DEL POS Bill Detail >> ", error);
            masterFailedNotification(error, {
              method: 2,
              source: `Add Captain Order Item - Delete Item`,
            });
          });
      }
      handleRefresh();
    } else {
      masterSuccessNotification("", {
        method: 0,
        source: `Add Items to New Captain Order`,
      });
      handleRefresh();
    }
    
  }

  // HANDLE NAME ONCHANGE
  const handleChangeName = (value) => {
    // console.log("handleChangeName", value.target.value);
    setSegment(1);
    form.setFieldsValue({
      payment_status: false
    })
    
    if(value.target.value) {
      setName(value.target.value);
    } else {
      setName(null);
    }
  };

  // Open Modal Guest In House
  const handleGuestName = () => {
    setModalOpen(true);
  };

  // GET GUEST NAME
  const getGuestName = (value) => {
    const _guest = value;
    console.log("getGuestName > value", _guest);

    setRoomNo(_guest[0]?.room_number);
    setPriceId(_guest[0]?.price_id);
    setSegment(2);
    if(_guest?.length > 0) {
      setName(_guest[0]?.guest_name);
    } else {
      setName(_guest.guest_name);
    }

    form.setFieldsValue({
      name: _guest?.length > 0 ? _guest[0]?.guest_name : _guest.guest_name,
      reservation_id: _guest?.length > 0 ? _guest[0]?.reservation_id : _guest.reservation_id,
      payment_status: _guest[0]?.payment_status == 2 ? true : false,
    });
  };

  // Handle Sales Type
  const getSalesType = (value) => {
    console.log("perTableCaptain > getSalesType > ", value);

    form.setFieldsValue({
      sales_type: value?.value ?? null,
    });

    if(value?.value) {
      setSalesType(value?.value);
    } else {
      setSalesType(null);
    }

    if (value?.value == 1) {
      setOpenModalChangeTable(true);
    } else {
      setTable(null);
    }
  };

  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={
          <>
            <Row className="cancel-order-modal-row">
              <AiOutlineInfoCircle
                style={{
                  color: "#f5222d",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Add Item to New Order`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={500}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={
            {
              // deleted_reason: "CANCEL ORDER",
            }
          }
          style={{ padding: "0px 36px" }}
        >
          <Row
            className="cancel-order-modal-form-serve-row"
            //   justify="center"
            //   gutter={[30, 30]}
            style={{ margin: "0px 40px 10px 0px", width: "100%" }}
          >
            <Text>{`Please, input Name and Sales Type!`}</Text>
          </Row>

          <Row
            className="name-row"
            justify="start"
            style={{ margin: "0px 0px" }}
          >
            <Col span={24} className="name-col">
              <Form.Item
                label="Name"
                name="name"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className="name-input"
                  placeholder="Name"
                  onChange={handleChangeName}
                  value={name}
                  addonAfter={
                    <Tooltip
                      className="modal-tooltip"
                      title="Find Guest In-House"
                    >
                      <MdOutlineDashboardCustomize
                        className="suffix-icons"
                        onClick={handleGuestName}
                        style={{
                          fontSize: 20,
                          color: "#3699FF",
                          marginTop: 3,
                        }}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <Form.Item label="" name="reservation_id" hidden />
            </Col>
          </Row>
          <Row
            className="name-row"
            justify="start"
            style={{ margin: "0px 0px" }}
          >
            <Col style={{width:"50%"}}>
              <Form.Item
                label=""
                name="payment_status"
                valuePropName="checked"
              >
                <Checkbox
                  className="pax-input"
                  placeholder="Total Pax"
                  min={0}
                  disabled
                  style={{
                    width: "100%",
                  }}
                >
                  {`Charge To Room`}
                </Checkbox>
              </Form.Item>
            </Col>
            <Col>
            {segment == 1 ? <Tag color="#2db7f5">Source: Walk In Guest</Tag> : segment == 2 ? <Tag color="#108ee9">Source: Guest In House</Tag> : ""}
            </Col>
          </Row>
          <Row
            className="serve-row"
            justify="center"
            style={{ margin: "0px 0px" }}
          >
            <Col span={24} className="serve-col">
              <Form.Item
                label="Sales Type"
                name="sales_type"
                rules={[
                  {
                    required: true,
                    message: "Please, Input the Sales Type!",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <MasterSalesType
                  getSalesType={getSalesType}
                  sales_type={
                    {
                      1: "Dine-In",
                      2: "Take Away",
                      3: "Online Order",
                    }[form.getFieldsValue().sales_type] ?? null
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            className="serve-row"
            justify="center"
            style={{ margin: "0px 0px" }}
          >
            <Col span={24} className="serve-col">
              <Form.Item
                label="Pax"
                name="pax"
                rules={[
                  {
                    required: true,
                    message: "Please, Input Pax Number!",
                  },
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputNumber
                  placeholder="Pax"
                  min={1}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row
            className="cancel-order-modal-form-serve-row"
            //   justify="center"
            //   gutter={[30, 30]}
            style={{ margin: "0px 40px 10px 0px" }}
          >
            <Text>{`Are you sure you want to Add Items to New Item?`}</Text>
          </Row>

          <Divider
            className="cancel-order-modal-form-divider-form"
            style={{ margin: "0px 0px 10px 0px" }}
          />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              onClick={(e) => {
                // selectedTable(tableKeys);
                // handleCancel();
              }}
              loading={loading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Yes`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`No`}
            </Button>
          </Row>
        </Form>
      </Modal>
      <ModalGuestInHouse
        openModal={modalOpen}
        closeModal={setModalOpen}
        getGuestName={getGuestName}
        refresh={refresh}
      />
      <CaptainModalChangeTable
        openModal={openModalChangeTable}
        closeModal={setOpenModalChangeTable}
        locationCategories={locationCategories}
        selectedTable={(e) => {
          // setChangeTableState(true);
          setTable(e);
        }}
      />
    </>
  );
}
