// Import React Components
import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token, user_name } from "../../../API/Global/Payload";
import { authorized_token } from "../../../API/Global/Permission";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Popover,
  Typography,
  Modal,
  Form,
  Input,
  Tooltip,
  Divider,
  Checkbox,
  Result,
  Select,
  InputNumber,
} from "antd";
import { FormOutlined, LoadingOutlined } from "@ant-design/icons";
import { FiEdit } from "react-icons/fi";

// Import Master Components
import MasterRoomNumberCkIn from "../../Reusable/Master/RoomNumber/RoomCheckIn";
import OptionRoomCategory from "../../Reusable/Master/RoomCat/OptionRoomCategory";
import RegistrationGroupForm from "../../Forms/RegistrationGroup/Form";

// import MasterRoomCatCheckIn from "../../Reusable/Master/RoomCat/RoomCatCheckIn";
import RegistrationForm from "../../Forms/Registration/Form";
import MasterRoomCatCheckIn from "../../Reusable/Master/RoomCat/OptionRoomCategory";
import MasterRoomNumber from "../../Reusable/Master/RoomNumber/RoomNumber";

// Import Notifications
import {
  failedCheckIn,
  failedFetch,
  incomplete,
  successCheckIn,
} from "../../Reusable/Notification/Notification";
import { masterFailedNotification } from "../../Reusable/Notification/MasterNotif/Notification";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function ModalArrivalGroup(props) {
  // PROPS
  const {
    // Data
    dataRes,
    is_refresh,
    // Modal Data
    dataModal,
    // dataLead,
    // Modal State
    openModal,
    closeModal,
  } = props;

  // CONTEXT
  const { getMasterRoomCat } = useContext(MasterContext);
  const { getReservation, getGuest, getArticleRoomGroup, getMasterBillTrx } =
    useContext(MainContext);

  // STATE MANAGEMENT
  // Open Modals
  // FLAGS LEADER
  const [open, setOpen] = useState(false);
  // Data
  const [data, setData] = useState(null);
  const [detail, setDetail] = useState([]);
  const [billReceiver, setBillReceiver] = useState(0);
  const [billMember, setBillMember] = useState([]);
  const [billData, setBillData] = useState([]);
  // Data To Forms
  const [optionLeader, setOptionLeader] = useState([]);
  const [lead, setLead] = useState(null);
  const [roomLeader, setRoomLeader] = useState(0);
  const [roomMember, setRoomMember] = useState([]);
  const [price, setPrice] = useState([]);
  const [article, setArticle] = useState([]);
  const [room, setRoom] = useState([]);
  const [dataRsv, setDataRsv] = useState([]);
  const [checkBox, setCheckBox] = useState({
    is_smoke: false,
    is_connecting: false,
  });
  // Room Category
  const [roomCode, setRoomCode] = useState(null);
  // Modal Forms
  const [chartOfAcc, setChartOfAcc] = useState(null);
  // Guest Show
  const [noShow, setNoShow] = useState(false);
  // Forms State
  // Modals
  const [initialForm, setInitialForm] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // GET CODE CATEGORY
  const code = OptionRoomCategory().optionValue;

  // USEFORM
  const [form] = Form.useForm();

  // FETCH ROOM CATEGORY
  const fetchRoomCode = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Response Room Category => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;
          let filtered = _res
            .filter((filt) => {
              return !filt.is_deleted;
            })
            .map((e) => ({
              room_category_name: e?.room_category_name,
              room_category_code: e?.room_category_code,
              room_category_id: e?.id,
            }));
          //   setOptionValue(filtered);

          // console.log("Room Category: ", filtered);
          setRoomCode(filtered);
        }
      },
      onReject: (error) => {
        setLoading(false);
        console.log("Failed to get Room: ", error);
        failedFetch(error);
      },
    });
  };

  // Fetch Data
  const fetchDataPrice = async () => {
    await getArticleRoomGroup({
      article: "reservation",
      type: "article-room-group",
      reservation_id: data[0]?.reservation_id
        ? data[0].reservation_id
        : detail[0]?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Article Room Group => ", response);

        if (response?.data?.msg?.length > 0 || response?.data) {
          let _response = response?.data?.msg;

          setRoom(_response);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });

    await getMasterBillTrx({
      bill: "reservation",
      type: "article-group",
      res_id: data[0]?.reservation_id
        ? data[0].reservation_id
        : detail[0]?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Article Stay Group => ", response);

        if (response?.data?.length > 0) {
          setArticle(response?.data);
        } else {
          setArticle([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
      },
    });
  };

  // GET DATA RESERVATION
  const fetchData = async () => {
    await getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {
        "on Await";
      },
      onSuccess: (response) => {
        console.log("Response Reservation >>>> ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          let filtered = _response.filter((e) => {
            return e?.reservation_id == data[0].reservation_id;
          });

          console.log("filtered Res >>>>", filtered);
          // console.log(detail);
          setDataRsv(filtered?.length > 0 ? filtered : []);
        }
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // Fetch Data Guest
  const fetchDataGuest = async () => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response?.data?.msg;

          const filtered = _res.filter((e) => {
            return e.id == data[0]?.guest_id;
          });

          console.log("Filter Guest >>> ", filtered);
          setLead(filtered.length > 0 ? filtered[0] : []);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dataRes?.length > 0 && dataModal?.length > 0 && !cleanUp) {
      fetchRoomCode();
      setOpen(openModal);

      console.log("Data Res calls multiple: ", dataRes);
      console.log("Data Modal: ", dataModal);

      setData(dataRes);
      handleLeaderOptions(dataModal, dataRes);
      setDetail(dataModal);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataRes, dataModal]);

  useEffect(() => {
    let cleanUp = false;

    if (roomCode?.length > 0 && roomLeader > 0 && !cleanUp) {
      console.log("Category Selected = ", roomCode);
      console.log("Room Leaders = ", roomLeader);

      setFieldForm(roomLeader, roomCode);
    }

    return () => {
      cleanUp = true;
    };
  }, [roomCode, roomLeader]);

  // Initial Forms
  useEffect(() => {
    let cleanUp = false;

    if (initialForm?.length > 0 && !cleanUp) {
      console.log("Initial Form is Setted: ", initialForm);

      form.setFieldsValue({
        room_modal: initialForm,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [initialForm]);

  // ARTICLE SETTINGS PRICE
  useEffect(() => {
    let cleanUp = false;

    if ((room?.length > 0 || article?.length > 0) && !cleanUp) {
      console.log("Room List: ", room);
      console.log("Article List: ", article);

      handlePrice(room, article);
    } else {
      // masterFailedNotification( )
    }

    return () => {
      cleanUp = true;
    };
  }, [room, article]);

  // Create options for Leaders
  const handleLeaderOptions = (value, bills) => {
    const _arr = value;

    console.log("Value: ", _arr);

    const options = _arr.map((items) => ({
      value: items?.room_category_id,
      label: items?.room_category,
      room_category: items?.room_category,
      room_category_id: items?.room_category_id,
    }));

    // console.log("Option Leaders: ", options);
    setOptionLeader(options);

    form.setFieldValue("reservation_id", _arr[0]?.reservation_id);
    form.setFieldValue("bill_receiver", bills[0]?.guest_name);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  // SET FIELDS FORM
  const setFieldForm = async (val, code) => {
    const details = detail;
    const room = code;
    const lead_cat_id = form.getFieldValue("room_category_id");

    console.log("Details: ", details);
    console.log("Room: ", room);
    console.log("Leaders Cat ID: ", lead_cat_id);

    let _arr = details.map((dev) => ({
      reservation_id: dev?.reservation_id,
      room_category: dev?.room_category,
      room_category_id: room.find((x) => {
        return x.room_category_id === dev?.room_category_id;
      })?.room_category_id,
      room_category_code: room.find((x) => {
        return x.room_category_id === dev?.room_category_id;
      })?.room_category_code,
      room_no: null,
      room_number: null,
      room_qty:
        dev?.room_category_id == lead_cat_id ? dev.room_qty - 1 : dev?.room_qty,
    }));

    console.log("Initial Forms Array: ", _arr);

    const _afterFilter = _arr.filter((items) => {
      return items.room_qty > 0;
    });

    console.log("Initial Forms Array Filtered: ", _afterFilter);

    setInitialForm(_afterFilter);
  };

  // SET COA
  const setCoa = (val) => {
    // console.log("Existed COA --> ", val);

    if (val != null || val != undefined) {
      setChartOfAcc(val);
    }

    // console.log("CHART OF ACCOUNT: ", chartOfAcc);
    // setRoomSettings({
    //   chart_of_acount: val,
    //   ...roomSettings,
    // });
  };

  // HANDLE CATEGORY LEADER
  const handleCategoryLeader = (val, alls) => {
    console.log("VALUES: ", val, alls);
    setBillReceiver(val);

    form.setFieldValue("room_category", alls?.room_category);
    form.setFieldValue("room_category_id", val);

    if (val > 0) {
      handleRemoveLead({ bill: val, data: data });
    }
  };

  // HANDLE CLEAR
  const handleClear = () => {
    setBillReceiver(0);
  };

  // Handle Remove Leaders
  const handleRemoveLead = (val) => {
    const _arr = val.data;
    const _rmv = val.bill;

    let _lead = [];

    for (let i = 0; i < _arr.length; i++) {
      if (_arr[i]?.room_category_id == _rmv) {
        _lead.push(_arr[i]);
        setBillData(_arr[i]);

        break;
      }
    }

    console.log("Data Leader: ", _lead);

    if (_lead.length > 0) {
      let _rest = _arr.filter((items) => {
        return items.price_id != _lead[0]?.price_id;
      });

      console.log("Data Rest: ", _rest);
      setBillMember(_rest);
    }
  };

  // GET ROOM
  const setRoomInfo = (val) => {
    const info = val;

    console.log("Room Informations --> ", info);
    setCheckBox({
      is_connecting: info?.smoking_room,
      is_smoke: info?.is_smoking,
    });

    form.setFieldsValue({
      room_number: info?.label,
      room_overlook: info?.room_overlook || "Data Unavailable",
      room_bedsetup: info?.room_type || "Data Unavailable",
      room_floor: info?.floor || "Data Unavailable",
      smoking_room: info?.is_smoking,
      connecting_room: info?.is_connecting,
    });
  };

  // GET ROOM NUMBER
  // Room Number
  const getRoomNumber = (value) => {
    const _info = value?.info;
    const _index = value?.form_index;
    const _data = value;

    const fields = form.getFieldsValue();
    const { room_modal } = fields;

    console.log("Fields Value: ", fields);
    console.log("Value: ", _data);

    Object.assign(room_modal[_index], {
      room_number: _data?.room_id,
      room_no: _data?.number,
      coa: _data?.coa,
    });

    console.log("Object room no assign: ", room_modal[_index]);

    setRoomMember(_data?.number);

    form.setFieldsValue({ room_modal });
  };

  // Room Leader
  const getRoomLeader = (value) => {
    const _data = value;

    setRoomLeader(_data?.number);

    form.setFieldsValue({
      room_number: _data?.room_id,
      room_no: _data?.number,
      coa: _data?.coa,
    });
  };

  // Handle Sorter
  const handleSorter = (submit, alls) => {
    // SORT or ORDER DATA RESERVATION BY LIST OF ROOM ASSIGNMENT CATEGORY
    console.log("Submit about to Re-Ordering: ", submit);
    console.log("Submit ALL: ", alls);

    const _roomNumber = submit.map(({ room_no }) => room_no).flat();

    const correctOrder = submit.map(
      ({ room_category, room_category_code, room_category_id }) => {
        return { room_category_id, room_category, room_category_code };
      }
    );

    // setRoomNumberFlat(_roomNumber);

    const sorted = [];
    const final = [];

    // REORDERING
    correctOrder.forEach((k) => {
      let n = billMember.filter((obj) => {
        return obj.room_category_id == k.room_category_id;
      });

      if (n.length > 0) {
        sorted.push(...n);
      }
    });

    console.log("Leaders room number: ", alls?.room_no, billData);

    final.unshift({
      ...billData,
      room_number: alls?.room_no,
      group: 1,
    });

    // PUSH TO FINAL BODY
    if (sorted?.length > 0) {
      for (let i = 0; i < sorted.length; i++) {
        final.push({
          ...sorted[i],
          room_number: _roomNumber[i],
          group: 2,
        });
      }
    }

    console.log("Room Number: ", _roomNumber);
    console.log("Correct Order: ", correctOrder);
    console.log("Sorting: ", sorted);
    console.log("Sorting Finale: ", final);
    // setReservation(sorted);

    const allBody = [];

    for (let i = 0; i < final?.length; i++) {
      allBody.push({
        room_number: final[i]?.room_number > 0 ? final[i]?.room_number : 0,
        price_id: final[i]?.price_id,
        reservation_id: final[i]?.reservation_id,
        room_category_id: final[i]?.room_category_id,
        room_category: final[i]?.room_category,
        group: final[i].group,
        // updated_by: user_name,
      });
    }

    console.log("Sorting Finale Body: ", allBody);

    return final;
  };

  // HANDLE PRICE
  const handlePrice = (_arrRoom, _arrArt) => {
    let _joinArr = [];
    const _room = _arrRoom;
    const _article = _arrArt;

    let _newPriceRoom = _room.map((val) => {
      return { ...val, room_price: val?.gross, total_price: val?.gross };
    });

    let _newPriceArticle = _article.map((val) => {
      return { ...val, room_price: val?.gross };
    });

    console.log("New Article Price: ", _newPriceArticle, _newPriceRoom);

    _joinArr = [..._newPriceRoom, ..._newPriceArticle];

    setPrice(_joinArr);
  };

  // ON FINISH
  const onFinish = async (val) => {
    const value = val;
    const submit = val.room_modal;

    console.log("ON FINISH --> ", value);

    setIsLoading(true);
    setRoom(submit);

    // console.log("Details --> ", roomSettings);

    if (value?.is_no_show == true) setNoShow(value?.is_no_show);

    //
    const sorter = handleSorter(submit, value);

    console.log("Return Sorter: ", sorter);

    if (sorter?.length > 0) showModalConfirm(sorter, value);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val, alls) => {
    const _submit = val;

    confirm({
      className: "checkin-confirm",
      title: `Are you sure you want to Check-In for the Group with Guest named ${
        data[0]?.guest_category === "INCOGNITO"
          ? "PRIVATE"
          : data[0]?.guest_name.toUpperCase() || " ----- "
      } with Reservation ID ${
        data[0]?.reservation_id || "-----"
      } and total member group is ${_submit?.length} People?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_submit, alls);
        // console.log("Article deleted");
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (val, alls) => {
    const _body = val;
    const _body_all = alls;
    // e.preventDefault();

    // console.log("EDITED > ", data);
    console.log("About to PUT: ", _body);

    const allBody = [];

    for (let i = 0; i < _body?.length; i++) {
      allBody.push({
        room_number: _body[i]?.room_number > 0 ? _body[i]?.room_number : 0,
        price_id: _body[i]?.price_id,
        reservation_id: _body[i]?.reservation_id,
        group_id: _body[i]?.group,
        // updated_by: user_name,
      });
    }

    console.log("All Body = ", allBody);

    await axios
      .put(
        `${baseurl}/reservation/room-number-group`,
        {
          data: allBody,
        },
        {
          headers: {
            Authorization: `Bearer ${
              authorized_token ? authorized_token : token
            }`,
          },
        }
      )
      .then((response) => {
        console.log("Room Number Updated: ", response);

        successCheckIn(response, { method: 3 });
        // is_refresh(true);
        fetchData();
        fetchDataGuest();
        fetchDataPrice();

        setTimeout(() => {
          setOpenForm(true);
          setIsLoading(false);

          handleClose();
        }, 2000);
      })
      .catch((error) => {
        console.log("Error Updated: ", error);
        setIsLoading(false);
        failedCheckIn(error, { method: 3 });
      });
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const error = val;

    console.log("Failed >>> ", error);

    incomplete(error);
    setIsLoading(false);
    // setOpen(true);
  };

  // HANDLE CLOSE
  const handleClose = () => {
    // Form
    setInitialForm(null);
    setLoading(true);
    setBillReceiver(0);
    setData(null);

    setRoomCode(null);
    setRoomLeader(0);

    setDetail(null);

    form.resetFields();
    setIsLoading(false);

    closeModal(false);
    setOpen(false);
  };

  // console.log("INIT >>>", initialForm);

  return (
    <>
      <Modal
        className="modal-check-in"
        title={
          <>
            <Row>
              <FiEdit style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`Modal Check-In Group`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        bodyStyle={{
          padding: "0px 30px 30px",
        }}
        width={1000}
      >
        <Form
          name="checkin-form"
          className="checkin-form"
          key="checkin-form"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{ span: 16 }}
          //   wrapperCol={{ span: 12 }}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            is_no_show: false,
            room_qty: 1,
          }}
        >
          <Row className="modal-row" justify="center">
            <Col className="reservation-col" span={24}>
              {!loading ? (
                <>
                  <Divider
                    className="divider-form"
                    orientation="left"
                    orientationMargin={0}
                    style={{
                      borderColor: "#d9d9d9",
                    }}
                  >
                    {`Select Leaders (Bill Receiver's Room)`}
                  </Divider>

                  <Row
                    className={`row-leaders`}
                    key={`row-leaders`}
                    gutter={10}
                    style={{ width: "100%" }}
                  >
                    <Col span={5}>
                      <Form.Item label="Reservation ID" name={"reservation_id"}>
                        <Input placeholder="Reservation ID" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={5}>
                      <Form.Item label="Room Category" name={"room_category"}>
                        <Select
                          placeholder="Room Category"
                          allowClear
                          showSearch
                          disabled={roomLeader > 0 ? true : false}
                          onChange={(val, code) => {
                            console.log("ONCHANGE: ", val, code);

                            handleCategoryLeader(val, code);
                          }}
                          onClear={handleClear}
                          value={billReceiver}
                          options={optionLeader}
                        />
                      </Form.Item>

                      <Form.Item
                        label="Room Category ID"
                        name={"room_category_id"}
                        hidden
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <Form.Item label="Qty" name={"room_qty"}>
                        <Input placeholder="Room Qty" disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item
                        label="Room Number"
                        name={"room_number"}
                        rules={[
                          {
                            required: true,
                            message: "Room Number is Empty!",
                          },
                          // }),
                        ]}
                      >
                        <MasterRoomNumberCkIn
                          name={null}
                          roomCode={billReceiver > 0 ? billReceiver : 0}
                          getRoomNumber={getRoomLeader}
                          roomInfo={setRoomInfo}
                          room_number={
                            detail?.room_number
                              ? detail?.room_number.filter((e) => {
                                  if (e != 0) {
                                    return e;
                                  }
                                })
                              : []
                          }
                          existed_coa={setCoa}
                          isGroup={false}
                          memberRoom={roomMember}
                          disable={billReceiver ? false : true}
                          isClose={openForm}
                        />
                      </Form.Item>

                      <Form.Item hidden label="Room No" name={"room_no"}>
                        <Input />
                      </Form.Item>

                      <Form.Item hidden label="COA" name={"coa"}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label="Bill Receiver" name={"bill_receiver"}>
                        <Input disabled placeholder="Bill Receiver" />
                      </Form.Item>
                    </Col>
                  </Row>

                  {roomLeader > 0 ? (
                    <Col span={24}>
                      <Divider
                        className="divider-form"
                        orientation="left"
                        orientationMargin={0}
                        style={{
                          borderColor: "#d9d9d9",
                        }}
                      >
                        {`Select Members Room`}
                      </Divider>

                      <Form.List name="room_modal">
                        {(fields, { add, remove }) => (
                          <>
                            {/* {console.log("THIS STATE IS EXECUTE >>>>")} */}
                            {fields.map(
                              ({ key, name }) => (
                                // {
                                //   console.log("KEY => ", key);
                                //   console.log("NAME => ", name);
                                //   console.log(...restField)
                                <Row
                                  className={`room-modal-${key}`}
                                  key={`${key}`}
                                  gutter={10}
                                  style={{ width: "100%" }}
                                >
                                  {/* {console.log("KEY => ", key, name)} */}
                                  <Col span={5}>
                                    <Form.Item
                                      label="Reservation ID"
                                      name={[name, "reservation_id"]}
                                      //dependencies={[name, "room_category_hide"]}
                                      // style={{
                                      //     width: 205,
                                      //   margin: "0px 30px 0px 0px",
                                      // }}
                                    >
                                      <Input
                                        placeholder="Reservation ID"
                                        disabled
                                        // style={{ fontSize: 12, width: "100%" }}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={5}>
                                    <Form.Item
                                      label="Room Category"
                                      name={[name, "room_category"]}
                                      //dependencies={[name, "room_category_hide"]}
                                      // style={{
                                      //     width: 205,
                                      //   margin: "0px 30px 0px 0px",
                                      // }}
                                    >
                                      <Input
                                        placeholder="Room Category"
                                        disabled
                                        // style={{ fontSize: 12, width: "100%" }}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={2}>
                                    <Form.Item
                                      label="Qty"
                                      name={[name, "room_qty"]}
                                    >
                                      <Input
                                        placeholder="Room Qty"
                                        disabled
                                        // style={{ fontSize: 12, width: "100%" }}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      label="Room Number"
                                      name={[name, "room_number"]}
                                      // hasFeedback
                                      // validateStatus="error"
                                      // help="Room Number Choosed has reached its limit by Room Quantity!"
                                      rules={[
                                        // ({ getFieldValue }) => ({
                                        {
                                          validator: async (_, value) => {
                                            const _field =
                                              form.getFieldValue().room_modal[
                                                name
                                              ];
                                            // console.log(
                                            //   `Number of Max Room = ${code[name]?.room_qty} but Number of Room Picked =  ${code[name]?.room_number?.length}`
                                            // );

                                            const _max =
                                              _field?.room_qty > 0
                                                ? _field.room_qty
                                                : 0;

                                            const _qty =
                                              _field?.room_number?.length > 0
                                                ? _field.room_number.length
                                                : 0;

                                            // if (groupState) {
                                            // console.log("Modal --> ", _field);
                                            // console.log(
                                            //   "Detail Key --> ",
                                            //   detail[key]?.room_number
                                            // );

                                            if (
                                              detail[key]?.room_number &&
                                              _max > 0
                                            ) {
                                              // console.log(
                                              //   "Detail Key --> ",
                                              //   detail[key]?.room_number,
                                              //   _qty,
                                              //   _max
                                              // );

                                              if (_qty < _max || _qty > _max) {
                                                return Promise.reject(
                                                  new Error(
                                                    "Room Numbers Assignment must be Equal with the Room Quantities!"
                                                  )
                                                );
                                              }
                                            } else {
                                              return Promise.reject(
                                                new Error(
                                                  "Room Numbers Assignment must be Equal with the Room Quantities!"
                                                )
                                              );
                                            }
                                            // }
                                          },
                                        },
                                        {
                                          required: true,
                                          message: "Room Number is Empty!",
                                        },
                                        // }),
                                      ]}
                                      // style={{
                                      //     width: 205,
                                      //   margin: "0px 30px 0px 0px",
                                      // }}
                                    >
                                      <MasterRoomNumberCkIn
                                        name={name}
                                        roomCode={
                                          initialForm?.length > 0
                                            ? initialForm[key]?.room_category_id
                                            : null
                                        }
                                        getRoomNumber={getRoomNumber}
                                        roomInfo={setRoomInfo}
                                        room_number={
                                          detail[key]?.room_number
                                            ? detail[key]?.room_number.filter(
                                                (e) => {
                                                  if (e != 0) {
                                                    return e;
                                                  }
                                                }
                                              )
                                            : []
                                        }
                                        existed_coa={setCoa}
                                        isGroup={true}
                                        leaderRoom={roomLeader}
                                        disable={roomLeader > 0 ? false : true}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      hidden
                                      label="Room No"
                                      name={[name, "room_no"]}
                                    >
                                      <Input />
                                    </Form.Item>

                                    <Form.Item
                                      hidden
                                      label="COA"
                                      name={[name, "coa"]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )
                              // }
                            )}
                          </>
                        )}
                      </Form.List>
                    </Col>
                  ) : null}

                  <Row
                    className="row-checkin-modal"
                    justify="start"
                    style={{ width: "100%", height: 70 }}
                  >
                    <Col span={12} style={{ width: "100%" }}>
                      <Form.Item
                        name="is_no_show"
                        valuePropName="checked"
                        label="Guest's Showing Status"
                        wrapperCol={{
                          // offset: 8,
                          span: 24,
                        }}
                      >
                        <Checkbox>
                          <Typography
                            style={{ fontWeight: 750 }}
                          >{`No Show`}</Typography>
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ) : (
                <Result
                  className="failed-result"
                  status="info"
                  title="Loading Data!"
                  icon={
                    <LoadingOutlined className="loading-icon" spin={true} />
                  }
                />
              )}
            </Col>

            <Divider
              className="divider-form"
              style={{ margin: "0px 0px 5px", backgroundColor: "#d9d9d9" }}
            />

            <Row
              justify="end"
              align="middle"
              style={{ padding: "0px 0px 5px", width: "100%" }}
            >
              <Button
                type="primary"
                htmlType="submit"
                key={"submit"}
                // onClick={onFinish}
                disabled={loading}
                loading={isLoading}
                className="submit-btn"
                style={{
                  marginRight: 15,
                }}
              >
                {`Submit`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleClose}
              >
                {`Cancel`}
              </Button>
            </Row>

            <Col
              className="room-info-col"
              span={24}
              style={{
                padding: "0px 30px 0px",
                border: "1px solid #000000",
                borderRadius: 5,
              }}
            >
              <Row style={{ fontSize: 20, fontWeight: 750 }}>
                Room Informations
              </Row>

              <Row>
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Room Floor</b>}
                  name="room_floor"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Room Floor"
                    // disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label={<b style={{ fontSize: "12px" }}>Room Bedsetup</b>}
                  name="room_bedsetup"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Room Bedstup"
                    // disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Form.Item
                  label={
                    <b style={{ fontSize: "12px" }}>Room Overlook/Overview</b>
                  }
                  name="room_overlook"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder="Room Overlook"
                    // disabled
                    // style={{ fontSize: 12, width: "100%" }}
                  />
                </Form.Item>
              </Row>

              <Row>
                <Col span={12} style={{ width: "100%" }}>
                  <Form.Item
                    label={<b style={{ fontSize: "12px" }}>Smoking Room</b>}
                    name="smoking_room"
                    valuePropName="checked"
                    style={{ width: "100%" }}
                  >
                    <Checkbox
                      checked={checkBox.is_smoke}
                      // placeholder="Room Overlook"
                      // disabled
                      // style={{ fontSize: 12, width: "100%" }}
                    />
                  </Form.Item>
                </Col>

                <Col span={12} style={{ width: "100%" }}>
                  <Form.Item
                    label={<b style={{ fontSize: "12px" }}>Connecting Room</b>}
                    name="connecting_room"
                    valuePropName="checked"
                    style={{ width: "100%" }}
                  >
                    <Checkbox
                      checked={checkBox.is_smoke}
                      // placeholder="Room Overlook"
                      // disabled
                      // style={{ fontSize: 12, width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>

      <RegistrationGroupForm
        openModal={openForm}
        closeModal={setOpenForm}
        dataRes={openForm == true ? dataRsv : []}
        dataGuest={openForm == true ? lead : null}
        dataRoom={openForm == true ? room : []}
        totalArticle={openForm == true ? price : []}
        dataCoa={openForm == true ? chartOfAcc : null}
        noShow={noShow ? noShow : false}
        is_refresh={is_refresh}
        is_checkin={true}
      />
    </>
  );
}
