// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { Table, Typography } from "antd";

// PARAMS
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

const DynamicTables = ({
  tableData,
  isLoading,
  getSelectedData,
  selectedRows,
  setSelectedRows,
}) => {
  const [data, setData] = useState([]);

  // Currency
  const currency = CurrencySymbols().code;

  useEffect(() => {
    let cleanUp = false;

    // console.log("TABLE DYNAMIC DATA ->> ", tableData);
    if (tableData && !cleanUp) {
      setData(tableData);
    }

    return () => {
      cleanUp = true;
    };
  }, [tableData]);

  const handleRowSelectionChange = (
    tableKey,
    selectedRowKeys,
    selectedRows
  ) => {
    // console.log("Selected RowKeys ", selectedRowKeys);
    // console.log("Selected Rows ", selectedRows);

    setSelectedRows((prev) => ({
      ...prev,
      [tableKey]: selectedRowKeys, // Simpan selected row keys per tabel
    }));

    getSelectedData((prev) => ({
      ...prev,
      [tableKey]: selectedRows,
    }));
  };

  // Table Columns
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Quantity`,
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price, record) => {
        return `${unit_price > 0 ? unit_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      render: (total_price, record) => {
        return `${total_price > 0 ? total_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      {data.map((table, index) => {
        const rowSelection = {
          selectedRowKeys: selectedRows[table.value] || [],
          onChange: (selectedRowKeys, selectedRows) => {
            handleRowSelectionChange(
              table.value,
              selectedRowKeys,
              selectedRows
            );
          },
        };

        return (
          <Table
            style={{ width: "100%", marginBottom: 25 }}
            key={table.value}
            rowKey={(record) => record.id}
            title={() => (
              <Typography.Title level={5}>{table.label}</Typography.Title>
            )}
            loading={isLoading}
            dataSource={table.details}
            columns={columns}
            size="small"
            rowSelection={rowSelection}
            pagination={false}
            // pagination={{
            //   defaultPageSize: 5,
            //   pageSizeOptions: [10, 20, 50, 100],
            //   showSizeChanger: true,
            //   showTotal: (total, range) =>
            //     `Showing ${range[0]}-${range[1]} of ${total} entries`,
            // }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
          />
        );
      })}
    </div>
  );
};

export default DynamicTables;
