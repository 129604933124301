// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Card,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  DatePicker,
  Typography,
  Select,
  Input,
  Layout,
  Form,
  InputNumber,
  Result,
  Alert,
  Checkbox,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
  WarningOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { RiTimerFlashFill } from "react-icons/ri";

// Import Page Components
import FolioTable from "../../../Components/Table/MasterFolio/GuestFolio/Table";

// Import Functions
import { CurrencySymbols } from "../../../Components/Reusable/Functions/Currency/Currency";
import { AccountingParams } from "../../../Components/Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../../Components/Reusable/Functions/Parameters/ParamsFO";
import ParametersBreakfast from "../../../Components/Reusable/Parameters/ParamsBF";
import { FilterEarlyArticle } from "../../../Components/Reusable/Functions/EarlyCO/EarlyCO";
import { BillArticleShow } from "../../../Components/Reusable/Functions/BillType/BillType";
import GroupingBill from "../../../Components/Reusable/Functions/GroupingArticle/GroupingBill";

// Master Components

// IMPORT GLOBAL
import {
  cardBody,
  cardHeader,
  mainBody,
  textForm,
} from "../../../Style/Global/Global";

// Import Notifications
import { masterIncomplete } from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import {
  failedCheckOut,
  failedFetch,
  successBill,
  successCheckOut,
} from "../../../Components/Reusable/Notification/Notification";

// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function EarlyCheckOutPage() {
  // CONTEXT
  const { getDeposit, getReservationLeader, getGuest } =
    useContext(MainContext);

  // USE LOCATION
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  // Params
  const currency = CurrencySymbols().code;
  const accounting = AccountingParams();
  const bill_type = FrontOfficeParams();
  const _bf = ParametersBreakfast().breakfast;

  // STATE MANAGEMENT
  // Data
  const [arrRes, setArrRes] = useState([]);
  const [dataRes, setDataRes] = useState(null);
  const [billReceiver, setBillReceiver] = useState(null);
  const [billMaster, setBillMaster] = useState({
    receiver: "",
    other: "",
  });
  // DATA
  const [billList, setBillList] = useState([]); // Bill C/O
  const [billMoved, setBillMoved] = useState([]); // SPLITTED/JOINED BILLS
  //
  const [guestList, setGuestList] = useState([]);
  const [subTotal, setSubTotal] = useState({
    total: 0,
    tax: 0,
    service: 0,
    grand: 0,
  });
  const [subTotalOther, setSubTotalOther] = useState({
    total: 0,
    tax: 0,
    service: 0,
    grand: 0,
  });
  // BOOLEAN
  const [isEarly, setIsEarly] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cardLoad, setCardLoad] = useState(true);
  const [loadBtn, setLoadBtn] = useState(false);
  // Is Exist
  const [checkbox, setCheckbox] = useState(0);
  const [isExist, setIsExist] = useState(true);
  const [isDisable, setIsDisable] = useState(true);

  // FORM
  const [form] = Form.useForm();
  const [formTwo] = Form.useForm();

  // Handle Early C/O
  const handleEarly = () => {
    const _path = "/front-office/expected-departure/early-check-out";

    console.log("Is Early: ", pathname.includes(_path));

    if (pathname.includes(_path)) {
      setIsEarly(true);
    } else {
      setIsEarly(false);
    }
  };

  // Fetch Data
  const fetchData = async (leaders) => {
    setCardLoad(true);
    setIsLoading(true);

    await getDeposit({
      type: "deposit-res-stay",
      res_id: leaders?.reservation_id
        ? leaders.reservation_id
        : dataRes?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Res Deposit: ", response);

        if (response?.data?.length > 0) {
          let _response = response.data;

          // setDepositAmount(
          //   _response[0]?.deposit_amount > 0 ? _response[0].deposit_amount : 0
          // );
        }
      },
      onReject: (error) => {
        console.log("ERROR: ", error);
        failedFetch(error);
      },
    });

    await getReservationLeader({
      leader: "get-leader",
      reservation_id: leaders?.reservation_id
        ? leaders.reservation_id
        : dataRes?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Leader Data --> ", response);

        // if (response?.data?.msg?.length > 0) {
        //   let _value = response.data.msg[0];

        //   setLeaders(_value);
        // }
      },
      onReject: (error) => {
        console.log(error);
      },
    });

    await axios
      .post(
        `${baseurl}/bill/master-folio-res`,
        {
          reservation_id: [leaders?.reservation_id || dataRes.reservation_id],
          source: [1],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("response modal receiver data: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleDataDetail(_res);
        } else {
          setBillList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setIsLoading(false);
        setCardLoad(false);
      });

    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setGuestList(_res);

          let filtered = _res.filter((e) => {
            if (
              leaders?.bill_receiver == e.id ||
              e.id == dataRes?.bill_receiver
            ) {
              return e;
            }
          });

          console.log("Filter Guest >>> ", filtered);

          setBillReceiver(filtered.length > 0 ? filtered[0] : null);

          form.setFieldValue("bill_receiver", filtered[0]?.full_name);
        } else {
          setBillReceiver(null);
          setGuestList([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (state.length > 0 && !cleanUp) {
      let leader = state.filter((x) => {
        return x.group == 1 || x.group == 0;
      });

      console.log("State: ", state);
      console.log("Lead: ", leader);

      setDataRes(leader[0]);

      fetchData(leader[0]);
      handleFormFields(leader[0]);

      setIsExist(true);
    } else {
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // HANDLE FINISH
  const handleFormFields = (values) => {
    form.setFieldsValue({
      reservation_id: values?.reservation_id,
      full_name: values?.guest_name,
      room_no: `${values?.room_category} - ${values?.room_number}`,
      arrival: moment(values?.arrival).format("DD-MM-YYYY"),
      departure: moment(values?.departure).format("DD-MM-YYYY"),
      today: moment().format("DD-MM-YYYY"),
      night: values?.night > 0 ? values.night : 0,
      new_night: moment().diff(moment(values?.arrival), "days"),
      payment_status:
        values?.payment_status == 3 ? "Cash Basis" : "Charge To Room",
    });

    setCardLoad(false);
  };

  // Handle Data Details
  const handleDataDetail = (value) => {
    const _arr = value;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    const _grouping = handleMerge(_billing);

    // Filter Early
    const _early_article = FilterEarlyArticle({
      article: _grouping.article,
      isAbf: accounting?.abf_plus_one_day,
      bf_id: bill_type.article_name,
    });

    const _elses = handleMerge(_grouping.article);

    // Grouping After Filter Data
    const _grouping_new = handleMerge(_early_article.article);

    // console.log("Breakfast: ", _bf);
    console.log("Grouping Res: ", _grouping);
    console.log("Grouping New: ", _grouping_new);
    console.log("Early Res: ", _early_article);

    setSubTotal({
      total: _grouping_new.total.unit_price,
      service: _grouping_new.total.service,
      tax: _grouping_new.total.tax,
      grand: _grouping_new.total.total_price,
    });

    setSubTotalOther({
      total: _elses.total.unit_price,
      service: _elses.total.service,
      tax: _elses.total.tax,
      grand: _elses.total.total_price,
    });

    setBillList(_grouping_new.article);
    setBillMoved(_elses.article);
  };

  // Handle Merge
  const handleMerge = (values) => {
    // Grouping Data
    const _grouping = BillArticleShow({
      article: values,
      bill_type: bill_type.bill_type,
    });

    return _grouping;
  };

  // HANDLE REFRESH
  const handleRefresh = async () => {
    setCheckbox(0);
    setIsDisable(true);

    await fetchData();
  };

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate("/front-office/expected-departure", { state: state });
  };

  // HANDLE SUCCESS
  const handleSuccess = (e) => {
    const _data = {
      detail: e,
      is_early: true,
      is_group: state.length > 1 ? true : false,
    };

    console.log("New State: ", _data, pathname);

    setTimeout(() => {
      navigate(`${pathname}/success`, {
        state: { data: _data },
      });
    }, 1000);
  };

  // HANDLE CHANGE
  const handleChoose = (val, type) => {
    const _check = val?.target?.checked;

    console.log("Checked: ", _check);
    console.log("Type: ", type);

    if (type == 1) {
      console.log("Bill New: ", billList);
    } else {
      console.log("Bill Old: ", billMoved);
    }

    if (_check) {
      setIsDisable(false);
      setCheckbox(type);
    } else {
      setIsDisable(true);
      setCheckbox(0);
    }
  };

  // ON FINISH
  const onFinish = (e) => {
    const _content = dataRes;

    console.log("DATA SUBMIT >>> ", _content);

    setLoadBtn(true);

    if (_content) {
      showModalConfirm(_content);
    } else {
      onFinishFailed();
    }
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = () => {
    const error = {
      errorFields: [
        {
          errors: ["Please, Choose some Bills!"],
        },
      ],
    };

    console.log("Data Incomplete >>> ", error);

    masterIncomplete(error);
    setLoadBtn(false);
  };

  // SHOW MODAL CHECK-OUT
  const showModalConfirm = () => {
    const _data = dataRes;
    var bulks = [];

    console.log("Data State => ", state);

    confirm({
      className: "checkout-confirm",
      title: `EARLY CHECK-OUT CONFIRMATION DATA`,
      icon: (
        <ExclamationCircleOutlined
          className="confirm-icon"
          style={{ color: "#1890ff" }}
        />
      ),
      content: (
        <>
          Are you sure all the Data with Reservation ID
          <b> {_data?.reservation_id} (s) </b> for Guest named{" "}
          <b> {billReceiver?.full_name || " - - - "} </b>
          is ready to change its C/O status to EARLY CHECK-OUT?
          <br />
          <br />
          <p>
            Once this operation is executed it can not be restore! Please,
            review again before submit!
          </p>
        </>
      ),
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      okButtonProps: {
        type: "primary",
        className: "submit-btn",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        setLoadBtn(false);
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _submitted = form.getFieldsValue();

    let _new_date = _submitted?.today
      ? moment(_submitted.today, "DD-MM-YYYY").format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    console.log("About to CO: ", _submitted);
    console.log("New Date: ", _new_date);

    await axios
      .post(
        `${baseurl}/guest/early-checkout`,
        {
          reservation_id: _submitted?.reservation_id,
          new_departure: _new_date,
          price_id: dataRes?.price_id,
          new_night: _submitted?.new_night,
          created_by: user_name,
          bill_type: checkbox,
          // bill_id:
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(async (response) => {
        console.log("Response Early C/O = ", response);

        if (response.status === 200) {
          successCheckOut(response, { method: 1 });

          handleSuccess(response.data?.detail);
        } else {
          failedCheckOut(response, { method: 1 });
        }
      })
      .catch((error) => {
        console.log("Failed to Check-Out = ", error);

        failedCheckOut(error, { method: 1 });
      })
      .finally(() => {
        setLoadBtn(false);
      });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="expected-arrival-main-layout">
          <Row className="row-title" justify="start" style={mainBody}>
            <RiTimerFlashFill
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Early Check Out Page`}
            </Typography>
          </Row>

          {isExist ? (
            <Layout>
              <Content>
                <Col span={24} className="master-folio-main-layout">
                  <Row
                    className="row-search-component"
                    justify="start"
                    style={cardBody}
                  >
                    <Space
                      align="end"
                      size={15}
                      className="header-space"
                      wrap={true}
                      style={{
                        padding: 30,
                        width: "100%",
                      }}
                    ></Space>
                  </Row>

                  <Row gutter={[30, 30]} className="table-row">
                    <Col
                      className="article-list-col"
                      xxl={{
                        span: 12,
                        order: 1,
                      }}
                      xl={{
                        span: 12,
                        order: 1,
                      }}
                      lg={{
                        span: 12,
                        order: 1,
                      }}
                      md={{
                        span: 24,
                        order: 1,
                      }}
                      sm={{
                        span: 24,
                        order: 1,
                      }}
                      xs={{
                        span: 24,
                        order: 1,
                      }}
                    >
                      <Row
                        justify="space-between"
                        className="row-btn"
                        style={{
                          padding: "15px 30px",
                          background: "#FFFFFF",
                          borderRadius: 5,
                          margin: "0px 0px 15px",
                          height: 75,
                        }}
                      >
                        <Col span={12} className="col-exp-btn">
                          <Typography
                            style={{
                              fontSize: 17,
                              fontWeight: 750,
                            }}
                          >
                            {`GUEST's BILL CHANGES of EARLY CHECK-OUT (${
                              state?.length == 1
                                ? "INDIVIDUAL C/O"
                                : "GROUP C/O"
                            })`}
                          </Typography>
                        </Col>

                        <Col span={12}>
                          <Row justify="end">
                            <Button
                              className="refresh-btn"
                              type="default"
                              icon={<SyncOutlined />}
                              onClick={handleRefresh}
                              style={{
                                margin: "0px 15px 0px",
                              }}
                            >
                              {`Refresh`}
                            </Button>

                            <Button
                              className="refresh-btn"
                              type="default"
                              icon={
                                <IoReturnUpBackOutline
                                  className="return-icons"
                                  style={{
                                    margin: "0px 5px 0px 0px",
                                  }}
                                />
                              }
                              onClick={handleReturn}
                            >
                              {`Cancel`}
                            </Button>
                          </Row>
                        </Col>
                      </Row>

                      <Divider
                        className="divider-form"
                        style={{
                          margin: "5px 0px",
                          backgroundColor: "#EBEDF3",
                        }}
                      />

                      <Row
                        className="row-table"
                        key={"rows-table"}
                        style={mainBody}
                      >
                        <Col
                          span={24}
                          className="table-col"
                          style={{
                            padding: "10px 15px 15px",
                          }}
                        >
                          <Row justify="space-between" className="row">
                            <Card
                              className="table-card"
                              bordered
                              loading={cardLoad}
                              style={{
                                width: "100%",
                                // marginTop: 16,
                              }}
                              title={
                                <Row
                                  justify="center"
                                  align="middle"
                                  className="title-row"
                                >
                                  <Col span={18} className="title-col">
                                    <Typography
                                      className="bill-txt"
                                      key={"txt-bill"}
                                      style={{ fontSize: 20, color: "#ffffff" }}
                                    >
                                      {`NEW TRANSACTION LIST AFTER EARLY C/O`}
                                    </Typography>
                                  </Col>

                                  <Col span={6} className="title-col">
                                    <Checkbox
                                      className="check-choose"
                                      onChange={(x) => handleChoose(x, 1)}
                                      checked={checkbox == 1 ? true : false}
                                      disabled={checkbox == 2 ? true : false}
                                      style={{
                                        color: "#000000",
                                        border: "1px solid #0000000",
                                        borderRadius: 5,
                                        background: "#FFFFFF",
                                        padding: 5,
                                      }}
                                    >
                                      {`SELECT NEW BILL`}
                                    </Checkbox>
                                  </Col>
                                </Row>
                              }
                              headStyle={{
                                textAlign: "center",
                                background: "#52c41a",
                              }}
                              bodyStyle={{
                                textAlign: "center",
                                display: "block",
                                background: "#f5f5f5",
                                padding: "0px 10px",
                              }}
                            >
                              <Form
                                className="form-bill"
                                key="form-bill"
                                name="form-bill"
                                form={form}
                                labelWrap={true}
                                labelAlign="left"
                                labelCol={{
                                  span: 8,
                                  offset: 2,
                                }}
                                wrapperCol={{
                                  span: 16,
                                }}
                              >
                                <Row
                                  align="middle"
                                  justify="center"
                                  style={{ margin: 0, height: 30 }}
                                >
                                  <Col span={12}>
                                    <Form.Item
                                      name="full_name"
                                      label="Guest Name"
                                    >
                                      <Input
                                        disabled
                                        placeholder="Guest Name"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      name="reservation_id"
                                      label="Reservation No."
                                    >
                                      <Input
                                        disabled
                                        placeholder="Reservation No."
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  align="middle"
                                  justify="start"
                                  style={{ margin: "5px 0px 0px", height: 30 }}
                                >
                                  <Col span={12}>
                                    <Form.Item
                                      name="bill_receiver"
                                      label="Bill Receiver"
                                    >
                                      <Input
                                        disabled
                                        placeholder="Bill Receiver"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      name="room_no"
                                      label="Room No."
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <Input
                                        readOnly={true}
                                        placeholder="Room No."
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  align="middle"
                                  justify="start"
                                  style={{ margin: "5px 0px 0px", height: 30 }}
                                >
                                  <Col span={12}>
                                    <Form.Item name="arrival" label="Arrival">
                                      <Input
                                        disabled
                                        placeholder="Arrival"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      name="payment_status"
                                      label="Payment Status"
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <Input
                                        readOnly={true}
                                        placeholder="Payment Status"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Row
                                  align="middle"
                                  justify="start"
                                  style={{ margin: "5px 0px 0px" }}
                                >
                                  <Col span={12}>
                                    <Form.Item
                                      name="departure"
                                      label="Old Departure"
                                      style={{
                                        width: "100%",
                                        margin: 0,
                                      }}
                                    >
                                      <Input
                                        readOnly={true}
                                        placeholder="Departure"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      name="today"
                                      label="New Departure"
                                      style={{
                                        width: "100%",
                                        margin: 0,
                                      }}
                                    >
                                      <Input
                                        readOnly={true}
                                        placeholder="Departure"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={12}>
                                    <Form.Item
                                      name="night"
                                      label="Old Night(s)"
                                      style={{
                                        width: "100%",
                                        margin: 0,
                                      }}
                                    >
                                      <Input
                                        disabled
                                        placeholder="Night(s)"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>

                                    <Form.Item
                                      name="new_night"
                                      label="New Night(s)"
                                      style={{
                                        width: "100%",
                                        margin: 0,
                                      }}
                                    >
                                      <Input
                                        disabled
                                        placeholder="Night(s)"
                                        bordered={false}
                                        style={textForm}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Form>
                            </Card>
                          </Row>

                          <Divider
                            className="divider-form"
                            orientation="left"
                            orientationMargin={15}
                            style={{
                              margin: "10px 5px 0px",
                              borderColor: "#EBEDF3",
                              color: "#8c8c8c",
                            }}
                          >
                            {`Transaction Details`}
                          </Divider>

                          <Row justify="space-between" className="row">
                            <FolioTable
                              dataTable={billList}
                              dataTotal={subTotal}
                              bill_type={bill_type.bill_type}
                              isLoading={isLoading}
                              currency={currency}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xxl={{
                        span: 12,
                        order: 2,
                      }}
                      xl={{
                        span: 12,
                        order: 2,
                      }}
                      lg={{
                        span: 12,
                        order: 2,
                      }}
                      md={{
                        span: 24,
                        order: 2,
                      }}
                      sm={{
                        span: 24,
                        order: 2,
                      }}
                      xs={{
                        span: 24,
                        order: 2,
                      }}
                      className="col-table"
                    >
                      <Row
                        justify="end"
                        className="row-btn"
                        align="middle"
                        style={{
                          padding: "15px 30px",
                          background: "#FFFFFF",
                          borderRadius: 5,
                          margin: "0px 0px 15px",
                          height: 75,
                        }}
                      >
                        <Col span={16}>
                          <Typography
                            style={{
                              fontSize: 17,
                              fontWeight: 750,
                            }}
                          >
                            {`PLEASE, SELECT ONE OF THESE BILLs TO SUBMIT FOR THIS GUEST!`}
                          </Typography>
                        </Col>

                        <Col span={8}>
                          <Row justify="end">
                            <Button
                              className="submit-btn"
                              type="primary"
                              loading={loadBtn}
                              disabled={isDisable}
                              onClick={onFinish}
                            >
                              {`Submit Changes`}
                            </Button>
                          </Row>
                        </Col>
                      </Row>

                      <Divider
                        className="divider-form"
                        style={{
                          margin: "5px 0px",
                          backgroundColor: "#EBEDF3",
                        }}
                      />

                      {billMoved.length > 0 ? (
                        <Row
                          className="row-table"
                          key={"rows-table"}
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: 5,
                            width: "100%",
                          }}
                        >
                          <Col
                            span={24}
                            className="table-col"
                            style={{
                              padding: "10px 15px 15px",
                            }}
                          >
                            <Row justify="space-between" className="row">
                              <Card
                                className="table-card"
                                bordered
                                loading={cardLoad}
                                style={{
                                  width: "100%",
                                  // marginTop: 16,
                                }}
                                title={
                                  <Row
                                    justify="center"
                                    align="middle"
                                    className="title-row"
                                  >
                                    <Col span={18} className="title-col">
                                      <Typography
                                        className="bill-txt"
                                        key={"txt-bill"}
                                        style={{
                                          fontSize: 20,
                                          color: "#FFFFFF",
                                        }}
                                      >
                                        {`OLD BILLS BEFORE EARLY C/O`}
                                      </Typography>
                                    </Col>

                                    <Col span={6} className="title-col">
                                      <Checkbox
                                        className="check-choose"
                                        onChange={(x) => handleChoose(x, 2)}
                                        checked={checkbox == 2 ? true : false}
                                        disabled={checkbox == 1 ? true : false}
                                        style={{
                                          color: "#000000",
                                          border: "1px solid #0000000",
                                          borderRadius: 5,
                                          background: "#FFFFFF",
                                          padding: 5,
                                        }}
                                      >
                                        {`SELECT OLD BILL`}
                                      </Checkbox>
                                    </Col>
                                  </Row>
                                }
                                headStyle={{
                                  textAlign: "center",
                                  background: "#f5222d",
                                }}
                                bodyStyle={{
                                  textAlign: "center",
                                  display: "block",
                                  background: "#f5f5f5",
                                  padding: "0px 10px",
                                }}
                              >
                                <Form
                                  className="form-bill"
                                  key="form-bill"
                                  name="form-bill"
                                  form={formTwo}
                                  labelWrap={true}
                                  labelAlign="left"
                                  labelCol={{
                                    span: 8,
                                    offset: 2,
                                  }}
                                  wrapperCol={{
                                    span: 16,
                                  }}
                                ></Form>
                              </Card>
                            </Row>

                            <Divider
                              className="divider-form"
                              orientation="left"
                              orientationMargin={15}
                              style={{
                                margin: "10px 5px",
                                borderColor: "#000000",
                                color: "#000000",
                              }}
                            >
                              {`Old Transaction List`}
                            </Divider>

                            <Row justify="space-between" className="row">
                              <FolioTable
                                dataTable={billMoved}
                                dataTotal={subTotalOther}
                                bill_type={bill_type.bill_type}
                                isLoading={isLoading}
                                currency={currency}
                              />
                            </Row>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
              </Content>
            </Layout>
          ) : (
            <>
              <Result
                status={"500"}
                title={"ERROR!"}
                subTitle={`Sorry, something went wrong!`}
                extra={[
                  <Row
                    className="extra-row"
                    key={"extra-row"}
                    justify="center"
                    gutter={30}
                  >
                    <Col className="return-col" span={24}>
                      <Space className="btn-space" size="large">
                        <Tooltip
                          className="return-tooltip"
                          title="Return to Expected Departure Page"
                          color="geekblue"
                          placement="right"
                        >
                          <Button
                            className="cancel-btn"
                            key="return-btn"
                            onClick={handleReturn}
                          >
                            {`Return`}
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>,
                ]}
                style={mainBody}
              >
                <>
                  <Space className="result-space" direction="vertical" size={5}>
                    <Paragraph>
                      <Text
                        strong
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {`The content you submitted may has the following error:`}
                      </Text>
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You are trying to reach this page without doing any process!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You're request is not valid!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`Please, check and modify the following information before resubmitting!`}
                    </Paragraph>
                  </Space>
                </>
              </Result>
            </>
          )}
        </Col>
      </Content>
    </Layout>
  );
}
