// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import { CloseCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Layout,
  Result,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// GLOBAL STYLES
import { mainBody } from "../../../Style/Global/Global";

export default function MasterFolioSuccessPage() {
  // data
  const [isExist, setIsExist] = useState(true);
  const [billMaster, setBillMaster] = useState(null);
  const [billReceiver, setBillReceiver] = useState([]);

  // Navigate
  const navigate = useNavigate();
  // State
  const { state } = useLocation();

  const { Paragraph, Text } = Typography;

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (state && !cleanUp) {
      console.log("Data Bill Submitted >>>", state);

      setIsExist(true);

      const _master =
        state?.bill_data?.length > 0 ? state?.bill_data[0]?.bill_master : null;
      setBillMaster(_master);
      setBillReceiver(state?.bill_receiver);
    } else {
      setIsExist(false);
      setBillMaster(null);
      setBillReceiver([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  //  HANDLE NAVIGATE
  const handleNavigate = () => {
    const path = "/front-office/front-office-cashier";

    const pays = {
      data: billReceiver,
      is_early: false,
      bill: [],
      bill_other: [],
      bill_receiver: null,
      bill_number: { receiver: billMaster },
    };

    //  console.log("New State: ", pays);

    navigate(path, {
      state: { billing: pays },
    });
  };

  //  HANDLE NAVIGATE
  const handleReturn = (value) => {
    navigate("/front-office/bill-outstanding-list/master-folio");
  };
  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="success-main-layout">
            <Row
              className="row-search-component"
              justify="start"
              gutter={[30, 30]}
            >
              <Col
                className="bill-col"
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Row className="row-test" style={mainBody}>
                  <Result
                    status={isExist ? "success" : "500"}
                    title={
                      isExist ? (
                        <Typography.Title
                          level={1}
                          style={{
                            color: "#52c41a",
                          }}
                        >
                          Bill Submitted Succesfully!
                        </Typography.Title>
                      ) : (
                        "ERROR!"
                      )
                    }
                    subTitle={
                      isExist ? (
                        <>
                          <Col
                            span={24}
                            style={{
                              fontWeight: 750,
                              fontSize: 14,
                              color: "#434343",
                            }}
                          >
                            <Typography>
                              {`Reservation No: ${
                                billReceiver[0]?.reservation_id
                                  ? billReceiver[0]?.reservation_id
                                  : " - - - - - "
                              }
                            `}
                            </Typography>
                          </Col>
                          {billMaster
                            ? "You can Redirect to Front Office Cashier for continue to payment or Return to Master FolioPage"
                            : ""}
                        </>
                      ) : (
                        `Sorry, something went wrong!`
                      )
                    }
                    extra={[
                      <Row
                        className="extra-row"
                        key={"extra-row"}
                        justify="center"
                        gutter={[0, 100]}
                      >
                        <Col className="return-col" span={24}>
                          <Space className="btn-space" size="large">
                            {isExist && billMaster ? (
                              <Tooltip
                                className="return-tooltip"
                                title="Redirect to FO Cashier Page"
                                color="geekblue"
                                placement="top"
                              >
                                <Button
                                  className="cancel-btn"
                                  key="return-btn"
                                  onClick={handleNavigate}
                                >
                                  {`Redirect to FO Cashier`}
                                </Button>
                              </Tooltip>
                            ) : null}

                            <Tooltip
                              className="return-tooltip"
                              title="Return to Master Folio Page"
                              color="geekblue"
                              placement="top"
                            >
                              <Button
                                className="cancel-btn"
                                key="return-btn"
                                onClick={handleReturn}
                              >
                                {`Return to Master Folio`}
                              </Button>
                            </Tooltip>
                          </Space>
                        </Col>
                      </Row>,
                    ]}
                    style={{
                      width: "100%",
                      padding: "100px 0px 15px 0px",
                      fontWeight: 750,
                      fontSize: 20,
                      color: "#262626",
                    }}
                  >
                    {isExist ? null : (
                      <>
                        <Space
                          className="result-space"
                          direction="vertical"
                          size={5}
                        >
                          <Paragraph>
                            <Text
                              strong
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {`The content you submitted may has the following error:`}
                            </Text>
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You are trying to reach success page without doing any process!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You're request is not valid!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`Please, check and modify the following information before resubmitting!`}
                          </Paragraph>
                        </Space>
                      </>
                    )}
                  </Result>
                </Row>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}
