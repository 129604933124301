// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// API
import { baseurl } from "../../../API/Config/Api";
// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Divider,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  Typography,
  Layout,
  Form,
  InputNumber,
  Result,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { ImExit } from "react-icons/im";
import { CloseCircleOutlined, PrinterOutlined } from "@ant-design/icons";

// Import Page Components
import BillReceipt from "../../../Components/Forms/Bill/BillReceipt";
import ModalCheckOutForm from "../../../Components/Forms/CheckOut/Form";
import MasterBill from "../../../Components/Forms/Bill/MasterBill";
import JoinBillTrx from "../../../Components/Forms/PreBill/JoinBillTrx";
import PreBillForm from "../../../Components/Forms/PreBill/PreBill";

// Master Components
import PreBillFormTrx from "../../../Components/Forms/PreBill/PreBillTrx";

// IMPORT GLOBAL
import { cardBody, cardHeader, mainBody } from "../../../Style/Global/Global";

// Import Notifications
import { masterIncomplete } from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import {
  failedBill,
  failedCheckOut,
  successBill,
  successCheckOut,
} from "../../../Components/Reusable/Notification/Notification";

// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function EarlyCheckOutSuccessPage() {
  // USE LOCATION & NAVIGATE
  const { state, pathname } = useLocation();

  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [leaders, setLeaders] = useState([]);
  const [bill, setBill] = useState([]);
  // MODALS
  const [isGroup, setIsGroup] = useState(false);
  const [master, setMaster] = useState(false);
  const [tyLetter, setTyLetter] = useState(false);
  const [payments, setPayments] = useState(state?.payment);
  // IS SUCCESS Check-Out
  const [isExist, setIsExist] = useState(true);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (state.data && !cleanUp) {
      console.log("Data CO >>>", state);

      setIsExist(true);

      setData(state.data.detail[0]);
      setBill(state.data.detail);
      handleLeader(state.data);
    } else {
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // HANDLE LEADERS
  const handleLeader = (val) => {
    const _arr = val;

    console.log("Handle Leaders: ", _arr);

    if (_arr.is_group) {
      setIsGroup(true);

      let _lead = _arr.detail.filter((lead) => {
        if (lead.group == 1) {
          return lead;
        }
      });

      if (_lead?.length == 0) {
        setLeaders(_arr.detail[0]);
        console.log("Leaders >>>", _lead);
      } else {
        console.log("Leaders Masters >>>", _lead);
        setLeaders(_lead[0]);
      }
    } else {
      setIsGroup(false);

      let _leader = _arr.detail[0];

      console.log("Leaders >>>", _leader);
      setLeaders(_leader);
    }
  };

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate("/front-office/expected-departure");
  };

  // Handle Pays
  const handleMove = () => {
    const path = "/front-office/expected-departure/guest-folio";

    navigate(`${path}`, { state: bill });
  };

  //   console.log("dataRes >>>", state);
  //   console.log("dataLeads >>>", leaders);

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="expected-arrival-main-layout">
            <Row
              className="row-search-component"
              justify="start"
              gutter={[30, 30]}
              // style={mainBody}
            >
              <Col
                className="bill-col"
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Row className="row-test" style={mainBody}>
                  <Result
                    status={isExist ? "success" : "500"}
                    title={
                      isExist
                        ? `Successfully Change/Update Status Guest to Early Check-Out!`
                        : "ERROR!"
                    }
                    subTitle={
                      isExist ? (
                        <>
                          {`Reservation No: ${
                            data?.reservation_id
                              ? data?.reservation_id
                              : " - - - - - "
                          }`}
                          <br />
                          {`Type: ${
                            isGroup
                              ? ` Group Reservations with ${
                                  state.data.detail.length || 0
                                } Members`
                              : ` Individual Reservations`
                          }`}
                          <br />
                          {`Guest Name: ${
                            data?.guest_name || leaders.guest_name
                          }`}
                        </>
                      ) : (
                        `Sorry, something went wrong!`
                      )
                    }
                    extra={[
                      <Row
                        className="extra-row"
                        key={"extra-row"}
                        justify="center"
                        gutter={[0, 100]}
                      >
                        <Col hidden={!isExist} className="print-col" span={24}>
                          <Space
                            className="success-space"
                            wrap={true}
                            size="middle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              className="co-btn"
                              key="co-btn"
                              type="danger"
                              icon={
                                <ImExit
                                  key={"id-keys"}
                                  style={{
                                    margin: "0px 10px 0px 0px",
                                  }}
                                />
                              }
                              onClick={() => {
                                handleMove();
                              }}
                            >
                              {`Proceed to Check-Out`}
                            </Button>
                          </Space>
                        </Col>

                        <Col className="return-col" span={24}>
                          <Space className="btn-space" size="large">
                            <Tooltip
                              className="return-tooltip"
                              title="Return to Expected Departure Page"
                              color="geekblue"
                              placement="right"
                            >
                              <Button
                                className="cancel-btn"
                                key="return-btn"
                                onClick={handleReturn}
                              >
                                {`Return`}
                              </Button>
                            </Tooltip>
                          </Space>
                        </Col>
                      </Row>,
                    ]}
                    style={{
                      width: "100%",
                      padding: "100px 0px 15px 0px",
                    }}
                  >
                    {isExist ? null : (
                      <>
                        <Space
                          className="result-space"
                          direction="vertical"
                          size={5}
                        >
                          <Paragraph>
                            <Text
                              strong
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {`The content you submitted may has the following error:`}
                            </Text>
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You are trying to reach success page without doing any process!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You're request is not valid!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`Please, check and modify the following information before resubmitting!`}
                          </Paragraph>
                        </Space>
                      </>
                    )}
                  </Result>
                </Row>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}
