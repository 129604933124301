// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// PARAMETERS
import ParametersFrontOffice from "../../../Reusable/Parameters/ParamsFO";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Select,
  Tooltip,
  Typography,
  Tag,
  Modal,
  Alert,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  CloseCircleOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { ImEnter } from "react-icons/im";

// Import Functions
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";

// Import Page Components
import ModalPermission from "../../../Modals/Permissions/Modal";
import ModalArrivalGroup from "../../../Modals/Arrival/ModalGroup";
import ModalArrival from "../../../Modals/Arrival/Modal";
import ArrivalForm from "../../../Forms/Arrival/Form";
import ArrivalPopover from "../../../Popover/Arrival/Popover";
import MasterSegment from "../../../Reusable/Master/Segment/Segment";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// CODE
export default function ExpectedArrivalTable(props) {
  // PROPS
  const { is_search, searchKey, searchDate } = props;

  // DATE NOW
  const timeNow = moment().hour();

  // Params
  const params = ParametersFrontOffice().params;
  const param_fo = FrontOfficeParams();

  // CONTEXT
  const { getReservation } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  // Group Data
  const [selectedData, setSelectedData] = useState([]);
  const [modalData, setModalData] = useState([]);
  // CHECKIN TIME
  const [disable, setDisable] = useState(true);
  // Group State
  const [isGroup, setIsGroup] = useState(false);
  // Selection Row
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  // const [dataLeader, setDataLeader] = useState([]);
  // BOOLEAN
  // Modal State
  const [isExpired, setIsExpired] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [modalAuth, setModalAuth] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  // Refresh
  const [refresh, setRefresh] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [permitLoad, setPermitLoad] = useState(false);

  // Columns
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      width: 135,
      hidden: isGroup ? true : false,
      render: (_, record) => {
        const _arr = moment(record?.arrival).format("YYYY-MM-DD");

        const _now = moment().format("YYYY-MM-DD");
        const _before = moment().subtract(2, "days").format("YYYY-MM-DD");

        const _date = moment(_arr).isBetween(_before, _now, undefined, "[]");

        // console.log("Arrival: ", _arr);
        // console.log("Date Now: ", _now);
        // console.log("Before 2 Days: ", _before);
        // console.log("Date Arrival IS: ", _date);

        return (
          <Row
            className="action-check-in-table-btn"
            justify="center"
            style={{ width: "100%" }}
          >
            <Col span={24} className="col-menu-popover-btn">
              {/* <Tooltip title="Check-In" className="checkin-tooltip">
              <ArrivalPopover details={record} is_refresh={setRefresh} />
            </Tooltip> */}
              <Button
                type="primary"
                className="submit-btn"
                key={"check-in-btn"}
                // disabled={!_date}
                icon={
                  <ImEnter
                    className="check-in-btn"
                    style={{
                      margin: "0px 10px 0px 0px",
                    }}
                  />
                }
                style={{ backgroundColor: "#1BC5BD", borderColor: "#1BC5BD" }}
                onClick={() => {
                  console.log("Check In Data --> ", record);

                  setSelectedData(record);
                  // setOpen(true);
                  if (_date == true) {
                    handleOpen(record);
                  } else {
                    setIsExpired(true);
                  }
                }}
              >
                {`Check-In`}
              </Button>
            </Col>
          </Row>
        );
      },
    },
    {
      title: "Reservation No",
      dataIndex: "reservation_id",
      // fixed: "left",
      width: 175,
      key: "reservation_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // fixed: "left",
      width: 200,
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (arrival, record) => {
        return moment(arrival).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.arrival) - moment(b.arrival),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a.departure) - moment(b.departure),
    },
    {
      title: "Breakfast",
      dataIndex: "is_abf",
      key: "is_abf",
      align: "center",
      render: (abf) => {
        return (
          <Tag key={"abf-keytag"} color={abf === true ? `green` : `geekblue`}>
            {abf === true ? `RB` : `RO`}
          </Tag>
        );
      },
      // width: 150,
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      // width: 150,
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            color={clr}
            style={{
              fontSize: 13,
              fontWeight: 500,
              borderColor: "transparent",
            }}
          >
            {gst}
          </Tag>
        );
      },
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.roomno - b.roomno,
    },
    {
      title: "Segment",
      dataIndex: "segment",
      key: "segment",
    },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      hidden: isGroup ? false : true,
      render: (group, record) => {
        let grp = "";
        let clr = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            clr = "#52c41a";
            break;

          case 1:
            grp = "Group Leader";
            clr = "#1677ff";
            break;

          case 2:
            grp = "Group Member";
            clr = "#8c8c8c";
            break;

          default:
            break;
        }

        return (
          <Tag key={"key-tag"} color={clr}>
            {grp}
          </Tag>
        );
      },
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
  ].filter((item) => !item.hidden);

  // USE EFFECT FETCH
  useEffect(() => {
    let cleanUp = false;

    if (params) {
      console.log("Params!", params);
      console.log("Params!", param_fo);

      fetchData();
    }

    if (refresh == true && !cleanUp) {
      setRefresh(false);
      setIsSuccess(false);
      setSelectedData([]);
      setModalData([]);
      setSelectedKeys([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh, params]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, searchDate]);

  // Fetch Data
  const fetchData = async () => {
    await getReservation({
      reservation: "reservation",
      type: "stay",
      onAwait: () => {
        "on Await";
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Expected Arrival Res => ", response);
        // console.log("Parameters => ", params);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filter = _res.filter((items) => {
            return items.group == 0;
          });

          setBulks(_res);
          handleGroup(isGroup, _res);
          // setData(filter);
        } else {
          setData([]);
          setBulks([]);
        }

        if (params?.checkin_time) {
          handleDisable(params);
        } else {
          setDisable(true);
        }

        setLoading(false);
      },
      onReject: (error) => {
        setLoading(false);
        failedFetch(error);
        console.log("ERROR >>>> ", error);
      },
    });
  };

  // CHECK COMPLETENESS
  const checkAvailability = (param) => {
    return !Object.keys(
      (({
        group_name,
        guest_category,
        arrival_flight,
        eta,
        departure_flight,
        etd,
        is_dummy,
        is_group,
        //tak tambah iki sitik kelewat ning ngisor
        letter,
        sales,
        created_at,
        created_by,
        nationality, ///???
        // reservation_id, ///???
        memo_room,
        room_type,
        arrangement,
        room_rate_id,
        deposit,
        reason,
        // room_rate_id,
        // res_created_by,
        // res_created,
        sub_segment_id,

        is_cm,
        is_deleted,
        updated_by,
        updated_date,
        rate_code,

        step_by,
        step_date,
        is_checkin,
        is_step,

        checkin_by,
        checkin_date,
        deleted_by,
        deleted_date,

        is_nonstay,
        nonstay_by,
        nonstay_count,
        nonstay_date,

        is_audit,
        audit_at,
        audit_by,

        re_audit_at,
        is_re_audit,
        re_audit_by,

        ...o
      }) => o)(param)
    ).some((key) => {
      // console.log(
      //   "FILTERED ==> ",
      //   param[key] === "" || param[key] === null || param[key] === undefined
      // );

      //  console.log("KEY: ", [key] + ": " + param[key]);

      return (
        param[key] === "" || param[key] === null || param[key] === undefined
      );
    });
  };

  // HANDLE DISABLED
  const handleDisable = (param) => {
    const _min = moment(param?.checkin_time).hour();
    // console.log("Parameters FO: ", param);
    // console.log("Minimum Check-In Time: ", _min);
    // console.log("Time Now: ", timeNow);

    if (timeNow >= _min) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  // HANDLE OPEN
  const handleOpen = (rec) => {
    const _record = rec;
    setIsSuccess(false);

    if (disable) {
      setModalAuth(true);
    } else {
      setOpen(true);
    }
  };

  // HANDLE CHECK-IN GROUP
  const handleGroupCheckIn = async () => {
    console.log("About Check-In => ", selectedData);

    const _arr = moment(selectedData[0]?.arrival).format("YYYY-MM-DD");

    const _now = moment().format("YYYY-MM-DD");
    const _before = moment().subtract(2, "days").format("YYYY-MM-DD");

    const _date = moment(_arr).isBetween(_before, _now, undefined, "[]");
    setIsSuccess(false);

    // navigate(`${pathname}/check-out`, { state: selectedData });

    if (disable) {
      setModalAuth(true);
    } else {
      // setOpen(true);
      if (_date == true) {
        setOpenGroup(true);
      } else {
        setIsExpired(true);
      }
    }
  };

  // HANDLE EARLY CHECK IN
  const handleEarlyCheckIn = async (value) => {
    const _record = value;
    console.log("Value Early: ", _record);
    console.log("Group: ", isGroup);

    if (
      _record?.auth_token &&
      _record?.auth_roles == "ROLE_SUPERVISOR" &&
      _record?.auth_dept.toLowerCase().includes("front")
    ) {
      switch (isGroup) {
        case true:
          setModalAuth(false);
          setOpenGroup(true);
          break;

        case false:
          setModalAuth(false);
          setOpen(true);
          break;

        default:
          break;
      }
    } else {
      setModalAuth(true);
    }
  };

  // HANDLE REFRESH
  const handleRefresh = (e) => {
    console.log("refresh is Hit!", e, modalAuth);

    if (e === true || e === false) {
      setRefresh(e);
    }

    if (modalAuth === true) {
      setModalAuth(false);
    }

    fetchData();
    setSelectedKeys([]);

    setModalAuth(false);
    setIsSuccess(true);
  };

  // HANDLE PRINT
  const handlePrint = async () => {
    setIsPrint(true);
  };

  // HANDLE GROUP
  const handleGroup = async (e, arr) => {
    const _ind = e;
    const _arr = arr?.length > 0 ? arr : bulks;
    setLoading(true);

    console.log("Group type: ", _ind);

    if (_ind === true) {
      let _data = _arr.filter((val) => {
        return val.group > 0;
      });

      console.log("This is supposed to be called! ", _data);

      setData(_data);
      setIsGroup(true);
      setPageSize(10);
    } else {
      let _data = _arr.filter((val) => {
        return val.group == 0;
      });

      console.log(_data);

      setData(_data);
      setIsGroup(false);
      setPageSize(5);
    }

    setTimeout(() => {
      setLoading(false);
    }, 750);
  };

  // ON SELECT CHANGE
  const onSelectChange = (newSelectedRowKeys, recordAll, type, index) => {
    const _allKeys = recordAll;
    const _allBulks = data;
    const _result = [];

    let _leader = [];
    let _members = [];

    if (_allBulks.length > 0) {
      // All Selected Keys
      let keys = _allBulks
        .filter((i) => {
          return i.reservation_id == _allKeys[0].reservation_id;
        })
        .map((i) => i?.id);

      // All Selected Data
      let _selected = _allBulks.filter((i) => {
        return i?.reservation_id === recordAll[0]?.reservation_id;
      });

      // FIND LEADS
      const _leads = _selected.filter((item) => {
        if (item.group == 1) {
          _leader.push(item);
        } else {
          _members.push(item);
        }
      });

      // Total Category Room
      // Merge Room
      const merged = _selected.reduce((acc, init) => {
        let key = init.room_category_id;

        acc[key] = acc[key] || [];
        acc[key].push(init.room_number);

        return acc;
      }, {});

      if (merged) {
        for (var key in merged) {
          // console.log(key);
          // console.log(merged);

          _result.push({
            room_category: _selected.find((items) => {
              return items?.room_category_id == key;
            })?.room_category,
            room_number: merged[key],
            room_qty: merged[key]?.length,
            reservation_id: _selected[0].reservation_id,
            room_category_id: parseInt(key),
          });
        }
      }

      // console.log("All Keys = ", keys);
      // console.log("All Selected = ", _selected);
      // console.log("Hasil Merged = ", _merged);
      // console.log("Room Category Total = ", merged);
      // console.log("Data Leaders = ", _leader);
      // console.log("Data Members = ", _members);
      // console.log("Hasil Results = ", _result);

      setSelectedKeys(keys);
      setSelectedData(_selected);
      // setDataLeader(_leader);

      setModalData(_result);
    }
  };

  // HANDLE ROW SELECTIONS
  const rowSelection = {
    columnTitle: "Check",
    columnWidth: 75,
    selectedRowKeys: selectedKeys,
    onSelect: (record, _selected, selectedRows, nativeEvent) => {
      // console.log("On Selects Records = ", record);
      console.log("On Selects Index = ", _selected);
      // console.log("On Selects Rows = ", selectedRows);
      // console.log("On Selects native events = ", nativeEvent);

      const _type = _selected;

      if (_type == false) {
        // console.log("This is Hit!", _type);
        setSelectedKeys([]);
        setSelectedData([]);
      } else {
        onSelectChange(record, selectedRows);
      }
    },
    // onChange: onSelectChange,
    getCheckboxProps: (record) => {
      var _able = disable;

      // console.log(
      //   "Disables Between date: ",
      //   _before,
      //   " < ",
      //   _arr,
      //   " < ",
      //   _now,
      //   " = ",
      //   _date
      // );
      // console.log("Disables date: ", _date);
      // console.log("Disables date: ", _date);

      return {
        disabled:
          selectedData?.length > 0
            ? record?.reservation_id !== selectedData[0]?.reservation_id
            : false,
      };
    },
    selections: [Table.SELECTION_NONE, Table.SELECTION_NONE],
  };

  // CHANGE PAGE SIZE
  const changePageSize = (val, size) => {
    // console.log(val);
    // console.log(size);
    // console.log(pageSize);
    setPageSize(size);
  };

  // GET SEGMENT
  const getSegment = async (value, key) => {
    console.log(value, key);
  };

  // HANDLE SEARCH
  const handleSearch = async () => {
    setLoading(true);

    const queryName = searchKey ? searchKey.toLowerCase() : null;
    const queryDate = searchDate
      ? moment(searchDate).format("YYYY-MM-DD")
      : null;

    console.log("Type = ", queryName);
    console.log("Tanggal = ", queryDate, " + ", searchDate);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item)
        const name = item?.guest_name ? item?.guest_name.toLowerCase() : "";

        return name.indexOf(queryName) !== -1;
      });

      // console.log("FILTERED => ", updatedList);
      if (queryDate != "Invalid date" && queryDate != null) {
        handleArrival(updatedList?.length > 0 ? updatedList : bulks, queryDate);
      } else {
        setData(updatedList);
      }
    } else {
      setData([]);
    }

    setLoading(false);
  };

  // FILTER BY DATE
  const handleArrival = (value, date) => {
    let _newData = value.filter((items) => {
      const dep = moment(items?.arrival).format("YYYY-MM-DD");

      if (isGroup) {
        return moment(dep).isSame(date) && items.group > 0;
      } else {
        return moment(dep).isSame(date) && items.group == 0;
      }
    });

    console.log("Date: ", _newData);

    setData(_newData);
    setLoading(false);
  };

  // CLOSE MODAL
  const handleClose = () => {
    // setDataCheck(null);

    setOpen(false);
    setOpenGroup(false);
    setPermitLoad(true);

    // setDataLeader([])
  };

  // CLOSE MODAL INFO
  const handleCancel = () => {
    setIsExpired(false);
  };

  return (
    <Row
      className="expected-arrival-main-table"
      justify="center"
      style={mainBody}
    >
      {/* <Radio.Group
                onChange={({ target: { value } }) => {
                    setSelectionType(value);
                }}
                value={selectionType}
            >
                <Radio value="checkbox">Checkbox</Radio>
                <Radio value="radio">radio</Radio>
            </Radio.Group> */}

      <Col span={24} style={{ padding: "30px" }}>
        <Row justify="end" align="top" className="btn-row" gutter={[0, 30]}>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            className="daterange-col"
          >
            <Space className="select-space" direction="vertical">
              <Button
                type="primary"
                icon={<PrinterOutlined />}
                onClick={handlePrint}
                disabled={loading}
                className="print-btn"
              >
                {`Print & Download List`}
              </Button>

              <Typography style={{ fontWeight: 750 }}>
                {`CHOOSE CHECK-IN TYPE:`}
              </Typography>

              <Select
                key="type-select"
                className="type-select"
                allowClear
                showSearch
                defaultValue={false}
                onChange={(e) => {
                  // console.log(e);

                  handleGroup(e);
                }}
                options={[
                  {
                    label: "Individual",
                    value: false,
                  },
                  {
                    label: "Group",
                    value: true,
                  },
                ]}
                style={{
                  minWidth: 205,
                }}
              />
            </Space>
          </Col>

          <Col
            className="refresh-col"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            xxl={12}
            // ={12}
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Space className="space" align="start" direction="horizontal">
              <Button
                className="submit-btn"
                type="primary"
                key={"check-in-btn"}
                icon={
                  <ImEnter
                    className="co-icon"
                    style={{
                      margin: "3px 10px 0px 0px",
                    }}
                  />
                }
                hidden={!isGroup}
                disabled={selectedData.length > 0 ? false : true}
                style={{
                  width: "100%",
                }}
                onClick={handleGroupCheckIn}
              >
                {`Check-In Group`}
              </Button>

              <Button
                className="refresh-btn"
                type="default"
                icon={<SyncOutlined />}
                onClick={handleRefresh}
              >
                {`Refresh`}
              </Button>
            </Space>
          </Col>
        </Row>

        <Col>
          <Row span={24} className="table-row">
            <Table
              className="arrival-table"
              key="arrival-table"
              name="arrival-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              rowSelection={
                isGroup
                  ? {
                      type: "checkbox",
                      ...rowSelection,
                    }
                  : false
              }
              bordered
              pagination={{
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                onChange: changePageSize,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              rowClassName={(record, index) => {
                const _res = checkAvailability(record);

                // console.log("Result checks: ", _res);

                return _res ? "completed" : "incompleted";
              }}
              scroll={{
                x: 2000,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              style={{
                margin: "30px 0px 15px",
              }}
            />
          </Row>
        </Col>
      </Col>

      <ModalArrival
        openModal={open}
        closeModal={handleClose}
        dataGuest={open == true ? selectedData : null}
        is_refresh={handleRefresh}
      />

      <ModalArrivalGroup
        openModal={openGroup}
        closeModal={handleClose}
        dataRes={openGroup == true ? selectedData : []}
        // dataLead={openGroup == true ? dataLeader : []}
        dataModal={openGroup == true ? modalData : []}
        is_refresh={handleRefresh}
      />

      <ArrivalForm
        dataArrival={isPrint == true ? data : []}
        modalClose={setIsPrint}
        modalOpen={isPrint}
      />

      <ModalPermission
        openModal={modalAuth}
        closeModal={setModalAuth}
        is_success={isSuccess}
        is_refresh={setRefresh}
        dataSubmit={modalAuth ? selectedData : []}
        roomStatus={3}
        is_deleted={false}
        is_authorized={handleEarlyCheckIn}
        is_loading={permitLoad}
        is_loadingFalse={setPermitLoad}
      />

      <Modal
        className="expired-modal"
        key="expired-modal"
        centered
        closable
        open={isExpired}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        title={
          <>
            <Row>
              <CloseCircleOutlined
                className="icon-exclam"
                style={{ color: "#3699FF", fontSize: 24 }}
              />
              <Typography style={{ marginLeft: 15, fontSize: 24 }}>
                {`Check-In Date's Less Than Expected Arrival's Day(s)`}
              </Typography>
            </Row>
          </>
        }
        width={800}
        bodyStyle={{
          padding: "15px 36px 36px",
        }}
      >
        <Alert
          className="arrival-alert"
          closable={false}
          message="This Reservations cannot be Checked-In because the Arrival's Date is Less or More than Expected Date!"
        />

        <br />

        <p>
          This Reservations with Reservation ID's :{" "}
          <strong>
            {selectedData.length > 0
              ? selectedData[0]?.reservation_id
              : selectedData?.reservation_id}
          </strong>{" "}
          and Guest name's{" "}
          <strong>
            {selectedData.length > 0
              ? selectedData[0]?.guest_name
              : selectedData?.guest_name}
          </strong>{" "}
          is supposed to be Checked-In on{" "}
          <strong>
            {selectedData.length > 0
              ? moment(selectedData[0]?.arrival).format("DD-MM-YYYY")
              : moment(selectedData?.arrival).format("dddd, DD-MM-YYYY")}
          </strong>{" "}
          and NOT ON TODAY Expected Arrival's List! You accidentally choose a
          wrong Reservations or Trying to Check-In an Expire Reservations!
          Please, check the data again!
        </p>

        <br />

        <p>{`Please, Create a new RESERVATIONS!`}</p>
      </Modal>
    </Row>
  );
}
