// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// API
// AUTH

// Import Ant Design Components
import {
  Divider,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  Typography,
  Layout,
  Form,
  InputNumber,
  Result,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { CloseCircleOutlined, PrinterOutlined } from "@ant-design/icons";

// Import Page Components
import Invoice from "../../../Components/Forms/Invoice/Invoice";

// Master Components

// IMPORT GLOBAL
import { mainBody } from "../../../Style/Global/Global";

// Import Notifications

// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function CashierSuccessPage() {
  // PROPS
  const { state, pathname } = useLocation();

  // USE LOCATION & NAVIGATE
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState("");
  const [invo, setInvo] = useState("");
  const [payments, setPayments] = useState(null);
  const [list, setList] = useState([]);
  // MODALS
  const [bill, setBill] = useState(false);
  // IS SUCCESS Check-Out
  const [isExist, setIsExist] = useState(true);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (state && !cleanUp) {
      console.log("Data CO >>>", state);

      setIsExist(true);
      handleBill(state);
    } else {
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate("/front-office/front-office-cashier");
  };

  const handleReturnDept = (e) => {
    navigate("/front-office/expected-departure");
  };

  // HANDLE LEADERS
  const handleBill = (val) => {
    const _data = val;
    const _invoice = _data.response?.length > 0 ? _data.response : [];
    const _newHeader = {
      ..._data?.bill_header,
      invoice_id: _invoice,
    };

    setData(_newHeader);
    setPayments(_data?.data);
    setList(_data?.list?.length > 0 ? _data.list : []);

    if (_invoice.length > 0) {
      const _res = _invoice.join(", ");

      setInvo(_res);
    } else {
      setInvo(null);
    }
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="success-main-layout">
            <Row
              className="row-search-component"
              justify="start"
              gutter={[30, 30]}
            >
              <Col
                className="bill-col"
                xxl={24}
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
              >
                <Row className="row-test" style={mainBody}>
                  <Result
                    status={isExist ? "success" : "500"}
                    title={
                      isExist ? (
                        <Typography.Title
                          level={1}
                          style={{
                            color: "#52c41a",
                          }}
                        >
                          Payment Success!
                        </Typography.Title>
                      ) : (
                        "ERROR!"
                      )
                    }
                    subTitle={
                      isExist ? (
                        <Col
                          span={24}
                          style={{
                            fontWeight: 750,
                            fontSize: 14,
                            color: "#434343",
                          }}
                        >
                          <Typography>
                            {`Bill No: ${
                              data?.bill_alias
                                ? data.bill_alias
                                : data?.bill_master || " - - - - - "
                            }
                            `}
                          </Typography>

                          <Typography>
                            {`Invoice No: ${
                              invo ? invo : state?.response || " - - - - - "
                            }
                            `}
                          </Typography>
                        </Col>
                      ) : (
                        `Sorry, something went wrong!`
                      )
                    }
                    extra={[
                      <Row
                        className="extra-row"
                        key={"extra-row"}
                        justify="center"
                        gutter={[0, 100]}
                      >
                        <Col hidden={!isExist} className="print-col" span={24}>
                          <Space
                            className="success-space"
                            wrap={true}
                            size="middle"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              type="primary"
                              className="print-btn"
                              key="print-btn"
                              icon={<PrinterOutlined />}
                              onClick={() => {
                                console.log("State: ", state);
                                setBill(true);
                              }}
                            >
                              {`Print Invoice`}
                            </Button>
                          </Space>
                        </Col>

                        <Col className="return-col" span={24}>
                          <Space className="btn-space" size="large">
                            <Tooltip
                              className="return-tooltip"
                              title="Return to FO - Cashier Page"
                              color="geekblue"
                              placement="right"
                            >
                              <Button
                                className="cancel-btn"
                                key="return-btn"
                                onClick={handleReturn}
                              >
                                {`Return to FO - Cashier`}
                              </Button>
                            </Tooltip>

                            <Tooltip
                              className="return-tooltip"
                              title="Return to Expected Departure Page"
                              color="blue"
                              placement="right"
                            >
                              <Button
                                className="cancel-btn"
                                key="return-btn"
                                onClick={handleReturnDept}
                              >
                                {`Return to Expected Departure`}
                              </Button>
                            </Tooltip>
                          </Space>
                        </Col>
                      </Row>,
                    ]}
                    style={{
                      width: "100%",
                      padding: "100px 0px 15px 0px",
                      fontWeight: 750,
                      fontSize: 20,
                      color: "#262626",
                    }}
                  >
                    {isExist ? null : (
                      <>
                        <Space
                          className="result-space"
                          direction="vertical"
                          size={5}
                        >
                          <Paragraph>
                            <Text
                              strong
                              style={{
                                fontSize: 16,
                              }}
                            >
                              {`The content you submitted may has the following error:`}
                            </Text>
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You are trying to reach success page without doing any process!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`You're request is not valid!`}
                          </Paragraph>

                          <Paragraph>
                            <CloseCircleOutlined
                              className="site-result-error-icon"
                              style={{
                                color: "#E73827",
                                margin: "0px 10px 0px 0px",
                              }}
                            />
                            {`Please, check and modify the following information before resubmitting!`}
                          </Paragraph>
                        </Space>
                      </>
                    )}
                  </Result>
                </Row>
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>

      <Invoice
        is_open={bill}
        is_close={setBill}
        data_guest={bill ? data : []}
        payment={bill ? payments : null}
        data_table={bill ? list : []}
        invoice={bill ? invo : null}
        is_preview={false}
      />
    </>
  );
}
