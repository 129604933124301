// Import Axios
import axios from "axios";
import { caller } from "../../config";

// ACCESS TOKEN
const access_token = sessionStorage.getItem("auth-token");

// CONFIG BEARERS
const config = { Authorization: `Bearer ${access_token ? access_token : ""}` };

// GET URL
const configurl = caller?.urlAddress;

console.log("Configurl: ", configurl);

// BASE API
const baseAPI = axios.create({
  baseURL:
    // baseURL ||
    // 'localhost:3005'
    process.env.REACT_APP_BASEURL ||
    // process.env.REACT_APP_BASE_URL_LAN ||
    // process.env.REACT_APP_BASEURL_DEV,
    // process.env.REACT_APP_BASE_URL_DEV,
    // process.env.REACT_APP_BASE_URL_DEVELOPMENT,
    // process.env.REACT_APP_BASE_URL_DEVELOPMENT_SECOND,
    // process.env.REACT_APP_BASE_URL_DEVELOPMENT_THIRD,
    // process.env.REACT_APP_BASE_URL_DEV_FOURTH,
    // process.env.REACT_APP_BASE_URL_DEV_FIFTH,
    // process.env.REACT_APP_BASE_URL_DEV_SIXTH,
    // process.env.REACT_APP_BASE_URL_DEV_SEVENTH,
    // process.env.REACT_APP_BASEURL_OMAYA,

    // IDEAS
    // process.env.REACT_APP_IDEAS
    // process.env.REACT_APP_IDEAS_DEV ||
    // process.env.REACT_APP_IDEAS_PRODUCTION ||
    // process.env.REACT_APP_IDEAS_PROD_NEW,
    // process.env.REACT_APP_IDEAS_DEV_NEW,
    // process.env.REACT_APP_IDEAS_TEST_NEW,
    //
    "localhost:12045",
  headers: config,
  // timeout: 5000,
});

// BASE URL
// export const baseurl = baseURL || "localhost:12045";
export const baseurl = process.env.REACT_APP_BASEURL || "localhost:12046";
// export const baseurl = process.env.REACT_APP_BASE_URL_LAN || "localhost:12045";
// export const baseurl = process.env.REACT_APP_BASE_URL_DEVELOPMENT;
// export const baseurl = process.env.REACT_APP_BASE_URL_DEVELOPMENT_SECOND;
// export const baseurl = process.env.REACT_APP_BASE_URL_DEVELOPMENT_THIRD;
// export const baseurl = process.env.REACT_APP_BASE_URL_DEV_FOURTH;
// export const baseurl = process.env.REACT_APP_BASE_URL_DEV_FIFTH;
// export const baseurl = process.env.REACT_APP_BASE_URL_DEV_SIXTH;
// export const baseurl = process.env.REACT_APP_BASE_URL_DEV_SEVENTH;
// export const baseurl = process.env.REACT_APP_IDEAS_DEV_NEW;
// export const baseurl = process.env.REACT_APP_BASEURL_OMAYA,

// IDEAS
// export const baseurl = process.env.REACT_APP_IDEAS;
// export const baseurl = process.env.REACT_APP_IDEAS_DEV || "localhost:12045";
// export const baseurl = process.env.REACT_APP_IDEAS_PRODUCTION || "localhost:12045";
// export const baseurl = process.env.REACT_APP_IDEAS_TEST_NEW;
// export const baseurl = process.env.REACT_APP_IDEAS_PROD_NEW;

// BASE API
export default baseAPI;
