// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import { Table, Button, Col, Row, Typography, Tooltip, Tag } from "antd";
import { SyncOutlined } from "@ant-design/icons/lib/icons";

// Import React Icons
import { FaRegEye } from "react-icons/fa";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Import Page Components
import BillOutstandingModal from "../../../Modals/BillOutstanding/Modal";

// CODE
export default function NARoomRateTable(props) {
  // PROPS
  const { dataNARoomRate, is_search, searchKey } = props;

  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getReservation } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [guestData, setGuestData] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Modal
  const [open, setOpen] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // FETCH DATA BILL OUTSTANDING
  const fetchData = async () => {
    await getReservation({
      reservation: "bill",
      type: "outstanding",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          const _map = _response.map((items, index) => {
            return {
              ...items,
              key: index + 1,
            };
          });

          console.log("Mapped: ", _map);

          setData(_map);
          setBulks(_map);
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    fetchData();
  }, []);

  // USEEFFECT SEARCH
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey]);

  // HANDLE SEARCH
  const handleSearch = async () => {
    const queryName = searchKey ? searchKey.toLowerCase() : null;

    let updatedList = bulks.filter((item) => {
      const name = item?.guest_name ? item.guest_name.toLowerCase() : "";

      return name.indexOf(queryName) !== -1;
    });

    setData(updatedList);
  };

  // HANDLE MOVE
  const move = () => {
    navigate("master-folio");
  };

  // HANDLE OPEN
  const handleOpen = () => {
    setOpen(true);
  };

  // COLUMN
  const columns = [
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      width: 50,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24}>
                <Tooltip title="Show Detail(s)" className="edit-tooltip">
                  <FaRegEye
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO DETAIL > ", record);

                      setGuestData(record);
                      handleOpen();
                    }}
                    style={{
                      fontSize: 20,
                      color: "#1BC5BD",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
    },
    {
      title: "Room Cat.",
      dataIndex: "room_category",
      key: "room_category",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.room_category.localeCompare(b.room_category),
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Bill No.",
      dataIndex: "bill_alias",
      key: "bill_alias",
    },
    {
      title: "Bill Master",
      dataIndex: "bill_master",
      key: "bill_master",
    },
    // {
    //   title: "Invoice Id",
    //   dataIndex: "invoice_id",
    //   key: "invoice_id",
    // },
    {
      title: "Status",
      dataIndex: "step_status",
      key: "step_status",
    },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      render: (group) => {
        let grp = "";
        let clr = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            clr = "#52c41a";
            break;

          case 1:
            grp = "Group Leader";
            clr = "#1677ff";
            break;

          case 2:
            grp = "Group Member";
            clr = "#8c8c8c";
            break;

          default:
            break;
        }

        return (
          <Tag key={"key-tag"} color={clr}>
            {grp}
          </Tag>
        );
      },
    },
    // {
    //   title: `Price ( ${currency} )`,
    //   dataIndex: "price",
    //   key: "price",
    //   render: (unit, record) => {
    //     return `${unit > 0 ? unit : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   },
    // },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    // },
    // {
    //   title: "Trx Date",
    //   dataIndex: "created_date",
    //   key: "created_date",
    //   render: (date, record) => {
    //     return moment(LocalizationDate(date)).format("HH:mm:ss, DD-MM-YYYY");
    //   },
    //   sort: ["ascend", "descend"],
    //   sorter: (a, b) => a.created_date.localeCompare(b.created_date),
    // },
  ];

  return (
    <>
      <Row className="table-main-row" justify="center" style={mainBody}>
        <Col className="table-main-col" span={24} style={{ padding: 30 }}>
          <Row
            justify="space-between"
            className="row-refresh-btn"
            gutter={30}
            style={{
              margin: "0px 0px 30px",
            }}
          >
            <Button className="search-btn" type="primary" onClick={move}>
              {`Go To Master Folio`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Table
            className="bill-outstanding-table"
            key="bill-outstanding-table"
            name="bill-outstanding-table"
            loading={loading}
            columns={columns}
            dataSource={data}
            bordered
            pagination={{
              pageSizeOptions: [5, 10, 25, 50, 100],
              defaultPageSize: 5,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            }}
            scroll={{
              x: 1000,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            rowKey={(record) =>
              record?.key > 0 ? record.key : record.bill_master
            }
          />
        </Col>
      </Row>

      <BillOutstandingModal
        openModal={open}
        closeModal={setOpen}
        guestData={open ? guestData : null}
      />
    </>
  );
}
