// Import React's Component
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

// Import Ant Design Components
import {
  Table,
  Button,
  Col,
  Row,
  Modal,
  Typography,
  Form,
} from "antd";

// Import React Icons
import { PrinterOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import MasterPaymentTypeEdit from "../../../../Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterPaymentCardEdit from "../../../../Reusable/Master/PaymentCard/PaymentCardEdit";

const { confirm } = Modal;

// CODE
export default function PaidOrderTable(props) {
  // PROPS
  const { dataRoomRate } = props;

  // DATA MANAGEMENT
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);

  // STATE MANAGEMENT
  // Loadings
  const [loading, setLoading] = useState(false);
  const [lodingBtn, setLodingBtn] = useState(false);
  const [isPrint, setIsPrint] = useState(false);

  // Filter
  const [payCode, setPayCode] = useState(null);
  const [payId, setPayId] = useState(null);

  // FORM
  const [form] = Form.useForm();

  //dummy
  const paidOrders = [
    {
      order_id: 1,
      date: "2024-10-13",
      sales_type: "Dine-in",
      table_code: "T01",
      name: "John Doe",
      payment_type: "Credit Card",
      payment_details: "BCA - IDR",
      shift: "Morning",
      user: "cashier1",
      location: "Outlet A",
      price: 500000
    },
    {
      order_id: 2,
      date: "2024-10-13",
      sales_type: "Takeaway",
      table_code: "N/A",
      name: "Jane Smith",
      payment_type: "IDR Cash",
      payment_details: "Cash - IDR",
      shift: "Morning",
      user: "cashier1",
      location: "Outlet A",
      price: 150000
    },
    {
      order_id: 3,
      date: "2024-10-13",
      sales_type: "Delivery",
      table_code: "N/A",
      name: "Michael Johnson",
      payment_type: "Debit",
      payment_details: "BNI - IDR",
      shift: "Evening",
      user: "cashier2",
      location: "Outlet B",
      price: 250000
    }
  ];

  useEffect(() => {
    let cleanUp = false;

    if (isPrint && !cleanUp) {
      printOut();
    }

    return () => {
      cleanUp = true;
    };
  }, [isPrint]);

  useEffect(() => {
    let cleanUp = false;

    if (!cleanUp) {
      setData(paidOrders);
      setBulks(paidOrders);
    }

    return () => {
      cleanUp = true;
    };
  }, []);

  // COLUMN
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      hidden: false,
      sorter: (a, b) => a.order_id - b.order_id,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      hidden: false,
      sorter: (a, b) => a.date - b.date,
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Sales Type",
      dataIndex: "sales_type",
      key: "sales_type",
      hidden: false,
      render: (text, record) => {
        return `${text} (${record.table_code})`
      }
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      hidden: false,
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
      // width: 150,
      hidden: false,
    },
    {
      title: "Payment Details",
      dataIndex: "payment_details",
      key: "payment_details",
      // width: 150,
      hidden: false,
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      hidden: false,
    },
    {
      title: `User`,
      dataIndex: "user",
      key: "user",
      // align: "right",
      hidden: false,
    },
    {
      title: `Location`,
      dataIndex: "location",
      key: "location",
      // align: "right",
      hidden: false,
    },
    {
      title: `Price`,
      dataIndex: "price",
      key: "price",
      align: "right",
      hidden: false,
      render: (text) => {
        return `${text}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," ) 
      },
    },
  ].filter((x) => !x.hidden);

  // Handle Print
  const componentRef = useRef();
  const pageStyle = "@media print{@page {size: landscape}}";

  const handlePrint = () => {
    // printOut();
    setIsPrint(true);
  };

  const printOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${moment().format("YYYY-MM-DD HH:mm:ss")} Paid Orders - Outlet Close Order`,
    onAfterPrint: () => {
      setIsPrint(false);
    },

    pageStyle: pageStyle,
  });

  // Payment Type
  const getPaymentType = (value, code, name) => {
    console.log("getPaymentType", value, code, name);
    if(value.name){
      setPayId(value);
    } else {
      setPayId(null);
      setPayCode(null);
    }

    if (value.name && payCode) {
      let filter = bulks.filter((x) => {
        if(x.payment_type == value.name && x.payment_details == payCode) {
          return true
        } else {
          return false
        }
      })
      setData(filter);
    } else {
      setData(bulks);
    }
  };

  // Payment Details/Card
  const getPaymentDetails = (value, code, acc) => {
    console.log("getPaymentDetails", value, code, acc);
    if(value.name){
      setPayCode(value.name);
    } else {
      setPayCode(null);
    }

    if (payId?.name && value.name) {
      let filter = bulks.filter((x) => {
        if(x.payment_type == payId?.name && x.payment_details == value.name) {
          return true
        } else {
          return false
        }
      })
      setData(filter);
    } else {
      setData(bulks);
    }
  };

  return (
    <>
      <Row justify="space-between" style={{ marginTop: 30, width: "100%", alignItems: "flex-end"}}>
        <Col style={{ width:"50%" }}>
          <Row justify="flex-start">
          <Col style={{marginLeft:"20px", minWidth: "40%"}}>
          <Typography
            style={{marginBottom: "5px"}}
            >Payment Type</Typography>
          <MasterPaymentTypeEdit
            getPaymentType={getPaymentType}
            pay={null}
            />
          </Col>
          <Col style={{marginLeft:"20px", minWidth: "40%"}}>
          <Typography
            style={{marginBottom: "5px"}}
          >Payment Details</Typography>
          <MasterPaymentCardEdit
            getPayCard={getPaymentDetails}
            getPayTypeId={payId?.id}
            payCard={null}
            getCode={() => {}}
          />
          </Col>
          </Row>
        </Col>
        <Col>
          <Button
            icon={<PrinterOutlined />}
            className="next-btn"
            type="primary"
            onClick={handlePrint}
          >{`Download`}</Button>
        </Col>
      </Row>

      <Row
        ref={componentRef}
        style={{ marginTop: 15, padding: 20, width: "100%" }}
      >
        <Typography.Title level={5}>
          {isPrint
            ? `Paid Orders - Outlet Close Order ${moment().format("DD-MM-YYYY HH:mm:ss")}`
            : null}
        </Typography.Title>

        <Table
          className="na-roomrate-table"
          key="na-roomrate-table"
          name="na-roomrate-table"
          loading={loading}
          columns={columns}
          // dataSource={paidOrders}
          dataSource={data}
          bordered
          size="small"
          pagination={
            false
          }
          scroll={{
            x: 1000,
          }}
          rowKey={(record) => (record.order_id)}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          summary={() => {
            const totalPrice = data.reduce((acc, order) => acc + order.price, 0);

            return (
              <>
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={3} colSpan={9}>
                      <b style={{ fontSize: 12 }}>Total</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={8} align="right">
                      <b style={{ fontSize: 12 }}>
                      {`${totalPrice}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              </>
            );
          }}
        />
      </Row>
    </>
  );
}
