// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { ExclamationCircleOutlined, SyncOutlined } from "@ant-design/icons";

// GLOBAL STYLES
import { textForm } from "../../../Style/Global/Global";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";

// NOTIFICATIONS
import { failedFetch } from "../../Reusable/Notification/Notification";

// REACT ICONS
import { HiViewList } from "react-icons/hi";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../API/Config/Api";

// PARAMS
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// PAGE COMPONENTS
import GroupingBill from "../../Reusable/Functions/GroupingArticle/GroupingBill";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";
import DetailRelatedBillModal from "./ModalDetail";

export default function GuestBillModal(props) {
  // Props
  const {
    openModal,
    closeModal,
    isJoin,
    getJoin,
    getType,
    checkoutData,
    receiver,
    getRelatedBillData,
    getJoinedRelatedBill,
    type,
  } = props;

  // PARAMS
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // State
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDetailBill, setOpenDetailBill] = useState(false);
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [join, setJoin] = useState(false);
  const [guestData, setGuestData] = useState(null);
  const [checkoutBill, setCheckoutBill] = useState(null);
  const [selectedBill, setSelectedBill] = useState([]);
  const [billData, setBillData] = useState(null);
  const [relatedBillData, setRelatedBillData] = useState([]);
  const [tempData, setTempData] = useState({
    selectedRowArray: [],
    selectedBill: [],
    isJoin: false,
  });

  // USEEFFECT OPEN MODAL
  useEffect(() => {
    let cleanUp = false;

    if (openModal && !cleanUp) {
      setOpen(openModal);
      setTempData({
        ...tempData,
        selectedRowArray: selectedRowArray,
        selectedBill: selectedBill,
      });
    } else {
      setOpen(false);
      // setTempData({
      //   selectedRowArray: [],
      //   selectedBill: [],
      // });
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  // USEEFFECT JOIN CHECKBOX
  useEffect(() => {
    let cleanUp = false;

    if (isJoin && !cleanUp) {
      setJoin(true);
      setTempData({
        ...tempData,
        isJoin: true,
      });
    } else {
      setJoin(false);
      setTempData({
        ...tempData,
        isJoin: false,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [isJoin]);

  // USEEFFECT HANDLE IF TYPE IS SPLIT
  useEffect(() => {
    let cleanUp = false;

    if ((type === 1 || type === 0) && selectedBill.length > 0 && !cleanUp) {
      setSelectedBill([]);
      setSelectedRowArray([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [type]);

  // USEEFFECT OTHER RECEIVER BILL
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (relatedBill.length > 0 && !cleanUp) {
  //     setData(relatedBill);
  //   } else {
  //     setData([]);
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, [relatedBill]);

  // USEEFFECT CHECKOUT DATA
  useEffect(() => {
    let cleanUp = false;

    if (checkoutData && !cleanUp) {
      setGuestData(checkoutData.data_res);
      fetchBillReceiverData(checkoutData.data_res.reservation_id);
      fetchDataOtherBill(checkoutData.data_res.reservation_id);
      // fetchData(checkoutData?.reservation_id);
    }

    return () => {
      cleanUp = true;
    };
  }, [checkoutData]);

  // USEEFFECT RECEIVER
  useEffect(() => {
    let cleanUp = false;

    if (receiver && !cleanUp) {
      setGuestData(receiver);
      fetchBillReceiverData(receiver.reservation_id);
      fetchDataOtherBill(receiver.reservation_id);
      // setCheckoutBill(checkoutData.data_bill);
      // fetchData(checkoutData?.reservation_id);
    }

    return () => {
      cleanUp = true;
    };
  }, [receiver]);

  // BILL RECEIVER
  const fetchBillReceiverData = async (res_id) => {
    setIsLoading(true);

    await axios
      .post(`${baseurl}/bill/master-folio-res`, {
        reservation_id: [res_id],
        source: [1],
      })
      .then((response) => {
        console.log("response modal receiver data: ", response);
        if (response?.data?.msg) {
          const _res = response.data.msg;
          // const filteredResponse = _res.filter((item) => item.is_show == false);
          // const mappedResponse = handleManipulateAPIResponse(_res);
          const _data = handleDataDetail(_res);
          setCheckoutBill(_data.article);
        } else {
          setCheckoutBill([]);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // FETCH DATA BILL LIST
  const fetchDataOtherBill = async (resId) => {
    setLoading(true);

    await axios
      .post(`${baseurl}/bill/linked-res`, {
        reservation_id: resId,
      })
      .then((response) => {
        console.log("response other bill list: ", response);
        if (response?.data?.msg) {
          const res = response.data.msg;

          // RELATED BILL DATA FOR TABLE
          const _data = res.map((item) => {
            return {
              ...item,
              id: generateUniqueKey(),
            };
          });
          setData(_data);
          handleRelatedBillData(_data);

          // RELATED BILL DATA FOR STATE
          // const _billData = [];
          // res?.forEach((item) =>
          //   _billData.push(...handleDataDetail(item.details).article)
          // );
          // const _mappedBillData = _billData?.map((item) => {
          //   return {
          //     ...item,
          //     id: generateUniqueKey(),
          //     reservation_id: item?.reservation_id,
          //     link_reservation_id: receiver?.reservation_id,
          //   };
          // });
          // console.log("MAPPED BILL DATA ->> ", _mappedBillData);

          // setRelatedBillData(_mappedBillData);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // HANDLE RELATED BILL DATA
  const handleRelatedBillData = (arr) => {
    // console.log("ARR RELATED BILL DATA FUNCTION ->> ", arr);

    const _bill = [];
    arr?.forEach((item) =>
      _bill.push(...handleDataDetail(item?.details).article)
    );

    const _related = _bill?.map((item) => {
      return {
        ...item,
        reservation_id: item?.reservation_id,
        link_reservation_id: receiver?.reservation_id,
      };
    });

    // RELATED BILL DATA
    getRelatedBillData(_related);
    setRelatedBillData(_related);
  };

  // Handle Data Details
  const handleDataDetail = (value) => {
    const _arr = value;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    // const _grouping = G
    const _grouping = BillArticleShow({
      article: _billing,
      bill_type: bill_type,
    });

    // console.log("Billing: ", _billing);
    console.log("Grouping Res: ", _grouping);

    return _grouping;
  };

  // GENERATE UNIQUE KEY FOR OPTION VALUE DATA
  const generateUniqueKey = () => {
    const timestamp = Date.now();
    const randomNum = Math.random().toString(36).substring(2, 10);

    return `key_${timestamp}_${randomNum}`;
  };

  // HANDLE CLOSE /  CANCEL MODAL
  const handleClose = () => {
    setSelectedRowArray(tempData.selectedRowArray);
    setSelectedBill(tempData.selectedBill);
    getJoin(tempData.isJoin);
    setJoin(tempData.isJoin);

    closeModal(false);
  };

  // HANDLE SUBMIT
  const handleSubmit = () => {
    if (!join && selectedBill.length === 0) {
      getType(0);
      getJoinedRelatedBill([]);
      getJoin(false);
      getRelatedBillData(relatedBillData);
    } else {
      // const _join = selectedBill?.map((item) => {
      //   return {
      //     ...item,
      //     reservation_id: item?.reservation_id,
      //     link_reservation_id: receiver?.reservation_id,
      //   };
      // });

      // GET details data from header related bill
      const _bill = [];
      selectedBill?.forEach((item) =>
        _bill.push(...handleDataDetail(item.details).article)
      );
      const _join = _bill?.map((item) => {
        return {
          ...item,
          reservation_id: item?.reservation_id,
          link_reservation_id: receiver?.reservation_id,
        };
      });

      // console.log("JOINNNNNN ->> ", _join);

      getJoinedRelatedBill(_join);
      getType(2); // change type to JOIN BILL
      getJoin(join);

      // Filter Related Bill Data
      const selectedId = _join.map((item) => item.article_id);
      const _filteredData = relatedBillData.filter(
        (item) => !selectedId.includes(item.article_id)
      );

      // console.log("RELATED ->> ", relatedBillData);
      // console.log("JOINED ->> ", _join);
      // console.log("FILTERED ->> ", _filteredData);

      // Set Related Bill Data
      getRelatedBillData(_filteredData);
    }

    // if (selectedBill?.length > 0 || join) {
    //   const _join = selectedBill?.map((item) => {
    //     return {
    //       ...item,
    //       reservation_id: item.reservation_id,
    //       link_reservation_id: receiver.reservation_id,
    //     };
    //   });

    //   getJoinedRelatedBill(_join);
    //   getType(2); // change type to JOIN BILL
    //   getJoin(join);
    // } else {
    //   getJoinedRelatedBill([]);
    //   // getType(isJoin ? 2 : 0);
    //   getType(join ? 2 : 0);
    // }

    closeModal(false); // set modal to close
  };

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray(selectedRowKeys);
      setSelectedBill(selectedRows);
    },
  };

  // HANDLE CHANGE JOIN CHECKBOX
  const onChange = (e) => {
    let _join = e.target.checked;
    setJoin(_join);
    // getType(_join ? 2 : selectedBill.length > 0 ? 2 : 0); // if join, change bill type to JOIN BILL
    // getJoin(_join);

    // if (!_join && selectedBill.length === 0) {
    //   getType(0);
    // } else {
    //   getType(2);
    // }
  };

  // HANDLE OPEN MODAL
  const handleOpen = () => {
    setOpenDetailBill(true);
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    if (receiver?.reservation_id) {
      fetchDataOtherBill(receiver?.reservation_id);
    } else if (checkoutData?.data_res?.reservation_id) {
      fetchDataOtherBill(checkoutData?.data_res?.reservation_id);
    }
  };

  // Table Columns
  const checkoutColumns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Quantity`,
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
  ];

  // Table Columns
  const billColumns = [
    {
      title: "Detail",
      dataIndex: "",
      key: "action",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24}>
                <Tooltip title="Show Detail(s)" className="edit-tooltip">
                  <HiViewList
                    className="edit-btn"
                    onClick={() => {
                      console.log("ABOUT TO DETAIL > ", record);
                      setBillData(record);
                      handleOpen();
                    }}
                    style={{
                      fontSize: 20,
                      color: "#27BCEE",
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      render: (total, record) => {
        return `${total > 0 ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    // {
    //   title: `Quantity`,
    //   dataIndex: "quantity",
    //   key: "quantity",
    //   render: (quantity, record) => {
    //     return `${quantity > 0 ? quantity : 0}`.replace(
    //       /\B(?=(\d{3})+(?!\d))/g,
    //       ","
    //     );
    //   },
    // },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
  ];

  return (
    <>
      <Modal
        className="guest-history-modals"
        key="guest-history-modals"
        title={
          <>
            <Space size={"large"} className="title-modal-row">
              <ExclamationCircleOutlined
                className="icon-title-modal"
                style={{
                  color: "#1890FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="title-modal-text"
                style={{
                  color: "#1890FF",
                  //   fontSize: 24,
                }}
              >
                {`Guest Bill List`}
              </Typography>
            </Space>
          </>
        }
        centered
        open={open}
        bodyStyle={{
          padding: 30,
        }}
        closable
        onCancel={handleClose}
        footer={null}
        width={"auto"}
      >
        <Form
          className="geust-form"
          key="geust-form"
          name="geust-form"
          labelAlign="left"
          labelCol={{
            span: 12,
          }}
          labelWrap={{
            span: 12,
          }}
          // onFinish={handleClose}
          layout="horizontal"
          style={{
            minWidth: 800,
          }}
        >
          <Row className="identity-row">
            <Col span={24} className="identity-col">
              <Row className="ident-row" gutter={30}>
                <Col span={12} className="name-col">
                  <Form.Item
                    label="Guest Name"
                    className="name-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="guest-name-text" style={textForm}>
                      {guestData?.guest_name
                        ? guestData?.guest_name?.replace(/\d+$/, "").trim()
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Arrival"
                    className="name-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="arrival-text" style={textForm}>
                      {guestData?.arrival
                        ? moment(guestData.arrival).format("DD-MM-YYYY")
                        : moment(guestData?.start_date).format("DD-MM-YYYY") !=
                          "Invalid Date"
                        ? moment(guestData?.start_date).format("DD-MM-YYYY")
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Departure"
                    className="name-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="departure-text" style={textForm}>
                      {guestData?.departure
                        ? moment(guestData.departure).format("DD-MM-YYYY")
                        : moment(guestData?.end_date).format("DD-MM-YYYY") !=
                          "Invalid Date"
                        ? moment(guestData?.end_date).format("DD-MM-YYYY")
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Night(s)"
                    className="night"
                    style={{ margin: 0 }}
                  >
                    <Typography className="departure-text" style={textForm}>
                      {guestData?.night ? guestData.night : 0}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="count-col">
                  <Form.Item
                    label="Reservation No."
                    className="name-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="reservation-text" style={textForm}>
                      {guestData?.reservation_id
                        ? guestData?.reservation_id
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Payment Detail"
                    className="payment-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="payment-text" style={textForm}>
                      {`${guestData?.room_category} - ${guestData?.room_number}`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Payment Status"
                    className="payment-item"
                    style={{ margin: 0 }}
                  >
                    <Typography className="payment-text" style={textForm}>
                      {guestData?.payment_status == 3
                        ? "Cash Basis"
                        : "Charge To Room"}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Divider
                className="form-divider"
                orientation="left"
                style={{
                  margin: "10px 0px",
                  borderColor: "#EBEDF3",
                  fontWeight: "normal",
                  fontSize: 15,
                }}
              >
                {"Transaction Detail"}
              </Divider>

              <Row
                className="visited-table"
                justify="center"
                gutter={30}
                style={{
                  padding: "0px 15px",
                }}
              >
                <Table
                  className="guest-bill-table"
                  key="guest-bill-table"
                  name="guest-bill-table"
                  columns={checkoutColumns}
                  dataSource={checkoutBill}
                  bordered={true}
                  loading={isLoading}
                  size="small"
                  // pagination={false}
                  pagination={{
                    defaultPageSize: 5,
                    pageSizeOptions: [10, 20, 50, 100],
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `Showing ${range[0]}-${range[1]} of ${total} entries`,
                  }}
                  rowKey={(record) => (record?.id ? record.id : record.key)}
                />
              </Row>

              <Form.Item
                className="information-item"
                labelCol={{
                  span: 6,
                }}
                style={{ margin: "15px 0px" }}
              >
                <Alert
                  className="guest-alert"
                  message="Information!"
                  showIcon
                  description={`Reservation-related bills have been loaded. Review the details before proceeding`}
                  type={"info"}
                  style={{
                    textAlign: "center",
                  }}
                />
              </Form.Item>

              <Divider
                className="form-divider"
                orientation="left"
                style={{
                  margin: "10px 0px",
                  borderColor: "#EBEDF3",
                  fontWeight: "normal",
                  fontSize: 15,
                }}
              >
                {"Related Bill List"}
              </Divider>

              <Row
                className="visited-table"
                // justify="center"
                gutter={30}
                style={{
                  padding: "0px 15px",
                }}
              >
                <Row
                  className="row-modal-btn"
                  justify="space-between"
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  <Typography>
                    *Select Bill if you want to join with Reservation-related
                    bills!
                  </Typography>

                  <Button
                    type={"default"}
                    icon={<SyncOutlined />}
                    onClick={handleRefresh}
                  >
                    {`Refresh`}
                  </Button>
                </Row>

                <Table
                  className="guest-bill-table"
                  key="guest-bill-table"
                  name="guest-bill-table"
                  columns={billColumns}
                  dataSource={data}
                  bordered={true}
                  loading={loading}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  size="small"
                  pagination={false}
                  rowKey={(record) => (record?.id ? record.id : record.key)}
                />
              </Row>

              <Row
                justify="start"
                gutter={30}
                style={{
                  padding: "15px",
                }}
              >
                <Divider
                  className="divider-form"
                  // style={{ margin: "15px 0px" }}
                />

                <Checkbox onChange={onChange} checked={join}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Join With Other Bill?
                  </Typography>
                </Checkbox>
              </Row>
            </Col>
          </Row>

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="submit-btn"
              type="default"
              style={{
                marginRight: 15,
                color: "white",
              }}
              onClick={handleSubmit}
            >
              {`Submit`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>

      <DetailRelatedBillModal
        openModal={openDetailBill}
        closeModal={setOpenDetailBill}
        billData={openDetailBill ? billData : null}
      />
    </>
  );
}
