// REACT COMPONENTS
import React, { useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Table, Tag, Typography } from "antd";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// REACT ICONS
import { PiFileArchiveFill } from "react-icons/pi";

// PAGE COMPONENTS
import MasterFolioForm from "../../../Forms/MasterFolio/Form";

export default function ListBillPayTable({
  articleData,
  billReceiver,
  getReturnArticleData,
  getArticleData,
  typeData,
  checkoutData,
  joinedRelatedBill = [],
  billSource,
  relatedBillData,
}) {
  // PARAMS
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  const [data, setData] = useState([]);
  // Refresh
  const [loading, setLoading] = useState(false);
  // Row Selection
  const [selectedRowArray, setSelectedRowArray] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (articleData.length > 0 && !cleanUp) {
      const _data =
        joinedRelatedBill.length > 0
          ? [...joinedRelatedBill, ...articleData]
          : articleData;
      setData(_data);
    } else {
      const _data = joinedRelatedBill.length > 0 ? joinedRelatedBill : [];
      setData(_data);
      setSelectedData([]);
      setSelectedRowArray([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [articleData, joinedRelatedBill]);

  // TABLE SELECTION
  const rowSelection = {
    selectedRowKeys: selectedRowArray,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: `, selectedRowKeys);
      console.log("selectedRows: ", selectedRows);

      setSelectedRowArray(selectedRowKeys);
      setSelectedData(selectedRows);
      // handlePick(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      // Disable checkbox berdasarkan kondisi
      disabled:
        typeData === 2 || typeData === 0
          ? record.link_reservation_id
            ? record.link_reservation_id === billReceiver?.reservation_id
            : record.reservation_id === billReceiver?.reservation_id
          : false,
    }),
  };

  // HANDLE MOVE DATA (CheckBox Option)
  const handlePick = () => {
    let sourceData = data;

    // Mapping remove joined related Bill
    const removeJoined = joinedRelatedBill.map((item) => item.id);

    // Filter sourceData untuk menghapus item yang id nya ada di removeJoined
    const updatedSourceData = sourceData.filter(
      (item) => !removeJoined.includes(item.id)
    );
    // Filter Source Data
    const filteredData = updatedSourceData.filter(
      (sourceItem) =>
        !selectedData?.some(
          (selectedItem) => selectedItem?.id === sourceItem?.id
        )
    );

    // Set Data on Table Bill Pay List by FilteredData
    // setData(filteredData);
    getArticleData(filteredData);
    // Set Data on Table Bill List by Selected Data
    getReturnArticleData(selectedData);
    // Reset Selected Data & Selected Row Array
    setSelectedData([]);
    setSelectedRowArray([]);
  };

  // Table Columns
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: `Quantity`,
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price, record) => {
        return `${unit_price > 0 ? unit_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      render: (total_price, record) => {
        return `${total_price > 0 ? total_price : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
  ];

  return (
    <>
      <Row
        className="master-folio-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-master-folio" style={{ padding: 30 }}>
          <Row className="row-modal-btn" justify="end">
            <Col span={8} className="col-exp-btn">
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 750,
                }}
              >
                Bill Pay List
              </Typography>
            </Col>

            <Col span={16}>
              <Row justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  disabled={selectedRowArray?.length > 0 ? false : true}
                  onClick={handlePick}
                  style={{
                    marginRight: "15px",
                    borderColor: "transparent",
                  }}
                >
                  <ArrowLeftOutlined />
                  {`Move`}
                </Button>

                <Button
                  className="submit-btn"
                  onClick={() => {
                    setOpen((state) => !state);
                  }}
                  type="primary"
                  disabled={data.length > 0 ? false : true}
                  icon={
                    <PiFileArchiveFill
                      style={{
                        // margin: "0px 5px 0px 0px",
                        fontSize: 20,
                      }}
                    />
                  }
                  style={{
                    display: "flex",
                    padding: "5px 10px 10px 5px",
                    marginRight: "15px",
                    minWidth: 100,
                    borderColor: "transparent",
                  }}
                >
                  {`Submit Bill`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 15, width: "100%" }}>
            <Table
              className="master-folio-table"
              key="master-folio-table"
              name="master-folio-table"
              title={() => (
                <Typography.Title level={5}>
                  Bill Receiver:{" "}
                  {billReceiver?.label ? billReceiver?.label : "-"}
                </Typography.Title>
              )}
              loading={loading}
              columns={columns}
              dataSource={data}
              size="small"
              //onChange={onChange}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={false}
              // pagination={{
              //   // defaultPageSize: 5,
              //   // pageSizeOptions: [10, 20, 50, 100],
              //   // showSizeChanger: true,
              //   showTotal: (total, range) =>
              //     `Showing ${range[0]}-${range[1]} of ${total} entries`,
              // }}
              scroll={{
                // y: 270,
                x: true,
              }}
              rowKey={(record) => record.id}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <MasterFolioForm
        openModal={open}
        closeModal={setOpen}
        billData={data}
        billReceiver={billReceiver}
        typeData={typeData}
        checkoutData={checkoutData}
        billSource={billSource}
        relatedBillData={relatedBillData}
      />
    </>
  );
}
