// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// AUTH
import {
  roles,
  token,
  user_level,
  user_name,
} from "../../../../API/Global/Payload";

// BASE API
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Tag,
  Tooltip,
  Form,
  Modal,
  Typography,
  Input,
  Select,
} from "antd";

// Import React Icons
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Notifications
import {
  failedFetch,
  failedRoomStatus,
  successRoomStatus,
} from "../../../Reusable/Notification/Notification";
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";

// Import Master Components
import MasterRoomStatus from "../../../Reusable/Master/RoomStatus/RoomStatus";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { FiEdit } from "react-icons/fi";

// Modal Confirm
const { confirm } = Modal;

// CODE
export default function RoomStatusTable(props) {
  // PROPS
  const { is_search, searchKey, searchGuest } = props;

  // CONTEXT
  const { getRoomStatus } = useContext(MainContext);
  const { getMasterRoomStatus } = useContext(MasterContext);

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      width: 150,
      render: (_, record) => {
        let _disable = false;

        if (user_level >= 10) {
          _disable = false;
        } else if (user_level < 3) {
          switch (record?.room_status_id) {
            case 6:
              _disable = true;
              break;
            case 7:
              _disable = true;
              break;
            case 10:
              _disable = true;
              break;

            default:
              _disable = false;
              break;
          }
        }

        // switch (record?.room_status_id) {
        //   case 6:
        //     _disable = true;
        //     break;
        //   case 7:
        //     _disable = true;
        //     break;
        //   case 10:
        //     _disable = true;
        //     break;

        //   default:
        //     break;
        // }

        return (
          <Tooltip title="Change Status Room" className="status-tooltip">
            <Button
              className="submit-btn"
              type="primary"
              icon={<FormOutlined />}
              disabled={_disable}
              onClick={() => {
                console.log("About to Change >> ", record);
                setOpen(!open);
                setDataStatus(record);

                setFormFields(record);
              }}
              // style={{ backgroundColor: "#13c2c2", borderColor: "#13c2c2" }}
            >
              {`Change`}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      // fixed: "left",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room Code",
      dataIndex: "room_category_code",
      key: "room_category_code",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Room ID",
      dataIndex: "room_id",
      key: "room_id",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_id.localeCompare(b.room_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    },
    {
      title: "Room Status",
      dataIndex: "room_status_id",
      key: "room_status_id",
      width: 250,
      filters: [
        {
          text: "Vacant Clean Unchecked",
          value: 1,
        },
        {
          text: "Vacant Clean Checked",
          value: 2,
        },
        {
          text: "Occupied Dirty",
          value: 3,
        },
        {
          text: "Occupied Clean",
          value: 4,
        },
        {
          text: "Vacant Dirty",
          value: 5,
        },
        {
          text: "Out Of Order",
          value: 6,
        },
        {
          text: "Out Of Service",
          value: 7,
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.room_status_id == value;
      },
      // sortDirections: ["descend", "ascend"],
      // sorter: (a, b) => a.room_status - b.room_status,
      render: (room_status, record) => {
        // console.log("Room Status ", room_status);

        let status = "";
        let clr = "";

        switch (room_status) {
          case 1:
            status = "Vacant Clean Unchecked";
            clr = "#1890ff";
            break;
          case 2:
            status = "Vacant Clean Checked";
            clr = "#87d068";
            break;
          case 3:
            status = "Occupied Dirty";
            clr = "#f5222d";
            break;
          case 4:
            status = "Occupied Clean";
            clr = "#13c2c2";
            break;
          case 5:
            status = "Vacant Dirty";
            clr = "#fa8c16";
            break;
          case 6:
            status = "Out of Order";
            clr = "black";
            break;
          case 7:
            status = "Out of Service";
            clr = "brown";
            break;
          case 8:
            status = "Occupied (No Luggage)";
            clr = "#fa541c";
            break;
          case 9:
            status = "NO SHOW";
            clr = "#135200";
            break;
          case 10:
            status = "OFF MARKET";
            clr = "#722ed1";
            break;

          default:
            status = "UNKNOWN";
            clr = "grey";
            break;
        }

        return (
          <Tag color={clr} style={{ fontSize: 18, fontWeight: 600 }}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Occupancy",
      dataIndex: "occupancy_status",
      key: "occupancy_status",
      render: (occupancy, record) => {
        if (occupancy == true) {
          return "Occupied";
        } else {
          return "Unoccupied";
        }
      },
    },
  ];

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [dataStatus, setDataStatus] = useState(null);
  const [status, setStatus] = useState(null);
  // Filter
  const [filter, setFilter] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Open Modal
  const [open, setOpen] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    fetchData();
    fetchMasterRoom();
  }, []);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, searchGuest]);

  // FETCH DATA
  const fetchData = async () => {
    await getRoomStatus({
      status: "status",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Room Status => ", response);

        let filtered = response.data.msg.filter((filt) => {
          if (filt.is_deleted == false || filt.is_deleted == null) {
            return filt;
          }
        });

        setData(filtered);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // FETCH ROOM STATUS
  const fetchMasterRoom = async () => {
    await getMasterRoomStatus({
      room_status: "room-status",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Room Status => ", response);

        let optionFilter = response.data.msg.map((value) => ({
          text: value.room_status,
          value: value.room_status_id,
        }));

        setFilter(optionFilter);
        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error ", error);
        failedFetch(error);
        setLoading(false);
      },
    });
  };

  // SET FORMS
  const setFormFields = (value) => {
    const record = value;

    form.setFieldsValue({
      room_number: record.room_number,
      room_status_id: record.room_status_id,
      occupancy_status: record.occupancy_status,
    });
  };

  // GET STATUS
  const getStatus = (value) => {
    setStatus(value);

    form.setFieldsValue({
      room_status_id: value,
    });
  };
  const getOcc = (value) => {
    form.setFieldsValue({
      occupancy_status: value,
    });
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (val) => {
    const dataModal = val;

    confirm({
      // icon: <ExclamationCircleTwoTone />,
      className: "modal-confirm",
      title: `Are you sure want to change Room Status of Room Number ${dataModal?.room_number}?`,
      okText: "Confirm",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(dataModal);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    console.log("ON FINISH >>> ", value);

    showModalConfirm(value);
    setIsLoading(true);
  };

  // ON FINISH FAILED
  const onFinishFailed = (error) => {
    console.log("ON FINISH FAILED >>> ", error);

    masterIncomplete(error);
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    form.resetFields();

    setStatus(null);
    setDataStatus(null);

    setOpen(!open);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const contentSubmit = value;

    console.log("Submit >>> ", value);

    await axios
      .put(
        `${baseurl}/room/status`,
        {
          room_status_id: contentSubmit?.room_status_id
            ? contentSubmit.room_status_id
            : dataStatus.room_status_id,
          occupancy_status:
            contentSubmit?.occupancy_status === true ? true : false,
          id: dataStatus?.id ? dataStatus.id : 0,
          updated_by: user_name,
          description: null,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Update Status >> ", response);
        successRoomStatus(response);
        handleCancel();
        fetchData();
      })
      .catch((error) => {
        console.log(error);
        failedRoomStatus(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const queryRoom = searchKey ? searchKey.toString() : null;
    const queryName = searchGuest ? searchGuest.toLowerCase() : null;

    console.log("Type Room = ", queryRoom);
    console.log("Type Name = ", queryName);

    let updatedList = [...data];
    // console.log("Update List = ", updatedList);

    updatedList = updatedList.filter((item) => {
      const filter = item.room_number.toString();
      const name = item?.guest_name ? item?.guest_name.toLowerCase() : "";

      if (queryName != null && queryRoom == null) {
        // console.log("first condition");
        return name.toLowerCase().indexOf(queryName) !== -1;
      } else if (queryName == null && queryRoom != null) {
        // console.log("second condition");
        return filter.indexOf(queryRoom) !== -1;
      } else {
        // console.log("third condition");
        return (
          filter.indexOf(queryRoom) !== -1 &&
          name.toLowerCase().indexOf(queryName) !== -1
        );
      }
    });
    // Trigger render with updated values
    setData(updatedList);
    // console.log("FILTERED => ", updatedList);
  };

  // console.log(filter);

  return (
    <>
      <Row className="room-status-main-table" justify="center" style={mainBody}>
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row justify="end" className="row-refresh-btn">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="room-status-table"
              key="room-status-table"
              name="room-status-table"
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{
                defaultPageSize: 5,
                pageSizeOptions: [10, 20, 50, 100],
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                // y: 270,
                x: 1500,
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
            />
          </Row>
        </Col>
      </Row>

      <Modal
        className="room-status-modal"
        title={
          <Row>
            <FiEdit style={{ fontSize: 24, color: "#3699FF" }} />
            <Typography style={{ marginLeft: 15 }}>
              {`Change Status Room`}
            </Typography>
          </Row>
        }
        centered
        open={open}
        onCancel={handleCancel}
        width={800}
        bodyStyle={{
          borderRadius: 30,
        }}
        footer={null}
      >
        <Form
          name="room-status-modal-form"
          className="room-status-modal-form"
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ padding: "36px 36px 0px 36px" }}
        >
          <Row gutter={30} className="row-room-id">
            <Col span={8}>
              <Form.Item label="Room Number" name="room_number">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Room Status" name="room_status_id">
                <MasterRoomStatus
                  getStatus={getStatus}
                  status={open == true ? dataStatus.room_status : null}
                  status_id={open == true ? dataStatus.room_status_id : 0}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Occupancy" name="occupancy_status">
                <Select
                  className="master-occupancy_status"
                  name="master-occupancy_status"
                  key={"occ"}
                  onChange={getOcc}
                  value={open == true ? dataStatus.occupancy_status : ""}
                  options={[
                    { label: "Occupied", value: true },
                    { label: "Unoccupied", value: false },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={30}
            className="row-modal-btn"
            justify="end"
            align="middle"
            style={{ padding: "15px 0px 30px 0px" }}
          >
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={isLoading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
