// Import React Components
import React, { useState, useEffect } from "react";

// Import Page Components
import { Button, Card, Col, List, Row, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// CODE
export default function TableCategoryCard(props) {
  // PROPS
  const {
    locationCategories,
    selectedData,
    selectedCategory,
    is_loading,
    is_roomService,
  } = props;

  // State Management
  // Data
  const [data, setData] = useState([]);
  const [isRoomService, setIsRoomService] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    // console.log("Romm Service: ", is_roomService);
    if (is_roomService === true && !cleanUp) {
      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    if (locationCategories?.length > 0 && !cleanUp) {
      // setIsLoading(true);
      console.log("Data Source Categories: ", locationCategories);
      const allData = is_roomService ? [
        {
          room_category_code: "",
          room_category_name: "All",
          room_category_id: 0,
          id: 0,
        }] : [
          {
            table_category_code: "",
            table_category_name: "All",
            table_category_id: 0,
            id: 0,
          },
      ];
      handleMap([...allData, ...locationCategories]);
      // handleSelect(selectedData);
    } else {
      setData([]);
    }

    if (is_loading === true && !cleanUp) {
      // console.log("Loading State: ", is_loading);
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
      }, 1250);
    } else {
      setIsLoading(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [locationCategories, is_roomService]);

  // Handle Map
  const handleMap = (val) => {
    const _data = val;
    let _key = 0;

    let _mapped = _data.map((value) => ({
      ...value,
      is_selected: selectedData?.table_category_id == value?.id ? true : false,
      key: _key++,
    }));

    console.log("Mapped: ", _mapped);
    setData(_mapped);
  };

  // Handle Select
  const handleSelect = (value, index) => {
    const _selected = value;
    let _newData = data.map((value) => {
      if (value?.id === _selected?.id) {
        return { ...value, is_selected: true };
      } else {
        return { ...value, is_selected: false };
      }
    });

    // console.log("New Data: ", _newData);
    setData(_newData);

    selectedCategory({
      table_category_code: !isRoomService
        ? _selected?.table_category_code
        : _selected?.room_category_code,
      table_category_id: _selected?.id || _selected?.table_category_id,
      table_category_name: !isRoomService
        ? _selected?.table_category_name
        : _selected?.room_category_name,
    });
  };

  return (
    <>
      <Row
        className="row-table-order"
        style={{ background: "#FFFFFF", borderRadius: 5, margin: "30px 0px" }}
      >
        <Col
          span={24}
          className="main-table-col"
          style={{ padding: "15px 30px" }}
        >
          <Row
            className="cat-row"
            justify="start"
            align="bottom"
            gutter={[0, 15]}
          >
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={24}
              xs={24}
              sm={24}
              className="cat-col"
            >
              <Typography
                className="cat-text"
                key="cat-txt-head"
              >{`Categories`}</Typography>
            </Col>
          </Row>

          <List
            className="cat-btn-list"
            grid={{
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 5,
              xl: 6,
              xxl: 8,
            }}
            loading={isLoading}
            dataSource={data}
            renderItem={(item, index) => (
              <>
                <List.Item className="card-list" key={item?.id}>
                  <Card
                    className="card-btn"
                    key={item?.key || item?.id}
                    hoverable
                    style={{
                      background: item?.is_selected ? "#fa541c" : "#D7DAE7",
                      borderRadius: 10,
                      width: "100%",
                      //   padding: 5,
                    }}
                    bodyStyle={{
                      padding: 10,
                    }}
                    onClick={() => handleSelect(item, index)}
                    loading={isLoading}
                  >
                    <Col span={24} key={item?.id} className={item.id}>
                      <Typography
                        className="cat-text"
                        style={{
                          fontWeight: 500,
                          fontSize: 18,
                          color: item?.is_selected ? "#FFFFFF" : "#000000",
                        }}
                      >
                        {isRoomService
                          ? item?.room_category_name
                          : item?.table_category_name}
                      </Typography>
                    </Col>
                  </Card>
                </List.Item>
              </>
            )}
            style={{
              padding: "15px 0px 0px",
              width: "100%",
            }}
          />
        </Col>
      </Row>
    </>
  );
}
