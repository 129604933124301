// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../../API/Config/Api";

// Context
import { Context as MasterContext } from "../../../../../API/Context/MasterContext/MasterContext";
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
} from "antd";

// Import React Icons
import {
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Page Components
import TableCategoryCard from "../../../../Widgets/Outlet/CategoryCard/CategoryCard";
import TableListCard from "../../../../Widgets/Outlet/TableList/TableList";

// Import Notifications
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../../Style/Global/Global";

// CODE
export default function CaptainOrderTable({ table_number, locationCategories }) {
  // CONTEXT
  const { getTableList } = useContext(MasterContext);
  const { getRoomStatus } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data Table
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [isRoomService, setIsRoomService] = useState(false);

  // Card Button & Categories
  const [categories, setCategories] = useState({
    table_category_code: "",
    table_category_name: "All",
    table_category_id: 0,
    id: 0,
  });

  // Loading State
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  // Get Session Storage
  let labelOutletLocation = sessionStorage.getItem("label_outlet_location");
  let idOutletLocation = sessionStorage.getItem("id_outlet_location");

  useEffect(() => {
    console.log("isRoomService", isRoomService);
  },[isRoomService])

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    console.log("Room Service: ", labelOutletLocation);
    if (labelOutletLocation?.toLowerCase().includes("room") && !cleanUp) {
      setIsRoomService(true);
    } else {
      setIsRoomService(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [labelOutletLocation]);

  // Data Effects
  useEffect(() => {
    let cleanUp = false;

    if (idOutletLocation > 0 && !cleanUp) {
      console.log("Selected: ", idOutletLocation);

      setData([]);
      if(isRoomService) {
        fetchRoom();
      } else {
        fetchAll();
      }
    } else {
      setData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [locationCategories, labelOutletLocation, idOutletLocation, isRoomService]);

  // Fetch Room
  const fetchRoom = async (value) => {
    await getRoomStatus({
      status: "status",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("ISI Room List >> ", response);

        if (response?.data?.msg?.length > 0 || response?.data?.msg != null) {
          const _res = response.data.msg;

          let filtered = _res.filter((val) => {
            if (val?.is_deleted == false || val?.is_deleted == null) {
              return val;
            }
          });
          
          setData(filtered);
          setBulks(filtered);

          if (value === true) {
            handleFilterImmediate(filtered);
          }
        } else {
          setData([]);
          setBulks([]);
        }

        setLoading(false);
        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("ERROR >>>> ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  // Fetch All
  const fetchAll = async () => {
    setLoading(true);
    await axios
      .post(`${baseurl}/pos/table-by-outlet`, {
        outlet_id: idOutletLocation,
      })
      .then((response) => {
        console.log("ALL TABLE >>> ", response);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            return e;
          });
          setData(_res);
        }
      })
      .catch((error) => {
        console.log("Error All Table >>> ", error);

        // failedFetch(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Handle Filter
  const handleFilter = (params) => {
    // const _data = value;
    const _code = params?.toLowerCase();

    console.log("handleFilter>params:", params);
    
    let _filtered = bulks.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Handle Filter >>>", _filtered);
    if(params){
      setData(_filtered);
    } else {
      setData(bulks);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  // Handle Filter
  const handleFilterImmediate = (value) => {
    const _data = value;
    const _code = categories.table_category_code.toLowerCase();

    let _filtered = _data.filter((val) => {
      if (val?.room_category_code.toLowerCase() == _code) {
        return val;
      }
    });

    console.log("Handle Filter Immediate >>>", _filtered);

    setData(_filtered);
  };

  // Handle Refresh
  const handleRefresh = () => {
    const _val = true;
    setIsLoading(true);

    switch (isRoomService) {
      case true:
        fetchRoom(_val);
        break;

      case false:
        if (categories?.table_category_id != 0) {
          fetchFilter(categories?.table_category_id);
        } else {
          fetchAll();
        }
        break;

      default:
        break;
    }
  };

  // Handle Button Card
  const handleButtonCard = (value) => {
    const _data = value;
    setIsLoading(true);

    console.log("Table Category: ", _data);

    setCategories(_data);

    if (isRoomService) {
      handleFilter(_data?.table_category_code);
    } else {
      if (_data?.table_category_id != 0) {
        fetchFilter(_data?.table_category_id);
      } else {
        fetchAll();
      }
    }
  };

  
  // Fetch Filter
  const fetchFilter = async (value) => {
    const _id = value;

    await getTableList({
      type: "table-by-category",
      table_category_id: _id > 0 ? _id : categories.table_category_id,
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Outlet Table Filter: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setData(_res);
        } else {
          setData([]);
        }

        setLoading(false);

        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
        setLoading(false);
        setIsLoading(false);
      },
    });
  };

  // HANDLE TABLE
  const handleTable = (val) => {
    const _number = val;

    console.log("Table Selected --> ", _number);

    table_number(_number);
  };

  return (
    <>
      <TableCategoryCard
        locationCategories={locationCategories}
        selectedCategory={handleButtonCard}
        selectedData={categories}
        is_roomService={isRoomService}
        is_loading={isLoading}
      />

      <Row className="row-table-order" style={mainBody}>
        <Col span={24} className="main-table-col">
          <Row
            className="row-table-order"
            justify="end"
            style={{ padding: 30 }}
          >
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={handleRefresh}
            >
              {`Refresh`}
            </Button>
          </Row>

          <Row
            className="outlet-row"
            align="middle"
            style={{ padding: "0px 30px 30px", width: "100%" }}
          >
            <TableListCard
              dataSource={data}
              table_select={handleTable}
              is_roomService={isRoomService}
              is_loading={isLoading}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
