// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";

// ANTD COMPONENTS
import { Button, Col, Form, Row, Typography } from "antd";
import { ArrowRightOutlined, SyncOutlined } from "@ant-design/icons";

// GLOBAL STYLES
import { mainBody } from "../../../../Style/Global/Global";
import { failedFetch } from "../../../Reusable/Notification/Notification";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../../API/Config/Api";

// PAGE COMPONENTS
import DynamicTables from "./DynamicTables";
import { AccountingParams } from "../../../Reusable/Functions/Parameters/ParamsAcc";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";
import GroupingBill from "../../../Reusable/Functions/GroupingArticle/GroupingBill";
import { BillArticleShow } from "../../../Reusable/Functions/BillType/BillType";

export default function ListBillTable(props) {
  // Props
  const {
    returnArticleData,
    getArticleData,
    getBillReceiver,
    getReturnArticleData,
    type,
    source,
    bill,
    receiver,
  } = props;

  // PARAMS
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // Data
  const [resId, setResId] = useState(null);
  const [detailData, setDetailData] = useState(null);
  // Row Selection
  const [selectedRowArray, setSelectedRowArray] = useState({});
  const [selectedData, setSelectedData] = useState({});
  // Refresh
  const [loading, setLoading] = useState(false);
  // State for dynamic table
  const [dynamic, setDynamic] = useState([]);
  const [key, setKey] = useState(0);
  // USE FORM
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("Selected Data ->> ", selectedData);
  }, [selectedData]);

  // USEFFECT RECEIVERBILL DATA
  useEffect(() => {
    let cleanUp = false;

    if (receiver && !cleanUp) {
      fetchBillReceiverData();
    } else {
      getBillReceiver([]);
      getArticleData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [receiver]);

  // useEffect for new selector
  useEffect(() => {
    let cleanUp = false;

    if (
      source !== -1 &&
      type !== -1 &&
      Object.keys(bill).length > 0 &&
      Object.values(bill).every((arr) => arr.length > 0) &&
      !cleanUp
    ) {
      const allValues = getAllValues(bill);
      const headerBill = flatBill(bill);

      setResId(allValues);
      setDetailData(headerBill);
      handleRefresh(allValues, headerBill);
    } else {
      setResId(null);
      setDetailData(null);
      handleRefresh();
    }

    return () => {
      cleanUp = true;
    };
  }, [source, type, bill]);

  // USEEFFECT RETURN ARTICLE DATA
  useEffect(() => {
    let cleanUp = false;

    if (returnArticleData && !cleanUp) {
      console.log("List Bill Receive Article ->> ", returnArticleData);

      const _returnData = addSelectedData(dynamic, returnArticleData); // new new
      console.log("Return Data ->> ", _returnData);

      setDynamic(_returnData);

      setKey(key + 1);

      // setData((state) => [...state, ...returnArticleData]); // option for check box
      // setData((state) => [...state, returnArticleData]); // option for button move action
    }

    return () => {
      cleanUp = true;
    };
  }, [returnArticleData]);

  // FETCH DETAIL DATA
  const fetchDetailData = async (billValues, headerBill) => {
    setLoading(true);

    await axios
      .post(`${baseurl}/bill/master-folio-res`, {
        reservation_id: billValues,
        source: typeof source == "object" ? [...source] : [source],
      })
      .then((response) => {
        console.log("response fetch detail data: ", response);
        if (response?.data?.msg) {
          const _res = response.data.msg;
          const filteredResponse = _res.filter(
            (item) => item.is_show == false && item.is_deleted == false
          );
          // const mappedResponse = handleManipulateAPIResponse(filteredResponse);
          const mappedResponse = handleDataDetail(filteredResponse);
          // Grouping response by reservation
          const groupingResponse = groupDataByReservation(
            // mappedResponse,
            mappedResponse.article,
            headerBill
          );

          setDynamic(groupingResponse);
        } else {
          setDynamic([]);
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // BILL RECEIVER
  const fetchBillReceiverData = async () => {
    const _bill = {
      Reservation: [receiver],
    };
    const billValues = getAllValues(_bill);
    const headerBill = flatBill(_bill);

    setLoading(true);

    await axios
      .post(`${baseurl}/bill/master-folio-res`, {
        reservation_id: billValues,
        source: [1],
      })
      .then((response) => {
        console.log("response fetch bill receiver: ", response);
        if (response?.data?.msg) {
          const _res = response.data.msg;
          const filteredResponse = _res.filter((item) => item.is_show == false);
          // const mappedResponse = handleManipulateAPIResponse(filteredResponse);
          const mappedResponse = handleDataDetail(filteredResponse);
          // Grouping response by reservation
          const groupingResponse = groupDataByReservation(
            // mappedResponse,
            mappedResponse.article,
            headerBill
            // detailData
          );
          // first index move to Bill Pay List Table
          const firstIndex = groupingResponse[0];

          console.log("MOVE TO BILL PAY LIST ->> ", firstIndex);

          // if split
          if (type == 1) {
            setDynamic(groupingResponse);
          } else {
            getArticleData(firstIndex?.details); // firstIndex move to table pay list
          }

          getBillReceiver(firstIndex); // bill receiver
          // getArticleData(firstIndex?.details); // firstIndex move to table pay list
        } else {
          getBillReceiver([]);

          // getArticleData([]);

          if (type == 1) {
            setDynamic([]);
          } else {
            getArticleData([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handle Data Details
  const handleDataDetail = (value) => {
    const _arr = value;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    const _grouping = BillArticleShow({
      article: _billing,
      bill_type: bill_type,
    });

    // console.log("Billing: ", _billing);
    console.log("Grouping Res: ", _grouping);
    return _grouping;
  };

  // HANDLE GROUPING DATA API RESPONSE FOR CREATE TABLE
  const groupDataByReservation = (dataSource, header) => {
    console.log(
      "GROUPING DATA ->> ",
      header.map((item) => {
        const details = dataSource.filter(
          (data) => data.reservation_id === item.reservation_id
        );
        return {
          ...item,
          details: details,
        };
      })
    );

    return header.map((item) => {
      const details = dataSource.filter(
        (data) => data.reservation_id === item.reservation_id
      );

      // if (details?.length > 0) {
      return {
        ...item,
        details: details,
      };
      // }
    });
  };

  // GET ALL VALUE (RESERVATION ID)
  const getAllValues = (billData) => {
    let result = [];

    for (let key in billData) {
      if (billData.hasOwnProperty(key)) {
        // get array from each category
        const categoryArray = billData[key];

        categoryArray.forEach((item) => {
          result.push(item?.reservation_id);
        });
      }
    }

    return result;
  };

  // FLAT MAP BILL
  const flatBill = (billData) => {
    let result = [];

    for (let key in billData) {
      if (billData.hasOwnProperty(key)) {
        result = result.concat(billData[key]);
      }
    }

    return result;
  };

  // HANDLE REFRESH
  const handleRefresh = (billValues = [], headerBill = []) => {
    getArticleData([]);
    getReturnArticleData(null);

    if (receiver) {
      fetchBillReceiverData();
    }

    if (billValues?.length > 0 && headerBill?.length > 0) {
      fetchDetailData(billValues, headerBill);
    } else {
      setDynamic([]);
      getBillReceiver([]);
    }
  };

  // ADD SELECTED DATA FROM TABLE BILL PAY LIST TO BILL LIST
  const addSelectedData = (sourceData, selectedData) => {
    // Loop melalui setiap elemen di selectedData
    selectedData.forEach((selectedItem) => {
      // Cari elemen di sourceData yang memiliki reservation_id yang sama dengan selectedItem
      const target = sourceData.find(
        (sourceItem) =>
          sourceItem.reservation_id === selectedItem.reservation_id
      );

      // Jika ditemukan elemen yang sesuai, tambahkan selectedItem ke dalam details-nya
      if (target) {
        target.details.push(selectedItem);
      }
    });

    return sourceData;
  };

  // REMOVE SELECTED DATA FROM TABE BILL LIST WHEN MOVE BTN CLICKED
  const removeSelectedData = (sourceData, selectedData) => {
    selectedData.forEach((selectedItem) => {
      sourceData.forEach((sourceItem) => {
        sourceItem.details = sourceItem.details.filter(
          (detail) => detail.id !== selectedItem.id
        );
      });
    });

    return sourceData;
  };

  // FLATMAP SELECTED DATA
  const mergeData = (data) => {
    const keys = Object.keys(data);
    const result = keys.flatMap((key) => data[key]);
    return result;
  };

  // HANDLE MOVE DATA (CheckBox Option)
  const handlePick = () => {
    const _mappedData = mergeData(selectedData); // selected data from check box
    const sourceData = dynamic; // source data
    // filter Source Data
    const filteredData = removeSelectedData(sourceData, _mappedData);
    // // Set Data on Table Bill List by FilteredData
    setDynamic(filteredData);
    // // Set Data on Table Bill Pay List by Selected Data
    getArticleData((state) => [...state, ..._mappedData]);
    // // Reset Selected Data & Selected Row Array
    setSelectedData({});
    setSelectedRowArray({});
  };

  // HANDLE DISABLED MOVE BUTTON
  const isButtonDisabled =
    Object.keys(selectedData).length === 0 ||
    Object.values(selectedData).every((arr) => arr.length === 0);

  return (
    <>
      <Row
        className="master-folio-main-table"
        justify="center"
        style={mainBody}
      >
        <Col span={24} className="col-master-folio" style={{ padding: 30 }}>
          <Row
            className="row-modal-btn"
            justify="end"
            // style={{ marginTop: 30 }}
          >
            <Col span={8} className="col-exp-btn">
              <Typography
                style={{
                  fontSize: 17,
                  fontWeight: 750,
                }}
              >
                Bill List
              </Typography>
            </Col>

            <Col span={16}>
              <Row justify="end">
                <Button
                  className="next-btn"
                  type="primary"
                  // disabled={Object.keys(selectedData).length === 0}
                  disabled={isButtonDisabled}
                  onClick={handlePick}
                  // icon={
                  //   <PiFileArchiveFill
                  //     style={{
                  //       margin: "0px 5px 0px 0px",
                  //       fontSize: 20,
                  //     }}
                  //   />
                  // }
                  style={{
                    // display: "flex",
                    // padding: "5px 10px 10px 5px",
                    marginRight: "15px",
                    // minWidth: 100,
                    borderColor: "transparent",
                  }}
                >
                  {`Move`}
                  <ArrowRightOutlined />
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={() => (resId ? handleRefresh() : null)}
                  onClick={() => handleRefresh(resId, detailData)}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row
            className="row-table"
            style={{ marginTop: 15, width: "100%" }}
            key={key}
          >
            {dynamic?.length > 0 ? (
              <DynamicTables
                tableData={dynamic}
                isLoading={loading}
                getSelectedData={setSelectedData}
                selectedRows={selectedRowArray}
                setSelectedRows={setSelectedRowArray}
              />
            ) : null}
          </Row>
        </Col>
      </Row>
    </>
  );
}
