// REACT COMPONENTS
import { useEffect, useState } from "react";
import moment from "moment";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// PAGE COMPONENTS
import GroupingBill from "../../Reusable/Functions/GroupingArticle/GroupingBill";
import GroupingArticle from "../../Reusable/Functions/GroupingArticle/GroupingArticle";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";

// REACT ICONS
import { RiBillFill } from "react-icons/ri";

// GLOBAL STYLES
import { mainBody, textForm } from "../../../Style/Global/Global";

export default function DetailRelatedBillModal(props) {
  // PROPS
  const { openModal, closeModal, billData } = props;

  // PARAMS
  const currency = CurrencySymbols().code;
  const accounting = AccountingParams();
  const bill_type = FrontOfficeParams().bill_type;

  // STATE
  // DATA
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({
    grand: 0,
    total: 0,
    tax: 0,
    service: 0,
  });
  // BOOLEAN
  const [isLoading, setIsLoading] = useState(true);

  // USE FORM
  const [form] = Form.useForm();

  // USEEFFECT GUEST DATA
  useEffect(() => {
    let cleanUp = false;

    if (billData && !cleanUp) {
      console.log("GUESTT DATAA ->> ", billData);
      const manipulatedResponse = handleManipulateResponse(billData?.details);
      setData(manipulatedResponse);
    }

    return () => {
      cleanUp = true;
    };
  }, [billData]);

  // HANDLE MANIPULATE API RESPONSE
  // MAKE RESPONSE TO 1 ROW (Article, Tax, Service)
  const handleManipulateResponse = (array) => {
    const _arr = array;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    const _grouping = GroupingArticle(_billing);
    // Showing Bill Type
    const totals = BillArticleShow({
      article: _grouping,
      bill_type: bill_type,
    });

    console.log("Billing: ", _billing);
    console.log("Grouping Res: ", _grouping);
    console.log("Grouping Ttoals: ", totals);

    setTotal({
      grand: totals.total.total_price,
      service: totals.total.service,
      tax: totals.total.tax,
      total: totals.total.unit_price,
    });

    return totals.article;
  };

  // HANDLE CLOSE
  const handleClose = () => {
    closeModal(false);
  };

  // TBALE COLUMNS
  const columns = [
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: `Quantity`,
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Price( ${currency} )`,
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        return `${price * record?.quantity}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
  ];

  return (
    <Modal
      className="bill-outstanding-modal"
      key="bill-outstanding-modal"
      title={
        <>
          <Row className="modal-title-row" justify="start" align="middle">
            <RiBillFill
              className="modal-icon"
              style={{
                color: "#3699FF",
                fontSize: 24,
              }}
            />
            <Typography
              className="modal-txt"
              style={{ marginLeft: 15 }}
            >{`Related Bill Detail`}</Typography>
          </Row>
        </>
      }
      centered
      // confirmLoading={isLoading}
      footer={null}
      open={openModal}
      closable
      onCancel={handleClose}
      width={1000}
    >
      <Form
        className="bill-detail-form"
        key="bill-detail-form"
        name="bill-detail-form"
        labelAlign="left"
        form={form}
        labelCol={{
          span: 12,
        }}
        labelWrap={{
          span: 12,
        }}
        layout="horizontal"
      >
        <Row className="identity-row" style={{ marginBottom: 20 }}>
          <Col span={24} className="identity-col">
            <Row className="ident-row" gutter={30}>
              <Col span={12} className="name-col">
                <Form.Item
                  label="Order ID"
                  className="name-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="guest-name-text" style={textForm}>
                    {billData?.order_id_alias ?? "-"}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Source"
                  className="name-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="arrival-text" style={textForm}>
                    {billData?.source ?? "-"}
                  </Typography>
                </Form.Item>
              </Col>

              <Col span={12} className="count-col">
                <Form.Item
                  label="Trx. Date"
                  className="name-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="reservation-text" style={textForm}>
                    {billData?.date
                      ? moment(billData?.date).format("DD-MM-YYYY, h:mm")
                      : "-"}
                  </Typography>
                </Form.Item>

                <Form.Item
                  label="Room Number"
                  className="payment-item"
                  style={{ margin: 0 }}
                >
                  <Typography className="payment-text" style={textForm}>
                    {billData?.room_number ?? "-"}
                  </Typography>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Divider
        className="form-divider"
        style={{ margin: "0px 0px 20px", background: "#EBEDF3" }}
      />

      <Row className="table-main-row" justify="start" style={mainBody}>
        <Col span={24}>
          <Row style={{ width: "100%" }}>
            <Typography
              style={{
                fontSize: 17,
                fontWeight: 750,
                marginBottom: 10,
              }}
            >
              {`Bill List`}
            </Typography>

            <Table
              className="bill-detail-table"
              name="bill-detail-table"
              key="bill-detail-table"
              columns={columns}
              dataSource={data}
              // loading={isLoading}
              bordered={true}
              size="small"
              pagination={false}
              scroll={{
                x: true,
              }}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              rowKey={(record) => {
                return record?.article_id > 0 ? record.article_id : record?.key;
              }}
              summary={() => {
                return (
                  <>
                    <Table.Summary fixed>
                      {bill_type ? (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4} />

                            <Table.Summary.Cell index={4}>
                              <b>Subtotal</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={5} align="right">
                              {`${currency} ${
                                total.total > 0 ? total.total : 0
                              }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4} />

                            <Table.Summary.Cell index={4}>
                              <b>Service</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={5} align="right">
                              {`${currency} ${total?.service || 0}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={4} />

                            <Table.Summary.Cell index={4}>
                              <b>Tax</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={5} align="right">
                              {`${currency} ${total?.tax || 0}`.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      ) : null}

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={4} />

                        <Table.Summary.Cell index={4}>
                          <b style={{ fontSize: 15 }}>Grand Total</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={5} align="right">
                          <b style={{ fontSize: 15 }}>
                            {`${currency} ${
                              total.grand > 0 ? total.grand : 0
                            }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>

      <Row justify="end" align="middle" style={{ marginTop: 30 }}>
        <Button className="cancel-btn" type="default" onClick={handleClose}>
          {`Close`}
        </Button>
      </Row>
    </Modal>
  );
}
