// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Table,
  Space,
  Button,
  Col,
  Row,
  Tag,
} from "antd";

// Import React Icons
import {
  SyncOutlined,
} from "@ant-design/icons/lib/icons";

// Import Notification
import { failedFetch } from "../../../../Components/Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import { LocalizationDate } from "../../../../Components/Reusable/Functions/Localizations/Localization";

// CODE
export default function KitchenHeaderTable({
  selectedData,
  is_refresh,
  is_created
}) {

  // CONTEXT
  const { getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [guestData, setGuestData] = useState([]);

  // Table Columns
  const columnsArticle = [
    {
      title: "Order ID",
      dataIndex: "order_id_alias",
      key: "order_id_alias",
      render: (text) => text,
    },
    {
      title: "Order Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (text) => {
        return moment(LocalizationDate(text)).format("YYYY-MM-DD, HH:mm");
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => text,
    },
    {
      title: "Sales Type",
      dataIndex: "sales_type",
      key: "sales_type",
      render: (text, record) => {
        const salesTypeMap = {
          "1": `Dine-In (${record.table_id})`,
          "2": "Take Away",
          "3": "Online Order",
        };
        
        return salesTypeMap[text] || "-";
      },
    },
    {
      title: "Status",
      dataIndex: "kitchen_status",
      key: "kitchen_status",
      render: (text, record) => {
        let color = "";
        let txt = "";

        switch (text) {
          case 1:
            color = "#2db7f5";
            txt = "Pending";
            break;
          case 2:
            color = "#87d068";
            txt = "Completed";
            break;

          default:
            color = "#d9d9d9";
            txt = "-";
            break;
        }

        return (
          <Tag color={color}>
            {txt ? txt.toUpperCase() : "-"}
          </Tag>
        );
      },
    },
  ].filter((item) => !item.hidden);

  // FETCH Data Header
  const fetchDataHeader = async () => {
    await axios
      .get(`${baseurl}/pos/order-header`, {})
      .then((response) => {
        console.log("HEADER FOR SAVED BILL: ", response);
        console.log("response.data?.msg?.length", response.data?.msg?.length);

        if (response.data?.msg?.length > 0) {
          let _res = response.data.msg?.filter((e) => {
            if (
              e.is_deleted == false &&
              !e.is_paid &&
              e.bill_status != 2 &&
              e.order_status == false
            ) {
              return e;
            }
          });

          console.log("fetchDataHeader>_res:", _res);

          setData(_res);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {});
  };

  // FETCH DATA
  const fetchDataGuestInHouse = async () => {
    await getGuestInHouse({
      guestInHouse: "guest-in-house",
      type: "stay",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setGuestData(filtered);
          // setBulks(filtered);
        } else {
          setGuestData([]);
          // setBulks([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT Data
  useEffect(() => {
    fetchDataHeader();
    fetchDataGuestInHouse();
  }, []);

  useEffect(() => {
    console.log("DATA>", data);
  },[data]);

  useEffect(() => {
    fetchDataHeader();
    fetchDataGuestInHouse();
  }, [is_created]);

  // Handle Row Selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`);
      console.log("selectedRows: ", selectedRows);
      console.log("guestData: ", guestData);
      // setRateCode(selectedRows);
      // getRateCode(selectedRows);
      let filterGuestData = selectedRows.length > 0 ? guestData.filter((x) => {
        if(x.reservation_id == selectedRows[0]?.reservation_id && x.guest_name == selectedRows[0]?.name){
          return true
        } else {
          return false
        }
      }) : [];

      console.log("filterGuestData: ", filterGuestData);

      let room_number = filterGuestData.length > 0 ? filterGuestData[0]?.room_number : null;

      console.log("room_number", room_number);

      selectedData(
        selectedRows?.length > 0 ? {...selectedRows[0], room_number : room_number} : []
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const handleRefresh = () => {
    fetchDataHeader();
    is_refresh();
  }

  return (
    <>
      <Row
        className="journal-header-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          <Row className="row-table-btn" justify="start">
            <Col className="col-text" span={12}>
              <Space
                className="title-spacetxt"
                align="center"
                size={"large"}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {`Order List`}
              </Space>
            </Col>

            <Col className="col-btn" span={12}>
              <Row justify="end">
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="order-list-table"
              name="order-list-table"
              key="order-list-table"
              columns={columnsArticle}
              dataSource={data}
              // dataSource={dataArticles}
              bordered
              // loading={loading}
              size="medium"
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              pagination={{
                pageSizeOptions: [10, 25, 50, 100],
                defaultPageSize: 10,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? "even" : "odd";
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
