// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// Import Ant Design Components
import {
  Button,
  Col,
  Input,
  Layout,
  Row,
  Typography,
  Space,
  DatePicker,
  Select,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { TbReport } from "react-icons/tb";

// Import Page Components
import ReportOutletTable from "../../../Components/Table/Outlet/Report/Table";

// Import Global Styles
import { cardBody, cardHeader } from "../../../Style/Global/Global";
import { IoReturnUpBackOutline } from "react-icons/io5";
import ModalOutletLocation from "../../../Components/Modals/Outlet/OutletLocation/Location/CaptainOrderModal";
import MasterPaymentTypeEdit from "../../../Components/Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterPaymentCardEdit from "../../../Components/Reusable/Master/PaymentCard/PaymentCardEdit";

// CODE
export default function ReportOutletPage() {
  // CONTEXT
  const { getParameters } = useContext(MasterContext);

  // Variable
  const [outletLocation, setOutletLocation] = useState("UNSELECTED");

  // State
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);

  // Filter
  const [shiftOptionValue, setShiftOptionValue] = useState([]);

  // State Filter
  const [date, setDate] = useState([]);
  const [shift, setShift] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [userName, setUserName] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  // USE NAVIGATE
  const navigate = useNavigate();

  // USE LOCATION
  const { pathname } = useLocation();

  // Date Picker
  const { RangePicker } = DatePicker;

  // Data order yang akan difilter
  const orders = [
    {
      order_id: "ORD001",
      date: "2024-10-20",
      sales_type: "Online",
      table_code: "",
      customer_name: "John Doe",
      payment_type: "Credit Card",
      payment_details: "BCA - IDR",
      shift: "1",
      user_name: "Jane Smith",
      location: "Outlet A",
      price: 150000
    },
    {
      order_id: "ORD002",
      date: "2024-10-21",
      sales_type: "Dine-in",
      table_code: "T10",
      customer_name: "Alice Johnson",
      payment_type: "IDR Cash",
      payment_details: "Cash - IDR",
      shift: "2",
      user_name: "Tom Williams",
      location: "Outlet A",
      price: 250000
    },
    {
      order_id: "ORD003",
      date: "2024-10-22",
      sales_type: "Online",
      table_code: "",
      customer_name: "Bob Brown",
      payment_type: "Debit",
      payment_details: "BNI - IDR",
      shift: "3",
      user_name: "Lisa Adams",
      location: "Outlet A",
      price: 300000
    }
  ];

  // USE EFFECT
  useEffect(() => {
    let currentOutletLoc = sessionStorage.getItem("label_outlet_location"); 
    let currentOutletID = sessionStorage.getItem("id_outlet_location"); 
    
    if (currentOutletLoc && currentOutletID) {
      setOpen(false);
      setOutletLocation(currentOutletLoc);
      setIsShow(true);
      fetchWorkHourData();
    } else {
      setIsShow(false);
      setOpen(true);
    }
  },[]);

  // Fetch Data Work Hour
  const fetchWorkHourData = async () => {
    await getParameters({
      params: "parameters",
      type: "shift",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Shift --> ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let _filter = _res.filter((value) => {
            return value.is_active === true;
          });

          let _filtered = _filter.map((value) => ({
            label: value?.shift_name,
            value: value?.id,
            code: value?.shift_id,
          }));
      
          // console.log("Filtered: ", _filtered);
          setShiftOptionValue(_filtered);
        }
      },
      onReject: (error) => {
        console.log("Params Shift --> ", error);
        // failedFetch(error);
      },
    });
  };

  // Handle Return
  const handleNavigateReturn = () => {
    navigate(`${pathname}`.substring(0, pathname.length - 14), {
      state: {},
    });
  };

  // Handle Open
  const handleOpen = () => {
    setOpen(true);
  };

  // Handle Select
  const handleSelect = (value) => {
    console.log("Selected Data: ", value);

    if (value?.outlet_location && value?.outlet_id > 0) {
      setOutletLocation(value.label_outlet_location);
      sessionStorage.setItem("label_outlet_location", value?.label_outlet_location);
      sessionStorage.setItem("id_outlet_location", value?.outlet_id);
      setIsShow(true);
      fetchWorkHourData();
    }
  };

  // Handle Date Range Picker
  const handleDate = (dates, dateString) => {
    console.log("dateString", dateString);
    if(dateString[0] && dateString[1]){
      setDate(dateString);
    } else {
      setDate([]);
    }
  };

  // Handle Shift
  const handleShift = (e) => {console.log("shift", e); setShift(e);};

  // Handle Clear
  const handleShiftClear = () => {
    setShift(null);
  };

  // Handle OrderId
  const handleOrderId = (e) => {
    console.log("orderId", e.target.value);
    setOrderId(e.target.value);
  };

  // Handle Customer Name
  const handleCustomerName = (e) => {
    console.log("customerName", e.target.value);
    setCustomerName(e.target.value);
  };

  // Handle User Name
  const handleUserName = (e) => {
    console.log("userName", e.target.value);
    setUserName(e.target.value);
  };

  // Payment Type
  const handlePaymentType = (value) => {
    console.log("paymentType", value);
    if(value?.name){
      setPaymentType(value);
    } else {
      setPaymentType(null);
      setPaymentDetails(null);
    }
  };

  // Payment Details
  const handlePaymentDetails = (value) => {
    console.log("paymentDetails", value);
    if(value?.name){
      setPaymentDetails(value);
    } else {
      setPaymentDetails(null)
    }
  };

  // Fungsi untuk memfilter orders berdasarkan nilai dari input form
  const filteredOrders = orders.filter(order => {
    const orderDate = order.date;
    const isWithinDateRange = date.length === 0 || 
      (orderDate >= date[0] && orderDate <= date[1]);

    return (
      isWithinDateRange
      && ((orderId == null) || order.order_id.toUpperCase().includes(orderId.toUpperCase())) &&
      ((customerName == null) || order.customer_name.toUpperCase().includes(customerName.toUpperCase())) &&
      ((userName == null) || order.user_name.toUpperCase().includes(userName.toUpperCase())) &&
      (shift == null || order.shift.includes(shift)) && 
      ((paymentType == null) || order.payment_type.toUpperCase().includes(paymentType.name?.toUpperCase())) &&
      ((paymentDetails == null) || order.payment_details.toUpperCase().includes(paymentDetails.name?.toUpperCase()))
    );
  });

  return (
    <Layout>
      <Content>
        <Col span={24} className="report-outlet-main-layout">
          <Row className="row-title" justify="start" style={cardHeader}>
            <TbReport
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              Report Outlet
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            wrap={true}
            style={cardBody}
          >
            <Col
              style={{width: "60%"}}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: "10px 30px 15px",
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <RangePicker
                    placeholder={["Start Date", "End Date"]}
                    onChange={handleDate}
                    style={{width: "388px"}}
                  />
                </Col>
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    width: "173px"
                  }}
                >
                  <Select
                    className="shift-select"
                    key="shift-select"
                    placeholder="Choose Work Hour"
                    allowClear
                    showSearch
                    value={shift}
                    onChange={handleShift}
                    onClear={handleShiftClear}
                    options={shiftOptionValue}
                    style={{
                      width: "100%",
                    }}
                  />
                </Col>
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Input onChange={handleOrderId} value={orderId} placeholder="Order ID" />
                </Col>
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Input onChange={handleCustomerName} value={customerName} placeholder="Customer Name" />
                </Col>
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Input onChange={handleUserName} value={userName} placeholder="User Name" />
                </Col>
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <MasterPaymentTypeEdit
                    getPaymentType={handlePaymentType}
                    pay={null}
                  />
                </Col>
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <MasterPaymentCardEdit
                    getPayCard={handlePaymentDetails}
                    getPayTypeId={paymentType?.id}
                    payCard={null}
                    getCode={() => {}}
                  />
                </Col>
              </Space>
            </Col>
            <Col style={{width: "40%", display: "flex", alignItems: "end"}}>
              <Space
                // align="end"
                size={15}
                className="header-space"
                // wrap={true}
                style={{
                  padding: "0px 0px 15px",
                  display: "flex", alignItems: "end"
                }}
              >
                <Col
                  className="location-col"
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Typography
                    style={{ marginBottom: 10 }}
                  >{`Location`}</Typography>
                  <Button
                    className="submit-btn"
                    onClick={handleOpen}
                    type="primary"
                    style={{
                      width: 205,
                    }}
                  >
                    {outletLocation}
                  </Button>
                </Col>
                <Col
                  className="btn-col"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleNavigateReturn}
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {`Return`}
                  </Button>
                </Col>
              </Space>
            </Col>
          </Row>

          {isShow ? (
            <ReportOutletTable reportData={filteredOrders}/>
          ) : null}

        </Col>
        <ModalOutletLocation
          closeModal={setOpen}
          openModal={open}
          getLocs={handleSelect}
        />
      </Content>
    </Layout>
  );
}
