// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment, { invalid } from "moment";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// Context
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Tag,
  Tooltip,
  Tabs,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined } from "@ant-design/icons";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { failedFetch } from "../../../Reusable/Notification/Notification";
import { BiSolidPrinter } from "react-icons/bi";
import { FaClipboardList } from "react-icons/fa";
import { TbFileDollar } from "react-icons/tb";

// Import Page Components
import HistoryGuestPopover from "../../../Popover/HistoryGuest/Popover";
import Invoice from "../../../Forms/Invoice/Invoice";

// Import Functions
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";
import { AccountingParams } from "../../../Reusable/Functions/Parameters/ParamsAcc";
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// CODE
export default function HistoryGuestTable(props) {
  // PROPS
  const { is_search, searchKey, searchDate } = props;

  // CONTEXT
  const { getHistoryGuest } = useContext(MainContext);

  // PARAMS
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [selected, setSelected] = useState(null);
  const [detail, setDetail] = useState(null);
  const [payAndInvo, setPayAndInvo] = useState({
    invoice: null,
    payment: {},
  });
  // Active Key Tab Pane
  const [activeKey, setActiveKey] = useState("1");
  // Boolean
  const [invoice, setInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // Fetch Data
  const fetchData = async (value) => {
    const _active = value?.source > 0 ? value.source : activeKey;

    setLoading(true);

    if (_active == 1) {
      await getHistoryGuest({
        guest: "guest",
        type: "checkout-stay",
        onAwait: () => {},
        onSuccess: (response) => {
          console.log("Response History => ", response);

          if (response.data?.msg?.length > 0) {
            const _res = response.data.msg;

            const _filtered = _res.filter((filt) => {
              if (!filt.is_deleted || filt.is_deleted == null) {
                return filt;
              }
            });

            setData(_filtered);
            setBulks(_filtered);
          } else {
            setData([]);
            setBulks([]);
          }

          setLoading(false);
        },
        onReject: (error) => {
          console.log(error);

          setLoading(false);
          failedFetch(error);
        },
      });
    } else {
      await axios
        .get(`${baseurl}/invoice/payment-header-history`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log("Response: ", response);

          if (response.data?.msg?.length > 0) {
            const _res = response.data.msg;

            const _filtered = _res.filter((filt) => {
              if (!filt.is_deleted || filt.is_deleted == null) {
                return filt;
              }
            });

            setData(_filtered);
            setBulks(_filtered);
          } else {
            setData([]);
            setBulks([]);
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          failedFetch(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Fetch Data Detail
  const fetchDetail = async (val) => {
    await axios
      .post(
        `${baseurl}/invoice/payment-detail-history-by-invoice`,
        {
          invoice_id: val?.invoice_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Details: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _filtered = _res.filter((filt) => {
            if (!filt.is_deleted || filt.is_deleted == null) {
              return filt;
            }
          });

          setDetail(_filtered);
        } else {
          setDetail(null);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Use Effect
  useEffect(() => {
    fetchData({ source: parseInt(activeKey) });
  }, []);

  // Use Effect
  useEffect(() => {
    let cleanUp = false;

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);
  }, [searchKey, searchDate]);

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      render: (_, record) => {
        return (
          <>
            <Row className="action-table-btn" style={{ width: "100%" }}>
              <Col span={24} className="col-menu">
                <Tooltip title="Option" className="option-tooltip">
                  <HistoryGuestPopover
                    details={record}
                    is_refresh={setRefresh}
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      width: 150,
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.reservation_id.localeCompare(b?.reservation_id),
      render: (_, record) => {
        if (record.group == 2) {
          return "";
        } else {
          return record.reservation_id;
        }
      },
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      width: 200,
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.guest_name.localeCompare(b?.guest_name),
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.room_number - b?.room_number,
    },
    {
      title: "Breakfast",
      dataIndex: "is_abf",
      key: "is_abf",
      align: "center",
      width: 95,
      render: (abf) => {
        return (
          <Tag key={"abf-keytag"} color={abf === true ? `green` : `geekblue`}>
            {abf === true ? `RB` : `RO`}
          </Tag>
        );
      },
      // width: 150,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            className="guest-tag"
            key={gst}
            color={clr}
            style={{ fontSize: 18, fontWeight: 600 }}
          >
            {gst}
          </Tag>
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.night - b?.night,
    },
    {
      title: "Late C/O Status",
      dataIndex: "is_late_checkout",
      key: "is_late_checkout",
      align: "center",
      render: (show, record) => {
        return show == true ? (
          <Tooltip key={record.id} title={`Guest Requested for Late Check-Out`}>
            <CheckCircleOutlined
              key={record.id}
              style={{
                color: "#389e0d",
                fontSize: 20,
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip key={record.id} title={`Not Listed as Late Check-Out`}>
            <CloseCircleOutlined
              key={record.id}
              style={{
                color: "#cf1322",
                fontSize: 20,
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      // width: 100,
      sortDirections: ["descend", "ascend"],
      render: (arrive, record) => {
        return moment(arrive).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a?.arrival) - moment(b?.arrival),
    },
    {
      title: "Check-In Time",
      dataIndex: "checkin_date",
      key: "checkin_date",
      width: 200,
      sortDirections: ["descend", "ascend"],
      render: (checkin, record) => {
        return moment(LocalizationDate(checkin)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
      sorter: (a, b) => moment(a?.checkin_date) - moment(b?.checkin_date),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      // width: 100,
      sortDirections: ["descend", "ascend"],
      render: (arrive, record) => {
        return moment(arrive).format("DD MMM YYYY");
      },
      sorter: (a, b) => moment(a?.arrival) - moment(b?.arrival),
    },
    {
      title: "Check-Out Time",
      dataIndex: "checkout_date",
      key: "checkout_date",
      width: 200,
      sortDirections: ["descend", "ascend"],
      render: (checkout, record) => {
        return moment(LocalizationDate(checkout)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
      sorter: (a, b) => moment(a?.checkout_date) - moment(b?.checkout_date),
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.adult.localeCompare(b?.adult),
    },
    // {
    //   title: "Child",
    //   dataIndex: "child",
    //   key: "child",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.child.localeCompare(b.child),
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "infant",
    //   key: "infant",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.infant.localeCompare(b.infant),
    // },
    {
      title: "Room Rate",
      dataIndex: "rate_code",
      key: "rate_code",
    },
    {
      title: "Group Name",
      dataIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      render: (group, record) => {
        let grp = "";
        let clr = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            clr = "#52c41a";
            break;

          case 1:
            grp = "Group Leader";
            clr = "#1677ff";
            break;

          case 2:
            grp = "Group Member";
            clr = "#8c8c8c";
            break;

          default:
            break;
        }

        return (
          <Tag key={"key-tag"} color={clr}>
            {grp}
          </Tag>
        );
      },
    },
  ];

  // Columns Invoice
  const columnInvoice = [
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      fixed: "left",
      align: "center",
      render: (_, record) => {
        return (
          <Tooltip key="tooltip-action" title="Show Invoice">
            <BiSolidPrinter
              className="action-icon"
              onClick={() => handleInvoice(record)}
              style={{
                color: "#3699FF",
                fontSize: 24,
                cursor: "pointer",
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Invoice No.",
      dataIndex: "invoice_id",
      key: "invoice_id",
      width: 150,
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.invoice_id.localeCompare(b?.invoice_id),
    },
    {
      title: "Bill No.",
      dataIndex: "bill_master",
      key: "bill_master",
      width: 150,
      // fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.bill_master.localeCompare(b?.bill_master),
    },
    {
      title: "Guest Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Bill Type",
      dataIndex: "bill_status",
      key: "bill_status",
      align: "center",
      render: (stats) => {
        let txt = "";
        let clr = "#f5222d";

        switch (stats) {
          case 1:
            txt = "NORMAL BILL";
            clr = "#ffec3d";

            break;

          case 2:
            txt = "SPLIT BILL";
            clr = "#2f54eb";

            break;

          case 3:
            txt = "JOIN BILL";
            clr = "#52c41a";

            break;

          default:
            txt = "ERROR";
            break;
        }

        return (
          <Tag
            key={"tags-key"}
            color={clr}
            style={{
              color: stats == 1 ? "#000000" : "#FFF",
            }}
          >
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (date) => {
        return date ? moment(date).format("DD-MM-YYYY") : " - ";
      },
    },
    {
      title: "Payment Status",
      dataIndex: "is_paid",
      key: "is_paid",
      align: "center",
      render: (bool) => {
        let txt = "";
        let clr = "grey";

        switch (bool) {
          case true:
            txt = "PAID";
            clr = "#52c41a";
            break;

          case false:
            txt = "UNPAID";
            clr = "#f5222d";
            break;

          default:
            txt = "UNKNOWN";
            break;
        }

        return (
          <Tag key={"tags-key"} color={clr}>
            {txt}
          </Tag>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      render: (res) => {
        return typeof res == "object" && res?.length > 0 ? res.join(", ") : res;
      },
    },
    {
      title: `Total ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      width: 150,
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // TABS KEYS
  const onKeyChange = (key) => {
    const keys = parseInt(key);
    setActiveKey(key);
    setData([]);

    fetchData({
      source: keys,
    });
  };

  // Handle Invoice
  const handleInvoice = async (value) => {
    const _data = value;
    // console.log("Data: ", _data);

    setSelected(_data);
    setInvoice(true);
    setPayAndInvo({
      invoice: _data?.invoice_id,
      payment: {
        payment_type: _data?.payment_type_name ? _data.payment_type_name : null,
        payment_details: _data?.payment_card_name
          ? _data.payment_card_name
          : null,
      },
    });

    await fetchDetail(_data);
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    setLoading(true);

    const query = searchKey ? searchKey.toLowerCase() : null;
    const depart = searchDate ? moment(searchDate).format("YYYY-MM-DD") : null;

    // console.log("Type = ", query);
    // console.log("Departure date = ", searchDate, " + ", depart);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        // console.log("ITEM => ", item)

        if (activeKey == 1) {
          let _name = item?.guest_name ? item.guest_name.toLowerCase() : null;

          return (
            item.guest_category != "INCOGNITO" && _name.indexOf(query) !== -1
          );
        } else {
          let _name = item?.full_name ? item.full_name.toLowerCase() : null;

          return _name && _name.indexOf(query) !== -1;
        }
      });

      // Trigger render with updated values
      if (depart != "Invalid date" && depart != null) {
        handleDeparture(updatedList?.length > 0 ? updatedList : bulks, depart);
      } else {
        setData(updatedList);
        setLoading(false);
      }
    } else {
      setData([]);
      setLoading(false);
    }
  };

  // FILTER BY DATE
  const handleDeparture = (value, date) => {
    let _newData = value.filter((items) => {
      const dep = moment(items?.departure).format("YYYY-MM-DD");

      return moment(dep).isSame(date);
    });

    console.log("Date: ", _newData);

    setData(_newData);
    setLoading(false);
  };

  // Handle Close
  const handleClose = (val) => {
    setInvoice(false);

    setSelected(null);

    setDetail([]);
    setPayAndInvo({
      invoice: null,
      payment: {},
    });
  };

  const items = [
    {
      label: (
        <>
          <Row
            className="row-first-tab"
            justify="start"
            align="middle"
            gutter={30}
            style={{
              height: "100%",
              width: 200,
              border: activeKey == 1 ? "1px solid #69c0ff" : "none",
              margin: 0,
              borderRadius: 4,
            }}
          >
            <Col className="tab-col">
              <Button
                className="1st-icon-tab-reservation-btn"
                icon={<FaClipboardList />}
                disabled={activeKey == 1 ? false : true}
                type="primary"
              />
            </Col>

            <Col
              style={{
                marginTop: 15,
              }}
            >
              <p>{`Departed Guest`}</p>
            </Col>
          </Row>
        </>
      ),
      key: "1",
      children: (
        <>
          <Table
            className="history-guest-table"
            name="history-guest-table"
            key="history-guest-table"
            loading={loading}
            columns={columns}
            dataSource={data}
            bordered
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [5, 10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            scroll={{
              x: 2500,
            }}
            rowKey={(record) => {
              return record.id ? record.id : record.key;
            }}
          />
        </>
      ),
    },
    {
      label: (
        <>
          <Row
            className="row-first-tab"
            justify="start"
            align="middle"
            gutter={30}
            style={{
              height: "100%",
              width: 200,
              border: activeKey == 2 ? "1px solid #69c0ff" : "none",
              margin: 0,
              borderRadius: 4,
            }}
          >
            <Col className="tab-col">
              <Button
                className="1st-icon-tab-reservation-btn"
                disabled={activeKey == 2 ? false : true}
                icon={<TbFileDollar />}
                type="primary"
              />
            </Col>

            <Col
              style={{
                marginTop: 15,
              }}
            >
              <p>{`Invoice List`}</p>
            </Col>
          </Row>
        </>
      ),
      key: "2",
      children: (
        <>
          <Table
            className="history-invoice-table"
            name="history-invoice-table"
            key="history-invoice-table"
            loading={loading}
            columns={columnInvoice}
            dataSource={data}
            bordered
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [5, 10, 20, 50, 100],
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} entries`,
            }}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "odd" : "even";
            }}
            scroll={{
              x: true,
            }}
            rowKey={(record) => {
              return record?.id > 0 ? record.id : record.key;
            }}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Row
        className="history-guest-main-table"
        justify="center"
        style={mainBody}
      >
        <Col className="col-table" span={24} style={{ padding: "30px" }}>
          {/* <Row justify="end" className="row-refresh-btn">
            <Button
              className="refresh-btn"
              type="default"
              icon={<SyncOutlined />}
              onClick={fetchData}
            >
              {`Refresh`}
            </Button>
          </Row> */}

          <Row className="row-table" style={{ width: "100%" }}>
            <Tabs
              key="history-res-tabs"
              name="history-res-tabs"
              className="history-res-tabs"
              defaultActiveKey="1"
              type="card"
              activeKey={activeKey}
              onChange={onKeyChange}
              tabBarExtraContent={
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              }
              // tabBarStyle={{
              //   border: "2px solid #000000",
              // }}
              items={items}
              style={{
                width: "100%",
              }}
            />
          </Row>
        </Col>
      </Row>

      <Invoice
        is_open={invoice}
        is_close={handleClose}
        data_guest={invoice ? selected : []}
        payment={invoice ? payAndInvo.payment : null}
        data_table={invoice ? detail : []}
        invoice={invoice ? payAndInvo.invoice : null}
        is_preview={false}
      />
    </>
  );
}
