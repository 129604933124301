const breadcrumbNameMap = {
  // Version Log
  "/changelog-versions": "Versions Changelog",

  // Dashboard
  "/": "Dashboard",
  "": "Dashboard",
  "/dashboard": "Dashboard",
  "/task-list": "My Task List",

  // Front Office
  "/front-office": "Front Office",
  "/front-office/reservation": "Reservation",
  "/front-office/reservation/add-reservation": "Add Reservation",
  "/front-office/reservation/edit-reservation": "Edit Reservation",
  "/front-office/reservation/add-member-group": "Add Member Reservation",
  "/front-office/reservation/guest-card-list": "Guest Card List",
  "/front-office/reservation-deposit": "Reservation Deposit",
  "/front-office/expected-arrival": "Expected Arrival",
  "/front-office/expected-departure": "Expected Departure",
  "/front-office/expected-departure/guest-folio":
    "Guest Folio - Check-Out Guest",
  "/front-office/expected-departure/check-out": "Check-Out",
  "/front-office/expected-departure/check-out/success": "Success Page",
  "/front-office/expected-departure/early-check-out": "Early Check-Out",
  "/front-office/expected-departure/early-check-out/success": "Success Page",
  "/front-office/guest-inhouse": "Guest In House",
  "/front-office/foreign-guest-list": "Foreign Guest List",
  "/front-office/history-guest-list": "History Guest List",
  "/front-office/bill-outstanding-list": "Bill Outstanding List",
  "/front-office/bill-outstanding-list/master-folio": "Master Folio",
  "/front-office/bill-outstanding-list/master-folio/success": "Success",
  "/front-office/abf-guest-list": "ABF Guest List",
  "/front-office/birthday": "Birthday",
  "/front-office/walkin-guest-list": "Walk In Guest List",
  "/front-office/room-chart": "Room Chart",
  "/front-office/room-availability": "Room Availability",
  "/front-office/non-stay": "Non-Stay",
  "/front-office/history-non-stay": "History Non-Stay",
  "/front-office/night-audit": "Night Audit",
  "/front-office/night-audit/success": "Success Page",
  "/front-office/re-audit": "Re-Night Audit",
  "/front-office/re-audit/success": "Success Page",
  "/front-office/front-office-cashier": "FO - Cashier",
  "/front-office/front-office-cashier/success": "Success Page",

  // Back Office
  "/back-office": "Back Office",
  // "/back-office/night-audit": "Night Audit",
  // "/back-office/night-audit/success": "Success Page",
  "/back-office/budget": "Budget",
  "/back-office/budget/budget-list": "Budget List",
  "/back-office/budget/budget-approval": "Budget Approvals",
  "/back-office/account-receivable": "Account Receivable",
  "/back-office/account-receivable/account-receivable-payment": "A/R Payment",
  "/back-office/account-receivable/account-receivable-aging": "A/R Aging",
  "/back-office/account-receivable/account-receivable-payment-list":
    "A/R Payment List",
  "/back-office/account-payable": "Account Payable",
  "/back-office/account-payable/account-payable-payment": "A/P Payment",
  "/back-office/account-payable/account-payable-aging": "A/P Aging",
  "/back-office/account-payable/account-payable-payment-list":
    "A/P Payment List",
  "/back-office/purchasing": "Purchasing",
  "/back-office/purchasing/purchase-request": "Purchase Request",
  "/back-office/purchasing/daily-market-list": "Daily Market List",
  "/back-office/purchasing/approval-request": "Approval",
  "/back-office/purchasing/vendor-management": "Vendor Management",
  "/back-office/purchasing/price-management": "Price Management",
  "/back-office/purchasing/purchase-order": "Purchase Order",
  "/back-office/inventory": "Inventory",
  "/back-office/inventory/receiving": "Stock Incoming",
  "/back-office/inventory/approval-receiving": "Stock Incoming Approval",
  "/back-office/inventory/warehouse": "Ware Houses",
  "/back-office/inventory/stock-opname": "Stock Opname",
  "/back-office/inventory/transfer-request": "Stock Transfer/Request",
  "/back-office/inventory/stock-outgoing": "Stock Outgoing",
  "/back-office/inventory/stock-on-hand": "Stock On Hand",
  "/back-office/inventory/transfer-request-approval":
    "Approval Stock Transfer/Request",
  "/back-office/inventory/transfer-request-receiving":
    "Receiving Stock Transfer/Request",
  "/back-office/inventory/standard-recipe": "Standard Recipe",

  // Accounting
  "/back-office/accounting": "Accounting",
  "/back-office/accounting/create-journal": "Journal",
  "/back-office/accounting/journal-list": "Journal List",
  "/back-office/accounting/general-ledgers": "General Ledgers",
  "/back-office/accounting/chart-of-account": "Chart of Account",
  "/back-office/accounting/balance-sheet": "Balance Sheet",
  "/back-office/accounting/trial-balance": "Trial Balance",
  "/back-office/accounting/closing": "Closing",
  "/back-office/accounting/profit-and-lost": "Profit & Lost",
  "/back-office/accounting/start-up-accounting": "Start Up Accounting",
  "/back-office/accounting/journal-register": "Journal Register",

  "/back-office/accounting/start-up-accounting/initial-accounting-table":
    "Initial Accounting Table",
  "/back-office/accounting/start-up-accounting/initial-balance-settings":
    "Initial Balance Settings",
  "/back-office/accounting/start-up-accounting/initial-debts-settings":
    "Initial Debts Settings",
  "/back-office/accounting/start-up-accounting/initial-receivables-settings":
    "Initial Receivables Settings",

  "/back-office/accounting/accounting-ledgers": "Company Ledgers",

  "/back-office/accounting/accounting-reports/daily-accounting-report":
    "Daily Accounting",
  "/back-office/accounting/accounting-reports/monthly-accounting-report":
    "Monthly Accounting",
  "/back-office/accounting/accounting-reports": "Accounting Reports",
  "/back-office/accounting/accounting-reports/daily-accounting-report/headers-daily-report":
    "Daily Report (Headers)",
  "/back-office/accounting/accounting-reports/daily-accounting-report/details-daily-report":
    "Daily Report (Details)",
  "/back-office/accounting/accounting-reports/daily-accounting-report/segment-statistics":
    "Statistic by Segments",

  "/back-office/accounting/accounting-reports/monthly-accounting-report/balance-sheet-report":
    "Balance Sheet",
  "/back-office/accounting/accounting-reports/monthly-accounting-report/profit-n-loss-report":
    "Profit and Loss",
  "/back-office/accounting/accounting-reports/monthly-accounting-report/trial-balance-report":
    "Trial Balance",

  "/accounting": "Accounting",
  "/accounting/create-journal": "Journal",
  "/accounting/journal-list": "Journal List",
  "/accounting/general-ledgers": "General Ledgers",
  "/accounting/chart-of-account": "Chart of Account",
  "/accounting/balance-sheet": "Balance Sheet",
  "/accounting/trial-balance": "Trial Balance",
  "/accounting/closing": "Closing",
  "/accounting/profit-and-lost": "Profit & Lost",
  "/accounting/start-up-accounting": "Start Up Accounting",

  "/accounting/start-up-accounting/initial-accounting-table":
    "Initial Accounting Table",
  "/accounting/start-up-accounting/initial-balance-settings":
    "Initial Balance Settings",
  "/accounting/start-up-accounting/initial-debts-settings":
    "Initial Debts Settings",
  "/accounting/start-up-accounting/initial-receivables-settings":
    "Initial Receivables Settings",

  "/accounting/accounting-ledgers": "Company Ledgers",

  "/accounting/accounting-reports/daily-accounting-report": "Daily Accounting",
  "/accounting/accounting-reports/monthly-accounting-report":
    "Monthly Accounting",
  "/accounting/accounting-reports": "Accounting Reports",
  "/accounting/accounting-reports/daily-accounting-report/headers-daily-report":
    "Daily Report (Headers)",
  "/accounting/accounting-reports/daily-accounting-report/details-daily-report":
    "Daily Report (Details)",
  "/accounting/accounting-reports/daily-accounting-report/segment-statistics":
    "Statistic by Segments",

  "/accounting/accounting-reports/monthly-accounting-report/balance-sheet-report":
    "Balance Sheet",
  "/accounting/accounting-reports/monthly-accounting-report/profit-n-loss-report":
    "Profit and Loss",
  "/accounting/accounting-reports/monthly-accounting-report/trial-balance-report":
    "Trial Balance",

  // House Keeping
  "/house-keeping": "House Keeping",
  "/house-keeping/room-monitoring": "Room Monitoring",
  "/house-keeping/room-status": "Room Status",
  "/house-keeping/out-of-order": "Out Of Order",
  "/house-keeping/off-market": "Off-Market",
  "/house-keeping/lost-and-found": "Lost and Found",

  // Inventory
  "/inventory": "Inventory",

  // Assets
  "/assets": "Assets",

  // General Report
  "/report": "Report",
  "/report/accounting": "Accounting Reports",
  "/report/accounting/daily-accounting-report": "Daily Accounting",
  "/report/accounting/monthly-accounting-report": "Monthly Accounting",
  "/report/accounting/daily-accounting-report/headers-daily-report":
    "Daily Report (Headers)",
  "/report/accounting/daily-accounting-report/details-daily-report":
    "Daily Report (Details)",
  "/report/accounting/daily-accounting-report/segment-statistics":
    "Statistic by Segments",

  "/report/accounting/monthly-accounting-report/balance-sheet-report":
    "Balance Sheet Report",
  "/report/accounting/monthly-accounting-report/balance-sheet-report/balance-sheet-main-report":
    "Balance Sheet Main Report",
  "/report/accounting/monthly-accounting-report/balance-sheet-report/balance-sheet-detail-report":
    "Balance Sheet Detail Report",
  "/report/accounting/monthly-accounting-report/profit-n-loss-report":
    "Profit and Loss",
  "/report/accounting/monthly-accounting-report/profit-n-loss-report/profit-n-loss-detail-report":
    "Profit and Loss Detail Report",
  "/report/accounting/monthly-accounting-report/profit-n-loss-report/profit-n-loss-main-report":
    "Profit and Loss Main Report",

  "/report/accounting/monthly-accounting-report/trial-balance-report":
    "Trial Balance",

  "/report/house-keeping/room-reckon": "Room Reckon",
  "/report/house-keeping/room-queue": "Room Queue",

  "/report/front-office": "Front Office Reports",
  "/report/front-office/cancel-reservation": "Cancelled Reservations",
  "/report/front-office/arrival-list": "Arrival List",
  "/report/front-office/departure-list": "Departure List",
  "/report/front-office/reservation-list": "Reservation List",
  "/report/front-office/inhouse-list": "Guest In-House List",
  "/report/front-office/history-guest": "Departed List",
  "/report/front-office/noshow-reservation": "No Show Reservation",
  "/report/front-office/extended-reservation": "Extended Reservation",
  "/report/front-office/early-check-out": "Early Check-Out Guest",
  "/report/front-office/recheck-in-check-out-guest": "Re-Check-In C/O Guest",
  "/report/front-office/reactivate-reservations":
    "Re-Activate Check-In Reservations",

  "/report/back-office": "Back Office Reports",
  "/report/back-office/purchasing-report": "Purchasing Reports",
  "/report/back-office/purchasing-report/purchasing-list":
    "Purchasing List Reports",
  "/report/back-office/purchasing-report/purchase-request":
    "Purchase Request Reports",
  "/report/back-office/purchasing-report/purchase-request-cancelled":
    "Purchase Request Cancelled Reports",
  "/report/back-office/purchasing-report/purchase-request-expired":
    "Purchase Request Expired Reports",
  "/report/back-office/purchasing-report/purchase-order":
    "Purchase Order Reports",
  "/report/back-office/purchasing-report/purchase-order-cancelled":
    "Purchase Order Cancelled Reports",
  "/report/back-office/purchasing-report/purchase-order-expired":
    "Purchase Order Expired Reports",
  "/report/back-office/purchasing-report/purchase-order-outstanding":
    "Purchase Order Outstanding Reports",
  "/report/back-office/purchasing-report/purchase-book":
    "Purchase Book Reports",

  "/report/back-office/inventory-report": "Inventory Reports",
  "/report/back-office/inventory-report/minimum-on-hand":
    "Minimum On Hand List Reports",
  "/report/back-office/inventory-report/slow-moving":
    "Slow Moving Stock List Reports",
  "/report/back-office/inventory-report/incoming-stock":
    "Stock Incoming Reports",
  "/report/back-office/inventory-report/incoming-stock/stock-article-incoming":
    "Stock Article Incoming Reports",
  "/report/back-office/inventory-report/incoming-stock/receiving":
    "Receiving List Reports",
  "/report/back-office/inventory-report/incoming-stock/receiving-without-po":
    "Receiving W/O PO List Reports",
  "/report/back-office/inventory-report/incoming-stock/journal-incoming-stock":
    "Journal of Incoming Stock Reports",
  "/report/back-office/inventory-report/incoming-stock/stock-article-return":
    "Stock Article Return Reports",
  "/report/back-office/inventory-report/incoming-stock/cancelled-receiving":
    "Cancelled Receiving Reports",
  "/report/back-office/inventory-report/incoming-stock/receiving-summary":
    "Receiving Summary Reports",
  "/report/back-office/inventory-report/incoming-stock/price-discrepancy":
    "Price Discrepancy Reports",
  "/report/back-office/inventory-report/incoming-stock/annual-receiving":
    "Annual Receiving Reports",

  "/report/back-office/inventory-report/outgoing-stock":
    "Outgoing Stock Reports",
  "/report/back-office/inventory-report/outgoing-stock/transfer-summary":
    "Transfer Summary Reports",
  "/report/back-office/inventory-report/outgoing-stock/transfer-validation":
    "Transfer Validation Reports",
  "/report/back-office/inventory-report/outgoing-stock/stock-article-moving":
    "Stock Article Moving Reports",
  "/report/back-office/inventory-report/outgoing-stock/single-article-moving":
    "Single Article Moving Reports",
  "/report/back-office/inventory-report/outgoing-stock/stock-outgoing":
    "Stock Outgoing Reports",
  "/report/back-office/inventory-report/outgoing-stock/expired-outgoing":
    "Expired Outgoing Reports",
  "/report/back-office/inventory-report/outgoing-stock/stock-requisition":
    "Stock Requisition Reports",
  "/report/back-office/inventory-report/outgoing-stock/requisition-summary":
    "Requisition Summary Reports",
  "/report/back-office/inventory-report/outgoing-stock/annual-outgoing":
    "Annual Outgoing Reports",
  "/report/back-office/inventory-report/outgoing-stock/stock-item-transform":
    "Stock Item Transform Reports",
  "/report/back-office/inventory-report/outgoing-stock/inter-kitchen-transfer":
    "Inter Kitchen Transfer Reports",

  "/report/back-office/inventory-report/standard-recipe-archive":
    "Standard Recipe Archives",

  "/report/house-keeping": " House Keeping Reports",
  "/report/food-and-beverage": " Outlet Reports",

  // F&B
  // MICE
  "/f-and-b": "F & B",
  "/f-and-b/mice": "MICE",
  "/f-and-b/mice/mice-reservations": "MICE Reservations",
  "/f-and-b/mice/history": "MICE History",
  "/f-and-b/mice/packages": "Master MICE Packages",
  // OUTLET
  "/f-and-b/outlet": "Outlet",
  "/f-and-b/outlet/captain-order": "Captain Order",
  "/f-and-b/outlet/table-availability": "Table Availability",
  "/f-and-b/outlet/kitchen-order": "Kitchen Order",
  "/f-and-b/outlet/captain-order/pos-cashier": "POS Cashier",
  "/f-and-b/outlet/captain-order/payment": "POS Payment",
  "/f-and-b/outlet/captain-order/payment/success": "POS Payment Success Page",
  "/f-and-b/outlet/pos-cashier": "POS Cashier",
  "/f-and-b/outlet/pos-cashier/payment": "POS Payment",
  "/f-and-b/outlet/pos-cashier/payment/success": "POS Payment Success Page",
  "/f-and-b/outlet/report-outlet": "Report Outlet",
  "/f-and-b/outlet/close-cashier": "Close Cashier",

  // Config
  "/configuration": "Configuration",
  "/configuration/master": "Masters",
  "/configuration/parameters": "Parameters",
  "/configuration/user-management": "User Management",
  "/configuration/user-management/user-profile": "User Profile",
  "/configuration/user-management/access-control": "Access Control",
  "/configuration/user-management/access-control/manage-role":
    "Manage User's Role & Permissions",
  "/configuration/user-management/registration": "User Register",
  "/configuration/user-management/blocking": "Blocking",

  // Config Master
  "/master": "Master",
  "/configuration/master/article-list": "Article List",
  "/master/article-inventory": "Article Inventory",
  "/configuration/master/article-inventory": "Article Inventory",
  "/master/article-summary": "Article Summary",
  "/configuration/master/article-summary": "Article Summary",
  "/master/article-main-group": "Article Main-Group",
  "/configuration/master/article-main-group": "Article Main-Group",
  "/master/article-sub-group": "Article Sub-Group",
  "/configuration/master/article-sub-group": "Article Sub-Group",
  "/master/article-list": "Article List",
  "/master/compliment-article": "Compliment Article",
  "/configuration/master/compliment-article": "Compliment Article",
  "/master/country": "Country",
  "/configuration/master/country": "Country",
  "/master/bs-template": "BS Template",
  "/configuration/master/bs-template": "BS Template",
  "/master/payment-card": "Payment Card",
  "/configuration/master/payment-card": "Payment Card",
  "/master/payment-type": "Payment Type",
  "/configuration/master/payment-type": "Payment Type",
  "/master/rate-periodic": "Rate Periodic",
  "/configuration/master/rate-periodic": "Rate Periodic",
  "/master/table": "Table",
  "/configuration/master/table": "Table",
  "/master/table-category": "Table Category",
  "/configuration/master/table-category": "Table Category",
  "/master/arrangement": "Arrangement",
  "/configuration/master/arrangement": "Arrangement",
  "/master/group-menu": "Group Menu",
  "/configuration/master/group-menu": "Group Menu",
  "/master/letter": "Letter",
  "/configuration/master/letter": "Letter",
  "/master/guest-card-file": "Guest Card File",
  "/configuration/master/guest-card-file": "Guest Card File",
  "/master/room": "Room",
  "/configuration/master/room": "Room",
  "/master/rate-code": "Rate Code",
  "/configuration/master/rate-code": "Rate Code",
  "/master/room-category": "Room Category",
  "/configuration/master/room-category": "Room Category",
  "/configuration/master/room-availability": "Room Availability",
  "/master/salesman": "Salesman",
  "/configuration/master/salesman": "Salesman",
  "/master/department": "Department",
  "/configuration/master/department": "Department",
  "/master/allotment": "Allotment",
  "/configuration/master/allotment": "Allotment",
  "/configuration/master/main-segment": "Main Segment",
  "/configuration/master/sources": "Sources",
  "/configuration/master/outlet-location": "Outlet Location",
  "/configuration/master/unit-measurement": "Unit of Measurement",

  // Config Params
  "/configuration/parameters/accounting-params": "Accounting Parameters",
  "/configuration/parameters/back-office-params": "Back Office Parameters",
  "/configuration/parameters/dashboard-params": "Dashboard Parameters",
  "/configuration/parameters/front-office-params": "Front Office Parameters",
  "/configuration/parameters/house-keeping-params": "House Keeping Parameters",
  "/configuration/parameters/outlet-params": "Outlet Parameters",
  "/configuration/parameters/working-hours-params": "Working Hours (Shift)",

  // General Reports
  "/configuration/parameters/general-report-params": "General Report",
  "/configuration/parameters/general-report-params/report-params":
    "Daily Report Parameters",
  "/configuration/parameters/general-report-params/monthly-report-params":
    "Monthly Report Parameters",
  "/configuration/parameters/general-report-params/accounting-params":
    "Accounting",
  "/configuration/parameters/general-report-params/front-office-params":
    "Front-Office",
  "/configuration/parameters/general-report-params/back-office-params":
    "Back-Office",
  "/configuration/parameters/general-report-params/house-keeping-params":
    "House Keeping",
  "/configuration/parameters/general-report-params/f-and-b-params": "F & B",

  "/configuration/parameters/general-report-params/report-params/report-master-headers":
    "Master Header Parameters",
  "/configuration/parameters/general-report-params/report-params/report-master-details":
    "Master Detail Parameters",
  "/configuration/parameters/general-report-params/report-params/report-master-segmentations":
    "Master Segment Parameters",

  "/configuration/parameters/general-report-params/report-params/statistics":
    "Statistic Label",
  "/configuration/parameters/general-report-params/report-params/segmentations":
    "Segmentation Parameters",
  "/configuration/parameters/general-report-params/report-params/drr-header-params":
    "Header Report Parameters",
  "/configuration/parameters/general-report-params/report-params/drr-detail-params":
    "Detail Report Parameters",

  "/configuration/parameters/general-report-params/monthly-report-params/report-master-balancesheet-details":
    "Balance Sheet Detail Report Master",
  "/configuration/parameters/general-report-params/monthly-report-params/report-master-balancesheet-details-params":
    "Balance Sheet Detail Report Parameter",
  "/configuration/parameters/general-report-params/monthly-report-params/report-master-balancesheet-headers":
    "Balance Sheet Headers Report Parameter",

  "/configuration/parameters/general-report-params/monthly-report-params/report-profit-and-loss-header":
    "Profit & Loss Headers Report Parameter",
  "/configuration/parameters/general-report-params/monthly-report-params/report-profit-and-loss-detail":
    "Profit & Loss Details Report Master",
  "/configuration/parameters/general-report-params/monthly-report-params/pnl-detail-params":
    "Profit & Loss Details Report Parameter",
  "/configuration/parameters/general-report-params/monthly-report-params/report-profit-and-loss-main":
    "Profit & Loss Main Report Parameter",
};

const breadcrumbTitleMap = {
  "": "Dashboard",
  "/": "Dashboard",
  dashboard: "Dashboard",
  "front-office": "Front Office",
  "back-office": "Back Office",
  accounting: "Accounting",
  assets: "Assets",
  "house-keeping": "House Keeping",
  master: "Master",
  outlet: "Outlet",
  report: "General Report",
  inventory: "Inventory",
  configuration: "Configuration",
};

export { breadcrumbNameMap, breadcrumbTitleMap };
