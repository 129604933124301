// Import React's Component
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Typography,
  Card,
  Tooltip,
  Checkbox,
  Tag,
} from "antd";

// Import React Icons
import { MdOutlineDashboardCustomize } from "react-icons/md";

// Import Page Components
import MasterSalesType from "../../../Reusable/Outlet/Master/SalesType/SalesType";
import ModalTableOrder from "../../../Modals/Outlet/Order/Modal";
import ModalChangeTable from "../../../Modals/Outlet/Order/ChangeTableModal";
import ModalCancelOrder from "../../../Modals/Outlet/Order/CancelModal";

// Import Notification
import {
  failedFetch,
  failedPOSOrder,
  incomplete,
  successPOSOrder,
  successPOSChangeTable,
  failedPOSChangeTable,
  successPOSPayment,
  failedPOSPayment,
} from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import POSPopover from "../../../Popover/POSOutlet/POSPopover";
import ItemNewOrder from "../../../Modals/Outlet/Order/ItemNewOrder";
import POSSplitBill from "../../../Modals/Outlet/Order/POSSplitBill";

// Modal
const { confirm } = Modal;

// CODE
export default function PerTablePOS(props) {
  // Props
  const {
    is_created,
    selectedData,
    locationCategories,
  } = props;

  // CONTEXT
  const { getGuestInHouse } = useContext(MainContext);

  // USE REF
  const componentRef = useRef();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [tableKey, setTableKey] = useState(null);
  const [dataOrder, setDataOrder] = useState(null);
  const [guestData, setGuestData] = useState([]);
  // Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cardLoad, setCardLoad] = useState(true);

  const [chargeToRoomState, setChargeToRoomState] = useState(false);
  const [deleteHeader, setDeleteHeader] = useState(false);
  const [segment, setSegment] = useState(0)

  // Selected Data & Keys
  const [billStatus, setBillStatus] = useState(0);

  // Modal
  const [open, setOpen] = useState(false);
  const [openModalChangeTable, setOpenModalChangeTable] = useState(false);
  const [modalDeleteOrder, setModalDeleteOrder] = useState(false);
  const [modalSplit, setModalSplit] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname } = useLocation();
  

  // Fetch Order
  const fetchOrder = async (record) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        order_id_alias: record?.order_id_alias,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.g_pos_article_detail.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            return {
              ...x,
              serving_que: x.serving_order,
              key: i,
            }
          });
          console.log("_resMap", _resMap)

          const res = _resMap.reduce((acc, e) => {
            const found = acc.find(x => e.article_id === x.article_id)
            if(found) {
              found.item_quantity += e.item_quantity;
              found.total_price += e.total_price;
            } else {
              acc.push(e)
            }
            return acc
          }, []);

          console.log("")

          setData(res);
          setDataOrder(response.data.msg[0]);
          handleFormField(response.data.msg[0], record);
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        failedFetch(error);
      })
      .finally(() => {
        setCardLoad(false);
      });
  };

  // FETCH DATA GUEST IN HOUSE
  const fetchDataGuestInHouse = async () => {
    await getGuestInHouse({
      guestInHouse: "guest-in-house",
      type: "stay",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setGuestData(filtered);
          // setBulks(filtered);
        } else {
          setGuestData([]);
          // setBulks([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
        failedFetch(error);
      },
    });
  };

  useEffect(() => {
    console.log("selectedData on useEffect PerTable", selectedData);
      fetchOrder(selectedData);

    if (selectedData?.id) {
      setBillStatus(1);
    }

    return () => {};
  }, [selectedData]);

  useEffect(() => {
    console.log("selectedData on useEffect PerTable", tableKey);
      fetchOrder(tableKey);

    if (tableKey?.id) {
      setBillStatus(1);
    }

    return () => {};
  }, [tableKey]);

  // COLUMNS
  const columns = [
    {
      title: "Items",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Qty",
      dataIndex: "item_quantity",
      key: "item_quantity",
      editable: true,
      render: (qty, record) => {
        return qty;
      },
    },
    {
      title: "Split Qty",
      dataIndex: "split_qty",
      key: "split_qty",
      hidden: billStatus == 2 ? false : true,
      render: (text, record) => {
        return <InputNumber onChange={((val) => handleSplitBill(val, record.key))} defaultValue={0} min={0} max={record.item_quantity} />;
      },
    },
    {
      title: "Unit Price",
      dataIndex: "gross",
      key: "gross",
      editable: true,
      hidden: billStatus == 2 ? true : false,
      render: (price) => {
        return `${price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      editable: true,
      hidden: billStatus == 2 ? true : false,
      render: (price, record) => {
        const total = price > 0 ? price : record.item_quantity * record.gross;

        return `${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ].filter(item => !item.hidden);

  // HANDLE EDIT
  const handleEdit = (value) => {
    console.log("Data Edited: ", value);

    let _notUpdated = [];

    let _updated = data.filter((item) => {
      if (item?.id === value?.id) {
        return value;
      } else {
        _notUpdated.push(item);
      }
    });

    // console.log("Updated: ", _updated);
    // console.log("Not Updated: ", _notUpdated);

    if (_updated?.length > 0) {
      setData([value, ..._notUpdated]);
    } else {
      setData(data);
    }
  };

  // SHOW MODAL
  const showConfirmChargeToRoomModal = (value) => {
    const _data = value;

    confirm({
      className: "confirm",
      title: `POS Order CHARGE TO ROOM Confirmation`,
      content: `Are you sure you want to add an Order for ${(
        _data?.name || " NON STAY GUEST "
      ).toUpperCase()} with CHARGE TO ROOM`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(_data);
        Modal.destroyAll();
      },

      onCancel() {
        setIsLoading(false);
        Modal.destroyAll();
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Form Field
  const handleFormField = (value, header) => {
    const _record = value;

    if(header?.segment == 1) {
      setSegment(1);
    } else if (header?.segment == 2){
      setSegment(2);
    };

    form.setFieldsValue({
      name: _record?.name,
      sales_type: _record?.sales_type,
      payment_status: _record?.payment_status == 2 ? true : false,
      pax: header?.pax,
    });

    if (_record?.payment_status == 2) {
      setChargeToRoomState(true);
    } else {
      setChargeToRoomState(false);
    }
    fetchDataGuestInHouse();
  };

  const handleChargeToRoom = async () => {
    let val = data.map((e, i) => {
      return {
        cust_amount: null,
        bill_status: null,
        order_id_alias: data[0]?.order_id_alias,
        created_by: null,
      };
    });
    await axios
      .post(
        `${baseurl}/pos/order-payment`,
        {
          data: val,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("POS Payment: ", response);

        if (response.status === 200) {
          successPOSPayment(response, { method: 2 });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedPOSPayment(error, { method: 2 });
        setIsLoading(false);
      })
      .finally(() => {
        // setIsLoading(false);
        handleClear();
        
      });
  };

  // ON FINISH
  const onFinish = (value) => {
    const _data = value ?? form.getFieldsValue();
    setIsLoading(true);

    console.log("On Finish: ", _data);

    if (chargeToRoomState == false) {
      navigate(`${pathname}/payment`, {
        state: {
          headerData: {
            ..._data,
            payment_status: selectedData?.payment_status,
          },
          detailData: data,
          selectedTable: selectedData,
          guestData: guestData,
        },
      });
    } else if (chargeToRoomState == true) {
      showConfirmChargeToRoomModal(_data);
    }
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const _error = value;

    console.log("On Finish FAILED: ", _error);
    incomplete(_error);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _data = value;
    let _totalNett = 0;
    let _totalTax = 0;
    let _totalSer = 0;
    let _totalGross = 0;

    data.forEach(({ unit_price, tax, service, gross, item_quantity }) => {
      let _tGross = gross * item_quantity;
      let _tTax = tax * item_quantity;
      let _tSer = service * item_quantity;
      let _tNett = unit_price * item_quantity;

      _totalNett += _tNett;
      _totalSer += _tSer;
      _totalTax += _tTax;
      _totalGross += _tGross;
    });

    let filterGuestData = guestData.filter((x) => {
      if(x.reservation_id == selectedData?.reservation_id && x.guest_name == selectedData?.name){
        return true
      } else {
        return false
      }
    });

    console.log("filterGuestData: ", filterGuestData);

    let room_number = filterGuestData.length > 0 ? filterGuestData[0]?.room_number : null;

    console.log("room_number", room_number);

    // console.log("About TO ORDER: ", _data);
    // console.log("TABLE SELECTED: ", tableKey);

      await axios
        .get(`${baseurl}/pos/last-order-id-header`)
        .then(async (res) => {
          if (res.status === 200) {
            console.log("Res Last ID: ", res);

          if (res?.data?.length > 0) {
            console.log("Get Last Order Id Header: ", res);
            console.log("DATA >>>", data);

            await axios
              .put(
                `${baseurl}/pos/order-header`,
                {
                  id: data[0]?.header_id,
                  name: _data?.name,
                  table_code: data[0]?.table_code,
                  table_id: data[0]?.table_id,
                  payment_type: null,
                  payment_details: null,
                  payment_status: selectedData?.payment_status,

                  total_nett: data
                    .map((e) => ({
                      nett: e.unit_price * e.item_quantity ?? 0,
                    }))
                    .reduce((prev, v) => prev + v.nett, 0),

                  total_gross: data
                    .map((e) => ({
                      gross: e.gross * e.item_quantity ?? 0,
                    }))
                    .reduce((prev, v) => prev + v.gross, 0),

                  total_tax: data
                    .map((e) => ({
                      tax: e.tax * e.item_quantity ?? 0,
                    }))
                    .reduce((prev, v) => prev + v.tax, 0),

                  total_service: data
                    .map((e) => ({
                      service: e.service * e.item_quantity ?? 0,
                    }))
                    .reduce((prev, v) => prev + v.service, 0),

                  is_open_bill: null,
                  sales_type: _data?.sales_type,
                  updated_by: user_name,
                  old_table_code: data[0]?.table_code,
                  old_table_id: data[0]?.table_id,
                  reservation_id: selectedData?.reservation_id,
                  pax: selectedData?.pax,
                  order_status: selectedData?.order_status,
                  kitchen_status: selectedData?.kitchen_status,
                  segment: selectedData?.segment,
                  source: selectedData?.source,
                  room_number: room_number,
                },
                {
                  headers: { Authorization: `Bearer ${token ? token : ""}` },
                }
              )

              .then(async (response) => {
                if (response.status === 200) {
                  console.log("Edit Header: ", response);
                  successPOSOrder(response, { method: 1 });
                  handleChargeToRoom();
                } else {
                  failedPOSOrder(response, { method: 1 });
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                console.log("Error: ", error);
                failedPOSOrder(error, { method: 1 });
                setIsLoading(false);
              });
          } else {
            // failedPOSOrder(response, { method: 0 });
          }
        }
      })

      .catch((error) => {
        console.log("Res Last Err: ", error);
        setIsLoading(false);
      });
  };

  const handleClear = () => {
    setBillStatus(0);
    setData([]);
    setChargeToRoomState(false);
    setDataOrder(null);
    is_created(true);
    setTableKey([]);
    setGuestData([]);
    setIsLoading(false);
    form.resetFields();
  };

  const handleSplitBill = (splitQty, item) => {
    console.log("SPLIT BILL", splitQty);
    let splitBill = data.map((x) => {
      if(x.key === item) {
        return {
          ...x,
          added_quantity: splitQty,
        }
      } else {
        return x
      }
    });

    console.log("splitBill", splitBill);
    setData(splitBill);
  }

  return (
    <>
      <Row className="row-pertable" style={mainBody}>
        <Col
          ref={componentRef}
          className="table-col"
          span={24}
          style={{ width: "100%", padding: "0px 30px 30px" }}
        >
          <Card
            className="table-card"
            bordered
            loading={cardLoad}
            style={{
              width: "100%",
              // marginTop: 16,
            }}
            title={
              <Row justify="center" align="middle" className="title-row">
                <Col span={24} className="title-col">
                  <Typography
                    className="bill-txt"
                    key={"txt-bill"}
                    style={{ fontSize: 20 }}
                  >
                    {selectedData?.order_id_alias || dataOrder?.order_id_alias || "---"}
                  </Typography>
                </Col>
              </Row>
            }
            headStyle={{
              textAlign: "center",
              background: "#e4e6ef",
            }}
            bodyStyle={{
              textAlign: "center",
              display: tableKey?.table_id || tableKey?.room_id || selectedData?.table_id ?  "block" : "none",
            }}
          >
            <Typography
              className="bill-txt"
              key={"txt-bill"}
              style={{ fontSize: 20, fontWeight: 500 }}
            >
              {tableKey?.room_id || tableKey?.table_id || selectedData?.table_id || "No Table"}
            </Typography>
          </Card>

          <Form
            form={form}
            className="order-form"
            key="order-form"
            name="order-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            // initialValues={{
            //   payment_type: false,
            // }}
            layout="vertical"
            style={{
              padding: "15px 0px 0px",
              margin: 0,
            }}
          >
            <Row className="mainmodal-row" justify="start">
              <Col className="mainmodal-col" span={24}>
                <Row className="name-row" justify="start" gutter={30}>
                  <Col span={9} className="name-col">
                    <Form.Item
                      label="Name"
                      name="name"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please, Input a Name!",
                      //   },
                      // ]}
                    >
                      <Input
                        className="name-input"
                        placeholder="Name"
                        // disabled={chargeToRoomState ? true : false}
                        disabled={true}
                        addonAfter={
                          <Tooltip
                            className="modal-tooltip"
                            title="Find Guest In-House"
                          >
                            <MdOutlineDashboardCustomize
                              className="suffix-icons"
                              style={{
                                fontSize: 20,
                                color: "#3699FF",
                                marginTop: 3,
                              }}
                            />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                    <Form.Item label="" name="reservation_id" hidden />
                  </Col>

                  <Col span={9} className="sales-col">
                    <Form.Item
                      label="Sales Type"
                      name="sales_type"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input the Sales Type!",
                        },
                      ]}
                    >
                      <MasterSalesType
                        getSalesType={() => {}}
                        sales_type={
                          {
                            1: "Dine-In",
                            2: "Take Away",
                            3: "Online Order",
                          }[form.getFieldsValue().sales_type] ?? "Take Away"
                        }
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} className="pax-col">
                    <Form.Item
                      label="Pax"
                      name="pax"
                      rules={[
                        {
                          required: true,
                          message: "Please, Input Pax Number!",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Pax"
                        disabled
                        style={{
                          width: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Row justify="end" align="bottom" style={{ marginTop: 35 }}>
                      <Space>
                        <Tooltip title="Option Menu" className="option-tooltip">
                          <POSPopover
                            headers={{
                              ...form.getFieldsValue(),
                              payment_status:
                                form.getFieldsValue().payment_status != true
                                  ? 1
                                  : 2,
                            }}
                            details={data.map((e) => ({
                              ...e,
                              payment_status: e.payment_status != true ? 1 : 2,
                            }))}
                            selectedTable={(e) => {
                              setTableKey(e);
                              is_created(true);
                            }}
                            is_refresh={null}
                            getAddNewState={() => {setBillStatus(2)}}
                            selectedData = {selectedData}
                            getClear={handleClear}
                          />
                        </Tooltip>
                      </Space>
                    </Row>
                  </Col>
                </Row>

                <Row
                  className="pax-row"
                  justify="start"
                  align="top"
                  gutter={30}
                >
                  <Col span={8} className="check-col">
                    <Form.Item
                      label=""
                      name="payment_status"
                      valuePropName="checked"
                    >
                      <Checkbox
                        className="pax-input"
                        placeholder="Total Pax"
                        disabled
                        min={0}
                        style={{
                          width: "100%",
                        }}
                      >
                        {`Charge To Room`}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={9} className="check-col">
                    {segment == 1 ? <Tag color="#2db7f5">Source: Walk In Guest</Tag> : segment == 2 ? <Tag color="#108ee9">Source: Guest In House</Tag> : ""}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Form.Item
              name="article_list"
              label=""
              rules={[
                {
                  validator: async (keys) => {
                    form.setFieldsValue({
                      article_list: data,
                    });

                    if (data.length === 0) {
                      return Promise.reject("Please, Select a Menu!");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Table
                className="ordertable-tbl"
                name="ordertable-tbl"
                key="ordertable=tbl"
                loading={loading}
                columns={columns}
                dataSource={data}
                bordered
                size="medium"
                pagination={false}
                //   {
                //   pageSize: 10,
                //   showTotal: (total, range) =>
                //     `Showing ${range[0]}-${range[1]} of ${total} entries`,
                // }
                scroll={{
                  x: true,
                }}
                // rowKey={(record) => record?.article_id}
                rowKey={(record) => record?.key}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "odd" : "even";
                }}
                summary={(data) => {
                  let total = data.reduce((acc, init) => {
                    let _total = init?.gross * init?.item_quantity;

                    return acc + _total;
                  }, 0);

                  return (
                    <> 
                      {billStatus == 2 ? null : (<Table.Summary
                        fixed
                        key="summary-tables"
                        className="sum-table"
                      >
                        <Table.Summary.Row
                          className="sum-order"
                          key="sum-order"
                        >
                          <Table.Summary.Cell
                            className="cum-cell"
                            key="sum-cell"
                            colSpan={3}
                          >
                            <Typography.Text strong>
                              {`Grand Total`}
                            </Typography.Text>
                          </Table.Summary.Cell>

                          <Table.Summary.Cell
                            className="cum-cell"
                            key="sum-total"
                            colSpan={1}
                          >
                            <Typography.Text strong>
                              {`${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Typography.Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>)}
                    </>
                  );
                }}
              />
            </Form.Item>

            <Divider
              className="divider-form"
              style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
            />

            <Row className="row-modal-btn" justify="end" align="middle">

              <Button
                type="primary"
                htmlType="submit"
                key={"submit-charge"}
                onClick={(e) => {}}
                // disabled={data.some((e) => e.id === null) ? true : false}
                hidden={billStatus == 1 ? false : true}
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Submit & Charge`}
              </Button>

              <Button
                type="primary"
                hidden={billStatus == 2 ? false : true}
                onClick={() => {
                  if(data.filter((x) => {
                    if(x.added_quantity > 0) {
                      return true
                    } else {
                      return false
                    }
                    }).length > 0) {
                      setModalSplit(true);
                  } else {
                    incomplete({errorFields:[{errors: ["Please, set Split Quantity!"]}]});
                  }
                }
                }
                loading={isLoading}
                className="submit-btn"
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Split Order`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                disabled={billStatus == 1 ? false : true}
                onClick={(e) => {
                  setDataEdit({
                    order_id_alias:
                      dataOrder?.order_id_alias
                  });
                  setModalDeleteOrder(true);
                  setDeleteHeader(true);
                }}
              >
                {`Cancel Order`}
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>

      <ModalTableOrder
        openModal={open}
        closeModal={setOpen}
        articleItems={dataEdit}
        is_edit={true}
        articleTable={handleEdit}
        is_refresh={(e) => {
          fetchOrder();
          // fetchTable();
          // if (e == true) {
          //   setTimeout(() => {
          //     is_created(true);
          //   }, 1000);
          // }
        }}
      />

      <ModalCancelOrder
        openModal={modalDeleteOrder}
        closeModal={setModalDeleteOrder}
        deleteItem={dataEdit}
        isHeader={deleteHeader}
        is_refresh={(e) => {
          if (deleteHeader) {
            if (e == true) {
              setTimeout(() => {
                is_created(true);
                handleClear();
              }, 1000);
            }
          } else {
            // fetchTable();
            fetchOrder();
          }
        }}
      />

      <ModalChangeTable
        openModal={openModalChangeTable}
        closeModal={setOpenModalChangeTable}
        selectedTable={async (e) => {
          await axios
            .put(
              `${baseurl}/pos/order-header`,
              {
                id: data[0]?.header_id,
                name: form.getFieldsValue(["name"]).name ?? "NON STAY GUEST",
                table_code: e?.table_code,
                table_id: e?.table_id,
                payment_type: null,
                payment_details: null,
                payment_status: data[0]?.payment_status != true ? 1 : 2,

                total_nett: data
                  .map((e) => ({
                    nett: e.unit_price * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.nett, 0),

                total_gross: data
                  .map((e) => ({
                    gross: e.gross * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.gross, 0),

                total_tax: data
                  .map((e) => ({
                    tax: e.tax * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.tax, 0),

                total_service: data
                  .map((e) => ({
                    service: e.service * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.service, 0),

                order_step: data[0]?.order_step,
                is_open_bill: null,
                sales_type: form.getFieldsValue(["sales_type"]).sales_type,
                updated_by: user_name,
                old_table_code: data[0]?.table_code,
                old_table_id: data[0]?.table_id,
              },
              {
                headers: { Authorization: `Bearer ${token ? token : ""}` },
              }
            )
            .then(async (response) => {
              console.log("Edit Header: ", response);
              successPOSChangeTable(response, { method: 0 });
            })
            .catch((error) => {
              console.log("Error: ", error);
              failedPOSChangeTable(error, { method: 0 });
            });
        }}
      />
      <POSSplitBill
        openModal={modalSplit}
        closeModal={setModalSplit}
        getClear={handleClear}
        data={data}
        locationCategories={locationCategories}
      />
    </>
  );
}
