// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";
import { Context as FinanceContext } from "../../../API/Context/FinanceContext/FinanceContext";

// BASEAPI
import { department, token } from "../../../API/Global/Payload";
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
  Table,
  Tag,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { PrinterOutlined, SyncOutlined } from "@ant-design/icons";
import { BiSolidFilePdf } from "react-icons/bi";

// Import Functions
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";
import { FormatDate } from "../../Reusable/Functions/FormatDate/FormatDate";
import GroupingBill from "../../Reusable/Functions/GroupingArticle/GroupingBill";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import GroupingArticle from "../../Reusable/Functions/GroupingArticle/GroupingArticle";

// Import Notifications
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { invoText, textForm } from "../../../Style/Global/Global";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function Invoice(props) {
  // PROPS
  const {
    is_open,
    is_close,
    data_guest,
    payment,
    data_table,
    totals,
    invoice,
    is_preview,
  } = props;

  // Params
  const currency = CurrencySymbols().code;

  // CONTEXT
  const { getGuest } = useContext(MainContext);
  const { getMasterPrint } = useContext(MasterContext);

  // Use Ref
  const componentRef = useRef();
  const bill_type = FrontOfficeParams().bill_type;
  const accounting = AccountingParams();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [guest, setGuest] = useState(null);
  const [company, setCompany] = useState(null);
  const [allGuest, setAllGuest] = useState([]);
  const [guestList, setGuestList] = useState([]);
  const [dateRes, setDateRes] = useState({
    arrival: null,
    departure: null,
  });
  // Bill
  const [headers, setHeaders] = useState(null);
  const [dataBill, setDataBill] = useState([]);
  const [dataBillRaw, setDataBillRaw] = useState([]);
  const [pay, setPay] = useState({
    type: "",
    detail: "",
  });
  // Tax & Service
  const [taxService, setTaxService] = useState({
    tax: 0,
    service: 0,
    subTotal: 0,
    total: 0,
  });
  // Modals Boolean
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // FECTH DATA
  const fetchData = async (value) => {
    const _data = value ? value : data;

    await axios
      .get(`${baseurl}/invoice/payment-header-history`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (response) => {
        // console.log("Response: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _filtered = _res.filter((filt) => {
            if (filt.invoice_id == _data?.invoice_id) {
              return filt;
            }
          });

          console.log("Filtered: ", _filtered);
          console.log("Data: ", _data);

          if (_filtered.length > 0) {
            setHeaders(_filtered[0]);

            setPay({
              type: _filtered[0]?.payment_type_name,
              detail: _filtered[0]?.payment_card_name,
            });

            await fetchDetail(_filtered[0]);
          } else {
            setHeaders(null);
            setDataBill([]);
            setPay({
              type: "",
              detail: "",
            });
          }
        } else {
          setHeaders(null);
          setDataBill([]);
          setPay({
            type: "",
            detail: "",
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
      });

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  // Fetch Data Detail
  const fetchDetail = async (val) => {
    const invoice_id =
      typeof val?.invoice_id == "object" ? val.invoice_id[0] : val.invoice_id;
    console.log("Fetch Detail: ", invoice_id);

    await axios
      .post(
        `${baseurl}/invoice/payment-detail-history-by-invoice`,
        {
          invoice_id: invoice_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Details: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _filtered = _res.filter((filt) => {
            if (!filt.is_deleted || filt.is_deleted == null) {
              return filt;
            }
          });

          handleBill(_filtered);
          setDataBillRaw(_filtered);
        } else {
          setDataBill([]);
          setDataBillRaw([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // FETCH GUEST
  const fetchGuest = async (value) => {
    const _data = value;

    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        // console.log("Res Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const guest_name = _res.filter((items) => {
            if (!items.is_deleted) {
              return value;
            }
          });

          console.log("Guest --> ", guest_name);
          setAllGuest(guest_name);
        } else {
          setAllGuest([]);
        }
      },
      onReject: (error) => {
        console.log("Error => ", error);
      },
    });
  };

  // FETCH GUEST
  const fetchGuestList = async (value) => {
    const _data = value?.reservation_id ? value : data_guest;

    await axios
      .post(
        `${baseurl}/guest/checkout-history-by-reservation-id`,
        {
          reservation_id: _data?.reservation_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (response) => {
        console.log("Guest List History: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _filter = _res.filter((items) => {
            return !items.is_deleted && items.is_deleted != null;
          });

          const _sorting = _filter.sort((a, b) => a?.group - b?.group);

          console.log("Guest List: ", _sorting);

          setGuestList(_sorting);
          setDateRes({
            arrival: _sorting[0]?.arrival ? moment(_sorting[0]?.arrival) : null,
            departure: _sorting[0]?.departure
              ? moment(_sorting[0]?.departure)
              : null,
          });
        } else {
          setGuestList([]);
          setDateRes({
            arrival: null,
            departure: null,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
      });
  };

  // Fetch Company
  const fetchCompany = async (val) => {
    const _company_id = val?.master_comp_id;

    await getGuest({
      guest: "guest",
      type: "company-and-ota",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Company => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          let filtered = _response.filter((val) => {
            if (val?.id == _company_id) {
              return val;
            }
          });

          console.log("Companies: ", filtered);

          setCompany(filtered?.length > 0 ? filtered[0] : null);
        } else {
          setCompany(null);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (is_open == true && !cleanUp) {
      console.log("Data Invoice: ", data_guest);

      setOpen(true);
      setData(data_guest);
      setHeaders(data_guest);

      if (!is_preview) {
        fetchGuest(data_guest);
        fetchGuestList(data_guest);

        if (data_guest?.invoice_id?.length > 0) {
          fetchData(data_guest);
        } else {
          fetchDetail(data_guest);
        }
      }
    }

    if (data_table?.length > 0 && is_preview && !cleanUp) {
      console.log("Data Table: ", data_table);

      handleBill(data_table);
      // setDataBill(data_table);
    } else {
      setDataBill([]);
    }

    if (totals?.total > 0 && is_preview && !cleanUp) {
      setTaxService({
        service: totals.service,
        tax: totals.tax,
        total: totals.grand,
        subTotal: totals.total,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [is_open, data_guest, data_table, totals]);

  useEffect(() => {
    let cleanUp = false;

    if (allGuest.length > 0 && dataBillRaw.length > 0 && !cleanUp) {
      const receiver = findReceiver({ bill: dataBillRaw, guest: allGuest });

      console.log("Receiver: ", receiver);

      setGuest(receiver);

      fetchCompany(receiver);
    }

    return () => {
      cleanUp = true;
    };
  }, [allGuest, dataBillRaw]);

  // FETCH PRINT FORM
  const fetchPrint = async () => {
    await getMasterPrint({
      reservation_id: data_guest?.reservation_id || data?.reservation_id,
      price_id: data_guest?.price_id > 0 ? data_guest.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: data_guest[0] || data,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "item_quantity",
      width: 50,
      render: (quantity, record) => {
        return `${quantity > 0 ? quantity : 0}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      },
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (total, record) => {
        return `${total > 0 ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Table's Column
  const columnGuest = [
    {
      title: "Res. ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "BF",
      dataIndex: "is_abf",
      key: "is_abf",
      align: "center",
      width: 50,
      render: (abf) => {
        return (
          <Tag key={"abf-keytag"} color={abf === true ? `green` : `geekblue`}>
            {abf === true ? `RB` : `RO`}
          </Tag>
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 50,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (arrive, record) => {
        return moment(arrive).format("DD-MM-YYYY");
      },
    },
    {
      title: "Depart",
      dataIndex: "departure",
      key: "departure",
      render: (arrive, record) => {
        return moment(arrive).format("DD-MM-YYYY");
      },
    },
    // {
    //   title: "Guest Type",
    //   dataIndex: "group",
    //   key: "group",
    //   render: (group, record) => {
    //     let grp = "";
    //     let clr = "";

    //     switch (group) {
    //       case 0:
    //         grp = "Individual Guest";
    //         clr = "#52c41a";
    //         break;

    //       case 1:
    //         grp = "Group Leader";
    //         clr = "#1677ff";
    //         break;

    //       case 2:
    //         grp = "Group Member";
    //         clr = "#8c8c8c";
    //         break;

    //       default:
    //         break;
    //     }

    //     return (
    //       <Tag key={"key-tag"} color={clr}>
    //         {grp}
    //       </Tag>
    //     );
    //   },
    // },
  ];

  // Handle Bill
  const handleBill = (val) => {
    const _arr = val;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    const _newArticle = BillArticleShow({
      article: _billing,
      bill_type: bill_type,
    });

    console.log("Filtered: ", _newArticle);

    setTaxService({
      service: _newArticle.total.service,
      tax: _newArticle.total.tax,
      total: _newArticle.total.total_price,
      subTotal: _newArticle.total.unit_price,
    });

    setDataBill(_newArticle.article);

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  // FIND BILL RECEIVER
  const findReceiver = (value) => {
    const _listBill = value?.bill || [];
    const _listGuest = value?.guest || [];
    // Bucket Array
    let _arr = [];

    const bill = _listBill.reduce(
      (acc, init) => {
        if (!acc[init?.bill_receiver] && init.bill_receiver) {
          acc[init.bill_receiver] = true;

          acc.result.push(init.bill_receiver);
        }

        return acc;
      },

      { result: [] }
    ).result;

    console.log("Bill ID: ", bill);

    // BIll Receiver
    if (bill.length > 0) {
      const guest_name = _listGuest.filter((x) => {
        return bill.includes(x.id);
      });

      console.log("Bill: ", guest_name);

      _arr.push(guest_name[0]);
    }

    return _arr[0];
  };

  // ON FINISH
  const onFinish = () => {
    handlePrint();
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    // PrintCount({
    //   data: data,
    //   is_update: true,
    //   counter_list: {
    //     bill: countPrint?.bill + 1,
    //     pre_master_bill: countPrint?.pre_master_bill,
    //     pre_bill: countPrint?.pre_bill,
    //     checkout_letter_history: countPrint?.checkout_letter_history,
    //     registration_form: countPrint?.registration_form,
    //     guarantee_letter: countPrint?.guarantee_letter,
    //     thankyou_letter: countPrint?.thankyou_letter,
    //     master_bill: countPrint?.master_bill,
    //     reservation_letter: countPrint?.reservation_letter,
    //   },
    // });

    handlePrintOut();
  };

  // PRINT OUT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Invoice ${
      data?.invoice_id ? data.invoice_id : " - - - - - "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  // Handle Refresh
  const handleRefresh = async () => {
    setLoading(true);
    setIsLoading(true);

    await fetchData();
    await fetchGuestList();
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    form.resetFields();

    setHeaders(null);
    setCompany(null);
    setData(null);
    setPay({
      detail: "",
      type: "",
    });

    setDataBill([]);

    setIsLoading(true);
    setLoading(false);

    setOpen(false);
    is_close(false);
  };

  return (
    <>
      <Modal
        className="res-bill-modal"
        title={
          <>
            <Row>
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Receipt Invoice`}
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          onFinish={onFinish}
          autoComplete="off"
          name="bill-form-modal"
          key="bill-form-modal"
          className="bill-form-modal"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{
                padding: "15px 30px 15px",
              }}
            >
              <Row
                gutter={30}
                className="row-header"
                style={{
                  height: 160,
                }}
              >
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Look Hotel`}
                  </Typography.Title>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Bandung, Jawa Barat`}
                  </Typography>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Tel: (+62) 88 - ---`}
                  </Typography>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Fax: (00---)`}
                  </Typography>

                  <Typography className="text-trx-date" style={invoText}>
                    {`Email: look-support@hilook.support`}
                  </Typography>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                orientation="center"
                orientationMargin={0}
                style={{
                  margin: 0,
                  borderColor: "#d9d9d9",
                }}
              >
                <Typography.Title
                  level={2}
                  className="text-form-title"
                  underline
                >
                  {`INVOICE`}
                </Typography.Title>
              </Divider>

              <Row
                className="row-receipt-no"
                style={{
                  height: 65,
                  width: "100%",
                  margin: 0,
                }}
              >
                <Col span={12} className="col-receipt">
                  <Form.Item
                    label="Bill No."
                    className="receipt_number"
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-bill-id" style={textForm}>
                      {headers?.bill_master
                        ? headers.bill_master
                        : data?.bill_master || ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Invoice No."
                    className="invoice_number"
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-bill-id" style={textForm}>
                      {invoice ? invoice : " - - - - "}
                    </Typography>
                  </Form.Item>
                  {/* </Row>

              <Row
                className="row-date"
                style={{ height: 30, width: "100%", margin: 0 }}
              > */}
                  <Form.Item
                    label="Payment Due Date"
                    className="date"
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-trx-date" style={textForm}>
                      {headers?.payment_date
                        ? moment(LocalizationDate(headers.payment_date)).format(
                            "DD-MM-YYYY"
                          )
                        : " - - - - "}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col
                  span={12}
                  className="col-paid"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {
                    is_preview ? (
                      <Typography.Title
                        level={1}
                        className="preview-txt"
                        key="preview-txt"
                        style={{
                          color: "#f5222d",
                          border: "1px solid #f5222d",
                          borderRadius: 5,
                        }}
                      >
                        {`PREVIEW`}
                      </Typography.Title>
                    ) : null
                    //  headers?.is_paid ? (
                    //   <Image
                    //     className="paid"
                    //     src="/assets/logos/paid.jpg"
                    //     preview={false}
                    //     style={{
                    //       margin: 0,
                    //       width: 150,
                    //       height: 75,
                    //     }}
                    //   />
                    // ) : (
                    //   <Image
                    //     className="unpaid"
                    //     src="/assets/logos/unpaid.jpg"
                    //     preview={false}
                    //     style={{
                    //       margin: 0,
                    //       width: 150,
                    //       height: 75,
                    //     }}
                    //   />
                    // )
                  }
                </Col>
              </Row>

              <Row className="row-payment-title" justify="start">
                <Col span={12}>
                  <Typography
                    className="text-payment"
                    style={{
                      margin: "30px 0px 0px",
                      fontSize: 14,
                      fontWeight: 750,
                      // color: "grey",
                    }}
                  >
                    {`Payment Data`}
                  </Typography>
                </Col>

                <Col span={12}>
                  <Typography
                    className="text-guest"
                    style={{
                      margin: "30px 0px 0px",
                      fontSize: 14,
                      fontWeight: 750,
                    }}
                  >
                    {`Guest Data`}
                  </Typography>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                  backgroundImage:
                    countPrint?.bill >= 1
                      ? "url(/assets/logos/copyopac.png)"
                      : null,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  backgroundPosition: "center",
                  // transform: "rotate(45deg)",
                }}
              >
                <Col span={12} className="col-pay-type">
                  <Form.Item
                    label="Payment"
                    className="payment"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {`${
                        payment?.payment_type
                          ? payment.payment_type.toUpperCase()
                          : pay.type
                      } - by ${
                        payment?.payment_card_name
                          ? payment.payment_card_name.toUpperCase()
                          : pay.detail
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Arrival"
                    className="arrival"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {`${
                        dateRes.arrival
                          ? FormatDate({
                              time: dateRes.arrival,
                              params_date: 1,
                            })
                          : " - "
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Departure"
                    className="departure"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {`${
                        dateRes.departure
                          ? FormatDate({
                              time: dateRes.departure,
                              params_date: 1,
                            })
                          : " - "
                      }`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Res. No"
                    className="reservation_id"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {`${data?.reservation_id ? data.reservation_id : ""}`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Guest Name"
                    className="guest_name"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {`${
                        headers?.full_name
                          ? headers.full_name
                          : data?.full_name || ""
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Bill Receiver"
                    className="bill_receiver"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {`${guest?.full_name ? guest.full_name : ""}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Typography
                  style={{
                    margin: 0,
                    fontSize: 14,
                    fontWeight: 750,
                  }}
                >
                  {`Transaction Details`}
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="transaction-table"
                  key="transaction-table"
                  name="transaction-table"
                  loading={loading}
                  columns={columns}
                  dataSource={dataBill}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) =>
                    record?.id > 0 ? record.id : record.article_id
                  }
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary fixed>
                          {bill_type ? (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={6}>
                                  <b>Subtotal</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${taxService?.subTotal}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={6}>
                                  <b>Service</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${taxService?.service}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={6}>
                                  <b>Tax</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${taxService?.tax}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          ) : null}

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={5} />

                            <Table.Summary.Cell index={5}>
                              <b style={{ fontSize: 15 }}>Grand Total</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={6} align="right">
                              <b style={{ fontSize: 15 }}>
                                {`${currency} ${
                                  taxService?.total > 0 ? taxService.total : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </b>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      </>
                    );
                  }}
                />
              </Row>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: please, keep this invoice as your payment's warranty!
              </Typography>

              {bill_type ? null : (
                <Typography className="text-notes" style={{ fontSize: 10 }}>
                  *notes: all prices shown are already include tax & service
                </Typography>
              )}

              <Row
                className="signature"
                justify="space-between"
                // gutter={30}
                style={{
                  padding: "30px 0px 0px",
                }}
              >
                <Col
                  span={10}
                  className="company-col"
                  style={{
                    border: "solid 1px",
                    padding: 10,
                    borderRadius: 4,
                  }}
                >
                  <Row justify="center" className="row-bill-instruction">
                    <Typography className="text-bill-instruction">
                      {`Billing Instruction`}
                    </Typography>
                  </Row>

                  <Row className="row-company" style={{ height: 20 }}>
                    <Typography
                      className="text-company-name"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Company Name : ${
                        company?.company_name || " Unregistered to a Company "
                      }`}
                    </Typography>
                  </Row>

                  <Row className="row-phone" style={{ height: 20 }}>
                    <Typography
                      className="text-phone"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Phone : ${
                        company?.company_phone
                          ? company.company_phone
                          : guest?.phone_number
                      }`}
                    </Typography>
                  </Row>

                  <Row className="row-contact" style={{ height: 20 }}>
                    <Typography
                      className="text-contact-person"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Contact Person :
                      ${
                        company?.contact_person
                          ? company.contact_person + " - " + company?.cp_number
                          : guest?.full_name
                      }`}
                    </Typography>
                  </Row>

                  <Row
                    className="row-address"
                    // style={{ height: 20 }}
                  >
                    <Typography
                      className="text-address"
                      style={{
                        fontSize: 12,
                        fontWeight: 750,
                      }}
                    >
                      {`Address : ${
                        company?.address ? company.address : guest?.address
                      }`}
                    </Typography>
                  </Row>
                </Col>

                <Col
                  span={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {`Guest Signature`}

                  <div
                    className="box-sign"
                    style={{
                      border: "1px solid #000000",
                      borderRadius: 5,
                      height: 60,
                    }}
                  />

                  {`Sign at ${
                    !is_preview
                      ? moment().format("DD-MM-YYYY")
                      : moment(headers?.payment_date).format("DD-MM-YYYY") || ""
                  }`}
                </Col>
              </Row>

              <div className="table-break" />

              <Row justify="start">
                <Typography
                  style={{
                    margin: "30px 0px 0px",
                    fontSize: 14,
                    fontWeight: 750,
                  }}
                >
                  {`GUEST LIST`}
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, background: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="guest-table"
                  key="guest-table"
                  name="guest-table"
                  loading={loading}
                  columns={columnGuest}
                  dataSource={guestList}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) => (record?.id > 0 ? record.id : record.key)}
                />
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: 0, margin: "0px 30px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              disabled={dataBill.length > 0 && !isLoading ? false : true}
              htmlType="submit"
              icon={<PrinterOutlined />}
              style={{
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="refresh-btn"
              type="default"
              hidden={is_preview}
              disabled={headers && !isLoading ? false : true}
              icon={<SyncOutlined />}
              onClick={handleRefresh}
              style={{
                marginRight: 30,
              }}
            >
              {`Refresh`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              disabled={isLoading}
              onClick={handleCancel}
            >
              {`Close`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
