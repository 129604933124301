// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// REACT ICONS
import { FiEdit } from "react-icons/fi";
import { SyncOutlined } from "@ant-design/icons";

// NOTIFICATIONS
import { failedFetch } from "../../Reusable/Notification/Notification";

import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// STYLES
const inputStyle = {
  fontWeight: 750,
};

// CODE
export default function GuestAccountBalanceModal(props) {
  // PROPS
  const { dataDetail, openModal, closeModal, is_refresh } = props;

  // CONTEXT
  const { getByPostNightAudit } = useContext(MainContext);

  // PARAMS
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // Data
  const [data, setData] = useState([]);
  // Tax & Service
  const [total, setTotal] = useState({
    tax: 0,
    service: 0,
    total: 0,
    sub: 0,
  });
  // Boolean State
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // Params

  // USEEFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal && !cleanUp) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (dataDetail && !cleanUp) {
      console.log("Data Detail:", dataDetail);
      setFormFields(dataDetail);
      fetchData();
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataDetail]);

  const setFormFields = (data) => {
    form.setFieldsValue({
      reservation_id: `${data?.reservation_id}`,
      guest_name: data?.guest_name,
      room: `${data?.room_category} - ${data?.room_number}`,
      adult: data?.adult,
      child: data?.child,
      infant: data?.infant,
      deposit: data?.deposit,
      arrival: moment(data?.arrival).format("DD-MM-YYYY"),
      departure: moment(data?.departure).format("DD-MM-YYYY"),
      night: data?.night,
    });
  };

  // FETCH DATA N/A GUEST ACCOUNT DETAIL
  const fetchData = async () => {
    await getByPostNightAudit({
      type: "detail-guest-account-balance",
      reservation_id: dataDetail?.reservation_id,
      price_id: dataDetail?.price_id,
      onAwait: () => {
        setLoading(true);
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          // setData(_response);
          handleFilter(_response);
        } else {
          setData([]);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log("Error: ", error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // Handle Close
  const handleClose = () => {
    setOpen(false);
    closeModal();
    setTotal({
      service: 0,
      sub: 0,
      total: 0,
      tax: 0,
    });

    form.resetFields();
  };

  // TABLE COLUMNS
  const columns = [
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      sortDirections: ["descend", "ascend"],
      render: (date, record) => {
        return date !== null || date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      render: (qty) => {
        return qty > 0 ? `${qty}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
    {
      title: `Price (${currency})`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      render: (gross) => {
        return gross > 0 ? `${gross}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
      },
    },
  ];

  // Handle Filter Data
  const handleFilter = (val) => {
    const _data = val;

    // Array Buckets
    var _merge = [];
    let _all = [];

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // GROUP BY ARTICLE ID
    const _filtered = Object.groupBy(_data, (items) => items.article_id);

    // FIND MAIN ARTICLES
    for (const key in _filtered) {
      const element = _filtered[key];

      let _newObj = {};

      const res = element.forEach(function (_objects, _index) {
        for (let _keys in _objects) {
          const _article_names = _objects?.article_name
            ? _objects.article_name.toLowerCase()
            : null;

          if (_keys.includes("article_name") && _article_names) {
            _newObj[_objects[_keys]] = _objects?.article_name;
            // _newObj[_keys + "_" + (_index + 1)] = _objects[_keys];
          }

          if (_objects[_keys] != null) {
            if (_article_names.includes(_tax_names)) {
              _newObj["tax"] = _objects["credit"];
              // _newObj["tax"] = _objects["credit"] / _objects["quantity"];
            } else if (_article_names.includes(_service_names)) {
              _newObj["service"] = _objects["credit"];
              // _newObj["service"] = _objects["credit"] / _objects["quantity"];
              _newObj["article_service_name"] = _objects["article_name"];
            } else {
              _newObj[_keys] = _objects[_keys];
              // _newObj["unit"] = _objects["credit"] / _objects["quantity"];
              _newObj["total"] = _objects["credit"];
              _newObj["id"] = _objects["article_id"];
              _newObj["quantity"] = _objects["quantity"];
            }
          }
        }
      });

      _merge.push(_newObj);
    }

    let _new = _merge.map((items) => {
      return {
        ...items,
        total_price: items.total + items?.service + items?.tax,
        unit_price: items.total,
      };
    });

    _all = _new;

    let _ser = 0;
    let _taxes = 0;
    let _nett = 0;
    let _total = 0;

    const findTotal = _all.forEach(
      ({ tax, service, unit_price, total_price }) => {
        _taxes += tax;
        _ser += service;
        _nett += unit_price;
        _total += total_price;
      }
    );

    console.log("Grouping Res: ", _all);
    console.log("Total Price: ", findTotal);

    setData(_all);
    setTotal({
      service: _ser,
      tax: _taxes,
      total: _total,
      sub: _nett,
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  };

  return (
    <>
      <Modal
        className="guest-account-modal"
        key="guest-account-modal"
        title={
          <>
            <Row className="modal-title-row" justify="start" align="middle">
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography
                className="modal-txt"
                style={{ marginLeft: 15 }}
              >{`Guest Account Balance Detail`}</Typography>
            </Row>
          </>
        }
        centered
        footer={null}
        open={open}
        closable
        onCancel={handleClose}
        width={1100}
      >
        <Form
          className="guest-account-form"
          key="guest-account-form"
          name="guest-account-form"
          layout="horizontal"
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{ padding: "0px 36px" }}
        >
          {!isLoading ? (
            <Row className="form-row" justify="center" align="top">
              <Col className="form-col" span={24}>
                <Row
                  className="form-row"
                  gutter={30}
                  align="middle"
                  justify="start"
                >
                  <Col span={6} className="form-col">
                    <Form.Item label="Reservation" name="reservation_id">
                      <Input readOnly bordered style={inputStyle} />
                    </Form.Item>
                  </Col>

                  <Col span={6} className="form-col">
                    <Form.Item label="Room" name="room">
                      <Input readOnly bordered style={inputStyle} />
                    </Form.Item>
                  </Col>

                  <Col span={8} className="form-col">
                    <Form.Item label="Guest Name" name="guest_name">
                      <Input readOnly bordered style={inputStyle} />
                    </Form.Item>
                  </Col>

                  <Col span={4} className="form-col">
                    <Form.Item label="Night" name="night">
                      <Input readOnly bordered style={inputStyle} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="form-row" gutter={0} justify="start">
                  <Col span={6} className="form-col">
                    <Form.Item label="Deposit" name="deposit">
                      <InputNumber
                        readOnly
                        bordered={false}
                        addonBefore={`${currency}`}
                        style={{
                          width: "100%",
                          fontWeight: "750px !important",
                        }}
                        formatter={(deposit) => {
                          return `${deposit}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={3} className="form-col">
                    <Form.Item label="Adult" name="adult">
                      <InputNumber
                        style={{ width: "100%", fontWeight: 750 }}
                        formatter={(adult) => {
                          return `${adult}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                        readOnly
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={3} className="form-col">
                    <Form.Item label="Child" name="child">
                      <InputNumber
                        style={{ width: "100%", fontWeight: 750 }}
                        formatter={(child) => {
                          return `${child}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                        readOnly
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={3} className="form-col">
                    <Form.Item label="Infant" name="infant">
                      <InputNumber
                        style={{ width: "100%", fontWeight: 750 }}
                        formatter={(infant) => {
                          return `${infant}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                        }}
                        readOnly
                        bordered={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4} className="form-col">
                    <Form.Item label="Arrival" name="arrival">
                      <Input readOnly bordered={false} style={inputStyle} />
                    </Form.Item>
                  </Col>

                  <Col span={5} className="form-col">
                    <Form.Item label="Departure" name="departure">
                      <Input readOnly bordered={false} style={inputStyle} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  orientation="left"
                  orientationMargin={0}
                  style={{ margin: "15px 0px 10px", borderColor: "#EBEDF3" }}
                >
                  {`Detail`}
                </Divider>

                <Row className="table-row" justify="start">
                  <Col span={24} className="table-col">
                    <Table
                      className="guest-account-table"
                      name="guest-account-table"
                      key="guest-account-table"
                      loading={loading}
                      bordered
                      columns={columns}
                      dataSource={data}
                      size="small"
                      pagination={false}
                      scroll={{
                        x: true,
                      }}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "odd" : "even";
                      }}
                      rowKey={(record) =>
                        record.id ? record.id : record.department_id
                      }
                      summary={() => {
                        return (
                          <>
                            <Table.Summary fixed>
                              {bill_type ? (
                                <>
                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3} />

                                    <Table.Summary.Cell index={5}>
                                      <b>Subtotal</b>
                                    </Table.Summary.Cell>

                                    <Table.Summary.Cell index={5} align="right">
                                      {`${currency} ${total?.sub}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>

                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3} />

                                    <Table.Summary.Cell index={5}>
                                      <b>Service</b>
                                    </Table.Summary.Cell>

                                    <Table.Summary.Cell index={5} align="right">
                                      {`${currency} ${total?.service}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>

                                  <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3} />

                                    <Table.Summary.Cell index={5}>
                                      <b>Tax</b>
                                    </Table.Summary.Cell>

                                    <Table.Summary.Cell index={5} align="right">
                                      {`${currency} ${total?.tax}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </Table.Summary.Cell>
                                  </Table.Summary.Row>
                                </>
                              ) : null}

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3} />

                                <Table.Summary.Cell index={4}>
                                  <b
                                    style={{ alignSelf: "end" }}
                                  >{`TOTAL TRANSACTIONS`}</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={0} align="right">
                                  <b>
                                    {`${currency} ${total?.total}`.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </b>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </Table.Summary>
                          </>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "30px 0px 0px" }}
          >
            <Button
              className="cancel-btn"
              type="default"
              disabled={loading}
              onClick={() => {
                fetchData();
              }}
              icon={<SyncOutlined />}
              style={{
                margin: "0px 15px 0px 0px",
              }}
            >
              {`Refresh`}
            </Button>

            <Button className="cancel-btn" type="default" onClick={handleClose}>
              {`Close`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
