// Import React Components
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { user_name, token } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

// Import React Icons
import { AiOutlineInfoCircle } from "react-icons/ai";

// Import Notification
import {
  failedFetch
} from "../../../Reusable/Notification/Notification";
import {
  masterSuccessNotification,
  masterFailedNotification
} from "../../../Reusable/Notification/MasterNotif/Notification";

export default function CaptainModalJoinDestination(props) {
  // PROPS
  const { openModal, closeModal, options, getClear, selectedTable } = props;

  // CONTEXT
  const { getGuestInHouse } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(false);
  
  const [key, setKey] = useState(0);

  // USE FORM
  const [form] = Form.useForm();
  const { Text } = Typography;

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
      fetchDataGuestInHouse();
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal]);

  useEffect(() => {
    console.log("options", options)
  },[options]);

  useEffect(() => {
    console.log("selected", selected);
  }, [selected]);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  useEffect(() => {
    console.log("key", key);
  }, [key]);

  // FETCH DATA GUEST IN HOUSE
  const fetchDataGuestInHouse = async () => {
    await getGuestInHouse({
      guestInHouse: "guest-in-house",
      type: "stay",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            return !filt.is_deleted;
          });

          setGuestData(filtered);
          // setBulks(filtered);
        } else {
          setGuestData([]);
          // setBulks([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
        failedFetch(error);
      },
    });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);
    setSelected(null);
    
    form.resetFields();
  };
  
  // HANDLE CANCEL
  const handleRefresh = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);
    getClear();
    setSelected(null);
    selectedTable(selected);

    form.resetFields();
  };

  // ON FINISH
  const onFinish = (e) => {
    console.log("ABOUT TO JOIN ", e);
    
  };

  const onFinishFailed = (e) => {
    console.log("ERROR", e)
  };

  const handleSubmit = () => {
    console.log("handleSubmit", selected.order_id_alias)
    fetchOrder(selected.order_id_alias);
  }

  const fetchOrder = async (val) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        order_id_alias: val,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.g_pos_article_detail.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const _resMap = _res.map((x, i) => {
            
            return {
              ...x,
              serving_que: x.serving_order,
            }
          });

          console.log("_resMap", _resMap);
          handleData( val, _resMap)
        } else {
          null
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        masterFailedNotification(error, {
          method: 0,
          source: `fetchOrder`,
        });
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 100);
      });
  };

  const handleData = async (val, dataSelected) => {
    let bulksData = [];
    let filter = options.filter((x) => {
      if(x.order_id_alias == val){
        return false;
      } else {
        return true;
      }
    });

    console.log("filter", filter);

    for(let i = 0; i < filter.length; i++){
      await axios
        .post(`${baseurl}/pos/detail-order-id`, {
          order_id_alias: filter[i].order_id_alias,
        })
        .then((response) => {
          console.log("Bill Selected: ", response);

          if (response.data?.msg?.length > 0) {
            const _res = response.data.msg[0]?.g_pos_article_detail.filter(
              (e) => {
                if (
                  e.is_deleted == false &&
                  e.is_paid == false &&
                  e.bill_status == 0
                ) {
                  return e;
                }
              }
            );

            const map = _res.map((x) => {
              return {
                ...x,
                serving_que: x.serving_order,
              }
            });

            map.map((x) => bulksData.push(x));
            

            console.log("bulks", bulksData);

          } else {
            null
          }
        })
        .catch((error) => {
          console.log("Error tables: ", error);

          masterFailedNotification(error, {
            method: 0,
            source: `fetchOrder`,
          });
        })
        .finally(() => {
          setTimeout(() => setLoading(false), 100);
        });
    }
    
    handlePush(bulksData, dataSelected, val);
  };

  const handlePush = (bulksData, dataSelected, val) => {
    console.log("dataSelected", dataSelected, "bulks", bulksData);

    let matchedItems = [];
    let unmatchedItems = [];

    // Fungsi untuk menemukan apakah suatu item ada dalam array berdasarkan article_name
    const findItem = (array, article_id, serving_que, order_step) => {
      return array.find(item => item.article_id == article_id && item.serving_que == serving_que && item.order_step == order_step);
    }

    bulksData.forEach(item => {
      
      let selectedItem = findItem(dataSelected, item.article_id, item.serving_que, item.order_step); //cek apakah ada item yang sama antara data di bulksData dan di dataSelected 
      
      if(selectedItem) {
         
        let matchedItem = findItem(matchedItems, item.article_id, item.serving_que, item.order_step); //cek apakah ada item yang sama antara data di matchedItems dan dataSelected. Kemungkinan sama jika join minimal 3 order.
        if(matchedItem) {
          matchedItem.item_quantity += item.item_quantity;
          
          matchedItem.notes= matchedItem?.notes && item?.notes ? matchedItem.notes + "," + item.notes :
               (matchedItem?.notes == null || matchedItem?.notes == "undefined") && item?.notes  ? item?.notes :
               matchedItem?.notes && (item?.notes == null || item?.notes == "undefined") ? matchedItem.notes :
               null;
        } else {
          matchedItems.push({
            id: selectedItem.id,
            article_id: selectedItem.article_id,
            article_name: selectedItem.article_name,
            serving_que: selectedItem.serving_que,
            item_quantity: selectedItem.item_quantity + item.item_quantity,
            gross: selectedItem.gross,
            tax: selectedItem.tax,
            service: selectedItem.service,
            is_tax: selectedItem.is_tax,
            is_service: selectedItem.is_service,
            order_step: selectedItem.order_step,
            // notes: selectedItem.notes + ", " + item.notes,
            notes: selectedItem.notes && item.notes ? selectedItem.notes + ", " + item.notes :
            (selectedItem?.notes == null || selectedItem?.notes == "undefined") && item?.notes ? item?.notes :
            selectedItem?.notes && (item?.notes == null || item?.notes == "undefined") ? selectedItem?.notes :
            null,
          })
        }
      } else {
        let unmatchedItem = findItem(unmatchedItems, item.article_id, item.serving_que, item.order_step);
        if(unmatchedItem) {
          unmatchedItem.item_quantity += item.item_quantity
        } else {
          unmatchedItems.push({
            ...item,
          })
        }
      }
    });

    console.log("matchedItems", matchedItems, "unmatchedItems", unmatchedItems);
    handleMatched(matchedItems, unmatchedItems, val);
  };

  // HANDLE MATCHED
  const handleMatched = async (matchedItems, unmatchedItems, val) => {
    if(matchedItems.length > 0) {
      for (let i = 0; i < matchedItems.length; i++) {
        await axios
          .put(
            `${baseurl}/pos/order-detail`,
            {
              deleted_reason: "JOIN ITEM", //Available
              notes: matchedItems[i].notes, //Available
              id: matchedItems[i].id, //Available
              created_by: user_name, //Available
              item_quantity: matchedItems[i].item_quantity, //Available
              serving_order: matchedItems[i].serving_que, //Available
              total_price: matchedItems[i].gross * matchedItems[i].item_quantity, //Available
              tax: matchedItems[i].tax, //Available
              service: matchedItems[i].service, //Available
              is_tax: matchedItems[i].is_tax, //Available
              is_service: matchedItems[i].is_service, //Available
              gross: matchedItems[i].gross, //Available
              add_ons: matchedItems[i].notes, //Available
              order_step: matchedItems[i].order_step,
            },
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("Update Detail: ", response);
              let successCount = i + 1;

              if(successCount == matchedItems.length) {
                handleUnmatched(unmatchedItems);
              }
            } else {
              masterFailedNotification(response, {
                method: 1,
                source: `Captain Order Item`,
              });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            masterFailedNotification(error, {
              method: 1,
              source: `Captain Order Item`,
            });
          })
      }
    } else {
      handleUnmatched(unmatchedItems, val);
    }
  };

  const handleUnmatched = async (unmatchedItems, val) => {
    console.log("handleUnmatched");
    let bulks = [];

    console.log("guestData", guestData);

    let filterGuestData = guestData.filter((x) => {
      if(x.reservation_id == selected?.reservation_id && x.guest_name == selected?.name){
        return true
      } else {
        return false
      }
    });

    console.log("filterGuestData: ", filterGuestData);

    let room_number = filterGuestData.length > 0 ? filterGuestData[0]?.room_number : null;

    console.log("room_number", room_number);

    if(unmatchedItems.length > 0) {
      for (let i = 0; i < unmatchedItems.length; i++) {
        bulks.push({
          id: unmatchedItems[i]?.article_id,
          article_id: unmatchedItems[i]?.article_id,
          article_sub_name: unmatchedItems[i]?.article_sub_name,
          article_sub_id: unmatchedItems[i]?.article_sub_id,
          article_name: unmatchedItems[i]?.article_name,
          unit_price: unmatchedItems[i]?.unit_price,
          item_quantity: unmatchedItems[i]?.item_quantity,
          total_price: unmatchedItems[i]?.gross * unmatchedItems[i]?.item_quantity,
          table_code: selected?.table_code || "No Tables",
          table_id: selected?.table_id || null,
          serving_order: unmatchedItems[i]?.serving_que,
          guest_name: selected?.name,
          chart_of_account: unmatchedItems[i]?.chart_of_account,
          tax: unmatchedItems[i]?.tax,
          service: unmatchedItems[i]?.service,
          is_tax: unmatchedItems[i]?.tax > 0 ? true : false,
          is_service: unmatchedItems[i]?.service > 0 ? true : false,
          gross: unmatchedItems[i]?.gross,
          add_ons: unmatchedItems[i]?.notes || null,
          notes: unmatchedItems[i]?.notes || null,
          payment_status: selected?.payment_status,
          order_id_alias: selected?.order_id_alias,
          header_id: selected?.id,
          created_by: user_name,
          order_step: unmatchedItems[i]?.order_step,
          source: 2,
          room_number: room_number,
        });
      }

      console.log("bulks", bulks);

      await axios
      .post(
        `${baseurl}/pos/order-detail`,
        {
          data: bulks.length > 0 ? bulks : null,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("Add Detail: ", response);

        if (response.status === 200) {
            handleDeleteOrder(val);
        } else {
          masterFailedNotification(response, {
            method: 0,
            source: `Captain Order Item`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 0,
          source: `Captain Order`,
        });
      })
    } else {
      handleDeleteOrder(val);
    }
  };

  const handleDeleteOrder = async (val) => {
    let filterDelete = options.filter((x) => {
      if(x.order_id_alias == selected.order_id_alias) {
        return false;
      } else {
        return true;
      };
    });

    for(let i = 0; i < filterDelete.length; i++) {
      let obj =
          {
            order_id_alias: filterDelete[i]?.order_id_alias,
            deleted_by: user_name,
            deleted_reason: "",
          };

      await axios
        .delete(`${baseurl}/pos/order-header`, {
          data: obj,
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("DEL POS Bill Header >> ", response);
            let successCount = i + 1;
            if(successCount == filterDelete.length) {
              // masterSuccessNotification("", {
              //   method: 0,
              //   source: `Join Captain Order Item`,
              // });
              // selectedTable(selected);
              // handleRefresh();
              checkStatusItem(val);
            }
          }
        })
        .catch((error) => {
          console.log("ERROR DEL POS Bill Header >> ", error);
          masterFailedNotification(error, {
            method: 2,
            source: `Join Captain Order Item`,
          });
        });
    }
  };

  const checkStatusItem = async (val) => {
    await axios
      .post(`${baseurl}/pos/detail-order-id`, {
        order_id_alias: val,
      })
      .then((response) => {
        console.log("Bill Selected: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg[0]?.g_pos_article_detail.filter(
            (e) => {
              if (
                e.is_deleted == false &&
                e.is_paid == false &&
                e.bill_status == 0
              ) {
                return e;
              }
            }
          );

          const check = _res.every((x) => x.order_step == 2)

          console.log("checkRes", _res);
          console.log("checkFinal", check);
          console.log("checkSelected", selected);
          handleUpdate(_res, check)
        } else {
          null
        }
      })
      .catch((error) => {
        console.log("Error tables: ", error);

        masterFailedNotification(error, {
          method: 0,
          source: `fetchOrder`,
        });
      })
  };

  const handleUpdate = async (details, check) => {
    let filterGuestData = guestData.filter((x) => {
      if(x.reservation_id == selected?.reservation_id && x.guest_name == selected?.name){
        return true
      } else {
        return false
      }
    });

    console.log("filterGuestData: ", filterGuestData);

    let room_number = filterGuestData.length > 0 ? filterGuestData[0]?.room_number : null;

    console.log("room_number", room_number);

    const obj = {
      id: selected?.id,
      name: selected?.name,
      table_code: selected?.table_code,
      table_id: selected?.table_id,
      payment_type: null,
      payment_details: null,
      payment_status: selected?.payment_status,

      total_nett: details
        .map((e) => ({
          nett: e.unit_price * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.nett, 0),

      total_gross: details
        .map((e) => ({
          gross: e.gross * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.gross, 0),

      total_tax: details
        .map((e) => ({
          tax: e.tax * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.tax, 0),

      total_service: details
        .map((e) => ({
          service: e.service * e.item_quantity ?? 0,
        }))
        .reduce((prev, v) => prev + v.service, 0),

      is_open_bill: selected?.is_open_bill,
      sales_type: selected?.sales_type, // Variable yang diupdate
      updated_by: user_name,
      old_table_code: selected?.table_code,
      old_table_id: selected?.table_id,
      reservation_id: selected?.reservation_id,
      pax: selected?.pax, // Variable yang diupdate
      order_status: selected?.order_status,
      kitchen_status: check ? 2 : 1,
      segment: selected?.segment,
      source: selected?.source,
      room_number: room_number,
    };
    console.log("handleUpdate > obj:", obj);

    await axios
      .put(
        `${baseurl}/pos/order-header`, obj,
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )

      .then(async (response) => {
        if (response.status === 200) {
          console.log("Edit Header: ", response);
          masterSuccessNotification("", {
            method: 0,
            source: `Join Captain Order Item`,
          });
          handleRefresh();
        } else {
          masterFailedNotification(response, {
            method: 1,
            source: `Captain Order`,
          });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        masterFailedNotification(error, {
          method: 1,
          source: `Captain Order`,
        });
      })
  }



  return (
    <>
      <Modal
        className="cancel-order-modal"
        title={
          <>
            <Row className="cancel-order-modal-row">
              <AiOutlineInfoCircle
                style={{
                  color: "#f5222d",
                  fontSize: 24,
                  // margin: "0px 22px 0px 15px",
                }}
              />
              <Typography
                style={{ marginLeft: 15 }}
              >{`Join Destination`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={500}
      >
        <Form
          className="purchase-ord-form"
          key="purchase-ord-form"
          name="purchase-ord-form"
          layout="horizontal"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={
            {
              // deleted_reason: "CANCEL ORDER",
            }
          }
          style={{ padding: "0px 36px" }}
        >
          <Row
            className="cancel-order-modal-form-serve-row"
            //   justify="center"
            //   gutter={[30, 30]}
            style={{ margin: "0px 40px 10px 0px" }}
          >
            <Text>{`Are you sure you want to join orders?`}</Text>
          </Row>

          <Row
            className="serve-row"
            justify="center"
            style={{ margin: "0px 40px" }}
          >
            <Col span={24} className="serve-col">
              <Form.Item
                label="Select Destination Order"
                name={"deleted_reason"}
                rules={[
                  {
                    required: true,
                    message: "Please, Select an Option!",
                  },
                ]}
              >
                <Select
                  placeholder={"Select Option"}
                  onChange={(e, record) => {setSelected(record)}}
                  options={options.map((x) => {
                    return {
                      ...x,
                      value: x.order_id_alias,
                      label: x.order_id_alias + " // " + x.name,
                    }
                  })}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="cancel-order-modal-form-divider-form"
            style={{ margin: "0px 0px 10px 0px" }}
          />

          <Row justify="end" align="middle">
            <Button
              // type="primary"
              // htmlType="submit"
              // key={"submit"}
              onClick={handleSubmit}
              loading={loading}
              className="submit-btn"
              style={{
                marginRight: 30,
              }}
            >
              {`Yes`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`No`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
