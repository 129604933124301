// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import { baseurl } from "../../../../../API/Config/Api";
import { token } from "../../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Steps,
  Progress,
  Alert,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Import Global Styles
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CODE
export default function InHouseAuditTable(props) {
  // PROPS
  const { dataInHouse, dateAudit, is_reset, is_refresh } = props;

  // COLUMN
  const columns = [
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
    },
    {
      title: "Room Cat",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (arrival) => {
        return moment(arrival).format("DD-MM-YYYY");
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (departure) => {
        return moment(departure).format("DD-MM-YYYY");
      },
    },
    {
      title: "Night Qty",
      dataIndex: "night",
      key: "night",
    },
    {
      title: "Show Status",
      dataIndex: "is_no_show",
      key: "is_no_show",
      render: (isShow) => {
        return isShow === false ? "Showing" : "No Show";
      },
    },
    {
      title: "Status",
      dataIndex: "reservation_status",
      key: "reservation_status",
    },
  ];

  // CONTEXT
  const { getNightAudit } = useContext(MainContext);

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [date_audit, setDate_Audit] = useState(null);
  // BOOLEAN
  const [loading, setLoading] = useState(false);

  // Fetch Data Night Audit Room Rate
  const fetchData = async () => {
    await getNightAudit({
      type: "in-house-list",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          setData(_response);
          dataInHouse(_response);
        } else {
          setData([]);
          dataInHouse([]);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // Fetch Data Re-Night Audit
  const fetchReAudit = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseurl}/re-night-audit/in-house-list`,
        {
          date: moment(dateAudit).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          setData(_response);
          dataInHouse(_response);
        } else {
          dataInHouse([]);
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dateAudit != date_audit && !cleanUp) {
      setDate_Audit(dateAudit);
      fetchReAudit();
    } else {
      if (date_audit) {
        setData([]);
        dataInHouse([]);
      } else {
        fetchData();
      }
    }

    if (is_refresh && !cleanUp) {
      fetchData();
      is_reset(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [dateAudit, is_refresh]);

  return (
    <>
      <Row style={{ marginTop: 30, width: "100%" }}>
        <Table
          className="na-inhouselist-table"
          key="na-inhouselist-table"
          name="na-inhouselist-table"
          loading={loading}
          columns={columns}
          dataSource={data}
          bordered
          size="small"
          pagination={false}
          scroll={{
            x: true,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) => (record.id ? record.id : record.key)}
        />
      </Row>

      <Row justify="end" style={{ padding: "5px 30px" }}>
        <Typography style={{ color: "#000000", fontWeight: 750 }}>
          {`Showing total of ${data?.length || 0} Data (Entries)`}
        </Typography>
      </Row>
    </>
  );
}
