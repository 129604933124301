import moment from "moment";

// CODE
function FilterEarlyCO(props) {
  // PROPS
  const { article_stay, article_room, isAbf, bf_id } = props;

  // Declare Variables
  let _newBill = [];
  const _today = moment().format("YYYY-MM-DD");

  console.log("About to Early: ", props);

  const newRoom = article_room.filter((items) => {
    let _date = moment(items.date).format("YYYY-MM-DD");
    let _newToday = moment(_today).subtract(1, "days").format("YYYY-MM-DD");

    if (moment(_newToday).isBefore(_date, "days") == false) {
      return items;
    }
  });

  const newArticles = article_stay.filter((items) => {
    let _date = moment(items?.order_date).format("YYYY-MM-DD");
    let _newToday = _today;

    if (isAbf) {
      _newToday = _today;
    } else {
      _newToday = moment(_today).subtract(1, "days").format("YYYY-MM-DD");
    }

    if (items.article_id == bf_id) {
      if (moment(_newToday).isBefore(_date, "days") == false) {
        return items;
      }
    } else {
      if (moment(items?.order_date).isBefore(_today, "days") == false) {
        return items;
      }
    }
  });

  console.log("Article Room: ", newRoom);
  console.log("Article Stay: ", newArticles);

  return { room: newRoom, article: newArticles };
}

// FILTER ARTICLES
function FilterEarlyArticle(props) {
  // PROPS
  const { article, isAbf, bf_id } = props;

  const _today = moment().format("YYYY-MM-DD");
  const _deleted = [];
  const _paramsBF = bf_id.toLowerCase() || null;

  console.log("About to Early: ", props);

  const newArticle = article.filter((items) => {
    let _date = moment(items.date).format("YYYY-MM-DD");
    let _newToday = moment(_today).subtract(1, "days").format("YYYY-MM-DD");
    let _names = items?.article_name ? items.article_name.toLowerCase() : null;

    if (_names.includes(_paramsBF)) {
      if (isAbf) {
        _newToday = _today;
      } else {
        _newToday = moment(_today).subtract(1, "days").format("YYYY-MM-DD");
      }

      if (moment(_newToday).isBefore(_date, "days") == false) {
        return items;
      } else {
        _deleted.push(items);
      }
    } else {
      let todayRoom = moment(_today).subtract(1, "days").format("YYYY-MM-DD");

      if (moment(todayRoom).isBefore(_date, "days") == false) {
        return items;
      } else {
        _deleted.push(items);
      }
    }
  });

  return { article: newArticle, else: _deleted };
}

export { FilterEarlyArticle, FilterEarlyCO };
