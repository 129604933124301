// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import {
  Button,
  Col,
  Layout,
  Row,
  Typography,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import Notifications
import {
  failedFetch,
} from "../../../../Components/Reusable/Notification/Notification";

// CONTEXT
import { Context as MasterContext } from "../../../../API/Context/MasterContext/MasterContext";

// Import Page Components
import ModalOutletLocation from "../../../../Components/Modals/Outlet/OutletLocation/Location/CaptainOrderModal";

// Import Global Border
import { cardBody, cardHeader } from "../../../../Style/Global/Global";
import { IoReturnUpBackOutline } from "react-icons/io5";
import { MdFoodBank } from "react-icons/md";
import CaptainOrderTable from "../../../../Components/Table/Outlet/Cashier/Main/CaptainOrderTable";
import { ArrowRightOutlined } from "@ant-design/icons";

// CODE
export default function CaptainOrderPage() {

  // CONTEXT
  const { getMasterRoomCat, getTableCategory, getParameters } = useContext(MasterContext);

  // USE NAVIGATE
  const navigate = useNavigate();

  // STATE MANAGEMENT

  // Status
  const [outletLocation, setOutletLocation] = useState("UNSELECTED");
  const [shift, setShift] = useState({ name: null, id: 0, });
  const [isShow, setIsShow] = useState(false);

  // Data
  const [locationCategories, setLocationCategories] = useState([]);
  
  // Modal State
  const [open, setOpen] = useState(false);

  // Loading

  // USEEFFECTS
  // useEffect Cashier Page is Open
  useEffect(() => {
    let cleanUp = false;
  
    const fetchData = async () => {
      await getParameters({
        params: "parameters",
        type: "shift",
        onAwait: () => {},
        onSuccess: (response) => {
          if (!cleanUp) {
            console.log("Shift --> ", response);
  
            if (response?.data?.msg?.length > 0) {
              let _res = response.data.msg;
              console.log("_res", _res);
              handleSetParameter(_res);
            }
          }
        },
        onReject: (error) => {
          console.log("Params Shift --> ", error);
          failedFetch(error);
        },
      });
    };
  
    fetchData();
  
    return () => {
      cleanUp = true;
    };
  }, []);

  // Handle Select
  const handleOpen = () => {
    setOpen(true);
  };


  // Handle Select
  const handleSelect = (value) => {
    console.log("Selected Data: ", value);

    if (value?.outlet_location && value?.outlet_id > 0) {
      setOutletLocation(value?.label_outlet_location);

      sessionStorage.setItem("label_outlet_location", value?.label_outlet_location);
      sessionStorage.setItem("id_outlet_location", value?.outlet_id);
      if(value?.label_outlet_location.toLowerCase().includes("room")){
        fetchRoom();
      } else {
        fetchOutlet(value?.outlet_id)
      }
      setIsShow(true);
    }
  };

  const handleNavigateReturn = () => {
    const path = "/f-and-b/outlet/";
    
    navigate(`${path}`);
  };

  const handleSetParameter = (val) => {
    let { currentShift } = getCurrentAndPreviousShift(val);
    let currentOutletLoc = sessionStorage.getItem("label_outlet_location"); 
    let currentOutletID = sessionStorage.getItem("id_outlet_location"); 
    
    console.log("currentShift", currentShift);
    
    setShift({
      name: currentShift.shift_name,
      id: currentShift.id,
    });

    if (currentOutletLoc && currentOutletID) {
      setOpen(false);
      setOutletLocation(currentOutletLoc);
      if(currentOutletLoc?.toLowerCase().includes("room")){
        fetchRoom();
      } else {
        fetchOutlet(currentOutletID)
      }
      setIsShow(true);
    } else {
      setOpen(true);
      setIsShow(false);
    }
  };

  // FUNGSI UNTUK MENDAPTKAN DATA SHIFT SAAT INI DAN SHIFT SEBELUMNYA
  function getCurrentAndPreviousShift(shifts) {
    const currentTime = moment(); // Get the current time
    let currentShift = null;

    // Sort shifts by start_at time (ignoring the date)
    const sortedShifts = shifts.sort((a, b) => {
        const startA = moment(a.start_at).format('HH:mm');
        const startB = moment(b.start_at).format('HH:mm');
        return startA.localeCompare(startB);
    });

    for (let i = 0; i < sortedShifts.length; i++) {
        const shift = sortedShifts[i];
        const startAt = moment(shift.start_at).set({
            year: currentTime.year(),
            month: currentTime.month(),
            date: currentTime.date()
        });
        const endAt = moment(shift.end_at).set({
            year: currentTime.year(),
            month: currentTime.month(),
            date: currentTime.date()
        });

        // Handle case where shift spans across midnight
        if (endAt.isBefore(startAt)) {
            if (currentTime.isBetween(startAt, moment().endOf('day')) || 
                currentTime.isBetween(moment().startOf('day'), endAt)) {
                currentShift = shift;
                break;
            }
        } else {
            if (currentTime.isBetween(startAt, endAt)) {
                currentShift = shift;
                break;
            }
        }
    }

    return { currentShift };
  };

  // Fetch Room
  const fetchRoom = async () => {
    await getMasterRoomCat({
      roomcat: "category",
      onAwait: () => { },
      onSuccess: (response) => {
        console.log("ROOM CATEGORY => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setLocationCategories(filtered);
        } else {
          setLocationCategories([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  const fetchOutlet = async (val) => {
    await getTableCategory({
      type: "category-by-outlet",
      outlet_id: val,
      onAwait: () => { },
      onSuccess: (response) => {
        console.log("Outlet Table Filter: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setLocationCategories(_res);
        } else {
          setLocationCategories([]);
        }
      },
      onReject: (error) => {
        console.log("Error: ", error);
        failedFetch(error);
      },
    });
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="table-order-main-layout">
          <Row
            className="table-order-title"
            align="middle"
            justify="start"
            style={cardHeader}
          >
            <MdFoodBank
              className="icons-head"
              style={{
                height: 24,
                width: 24,
                margin: "10px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "10px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`Captain Order`}
            </Typography>
          </Row>

          <Row
            className="row-search-component"
            justify="start"
            style={cardBody}
          >
            <Space
              align="end"
              size={15}
              className="header-space"
              wrap={true}
              style={{
                padding: "10px 30px 15px",
              }}
            >
              <Col
                className="location-col"
                style={{
                  textAlign: "start",
                  // margin: "22px 30px",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography
                  style={{ marginBottom: 10 }}
                >{`Location`}</Typography>

                <Button
                  className="submit-btn"
                  onClick={handleOpen}
                  // disabled
                  type="primary"
                  style={{
                    width: 205,
                  }}
                >
                  {outletLocation}
                </Button>
              </Col>

              <Col
                className="shift-col"
                style={{
                  textAlign: "start",
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                <Typography style={{ marginBottom: 10 }}>{`Shift`}</Typography>

                <Button
                  className="submit-btn"
                  onClick={handleOpen}
                  type="primary"
                  style={{
                    width: 205,
                  }}
                  disabled
                >
                  {shift.name}
                </Button>
              </Col>

              <Col
                className="btn-col"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  className="refresh-btn"
                  type="default"
                  icon={
                    <IoReturnUpBackOutline
                      className="return-icons"
                      style={{
                        margin: "0px 5px 0px 0px",
                      }}
                    />
                  }
                  onClick={handleNavigateReturn}
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {`Return`}
                </Button>
              </Col>
              <Col>
                <Button
                  className="export-btn"
                  type="primary"
                  onClick={() => navigate("/f-and-b/outlet/kitchen-order")}
                  icon={<ArrowRightOutlined />}
                  style={
                    {
                      // marginRight: 15,
                    }
                  }
                >
                  {`Go to Kitchen Order`}
                </Button>
              </Col>
            </Space>
          </Row>

          {isShow ? (
            <CaptainOrderTable
              locationCategories={locationCategories}
            />
          ) : null}

        </Col>

        <ModalOutletLocation
          closeModal={setOpen}
          openModal={open}
          getLocs={handleSelect}
        />
      </Content>
    </Layout>
  );
}
