// Import React's Component
import React, { useState } from "react";

// Import Ant Design Components
import {
  Space,
  Col,
  Row,
} from "antd";

// Import Global Styles
import POSHeaderTable from "../../Cashier/Headers/Table";
import PerTablePOS from "../../../../Components/Table/Outlet/Order/PerTablePOS";

// CODE
export default function CreateOrderPage({ locationCategories }) {

  // STATE MANAGEMENT
  // Additional
  const [selectedData, setSelectedData] = useState([]);

  // Refresh
  const [refresh, setRefresh] = useState(false);

  // Handle Created
  const handleCreated = (value) => {
    // console.log("Created: ", value);

    if (value === true) {
      setRefresh(!refresh);
      setSelectedData([]);
    }
  };

  const handleSelectedData = (value) => {
    console.log("handleSelectedData", value);
    setSelectedData(value);
  };


  return (
    <>
      <Col
        span={24}
        className="main-space"
        align="start"
        style={{ width: "100%" }}
      >
        <Row
          className="mainspace-row"
          align="start"
          justify="space-evenly"
          gutter={[30, 30]}
        >
          <Col
            className="article-list-col"
            xxl={{
              span: 14,
              order: 1,
            }}
            xl={{
              span: 12,
              order: 1,
            }}
            lg={{
              span: 12,
              order: 1,
            }}
            md={{
              span: 24,
              order: 1,
            }}
            sm={{
              span: 24,
              order: 1,
            }}
            xs={{
              span: 24,
              order: 1,
            }}
          >
            <POSHeaderTable
                selectedData={handleSelectedData}
                is_refresh={refresh}
              />
          </Col>

          <Col
            className="order-col"
            xxl={{
              span: 10,
              order: 2,
            }}
            xl={{
              span: 12,
              order: 2,
            }}
            lg={{
              span: 12,
              order: 2,
            }}
            md={{
              span: 24,
              order: 2,
            }}
            sm={{
              span: 24,
              order: 2,
            }}
            xs={{
              span: 24,
              order: 2,
            }}
          >
            <Row
              justify="end"
              className="row-btn"
              style={{
                padding: 30,
                background: "#FFFFFF",
                borderRadius: 5,
              }}
            >
              <Col span={24} className="ordertable-col">
                <Space
                  className="title-spacetxt"
                  align="center"
                  size={"large"}
                  style={{
                    fontSize: 20,
                    fontWeight: 500,
                  }}
                >
                  {`Order Details`}
                </Space>
              </Col>
            </Row>

            <Row className="table-order-row">
              <PerTablePOS
                is_created={handleCreated}
                selectedData={selectedData}
                locationCategories={locationCategories}
              />
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}
