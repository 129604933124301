// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

// CONTEXT
import { Context as FinanceContext } from "../../../../../../API/Context/FinanceContext/FinanceContext";
import { Context as MasterContext } from "../../../../../../API/Context/MasterContext/MasterContext";

// AUTH
import AuditDate from "../../../../../Reusable/DateAudit/Date";

// Import Ant Design Components
import {
  Button,
  Col,
  Row,
  Collapse,
  Table,
  Typography,
  Card,
  Divider,
  ConfigProvider,
} from "antd";

// Import Icons
import { MdPrint } from "react-icons/md";
import { PrinterOutlined } from "@ant-design/icons";

// Import Page Components
import EmptyTableComponents from "../../../../../Reusable/Empty/EmptyTable";
import SpinnerComponents from "../../../../../Reusable/Spinner/Spinner";

// Import Notifications
import { failedFetch } from "../../../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody, printPreview } from "../../../../../../Style/Global/Global";

// Const
const { Panel } = Collapse;
const { Text, Link, Title } = Typography;

// CODE
export default function HeaderDSRTable({ is_printed, dataDate }) {
  // CONTEXT
  const { getDSR, getDailyHistory } = useContext(FinanceContext);
  const { getParameters } = useContext(MasterContext);
  const tablesContainerRef = useRef(null);

  // Columns Table
  const column = [
    {
      title: () => {
        return handleTitleHeader("Descripton");
      },
      dataIndex: "description",
      key: "description",
      width: 150,
      //   ellipsis: true,
      render: (description, record) => {
        return (
          <Text
            className="drr-desc-text"
            style={
              is_printed === true
                ? printPreview
                : {
                    fontSize: 10,
                    fontWeight: 500,
                    // textTransform: "capitalize",
                  }
            }
          >
            {`${description}`
              .replace(/-+/g, " ")
              .replace(/(^\w|\s\w)/g, (val) => val)}
          </Text>
        );
      },
    },
    {
      title: () => {
        return handleTitleHeader("Today");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("Today");
          },
          dataIndex: "actual_today",
          key: "actual_today",
          width: 100,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_today",
          key: "budget_today",
          width: 125,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Variance");
          },
          dataIndex: "variance_today",
          key: "variance_today",
          width: 125,
          render: (act, record) => {
            const values = parseInt(act).toFixed(0);

            // return handleTitleHeader(handleNegativeBracket(values));
            if (values == 0) {
              return "-";
            } else if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return handleTitleHeader(
                handleNegativeBracket(record.actual_ytd - record.budget_ytd)
              );
            }
          },
        },
      ],
    },
    {
      title: () => {
        return handleTitleHeader("Month to Date (MTD)");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("MTD");
          },
          dataIndex: "actual_mtd",
          key: "actual_mtd",
          width: 100,
          render: (mtd, record) => {
            const values = parseInt(mtd).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_mtd",
          key: "budget_mtd",
          width: 125,
          render: (mtd, record) => {
            const values = parseInt(mtd).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Variance");
          },
          dataIndex: "variance_mtd",
          key: "variance_mtd",
          width: 125,
          render: (mtd, record) => {
            const values = parseInt(mtd).toFixed(0);

            if (values == 0) {
              return handleTitleHeader("-");
            } else if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return handleTitleHeader(
                handleNegativeBracket(record.actual_ytd - record.budget_ytd)
              );
            }
          },
        },
      ],
    },
    {
      title: () => {
        return handleTitleHeader("Year to Date (YTD)");
      },
      children: [
        {
          title: () => {
            return handleTitleHeader("YTD");
          },
          width: 125,
          dataIndex: "actual_ytd",
          key: "actual_ytd",
          render: (ytd, record) => {
            const values = parseInt(ytd).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Budget");
          },
          dataIndex: "budget_ytd",
          key: "budget_ytd",
          width: 130,
          render: (ytd, record) => {
            const values = parseInt(ytd).toFixed(0);

            if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return "-";
            }
          },
        },
        {
          title: () => {
            return handleTitleHeader("Variance");
          },
          dataIndex: "variance_ytd",
          key: "ytd_variance",
          render: (ytd, record) => {
            const values = parseInt(ytd).toFixed(0);

            if (values == 0) {
              return handleTitleHeader("-");
            } else if (values < 0) {
              return handleTitleHeader(handleNegativeBracket(values));
            } else if (values > 0) {
              return handleTitleHeader(values);
            } else {
              return handleTitleHeader(
                handleNegativeBracket(record.actual_ytd - record.budget_ytd)
              );
            }
          },
        },
      ],
    },
  ];

  // Date Now
  const date = new Date(Date.now());
  const _date = AuditDate().audit;
  const today = moment(date).format("DD MMMM YYYY");

  // STATE MANAGEMENT
  // Data
  const [bulks, setBulks] = useState([]);
  const [dataMaster, setDataMaster] = useState([]);
  const [dateNow, setDateNow] = useState(_date);
  // Tables
  const [tablesData, setTablesData] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [payments, setPayments] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [payable, setPayable] = useState([]);
  // Data
  // Boolean State
  const [isProcessing, setIsProcessing] = useState(true);
  const [isPrinted, setIsPrinted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch Data
  const fetchData = async () => {
    await getDSR({
      type: "dsr-header-group-id",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response: ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          // setRevenue(_res);
          handleData(_res);
        } else {
          setBulks([]);
        }
      },
      onReject: (error) => {
        console.log("Failed Revenue DRR: ", error);
        setLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });

    await getParameters({
      params: "parameters",
      type: "source-header-dsr",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setDataMaster(filtered);
        } else {
          setDataMaster([]);
        }
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    await getDSR({
      type: "dsr-statistic",
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response DSR Statistics => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          setStatistics(_res);
        } else {
          setStatistics([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("Failed Statistics DRR: ", error);
        setIsLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });

    // await getDailyHistory({
    //   type: "dsr-header-group-id9",
    //   date: null,
    //   onAwait: () => {},
    //   onSuccess: (response) => {
    //     console.log("Response Ledgers => ", response);

    //     if (response?.data?.length > 0) {
    //       let _res = response.data;

    //       setPayments(_res);
    //     } else {
    //       setPayments([]);
    //     }
    //   },
    //   onReject: (error) => {
    //     console.log("Failed Payments DRR: ", error);
    //     setLoading(false);
    //     failedFetch(error);
    //   },
    // });

    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  // FETCH HISTORY
  const fetchDataHistory = async () => {
    await getDailyHistory({
      type: "dsr-header-group-id",
      date: moment(dataDate).format("YYYY-MM-DD"),
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response History: ", response);

        if (response?.data?.length > 0) {
          let _res = response.data;

          // setRevenue(_res);
          handleData(_res);
        } else {
          setTablesData([]);
        }
      },
      onReject: (error) => {
        console.log("Failed Revenue DRR: ", error);
        setLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });

    await getDailyHistory({
      type: "dsr-statistic",
      date: moment(dataDate).format("YYYY-MM-DD"),
      onAwait: () => {
        setIsLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response DSR Statistics History => ", response);

        if (response?.data?.length > 0) {
          let _res = response.data;

          setStatistics(_res);
        } else {
          setStatistics([]);
        }

        setIsLoading(false);
      },
      onReject: (error) => {
        console.log("Failed Statistics DRR: ", error);
        setIsLoading(false);
        setIsProcessing(false);
        failedFetch(error);
      },
    });

    // await getDailyHistory({
    //   type: "dsr-header-group-id9",
    //   date: moment(dataDate).format("YYYY-MM-DD"),
    //   onAwait: () => {},
    //   onSuccess: (response) => {
    //     console.log("Response Ledgers History => ", response);

    //     if (response?.data?.length > 0) {
    //       let _res = response.data;

    //       setPayments(_res);
    //     } else {
    //       setPayments([]);
    //     }
    //   },
    //   onReject: (error) => {
    //     console.log("Failed Payments DRR: ", error);
    //     setLoading(false);
    //     failedFetch(error);
    //   },
    // });

    await getParameters({
      params: "parameters",
      type: "source-header-dsr",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _res = response.data.msg;

          let filtered = _res.filter((filt) => {
            if (filt.is_deleted == false || filt.is_deleted == null) {
              return filt;
            }
          });

          setDataMaster(filtered);
        } else {
          setDataMaster([]);
        }
      },
      onReject: (error) => {
        console.log(error);

        setLoading(false);
        failedFetch(error);
      },
    });

    setTimeout(() => {
      setLoading(false);
    }, 2500);
  };

  // USE EFFECT
  // useEffect(() => {
  //   let cleanUp = false;

  //   if (!cleanUp) {
  //     fetchData();
  //   }

  //   return () => {
  //     cleanUp = true;
  //   };
  // }, []);

  // Print Effects
  useEffect(() => {
    let cleanUp = false;

    // console.log("Data Loading: ", loading);
    // console.log("Data Processing: ", isProcessing);

    if (is_printed === true && !cleanUp) {
      setIsPrinted(true);
    } else {
      setIsPrinted(false);
    }

    if (dataDate && !cleanUp) {
      console.log("Tanggal: ", dataDate);
      setDateNow(dataDate);

      fetchDataHistory();
    }

    return () => {
      cleanUp = true;
    };
  }, [is_printed, dataDate]);

  // Table Break
  useEffect(() => {
    if (tablesContainerRef.current) {
      const tables =
        tablesContainerRef.current.querySelectorAll(".table-wrapper");

      tables.forEach((table, index) => {
        const observer = new ResizeObserver(() => {
          if (index > 0) {
            const previousTableBottom =
              tables[index - 1].offsetTop + tables[index - 1].offsetHeight;
            if (table.offsetTop < previousTableBottom) {
              table.classList.add("table-break");
            } else {
              table.classList.remove("table-break");
            }
          }
        });
        observer.observe(table);
      });
    }
  }, [tablesData]);

  // Create Tables
  useEffect(() => {
    let cleanUp = false;

    console.log("This is rerendered: ", bulks);
    console.log("This is <MASTER>: ", dataMaster);

    if (dataMaster.length > 0 && bulks.length > 0 && !cleanUp) {
      handleSorter();
    }

    return () => {
      cleanUp = true;
    };
  }, [dataMaster, bulks]);

  // HANDLE DATA
  const handleData = (value) => {
    const _valRes = value;
    console.log("Data in Handling: ", _valRes);

    let result = _valRes.reduce(function (r, a) {
      r[a.group_id] = r[a.group_id] || [];
      r[a.group_id].push(a);

      return r;
    }, []);

    console.log("Data Mapped: ", result);
    setBulks(result);
    // handleCreateTable(result);
  };

  // Handle Sorting based on Parameters
  const handleSorter = () => {
    const result = [];

    dataMaster.forEach(function (key) {
      var found = false;

      // console.log("KEY: ", key)

      let items = bulks.filter(function (item) {
        // console.log("Items: ", item)

        if (!found && item[0].group_id === key.id) {
          // console.log("Item is true: ", item[0].group_id)
          // console.log("Item is matched with: ", key)
          result.push(item);
          found = true;
          return false;
        } else {
          return true;
        }
      });
    });

    console.log("Results of sorting: ", result);
    if (result?.length > 0) {
      handleCreateTable(result);
    } else {
      setIsProcessing(false);
    }
  };

  // Handle Table
  const handleCreateTable = (value) => {
    const _bulks = value;
    let _tables = [];

    for (let i = 0; i < _bulks.length; i++) {
      if (_bulks[i] !== null && _bulks[i] !== undefined) {
        // console.log(`Loop-${i}: `, _bulks[i]);

        _tables.push(
          <div
            // ref={(el) => (tablesContainerRef.current = el)}
            key={`table-${i}`}
            className="table-wrapper"
            style={{ width: "100%" }}
          >
            <Table
              className={"dsr-table"}
              name={"dsr-table-" + i}
              key={"dsr-table-" + i}
              title={() => (
                <Typography
                  className="table-title"
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {_bulks[i][0]?.label_header || `Data-${i}`}
                </Typography>
              )}
              dataSource={_bulks[i]}
              columns={column}
              pagination={false}
              size="small"
              bordered={true}
              loading={loading}
              rowKey={(record) =>
                record?.id ? record.id : record?.account_code
              }
              // scroll={{
              //   x: 1000,
              // }}
              summary={(pageData) => {
                let totalToday = 0;
                let totalBudgetToday = 0;

                let totalMtd = 0;
                let totalBudgetMtd = 0;

                let totalYtd = 0;
                let totalBudgetYtd = 0;

                pageData.forEach(
                  ({
                    budget_today,
                    budget_mtd,
                    budget_ytd,
                    actual_today,
                    actual_mtd,
                    actual_ytd,
                  }) => {
                    totalToday += actual_today;
                    totalBudgetToday += Math.floor(budget_today ?? 0);

                    totalMtd += actual_mtd;
                    totalBudgetMtd += Math.floor(budget_mtd ?? 0);

                    totalYtd += actual_ytd;
                    totalBudgetYtd += budget_ytd;
                  }
                );
                return (
                  <>
                    <Table.Summary
                      fixed
                      key="summary-tables"
                      className="sum-table"
                    >
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          className={"summary-head"}
                          index={0}
                          // colSpan={3}
                          align="right"
                        >
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {_bulks[i][0]?.total_label_name || "Total"}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={1}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalToday)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetToday)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(
                              totalToday - totalBudgetToday
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalMtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetMtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalMtd - totalBudgetMtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalYtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetYtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalYtd - totalBudgetYtd)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      <Table.Summary.Row
                        className="row-space-2"
                        key={"row-space-2"}
                      >
                        <Table.Summary.Cell
                          key="row-cell-space"
                          className="row-cell-space"
                          index={0}
                          colSpan={9}
                        />
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
              style={{ padding: "0px 0px 10px 0px" }}
            />
          </div>
        );
      }
    }

    console.log("Table Results: ", _tables);
    setTablesData(_tables);

    setIsProcessing(false);
  };

  // Handle Negative Bracket
  const handleNegativeBracket = (values) => {
    const _formatted = values;

    return `${_formatted || 0}`
      .replace(/-(.*)/, "($1)")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle Header Title
  const handleTitleHeader = (values) => {
    const _formatted = values.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <Text
        className="header-text"
        style={
          isPrinted === true
            ? printPreview
            : {
                fontSize: 10,
                fontWeight: 500,
              }
        }
      >
        {_formatted}
      </Text>
    );
  };

  // Handle Refresh
  const handleRefresh = (value) => {
    console.log("is refresh: ", value);

    fetchData();
  };

  if (isProcessing === true) {
    return <SpinnerComponents />;
  }

  return (
    <>
      <Row
        // gutter={[30, 30]}
        className="row-body"
        justify="start"
        style={mainBody}
      >
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {`Summary of Daily Revenue Report (DRR)`}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 0px 0px", color: "#8c8c8c" }}
          >
            {`Idea's Hotel Bandung`}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{ margin: "0px 0px 20px 0px", color: "#8c8c8c" }}
          >
            {moment(dateNow ? dateNow : _date).format("DD MMM YYYY")}
          </Typography.Title>

          <Row ref={tablesContainerRef}>
            {tablesData?.length > 0 ? (
              tablesData
            ) : (
              <EmptyTableComponents is_refresh={handleRefresh} />
            )}
          </Row>

          {/* <Collapse
            className="daily-reports-collapse"
            defaultActiveKey={[1, 2, 3, 4, 5]}
            bordered={true}
            style={{ marginBottom: 50 }}
          >
            <Panel
              header={<Title level={5}>{`Daily Report`}</Title>}
              key="1"
              collapsible="disabled"
              showArrow={false}
            > */}
          <Row ref={tablesContainerRef}>
            <ConfigProvider key={"report-table"} renderEmpty={() => null}>
              <div className="table-wrapper" style={{ width: "100%" }}>
                <Table
                  className="dsr-table"
                  name="data-ledger-table"
                  key="data-ledger-table"
                  dataSource={[]}
                  columns={null}
                  pagination={false}
                  size="small"
                  bordered={true}
                  loading={loading}
                  rowKey={(record) =>
                    record?.id ? record.id : record?.account_code
                  }
                  style={{ width: "100%" }}
                  // scroll={{
                  //   x: 1000,
                  // }}
                  summary={(pageData) => {
                    let totalToday = 0;
                    let totalBudgetToday = 0;

                    let totalMtd = 0;
                    let totalBudgetMtd = 0;

                    let totalYtd = 0;
                    let totalBudgetYtd = 0;

                    pageData.forEach(
                      ({
                        budget_today,
                        budget_mtd,
                        budget_ytd,
                        actual_today,
                        actual_mtd,
                        actual_ytd,
                      }) => {
                        totalToday += actual_today;
                        totalBudgetToday += Math.floor(budget_today ?? 0);

                        totalMtd += actual_mtd;
                        totalBudgetMtd += Math.floor(budget_mtd ?? 0);

                        totalYtd += actual_ytd;
                        totalBudgetYtd += budget_ytd;
                      }
                    );

                    console.log(payments);

                    return payments.length > 0 ? (
                      <>
                        <Table.Summary
                          fixed
                          key="summary-tables"
                          className="sum-table"
                        >
                          <Table.Summary.Row style={{ width: "100%" }}>
                            <Table.Summary.Cell>
                              <Text
                                strong
                                style={{
                                  fontSize: isPrinted ? "12px" : "12px",
                                }}
                              >
                                {payments[0]?.description}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text
                                strong
                                style={{
                                  fontSize: isPrinted ? "12px" : "15px",
                                }}
                              >
                                {payments.length > 0
                                  ? handleNegativeBracket(
                                      payments[0]?.actual_total
                                    )
                                  : 0}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text
                                strong
                                style={{
                                  fontSize: isPrinted ? "12px" : "12px",
                                }}
                              >
                                {payments[1]?.description}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text
                                strong
                                style={{
                                  fontSize: isPrinted ? "12px" : "15px",
                                }}
                              >
                                {payments.length > 0
                                  ? handleNegativeBracket(
                                      payments[1]?.actual_total
                                    )
                                  : 0}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>

                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              className={"summary-head"}
                              index={0}
                              // colSpan={3}
                              align="right"
                            >
                              <Text
                                strong
                                style={{
                                  fontSize: isPrinted ? "12px" : "12px",
                                }}
                              >
                                {`Today Balance`}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text
                                strong
                                style={{
                                  fontSize: isPrinted ? "12px" : "15px",
                                }}
                              >
                                {payments.length > 0
                                  ? handleNegativeBracket(
                                      payments[0]?.actual_total -
                                        payments[1]?.actual_total
                                    )
                                  : 0}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      </>
                    ) : null;
                  }}
                />
              </div>
            </ConfigProvider>
          </Row>
          {/* </Panel> */}

          {/* <Panel
              header={<Title level={5}>{`Gross Revenue`}</Title>}
              key="2"
              collapsible="disabled"
              showArrow={false}
            >
              <Table
                className="dsr-table"
                name="data-payable-table"
                key="data-payable-table"
                dataSource={payable}
                columns={column}
                pagination={false}
                size="small"
                bordered={true}
                loading={loading}
                rowKey={(record) =>
                  record?.id ? record.id : record?.account_code
                }
                // scroll={{
                //   x: 1000,
                // }}
                summary={(pageData) => {
                  let totalToday = 0;
                  let totalBudgetToday = 0;

                  let totalMtd = 0;
                  let totalBudgetMtd = 0;

                  let totalYtd = 0;
                  let totalBudgetYtd = 0;

                  pageData.forEach(
                    ({
                      budget_today,
                      budget_mtd,
                      budget_ytd,
                      actual_today,
                      actual_mtd,
                      actual_ytd,
                    }) => {
                      totalToday += actual_today;
                      totalBudgetToday += Math.floor(budget_today ?? 0);

                      totalMtd += actual_mtd;
                      totalBudgetMtd += Math.floor(budget_mtd ?? 0);

                      totalYtd += actual_ytd;
                      totalBudgetYtd += budget_ytd;
                    }
                  );
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          className={"summary-head"}
                          index={0}
                          colSpan={10}
                          align="left"
                        >
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "20px" : "12px" }}
                          >
                            {`Total`}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          className={"summary-head"}
                          index={0}
                          // colSpan={3}
                          align="right"
                        >
                          {/* <Text
                  strong
                  style={{ fontSize: isPrinted ? "6px" : "12px" }}
                >
                  {`Total`}
                </Text> 
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalToday)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetToday)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(
                              totalToday - totalBudgetToday
                            )}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalMtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetMtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalMtd - totalBudgetMtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalYtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalBudgetYtd)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={9}>
                          <Text
                            strong
                            style={{ fontSize: isPrinted ? "6px" : "12px" }}
                          >
                            {handleNegativeBracket(totalYtd - totalBudgetYtd)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </Panel> */}

          {/* <Panel
              header={<Title level={5}>{`Guest Ledgers`}</Title>}
              key="3"
              collapsible="disabled"
              showArrow={false}
            >
              <Table
                className="dsr-table"
                name="data-ledger-table"
                key="data-ledger-table"
                dataSource={payments}
                columns={columnLedger}
                pagination={false}
                size="small"
                bordered={true}
                loading={loading}
                rowKey={(record) =>
                  record?.id ? record.id : record?.description
                }
                // scroll={{
                //   x: 1000,
                // }}
                // summary={(pageData) => {
                //   let totalToday = 0;
                //   let totalBudgetToday = 0;

                //   let totalMtd = 0;
                //   let totalBudgetMtd = 0;

                //   let totalYtd = 0;
                //   let totalBudgetYtd = 0;

                //   pageData.forEach(
                //     ({
                //       today_budget,
                //       mtd_budget,
                //       ytd_budget,
                //       today,
                //       mtd,
                //       ytd,
                //       actual_total,
                //     }) => {
                // totalToday += today;
                // totalBudgetToday += Math.floor((today_budget ?? 0) / 365);

                // totalMtd += mtd;
                // totalBudgetMtd += Math.floor((mtd_budget ?? 0) / 12);

                // totalYtd += ytd;
                // totalBudgetYtd += ytd_budget;
                //   }
                // );
                // return (
                //     <>
                //       <Table.Summary.Row>
                //         <Table.Summary.Cell
                //           className={"summary-head"}
                //           index={0}
                //           colSpan={10}
                //           align="left"
                //         >
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {`Total`}
                //           </Text>
                //         </Table.Summary.Cell>
                //       </Table.Summary.Row>
                //       <Table.Summary.Row>
                //         <Table.Summary.Cell
                //           className={"summary-head"}
                //           index={0}
                //           // colSpan={3}
                //           align="right"
                //         >
                //           {/* <Text
                //   strong
                //   style={{ fontSize: isPrinted ? "6px" : "12px" }}
                // >
                //   {`Total`}
                // </Text> 
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={1}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalToday)}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={2}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalBudgetToday)}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={3}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(
                //               totalToday - totalBudgetToday
                //             )}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={4}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalMtd)}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={5}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalBudgetMtd)}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={6}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalMtd - totalBudgetMtd)}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={7}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalYtd)}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={8}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalBudgetYtd)}
                //           </Text>
                //         </Table.Summary.Cell>
                //         <Table.Summary.Cell index={9}>
                //           <Text
                //             strong
                //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                //           >
                //             {handleNegativeBracket(totalYtd - totalBudgetYtd)}
                //           </Text>
                //         </Table.Summary.Cell>
                //       </Table.Summary.Row>
                //     </>
                // );
                // }}
              />
            </Panel> */}

          {/* <Panel
              header={<Title level={5}>{`Room Statistics`}</Title>}
              key="4"
              collapsible="disabled"
              showArrow={false}
            > */}

          <Row ref={tablesContainerRef}>
            <ConfigProvider key={"statistics-table"} renderEmpty={() => null}>
              <div className="table-wrapper" style={{ width: "100%" }}>
                <Table
                  className="dsr-table"
                  name="statistics-table"
                  key="statistics-table"
                  title={() =>
                    statistics?.length > 0 ? (
                      <Typography
                        className="table-title"
                        style={{
                          fontSize: 12,
                          fontWeight: 500,
                        }}
                      >
                        {`Statistics`}
                      </Typography>
                    ) : null
                  }
                  dataSource={statistics}
                  columns={statistics.length > 0 ? column : []}
                  // columns={column}
                  pagination={false}
                  size="small"
                  bordered={true}
                  loading={isLoading}
                  rowKey={(record) => {
                    return record?.id ? record.id : record?.row_number;
                  }}
                  // scroll={{
                  //   x: 700,
                  // }}
                  // summary={(pageData) => {
                  //   let totalToday = 0;
                  //   let totalBudgetToday = 0;

                  //   let totalMtd = 0;
                  //   let totalBudgetMtd = 0;

                  //   let totalYtd = 0;
                  //   let totalBudgetYtd = 0;

                  //   pageData.forEach(
                  //     ({
                  //       today_budget,
                  //       mtd_budget,
                  //       ytd_budget,
                  //       today,
                  //       mtd,
                  //       ytd,
                  //     }) => {
                  //       totalToday += today;
                  //       totalBudgetToday += Math.floor((today_budget ?? 0) / 365);

                  //       totalMtd += mtd;
                  //       totalBudgetMtd += Math.floor((mtd_budget ?? 0) / 12);

                  //       totalYtd += ytd;
                  //       totalBudgetYtd += ytd_budget;
                  //     }
                  //   );
                  //   return (
                  //     <>
                  //       <Table.Summary.Row>
                  //         <Table.Summary.Cell
                  //           className={"summary-head"}
                  //           index={0}
                  //           colSpan={10}
                  //           align="left"
                  //         >
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {`Total`}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //       </Table.Summary.Row>
                  //       <Table.Summary.Row>
                  //         <Table.Summary.Cell
                  //           className={"summary-head"}
                  //           index={0}
                  //           // colSpan={3}
                  //           align="right"
                  //         >
                  //           {/* <Text
                  //   strong
                  //   style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  // >
                  //   {`Total`}
                  // </Text> */}
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={1}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalToday)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={2}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalBudgetToday)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={3}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(
                  //               totalToday - totalBudgetToday
                  //             )}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={4}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalMtd)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={5}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalBudgetMtd)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={6}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalMtd - totalBudgetMtd)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={7}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalYtd)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={8}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalBudgetYtd)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell index={9}>
                  //           <Text
                  //             strong
                  //             style={{ fontSize: isPrinted ? "6px" : "12px" }}
                  //           >
                  //             {handleNegativeBracket(totalYtd - totalBudgetYtd)}
                  //           </Text>
                  //         </Table.Summary.Cell>
                  //       </Table.Summary.Row>
                  //     </>
                  //   );
                  // }}
                />
              </div>
            </ConfigProvider>
          </Row>
          {/* </Panel>
          </Collapse> */}
        </Col>
      </Row>
    </>
  );
}
