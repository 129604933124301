// Import React Components
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

// AUTH
import { baseurl } from "../../../../API/Config/Api";
import { token } from "../../../../API/Global/Payload";

// Import AntD Components
import {
  Col,
  Layout,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Button,
  Card,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Divider,
  Tag,
} from "antd";

// Import React Icon Components
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { PrinterOutlined, SyncOutlined } from "@ant-design/icons";

// Import Page Components
import Invoice from "../../../Forms/Invoice/Invoice";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";
import { AccountingParams } from "../../../Reusable/Functions/Parameters/ParamsAcc";
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";
import GroupingBill from "../../../Reusable/Functions/GroupingArticle/GroupingBill";
import GroupingArticle from "../../../Reusable/Functions/GroupingArticle/GroupingArticle";
import { BillArticleShow } from "../../../Reusable/Functions/BillType/BillType";

// Import Notifications
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Global Styles
import { mainBody, textForm } from "../../../../Style/Global/Global";

// CODE
export default function FOCashierTable(props) {
  // PROPS
  const {
    source,
    bill_header,
    bill_detail,
    selected_total,
    getTotal,
    is_reset,
    is_rereset,
  } = props;

  // Currency
  const currency = CurrencySymbols().code;
  const accounting = AccountingParams();
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  // DATA
  const [sources, setSources] = useState(null);
  // Table
  const [data, setData] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState({
    grand: 0,
    total: 0,
    tax: 0,
    service: 0,
  });
  const [tagStatus, setTagStatus] = useState({
    txt: "UN-SELECTED",
    clr: "#f5222d",
  });
  // BOOLEAN
  const [loading, setLoading] = useState(false);
  const [cardLoad, setCardLoad] = useState(false);
  const [open, setOpen] = useState(false);

  // FORM
  const [form] = Form.useForm();

  // FETCH DATA
  const fetchData = async (val) => {
    setLoading(true);
    setCardLoad(true);

    await axios
      .post(
        `${baseurl}/guest/payment-reservation-by-bill-master`,
        {
          bill_master: val?.bill_master
            ? val.bill_master
            : bill_header?.bill_master,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Fetch Details: ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          handleBill(_res);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Errors: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
        setCardLoad(false);
      });
    // await axios
    //   .post(`${baseurl}/bill/detail-unpaid`, data, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   })
    //   .then((response) => {
    //     console.log("Fetch Detail: ", response);

    //     if (response.data?.msg?.length > 0) {
    //       const _res = response.data.msg;

    //       const _filter = _res.filter((items) => {
    //         if (!items?.is_deleted && items?.id > 0) {
    //           return items;
    //         }
    //       });

    //       // setData(_filter);
    //       handleBill(_filter);
    //       handleTotal(_filter);
    //     } else {
    //       setData([]);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("Errors: ", error);
    //     failedFetch(error);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //     setCardLoad(false);
    //   });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (source && !cleanUp) {
      console.log("Sources: ", source);

      fetchData(source);
    }

    return () => {
      cleanUp = true;
    };
  }, [source]);

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (bill_header && !cleanUp) {
      console.log("Data Card: ", bill_header);

      setCardData(bill_header);
      handleFormFields(bill_header);
    }

    if (bill_detail?.length > 0 && !cleanUp) {
      console.log("Data Detail: ", bill_detail);

      setData(bill_detail);
    } else {
      setData([]);
    }

    if (selected_total?.grand > 0 && bill_detail?.length > 0 && !cleanUp) {
      console.log("Data Total: ", selected_total);

      setSubTotal(selected_total);
      getTotal({
        total: selected_total?.grand,
        deposit: false,
      });
    } else {
      setSubTotal({
        grand: 0,
        total: 0,
        tax: 0,
        service: 0,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [bill_header, bill_detail, selected_total]);

  // COLUMNS
  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      width: 75,
      render: (_, record, index) => {
        return index + 1 + ".";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Res ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: `Trx. Date`,
      dataIndex: "date",
      key: "date",
      width: 200,
      render: (date) => {
        return moment(LocalizationDate(date)).format("DD-MM-YYYY");
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 50,
    },
    // {
    //   title: `Price ( Rp )`,
    //   dataIndex: "price",
    //   key: "price",
    // },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      width: 150,
      align: "right",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      render: (price) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Handle Refresh
  const handleRefresh = async () => {
    await fetchData(cardData);
    is_rereset(true);

    // console.log("Card: ", cardData);

    getTotal({
      // total: total,
      deposit: true,
      data_depo: cardData,
    });
  };

  // Handle Form Fields
  const handleFormFields = (val) => {
    const _record = val;
    const status = val?.bill_status;

    console.log("Records: ", _record);

    switch (status) {
      case 0:
        setTagStatus({
          txt: "NORMAL BILL",
          clr: "#ffec3d",
        });
        break;

      case 1:
        setTagStatus({
          txt: "SPLIT BILL",
          clr: "#2f54eb",
        });
        break;

      case 2:
        setTagStatus({
          txt: "JOIN BILL",
          clr: "#52c41a",
        });
        break;

      default:
        setTagStatus({ txt: "ERROR" });
        break;
    }

    form.setFieldsValue({
      bill_alias: _record?.bill_master,
      reservation_id: _record?.reservation_id,
      total: _record?.total_price > 0 ? _record.total_price : 0,
      full_name: _record?.full_name,
      bill_receiver: _record?.full_name,
    });

    setCardLoad(false);
  };

  // Handle Bill
  const handleBill = (value) => {
    const _arr = value;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    // const _grouping = GroupingArticle(_billing);
    // Showing Bill Type
    const totals = BillArticleShow({
      article: _billing,
      bill_type: bill_type,
    });

    // console.log("Billing: ", _billing);
    // console.log("Grouping Res: ", _grouping);
    console.log("Grouping Totals: ", totals);

    // return _billing;
    setData(totals.article);
    setSubTotal({
      grand: totals.total.total_price,
      service: totals.total.service,
      tax: totals.total.tax,
      total: totals.total.unit_price,
    });
    // Return
    // selected_total({
    //   grand: totals.total.total_price,
    //   service: totals.total.service,
    //   tax: totals.total.tax,
    //   total: totals.total.unit_price,
    // });
    getTotal({
      total: totals.total.total_price,
      deposit: false,
    });
  };

  // Handle Total Amount
  const handleTotal = (val) => {
    const _data = val;
    let _totals = 0;

    const _total = _data.forEach(({ price }) => {
      if (price > 0) {
        return (_totals += price);
      }
    }, 0);

    // console.log("Totals: ", _totals);

    setTotal(_totals);
    getTotal({
      total: _totals,
      deposit: false,
    });
    // form.setFieldValue("total_amount", _total);
  };

  // Handle Invoice
  const handleInvoice = () => {
    setOpen(true);
  };

  // Handle Close
  const handleClose = (val) => {
    setOpen(false);
  };

  return (
    <>
      <Row justify="space-between" className="row-btn">
        <Card
          className="table-card"
          bordered
          loading={cardLoad}
          style={{
            width: "100%",
            // marginTop: 16,
          }}
          title={
            <Row justify="center" align="middle" className="title-row">
              <Col span={24} className="title-col">
                <Typography
                  className="bill-txt"
                  key={"txt-bill"}
                  style={{ fontSize: 20 }}
                >
                  {`Bill No: ${cardData?.bill_master || ""}`}
                </Typography>
              </Col>
            </Row>
          }
          headStyle={{
            textAlign: "center",
            background: "#e4e6ef",
          }}
          bodyStyle={{
            textAlign: "center",
            display: "block",
            background: "#f5f5f5",
            padding: "0px 10px",
          }}
        >
          <Form
            className="form-bill"
            key="form-bill"
            name="form-bill"
            form={form}
            labelWrap={true}
            labelAlign="left"
            labelCol={{
              span: 8,
              offset: 2,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            <Row
              align="middle"
              justify="start"
              style={{ margin: 0, height: 30 }}
            >
              <Col span={12}>
                <Form.Item name="bill_receiver" label="Bill Receiver">
                  <Input
                    disabled
                    placeholder="Bill Receiver"
                    bordered={false}
                    style={textForm}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="total"
                  label="Total Trx"
                  style={{
                    width: "100%",
                  }}
                >
                  <InputNumber
                    // disabled
                    addonBefore={currency}
                    formatter={(price) => {
                      return `${price > 0 ? price : 0}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                    }}
                    // onChange={(value) => {
                    //   return value;
                    // }}
                    readOnly={true}
                    placeholder="Total Transactions"
                    bordered={false}
                    style={{
                      width: "100%",
                      fontSize: 20,
                      fontWeight: 750,
                      color: "#434343",
                      "&::placeholder": {
                        fontSize: 20,
                        color: "#434343",
                      },
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row align="middle" justify="center" style={{ height: 50 }}>
              <Col span={12} style={{ margin: 0 }}>
                <Form.Item name="full_name" label="Guest Name">
                  <Input
                    disabled
                    placeholder="Guest Name"
                    bordered={false}
                    style={textForm}
                  />
                </Form.Item>
              </Col>

              <Col span={12} style={{ margin: 0 }}>
                <Form.Item name="reservation_id" label="Reservation No.">
                  <Input.TextArea
                    disabled
                    placeholder="Reservation No."
                    bordered={false}
                    style={textForm}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Col span={12} style={{ margin: 0 }}>
              <Form.Item label="Bill Status">
                <Tag
                  color={tagStatus.clr}
                  key="bill-tag"
                  style={{
                    color: tagStatus.txt == "NORMAL BILL" ? "#434343" : "#fff",
                    fontWeight: 700,
                  }}
                >
                  {tagStatus.txt}
                </Tag>
              </Form.Item>
            </Col>
          </Form>
        </Card>
      </Row>

      <Row
        justify="center"
        className="row-table"
        style={{
          padding: "5px 30px 30px",
          background: "#FFFFFF",
          borderRadius: 5,
        }}
      >
        <Table
          className="table-list"
          name="table-list"
          key="table-list"
          loading={loading}
          columns={columns}
          dataSource={data}
          bordered={true}
          size="small"
          scroll={{
            x: true,
          }}
          rowKey={(record) => {
            return record?.id > 0 ? record.id : record.key;
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          pagination={false}
          summary={() => {
            return (
              <>
                <Table.Summary fixed>
                  {bill_type ? (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={5} />

                        <Table.Summary.Cell index={5}>
                          <b>Subtotal</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={6} align="right">
                          {`${currency} ${
                            subTotal.total > 0 ? subTotal.total : 0
                          }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={5} />

                        <Table.Summary.Cell index={5}>
                          <b>Service</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={6} align="right">
                          {`${currency} ${subTotal?.service}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={5} />

                        <Table.Summary.Cell index={5}>
                          <b>Tax</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={6} align="right">
                          {`${currency} ${subTotal?.tax}`.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  ) : null}

                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={5} />

                    <Table.Summary.Cell index={5}>
                      <b style={{ fontSize: 15 }}>Grand Total</b>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={6} align="right">
                      <b style={{ fontSize: 15 }}>
                        {`${currency} ${
                          subTotal.grand > 0 ? subTotal.grand : 0
                        }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              </>
            );
          }}
        />
      </Row>

      <Divider
        className="divider-form"
        style={{
          margin: "5px 0px",
          backgroundColor: "#EBEDF3",
        }}
      />

      <Row
        justify="end"
        style={{
          backgroundColor: "#FFF",
          padding: "15px 30px",
        }}
      >
        <Button
          className="next-btn"
          icon={<PrinterOutlined />}
          type="primary"
          disabled={data.length > 0 ? false : true}
          onClick={handleInvoice}
          style={{
            margin: "0px 15px 0px",
          }}
        >
          {`Print Preview Invoice`}
        </Button>

        <Button
          className="cancel-btn"
          icon={<SyncOutlined />}
          type="default"
          disabled={cardData ? false : true}
          onClick={handleRefresh}
        >
          {`Refresh`}
        </Button>
      </Row>

      <Invoice
        is_open={open}
        is_close={handleClose}
        data_guest={open ? cardData : null}
        payment={null}
        data_table={open ? data : []}
        totals={open ? subTotal : 0}
        is_preview={true}
      />
    </>
  );
}
