// Import Socket.io
import { io } from "socket.io-client";
// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// API
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  Typography,
  Layout,
  Form,
  InputNumber,
  Result,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BsUiChecksGrid } from "react-icons/bs";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

// Master Components
import MasterPaymentTypeEdit from "../../../Components/Reusable/Master/PaymentType/PaymentTypeEdit";
import MasterPaymentCardEdit from "../../../Components/Reusable/Master/PaymentCard/PaymentCardEdit";
import POSPreBillFormTrx from "../../../Components/Forms/POSPreBill/POSPreBillTrx";

// IMPORT GLOBAL
import { mainBody } from "../../../Style/Global/Global";

// Import Notifications
import { masterIncomplete } from "../../../Components/Reusable/Notification/MasterNotif/Notification";
import {
  failedPOSOrder,
  failedPOSPayment,
  successPOSOrder,
  successPOSPayment,
} from "../../../Components/Reusable/Notification/Notification";

// CONST
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function POSPaymentPage() {
  // USE LOCATION
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  // STATE MANAGEMENT
  // Data
  const [headerData, setHeaderData] = useState(null);
  const [detailData, setDetailData] = useState([]);
  const [tableKey, setTableKey] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  // Payment
  const [payCode, setPayCode] = useState(null);
  const [payId, setPayId] = useState(null);
  const [coa, setCoa] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  // Is Exist
  const [isExist, setIsExist] = useState(true);
  // Print
  const [bodyToPrint, setBodyToPrint] = useState([]);

  // FORM
  const [form] = Form.useForm();

  // USE EFFECTS
  useEffect(() => {
    console.log("Total Transactions: ", totalAmount);
    console.log("Total Deposit: ", depositAmount);

    form.setFieldsValue({
      total_amount: totalAmount,
      payment_amount: totalAmount,
      change_amount: 0,
      deposit_amount: 0,
    });

  }, [totalAmount, depositAmount]);

  useEffect(() => {
    let cleanUp = false;

    console.log(state?.headerData);
    console.log(state?.detailData);
    console.log("state.selectedTable", state?.selectedTable);

    setHeaderData(state?.headerData);
    setDetailData(state?.detailData);
    setTableKey(state?.selectedTable);

    let totalAmount = 0;

    state?.detailData.forEach((e) => {
      totalAmount += (e.quantity || e.item_quantity) * e.gross;
    });

    console.log(totalAmount);

    setTotalAmount(totalAmount);

    if (state?.headerData != null && !cleanUp) {
      setButtonLoading(true);
      setIsExist(true);
    } else {
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  useEffect(() => {
    console.log("bodyToPrint", bodyToPrint);
    const socket = io("https://printer-api.hilook.support/", {
      transports: ["websocket"],
      auth: {
        hotel_id: "abcdefg",
      },
      withCredentials: true,
    });

    socket.connect();

    if(bodyToPrint){
      socket.emit("message", bodyToPrint);
    }

    // socket.on("recieve_message", setMessage);
    
    return () => {
      socket.disconnect();
    }
  },[bodyToPrint]);

  // END USE EFFECT

  // HANDLE RETURN
  const handleReturn = (e) => {
    navigate(`${pathname.substring(0, pathname.length - 8)}`, {});
  };

  // Payment Type
  const getPaymentType = (value, code, name) => {
    let _value = value;
    let _code = code;
    let _name = name;
    console.log(value, code, name);

    console.log(payCode);

    setPayId(value.id);

    form.setFieldsValue({
      payment_type: _value,
      payment_details: null,
    });
  };
  // Payment Details/Card
  const getPayCard = (value, code, acc) => {
    console.log(value, code, acc);
    let _value = value;
    let _code = code;
    let _acc = acc;

    const fieldvalue = form.getFieldsValue();

    // console.log(fieldvalue);

    if (code) {
      setPayCode(_code);
    }

    if (acc) {
      setCoa(_acc);
    }

    form.setFieldsValue({
      payment_details: _value,
    });
  };

  // HANDLE CHANGE
  const handleChange = (val) => {
    // console.log("Value Change: ", val);

    let _val = val;
    let _total = totalAmount;
    let _depo = 0;

    let _pay = _depo + _val;
    let _change = _pay - _total;

    // console.log("Change Payment: ", _change);

    form.setFieldsValue({
      change_amount: _change,
    });
  };

  // ON FINISH
  const onFinish = async (e) => {
    console.log("ON-FINISH FORM >>>", e);

    showModalConfirm(e);
  };

  // ON SUBMIT FINISH FAILED
  const onFinishFailed = async (error) => {
    console.log("Data Incomplete >>> ", error?.values);

    masterIncomplete(error);
  };

  // SHOW MODAL POS PAYMENT
  const showModalConfirm = async (record) => {
    const _data = record;

    console.log("Data Articles => ", _data);

    confirm({
      className: "pos-payment-confirm",
      title: `POS Payment Confirmations`,
      icon: (
        <ExclamationCircleOutlined
          className="confirm-icon"
          style={{ color: "#faad14" }}
        />
      ),
      content: `Are you sure want to pay this bill ?`,
      okText: "Submit",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      okButtonProps: {
        type: "primary",
        className: "submit-btn",
        danger: true,
        style: { backgroundColor: "#1BC5BD", borderColor: "#1BC5BD" },
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        setLoading(false);
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (value) => {
    const _data = value;
    let _totalNett = 0;
    let _totalTax = 0;
    let _totalSer = 0;
    let _totalGross = 0;

    detailData.forEach(({ unit_price, tax, service, gross, item_quantity }) => {
      let _tGross = gross * item_quantity;
      let _tTax = tax * item_quantity;
      let _tSer = service * item_quantity;
      let _tNett = unit_price * item_quantity;

      _totalNett += _tNett;
      _totalSer += _tSer;
      _totalTax += _tTax;
      _totalGross += _tGross;
    });

    console.log("TABLE SELECTED: ", tableKey);

    console.log("guestData", state?.guestData);

    let filterGuestData = state?.guestData?.filter((x) => {
      if(x.reservation_id == tableKey?.reservation_id && x.guest_name == tableKey?.name){
        return true
      } else {
        return false
      }
    });

    console.log("filterGuestData: ", filterGuestData);

    let room_number = filterGuestData.length > 0 ? filterGuestData[0]?.room_number : null;

    console.log("room_number", room_number);

      await axios
        .get(`${baseurl}/pos/last-order-id-header`)
        .then(async (res) => {
          if (res.status === 200) {
            console.log("Res Last ID: ", res);

            // if (res?.data?.length > 0 && isSplit != true) {
          console.log("Get Last Order Id Header: ", res);
          console.log("DATA >>>", res?.data);

          await axios
            .put(
              `${baseurl}/pos/order-header`,
              {
                id: detailData[0]?.header_id,
                name: headerData?.name,
                table_code: detailData[0]?.table_code,
                table_id: detailData[0]?.table_id,
                payment_type: _data?.payment_type,
                payment_details: _data?.payment_details,
                payment_status: headerData?.payment_status,
                reservation_id: tableKey?.reservation_id,

                total_nett: detailData
                  .map((e) => ({
                    nett: e.unit_price * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.nett, 0),

                total_gross: detailData
                  .map((e) => ({
                    gross: e.gross * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.gross, 0),

                total_tax: detailData
                  .map((e) => ({
                    tax: e.tax * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.tax, 0),

                total_service: detailData
                  .map((e) => ({
                    service: e.service * e.item_quantity ?? 0,
                  }))
                  .reduce((prev, v) => prev + v.service, 0),

                is_open_bill: tableKey?.is_open_bill,
                sales_type: headerData?.sales_type,
                updated_by: user_name,
                old_table_code: detailData[0]?.table_code,
                old_table_id: detailData[0]?.table_code,
                pax: tableKey?.pax,
                order_status: tableKey?.order_status,
                kitchen_status: tableKey?.kitchen_status,
                segment: tableKey?.segment,
                source: tableKey?.source,
                room_number: room_number,
              },
              {
                headers: {
                  Authorization: `Bearer ${token ? token : ""}`,
                },
              }
            )

            .then(async (response) => {
              if (response.status == 200) {
                console.log("Edit Header: ", response);
                successPOSOrder(response, { method: 1 });
                handlePOSPayment(res?.data[0]);
              } else {
                failedPOSOrder(response, { method: 1 });
              }
            })
            .catch((error) => {
              console.log("Error: ", error);
              failedPOSOrder(error, { method: 1 });
            });
        }
      })

      .catch((error) => {
        console.log("Res Last Err: ", error);
      });
  };

  const handlePOSPayment = async (v) => {
    let val = detailData.map((e, i) => {
      return {
        cust_amount: form.getFieldsValue(["payment_amount"]).payment_amount,
        bill_status: 0,
        order_id_alias: detailData[0]?.order_id_alias ? detailData[0]?.order_id_alias : v.order_id_alias,
        created_by: user_name,
      };
    });

    console.log("handlePOSPayment body: ", val);
    await axios
      .post(
        `${baseurl}/pos/order-payment`,
        {
          data: val,
        },
        {
          headers: { Authorization: `Bearer ${token ? token : ""}` },
        }
      )
      .then((response) => {
        console.log("POS Payment: ", response);
        
        let bodyReceipt = state?.detailData.map((e) => {
          return {
            ...e,
            name: state?.headerData.name ?? "NON STAY GUEST",
            payment_status: state?.headerData.payment_status,
            sales_type: state?.headerData.sales_type,
          };
        });

        let totalPrice = 0;
        let totalTax = 0;
        let totalService = 0;
        let gross = 0;

        state?.detailData.map((e) => {
          totalPrice +=
            (e.unit_price ?? 0) *
            ((e.item_quantity || e.quantity) ?? 0);
          totalTax += (e.item_quantity || e.quantity) * e.tax ?? 0;
          totalService +=
            (e.item_quantity || e.quantity) * e.service ?? 0;
          gross += (e.item_quantity || e.quantity) * e.gross ?? 0;
        });

        if (response.status === 200) {
          let body = {
            printer_id: 2,
            order_id_alias: bodyReceipt[0]?.order_id_alias,
            date: bodyReceipt.length > 0 ? moment().format("DD-MM-YYYY HH:mm:ss") : "",
            name: bodyReceipt[0]?.name,
            sales_type: { 1: "Dine-In", 2: "Take Away", 3: "Online Order", }[bodyReceipt[0]?.sales_type] ?? "Others",
            table_code: bodyReceipt[0]?.table_code,
            source: (bodyReceipt[0]?.segment == 2) ? `Guest In House` : `Walk In Guest`,
            pax: tableKey?.pax,
            subtotal: totalPrice,
            service: totalService,
            tax: totalTax,
            total: gross,
            detail: bodyReceipt.map((x) => {
              return {
                article_name: x.article_name,
                quantity: x.item_quantity,
                unit_price: x.unit_price,
                total_price: x.item_quantity * x.unit_price,
                notes: x.notes,
              }
            }),
          };

          console.log("setBodyToPrint", body);
          bodyBill(body);
          successPOSPayment(response, { method: 3 });
          setTimeout(() => {
            navigate(`${pathname}/success`, {
              state: {
                headerData: headerData,
                payment_id: response?.data?.msg,
                selectedTable: tableKey,
              },
            });
          }, 100)
        } else {
          failedPOSPayment(response, { method: 3 });
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedPOSPayment(error, { method: 3 });
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const bodyBill = (body) => {
    setBodyToPrint(body);
    let bodyBill = state?.detailData.map((e) => {
      return {
        ...e,
        name: state?.headerData.name ?? "NON STAY GUEST",
        payment_status: state?.headerData.payment_status,
        sales_type: state?.headerData.sales_type,
        source: (state?.headerData?.segment == 2) ? `Guest In House` : `Walk In Guest`,
        pax: state?.headerData?.pax,
      };
    });

    console.log("bodyBill", bodyBill);
  };

  return (
    <Layout>
      <Content>
        <Col span={24} className="expected-arrival-main-layout">
          <Row className="row-title" justify="start" style={mainBody}>
            <BsUiChecksGrid
              style={{
                height: 24,
                width: 24,
                margin: "22px 30px",
                color: "#3699FF",
              }}
            />
            <Typography
              style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
            >
              {`POS Payment Page`}
            </Typography>
          </Row>

          <Row className="space" size="large" style={{ height: 30 }} />

          {isExist ? (
            <>
              <Row
                className="row-search-component"
                justify="start"
                gutter={[30, 30]}
                // style={mainBody}
              >
                <Col
                  className="bill-col"
                  xxl={16}
                  xl={16}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={0}
                >
                  <Row style={mainBody}>
                    {/* HERE'S THE BILL */}
                    <POSPreBillFormTrx
                      totalPayment={0}
                      dataArticle={state?.detailData.map((e) => {
                        return {
                          ...e,
                          name: state?.headerData.name ?? "NON STAY GUEST",
                          payment_status: state?.headerData.payment_status,
                          sales_type: state?.headerData.sales_type,
                          pax: state?.selectedTable?.pax,
                        };
                      })}
                    />
                  </Row>
                </Col>

                <Col
                  className="btn-col"
                  xxl={8}
                  xl={8}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                >
                  <Row
                    className="row-payment"
                    justify="center"
                    align="middle"
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 5,
                      // width: "100%",
                      // minHeight: "100%",
                    }}
                  >
                    <Typography.Title
                      level={4}
                      className="text-form-title"
                      style={{
                        height: 67,
                        display: "flex",
                        alignItems: "center",
                        fontSize: 20,
                        fontWeight: 750,
                      }}
                    >
                      {`PAYMENTS`}
                    </Typography.Title>

                    <Form
                      name="resv-form-modal"
                      className="resv-form-modal"
                      form={form}
                      labelCol={{ span: 8 }}
                      labelWrap={{ span: 16 }}
                      labelAlign="left"
                      //   onChange={handleChange}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                      autoComplete="off"
                      layout="horizontal"
                      style={{
                        padding: 15,
                        width: "100%",
                      }}
                    >
                      <Divider
                        className="divider-form"
                        style={{
                          backgroundColor: "#EBEDF3",
                        }}
                      />
                      <Row
                        className="row-cashier"
                        // style={{
                        //   padding: "0px 30px 0px",
                        // }}
                      >
                        <Col span={24}>
                          <Form.Item
                            label="Total Amount"
                            name="total_amount"
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row justify="start">
                        <Col span={24}>
                          <Form.Item
                            label="Payment Amount"
                            name="payment_amount"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please input the payment amount from customer!",
                              },
                            ]}
                            style={{
                              //   margin: "30px 0px 15px",
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              // disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                              onChange={handleChange}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Change Amount"
                            name="change_amount"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input the payment amount from customer!",
                            //   },
                            // ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>

                          <Form.Item
                            label="Deposit Amount"
                            name="deposit_amount"
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Please input the payment amount from customer!",
                            //   },
                            // ]}
                            style={{
                              width: "100%",
                            }}
                          >
                            <InputNumber
                              min={0}
                              placeholder="Amount"
                              addonBefore={"Rp"}
                              disabled
                              formatter={(unit_price) => {
                                return ` ${unit_price}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>
                        <Form.Item
                          label="Payment Type"
                          name="payment_type"
                          rules={[
                            {
                              required: true,
                              message: "Please, Select the Payment Type!",
                            },
                          ]}
                          style={{ minWidth: "100%" }}
                        >
                          <MasterPaymentTypeEdit
                            getPaymentType={getPaymentType}
                            pay={null}
                          />
                        </Form.Item>
                      </Row>

                      <Row>
                        <Form.Item
                          label="Payment Details"
                          name="payment_details"
                          rules={[
                            {
                              required: true,
                              message: "Please, Select the Payment Details!",
                            },
                          ]}
                          style={{ minWidth: "100%" }}
                        >
                          <MasterPaymentCardEdit
                            getPayCard={getPayCard}
                            getPayTypeId={payId}
                            payCard={null}
                            getCode={setPayCode}
                          />
                        </Form.Item>
                      </Row>

                      <Divider
                        className="divider-form"
                        style={{
                          margin: "15px 0px",
                          backgroundColor: "#EBEDF3",
                        }}
                      />

                      <Row
                        justify="end"
                        align="middle"
                        className="row-form-btn"
                        style={{ padding: 15 }}
                        gutter={30}
                      >
                        {/* <Button
                      className="print-btn"
                      type="primary"
                      // htmlType="submit"
                      icon={<PrinterOutlined />}
                      // onClick={() => {
                      //   handlePrint();
                      //   if (counter == 0) {
                      //     myclick();
                      //   }
                      // }}
                      style={{
                        // backgroundColor: "#1BC5BD",
                        // borderColor: "#1BC5BD",
                        marginRight: 30,
                      }}
                    >
                      Print & Download
                    </Button> */}

                        <Button
                          className="submit-btn"
                          type="primary"
                          danger
                          htmlType="submit"
                          loading={loading}
                          style={{
                            backgroundColor: "#1BC5BD",
                            borderColor: "#1BC5BD",
                            margin: "0px 30px 0px 0px",
                          }}
                        >
                          {`Submit`}
                        </Button>

                        <Button
                          className="cancel-btn"
                          type="default"
                          onClick={handleReturn}
                        >
                          {`Cancel`}
                        </Button>
                      </Row>
                    </Form>
                  </Row>
                </Col>
                {/* <MasterBillTrxForm  /> */}
              </Row>
            </>
          ) : (
            <>
              <Result
                status={"500"}
                title={"ERROR!"}
                subTitle={`Sorry, something went wrong!`}
                extra={[
                  <Row
                    className="extra-row"
                    key={"extra-row"}
                    justify="center"
                    gutter={30}
                  >
                    <Col className="return-col" span={24}>
                      <Space className="btn-space" size="large">
                        <Tooltip
                          className="return-tooltip"
                          title="Return to Expected Departure Page"
                          color="geekblue"
                          placement="right"
                        >
                          <Button
                            className="cancel-btn"
                            key="return-btn"
                            onClick={handleReturn}
                          >
                            {`Return`}
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>,
                ]}
                style={mainBody}
              >
                <>
                  <Space className="result-space" direction="vertical" size={5}>
                    <Paragraph>
                      <Text
                        strong
                        style={{
                          fontSize: 16,
                        }}
                      >
                        {`The content you submitted may has the following error:`}
                      </Text>
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You are trying to reach this page without doing any process!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`You're request is not valid!`}
                    </Paragraph>

                    <Paragraph>
                      <CloseCircleOutlined
                        className="site-result-error-icon"
                        style={{
                          color: "#E73827",
                          margin: "0px 10px 0px 0px",
                        }}
                      />
                      {`Please, check and modify the following information before resubmitting!`}
                    </Paragraph>
                  </Space>
                </>
              </Result>
            </>
          )}
        </Col>
      </Content>
    </Layout>
  );
}
