// Import React's Component
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";

// AUTH
import { token } from "../../../../API/Global/Payload";

// CONTEXT
import { Context as MainContext } from "../../../../API/Context/MainContext/MainContext";

// Parameters
import ParametersFrontOffice from "../../../Reusable/Parameters/ParamsFO";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Tooltip,
  DatePicker,
  Typography,
  Select,
  Dropdown,
  Tag,
  notification,
  Tabs,
} from "antd";

// Import React Icons
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons/lib/icons";
import { PiFastForwardCircleFill } from "react-icons/pi";
import { ImExit } from "react-icons/im";
import { IoClose } from "react-icons/io5";
import { FaClipboardList, FaPlaneDeparture } from "react-icons/fa";
import { MdDoorSliding } from "react-icons/md";

// Notification
import { failedFetch } from "../../../Reusable/Notification/Notification";

// Import Page Components
import DeparturePopover from "../../../Popover/Departure/Popover";
import ModalCheckOutForm from "../../../Forms/CheckOut/Form";
import MasterSegment from "../../../Reusable/Master/Segment/Segment";
// import ModalAddArticle from "../../../Modals/Article/Modal";

// Import Functions
import { LocalizationDate } from "../../../Reusable/Functions/Localizations/Localization";
import { FrontOfficeParams } from "../../../Reusable/Functions/Parameters/ParamsFO";
import DepartureForm from "../../../Forms/Departure/Form";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

// Modal Confirm
const { confirm, error } = Modal;

// CODE
export default function ExpectedDepartureTable(props) {
  // PROPS
  const { is_search, searchKey, searchId } = props;

  // CONST TIME NOW
  const timeNow = moment().hour();
  const dateNow = moment().format("YYYY-MM-DD");

  // CONTEXT
  const { getExpectedDeparture } = useContext(MainContext);

  // FO PARAMS
  let params = ParametersFrontOffice().params;
  const param_fo = FrontOfficeParams();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);
  const [bulksGroup, setBulksGroup] = useState([]);
  const [disable, setDisable] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  // Group State
  const [isGroup, setIsGroup] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [activeKey, setActiveKey] = useState("1");
  // Modal
  const [open, setOpen] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  // Is Check Out
  const [checkOut, setCheckOut] = useState(false);
  const [disableEarly, setDisableEarly] = useState(true);
  const [refresh, setRefresh] = useState(false);
  // Loading
  const [skeleton, setSkeleton] = useState(false);
  const [loading, setLoading] = useState(false);

  // Table's Column
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      align: "left",
      width: 150,
      hidden: isGroup ? true : false,
      render: (_, record) => {
        var _able = true;
        var _complete = checkAvailability(record);
        const _departToday = moment(record?.departure).format("YYYY-MM-DD");
        var _isToday = moment(dateNow).isSameOrAfter(_departToday);

        if (disable == false) {
          if (_complete == true) {
            if (_isToday == true) {
              _able = false;
            } else {
              if (record.is_late_checkout == true) {
                _able = false;
              } else {
                _able = true;
              }
            }
          }
        } else {
          if (record.is_late_checkout == true && _complete == true) {
            _able = false;
          } else {
            _able = true;
          }
        }

        return (
          <>
            <Row
              className="action-table-btn"
              justify="center"
              style={{ width: "100%" }}
            >
              <Col span={24}>
                {record?.night > 1 ? (
                  <DeparturePopover
                    details={record}
                    is_refresh={handleRefresh}
                    disabled={_able}
                    time_disable={disable}
                  />
                ) : (
                  <Tooltip title="Check-Out" className="check-out-popover">
                    <Button
                      className="checkout-btn"
                      type="danger"
                      disabled={_able}
                      icon={
                        <ImExit
                          className="co-icon"
                          style={{
                            margin: "0px 10px 0px 0px",
                          }}
                        />
                      }
                      onClick={() => {
                        console.log("About to Checked Out >> ", record);
                        handleCheckOut(record);
                      }}
                      // style={{ backgroundColor: "#13c2c2", borderColor: "#13c2c2" }}
                    >
                      {`Check-Out`}
                    </Button>
                  </Tooltip>
                )}
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Reservation ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
      fixed: "left",
      width: 175,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.reservation_id.localeCompare(b.reservation_id),
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      fixed: "left",
      width: 200,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.guest_name.localeCompare(b.guest_name),
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No",
      dataIndex: "room_number",
      key: "room_number",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.room_number - b.room_number,
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 80,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.night - b.night,
    },
    {
      title: "Late C/O Status",
      dataIndex: "is_late_checkout",
      key: "is_late_checkout",
      align: "center",
      render: (show, record) => {
        return show == true ? (
          <Tooltip key={record.id} title={`Guest Requested for Late Check-Out`}>
            <CheckCircleOutlined
              key={record.id}
              style={{
                color: "#389e0d",
                fontSize: 20,
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip key={record.id} title={`Not Listed as Late Check-Out`}>
            <CloseCircleOutlined
              key={record.id}
              style={{
                color: "#cf1322",
                fontSize: 20,
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Check-In Date",
      dataIndex: "checkin_date",
      key: "checkin_date",
      sortDirections: ["descend", "ascend"],
      render: (checkin, record) => {
        return moment(LocalizationDate(checkin)).format(
          "HH:mm:ss - DD MMM YYYY"
        );
      },
      sorter: (a, b) => moment(a.checkin_date) - moment(b.checkin_date),
    },
    {
      title: "Arrivals",
      dataIndex: "arrival",
      key: "arrival",
      // sortDirections: ["descend", "ascend"],
      render: (arr, record) => {
        return moment(arr).format("DD MMM YYYY");
      },
      //sorter: (a, b) => a.departure.localeCompare(b.departure),
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      // sortDirections: ["descend", "ascend"],
      render: (depart, record) => {
        return moment(depart).format("DD MMM YYYY");
      },
      sorter: (a, b) => a.departure.localeCompare(b.departure),
    },
    {
      title: "Adult",
      dataIndex: "adult",
      key: "adult",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.adult - b.adult,
    },
    {
      title: "Guest Status",
      dataIndex: "guest_category",
      key: "guest_category",
      // width: 150,
      filters: [
        {
          text: "REGULAR",
          value: "REGULAR",
        },
        {
          text: "VVIP",
          value: "VVIP",
        },
        {
          text: "VIP",
          value: "VIP",
        },
        {
          text: "INCOGNITO",
          value: "INCOGNITO",
        },
        {
          text: "RED ALERT",
          value: "RED ALERT",
        },
        {
          text: "UNASSIGNED",
          value: "",
        },
      ],
      onFilter: (value, record) => {
        // console.log("VALUE = ", value);
        return record.guest_category === value;
      },
      render: (category, record) => {
        let gst = "";
        let clr = "";

        switch (category) {
          case "VIP":
            gst = "VIP";
            clr = "geekblue";
            break;

          case "VVIP":
            gst = "VVIP";
            clr = "green";
            break;

          case "INCOGNITO":
            gst = "INCOGNITO";
            clr = "default";
            break;

          case "RED ALERT":
            gst = "RED ALERT";
            clr = "magenta";
            break;

          case "REGULAR":
            gst = "REGULAR";
            clr = "gold";
            break;

          case "":
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          case null:
            gst = "UNASSIGNED";
            clr = "purple";
            break;

          default:
            break;
        }

        return (
          <Tag
            color={clr}
            style={{
              fontSize: 13,
              fontWeight: 500,
              borderColor: "transparent",
            }}
          >
            {gst}
          </Tag>
        );
      },
    },
    // {
    //   title: "Child",
    //   dataIndex: "child",
    //   key: "child",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.child.localeCompare(b.child),
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "infant",
    //   key: "infant",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.infant.localeCompare(b.infant),
    // },
    {
      title: "Room Rate",
      dataIndex: "rate_code",
      key: "rate_code",
    },
    {
      title: "Memo Room",
      dataIndex: "memo_room",
      key: "memo_room",
    },
    {
      title: "Guest Type",
      dataIndex: "group",
      key: "group",
      hidden: isGroup ? false : true,
      render: (group, record) => {
        let grp = "";
        let clr = "";

        switch (group) {
          case 0:
            grp = "Individual Guest";
            clr = "#52c41a";
            break;

          case 1:
            grp = "Group Leader";
            clr = "#1677ff";
            break;

          case 2:
            grp = "Group Member";
            clr = "#8c8c8c";
            break;

          default:
            break;
        }

        return (
          <Tag key={"key-tag"} color={clr}>
            {grp}
          </Tag>
        );
      },
    },
  ].filter((item) => !item.hidden);

  // USE NAVIGATE
  const navigate = useNavigate();
  // USE LOCATION
  const { pathname, state } = useLocation();

  // Fetch Data
  const fetchData = async (keys) => {
    await getExpectedDeparture({
      expectedDeparture: "guest-in-house",
      type: "stay",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Expected Departure => ", response);
        console.log("Parameters FO: ", params);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          dataConfig({
            active_key: keys?.source,
            array: _res,
          });
        } else {
          setData([]);
          setBulks([]);
          setBulksGroup([]);
        }

        if (params?.checkout_time) {
          handleDisable(params);
        } else {
          setDisable(true);
        }

        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        failedFetch(error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (params) {
      fetchData();
    }
    // is_search(() => handleSearch);

    if (refresh == true && !cleanUp) {
      fetchData();
      setRefresh(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [refresh, params]);

  // USE EFFECT CHECK OUT
  useEffect(() => {
    let cleanUp = false;

    if (state?.length > 0 && !cleanUp) {
      console.log("Return from CO: ", state);

      if (isGroup) {
        selectedRows(state);
        setSelectedData(state);
      } else {
        selectedRows([]);
        setSelectedData([]);
      }
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // Use Effect Search
  useEffect(() => {
    let cleanUp = false;

    if (selectedData.length > 0 && !cleanUp) {
      handleDisableEarly(selectedData);
    } else {
      setDisableEarly(true);
    }

    return () => {
      cleanUp = true;
    };
  }, [selectedData]);

  // Use Effect Search
  useEffect(() => {
    is_search(() => handleSearch);

    if (searchId) {
      is_search(() => handleSearchId);
      // handleSearchId(searchId)
    }
  }, [searchKey, searchId]);

  // CHANGE PAGE SIZE
  const changePageSize = (val, size) => {
    setPageSize(size);
  };

  // HANDLE DISABLE
  const handleDisable = (param) => {
    const _max = moment(param.checkout_time).hour();

    console.log(_max);
    console.log(`${timeNow} < ${_max}`, timeNow < _max);

    if (timeNow < _max) {
      setDisable(false);
    }
  };

  // DATA CONFIG
  const dataConfig = async (value) => {
    const _data = value.array;
    const _keys = value.active_key > 0 ? value.active_key : activeKey;

    // Filtering by Date
    const filtered = _data.filter((val) => {
      const _date = val?.departure
        ? moment(val.departure).isSame(dateNow, "days")
        : null;

      if (_keys == 1) {
        if (_date) return val;
      } else {
        return val;
      }
    });

    console.log("Filter by Date: ", filtered);

    filterDate(filtered);
  };

  // Handle Data
  const filterDate = (val) => {
    const _array = val;

    // Array Buckets
    let arr1 = [];
    let arr2 = [];

    const filter = _array.filter((val) => {
      if (val?.group > 0) {
        arr1.push(val);
      } else {
        arr2.push(val);
      }
    });

    setBulksGroup(arr1);
    setBulks(arr2);

    if (isGroup) {
      setData(arr1);
    } else {
      setData(arr2);
    }
  };

  // HANDLE CHANGE
  const handleChange = async (value) => {
    const content = value ? moment(value).format("YYYY-MM-DD") : null;
    const start = moment(content).format("YYYY-MM-DD");
    const end = moment(value?.length > 0 ? value[1] : "").format("YYYY-MM-DD");

    console.log("Date Range --> ", content);

    if (bulks.length > 0 && bulksGroup.length > 0 && content) {
      if (isGroup) {
        const updatedData = bulks.filter((val) => {
          // return moment(val?.departure).isBetween(start, end, undefined, "[]");
          return moment(val?.departure).isSame(content, "days");
        });

        console.log("Filter Date Range => ", updatedData);
        setData(updatedData);
      }
    } else {
      setData([]);
    }
  };

  // HANDLE SEGMENT
  const getSegment = (value) => {
    console.log(value);
  };

  // HANDLE GROUP
  const handleGroup = async (e) => {
    const _ind = e;
    setLoading(true);

    if (_ind === 1) {
      let _data = bulks.filter((val) => {
        return val.group > 0;
      });

      setData(bulksGroup);
      setIsGroup(true);
      setPageSize(10);
    } else {
      setData(bulks);
      setIsGroup(false);
      setPageSize(5);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // HANDLE ROW KEYS
  const selectedRows = (value) => {
    const _keys = value;

    let filteredKey = _keys.map((val) => {
      return val.id;
    });

    console.log("KEYS --> ", filteredKey);
    setSelectedKeys(filteredKey);
  };

  // TABS KEYS
  const onKeyChange = async (key) => {
    const keys = parseInt(key);

    setActiveKey(key);
    setIsGroup(false);
    setPageSize(5);
    setSelectedData([]);

    await fetchData({
      source: keys,
    });
  };

  // HANDLE ROW SELECTIONS
  const rowSelection = {
    type: "checkbox",
    columnTitle: "Actions",
    //   return <Dropdown className="dropdown-check" trigger={"click"} />;
    // },
    columnWidth: 100,
    checkStrictly: true,
    selectedRowKeys: selectedKeys,
    onSelect: (selectedRowKeys, _selected, selectedRows) => {
      console.log(`Selected Row Keys: `, selectedRowKeys);
      console.log("Selected Rows: ", selectedRows);
      // console.log("Selected Type: ", _selected);

      const _type = _selected;

      // handleDisableEarly(selectedRows);

      // setSelectedData(selectedRows);
      // setSelectedKeys(selectedRowKeys);

      if (_type == false) {
        // console.log("This is Hit!", _type);
        setSelectedKeys([]);
        setSelectedData([]);
        setIncomplete(false);
      } else {
        onSelectChange(selectedRowKeys, selectedRows);
      }
    },
    getCheckboxProps: (record) => {
      var _able = true;
      var _complete = checkAvailability(record);
      const _departToday = moment(record?.departure).format("YYYY-MM-DD");
      var _isToday = moment(dateNow).isSameOrAfter(_departToday);

      // console.log("Tolong Cek dong ini Disable nya: ", disable);
      // console.log("Tolong Cek dong ini Disable nya: ", record);

      if (disable == false) {
        if (_complete == true) {
          if (_isToday == true) {
            _able = false;
          } else {
            if (record.is_late_checkout == true) {
              _able = false;
            } else {
              _able = true;
            }
          }
        }
      } else {
        if (record.is_late_checkout == true && _complete == true) {
          _able = false;
        } else {
          _able = true;
        }
      }

      // console.log(`ABLES ${record?.reservation_id}:`, _able);

      if (selectedData?.length > 0) {
        return {
          disabled: _able
            ? record.reservation_id !== selectedData[0]?.reservation_id
            : false,
        };
      } else {
        return {
          disabled: _able,
        };
      }
    },
  };

  // ON SELECT CHANGE
  const onSelectChange = (newSelectedRowKeys, recordAll, type, index) => {
    const _allKeys = recordAll;
    const _allBulks = data;
    const _result = [];

    // console.log("Selected Key: ", newSelectedRowKeys);
    console.log("Selected Data: ", _allKeys);
    // console.log("Type: ", type);
    // console.log("Index Data: ", index);
    // setSelectedRowKeys(newSelectedRowKeys);

    if (_allBulks.length > 0) {
      // All Selected Keys
      let keys = _allBulks
        .filter((val) => {
          return val?.reservation_id == _allKeys[0]?.reservation_id;
        })
        .map((value) => value?.id);

      // : data
      //     .filter((i) => i?.reservation_id == recordAll[0]?.reservation_id)
      //     .map((i) => i?.id);

      // All Selected Data
      let _selected = _allBulks
        .filter((value) => {
          return value?.reservation_id === recordAll[0]?.reservation_id;
        })
        .sort((a, b) => a.group - b.group);

      for (let i = 0; i < _selected?.length; i++) {
        let _bool = checkAvailability(_selected[i]);

        if (_bool == false) {
          setIncomplete(true);
          break;
        }
      }

      // Total Category Room
      // Merge Room
      const merged = _selected.reduce((acc, init) => {
        let key = init.room_category;

        // let room_number = [];
        // room_number.push(init.room_number);

        acc[key] = acc[key] || [];
        acc[key].push(init.room_number);

        return acc;
      }, {});

      if (merged) {
        for (var key in merged) {
          // console.log(key);
          // console.log(merged);

          _result.push({
            room_category: key,
            room_number: merged[key],
            room_qty: merged[key].length,
            reservation_id: _selected[0].reservation_id,
          });
        }
      }

      console.log("All Keys = ", keys);
      console.log("All Selected = ", _selected);
      // console.log("Hasil Merged = ", _merged);
      console.log("Hasil Results = ", _result);
      // console.log("Room Category Total = ", merged);

      setSelectedKeys(keys);
      setSelectedData(_selected);

      // setModalData(_result);
    }
  };

  // Handle Hide
  const handleHide = () => {
    let _hide = true;

    const _record = moment(selectedData[0]?.departure).format("YYYY-MM-DD");
    var _isToday = moment(dateNow).isSameOrAfter(_record, "days");

    const _findLate =
      selectedData.length > 0
        ? selectedData.filter((x) => {
            x.is_late_checkout == true;
          })
        : false;

    if (disable) {
      if (_isToday && selectedData.length > 0 && _findLate.length > 0) {
        _hide = false;
      } else {
        _hide = true;
      }
    } else {
      if (_isToday && selectedData.length > 0) {
        _hide = false;
      } else {
        _hide = true;
      }
    }

    return _hide;
  };

  // Handle Disable Early
  const handleDisableEarly = (value) => {
    const _record = value;

    var _date =
      moment(_record[0]?.departure).format("YYYY-MM-DD") >
      moment().format("YYYY-MM-DD");

    // console.log(
    //   "Date Departure: ",
    //   _date,
    //   moment(_record[0]?.departure).format("YYYY-MM-DD"),
    //   " > ",
    //   moment().format("YYYY-MM-DD")
    // );

    if (_date && _record[0]?.night > 1) {
      setDisableEarly(false);
    } else {
      setDisableEarly(true);
    }
  };

  // Handle Check Out
  const handleCheckOut = async (value) => {
    const _record = [value];
    const path = "guest-folio";

    console.log("Record => ", _record);

    if (incomplete) {
      IncompleteData();
    } else {
      navigate(`${path}`, {
        state: isGroup ? selectedData : _record,
      });
    }
  };

  // Handle Check Out
  const handleEarly = async (value) => {
    const _record = [value];
    console.log("Navigate => ", pathname, state);
    console.log("Record => ", _record);

    if (incomplete) {
      IncompleteData();
    } else {
      navigate(`${pathname}/early-check-out`, {
        state: isGroup ? selectedData : _record,
      });
    }
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpen(false);
    // setDataCheck(null);
  };

  // CHECK COMPLETENESS
  const checkAvailability = (param) => {
    return !Object.keys(
      (({
        group_name,
        // guest_category,
        arrival_flight,
        eta,
        departure_flight,
        etd,
        is_dummy,
        is_group,
        //tak tambah iki sitik kelewat ning ngisor
        letter,
        // sales,
        created_by,
        created_date,
        nationality, ///???
        // reservation_id, ///???
        memo_room,
        room_type,
        arrangement,
        deposit,
        room_rate_id,
        is_no_show,
        reason,

        // guest_id,
        // res_created_by,
        // res_created_date,

        updated_by,
        updated_date,
        sub_segment_id,
        rate_code,

        is_checkin,
        checkin_date,
        checkin_by,

        step_date,
        step_by,

        is_cm,
        is_step,
        is_deleted,
        is_checkout,

        checkout_by,
        checkout_date,

        deleted_by,
        deleted_date,

        is_nonstay,
        nonstay_by,
        nonstay_count,
        nonstay_date,

        is_audit,
        audit_at,
        audit_by,
        // room_type,
        is_late_checkout,
        late_checkout_by,
        late_checkout_date,

        re_audit_at,
        is_re_audit,
        re_audit_by,

        ...o
      }) => o)(param)
    ).some((key) => {
      // console.log("Keys: ", key, " || Values: ", param[key]);

      // console.log("IS LATE CO? ==> ", key, param[key], late);

      return (
        param[key] === "" || param[key] === null || param[key] === undefined
      );
    });
  };

  // HANDLE REFRESH
  const handleRefresh = async () => {
    // console.log("refresh is Hit!", e);
    setSelectedKeys([]);

    await fetchData();
  };

  // HANDLE SEARCH
  const handleSearch = () => {
    const query = searchKey ? searchKey.toLowerCase() : null;

    // console.log("Type = ", query);

    if (bulks.length > 0) {
      let updatedList = bulks.filter((item) => {
        const _name = item.guest_name.toLowerCase();
        // console.log("ITEM => ", item)
        return (
          item.guest_category != "INCOGNITO" && _name.indexOf(query) !== -1
        );
      });
      // Trigger render with updated values
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Handle Search ID
  const handleSearchId = () => {
    const query = searchId ? searchId.toLowerCase() : null;

    if (bulks.length > 0) {
      const updatedList = bulks.filter((item) => {
        const _id = item?.reservation_id.toLowerCase();

        return item?.guest_category != "INCOGNITO" && _id.indexOf(query) !== -1;
      });
      setData(updatedList);
    } else {
      setData([]);
    }
  };

  // Notifications
  const IncompleteData = () => {
    notification.open({
      className: "notif-failed-fetch",
      message: null,
      description: (
        <>
          <Row align="middle" className="login-notif" style={{ width: "100%" }}>
            <Col span={6}>
              <WarningOutlined
                className="icon-warning"
                style={{
                  color: "#FFFFFF",
                  fontSize: 50,
                }}
              />
            </Col>

            <Col span={18}>
              <Row
                className="login-notif"
                style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
              >
                {`DATA INCOMPLETE!`}
              </Row>

              <Space
                className="fetch-failed-notif"
                direction="vertical"
                wrap={true}
                size={2}
                style={{ width: "100%", fontSize: 14 }}
              >
                {`Check-Out Prohibited!}`}

                {`Please, Complete the Member's Remaining Data to Proceed C/O!}`}
              </Space>
            </Col>
          </Row>
        </>
      ),
      closeIcon: (
        <IoClose
          className="close-icon"
          style={{ color: "#FFF", fontSize: 20 }}
        />
      ),
      placement: "top",
      duration: 30,
      style: {
        borderRadius: 5,
        minHeight: 125,
        // height: 75,
        padding: "0px 20px 10px",
        background: `linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)`,
        // color: "#5c0011",
        color: "#FFFFFF",
        // width: "100%",
      },
      maxCount: 1,
    });
  };

  // TAB'ITEMS
  const items = [
    {
      label: (
        <>
          <Row
            className="row-first-tab"
            justify="start"
            align="middle"
            gutter={30}
            style={{
              height: "100%",
              width: "100%",
              border: activeKey == 1 ? "1px solid #f85032" : "none",
              margin: 0,
              borderRadius: 4,
            }}
          >
            <Col className="tab-col">
              <Button
                className="1st-icon-tab-reservation-btn"
                icon={<FaPlaneDeparture />}
                // disabled={activeKey == 1 ? false : true}
                type={activeKey == 1 ? "danger" : "default"}
              />
            </Col>

            <Col
              style={{
                marginTop: 15,
                color: activeKey == 1 ? "#f85032" : "#000000",
              }}
            >
              <p>{`Today's Departure`}</p>
            </Col>
          </Row>
        </>
      ),
      key: "1",
      children: (
        <>
          <Row justify="start" className="row-refresh-btn" align="bottom">
            <Col
              span={12}
              className="daterange-col"
              style={{
                padding: "15px 0px 0px 30px",
              }}
            >
              <Typography style={{ fontWeight: 750 }}>
                {`CHOOSE CHECK-OUT TYPE:`}
              </Typography>

              <Select
                key="type-select"
                className="type-select"
                allowClear
                showSearch
                defaultValue={0}
                onChange={(e) => {
                  // console.log(e);
                  handleGroup(e);
                }}
                options={[
                  {
                    label: "Individual",
                    value: 0,
                  },
                  {
                    label: "Group",
                    value: 1,
                  },
                ]}
                style={{
                  minWidth: 205,
                }}
              />
            </Col>

            <Col
              span={12}
              className="refresh-btn-col"
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "10px 0px",
              }}
            >
              <Button
                className="checkout-btn"
                type="danger"
                icon={
                  <ImExit
                    className="co-icon"
                    style={{
                      margin: "3px 10px 0px 0px",
                    }}
                  />
                }
                hidden={!isGroup}
                disabled={handleHide()}
                onClick={handleCheckOut}
              >
                {`Check-Out Group`}
              </Button>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="expected-depart-table"
              key="expected-depart-table"
              name="expected-depart-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              rowSelection={isGroup ? { ...rowSelection } : false}
              pagination={{
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                onChange: changePageSize,
                // onShowSizeChange: onShowSizeChange,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
              }}
              rowClassName={(record, index) => {
                return checkAvailability(record) ? "completed" : "incompleted";
                //&& (record.reservation_status == "Guaranteed" ||
                //record.reservation_status == "Not Guaranteed" )
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </>
      ),
    },
    {
      label: (
        <>
          <Row
            className="row-first-tab"
            justify="start"
            align="middle"
            gutter={30}
            style={{
              height: "100%",
              width: "100%",
              border: activeKey == 2 ? "1px solid #f85032" : "none",
              margin: 0,
              borderRadius: 4,
            }}
          >
            <Col className="tab-col">
              <Button
                className="1st-icon-tab-reservation-btn"
                icon={<MdDoorSliding />}
                // disabled={activeKey == 2 ? false : true}
                type={activeKey == 2 ? "danger" : "default"}
              />
            </Col>

            <Col
              style={{
                marginTop: 15,
                color: activeKey == 2 ? "#f85032" : "#000000",
              }}
            >
              <p>{`All Departure`}</p>
            </Col>
          </Row>
        </>
      ),
      key: "2",
      children: (
        <>
          <Row
            justify="start"
            className="row-refresh-btn"
            align="top"
            gutter={[0, 30]}
            style={{
              padding: "15px 0px",
            }}
          >
            <Col span={6} className="daterange-col">
              <Typography style={{ fontWeight: 750 }}>
                {`CHOOSE DEPARTURE DATE:`}
              </Typography>

              <DatePicker
                className="datepick"
                onChange={handleChange}
                style={{
                  width: "100%",
                }}
              />
            </Col>

            <Col span={6} className="daterange-col">
              <Typography style={{ fontWeight: 750 }}>
                {`CHOOSE CHECK-OUT TYPE:`}
              </Typography>

              <Select
                key="type-select"
                className="type-select"
                allowClear
                showSearch
                defaultValue={0}
                onChange={(e) => {
                  // console.log(e);
                  handleGroup(e);
                }}
                options={[
                  {
                    label: "Individual",
                    value: 0,
                  },
                  {
                    label: "Group",
                    value: 1,
                  },
                ]}
                style={{
                  minWidth: 205,
                }}
              />
            </Col>

            <Col
              span={12}
              className="refresh-btn-col"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Space
                className="btn-space"
                size="large"
                direction="horizontal"
                align="end"
                style={{
                  padding: "15px 0px",
                }}
              >
                <Button
                  className="checkout-btn"
                  type="danger"
                  icon={
                    <ImExit
                      className="co-icon"
                      style={{
                        margin: "3px 10px 0px 0px",
                      }}
                    />
                  }
                  hidden={!isGroup}
                  disabled={handleHide()}
                  style={{
                    width: "100%",
                  }}
                  onClick={handleCheckOut}
                >
                  {`Check-Out Group`}
                </Button>

                <Button
                  className="checkout-btn"
                  type="danger"
                  icon={
                    <PiFastForwardCircleFill
                      className="co-icon"
                      style={{
                        margin: "3px 10px 0px 0px",
                      }}
                    />
                  }
                  hidden={!isGroup}
                  // hidden={true}
                  disabled={disableEarly}
                  style={{
                    width: "100%",
                  }}
                  onClick={handleEarly}
                >
                  {`Early Check-Out Group`}
                </Button>
              </Space>
            </Col>
          </Row>

          <Row className="row-table" style={{ marginTop: 30, width: "100%" }}>
            <Table
              className="expected-depart-table"
              key="expected-depart-table"
              name="expected-depart-table"
              loading={loading}
              columns={columns}
              dataSource={data}
              bordered={true}
              rowSelection={isGroup ? { ...rowSelection } : false}
              pagination={{
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 20, 50, 100],
                showSizeChanger: true,
                onChange: changePageSize,
                // onShowSizeChange: onShowSizeChange,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} entries`,
              }}
              scroll={{
                x: 2000,
              }}
              rowClassName={(record, index) => {
                return checkAvailability(record) ? "completed" : "incompleted";
                //&& (record.reservation_status == "Guaranteed" ||
                //record.reservation_status == "Not Guaranteed" )
              }}
              rowKey={(record) => (record.id ? record.id : record.key)}
            />
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Row className="reservation-main-table" justify="center" style={mainBody}>
        <Col
          className="col-main-table"
          span={24}
          style={{ padding: "10px 30px", width: "100%" }}
        >
          <Tabs
            key="history-res-tabs"
            name="history-res-tabs"
            className="history-res-tabs"
            defaultActiveKey="1"
            type="card"
            activeKey={activeKey}
            onChange={onKeyChange}
            tabBarExtraContent={
              <Button
                className="refresh-btn"
                type="default"
                icon={<SyncOutlined />}
                onClick={fetchData}
              >
                {`Refresh`}
              </Button>
            }
            // tabBarStyle={{
            //   border: "2px solid #000000",
            // }}
            items={items}
            style={{
              width: "100%",
            }}
          />
        </Col>
      </Row>

      {/* <ModalCheckOutForm
        openModal={open}
        closeModal={setOpen}
        submitDataCkOutForm={open == true ? dataCheck : ""}
        is_early={false}
        is_checkOut={setCheckOut}
        is_refresh={setRefresh}
      /> */}
    </>
  );
}
