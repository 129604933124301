// Import React Components
import moment from "moment";

// CODE
export const FormatDate = (props) => {
  const { time, params_date } = props;

  let _new_format = "";

  switch (params_date) {
    case 1:
      _new_format = moment(time).format("DD-MM-YYYY");
      break;

    case 2:
      _new_format = moment(time).format("YYYY-MM-DD");
      break;

    case 3:
      _new_format = moment(time).format("DD MMM YYYY");
      break;

    default:
      _new_format = " - ";
      break;
  }

  return _new_format;
};
