// Import React Components
import React, { useState, useEffect } from "react";
import axios from "axios";

// BASEAPI
import { baseurl } from "../../../../API/Config/Api";
import { token, user_name } from "../../../../API/Global/Payload";

// Import React Components
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
} from "antd";

// Import React Icons
import {
  FaConciergeBell,
} from "react-icons/fa";

// Import Page Components
import MasterServeQue from "../../../Reusable/Outlet/Master/ServeQue/ServeQue";

// Import Functions
import { CurrencySymbols } from "../../../Reusable/Functions/Currency/Currency";

// Import Notifications
import { masterIncomplete } from "../../../Reusable/Notification/MasterNotif/Notification";
import {
  failedPOSOrder,
  successPOSOrder,
} from "../../../Reusable/Notification/Notification";

// CODE
export default function ModalTableOrder(props) {
  // PROPS
  const {
    openModal,
    closeModal,
    // Data
    articleItems,
    articleTable,
    is_edit,
    is_refresh,
  } = props;

  // Params
  const currency = CurrencySymbols().code;

  // STATE MANAGEMENT
  // Modal
  const [open, setOpen] = useState(false);
  // Edit
  const [dataEdit, setDataEdit] = useState(null);
  // Loading
  const [loading, setLoading] = useState(false);

  // USE FORM
  const [form] = Form.useForm();

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal === true && !cleanUp) {
      setOpen(openModal);
    } else {
      setOpen(false);
    }

    if (articleItems && !cleanUp) {
      console.log("About to Order: ", articleItems);
      handleFormFields(articleItems);
    }
    // setFilterData(handleFilter);
    // setEdit(dataGuest);

    return () => {
      cleanUp = true;
    };
  }, [openModal, articleItems]);

  // Handle SET FORMS FIELD
  const handleFormFields = (value) => {
    const _record = value;

    if (is_edit) {
      setDataEdit(_record);

      form.setFieldsValue({
        article_name: _record?.article_name,
        gross: _record?.gross,
        item_quantity: _record?.quantity || _record?.item_quantity,
        note: _record?.note,
        serving_que: _record?.serving_que,
        serving_id: _record?.serving_id,
      });
    } else {
      form.setFieldsValue({
        article_name: _record?.article_name,
        gross: _record?.gross,
      });
    }
  };

  // Handle Serve Que
  const getServeQue = (val) => {
    const _value = val;

    console.log("Serv Que: ", _value);

    form.setFieldsValue({
      serving_que: _value?.label,
      serving_id: _value?.value,
    });
  };

  // ON FINISH
  const onFinish = (val) => {
    setLoading(true);
    const _data = val;

    console.log("Data: ", _data);

    handleSubmit(_data);
    // articleTable({
    //   ...articleItems,
    //   ..._data,
    // });

    // handleCancel();
  };

  // ON FINISH FAILED
  const onFinishFailed = (val) => {
    const _data = val;

    console.log("on Finish Failed: ", _data?.values);

    masterIncomplete(_data);
  };

  // HANDLE SUBMIT
  const handleSubmit = async (e) => {
    const contentEdit = e;
    console.log("EDITED > ", contentEdit);

    switch (is_edit) {
      case true:
        // articleTable({ ...dataEdit, ...contentEdit });
        await axios
          .put(
            `${baseurl}/pos/order-detail`,
            // {
            //   unit_price: val.unit_price,
            //   item_quantity: val.item_quantity,
            //   total_price: val.total_price,
            //   serving_order: val.serving_order,
            //   guest_name: val.guest_name,
            //   updated_by: user_name,
            //   tax: val.tax,
            //   service: val.service,
            //   gross: val.gross,
            //   add_ons: val.add_ons,
            //   id: val.id,
            //   article_id: val.article_id,
            // },
            {
              deleted_reason: e.deleted_reason,
              notes: e.notes ?? null,
              id: articleItems.id,
              created_by: user_name,
              item_quantity: e.item_quantity,
              serving_order: e.serving_order,
              total_price: e.gross * e.item_quantity,
              tax: articleItems.tax,
              service: articleItems.service,
              is_tax: articleItems.is_tax,
              is_service: articleItems.is_service,
              gross: e.gross,
              add_ons: articleItems.add_ons,
            },
            {
              headers: { Authorization: `Bearer ${token ? token : ""}` },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              console.log("Update Detail: ", response);
              successPOSOrder(response, { method: 1 });
              is_refresh(true);
              // setTimeout(() => {
              //   is_created(true);
              // }, 1000);
            } else {
              failedPOSOrder(response, { method: 1 });
            }
          })
          .catch((error) => {
            console.log("Error: ", error);
            failedPOSOrder(error, { method: 1 });
          })
          .finally(() => {
            // setIsLoading(false);
          });
        break;

      case false:
        articleTable({
          ...articleItems,
          ...contentEdit,
        });

        break;

      default:
        break;
    }

    handleCancel();
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setLoading(false);

    form.resetFields();
  };

  return (
    <>
      <Modal
        className="edit-modal-guest"
        title={
          <>
            <Row className="modaltitle-row">
              <FaConciergeBell style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>{`Order Detail`}</Typography>
            </Row>
          </>
        }
        getContainer={false}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            item_quantity: 1,
            // deleted_reason: "CANCEL ORDER",
          }}
          name="order-modal"
          className="order-modal"
          layout="vertical"
          style={{ padding: "0px 30px" }}
        >
          <Row gutter={30} className="form-main-row">
            <Col span={24} className="form-main-col">
              <Row className="serve-row" justify="start" gutter={30}>
                <Col span={16} className="serve-col">
                  <Form.Item
                    label="Serving Que"
                    name={"serving_que"}
                    rules={[
                      {
                        required: true,
                        message: "Please, Select an Option!",
                      },
                    ]}
                  >
                    <MasterServeQue
                      getServeQue={getServeQue}
                      servingQue={is_edit ? dataEdit?.serving_que : null}
                    />
                  </Form.Item>

                  <Form.Item label="Serving ID" name={"serving_id"} hidden>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8} className="qty-col">
                  <Form.Item
                    label="Items Qty"
                    name={"item_quantity"}
                    rules={[
                      {
                        required: true,
                        message: "Please, Input Items Quantity!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Items Quantity"
                      defaultValue={1}
                      min={1}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="serve-row" justify="start" gutter={30}>
                <Col span={16} className="article-col">
                  <Form.Item label="Article Name" name={"article_name"}>
                    <Input placeholder="Article Name" disabled />
                  </Form.Item>

                  <Form.Item label="Article ID" name={"article_id"} hidden>
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8} className="price-col">
                  <Form.Item label="Unit Price" name={"gross"}>
                    <InputNumber
                      placeholder="Price"
                      disabled
                      addonBefore={currency}
                      formatter={(val) => {
                        return `${val > 0 ? val : 0}`.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                      }}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={30} className="notes-row" justify="start">
                <Col className="note-col" span={16}>
                  <Form.Item label="Notes" name={"notes"}>
                    <Input.TextArea
                      placeholder="Order Notes (et. Well Done Steak)"
                      rows={4}
                      allowClear
                      maxLength={120}
                      showCount
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                {is_edit == true ? (
                  <Col className="note-col" span={8}>
                    <Form.Item
                      label="Edit Reason"
                      name={"deleted_reason"}
                      rules={[
                        {
                          required: true,
                          message: "Please, Select an Option!",
                        },
                      ]}
                    >
                      <Select
                        // defaultValue="CANCEL ORDER"
                        placeholder={"Select Option"}
                        //   style={{
                        //     width: 120,
                        //   }}
                        onChange={() => {}}
                        options={[
                          {
                            value: "CANCEL ORDER",
                            label: "CANCEL ORDER",
                          },
                          {
                            value: "WRONG POSTING",
                            label: "WRONG POSTING",
                          },
                          {
                            value: "WRONG QUANTITY",
                            label: "WRONG QUANTITY",
                          },
                          {
                            value: "OTHERS",
                            label: "OTHERS",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>

          {/* <Row gutter={30}>
            <Col span={10}>
              <Form.Item label="Categories" name={"categories"}>
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                  }}
                >
                  <List
                    itemLayout="vertical"
                    dataSource={dataCategories}
                    renderItem={(item) => (
                      <List.Item>
                        <Button
                          type="primary"
                          block
                          onClick={() => {
                            handleButtonCategories(item.categories_name);
                          }}
                          style={{ height: 50, borderRadius: 7 }}
                        >
                          <Row>
                            <Col span={14}>
                              <Row justify="start">
                                <Typography
                                  style={{
                                    color: "#ffffff",
                                    marginTop: 5,
                                  }}
                                >
                                  {item.categories_name}
                                </Typography>
                              </Row>
                            </Col>

                            <Col span={10}>
                              <Row justify="end">{item.icon}</Row>
                            </Col>
                          </Row>
                        </Button>
                      </List.Item>
                    )}
                  />
                </Space>
              </Form.Item>
            </Col>

            <Col span={14}>
              <Row>
                <Form.Item label="Sub Categories" name={"sub_categories"}>
                  <List
                    grid={{
                      gutter: 8,
                      column: {
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 4,
                        xxl: 6,
                      },
                    }}
                    className="border-color"
                    style={{ padding: 15 }}
                    dataSource={subCategories}
                    renderItem={(item) => (
                      <List.Item>
                        <Button
                          block
                          type="default"
                          className="modal-TableOrderSubCategories-btn"
                          onClick={() => {
                            handleButtonSubCategories(item.subcatg);
                          }}
                          style={{
                            backgroundColor: "#3699FF26",
                            borderColor: "#3699FF26",
                            marginRight: 30,
                            borderRadius: 7,
                          }}
                        >
                          <Row justify="center">
                            <Typography
                              style={
                                {
                                  //   color: "#ffffff",
                                }
                              }
                            >
                              {item.subcatg}
                            </Typography>
                          </Row>
                        </Button>
                      </List.Item>
                    )}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item label="Items" name={"items"}>
                  <List
                    grid={{
                      gutter: 8,
                      column: {
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 4,
                        xxl: 6,
                      },
                    }}
                    className="border-color"
                    style={{ padding: 15 }}
                    dataSource={itemsCategories}
                    renderItem={(item) => (
                      <List.Item>
                        <Button
                          block
                          type="default"
                          className="modal-TableOrderItems-btn"
                          style={{
                            backgroundColor: "#F64E6026",
                            borderColor: "#F64E6026",
                            borderRadius: 7,
                            // marginRight: 30,
                          }}
                        >
                          <Row justify="start">
                            <Typography
                              style={
                                {
                                  //   color: "#ffffff",
                                }
                              }
                            >
                              {item.items}
                            </Typography>
                          </Row>
                        </Button>
                      </List.Item>
                    )}
                  />
                </Form.Item>
              </Row>
              <Row>
                <Space size="middle">
                  <Col span={22}>
                    <Form.Item name={"items"}>
                      <Input
                        placeholder="Items Selected"
                        disabled
                        style={{ width: 200 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item name={"quantity"}>
                      <InputNumber
                        min={1}
                        max={10}
                        defaultValue={1}
                        onChange={<></>}
                      />
                    </Form.Item>
                  </Col>
                </Space>
              </Row>
              <Row>
                <Form.Item label="Notes" name="notes">
                  <Input.TextArea
                    placeholder="Insert Notes"
                    rows={3}
                    style={{ width: 350 }}
                  />
                </Form.Item>
              </Row>
              <Row justify="end">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  Add
                </Button>
              </Row>
            </Col>
          </Row> */}

          {/* <Divider style={{ margin: "15px 0px" }} /> */}

          {/* <Table
            // loading={loading}
            // rowSelection={{
            //   type: selectionType,
            //   ...rowSelection,
            // }}
            columns={columns}
            dataSource={dataSource}
            //onChange={onChange}
            pagination={{
              pageSize: 5,
            }}
            // scroll={{
            //     y: 270,
            // }}
            rowKey={(record) => (record.id ? record.id : record.key)}
          /> */}
          {/* <TablePerOrder handleFilter={filterData} /> */}

          <Divider className="divider-form" style={{ margin: "15px 0px" }} />

          <Row justify="end" align="middle">
            <Button
              type="primary"
              htmlType="submit"
              key={"submit"}
              // onClick={onFinish}
              loading={loading}
              className="submit-btn"
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 30,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
