// Import React's Component
import React, { useState } from "react";

// Import Table
import CaptainArticleMenuPage from "../../../../../Pages/Outlet/Order/ArticleMenu/CaptainArticleMenu";
import PerTablePage from "../../Order/PerTableCaptain";

// Import Ant Design Components
import {
  Space,
  Col,
  Row,
  Button,
} from "antd";

// Import Notifications
import { FileOutlined } from "@ant-design/icons";

// CODE
export default function CaptainOrderTable({ locationCategories }) {

  // STATE MANAGEMENT
  const [articleList, setArticleList] = useState(null);

  // Outlet
  // Modal
  const [newOrder, setNewOrder] = useState(false);
  const [menuDisabled, setMenuDisabled] = useState(null);

  // Handle Article
  const handleArticle = (val) => {
    const _data = val;
    console.log("handleArticle on CaptainOrderTable", _data);

    setArticleList(_data);
  };

  // Handle Menu Disabled
  const handleMenuDisabled = (val) => {
    console.log("handleMenuDisabled", val);
    setMenuDisabled(val);
  };
  

  // Render Components
  return (
    <Col
      span={24}
      className="main-space"
      align="start"
      style={{ width: "100%" }}
    >
      <Row
        className="mainspace-row"
        align="start"
        justify="space-evenly"
        gutter={[30, 30]}
      >
        <Col
          className="article-list-col"
          xxl={{ span: 14, order: 1, }}
          xl={{ span: 12, order: 1, }}
          lg={{ span: 12, order: 1, }}
          md={{ span: 24, order: 1, }}
          sm={{ span: 24, order: 1, }}
          xs={{ span: 24, order: 1, }}
        >
          <CaptainArticleMenuPage
            articleData={handleArticle}
            menuDisabled={menuDisabled}
          />
        </Col>

        <Col
          className="order-col"
          xxl={{ span: 10, order: 2, }}
          xl={{ span: 12, order: 2, }}
          lg={{ span: 12, order: 2, }}
          md={{ span: 24, order: 2, }}
          sm={{ span: 24, order: 2, }}
          xs={{ span: 24, order: 2, }}
        >
          <Row
            justify="end"
            className="row-btn"
            style={{
              padding: 30,
              background: "#FFFFFF",
              borderRadius: 5,
            }}
          >
            <Col span={12} className="ordertable-col">
              <Space
                className="title-spacetxt"
                align="center"
                size={"large"}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {`Order Detail`}
              </Space>
            </Col>
            <Col span={12} className="ordertable-new-col">
              <Row justify="end">
                <Button
                  type="primary"
                  icon={
                    <FileOutlined
                      className="pay-icon"
                      style={{
                        fontSize: 15,
                        margin: "0px 0px 0px",
                      }}
                    />
                  }
                  onClick={() => {
                    setNewOrder(true);
                    console.log("truee")
                  }}
                  style={{
                    margin: "0px 0px 0px 0px",
                  }}
                >
                  {`New Captain Order`}
                </Button>
              </Row>
            </Col>
          </Row>

          <Row className="table-order-row">
            <PerTablePage
              articleItems={articleList}
              getNewOrder={setNewOrder}
              isNewOrder={newOrder}
              menuDisabled={handleMenuDisabled}
              locationCategories={locationCategories}
            />
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
