// Import React Components
import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import axios from "axios";

// CONTEXT
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// API
import { baseurl } from "../../../API/Config/Api";

// AUTH
import { token, user_name } from "../../../API/Global/Payload";

// Import Ant Design Components
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";

// Import React's Icons
import { FiEdit } from "react-icons/fi";
import { FormOutlined } from "@ant-design/icons";

// Import Page Components

// Import Functions
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { Pricing } from "../../Reusable/Functions/Pricing/Pricing";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { TaxesParameter } from "../../Reusable/Functions/Taxes/Taxes";
import { Grossing } from "../../Reusable/Functions/GrossBreakdown/Grossing";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";

// Import Notifications
import { masterIncomplete } from "../../Reusable/Notification/MasterNotif/Notification";
import {
  failedLateCO,
  successLateCO,
} from "../../Reusable/Notification/Notification";

// MODALS
const { confirm } = Modal;

// CODE
export default function ModalLateCheckOut(props) {
  // PROPS
  const { dataGuest, openModal, closeModal, is_refresh } = props;

  // CONTEXT
  const { getArticleList } = useContext(MasterContext);

  // Params
  const currency = CurrencySymbols().code;
  const taxes = TaxesParameter();
  const accounting = AccountingParams();

  // STATE MANAGAMENT
  // Data
  const [data, setData] = useState(null);
  const [articleId, setArticleId] = useState(0);
  // Modal
  const [open, setOpen] = useState(false);
  // Tax & Service
  const [isTax, setIsTax] = useState(false);
  const [isService, setIsService] = useState(false);
  const [isServiceTaxed, setIsServiceTaxed] = useState(false);
  const [percentages, setPercentages] = useState({
    tax: taxes?.tax_percentage,
    service: taxes?.service_percentage,
  });
  // Loading
  const [isLoading, setIsLoading] = useState(false);

  // USEFORM
  const [form] = Form.useForm();

  // FETCH ARTICLE ID
  const fetchData = async () => {
    await getArticleList({
      list: "list",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const filterData = _res.filter((val) => {
            if (val.is_deleted === false || val.is_deleted === null) {
              let name = val?.article_name
                ? val.article_name.toLowerCase()
                : null;

              return name.includes("others");
            }
          });

          console.log("Article ID: ", filterData);

          if (filterData.length > 0) {
            setArticleId(filterData[0]?.id);
          } else {
            setArticleId(0);
          }
        } else {
          setArticleId(0);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (dataGuest && !cleanUp) {
      setData(dataGuest);

      setFormFields(dataGuest);
    }

    if (openModal == true && !cleanUp) {
      fetchData();
      setOpen(true);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [dataGuest, openModal]);

  // SET FIELDS
  const setFormFields = (value) => {
    const _data = value;

    form.setFieldsValue({
      reservation_id: _data?.reservation_id
        ? _data.reservation_id
        : dataGuest?.reservation_id,
      guest_name:
        _data?.guest_category == "INCOGNITO" ? "PRIVATE" : _data?.guest_name,
      room_category: _data?.room_category
        ? _data.room_category
        : dataGuest?.room_category,
      checkin_date: _data?.checkin_date
        ? moment(LocalizationDate(_data.checkin_date)).format(
            "HH:mm, DD-MM-YYYY"
          )
        : dataGuest?.guest_name,
      departure: _data?.departure
        ? moment(_data.departure).format("DD-MM-YYYY")
        : dataGuest?.departure,
      room_number: _data?.room_number || dataGuest?.room_number,
    });
  };

  // ON FINISH
  const onFinish = (value) => {
    const content = value;
    setIsLoading(true);

    // console.log("ON FINISH >>> ", content);
    showModalConfirm(content);
  };

  // ON FINISH FAILED
  const onFinishFailed = (value) => {
    const error = value;

    masterIncomplete(error);
  };

  // HANDLE PERCENTAGES
  const handlePercent = async (val) => {
    const _params = val;

    console.log("Params Acc: ", _params);

    setPercentages({
      service: _params.service_percentage,
      tax: _params.tax_percentage,
    });
  };

  // Checked Tax & Service
  const onCheckTax = (value) => {
    const _bool = value.target.checked;
    const _nett = form.getFieldValue("charge");

    console.log("TAX: ", _bool);
    console.log("Nett: ", _nett);

    setIsTax(_bool);

    form.setFieldsValue({
      is_tax: _bool,
      // gross: 0,
    });

    handlePricing(_nett, isService, _bool);
  };

  const onCheckService = (value) => {
    const _bool = value.target.checked;
    const _nett = form.getFieldValue("charge");

    console.log("Service: ", _bool);

    setIsService(_bool);

    form.setFieldsValue({
      is_service: _bool,
      // gross: 0,
    });

    handlePricing(_nett, _bool, isTax);
  };

  // Service get Taxed
  const onCheckServiceTaxed = (value) => {
    const _bool = value.target.checked;
    const _nett = form.getFieldValue("charge");

    console.log("Service: ", _bool);

    setIsServiceTaxed(_bool);

    form.setFieldsValue({
      is_service_taxed: _bool,
      // gross: 0,
    });

    handlePricing(_nett, isService, isTax);
  };

  // Handle Gross
  const handleGross = (val) => {
    console.log("Value Gross: ", val);

    const _grossing = Grossing({
      gross: val,
      tax: percentages.tax,
      service: percentages.service,
    });

    // console.log("Results: ", _grossing);

    // handleGrossing(_grossing.nett, isService, isTax);
    form.setFieldsValue({
      charge: _grossing.nett,
      tax: _grossing.taxes,
      service: _grossing.services,
    });
  };

  // HANDLE PRICING
  const handlePricing = (val, servs, taxes) => {
    let servBool = typeof servs == "boolean" ? servs : isService;
    let taxBool = typeof taxes == "boolean" ? taxes : isTax;
    let _isServiceTaxed = form.getFieldValue("is_service_taxed");

    let _price = val;

    // console.log("Type OF: ", typeof servs);
    console.log("Nett Unit Price: ", _price);
    // console.log("Percentages: ", accounting);
    // console.log(`IS TAX: ${isTax} && `, `IS SERVICE: ${isService}`);
    // console.log(`IS TAX: ${taxBool} && `, `IS SERVICE: ${servBool}`);

    let _serviceOnly = _price * (percentages.service / 100);
    let _taxOnly = _price * (percentages.tax / 100);
    // Gross Service & Tax
    let _grossSer = _price + _serviceOnly;
    let _grossTax = _price + _taxOnly;

    let _serOnly = parseFloat(_serviceOnly).toFixed(0);
    let _taxed = parseFloat(_taxOnly).toFixed(0);

    let _grossService = parseFloat(_grossSer).toFixed(0);
    let _grossTaxed = parseFloat(_grossTax).toFixed(0);

    let _newPrice = Pricing({
      nett: _price || 0,
      tax: percentages.tax || 0,
      service: percentages.service || 0,
      is_service_taxed: _isServiceTaxed,
      coefficient: accounting?.coefficient,
    });

    // console.log("Service Only --> ", _price, " + ", _serviceOnly, " = ", _grossSer);
    // console.log("Tax Only --> ", _price, " + ", _taxOnly, " + ", _grossTax);
    console.log("New Price: ", _newPrice);

    if (taxBool && servBool) {
      form.setFieldsValue({
        gross: _newPrice.gross || 0,
        service: _newPrice.services || 0,
        tax: _newPrice.taxes || 0,
      });
    } else if (taxBool && !servBool) {
      form.setFieldsValue({
        gross: _grossTaxed || 0,
        service: 0,
        tax: _taxed || 0,
      });
    } else if (!taxBool && servBool) {
      form.setFieldsValue({
        gross: _grossService || 0,
        service: _serOnly || 0,
        tax: 0,
      });
    } else {
      form.setFieldsValue({
        gross: _price || 0,
        service: 0,
        tax: 0,
      });
    }

    // console.log("New Price: ", _newPrice);
  };

  // SHOW MODAL CONFIRM
  const showModalConfirm = (value) => {
    const contentPrint = value;

    // console.log("PRINT THIS >>> ", newData);
    console.log("ABOUT TO PRINT >>> ", contentPrint);

    confirm({
      className: "change-room-confirm",
      title: `Are you sure the Guest with Named ${
        contentPrint?.guest_name.toUpperCase() || " - - - "
      } is About to Late Check-Out at ${moment(
        contentPrint.late_checkout_date
      ).format("HH:mm")}?`,
      // icon: <ExclamationCircleTwoTone />,
      okText: "Confirm",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,

      onOk() {
        handleSubmit(contentPrint);
      },

      onCancel() {
        setIsLoading(false);
        // console.log("Confirmation Cancelled");
      },

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // HANDLE SUBMIT
  const handleSubmit = async (val) => {
    const _data = val;

    console.log("About to PUT: ", _data);

    await axios
      .put(
        `${baseurl}/guest/late-checkout`,
        {
          reservation_id: _data?.reservation_id || data?.reservation_id,
          price_id: _data?.price_id || data?.price_id,
          late_checkout_date:
            moment(_data?.late_checkout_date).format("YYYY-MM-DD:HH:mm:ss") ||
            moment().format("HH:mm:ss, YYYY-MM-DD"),
          charge: parseInt(_data?.charge) > 0 ? parseInt(_data.charge) : 0,
          is_service_stay: isService,
          is_tax_stay: isTax,
          tax_stay: parseInt(_data?.tax) > 0 ? parseInt(_data.tax) : 0,
          service_stay:
            parseInt(_data?.service) > 0 ? parseInt(_data.service) : 0,
          gross_stay: parseInt(_data?.gross) > 0 ? parseInt(_data.gross) : 0,
          article_id: articleId,
          late_checkout_by: user_name,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        console.log("Success Late CO --> ", response);
        successLateCO(response);

        is_refresh(true);
        handleCancel();
      })
      .catch((error) => {
        console.log("Failed Late CO --> ", error);
        failedLateCO(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // HANDLE CLOSE MODAL
  const handleCancel = () => {
    // setClose(false);
    form.resetFields();

    setArticleId(0);

    setData(null);
    setIsLoading(false);
    // setRoomCat(null);
    // setNewRoom(null);
    // setDataPrint(null);
    // setSelect([]);

    setOpen(false);
    closeModal(false);
  };

  return (
    <>
      <Modal
        className="late-co-modal"
        title={
          <>
            <Row>
              <FiEdit
                className="modal-icon"
                style={{
                  color: "#3699FF",
                  fontSize: 24,
                }}
              />
              <Typography style={{ marginLeft: 15 }}>
                {`Late Check-Out Form`}
              </Typography>
            </Row>
          </>
        }
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Form
          className="late-co-form"
          name="late-co-form"
          key="late-co-form"
          autoComplete="false"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            is_tax: false,
            is_service: false,
            is_service_taxed: false,
            charge: 0,
          }}
          layout="vertical"
          labelAlign="left"
          style={{ padding: "0px 36px" }}
        >
          <Row gutter={30} className="row-header">
            <Col span={8} className="col-guest-name">
              <Form.Item
                label="Guest Name"
                name="guest_name"
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <Input placeholder="Guest's Name" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-res-id">
              <Form.Item
                label="Reservation ID"
                name="reservation_id"
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <Input placeholder="Reservation ID" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-cat">
              <Form.Item
                label="Room Category"
                name="room_category"
                style={{
                  width: "100%",
                }}
              >
                <Input placeholder="Room Category" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={30} className="row-date">
            <Col span={8} className="col-arrival">
              <Form.Item
                label="Check-In Date"
                name="checkin_date"
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <Input placeholder="Check-In Date" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-res-id">
              <Form.Item
                label="Departure"
                name="departure"
                style={{
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <Input placeholder="Departure" disabled />
              </Form.Item>
            </Col>

            <Col span={8} className="col-room-cat">
              <Form.Item
                label="Room Number"
                name="room_number"
                style={{
                  width: "100%",
                }}
              >
                <Input placeholder="Room Number" disabled />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "0px 0px 10px" }}
          />

          <Row
            className="late-co-time"
            gutter={30}
            align="middle"
            justify="start"
          >
            <Col span={8}>
              <Form.Item
                label="Late Check Out Time"
                name="late_checkout_date"
                rules={[
                  {
                    required: true,
                    message: "Late Check-Out's Time Cannot be Empty!",
                  },
                ]}
              >
                <DatePicker.TimePicker
                  placeholder="Check-Out Time"
                  allowClear
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Nett Charge's Amount"
                name="charge"
                rules={[
                  {
                    required: true,
                    message: "Charge's Amount Cannot be Empty!",
                  },
                ]}
                style={{
                  //   marginRight: 30,
                  width: "100%",
                }}
              >
                <InputNumber
                  placeholder="Charge's Amount"
                  addonBefore={currency}
                  onChange={handlePricing}
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8} className="tax-and-service-col">
              <Form.Item
                name="is_tax"
                valuePropName="checked"
                style={{
                  margin: 0,
                }}
              >
                <Checkbox onChange={onCheckTax}>{`Include Tax`}</Checkbox>
              </Form.Item>

              <Form.Item
                name="is_service"
                valuePropName="checked"
                style={{
                  margin: 0,
                }}
              >
                <Checkbox
                  onChange={onCheckService}
                >{`Include Service`}</Checkbox>
              </Form.Item>

              <Form.Item
                name="is_service_taxed"
                valuePropName="checked"
                style={{
                  margin: 0,
                }}
              >
                <Checkbox
                  onChange={onCheckServiceTaxed}
                >{`Services are Taxed`}</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row className="gross-row" gutter={30}>
            <Col span={8}>
              <Form.Item
                label="Gross Charge's Amount"
                name="gross"
                rules={[
                  {
                    required: true,
                    message: "Charge's Amount Cannot be Empty!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Charge's Amount"
                  disabled={isService && isTax ? false : true}
                  addonBefore={currency}
                  onChange={handleGross}
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Tax" name="tax">
                <InputNumber
                  placeholder="Taxes Amount"
                  disabled
                  addonBefore={currency}
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Service" name="service">
                <InputNumber
                  placeholder="Services Amount"
                  disabled
                  addonBefore={currency}
                  formatter={(price) => {
                    return `${price > 0 ? price : 0}`.replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row className="row-reason">
            <Form.Item
              label="Reason"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason of Late Check-Out Cannot be Empty!",
                },
              ]}
              style={{ width: "100%" }}
            >
              <Input.TextArea
                className="input-reason"
                maxLength={200}
                showCount
                placeholder="Reason of Late Check-Out"
                rows={3}
              />
            </Form.Item>
          </Row>

          <Divider
            className="divider-form"
            style={{ margin: "15px 0px 10px" }}
          />

          <Row
            justify="end"
            align="middle"
            // style={{ padding: "15px 0px 0px" }}
            className="modal-btn-form"
          >
            <Button
              className="submit-btn"
              type="primary"
              htmlType="submit"
              loading={isLoading}
              style={{
                // backgroundColor: "#1BC5BD",
                // borderColor: "#1BC5BD",
                marginRight: 15,
              }}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
