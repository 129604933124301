// Import React's Component
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Import Ant Design Components
import { Divider, Radio, Table, Space, Button, Col, Row } from "antd";

// Import React Icons
import {
  FormOutlined,
  DeleteFilled,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
  FileExcelFilled,
  PrinterOutlined,
} from "@ant-design/icons/lib/icons";

// Import Components
import ModalReportOutlet from "../../../Modals/Outlet/Report/Modal";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";

const dataSource = [
  {
    key: "1",
    date: "12/12/2023",
    id: "RES/1234/1234/1234",
    report_name: "Sold Total Item by Period Report",
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

// const onChange = (pagination, filters, sorter, extra) => {
//     console.log('params', pagination, filters, sorter, extra);
// };

// CODE
export default function ReportOutletTable({ reportData }) {
  // COLUMN
  const columns = [
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
      hidden: false,
      sorter: (a, b) => a.order_id - b.order_id,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      hidden: false,
      sorter: (a, b) => a.date - b.date,
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Sales Type",
      dataIndex: "sales_type",
      key: "sales_type",
      hidden: false,
      render: (text, record) => {
        return `${text} (${record.table_code})`
      }
    },
    {
      title: "Name",
      dataIndex: "customer_name",
      key: "customer_name",
      hidden: false,
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
      // width: 150,
      hidden: false,
    },
    {
      title: "Payment Details",
      dataIndex: "payment_details",
      key: "payment_details",
      // width: 150,
      hidden: false,
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      hidden: false,
    },
    {
      title: `User`,
      dataIndex: "user_name",
      key: "user_name",
      // align: "right",
      hidden: false,
    },
    {
      title: `Location`,
      dataIndex: "location",
      key: "location",
      // align: "right",
      hidden: false,
    },
    {
      title: `Price`,
      dataIndex: "price",
      key: "price",
      align: "right",
      hidden: false,
      render: (text) => {
        return `${text}`.replace( /\B(?=(\d{3})+(?!\d))/g, "," ) 
      },
    },
  ].filter((x) => !x.hidden);

  // DATA MANAGEMENT
  const [data, setData] = useState([]);
  const [bulks, setBulks] = useState([]);

  // STATE MANAGEMENT
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if(reportData.length > 0 && !cleanUp){
      setData(reportData);
      setBulks(reportData);
    } else {
      setData([]);
      setBulks([]);
    }

    return () => {
      cleanUp = true;
    }
  },[reportData]);

  // Modal Open Set State
  const handleOpen = () => {
    setOpen(true);
  };
  

  return (
    <>
      <Row className="lostfound-main-table" justify="center" style={mainBody}>
        <Col span={24} style={{ padding: "30px" }}>
        <Row className="row-table-btn" gutter={[0, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xxl={24}
              className="print-col"
            >
              <Row
                justify="end"
                align="top"
                gutter={[0, 15]}
                className="btn-row"
              >
                <Button
                  className="export-btn"
                  type="primary"
                  // onClick={handleExport}
                  icon={<FileExcelFilled />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Download Excel`}
                </Button>

                <Button
                  className="export-btn"
                  type="primary"
                  // onClick={handlePrint}
                  icon={<PrinterOutlined />}
                  style={{
                    marginRight: 15,
                  }}
                >
                  {`Print`}
                </Button>

                <Button
                  className="refresh-btn"
                  type="default"
                  icon={<SyncOutlined />}
                  // onClick={fetchData}
                >
                  {`Refresh`}
                </Button>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 30, width: "100%" }}>
            <Table
              loading={loading}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "odd" : "even";
              }}
              columns={columns}
              dataSource={data}
              pagination={{
                pageSize: 5,
              }}
              rowKey={(record) => (record.order_id)}
              summary={() => {
                const totalPrice = data.reduce((acc, order) => acc + order.price, 0);

                return (
                  <>
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={3} colSpan={9}>
                          <b style={{ fontSize: 14 }}>Total</b>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell index={8} align="right">
                          <b style={{ fontSize: 14 }}>
                          {`${totalPrice}`.replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </b>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  </>
                );
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
}
