// Import React Components
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";
import { Context as MasterContext } from "../../../API/Context/MasterContext/MasterContext";

// BASEAPI
import { baseurl } from "../../../API/Config/Api";

// Import Ant Design Components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  Image,
  Space,
  Checkbox,
  Table,
  Tag,
} from "antd";
import { Content } from "antd/lib/layout/layout";

// Import React Icons
import { BiSolidFilePdf } from "react-icons/bi";
import {
  FormOutlined,
  PlusCircleTwoTone,
  PlusOutlined,
  PrinterOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// Import Functions
import { LocalizationDate } from "../../Reusable/Functions/Localizations/Localization";
import { PrintCount } from "../../Reusable/Functions/PrintCount/PrintCount";
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";

// Import Page Components
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import GroupingArticle from "../../Reusable/Functions/GroupingArticle/GroupingArticle";
import MasterIdCardType from "../../Reusable/Master/IdCardType/IdCardType";
import MasterTitle from "../../Reusable/Master/Title/Title";

// Import Notification
import { failedFetch } from "../../Reusable/Notification/Notification";

// Import Global Styles
import { textForm } from "../../../Style/Global/Global";
import { token } from "../../../API/Global/Payload";
import GroupingBill from "../../Reusable/Functions/GroupingArticle/GroupingBill";

// MODAL CONFIRM
const { confirm } = Modal;

// CODE
export default function MasterBill(props) {
  // PROPS
  const {
    // Modal State
    openModal,
    closeModal,
    // Data
    dataGuest,
    new_payment,
    is_history,
  } = props;

  // PARAMS
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;
  const accounting = AccountingParams();

  // CONTEXT
  const { getMasterBillTrx, getBillHeader, getMasterDetail } =
    useContext(MainContext);
  const { getMasterPrint } = useContext(MasterContext);

  // Use Ref
  const componentRef = useRef();

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState(null);
  const [invoice, setInvoice] = useState({
    invoice: [],
    bill: [],
  });
  // Bill
  const [dataBill, setDataBill] = useState([]);
  const [guestList, setGuestList] = useState([]);
  const [headerBill, setHeaderBill] = useState(null);
  const [headers, setHeaders] = useState(null);
  // Total
  const [taxService, setTaxService] = useState({
    tax: 0,
    service: 0,
    subTotal: 0,
    total: 0,
  });
  // BOOLEAN
  const [open, setOpen] = useState(false);
  // Loading
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaid, setIsPaid] = useState(true);
  // Print Count
  const [countPrint, setCountPrint] = useState(null);

  // USE FORM
  const [form] = Form.useForm();

  // Fetch Data Bill Headers & Details
  const fetchData = async (value) => {
    const _data = value;

    // await getBillHeader({
    //   bill: "header-reservation",
    //   res_id: _data?.reservation_id
    //     ? _data.reservation_id
    //     : data?.reservation_id,
    //   price_id: null,
    //   onAwait: () => {},
    //   onSuccess: (response) => {
    //     console.log("Bill Header Data => ", response);

    //     if (response.data?.msg?.length > 0) {
    //       const _res = response.data.msg;

    //       handleHeader(_res);
    //     } else {
    //       setHeaders(null);
    //       setHeaderBill(null);
    //       setLoading(false);
    //     }
    //   },
    //   onReject: (error) => {
    //     console.log(error);
    //     failedFetch(error);
    //   },
    // });

    await getMasterDetail({
      bill: "detail-master-reservation",
      res_id: _data?.reservation_id
        ? _data.reservation_id
        : data?.reservation_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Bill Detail Data => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response?.data?.msg;

          handleFilter(_response);
        } else {
          setLoading(false);
          setDataBill([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
      },
    });
  };

  // FETCH GUEST
  const fetchGuestList = async (value) => {
    const _data = value?.reservation_id ? value : data;

    await axios
      .post(
        `${baseurl}/guest/checkout-history-by-reservation-id`,
        {
          reservation_id: [_data?.reservation_id],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (response) => {
        console.log("Guest List History: ", response);

        if (response.data?.msg?.length > 0) {
          const _res = response.data.msg;

          const _filter = _res.filter((items) => {
            return !items.is_deleted && items.is_deleted != null;
          });

          setGuestList(_filter);
          handleReceiver(_filter);
        } else {
          setGuestList([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        failedFetch(error);
      });
  };

  // FETCH PRINT FORM
  const fetchPrint = async (value) => {
    let _data = value;

    await getMasterPrint({
      reservation_id: _data?.reservation_id || data?.reservation_id,
      price_id: _data?.price_id > 0 ? _data.price_id : data?.price_id,
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Count Print: ", response);

        if (response?.data?.msg?.length > 0) {
          setCountPrint(response.data.msg[0]);
        } else {
          PrintCount({
            data: dataGuest?.length > 0 ? dataGuest[0] : dataGuest,
            is_update: false,
          });
        }
      },
      onReject: (error) => {
        console.log("Failed get Print Count: ", error);
      },
    });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (openModal == true && !cleanUp) {
      setOpen(true);
      console.log("Data Guest Master Bills: ", dataGuest);

      setData(dataGuest);
      fetchData(dataGuest);
      fetchGuestList(dataGuest);
      fetchPrint(dataGuest);
    }

    return () => {
      cleanUp = true;
    };
  }, [openModal, dataGuest]);

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "item_quantity",
      align: "center",
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      render: (price, record) => {
        return `${price > 0 ? price : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      render: (total, record) => {
        return `${total > 0 ? total : 0}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  // Table's Column
  const columnGuest = [
    {
      title: "Res. ID",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      render: (guest, record) => {
        return record.guest_category == "INCOGNITO" ? (
          <Tag color="default">{`PRIVATE`}</Tag>
        ) : record.guest_category == "RED ALERT" ? (
          <Tag color="magenta">{guest}</Tag>
        ) : (
          guest
        );
      },
    },
    {
      title: "Room Category",
      dataIndex: "room_category",
      key: "room_category",
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "BF",
      dataIndex: "is_abf",
      key: "is_abf",
      align: "center",
      width: 50,
      render: (abf) => {
        return (
          <Tag key={"abf-keytag"} color={abf === true ? `green` : `geekblue`}>
            {abf === true ? `RB` : `RO`}
          </Tag>
        );
      },
    },
    {
      title: "Night",
      dataIndex: "night",
      key: "night",
      width: 50,
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (arrive, record) => {
        return moment(arrive).format("DD-MM-YYYY");
      },
    },
    {
      title: "Depart",
      dataIndex: "departure",
      key: "departure",
      render: (arrive, record) => {
        return moment(arrive).format("DD-MM-YYYY");
      },
    },
  ];

  // HANDLE FILTER
  const handleFilter = (value) => {
    const _res = value;
    checkPaymentStatus(_res);

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _res,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    const _article = BillArticleShow({
      article: _billing,
      bill_type: bill_type,
    });

    // Grouping Data
    const _grouping = GroupingArticle(_article.article);

    console.log("Billing: ", _billing);
    console.log("Article: ", _article);
    console.log("Group By: ", _grouping);
    // console.log("Merge All: ", _all);

    setDataBill(_grouping);
    setTaxService({
      service: _article.total.service,
      tax: _article.total.tax,
      total: _article.total.total_price,
      subTotal: _article.total.unit_price,
    });

    setLoading(false);

    setTimeout(() => {
      setIsLoading(false);
    }, 1250);
  };

  // Check Payment
  const checkPaymentStatus = (val) => {
    const _array = val;

    const _pay = _array.map((items) => {
      if (!items.is_paid) setIsPaid(false);

      return items.is_paid;
    });

    const _invoice = _array.reduce(
      (acc, init) => {
        if (!acc[init?.invoice_id] && init.invoice_id) {
          acc[init.invoice_id] = true;

          acc.invoice.push(init.invoice_id);
        }

        if (!acc[init?.bill_master] && init.bill_master) {
          acc[init.bill_master] = true;

          acc.bill.push(init.bill_master);
        }

        return acc;
      },

      { invoice: [], bill: [] }
    );

    console.log("Payment Status: ", _pay);
    console.log("Invoice & Bill's: ", _invoice);

    setInvoice({
      invoice: _invoice.invoice,
      bill: _invoice.bill,
    });
  };

  // Handle Find Bill Receiver
  const handleReceiver = (val) => {
    const _arr = val;
    let _obj = {};

    const _receiver = _arr.filter((items) => {
      if (items.group == 1) return;
      _obj = {
        receiver:
          items.guest_name +
          " from " +
          items.room_category +
          " - " +
          items.room_number,
      };
    });

    console.log("Receiver: ", _receiver, _obj);

    setHeaders(_obj.receiver);
  };

  // ON FINISH
  const onFinish = () => {
    handlePrint();
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    form.resetFields();

    setData([]);
    setDataBill([]);
    setHeaders(null);
    setHeaderBill(null);

    setTaxService({
      gross: 0,
      nett: 0,
      service: 0,
      tax: 0,
      is_set: false,
    });

    setLoading(true);
    setIsLoading(true);

    setOpen(false);
    closeModal(false);
  };

  // HANDLE REACT PRINT
  const handlePrint = () => {
    PrintCount({
      data: data,
      is_update: true,
      counter_list: {
        master_bill: countPrint?.master_bill + 1,
        bill: countPrint?.bill,
        pre_master_bill: countPrint?.pre_master_bill,
        pre_bill: countPrint?.pre_bill,
        checkout_letter_history: countPrint?.checkout_letter_history,
        registration_form: countPrint?.registration_form,
        guarantee_letter: countPrint?.guarantee_letter,
        thankyou_letter: countPrint?.thankyou_letter,
        reservation_letter: countPrint?.reservation_letter,
      },
    });

    handlePrintOut();
  };

  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Master Receipt Bill ${
      data?.reservation_id ? data.reservation_id : " ---- "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
  });

  // CONSOLE LOG
  // console.log("DATA >>>", dataBill);

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Master Transaction`}
              </Typography>
            </Row>
          </>
        }
        // getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          className="master-bill-modal"
          name="master-bill-modal"
          key="master-bill-modal"
          form={form}
          labelCol={{
            span: 8,
          }}
          labelWrap={{
            span: 12,
          }}
          wrapperCol={{
            span: 16,
          }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          autoComplete="off"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{ padding: 30 }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Master Transaction List`}
                  </Typography.Title>

                  {`(Group Reservations)`}
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    // src="/looklogo(color).png"
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "25%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-receipt-no"
                style={{
                  height: 50,
                  width: "100%",
                  margin: 0,
                }}
              >
                <Col span={12} className="col-receipt">
                  <Form.Item
                    label="Bill(s)"
                    className="bill_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-trx-date" style={textForm}>
                      {invoice.bill.length > 0 ? invoice.bill.join(", ") : ""}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Invoice(s)"
                    className="receipt_number"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                  >
                    <Typography className="text-bill-id" style={textForm}>
                      {invoice.invoice.length > 0
                        ? invoice.invoice.join(", ")
                        : ""}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col
                  span={12}
                  className="col-paid"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {!isPaid ? (
                    <Image
                      className="paid"
                      src="/assets/logos/unpaid.jpg"
                      preview={false}
                      style={{
                        margin: 0,
                        width: 150,
                        height: 75,
                      }}
                    />
                  ) : (
                    <Image
                      className="paid"
                      src="/assets/logos/paid.jpg"
                      preview={false}
                      style={{
                        margin: 0,
                        width: 150,
                        height: 75,
                      }}
                    />
                  )}
                </Col>
              </Row>

              <Row className="row-payment-title" justify="start">
                <Col span={12}>
                  <Typography
                    className="text-payment"
                    style={{
                      margin: "30px 0px 0px",
                      fontSize: 14,
                      fontWeight: 750,
                      // color: "grey",
                    }}
                  >
                    {`Payment Data`}
                  </Typography>
                </Col>

                <Col span={12}>
                  <Typography
                    className="text-payment"
                    style={{
                      margin: "30px 0px 0px",
                      fontSize: 14,
                      fontWeight: 750,
                      // color: "grey",
                    }}
                  >
                    {`Guest Name`}
                  </Typography>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                  backgroundImage:
                    countPrint?.master_bill >= 1
                      ? "url(/assets/logos/copyopac.png)"
                      : null,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto",
                  backgroundPosition: "center",
                }}
              >
                <Col span={12}>
                  <Form.Item
                    label="Payment Type"
                    className="payment_type"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-payment-type" style={textForm}>
                      {`${
                        new_payment?.payment_type
                          ? new_payment.payment_type.toUpperCase()
                          : data?.payment_type
                      } - by ${
                        new_payment?.payment_details
                          ? new_payment.payment_details.toUpperCase()
                          : data?.payment_details
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Payment Status"
                    className="payment_status"
                    style={{ margin: 0 }}
                  >
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {`${
                        data?.payment_status == 3
                          ? "CASH BASIS"
                          : data?.payment_status == 2
                          ? "CHARGE TO ROOM"
                          : ""
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Group Leader"
                    className="bill_receiver"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-receiver" style={textForm}>
                      {`${headers ? headers : ""}`}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12} className="col-pay-type">
                  <Form.Item
                    label="Res. No"
                    className="reservation_id"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {`${data?.reservation_id ? data.reservation_id : ""}`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Guest Name"
                    className="guest_name"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {`${data?.guest_name ? data.guest_name : ""}`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Arrival"
                    className="arrival"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {`${
                        data?.arrival
                          ? moment(data.arrival).format("DD-MM-YYYY")
                          : ""
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Departure"
                    className="departure"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {`${
                        data?.departure
                          ? moment(data.departure).format("DD-MM-YYYY")
                          : ""
                      }`}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Night(s)"
                    className="night"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {`${data?.night ? data.night : ""}`}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Typography
                  style={{
                    margin: 0,
                    fontSize: 14,
                    fontWeight: 750,
                  }}
                >
                  {`Transaction Details`}
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="masterbill-table"
                  key="masterbill-table"
                  name="masterbill-table"
                  loading={loading}
                  columns={columns}
                  dataSource={dataBill}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) =>
                    record?.id > 0 ? record.id : record?.article_id
                  }
                  summary={() => {
                    return (
                      <>
                        <Table.Summary fixed>
                          {bill_type ? (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3} />

                                <Table.Summary.Cell index={3}>
                                  <b>Subtotal</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {`${currency} ${taxService.subTotal}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3} />

                                <Table.Summary.Cell index={3}>
                                  <b>Service</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {`${currency} ${taxService.service}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={3} />

                                <Table.Summary.Cell index={3}>
                                  <b>Tax</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={4} align="right">
                                  {`${currency} ${taxService.tax}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          ) : null}

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={3} />

                            <Table.Summary.Cell index={3}>
                              <b style={{ fontSize: 14 }}>Grand Total</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={4} align="right">
                              <b style={{ fontSize: 14 }}>
                                {`${currency} ${taxService.total}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </b>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      </>
                    );
                  }}
                />
              </Row>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: please, keep this invoice as your payment's warranty!
              </Typography>

              <Row
                className="signature"
                justify="end"
                style={{ padding: "30px 0px 0px" }}
              >
                <Col span={12} />

                <Col
                  span={12}
                  style={{
                    textAlign: "center",
                  }}
                >
                  {`Guest Signature`}

                  <div
                    className="box-sign"
                    style={{
                      border: "1px solid #000000",
                      borderRadius: 5,
                      height: 60,
                    }}
                  />

                  {`Sign at ${
                    data?.checkout_date
                      ? moment(LocalizationDate(data.checkout_date)).format(
                          "HH:mm, DD-MM-YYYY"
                        )
                      : moment(
                          LocalizationDate(headerBill?.created_date)
                        ).format("HH:mm, DD-MM-YYYY")
                  }`}
                </Col>
              </Row>

              <div className="table-break" />

              <Row justify="start">
                <Typography
                  style={{
                    margin: "30px 0px 0px",
                    fontSize: 14,
                    fontWeight: 750,
                  }}
                >
                  {`GUEST LIST`}
                </Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, background: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="guest-table"
                  key="guest-table"
                  name="guest-table"
                  loading={loading}
                  columns={columnGuest}
                  dataSource={guestList}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) => (record?.id > 0 ? record.id : record.key)}
                />
              </Row>
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <>
            <Row
              justify="end"
              align="middle"
              className="row-form-btn"
              style={{ padding: "0px 30px" }}
            >
              <Divider
                className="divider-form"
                style={{ margin: "15px 0px" }}
              />

              <Button
                className="print-btn"
                type="primary"
                htmlType="submit"
                icon={<PrinterOutlined />}
                style={{
                  marginRight: 15,
                }}
              >
                {`Print & Download`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                disabled={isLoading}
                onClick={() => {
                  fetchData();
                  setIsLoading(true);
                  fetchGuestList();
                }}
                icon={<SyncOutlined />}
                style={{
                  margin: "0px 15px 0px 0px",
                }}
              >
                {`Refresh`}
              </Button>

              <Button
                className="cancel-btn"
                type="default"
                onClick={handleCancel}
              >
                {`Close`}
              </Button>
            </Row>
          </>
        </Form>
      </Modal>
    </>
  );
}
