// Import Ant Design Notification Components
import { Col, Row, Space, notification } from "antd";

import {
  CheckOutlined,
  CloseOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { IoClose } from "react-icons/io5";
import { BsCheckLg } from "react-icons/bs";

// Limits
let isAlreadyShown = 0;

// UNAUTHORIZED TXT
const _relogin =
  "Your Token is Expired! Please, Re-Login to acquire a new one!";
const _unauthorized = "Unauthorized! Access Denied!";
const _general = "Please, Check Your Internet Connection or Try Again Later!";

// NOTIFICATION COMPONENTS
// GENERAL FAILED FETCH DATA
const failedFetch = (error) => {
  console.log("Notif --> ", error);

  let err_code = error?.response?.status;
  let desc = "";

  switch (err_code) {
    case 401:
      desc = "Please, Login Again to Acquire The New Session Access!";
      isAlreadyShown = isAlreadyShown + 1;
      break;

    case 403:
      desc = "You don't have permission to access this resource!";
      isAlreadyShown = isAlreadyShown + 1;
      break;

    default:
      desc = "Please, Check Your Internet Connection or Try Again Later!";
      isAlreadyShown = isAlreadyShown + 1;
      break;
  }

  if (isAlreadyShown == 1) {
    console.log("So Many Failed Fetch Notifications --> ", isAlreadyShown);

    notification.open({
      className: "notif-failed-fetch",
      message: null,
      description: (
        <>
          <Row align="middle" className="login-notif" style={{ width: "100%" }}>
            <Col span={6}>
              <WarningOutlined
                className="icon-warning"
                style={{
                  color: "#FFFFFF",
                  fontSize: 50,
                }}
              />
            </Col>

            <Col span={18}>
              <Row
                className="login-notif"
                style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
              >
                {`Failed To Get Data!`}
              </Row>

              <Space
                className="fetch-failed-notif"
                direction="vertical"
                wrap={true}
                size={2}
                style={{ width: "100%", fontSize: 14 }}
              >
                {`${
                  error?.response?.data?.cc
                    ? error.response.data.cc.toUpperCase()
                    : `Request Failed`
                }!`}
                {desc}
              </Space>
            </Col>
          </Row>
        </>
      ),
      closeIcon: (
        <IoClose
          className="close-icon"
          style={{ color: "#FFF", fontSize: 20 }}
        />
      ),
      placement: "top",
      duration: 30,
      style: {
        borderRadius: 5,
        minHeight: 125,
        // height: 75,
        padding: "0px 20px 10px",
        background: `linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)`,
        // color: "#5c0011",
        color: "#FFFFFF",
        // width: "100%",
      },
      maxCount: 1,
    });
  }
  setTimeout(() => {
    isAlreadyShown = 0;
  }, 2000);
};

// NOTIF LOGIN
// Notif Login Failed
const failedLogin = (error, { method }) => {
  // console.log("Failed Login --> ", error);
  const type = method;

  notification.open({
    className: "notif-failed-login",
    message: null,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <IoClose className="success-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={18}>
            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {type == 0 ? `Failed to Login!` : `Failed to Sign Out`}
            </Row>

            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 14 }}
            >
              {type == 0
                ? `Please, Check Your Account's Email or Password`
                : `Please, Check Your Internet Connections!`}
            </Row>

            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Message: ${
                error?.detail ? error.detail : error.cc || "ERROR AUTH!"
              }`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose className="close-icon" style={{ color: "#FFF", fontSize: 20 }} />
    ),
    placement: "top",
    duration: 30,
    style: {
      borderRadius: 5,
      minHeight: 125,
      // height: 75,
      padding: "0px 20px 5px",
      background: "linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)",
      color: "#FFFFFF",
      // opacity: 0.6,
    },
  });
};

// Notif Login Failed
const successLogin = (response, { method }) => {
  const type = method;

  notification.open({
    className: "notif-success-login",
    message: null,
    // message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <BsCheckLg className="success-icon" style={{ fontSize: 35 }} />
          </Col>

          <Col span={18}>
            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Success!`}
            </Row>
            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 15 }}
            >
              {type == 0 ? `Signing In...` : `Signing Out...`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose className="close-icon" style={{ color: "#FFF", fontSize: 20 }} />
    ),
    placement: "top",
    duration: 30,
    style: {
      borderRadius: 5,
      height: 75,
      padding: "0px 20px",
      background:
        "var(--button-primary, linear-gradient(262deg, #00C4F2 2.77%, rgba(160, 0, 174, 0.40) 139.4%))",
      color: "#FFFFFF",
      // opacity: 0.6,
    },
  });
};

// NOTIF REGISTER
// Notif Register Failed
const failedRegister = (error, { method }) => {
  // console.log(error);
  // console.log(method);

  notification.open({
    className: "notif-failed-login",
    message: null,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <IoClose className="success-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={18}>
            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {method === 0 ? `Sign-Up Failed!` : `Update User's Failed`}
            </Row>

            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Please, Check Your Internet Connections!`}
            </Row>

            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Message: ${
                error?.detail ? error.detail : error || "Request Error!"
              } `}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose className="close-icon" style={{ color: "#FFF", fontSize: 20 }} />
    ),
    placement: "top",
    duration: 30,
    style: {
      borderRadius: 5,
      minHeight: 125,
      // height: 75,
      padding: "0px 20px 5px",
      background: "linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)",
      color: "#FFFFFF",
      // opacity: 0.6,
    },
  });
};

// Notif Register Failed
const successRegister = (response, { method }) => {
  const type = method > 0 ? method : 0;

  notification.open({
    className: "notif-success-login",
    message: null,
    // message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: (
      <>
        <Row align="middle" className="login-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <BsCheckLg className="success-icon" style={{ fontSize: 35 }} />
          </Col>

          <Col span={18}>
            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Success!`}
            </Row>
            <Row
              className="login-notif"
              style={{ width: "100%", fontSize: 15 }}
            >
              {type === 0
                ? `New User is Registered Successfully!`
                : `User's Data Updated Successfully!`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose className="close-icon" style={{ color: "#FFF", fontSize: 20 }} />
    ),
    placement: "top",
    duration: 30,
    style: {
      borderRadius: 5,
      height: 75,
      padding: "0px 20px",
      background:
        "var(--button-primary, linear-gradient(262deg, #00C4F2 2.77%, rgba(160, 0, 174, 0.40) 139.4%))",
      color: "#FFFFFF",
      // opacity: 0.6,
    },
  });
};

// NOTIF AUTH PERMISSIONS
// Notif Permit Failed
const failedPermit = (error) => {
  // const type = method;
  console.log("Failed Login --> ", error);

  notification.open({
    className: "notif-failed-permit",
    message: null,
    description: (
      <>
        <Row align="middle" className="permit-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="permit-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Request Failed!`}
            </Row>

            <Space
              className="fetch-failed-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Please, Check Your Account's Email or Password!`}
              {`Message: ${
                error?.response?.data?.detail
                  ? error.response.data.detail
                  : error || "REQUEST ERROR!"
              }`}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose className="close-icon" style={{ color: "#FFF", fontSize: 20 }} />
    ),
    placement: "top",
    duration: 30,
    style: {
      borderRadius: 5,
      minHeight: 125,
      // height: 75,
      padding: "0px 20px 10px",
      background: "linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)",
      color: "#FFFFFF",
    },
  });
};

// Notif Login Failed
const successPermit = (response) => {
  // const type = method;

  notification.open({
    className: "notif-success-permit",
    // message: <div style={{ color: "#ffffff" }}>{`SUCCESS!`}</div>,
    description: (
      <>
        <Row align="middle" className="permit-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <BsCheckLg className="success-icon" style={{ fontSize: 35 }} />
          </Col>

          <Col span={18}>
            <Row
              className="permit-notif"
              style={{ width: "100%", fontSize: 23, fontWeight: 500 }}
            >
              {`Success!`}
            </Row>
            <Row
              className="permit-notif"
              style={{ width: "100%", fontSize: 15 }}
            >
              {`Permissions Granted!`}
            </Row>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose className="close-icon" style={{ color: "#FFF", fontSize: 20 }} />
    ),
    placement: "top",
    duration: 30,
    style: {
      borderRadius: 5,
      height: 75,
      padding: "0px 20px",
      background:
        "var(--button-primary, linear-gradient(262deg, #00C4F2 2.77%, rgba(160, 0, 174, 0.40) 139.4%))",
      color: "#FFFFFF",
    },
  });
};

// NOTIF ADD NEW RESERVATION
// Notif Add Reservation Incomplete
const incomplete = (error) => {
  console.log("Incomplete: ", error);

  notification.open({
    className: "notif-incomplete-reservation",
    message: null, // <div style={{ color: "#FFFFFF" }}>Data Input Required!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="incomplete-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`Data Input Required!`}
            </Row>

            <Space
              className="incomplete-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {` Required Data are Empty!`}
              {error.errorFields[0]
                ? error.errorFields[0].errors[0]
                : "Please, Complete the Data!"}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "top",
    duration: 30,
    maxCount: 1,
    style: {
      borderRadius: 5,
      // minHeight: 100,
      padding: "10px 20px",
      background: "linear-gradient(255deg, #F85032 28.84%, #E73827 85.93%)",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Reservation Failed
const failedAddReserv = (error, { method }) => {
  const type = method;
  const auth = error?.response?.status;

  notification.error({
    className: "notif-failed-add-reservation",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Create Reservation!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Reservation!</div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Cancel Reservation!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Create Group Reservation!
        </div>
      ),
    description:
      auth === 401 ? _relogin : auth === 403 ? _unauthorized : _general,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Reservation Success
const successAddReserv = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-add-reservation",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "Reservation is Created Successfully!"
        : type == 1
        ? "Reservation is Updated Successfully!"
        : type == 2
        ? "Reservation is Cancelled Successfully!"
        : "Reservation Group is Created Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION INVENTORY ARTICLE
// Notif Inventory Article Failed
const failedArticlePR = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-art-inventory",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add Purchase Request!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Purchase Request!
        </div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Cancel Purchase Request!
        </div>
      ) : (
        // : type == 3 ? (
        //   <div style={{ color: "#FFFFFF" }}>
        //     Failed To Add Breakfast in Reservations!
        //   </div>
        // )
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add Other Purchase Request!
        </div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Inventory Article Success
const successArticlePR = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-art-stay",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "Purchase Request is Added Successfully!"
        : type == 1
        ? "Purchase Request is Updated Successfully!"
        : type == 2
        ? "Purchase Request is Cancelled Successfully!"
        : // : type == 3
          // ? "Breakfast in Reservations is Added Successfully!"
          "Other Purchase Request Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION STAY ARTICLE
// Notif Reservation Stay Article Failed
const failedArticleStay = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-art-stay",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add Article!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Article!</div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Cancel Article!</div>
      ) : type == 3 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add Breakfast in Reservations!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add Breakfast in Reservations Group!
        </div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Reservation Stay Success
const successArticleStay = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-art-stay",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "Article is Added Successfully!"
        : type == 1
        ? "Article is Updated Successfully!"
        : type == 2
        ? "Article is Cancelled Successfully!"
        : type == 3
        ? "Breakfast in Reservations is Added Successfully!"
        : "Breakfast in Group Reservations is Added Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// Notif Article Vendor Success
const successArticleVendor = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-art-vendor",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "Article is Added Successfully!"
        : type == 1
        ? "Article is Updated Successfully!"
        : type == 2
        ? "Article is Deleted Successfully!"
        : "Article is Canceled Successfully",

    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// Notif Article Vendor Failed
const failedArticleVendor = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-art-stay",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add Article!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Article!</div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Cancel Article!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete Article!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Article Is Exist
const ArticleIncomplete = (error, { method }) => {
  // console.log(error);

  notification.open({
    className: "notif-incomplete-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="incomplete-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`Data Incomplete!`}
            </Row>

            <Space
              className="incomplete-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {method == 1
                ? `Article ${
                    error.article_name_addon
                      ? error.article_name + " " + error.article_name_addon
                      : error.article_name
                  } is exist! Please, select another article!`
                : `Unsaved data cannot be edited!`}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "top",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#FFA800",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// Notif Approval Incomlete
const ApprovalIncomplete = () => {
  notification.open({
    className: "notif-incomplete-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="incomplete-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`Data Incomplete!`}
            </Row>

            <Space
              className="incomplete-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {"Please, Input Chart Of Account In Each Article"}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "top",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#FFA800",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// CHECK IN NOTIFICATION
// Notif Check In Failed
const failedCheckIn = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-check-in",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Checked-In Guest!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed to Assigned Room to a Guest!
        </div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Checked-In Guest!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed to Assigned Rooms to Group!
        </div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Check In Success
const successCheckIn = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-check-in",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "Guest is Checked-In Successfully!"
        : type == 1
        ? "Guest's Room is Assigned Successfully!"
        : type == 2
        ? "Group is Checked-In Successfully!"
        : "Guest's Rooms are Assigned Successfully for All the Group Member!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// CHECK OUT NOTIFICATION
// Notif Check Out Failed
const failedCheckOut = (error, { method }) => {
  const type = method;
  const msg = error.response;

  notification.error({
    className: "notif-failed-check-out",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Checked-Out Guest!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Early Check-Out Guest!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Create Bill for The Guest!
        </div>
      ),
    description: (
      <>
        {msg?.data?.detail
          ? msg.data.detail
          : "Please, Check Your Internet and Try Again Later!"}
      </>
    ),
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Check In Success
const successCheckOut = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-check-out",
    message: <div style={{ color: "#ffffff" }}>{`SUCCESS!`}</div>,
    description:
      type == 0
        ? "Guest is Checked-Out Successfully!"
        : type == 1
        ? "Guest's Status is Successfully Updated to Early Check-Out!"
        : `Guest's  is Created Successfully!`,
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// GUEST IN HOUSE
// CHANGE ROOM NOTIFICATION
// Notif Change Room Failed
const failedChangeRoom = (error) => {
  notification.error({
    className: "notif-failed-check-out",
    message: (
      <div style={{ color: "#FFFFFF" }}>Failed To Changed Guest's Room!</div>
    ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Change Room Success
const successChangeRoom = (response) => {
  notification.success({
    className: "notif-success-check-out",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: "Guest's Room is Changed Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// GUEST NOTIF
// Notif Add Guest Incomplete
const incompleteGuest = (error) => {
  console.log(error);

  notification.error({
    className: "notif-failed-add-reservation",
    message: <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        Warning!
        <br />
        Guest must be Over 17 Years old or have Personal Identification Number!
      </>
    ),
    icon: <WarningOutlined />,
    placement: "top",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Guest Failed
const failedGuest = (error, { method, main_type }) => {
  const type = method;
  const comp = main_type;
  let _text = "";

  let _err_msg = error?.response?.data?.detail
    ? error.response.data.detail
    : "Please, Try again later!";

  // console.log("ERROR ADD GUEST: ", type);
  switch (comp) {
    case 1:
      _text = "Guest";
      break;

    case 2:
      _text = "Company/Corporate";
      break;

    case 3:
      _text = "Travel-Agent";
      break;

    default:
      break;
  }

  notification.error({
    className: "notif-failed-add-guest",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Create New {_text}!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Data {_text}!</div>
      ) : type == 2 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          The ID Card Number is have been registered!
        </div>
      ),
    description:
      type == 3 ? (
        <>Please, check the Data Again or Try to Find the Guest in the List!</>
      ) : (
        _err_msg
      ),
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Guest Failed
const successGuest = (response, { method, main_type }) => {
  const type = method;
  const comp = main_type;
  let _text = "";
  // console.log("Response API: ", response);

  console.log("Type: ", method);

  switch (comp) {
    case 1:
      _text = "Guest";
      break;

    case 2:
      _text = "Company/Corporate";
      break;

    case 3:
      _text = "Travel-Agent";
      break;

    default:
      break;
  }

  notification.success({
    className: "notif-success-add-guest",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? `A New ${_text} is Created Successfully!`
        : type == 1
        ? `Data ${_text} is Updated Successfully!`
        : `Data  ${_text} is Deleted Successfully!`,
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION NON-STAY
// Notif Reservation Non-Stay Failed
const failedNonStay = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-resv-nonstay",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Move Requested Data to Non-Stay Table!
        </div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Remove Requested Data from Non-Stay Table!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Requesting Non-Stay!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Reservation Non-Stay Success
const successNonStay = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-resv-nonstay",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "The Requested Data is Moved to The Non-Stay Table Successfully!"
        : type == 1
        ? "The Requested Data from Non-Stay Table is Removed Successfully!"
        : "Requesting Non-Stay is Created Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION LATE CHECKOUT
// Notif Late Check-Out Failed
const failedLateCO = (error) => {
  // const type = method;
  console.log(error);

  notification.open({
    className: "notif-failed-late-co",
    message: null,
    // (
    //   <div style={{ color: "#FFFFFF", fontSize: 14 }}>
    //     Failed To Set Late Check-Out for The Guest!
    //   </div>
    // ),
    description: (
      <>
        <Row align="middle" className="error-notif" style={{ width: "100%" }}>
          <Col span={6}>
            <IoClose className="error-icon" style={{ fontSize: 50 }} />
          </Col>

          <Col span={18}>
            <Row
              className="fail-notif"
              style={{ width: "100%", fontSize: 14, fontWeight: 500 }}
            >
              {`Failed To Set Late Check-Out for The Guest!`}
            </Row>

            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 12 }}
            >
              {`Status: ${error?.response?.status} - ${error?.response?.statusText}`}
            </Row>

            <Row className="fail-notif" style={{ width: "100%", fontSize: 12 }}>
              {error?.response?.data?.detail || _general}
            </Row>
          </Col>
        </Row>
      </>
    ),
    // error?.response?.data?.detail || _general,
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Late Check-Out Failed
const successLateCO = (response) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  // const type = method;

  notification.success({
    className: "notif-success-late-co",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: "Guest is Set to Late Check-Out Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// HOUSE KEEPING
// NOTIFICATION ROOM STATUS
// Notif Change Room Status Failed
const failedRoomStatus = (error) => {
  notification.error({
    className: "notif-failed-room-status",
    message: (
      <div style={{ color: "#FFFFFF" }}>Failed To Change Room Status!</div>
    ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Change Room Status Success
const successRoomStatus = (response) => {
  // console.log("Response API: ", response);
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-room-status",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: "Room Status is Updated Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION LOST & FOUND
// Notif Add Lost & Found Failed
const failedLostFound = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-room-status",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add Lost & Found Data!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update Lost & Found Data!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Delete Lost & Found Data!
        </div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Lost & Found Success
const successLostFound = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-room-status",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Lost & Found Data is Created Successfully!"
        : type == 1
        ? "Lost & Found Data is Updated Successfully!"
        : "Lost & Found Data is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION ACOUNTING MAIN ACCOUNT (Master COA)
// Notif Main Account Failed
const failedMainAccount = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-main-account",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add COA!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update COA!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete COA!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Main Account Success
const successMainAccount = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-art-nonstay",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "COA is Added Successfully!"
        : type == 1
        ? "COA is Updated Successfully!"
        : "COA is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// Bill Creations
// Notif Main Account Failed
const failedBill = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-main-account",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Create Bills!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Bills!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete Bills!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Main Account Success
const successBill = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-art-nonstay",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "Bill is Created Successfully!"
        : type == 1
        ? "Bill is Updated Successfully!"
        : "Bill is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION INITIAL ACCOUNTING
// NOTIFICATION INITIAL BALANCE
// Notif Initial Balance Failed
const failedInitialBalance = (error) => {
  // const type = method;

  notification.error({
    className: "notif-failed-main-account",
    message: (
      <div style={{ color: "#FFFFFF" }}>Failed To Update Initial Balance!</div>
    ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Initial Balance Success
const successInitialBalance = (response) => {
  // const type = method;

  notification.success({
    className: "notif-success-art-nonstay",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: "Initial Balance is Updated Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION INITIAL DEBTS & RECEIVABLES
// Notif Initial Balance Failed
const failedInitialAccounting = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-initial-account",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add A New Initial Debts!
        </div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add A New Initial Receivables!
        </div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Initial Balance Success
const successInitialAccounting = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-initial-account",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 1
        ? "A New Initial Debt is Created Successfully!"
        : "A New Initial Receivable is Created Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// NOTIFICATION CREATE JOURNAL
// Failed Add Journal
const failedCreateJournal = (error) => {
  notification.open({
    className: "notif-failed-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="failed-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`Failed to Create Journals!`}
            </Row>

            <Space
              className="failed-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Please, Check Your Internet and Try Again Later!`}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// Success Add Journal
const successCreateJournal = (response) => {
  notification.open({
    className: "notif-success-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <CheckOutlined
              className="icon-success"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`SUCCESS!`}
            </Row>

            <Space
              className="success-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Journal is Created Successfully!`}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// NOTIFICATION LEDGERS
// Failed Ledgers
const failedLedgers = (error) => {
  notification.open({
    className: "notif-failed-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="failed-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`Failed to Create Journals!`}
            </Row>

            <Space
              className="failed-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Please, Check Your Internet and Try Again Later!`}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// Success Add Ledgers
const successLedgers = (response) => {
  notification.open({
    className: "notif-success-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <CheckOutlined
              className="icon-success"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`SUCCESS!`}
            </Row>

            <Space
              className="success-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {`Journal is Created Successfully!`}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// NOTIFICATION CLOSE PERIOD
// Failed Closing
const failedClosing = (error) => {
  console.log("Error: ", error);

  notification.open({
    className: "notif-failed-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="failed-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <WarningOutlined
              className="icon-warning"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="failed-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`Failed to Closing!`}
            </Row>

            <Space
              className="failed-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {error?.response?.status === 409
                ? `You have already create a Closing Request!`
                : `Please, Check Your Internet and Try Again Later!`}
            </Space>
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// Success Add Journal
const successClosing = (response) => {
  notification.open({
    className: "notif-success-forms",
    message: null, // <div style={{ color: "#FFFFFF" }}>Required Data!</div>,
    description: (
      <>
        <Row
          align="middle"
          justify="space-around"
          className="permit-notif"
          style={{
            width: "100%",
            // padding: 10,
          }}
        >
          <Col span={6}>
            <CheckOutlined
              className="icon-success"
              style={{
                color: "#FFFFFF",
                fontSize: 50,
              }}
            />
          </Col>

          <Col span={18}>
            <Row
              className="success-notif"
              style={{ width: "100%", fontSize: 16, fontWeight: 500 }}
            >
              {`CLOSING SUCCESS!`}
            </Row>

            {/* <Space
              className="success-notif"
              direction="vertical"
              wrap={true}
              size={2}
              style={{ width: "100%", fontSize: 14 }}
            >
              {`!`}
            </Space> */}
          </Col>
        </Row>
      </>
    ),
    closeIcon: (
      <IoClose
        className="close-notif-icon"
        style={{ color: "#FFF", fontSize: 20 }}
      />
    ),
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
      borderRadius: 5,
      paddingTop: 0,
    },
  });
};

// NOTIFICATION MICE ARTICLE
// Notif Add MICE Article Failed
const failedArticleMice = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-art-mice",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add MICE Article!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update MICE Article!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Cancel MICE Article!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add MICE Article Success
const successArticleMice = (response, { method }) => {
  const type = method;

  notification.success({
    className: "notif-success-art-mice",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "MICE's Article is Added Successfully!"
        : type == 1
        ? "MICE's Article is Updated Successfully!"
        : "MICE's Article is Cancelled Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// BACK OFFICE
// NOTIFICATION BACK OFFICE
// Notif Budget
const failedBudget = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-room-status",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add Budget Data!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update Budget Data!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete Budget Data!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add Lost & Found Success
const successAddBudget = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-room-status",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Budget Data is Created Successfully!"
        : type == 1
        ? "Budget Data is Updated Successfully!"
        : "Budget Data is Deleted Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// Notif PO
const failedPO = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-po",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Save PO Data!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add to Draft PO Data!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete PO Data!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add PO Success
const successPO = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-po",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New PO Data is Saved Successfully!"
        : type == 1
        ? "PO Data is Saved as Draft Successfully!"
        : "PO Data is Canceled Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// Notif Stock Incoming (Receiving)
const failedSI = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-po",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Save Doc Data!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Add to Draft Doc Data!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Delete Doc Data!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif Add SI Success
const successSI = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-po",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A New Doc Data is Saved Successfully!"
        : type == 1
        ? "Doc Data is Saved as Draft Successfully!"
        : "Doc Data is Canceled Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

// Notif POS Order Success
const successPOSOrder = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-pos-order",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      type == 0
        ? "A POS Order is Added Successfully!"
        : type == 1
        ? "POS Order is Updated Successfully!"
        : "POS Order is Canceled Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

const failedPOSOrder = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-pos-order",
    message:
      type == 0 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Added POS Order!</div>
      ) : type == 1 ? (
        <div style={{ color: "#FFFFFF" }}>Failed To Update POS Order!</div>
      ) : (
        <div style={{ color: "#FFFFFF" }}>Failed To Cancel POS Order!</div>
      ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif POS Order Change Table Success
const successPOSChangeTable = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-pos-order",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description: "POS Order Change Table Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

const failedPOSChangeTable = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-pos-order",
    message: (
      <div style={{ color: "#FFFFFF" }}>Failed To POS Order Change Table!</div>
    ),

    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif POS Payment Normal Bill Split Bill, Join Bill, Charge To Room
const successPOSPayment = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-pos-order",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      {
        0: "POS Payment Split Bill is Paid Successfully!",
        1: "POS Payment Join Bill is Paid Successfully!",
        2: "POS Payment Charge To Room is Paid Successfully!",
      }[method] ?? "POS Payment is Paid Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

const failedPOSPayment = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-pos-order",
    message: {
      0: (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Process POS Payment Split Bill!
        </div>
      ),
      1: (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Process POS Payment Join Bill!
        </div>
      ),
      2: (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Process POS Payment Charge To Room!
        </div>
      ),
    }[method] ?? (
      <div style={{ color: "#FFFFFF" }}>Failed To Process POS Payment!</div>
    ),
    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// Notif POS Order Change Table Success
const successPOSReservation = (response, { method }) => {
  // console.log("Response API: ", response);
  const type = method;
  //   console.log("Type: ", method);

  notification.success({
    className: "notif-success-pos-order",
    message: <div style={{ color: "#ffffff" }}>SUCCESS!</div>,
    description:
      {
        0: "POS Reservation Table is Added Successfully!",
        1: "POS Reservation Table is Updated Successfully!",
        2: "POS Reservation Table is Canceled Successfully!",
      }[method] ?? "POS Reservation Table is Added Successfully!",
    icon: <CheckOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#52c41a",
      color: "#FFFFFF",
    },
  });
};

const failedPOSReservation = (error, { method }) => {
  const type = method;

  notification.error({
    className: "notif-failed-pos-order",
    message: {
      0: (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Add POS Reservation Table!
        </div>
      ),
      1: (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Update POS Reservation Table!
        </div>
      ),
      2: (
        <div style={{ color: "#FFFFFF" }}>
          Failed To Cancel POS Reservation Table!
        </div>
      ),
    }[method] ?? (
      <div style={{ color: "#FFFFFF" }}>
        Failed To Add POS Reservation Table!
      </div>
    ),

    description: <>Please, Check Your Internet and Try Again Later!</>,
    icon: <WarningOutlined />,
    placement: "bottomRight",
    duration: 30,
    style: {
      fontWeight: "bold",
      backgroundColor: "#ff4d4f",
      color: "#FFFFFF",
    },
  });
};

// return clearInterval(time);

export {
  // Fetch
  failedFetch,

  // Login
  failedLogin,
  successLogin,

  // Register
  failedRegister,
  successRegister,

  // Permission
  failedPermit,
  successPermit,

  // FRONT OFFICE
  // Reservation
  incomplete,
  failedAddReserv,
  successAddReserv,

  // Article
  failedArticleStay,
  successArticleStay,
  successArticleVendor,
  failedArticleVendor,
  ArticleIncomplete,

  // Article Inventory
  failedArticlePR,
  successArticlePR,

  // Check In
  failedCheckIn,
  successCheckIn,

  // Check Out
  failedCheckOut,
  successCheckOut,

  // Guest In House
  // Late Check-Out
  failedLateCO,
  successLateCO,

  // Change Room
  successChangeRoom,
  failedChangeRoom,

  // Guest
  incompleteGuest,
  failedGuest,
  successGuest,

  // Non-Stay
  failedNonStay,
  successNonStay,

  // HOUSE KEEPING
  // Room Status
  failedRoomStatus,
  successRoomStatus,

  // Lost & Found
  failedLostFound,
  successLostFound,

  // ACCOUNTING
  // Main Account
  failedMainAccount,
  successMainAccount,

  // Bill
  failedBill,
  successBill,

  // Start Up Accounting
  // Initial Balance
  failedInitialBalance,
  successInitialBalance,

  // Initial Accountnig
  failedInitialAccounting,
  successInitialAccounting,

  // Create Journal
  failedCreateJournal,
  successCreateJournal,

  // LEDGERS
  successLedgers,
  failedLedgers,

  // CLOSING
  failedClosing,
  successClosing,

  // MICE
  // MICE Article
  failedArticleMice,
  successArticleMice,

  // BACK OFFICE
  // BUDGET
  failedBudget,
  successAddBudget,
  // PURCHASING
  successPO,
  failedPO,
  // STOCK INCOMING (Receiving)
  successSI,
  failedSI,
  // APPROVAL
  ApprovalIncomplete,

  // POS
  successPOSOrder,
  failedPOSOrder,
  successPOSChangeTable,
  failedPOSChangeTable,
  successPOSPayment,
  failedPOSPayment,
  successPOSReservation,
  failedPOSReservation,
};
