// REACT COMPONENTS
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ANTD COMPONENTS
import { ExclamationCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Image,
  Modal,
  Row,
  Table,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import confirm from "antd/lib/modal/confirm";

// GLOBAL STYLES
import { textForm } from "../../../Style/Global/Global";

// REACT ICONS
import { BiSolidFilePdf } from "react-icons/bi";

// PARAMS
import { CurrencySymbols } from "../../Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../Reusable/Functions/Parameters/ParamsFO";

// PAGE COMPONENTS
import LoadingComponents from "../../Reusable/Spinner/LoadingScreen";
import { useReactToPrint } from "react-to-print";
import GroupingBill from "../../Reusable/Functions/GroupingArticle/GroupingBill";
import { BillArticleShow } from "../../Reusable/Functions/BillType/BillType";
import { AccountingParams } from "../../Reusable/Functions/Parameters/ParamsAcc";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../API/Config/Api";
import { token } from "../../../API/Global/Payload";

// NOTIFICATIONS
import {
  masterFailedNotification,
  masterSuccessNotification,
} from "../../Reusable/Notification/MasterNotif/Notification";
import { failedFetch } from "../../Reusable/Notification/Notification";

export default function MasterFolioForm(props) {
  // PROPS
  const {
    openModal,
    closeModal,
    billData,
    billReceiver,
    typeData,
    checkoutData,
    billSource,
    relatedBillData,
  } = props;

  // Use Ref
  const componentRef = useRef();

  // Params
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  // Data
  const [data, setData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [isFromCheckOut, setIsFromCheckOut] = useState(false);
  const [subTotal, setSubTotal] = useState({
    total: 0,
    tax: 0,
    service: 0,
    grand: 0,
  });
  // Modal
  const [open, setOpen] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  // navigate
  const navigate = useNavigate();
  // USE FORM
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("Checkout Data Form ->> ", checkoutData);
    console.log("Bill Receiver Form ->> ", billReceiver);
    console.log("Related Bill Data ->> ", relatedBillData);
  }, [billReceiver, checkoutData, relatedBillData]);

  // USEEFFECT CHECKOUT DATA
  useEffect(() => {
    let cleanUp = false;

    if (checkoutData?.departure?.length > 0 && !cleanUp) {
      setIsFromCheckOut(true);
      setStateData(checkoutData.departure);
    } else {
      setIsFromCheckOut(false);
      setStateData([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [checkoutData]);

  // USE EFFECT
  useEffect(() => {
    if (openModal == true) {
      setOpen(openModal);
    }

    if (billData) {
      // console.log("Bill Data Master Folio ->> ", billData);
      const resId = getReservationId(billData);
      const articleId = getArticleId(billData);
      const source = getSource(billData, billSource);
      fetchDetailData(resId, articleId, source);
    }

    setIsLoading(false);
  }, [openModal, billData, billSource]);

  // FETCH DETAIL DATA
  const fetchDetailData = async (resId, articleId, source) => {
    setLoading(true);

    await axios
      .post(`${baseurl}/bill/master-folio-res`, {
        reservation_id: resId,
        source: source,
      })
      .then((response) => {
        // console.log("response master folio res form: ", response);
        if (response?.data?.msg) {
          const _res = response.data.msg;
          const _data = removeUnselectData(articleId, _res);
          const _detail = handleDataDetail(_data);

          setData(_detail.article);
          setSubTotal({
            grand: _detail.total.total_price,
            service: _detail.total.service,
            tax: _detail.total.tax,
            total: _detail.total.unit_price,
          });
        } else {
          setData([]);
          setSubTotal({
            total: 0,
            tax: 0,
            service: 0,
            grand: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // GET RESERVATION ID FROM BILL
  const getReservationId = (billData) => {
    const uniqueReservationId = new Set();

    billData.forEach((item) => {
      if (item.reservation_id) {
        uniqueReservationId.add(item.reservation_id);
      }
    });

    return Array.from(uniqueReservationId);
  };

  // GET ARTICLE ID FROM BILL
  const getArticleId = (billData) => {
    const uniqueArticleId = new Set();

    billData.forEach((item) => {
      if (item.article_id) {
        uniqueArticleId.add(item.article_id);
      }
    });

    return Array.from(uniqueArticleId);
  };

  // GET SOURCE FROM BILL
  const getSource = (billData, billSource) => {
    const uniqueSource = new Set();
    uniqueSource.add(1); // source for default reservation

    // add bill source from selector state
    if (billSource !== -1 && billSource?.length > 0) {
      billSource.forEach((item) => {
        if (item) {
          uniqueSource.add(item);
        }
      });
    }

    // add bill source from key bill
    billData.forEach((item) => {
      if (item.source_code) {
        uniqueSource.add(item.source_code);
      }
    });

    return Array.from(uniqueSource);
  };

  // REMOVE UNSELECTED DATA
  const removeUnselectData = (articleId, billData) => {
    return billData.filter((item) => articleId.includes(item.article_id));
  };

  // Handle Data Details
  const handleDataDetail = (value) => {
    const _arr = value;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
    });

    // Grouping Data
    // const _grouping = G
    const _grouping = BillArticleShow({
      article: _billing,
      bill_type: bill_type,
    });

    // console.log("Billing: ", _billing);
    // console.log("Grouping Res: ", _grouping);

    return _grouping;
  };

  // HANDLE SPLIT DATA FOR BODY REQ
  const splitData = (billData) => {
    let reservation_id = [];
    let article_id = [];

    billData.forEach((item) => {
      reservation_id.push(item.reservation_id);
      article_id.push(item.article_id);
    });

    return {
      reservation_id,
      article_id,
    };
  };

  // HANDLE SUBMIT
  const handleSubmit = () => {
    confirm({
      title: `Do you want to Submit Bill Data?`,
      icon: <ExclamationCircleOutlined />,
      centered: true,
      okText: "Confirm",
      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },

      onOk() {
        data.length > 0 && handleUpdateBill(data);
      },

      onCancel() {},

      okButtonProps: {
        className: "submit-btn",
        type: "primary",
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },
    });
  };

  // HANDLE UPDATE BILL
  const handleUpdateBill = async (e) => {
    const contentSubmit = splitData(e);
    const splitPayment =
      relatedBillData?.length > 0 ? splitData(relatedBillData) : null;

    console.log("SPLIT PAYMENT ->> ", splitPayment);

    await axios
      .put(
        `${baseurl}/bill/master-folio`,
        {
          bill_status: typeData,
          reservation_id: contentSubmit?.reservation_id,
          article_id: contentSubmit?.article_id,
          bill_receiver: billReceiver?.guest_id,
          split_payment: splitPayment
            ? {
                bill_status: 0, // default: normal bill
                reservation_id: splitPayment?.reservation_id,
                article_id: splitPayment?.article_id,
                bill_receiver: billReceiver?.guest_id,
              }
            : null,
        },
        { headers: { Authorization: `Bearer ${token ? token : ""}` } }
      )
      .then((response) => {
        console.log("Update Master Folio ->> ", response);
        const _state = response?.data?.msg;

        if (isFromCheckOut) {
          handleNavigate(); // redirect to guest folio
          masterSuccessNotification(response, {
            method: 1,
            source: "Master Folio",
          });
        } else {
          handleSuccess(_state, billReceiver); // Redirect to Success Page
        }
      })
      .catch((error) => {
        console.log("Failed Update Master Folio ->> ", error);
        masterFailedNotification(error, { method: 1, source: "Master Folio" });
      });
  };

  // HANDLE CANCEL
  const handleCancel = () => {
    setOpen(false);
    closeModal(false);
    setData([]);
    // setEdit(null);

    setIsLoading(true);

    form.resetFields();
  };

  // HANDLE PRINT OUT
  const handlePrintOut = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Master Folio ${
      data[0]?.reservation_id ? data[0]?.reservation_id : " ---- "
    }`,
    onPrintError: (error) => {
      console.log("Error Print Out: ", error);
    },
    // onafterprint: () => alert("Print Success"),
  });

  // HANDLE NAVIGATE
  const handleNavigate = () => {
    const _state = stateData;
    const _path = "/front-office/expected-departure/guest-folio";

    console.log("State Return To Guest Folio ->> ", _state);

    navigate(_path, {
      state: _state,
    });
  };

  // HANDLE SUCCESS
  const handleSuccess = async (state, receiver) => {
    const path = "/front-office/bill-outstanding-list/master-folio/success";

    navigate(`${path}`, {
      state: {
        bill_receiver: receiver?.length > 0 ? receiver : [receiver],
        bill_data: !isFromCheckOut && !checkoutData ? null : state,
      },
    });
  };

  // Table's Column
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => {
        return date && date !== "Invalid date"
          ? moment(date).format("DD-MM-YYYY")
          : "-";
      },
    },
    {
      title: "Article Name",
      dataIndex: "article_name",
      key: "article_name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: `Unit Price ( ${currency} )`,
      dataIndex: "unit_price",
      key: "unit_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>{`${record.unit_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        );
      },
    },
    {
      title: `Total Price ( ${currency} )`,
      dataIndex: "total_price",
      key: "total_price",
      align: "right",
      sortDirections: ["descend", "ascend"],
      render: (_, record) => {
        return (
          <p>{`${record.total_price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        className="res-form-modal"
        title={
          <>
            <Row className="row-modal-title">
              <BiSolidFilePdf style={{ color: "#3699FF", fontSize: 24 }} />
              <Typography style={{ marginLeft: 15 }}>
                {`View Master Folio`}
              </Typography>
            </Row>
          </>
        }
        getContainer={true}
        centered
        open={open}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Form
          name="resv-form-modal"
          className="resv-form-modal"
          form={form}
          labelCol={{ span: 12 }}
          labelWrap={{ span: 12 }}
          labelAlign="left"
          style={{
            maxWidth: 1000,
          }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          //   initialValues={handleShow}
          //   onChange={handleChange}
          autoComplete="off"
          layout="horizontal"
        >
          {!isLoading ? (
            <Content
              ref={componentRef}
              className="content-receipt-form"
              style={{ padding: "30px 80px 30px 80px" }}
            >
              <Row gutter={30} className="row-header">
                <Col span={12} className="col-title-form">
                  <Typography.Title level={2} className="text-form-title">
                    {`Master Folio`}
                  </Typography.Title>
                </Col>

                <Col span={12} align="middle" className="col-img-logo">
                  <Image
                    src="/ideas.png"
                    className="img-form-logo"
                    preview={false}
                    style={{
                      width: "20%",
                    }}
                  />

                  <Typography.Title level={4} className="text-form-id">
                    {`Idea's Hotel`}
                  </Typography.Title>
                </Col>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "10px 0px", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-receipt-no"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="No"
                  className="receipt_number"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  {}
                </Form.Item>
              </Row>

              <Row
                className="row-date"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="Date"
                  className="date"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Typography className="date-text" style={textForm}>
                    {moment().format("HH:mm, DD-MM-YYYY")}
                  </Typography>
                </Form.Item>
              </Row>

              <Row
                className="row-type"
                style={{ height: 30, width: "100%", margin: 0 }}
              >
                <Form.Item
                  label="Type"
                  className="type"
                  style={{ width: "100%" }}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                >
                  <Typography className="type-text" style={textForm}>
                    {typeData === 1
                      ? "SPLIT BILL"
                      : typeData === 2
                      ? "JOIN BILL"
                      : "NORMAL BILL"}
                  </Typography>
                </Form.Item>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: "15px 0", backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-guest-details"
                style={{
                  width: "100%",
                }}
              >
                <Col span={12} className="col-res-id">
                  <Form.Item
                    label="Guest Name"
                    className="guest_name"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-guest-name" style={textForm}>
                      {billReceiver?.guest_name
                        ? billReceiver?.guest_name?.replace(/\d+$/, "").trim()
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Arrival"
                    className="arrival"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {billReceiver?.arrival &&
                      billReceiver?.arrival !== "Invalid date"
                        ? `${moment(billReceiver?.arrival).format(
                            "DD-MM-YYYY"
                          )}`
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Departure"
                    className="departure"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {billReceiver?.departure &&
                      billReceiver?.departure !== "Invalid date"
                        ? `${moment(billReceiver?.departure).format(
                            "DD-MM-YYYY"
                          )}`
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Night(s)"
                    className="night"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {billReceiver?.night ? billReceiver.night : 0}
                    </Typography>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label="Reservation No."
                    className="reservation_id"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-res-id" style={textForm}>
                      {billReceiver?.reservation_id
                        ? billReceiver?.reservation_id
                        : "-"}
                    </Typography>
                  </Form.Item>

                  <Form.Item
                    label="Room No."
                    className="room_no"
                    style={{ margin: 0 }}
                  >
                    <Typography className="text-room-number" style={textForm}>
                      {`${billReceiver?.room_category} - ${billReceiver?.room_number}`}
                    </Typography>
                  </Form.Item>

                  <Form.Item label="Payment Status" className="payment_status">
                    <Typography
                      className="text-payment-details"
                      style={textForm}
                    >
                      {billReceiver?.payment_status == 3
                        ? "Cash Basis"
                        : "Charge To Room"}
                    </Typography>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Typography
                  style={textForm}
                >{`Transaction Details`}</Typography>
              </Row>

              <Divider
                className="divider-form"
                style={{ margin: 0, backgroundColor: "#d9d9d9" }}
              />

              <Row
                className="row-table"
                justify="start"
                style={{ padding: "10px 0px" }}
              >
                <Table
                  className="transaction-table"
                  key="transaction-table"
                  name="transaction-table"
                  loading={isLoading}
                  columns={columns}
                  dataSource={data}
                  bordered={true}
                  size="small"
                  style={{
                    padding: 5,
                    border: "1px solid #000000",
                  }}
                  pagination={false}
                  rowKey={(record) => (record?.id ? record.id : record.key)}
                  summary={(pageData) => {
                    return (
                      <>
                        <Table.Summary fixed>
                          {bill_type ? (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={5}>
                                  <b>Subtotal</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${
                                    subTotal.total > 0 ? subTotal.total : 0
                                  }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={5}>
                                  <b>Service</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${
                                    subTotal.service > 0 ? subTotal?.service : 0
                                  }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>

                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5} />

                                <Table.Summary.Cell index={5}>
                                  <b>Tax</b>
                                </Table.Summary.Cell>

                                <Table.Summary.Cell index={6} align="right">
                                  {`${currency} ${
                                    subTotal?.tax > 0 ? subTotal.tax : 0
                                  }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          ) : null}

                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={5} />

                            <Table.Summary.Cell index={5}>
                              <b style={{ fontSize: 15 }}>Grand Total</b>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell index={6} align="right">
                              <b style={{ fontSize: 15 }}>
                                {`${currency} ${
                                  subTotal.grand > 0 ? subTotal.grand : 0
                                }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </b>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </Table.Summary>
                      </>
                    );
                  }}
                />
              </Row>

              <Typography className="text-notes" style={{ fontSize: 10 }}>
                *notes: please, keep this invoice as your payment's warranty!
              </Typography>

              {bill_type ? null : (
                <Typography className="text-notes" style={{ fontSize: 10 }}>
                  *notes: all prices shown are already include tax & service
                </Typography>
              )}
            </Content>
          ) : (
            <LoadingComponents />
          )}

          <Row
            justify="end"
            align="middle"
            className="row-form-btn"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <Divider className="divider-form" style={{ margin: "15px 0px" }} />

            <Button
              className="print-btn"
              type="primary"
              htmlType="submit"
              icon={<PrinterOutlined />}
              onClick={() => {
                handlePrintOut();
              }}
              style={{
                marginRight: 30,
              }}
            >
              {`Print & Download`}
            </Button>

            <Button
              className="submit-btn"
              type="default"
              style={{
                marginRight: 15,
                color: "white",
              }}
              onClick={handleSubmit}
            >
              {`Submit`}
            </Button>

            <Button
              className="cancel-btn"
              type="default"
              onClick={handleCancel}
            >
              {`Cancel`}
            </Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
