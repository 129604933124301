// REACT COMPONENTS
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// ANTD COMPONENTS
import {
  Button,
  Col,
  Divider,
  Layout,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import { AppstoreFilled, SyncOutlined } from "@ant-design/icons";

// GLOBAL STYLES
import { cardBody, cardHeader } from "../../../Style/Global/Global";

// REACT ICONS
import { FaMoneyBills } from "react-icons/fa6";
import { IoReturnUpBackOutline } from "react-icons/io5";

// PAGE COMPONENTS
import ListBillTable from "../../../Components/Table/MasterFolio/ListBill/Table";
import ListBillPayTable from "../../../Components/Table/MasterFolio/ListBillPay/Table";
import BillList from "../../../Components/Reusable/MasterFolio/BillList";
import GuestBillModal from "../../../Components/Modals/GuestBillList/Modal";
import GroupingBill from "../../../Components/Reusable/Functions/GroupingArticle/GroupingBill";

// CONTEXT
import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// GLOBAL API
import axios from "axios";
import { baseurl } from "../../../API/Config/Api";

// NOTIFICATIONS
import { failedFetch } from "../../../Components/Reusable/Notification/Notification";
import { BillArticleShow } from "../../../Components/Reusable/Functions/BillType/BillType";

// PARAMS
import { AccountingParams } from "../../../Components/Reusable/Functions/Parameters/ParamsAcc";
import { CurrencySymbols } from "../../../Components/Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../../Components/Reusable/Functions/Parameters/ParamsFO";

export default function MasterFolioPage() {
  // CONTEXT
  const { getReservationBySource, getTrxSource } = useContext(MainContext);

  // PARAMS
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  const [articleData, setArticleData] = useState([]);
  const [returnArticleData, setReturnArticleData] = useState(null);
  const [billReceiver, setBillReceiver] = useState([]);
  const [source, setSource] = useState(-1);
  const [type, setType] = useState(0);
  const [status, setStatus] = useState(1);
  const [element, setElement] = useState(null);
  const [bill, setBill] = useState({});
  const [optionValue, setOptionValue] = useState([]);
  const [sourceOption, setSourceOption] = useState([]);
  // Receiver Bill
  const [receiver, setReceiver] = useState();
  // Related Bill
  const [relatedBill, setRelatedBill] = useState([]);
  const [joinedRelatedBill, setJoinedRelatedBill] = useState([]);
  // Key Selector
  const [keySource, setKeySource] = useState(0);
  const [keyType, setKeyType] = useState(0);
  const [keyBill, setKeyBill] = useState(0);

  // Modal
  const [open, setOpen] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  // from C/O
  const [checkoutData, setCheckoutData] = useState(null);
  // State Data
  const { state } = useLocation();
  // Navigate
  const navigate = useNavigate();

  // USEEFFECT STATE FROM C/O
  useEffect(() => {
    let cleanUp = false;

    if (state && !cleanUp) {
      console.log("State ->> ", state);

      // const co = state?.departure?.length > 0 ? state : [];
      setCheckoutData(state);
      setSource(sourceOption.length > 0 ? [sourceOption[0]?.id] : [1]);
      setStatus(state.data_res.is_group ? 2 : 1);
      setReceiver({
        label: `${state.data_res.reservation_id} - ${state.data_res.guest_name}`,
        value: state.data_res.reservation_id,
        ...state.data_res,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  // USEEFFECT CHANGE BILL
  useEffect(() => {
    console.log("Selected Bill ->> ", bill);
    console.log("Joined Related Bill ->> ", joinedRelatedBill);
  }, [bill, joinedRelatedBill]);

  // USEEFFECT FOR HANDLE JOIN
  useEffect(() => {
    console.log("ISJOINNNN ->> ", isJoin);

    // IF NOT JOIN WITH OTHER BILL
    if (!isJoin) {
      setElement(null);
      setKeySource((state) => state++);
      handleClearSource(); // Source & Member Bill -> Reset & Clear
    }
  }, [isJoin]);

  // USEEFFECT HANDLE SPLIT / NORMAL TYPE
  useEffect(() => {
    let cleanUp = false;

    if (
      joinedRelatedBill.length > 0 &&
      (type === 1 || type === 0) &&
      !cleanUp
    ) {
      setJoinedRelatedBill([]);
    }

    return () => {
      cleanUp = true;
    };
  }, [type, joinedRelatedBill]);

  // USEEFFECT CHANGE BILL RECEIVER
  useEffect(() => {
    let cleanUp = false;

    if (receiver && !cleanUp) {
      setOpen(true);
      // fetchDataOtherBill(receiver?.reservation_id);
    } else {
      setOpen(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [receiver]);

  // USE EFFECT FETCHDATA
  useEffect(() => {
    fetchData();
    fetchSource();
  }, []);

  //  FETCH DATA MASTER FOLIO BILL
  const fetchData = async () => {
    await getReservationBySource({
      reservation: "bill",
      type: "master-folio",
      source: [1],
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;
          console.log("Response Master Folio => ", _response);

          // Handle duplicate response
          const filterUniqueReservations = (data) => {
            const uniqueReservations = [];

            data.forEach((item) => {
              if (
                !uniqueReservations.some(
                  (reservation) =>
                    reservation.reservation_id === item.reservation_id
                )
              ) {
                uniqueReservations.push(item);
              }
            });

            return uniqueReservations;
          };

          const _filtered = filterUniqueReservations(_response);
          // const filterShowData = _filtered.filter((val) => val.is_show);

          const mappedOptions = _filtered.map((item) => {
            return {
              label: `${item?.reservation_id} - ${item?.guest_name
                ?.replace(/\d+$/, "")
                .trim()} `,
              value: `${item?.reservation_id} - ${item?.guest_name
                ?.replace(/\d+$/, "")
                .trim()} `,
              reservation_id: item?.reservation_id,
              guest_id: item?.guest_id,
              bill_receiver: item?.bill_receiver
                ? item.bill_receiver
                : item.guest_id,
              room_number: item?.room_number ? item?.room_number : "-",
              room_category: item?.room_category ? item?.room_category : "-",
              guest_name: item?.guest_name,
              arrival: item?.start_date,
              departure: item?.end_date,
              payment_status:
                item?.payment_status == 3 ? "Cash Basis" : "Charge To Room",
              payment_details: item?.payment_details,
              payment_type: item?.payment_type,
              night: item?.night ? item.night : 0,
              price_id: item?.price_id,
            };
          });

          setOptionValue(mappedOptions);
        } else {
          setOptionValue([]);
        }
        // setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        // setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  //  FETCH DATA TRX SOURCE
  const fetchSource = async () => {
    await getTrxSource({
      type: "trx-source",
      onAwait: () => {
        // setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response Source => ", response);
        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;
          const _data = _res.map((item) => {
            return {
              label: item?.source,
              value: item?.id,
              source_code: item?.id,
              ...item,
            };
          });

          setSourceOption(_data);
        } else {
          setSourceOption([]);
        }
      },
      onReject: (error) => {
        console.log(error);
        failedFetch(error);
      },
    });
  };

  // HANDLE CHANGE BILL RECEIVER SELECTOR
  const handleChangeReceiver = (value, allKeys) => {
    console.log("Bill List All Keys ->> ", allKeys);

    if (receiver) {
      setJoinedRelatedBill([]);
    }

    setReceiver(allKeys);
  };

  // HANCLE CLEAR RECEIVER SELECTOR
  const handleClearReceiver = () => {
    setReceiver(null);
    setJoinedRelatedBill([]);
    setType(0);
    handleClearType();

    if (type == 1) {
      setBill({});
    }
  };

  // GROUP OPTIONS
  const groupOptions = [
    {
      label: "INDIVIDUAL",
      value: 1,
    },
    {
      label: "GROUP",
      value: 2,
    },
  ];

  // TYPE OPTIONS
  const typeOptions = [
    {
      label: "NORMAL BILL",
      value: 0,
    },
    {
      label: "SPLIT BILL",
      value: 1,
    },
    {
      label: "JOIN BILL",
      value: 2,
    },
  ];

  // HANDLE REMOVE UNUSED KEYS
  const removeUnusedKeys = (bill, allKeys) => {
    // ambil label dari allKeys
    const validLabels = allKeys.map((item) => item.label);

    // loop each key in bill
    Object.keys(bill).forEach((key) => {
      // jika key tidak ada di allKeys, hapus key dari bill
      if (!validLabels.includes(key)) {
        delete bill[key];
      }
    });

    return bill;
  };

  // HANDLE CHANGE SOURCE
  const handleChangeSource = (value, allKeys) => {
    console.log("Value Source ->> ", value);
    console.log("AllKeys Source ->> ", allKeys);

    setSource(value);

    if (allKeys?.length > 0) {
      setElement(
        allKeys.map((item, index) => {
          return (
            <Col
              key={index}
              style={{
                textAlign: "start",
                // margin: "22px 30px",
                fontSize: 14,
                fontWeight: 400,
                maxWidth: 250,
                minWidth: 200,
              }}
            >
              Select {item.label} Bill:
              <BillList
                keyBill={keyBill}
                index={index}
                label={item.label}
                value={item.value}
                type={type}
                getBill={setBill}
                status={status}
              />
            </Col>
          );
        })
      );
    } else if (allKeys) {
      setElement(
        <Col
          style={{
            textAlign: "start",
            // margin: "22px 30px",
            fontSize: 14,
            fontWeight: 400,
            maxWidth: 250,
            minWidth: 200,
          }}
        >
          Select {allKeys.label} Bill:
          <BillList
            keyBill={keyBill}
            index={0}
            label={allKeys.label}
            value={allKeys.value}
            type={type}
            getBill={setBill}
            status={status}
          />
        </Col>
      );
    } else {
      setElement(null);
    }

    // if bill not empty
    if (
      Object.keys(bill).length > 0 &&
      Object.values(bill).every((arr) => arr.length > 0)
    ) {
      // if type == join
      if (type == 2) {
        console.log("Bill Change ->> ", bill);
        const removeKeys = removeUnusedKeys(bill, allKeys); // sesuaikan bill berdasarkan allKeys/source yg dipilih
        setBill(removeKeys);
      } else {
        handleClearSource();
      }
    }
  };

  // HANDLE CLEAR SOURCE
  const handleClearSource = () => {
    setKeyBill(keyBill + 1);
    setBill({});
  };

  // HANDLE CHANGE TYPE
  const handleChangeType = (value) => {
    if (type != value) {
      setType(value);

      if (value !== 2) setIsJoin(false);

      if (source !== -1) handleClearType();
    }
  };

  // HANDLE CLEAR TYPE
  const handleClearType = () => {
    // change key for re-render
    setKeySource(keySource + 1);
    setKeyBill(keyBill + 1);
    // reset source and bill
    setSource(-1);
    setBill({});
    setElement(null);

    setIsJoin((state) => (state ? state : false));
  };

  // HANDLE CHANGE STATUS
  const handleChangeStatus = (value) => {
    setStatus(value);

    if (type !== -1 && source !== -1) {
      handleClearStatus();
    }
  };

  // HANDLE CLEAR STATUS
  const handleClearStatus = () => {
    setKeyType(keyType + 1);
    setKeySource(keySource + 1);
    setKeyBill(keyBill + 1);

    setType(0);
    setSource(-1);
    setBill({});
    setElement(null);
  };

  // HANDLE RETURN TO MENU Bill OUTSTANDING
  const handleReturn = () => {
    navigate(-1);
  };

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="master-folio-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaMoneyBills
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Master Folio`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 200,
                  }}
                >
                  Select Status:
                  <Select
                    style={{
                      width: "100%",
                    }}
                    // defaultValue={1}
                    value={status}
                    onChange={handleChangeStatus}
                    options={groupOptions}
                    allowClear
                    placeholder="Status"
                    showSearch
                    onClear={handleClearStatus}
                    disabled={checkoutData ? true : false}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    // margin: "22px 30px",
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 250,
                    minWidth: 200,
                  }}
                >
                  Select Type:
                  <Select
                    key={keyType} // key untuk paksa re-render
                    style={{
                      width: "100%",
                    }}
                    defaultValue={type}
                    value={type}
                    onChange={handleChangeType}
                    options={typeOptions}
                    allowClear
                    placeholder="Type"
                    showSearch
                    onClear={handleClearType}
                  />
                </Col>

                <Col
                  style={{
                    textAlign: "start",
                    marginRight: 15,
                    fontSize: 14,
                    fontWeight: 400,
                    maxWidth: 300,
                    minWidth: 250,
                  }}
                >
                  Select Bill Receiver:
                  <Row
                    className="master-row"
                    align="top"
                    justify="start"
                    gutter={0}
                  >
                    <Col span={22} className="select-col">
                      <Select
                        className="bill-list"
                        // key={optionValue.key}
                        style={{ width: "100%" }}
                        value={receiver}
                        placeholder="Receiver Bill"
                        showSearch
                        allowClear
                        disabled={checkoutData ? true : false}
                        onChange={handleChangeReceiver}
                        onClear={handleClearReceiver}
                        options={optionValue}
                        dropdownRender={(menu) => (
                          <>
                            {menu}
                            <Divider
                              style={{
                                margin: "5px 0",
                                background: "#EBEDF3",
                              }}
                            />

                            <Row
                              justify="end"
                              align="middle"
                              key={"rows-key"}
                              style={{
                                padding: "0px 5px",
                              }}
                            >
                              <Button
                                type={"default"}
                                icon={<SyncOutlined />}
                                onClick={fetchData}
                              >
                                {`Refresh`}
                              </Button>
                            </Row>
                          </>
                        )}
                      />
                    </Col>

                    <Col span={2} className="select-col">
                      <Tooltip
                        className="redirect-tooltip"
                        title={"Open Guest Bill"}
                      >
                        <Button
                          className="master-btn"
                          icon={
                            <AppstoreFilled
                              onClick={() => (receiver ? setOpen(true) : null)}
                              style={{ fontSize: 20, color: "#3699FF" }}
                            />
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>

                {isJoin ? (
                  <Col
                    // hidden={isJoin ? false : true}
                    style={{
                      textAlign: "start",
                      // margin: "22px 30px",
                      fontSize: 14,
                      fontWeight: 400,
                      maxWidth: 250,
                      minWidth: 200,
                    }}
                  >
                    Select Source Member:
                    <Select
                      key={keySource} // key untuk paksa re-render
                      style={{
                        width: "100%",
                      }}
                      mode={type == 2 ? "multiple" : null}
                      onChange={handleChangeSource}
                      options={sourceOption}
                      allowClear
                      placeholder="Source"
                      showSearch
                      onClear={handleClearSource}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "5px 0",
                              background: "#EBEDF3",
                            }}
                          />

                          <Row
                            justify="end"
                            align="middle"
                            key={"rows-key"}
                            style={{
                              padding: "0px 5px",
                            }}
                          >
                            <Button
                              type={"default"}
                              icon={<SyncOutlined />}
                              onClick={fetchSource}
                            >
                              {`Refresh`}
                            </Button>
                          </Row>
                        </>
                      )}
                    />
                  </Col>
                ) : null}

                {element}

                <Col
                  className="col-search-btn"
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  <Button
                    className="refresh-btn"
                    type="default"
                    icon={
                      <IoReturnUpBackOutline
                        className="return-icons"
                        style={{
                          margin: "0px 5px 0px 0px",
                        }}
                      />
                    }
                    onClick={handleReturn}
                  >
                    {`Return`}
                  </Button>
                </Col>
              </Space>
            </Row>

            <Row gutter={[30, 30]} className="table-row">
              <Col
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="col-table"
              >
                <ListBillTable
                  returnArticleData={returnArticleData}
                  getArticleData={setArticleData}
                  getReturnArticleData={setReturnArticleData}
                  getBillReceiver={setBillReceiver}
                  type={type}
                  source={source}
                  bill={bill}
                  receiver={receiver}
                />
              </Col>

              <Col
                xxl={12}
                xl={12}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                className="col-table"
              >
                <ListBillPayTable
                  articleData={articleData}
                  billReceiver={billReceiver}
                  getReturnArticleData={setReturnArticleData}
                  getArticleData={setArticleData}
                  typeData={type}
                  billSource={source}
                  checkoutData={checkoutData}
                  joinedRelatedBill={joinedRelatedBill}
                  relatedBillData={relatedBill}
                />
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>

      <GuestBillModal
        openModal={open}
        closeModal={setOpen}
        isJoin={isJoin}
        getJoin={setIsJoin}
        getType={handleChangeType}
        type={type}
        checkoutData={checkoutData}
        receiver={receiver}
        getRelatedBillData={setRelatedBill}
        getJoinedRelatedBill={setJoinedRelatedBill}
      />
    </>
  );
}
