// Import React's Component
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";

// CONTEXT
import { token } from "../../../../../API/Global/Payload";
import { baseurl } from "../../../../../API/Config/Api";
import { Context as MainContext } from "../../../../../API/Context/MainContext/MainContext";

// Import Ant Design Components
import {
  Divider,
  Radio,
  Table,
  Space,
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  Tooltip,
  Steps,
  Progress,
  Alert,
} from "antd";

// Import React Icons
import { FormOutlined, MenuOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DeleteFilled,
  FileExcelFilled,
  PlusCircleTwoTone,
  PlusOutlined,
  RetweetOutlined,
  SyncOutlined,
} from "@ant-design/icons/lib/icons";
import { FaFileDownload } from "react-icons/fa";
import { RiDeleteBin5Fill } from "react-icons/ri";

// Notification
import { failedFetch } from "../../../../Reusable/Notification/Notification";

// CONST
const { Text, Link, Title } = Typography;

// CODE
export default function CountryAuditTable(props) {
  // PROPS
  const { dataCountry, dateAudit, is_reset, is_refresh } = props;

  // COLUMN
  const columns = [
    {
      title: "Reservation No.",
      dataIndex: "reservation_id",
      key: "reservation_id",
    },
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
    },
    {
      title: "Room No.",
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: "Rate Code",
      dataIndex: "rate_code",
      key: "rate_code",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Arrival",
      dataIndex: "arrival",
      key: "arrival",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Departure",
      dataIndex: "departure",
      key: "departure",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
  ];

  // CONTEXT
  const { getNightAudit } = useContext(MainContext);

  // STATE MANAGEMENT
  // DATA
  const [data, setData] = useState([]);
  const [date_audit, setDate_Audit] = useState(null);
  // Boolean
  const [loading, setLoading] = useState(false);

  // Fetch Data Night Audit Room Rate
  const fetchData = async () => {
    await getNightAudit({
      type: "check-nation-country",
      onAwait: () => {
        setLoading(true);
      },
      onSuccess: (response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          let _response = response.data.msg;

          setData(_response);
          dataCountry(_response);
        }
        setLoading(false);
      },
      onReject: (error) => {
        console.log(error);
        setLoading(false);
        // _error.push(error);
        failedFetch(error);
      },
    });
  };

  // Fetch Data Re-Night Audit
  const fetchReAudit = async () => {
    setLoading(true);

    await axios
      .post(
        `${baseurl}/re-night-audit/check-nation-country`,
        {
          date: moment(dateAudit).format("YYYY-MM-DD"),
        },
        {
          headers: { Authorization: `Bearer ${token || ""}` },
        }
      )
      .then((response) => {
        console.log("Response => ", response);

        if (response?.data?.msg?.length > 0) {
          const _response = response.data.msg;

          setData(_response);
          dataCountry(_response);
        } else {
          dataCountry([]);
          setData([]);
        }
      })
      .catch((error) => {
        console.log("Err: ", error);
        failedFetch(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // USE EFFECT
  useEffect(() => {
    let cleanUp = false;

    if (dateAudit != date_audit && !cleanUp) {
      setDate_Audit(dateAudit);
      fetchReAudit();
    } else {
      if (date_audit) {
        setData([]);
        dataCountry([]);
      } else {
        fetchData();
      }
    }

    if (is_refresh && !cleanUp) {
      fetchData();
      is_reset(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [dateAudit, is_refresh]);

  return (
    <>
      <Row style={{ marginTop: 30, width: "100%" }}>
        <Table
          className="na-country-table"
          key="na-country-table"
          name="na-country-table"
          loading={loading}
          columns={columns}
          dataSource={data}
          bordered
          size="small"
          pagination={false}
          scroll={{
            x: true,
          }}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "odd" : "even";
          }}
          rowKey={(record) =>
            record?.room_number ? record.room_number : record.id
          }
        />
      </Row>

      <Row justify="end" style={{ padding: "5px 30px" }}>
        <Typography style={{ color: "#000000", fontWeight: 750 }}>
          {`Showing total of ${data?.length || 0} Data (Entries)`}
        </Typography>
      </Row>
    </>
  );
}
