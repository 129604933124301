// Import React Icons
import React, { useState } from "react";

// Import Ant Design Components
import { Col, Row, Space, Button } from "antd";

// Import Global Styles
import { mainBody } from "../../../../Style/Global/Global";
import MainCategoryList from "../../../../Components/Widgets/Outlet/Articles/MainGroup/MainList";
import SubCategoryList from "../../../../Components/Widgets/Outlet/Articles/SubGroup/SubList";
import ArticleItemList from "../../../../Components/Widgets/Outlet/Articles/ArticleItem/ArticleItem";
import ModalTableOrder from "../../../../Components/Modals/Outlet/Order/Modal";
import { PlusOutlined } from "@ant-design/icons";
import ModalOpenMenu from "../../../../Components/Modals/Outlet/Order/OpenMenuModal";

// CODE
export default function CaptainArticleMenuPage(props) {
  // PROPS
  const { articleData, menuDisabled } = props;

  // State Management
  // Article Hierarchy
  const [articleItem, setArticleItem] = useState([]);
  const [subGroup, setSubGroup] = useState([]);
  const [mainGroup, setMainGroup] = useState([]);
  // Open Modal Order
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // Handle Pick
  const handleMainArticle = (value) => {
    console.log("Values Main: ", value);

    setMainGroup(value);
    setSubGroup(null);
  };

  // Handle Pick
  const handleSubArticle = (value) => {
    console.log("Values Sub: ", value);

    setSubGroup(value);
    setArticleItem(null);
  };

  // Handle Pick
  const handleArticleItem = (value) => {
    console.log("Values Item: ", value);

    setArticleItem(value);
    setOpen(true);
  };

  // Handle Table
  const handleTable = (value) => {
    const _table = value;

    console.log("Table: ", _table);

    articleData(_table);
  };

  // OPEN MODAL
  const handleOpen = () => {
    setOpenMenu(true);
    setRefresh(false);
  };

  // CLOSE MODAL
  const handleCancel = () => {
    setOpenMenu(false);
  };

  // REFRESH MODAL
  const handleRefresh = () => {
    setOpenMenu(false);
    setSubGroup([]);
    setRefresh(true);
  };

  return (
    <>
      <Row className="main-row" style={mainBody}>
        <Col
          span={8}
          className="main-col"
          style={{ padding: "30px 0px 30px 30px" }}
        >
          <Row>
            <Space
              className="title-spacetxt"
              align="center"
              size={"large"}
              style={{
                fontSize: 20,
                fontWeight: 500,
              }}
            >
              {`Main Categories`}
            </Space>

            <MainCategoryList main_selected={handleMainArticle} />
          </Row>
          <Row>
            <Space
              className="title-spacetxt"
              align="center"
              size={"large"}
              style={{
                fontSize: 20,
                fontWeight: 500,
              }}
              direction="vertical"
            >
              {`Open Menu`}
            </Space>
          </Row>
          <Row>
          <Button
              className="submit-btn"
              type="primary"
              onClick={handleOpen}
              icon={<PlusOutlined />}
            >
              {`Add New Item`}
            </Button>
          </Row>
        </Col>

        <Col span={16} className="main-col" style={{ padding: 30 }}>
          <Row className="sub-list-row" justify="start" align="top">
            <Col span={24} className="submain-col">
              <Space
                className="title-spacetxt"
                align="center"
                size={"large"}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {`Sub Categories`}
              </Space>

              <SubCategoryList
                main_selected={mainGroup}
                sub_selected={handleSubArticle}
              />
            </Col>
          </Row>

          <Row
            className="sub-list-row"
            justify="start"
            style={{
              margin: "30px 0px 0px",
            }}
          >
            <Col span={24} className="submain-col">
              <Space
                className="title-spacetxt"
                align="center"
                size={"large"}
                style={{
                  fontSize: 20,
                  fontWeight: 500,
                }}
              >
                {`Items`}
              </Space>
              <ArticleItemList
                sub_selected={subGroup}
                item_selected={handleArticleItem}
                menuDisabled={menuDisabled}
                refresh={refresh}
              />
              {/* </Row> */}
            </Col>
          </Row>
        </Col>
      </Row>

      <ModalTableOrder
        openModal={open}
        closeModal={setOpen}
        articleItems={articleItem}
        articleTable={handleTable}
        is_edit={false}
      />
      <ModalOpenMenu
        is_open={openMenu}
        is_close={handleCancel}
        is_refresh={handleRefresh}
        article={null}
        articleTable={handleTable}
      />
    </>
  );
}
