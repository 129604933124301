// Import React Components
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import { Context as MainContext } from "../../../API/Context/MainContext/MainContext";

// AUTH
import { baseurl } from "../../../API/Config/Api";
import { token, user_name } from "../../../API/Global/Payload";

// Import Antd Components
import { Content } from "antd/lib/layout/layout";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Layout,
  Row,
  Space,
  Table,
  Typography,
  Input,
  InputNumber,
  Alert,
  Modal,
  Result,
  Tooltip,
} from "antd";

// Import Icons Components
import { IoReturnUpBackOutline } from "react-icons/io5";
import { FaFileInvoiceDollar } from "react-icons/fa";
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SendOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { FaMoneyBills } from "react-icons/fa6";
import { ImExit } from "react-icons/im";

// Import Page Components
import FolioTable from "../../../Components/Table/MasterFolio/GuestFolio/Table";

// Import Functions
import { CurrencySymbols } from "../../../Components/Reusable/Functions/Currency/Currency";
import { FrontOfficeParams } from "../../../Components/Reusable/Functions/Parameters/ParamsFO";
import { BillArticleShow } from "../../../Components/Reusable/Functions/BillType/BillType";
import { AccountingParams } from "../../../Components/Reusable/Functions/Parameters/ParamsAcc";
import GroupingArticle from "../../../Components/Reusable/Functions/GroupingArticle/GroupingArticle";
import GroupingBill from "../../../Components/Reusable/Functions/GroupingArticle/GroupingBill";

// Import Global Styles
import {
  cardHeader,
  cardBody,
  mainBody,
  textForm,
} from "../../../Style/Global/Global";

// Import Notifications
import {
  failedCheckOut,
  failedFetch,
  successCheckOut,
} from "../../../Components/Reusable/Notification/Notification";

// MODALS
const { confirm } = Modal;
const { Paragraph, Text } = Typography;

// CODE
export default function GuestFolioPage() {
  // NAVs
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  // CONTEXT
  const { getGuest } = useContext(MainContext);

  // Currency
  const accounting = AccountingParams();
  const currency = CurrencySymbols().code;
  const bill_type = FrontOfficeParams().bill_type;

  // STATE MANAGEMENT
  // Data Reservations Array
  const [arrRes, setArrRes] = useState([]);
  const [dataRes, setDataRes] = useState(null);
  const [billReceiver, setBillReceiver] = useState(null);
  const [billMaster, setBillMaster] = useState({
    receiver: "",
    other: "",
  });
  // DATA
  const [billList, setBillList] = useState([]); // Bill C/O
  const [billMoved, setBillMoved] = useState([]); // SPLITTED/JOINED BILLS
  //
  const [guestList, setGuestList] = useState([]);
  const [subTotal, setSubTotal] = useState({
    total: 0,
    tax: 0,
    service: 0,
    grand: 0,
  });
  const [subTotalOther, setSubTotalOther] = useState({
    total: 0,
    tax: 0,
    service: 0,
    grand: 0,
  });
  // BOOLEAN
  const [isEarly, setIsEarly] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cardLoad, setCardLoad] = useState(true);
  const [loadBtn, setLoadBtn] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  // Is Exist
  const [isExist, setIsExist] = useState(false);
  const [isMoved, setIsMoved] = useState(false);

  // FORM
  const [form] = Form.useForm();
  const [formTwo] = Form.useForm();

  // FETCH DATA
  const fetchData = async (value) => {
    setIsLoading(true);
    setCardLoad(true);
    setIsDisable(true);

    await axios
      .post(
        `${baseurl}/guest/folio-by-reservation-id`,
        {
          reservation_id: value?.res_id ? value.res_id : dataRes.reservation_id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Response Paid Bill: ", response);

        if (response?.data?.length > 0) {
          const _res = response.data[0];

          const _filtered = _res?.data
            ? _res.data.filter((items) => {
                if (!items.is_deleted && items.id > 0) return items;
              })
            : [];

          // console.log("Bill Paid: ", _res);
          findReservations(_filtered?.length > 0 ? _filtered : []);
          findBillNumber(_filtered?.length > 0 ? _filtered : []);

          handleBill({
            data: _filtered?.length > 0 ? _filtered : [],
            bill_id: value?.bill_id ? value.bill_id : dataRes,
          });

          console.log(_filtered);

          setCardLoad(false);
          setLoadBtn(false);
          setIsLoading(false);

          if (_filtered?.length > 0) {
            setIsDisable(false);
          }
        } else {
          setBillList([]);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        failedFetch(error);
      });
  };

  // Fetch Data Guest
  const fetchDataGuest = async (value) => {
    await getGuest({
      guest: "guest",
      type: "card",
      onAwait: () => {},
      onSuccess: (response) => {
        console.log("Response Guest => ", response);

        if (response?.data?.msg?.length > 0) {
          const _res = response.data.msg;

          setGuestList(_res);

          let filtered = _res.filter((e) => {
            if (value?.bill_receiver == e.id) {
              return e;
            }
          });

          console.log("Filter Guest >>> ", filtered);

          setBillReceiver(filtered.length > 0 ? filtered[0] : null);

          form.setFieldValue("bill_receiver", filtered[0]?.full_name);
        } else {
          setBillReceiver(null);
          setGuestList([]);
        }
      },
      onReject: (error) => {
        console.log(error);
      },
    });
  };

  // USE EFFECTS
  useEffect(() => {
    let cleanUp = false;

    if (state?.length > 0 && !cleanUp) {
      console.log("State: ", state);

      // const _arr = findReservations(state);

      fetchData({
        bill_id: state[0],
        res_id: state[0]?.reservation_id,
      });
      fetchDataGuest(state[0]);

      setDataRes(state[0]);
      handleFormField(state[0]);
    } else {
      setIsExist(false);
    }

    return () => {
      cleanUp = true;
    };
  }, [state]);

  useEffect(() => {
    let cleanUp = false;

    if (guestList.length > 0 && billMoved.length > 0 && !cleanUp) {
      console.log("Other Bills: ", billMoved);
      handleOther(billMoved[0]?.bill_receiver);

      handleTotal({
        type: 2,
        data: billMoved,
      });
    }

    if (billList.length > 0 && !cleanUp) {
      handleTotal({
        type: 1,
        data: billList,
      });
    }

    return () => {
      cleanUp = true;
    };
  }, [guestList, billMoved, billList]);

  // FIND RESERVATIONS ID
  const findReservations = (value) => {
    const _res_id = value.reduce(
      (acc, init) => {
        if (!acc[init?.reservation_id] && init.reservation_id) {
          acc[init.reservation_id] = true;

          acc.result.push(init.reservation_id);
        }

        return acc;
      },

      { result: [] }
    ).result;

    console.log("Reservation ID's: ", _res_id);

    setArrRes(_res_id);

    return _res_id;
  };

  // FIND RESERVATIONS ID
  const findBillNumber = (value) => {
    const _bills = value.reduce(
      (acc, init) => {
        if (!acc[init?.bill_master] && init.bill_master) {
          acc[init.bill_master] = true;

          acc.result.push(init.bill_master);
        }

        return acc;
      },

      { result: [] }
    ).result;

    console.log("BILL MASTER's: ", _bills);

    return _bills;
  };

  // Handle Form Fields
  const handleFormField = (values) => {
    form.setFieldsValue({
      reservation_id: values?.reservation_id,
      full_name: values?.guest_name,
      room_no: `${values?.room_category} - ${values?.room_number}`,
      arrival: moment(values?.arrival).format("DD-MM-YYYY"),
      departure: moment(values?.departure).format("DD-MM-YYYY"),
      night: values?.night > 0 ? values.night : 0,
      payment_status:
        values?.payment_status == 3 ? "Cash Basis" : "Charge To Room",
    });
  };

  // Handle Bill
  const handleBill = (value) => {
    const _arr = value?.data;
    const bill_id = value?.bill_id;
    const _isMoved = value?.bill_id.guest_id == value?.bill_id?.bill_receiver;

    // Bucket Arrays
    const billsPay = [];
    const others = [];

    // Splitting Bill based on Bill Receiver
    const groupedBillsArray = _arr.reduce((acc, bill) => {
      if (!acc[bill.bill_receiver]) {
        acc[bill.bill_receiver] = [];
      }

      acc[bill.bill_receiver].push(bill);

      return acc;
    }, {});

    console.log("Res Group by Bill Receiver : ", groupedBillsArray);

    // Separating Bills based on Bill Receiver ID
    for (const billId in groupedBillsArray) {
      const keys = groupedBillsArray[billId];

      if (parseInt(billId) == bill_id?.bill_receiver && _isMoved == true) {
        billsPay.push(...keys);
      } else {
        others.push(...keys);
      }
    }

    // Handle Bills to Pay
    const billToPay = handleGroup(billsPay);

    // Handle Others
    const billOther = handleGroup(others);

    console.log("Bill Receiver: ", billToPay);
    console.log("Bill Others: ", others);
    console.log("Bill Others: ", billOther);

    //
    setBillList(billToPay);
    setBillMoved(billOther);
    setIsExist(true);
  };

  // Handle Group Bills
  const handleGroup = (value) => {
    const _arr = value;

    // NAMES
    const _tax_names = accounting?.tax_code.toLowerCase();
    const _service_names = accounting?.service_code.toLowerCase();

    // Handle Bills to Others
    const _billing = GroupingBill({
      dataArticle: _arr,
      tax_name: _tax_names,
      service_name: _service_names,
      bill_type: bill_type,
    });

    // Grouping Data
    // const _grouping = GroupingArticle(_billing);
    // const _grouping = BillArticleShow(_billing);

    console.log("Billing: ", _billing);
    // console.log("Grouping Res: ", _grouping);
    // console.log("Article Bills: ", _newArticle);

    return _billing;
  };

  // Handle Find Others Bill Receiver
  const handleOther = (value) => {
    const _arr = value;
    const _res_id = arrRes.length > 0 ? arrRes : findReservations(billMoved);
    const _curr_res = dataRes?.reservation_id
      ? dataRes.reservation_id
      : state[0]?.reservation_id;
    const _others_id = _res_id.filter((x) => {
      return x != _curr_res;
    });

    const _others = guestList.filter((items) => {
      return items?.id == _arr;
    });

    console.log("Bill Other Receivers: ", _others);
    console.log("Bill Res ID: ", _res_id);
    console.log("Bill Current Res ID: ", _curr_res);
    console.log("Bill Others ID: ", _others_id);

    formTwo.setFieldsValue({
      bill_receiver_other: _others[0]?.full_name,
      full_name_other: _others[0]?.full_name,
      reservation_id_others: _others_id?.length > 0 ? _others_id[0] : " ---- ",
    });
  };

  // Handle Total
  const handleTotal = (values) => {
    const _data = values.data;

    // Showing Bill Type
    const totals = BillArticleShow({
      article: _data,
      bill_type: bill_type,
    });

    console.log("Totals: ", totals);

    if (values?.type == 1) {
      setSubTotal({
        grand: totals.total.total_price,
        service: totals.total.service,
        tax: totals.total.tax,
        total: totals.total.unit_price,
      });

      setBillMaster({
        receiver: _data[0].bill_master,
      });
    } else {
      setSubTotalOther({
        grand: totals.total.total_price,
        service: totals.total.service,
        tax: totals.total.tax,
        total: totals.total.unit_price,
      });

      setBillMaster({
        other: _data[0].bill_master,
      });
    }

    setCardLoad(false);
    setIsLoading(false);
    // setIsDisable(false);
  };

  // SHOW MODAL CHECK-OUT
  const showModalConfirm = async (values) => {
    const _data = values;

    confirm({
      className: "checkout-confirm",
      title: `Check-Out Confirmations`, // isEarly ? `Early Check-Out` : `Check-Out Confirmations`,
      icon: (
        <ExclamationCircleOutlined
          className="confirm-icon"
          style={{ color: "#1890ff" }}
        />
      ),
      content: (
        <>
          <Typography>
            Are you sure all the Data with Reservation ID:{" "}
            <b>{_data?.reservation_id || " - - - - - "}</b> and with Guest Named{" "}
            <b>{_data?.full_name || " - - - - - "}</b> from Rooms{" "}
            <b>{_data?.room_no || " XXXX - 000 "}</b> is ready to Check-Out?
          </Typography>
        </>
      ),
      okText: "Check-Out",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,

      okButtonProps: {
        type: "primary",
        className: "danger-co-btn",
        danger: true,
      },

      cancelButtonProps: {
        className: "cancel-btn",
        type: "default",
      },

      onOk() {
        handleSubmit(_data);
      },

      onCancel() {
        setLoadBtn(false);
      },

      width: 750,
      bodyStyle: {
        padding: 30,
        borderRadius: 10,
      },
    });
  };

  // Handle Return
  const handleReturn = () => {
    const path = "/front-office/expected-departure";

    navigate(path);
  };

  // Handle Move Master Folio Page
  const handleMove = () => {
    const path = "/front-office/bill-outstanding-list/master-folio";

    navigate(path, {
      state: {
        data_res: dataRes,
        data_bill: billList,
        departure: state,
      },
    });
  };

  // Handle Check-Out
  const handleCheckOut = () => {
    const submit = form.getFieldsValue();

    console.log("Data C/O: ", submit);

    setLoadBtn(true);
    showModalConfirm(submit);
  };

  // Handle Submit
  const handleSubmit = async (value) => {
    const allBody = {
      reservation_id: dataRes.reservation_id,
      created_by: user_name,
      price_id_reservation: state.map((x) => {
        return {
          price_id: x.price_id,
          room_number: x.room_number,
        };
      }),
    };

    console.log("ALL BODY: ", allBody);

    await axios
      .post(`${baseurl}/guest/checkout-reservation`, allBody, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (response) => {
        console.log("Res C/O: ", response);

        if (response.status == 200) {
          successCheckOut(response, { method: 0 });

          await handleSuccess();
        } else {
          failedCheckOut(response, { method: 0 });
        }
      })
      .catch((error) => {
        console.log("Error C/O: ", error);

        failedCheckOut(error, { method: 0 });
      })
      .finally(() => {
        setLoadBtn(false);
      });
  };

  // HANDLE SUCCESS
  const handleSuccess = async (e) => {
    const path = "/front-office/expected-departure/check-out/success";
    const pays = {
      data: state,
      is_early: isEarly,
      bill: billList,
      bill_other: billMoved,
      bill_receiver: billReceiver,
      bill_number: billMaster,
    };

    console.log("New State: ", pays);

    setTimeout(() => {
      setLoadBtn(false);

      navigate(`${path}`, {
        state: { billing: pays },
      });
    }, 1000);
  };

  if (!isExist) {
    return (
      <Result
        status={"500"}
        title={"ERROR!"}
        subTitle={`Sorry, something went wrong!`}
        extra={[
          <Row
            className="extra-row"
            key={"extra-row"}
            justify="center"
            gutter={30}
          >
            <Col className="return-col" span={24}>
              <Space className="btn-space" size="large">
                <Tooltip
                  className="return-tooltip"
                  title="Return to Expected Departure Page"
                  color="geekblue"
                  placement="right"
                >
                  <Button
                    className="cancel-btn"
                    key="return-btn"
                    onClick={handleReturn}
                  >
                    {`Return`}
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>,
        ]}
        style={mainBody}
      >
        <>
          <Space className="result-space" direction="vertical" size={5}>
            <Paragraph>
              <Text
                strong
                style={{
                  fontSize: 16,
                }}
              >
                {`The content you submitted may has the following error:`}
              </Text>
            </Paragraph>

            <Paragraph>
              <CloseCircleOutlined
                className="site-result-error-icon"
                style={{
                  color: "#E73827",
                  margin: "0px 10px 0px 0px",
                }}
              />
              {`You are trying to reach this page without doing any process!`}
            </Paragraph>

            <Paragraph>
              <CloseCircleOutlined
                className="site-result-error-icon"
                style={{
                  color: "#E73827",
                  margin: "0px 10px 0px 0px",
                }}
              />
              {`You're request is not valid!`}
            </Paragraph>

            <Paragraph>
              <CloseCircleOutlined
                className="site-result-error-icon"
                style={{
                  color: "#E73827",
                  margin: "0px 10px 0px 0px",
                }}
              />
              {`Please, check and modify the following information before resubmitting!`}
            </Paragraph>
          </Space>
        </>
      </Result>
    );
  }

  return (
    <>
      <Layout>
        <Content>
          <Col span={24} className="master-folio-main-layout">
            <Row className="row-title" justify="start" style={cardHeader}>
              <FaFileInvoiceDollar
                style={{
                  height: 24,
                  width: 24,
                  margin: "22px 30px",
                  color: "#3699FF",
                }}
              />
              <Typography
                style={{ margin: "22px 0px", fontSize: 17, fontWeight: 500 }}
              >
                {`Guest Folio`}
              </Typography>
            </Row>

            <Row
              className="row-search-component"
              justify="start"
              style={cardBody}
            >
              <Space
                align="end"
                size={15}
                className="header-space"
                wrap={true}
                style={{
                  padding: 30,
                  width: "100%",
                }}
              ></Space>
            </Row>

            <Row gutter={[30, 30]} className="table-row">
              <Col
                className="article-list-col"
                xxl={{
                  span: 12,
                  order: 1,
                }}
                xl={{
                  span: 12,
                  order: 1,
                }}
                lg={{
                  span: 12,
                  order: 1,
                }}
                md={{
                  span: 24,
                  order: 1,
                }}
                sm={{
                  span: 24,
                  order: 1,
                }}
                xs={{
                  span: 24,
                  order: 1,
                }}
              >
                <Row
                  justify="space-between"
                  className="row-btn"
                  style={{
                    padding: "15px 30px",
                    background: "#FFFFFF",
                    borderRadius: 5,
                    margin: "0px 0px 15px",
                  }}
                >
                  <Col span={12} className="col-exp-btn">
                    <Typography
                      style={{
                        fontSize: 17,
                        fontWeight: 750,
                      }}
                    >
                      {`GUEST's BILL TO PAY (${
                        state?.length == 1 ? "INDIVIDUAL C/O" : "GROUP C/O"
                      })`}
                    </Typography>
                  </Col>

                  <Col span={12}>
                    <Row justify="end">
                      <Button
                        className="refresh-btn"
                        type="default"
                        icon={<SyncOutlined />}
                        onClick={fetchData}
                        style={{
                          margin: "0px 15px 0px",
                        }}
                      >
                        {`Refresh`}
                      </Button>

                      <Button
                        className="refresh-btn"
                        type="default"
                        icon={
                          <IoReturnUpBackOutline
                            className="return-icons"
                            style={{
                              margin: "0px 5px 0px 0px",
                            }}
                          />
                        }
                        onClick={handleReturn}
                      >
                        {`Cancel`}
                      </Button>
                    </Row>
                  </Col>
                </Row>

                <Divider
                  className="divider-form"
                  style={{
                    margin: "5px 0px",
                    backgroundColor: "#EBEDF3",
                  }}
                />

                <Row className="row-table" key={"rows-table"} style={mainBody}>
                  <Col
                    span={24}
                    className="table-col"
                    style={{
                      padding: "10px 15px 15px",
                    }}
                  >
                    <Row justify="space-between" className="row">
                      <Card
                        className="table-card"
                        bordered
                        loading={cardLoad}
                        style={{
                          width: "100%",
                          // marginTop: 16,
                        }}
                        title={
                          <Row
                            justify="center"
                            align="middle"
                            className="title-row"
                          >
                            <Col span={24} className="title-col">
                              <Typography
                                className="bill-txt"
                                key={"txt-bill"}
                                style={{ fontSize: 20 }}
                              >
                                {`Bill No: ${billMaster?.receiver || " ---- "}`}
                              </Typography>
                            </Col>
                          </Row>
                        }
                        headStyle={{
                          textAlign: "center",
                          background: "#e4e6ef",
                        }}
                        bodyStyle={{
                          textAlign: "center",
                          display: "block",
                          background: "#f5f5f5",
                          padding: "0px 10px",
                        }}
                      >
                        <Form
                          className="form-bill"
                          key="form-bill"
                          name="form-bill"
                          form={form}
                          labelWrap={true}
                          labelAlign="left"
                          labelCol={{
                            span: 8,
                            offset: 2,
                          }}
                          wrapperCol={{
                            span: 16,
                          }}
                        >
                          <Row
                            align="middle"
                            justify="center"
                            style={{ margin: 0, height: 30 }}
                          >
                            <Col span={12}>
                              <Form.Item name="full_name" label="Guest Name">
                                <Input
                                  disabled
                                  placeholder="Guest Name"
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                name="reservation_id"
                                label="Reservation No."
                              >
                                <Input
                                  disabled
                                  placeholder="Reservation No."
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            align="middle"
                            justify="start"
                            style={{ margin: "5px 0px 0px", height: 30 }}
                          >
                            <Col span={12}>
                              <Form.Item
                                name="bill_receiver"
                                label="Bill Receiver"
                              >
                                <Input
                                  disabled
                                  placeholder="Bill Receiver."
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                name="room_no"
                                label="Room No."
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Input
                                  readOnly={true}
                                  placeholder="Room No."
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            align="middle"
                            justify="start"
                            style={{ margin: "5px 0px 0px", height: 30 }}
                          >
                            <Col span={12}>
                              <Form.Item name="arrival" label="Arrival">
                                <Input
                                  disabled
                                  placeholder="Arrival"
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                name="departure"
                                label="Departure"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Input
                                  readOnly={true}
                                  placeholder="Departure"
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>
                          </Row>

                          <Row
                            align="middle"
                            justify="start"
                            style={{ margin: "5px 0px 0px", height: 30 }}
                          >
                            <Col span={12}>
                              <Form.Item name="night" label="Night(s)">
                                <Input
                                  disabled
                                  placeholder="Night(s)"
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>

                            <Col span={12}>
                              <Form.Item
                                name="payment_status"
                                label="Payment Status"
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Input
                                  readOnly={true}
                                  placeholder="Payment Status"
                                  bordered={false}
                                  style={textForm}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form>
                      </Card>
                    </Row>

                    <Divider
                      className="divider-form"
                      orientation="left"
                      orientationMargin={15}
                      style={{
                        margin: "10px 5px",
                        borderColor: "#EBEDF3",
                        color: "#8c8c8c",
                      }}
                    >
                      {`Buying List`}
                    </Divider>

                    <Row justify="space-between" className="row">
                      <FolioTable
                        dataTable={billList}
                        dataTotal={subTotal}
                        bill_type={bill_type}
                        isLoading={isLoading}
                        currency={currency}
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col
                xxl={{
                  span: 12,
                  order: 2,
                }}
                xl={{
                  span: 12,
                  order: 2,
                }}
                lg={{
                  span: 12,
                  order: 2,
                }}
                md={{
                  span: 24,
                  order: 2,
                }}
                sm={{
                  span: 24,
                  order: 2,
                }}
                xs={{
                  span: 24,
                  order: 2,
                }}
                className="col-table"
              >
                <Row justify="space-between" className="row-btn">
                  <Col span={24} className="col-exp-btn">
                    {billMoved.length > 0 ? (
                      <>
                        <Alert
                          message={`Informational Notes (Bill Joined/Splitted)`}
                          description={
                            <Typography
                              className="text-info"
                              style={{
                                fontWeight: 750,
                                fontSize: 15,
                                textAlign: "justify",
                              }}
                            >
                              {`There are some Articles of this bill that has been Splitted(Moved) or Joined to/with Another Bills!`}
                              {` If you want to see the Details or Wanted to Split/Join Bills, Please go to Master Folio Page!`}
                            </Typography>
                          }
                          type="warning"
                          showIcon
                          action={
                            <Space>
                              <Button
                                size="small"
                                type="primary"
                                className="print-btn"
                                onClick={handleMove}
                                icon={<SendOutlined />}
                              >
                                {`Master Folio`}
                              </Button>
                            </Space>
                          }
                        />

                        <Alert
                          message={`Bill Joined/Splitted`}
                          description={
                            <Typography
                              className="text-info"
                              style={{
                                fontWeight: 750,
                                fontSize: 15,
                                textAlign: "justify",
                              }}
                            >
                              {`RESERVATION ID: ${arrRes.join(", ")}`}
                            </Typography>
                          }
                          type="error"
                          showIcon
                          icon={<WarningOutlined />}
                        />
                      </>
                    ) : (
                      <Alert
                        message={`Informational Notes (Unchanged Bill)`}
                        description={
                          <Typography
                            className="text-info"
                            style={{
                              fontWeight: 750,
                              fontSize: 15,
                            }}
                          >
                            {`If you want to see the Details or wanted to Split/Join Bills, Please go to Master Folio Page!`}
                          </Typography>
                        }
                        type="info"
                        showIcon
                        action={
                          <Space>
                            <Button
                              size="small"
                              type="primary"
                              className="print-btn"
                              onClick={handleMove}
                              icon={<SendOutlined />}
                            >
                              {`Master Folio`}
                            </Button>
                          </Space>
                        }
                      />
                    )}
                  </Col>
                </Row>

                <Row
                  justify="end"
                  className="row-btn"
                  style={{
                    padding: "15px 30px",
                    background: "#FFFFFF",
                    borderRadius: 5,
                    margin: "15px 0px",
                  }}
                >
                  <Row justify="end">
                    <Button
                      className="check-out-btn"
                      type="danger"
                      loading={loadBtn}
                      disabled={isDisable}
                      icon={
                        <ImExit
                          className="co-icon"
                          style={{
                            margin: "0px 10px 0px 0px",
                          }}
                        />
                      }
                      onClick={handleCheckOut}
                    >
                      {`Check-Out`}
                    </Button>
                  </Row>
                </Row>

                <Divider
                  className="divider-form"
                  style={{
                    margin: "5px 0px",
                    backgroundColor: "#EBEDF3",
                  }}
                />

                {billMoved.length > 0 ? (
                  <Row
                    className="row-table"
                    key={"rows-table"}
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 5,
                      width: "100%",
                    }}
                  >
                    <Col
                      span={24}
                      className="table-col"
                      style={{
                        padding: "10px 15px 15px",
                      }}
                    >
                      <Row justify="space-between" className="row">
                        <Card
                          className="table-card"
                          bordered
                          loading={cardLoad}
                          style={{
                            width: "100%",
                            // marginTop: 16,
                          }}
                          title={
                            <Row
                              justify="center"
                              align="middle"
                              className="title-row"
                            >
                              <Col span={24} className="title-col">
                                <Typography
                                  className="bill-txt"
                                  key={"txt-bill"}
                                  style={{ fontSize: 20 }}
                                >
                                  {`Bill No: ${
                                    billMoved[0].bill_master || " ---- "
                                  }`}
                                </Typography>
                              </Col>
                            </Row>
                          }
                          headStyle={{
                            textAlign: "center",
                            background: "#e4e6ef",
                          }}
                          bodyStyle={{
                            textAlign: "center",
                            display: "block",
                            background: "#f5f5f5",
                            padding: "0px 10px",
                          }}
                        >
                          <Form
                            className="form-bill"
                            key="form-bill"
                            name="form-bill"
                            form={formTwo}
                            labelWrap={true}
                            labelAlign="left"
                            labelCol={{
                              span: 8,
                              offset: 2,
                            }}
                            wrapperCol={{
                              span: 16,
                            }}
                          >
                            <Row
                              align="middle"
                              justify="center"
                              style={{ margin: 0, height: 30 }}
                            >
                              <Col span={12}>
                                <Form.Item
                                  name="full_name_other"
                                  label="Guest Name"
                                >
                                  <Input
                                    disabled
                                    placeholder="Guest Name"
                                    bordered={false}
                                    style={textForm}
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={12}>
                                <Form.Item
                                  name="bill_receiver_other"
                                  label="Bill Receiver"
                                >
                                  <Input
                                    disabled
                                    placeholder="Bill Receiver."
                                    bordered={false}
                                    style={textForm}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row
                              align="middle"
                              justify="center"
                              style={{ margin: 0, height: 30 }}
                            >
                              <Col span={12}>
                                <Form.Item
                                  name="reservation_id_others"
                                  label="Reservation ID"
                                >
                                  <Input
                                    disabled
                                    placeholder="Reservation ID"
                                    bordered={false}
                                    style={textForm}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form>
                        </Card>
                      </Row>

                      <Divider
                        className="divider-form"
                        orientation="left"
                        orientationMargin={15}
                        style={{
                          margin: "10px 5px",
                          borderColor: "#EBEDF3",
                          color: "#8c8c8c",
                        }}
                      >
                        {`Buying List`}
                      </Divider>

                      <Row justify="space-between" className="row">
                        <FolioTable
                          dataTable={billMoved}
                          dataTotal={subTotalOther}
                          bill_type={bill_type}
                          isLoading={isLoading}
                          currency={currency}
                        />
                      </Row>
                    </Col>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </Col>
        </Content>
      </Layout>
    </>
  );
}
